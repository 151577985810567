import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "40px",
      // alignItems:"center",
      // width:"80%"
    },
  };
});
function Quote({ singleLead }) {
  const { formContainer } = useStyle();
  return (
    <Paper elevation={3}>
      <Box className={formContainer}>
        <Paper elevation={3}>
          <TableContainer component={Paper} sx={{}}>
            <Table
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Subject
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Expired At
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Sub Total ($)
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Discount ($)
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Tax ($)
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Adjustment ($)
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Grand Total ($)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {singleLead?.leadQuote?.map((quote,i) => {
                  return (
                    <TableRow key={i} size="small">
                      <TableCell size="small" align="left">{quote?.Subject}</TableCell>
                      <TableCell size="small" align="left">{ moment(quote?.ExpiredAt).format("DD-MMM-YYYY")}</TableCell>
                      <TableCell size="small" align="left">{ quote?.SubTotal}</TableCell>
                      <TableCell size="small" align="left">{ quote?.DiscountAmount}</TableCell>
                      <TableCell size="small" align="left">{ quote?.TaxAmount}</TableCell>
                      <TableCell size="small" align="left">{ quote?.AdjustmentAmount}</TableCell>
                      <TableCell size="small" align="left">{ quote?.GrandTotal}</TableCell>
                    </TableRow>
                  );
                })}
                {singleLead?.leadQuote?.length > 0 ? null : (
                  <TableRow sx={{ width: "100%", textAlign: "center" }}>
                    <Typography>No records Found</Typography>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Paper>
  );
}

export default Quote;
Quote.propTypes = {
  singleLead: PropTypes.any,
};
