import { Box, Paper, Typography } from "@mui/material";
import React from "react";
// import ListAltIcon from '@mui/icons-material/ListAlt';
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import SellIcon from "@mui/icons-material/Sell";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import WorkIcon from "@mui/icons-material/Work";
import TokenIcon from "@mui/icons-material/Token";
const useStyle = makeStyles(() => {
  return {
    paperBox: {
      width: "100%",
      maxWidth: "1280px",
      padding: "20px",
      marginTop: "20px",
    },
    paperContentBox: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: "10px",
    },
    contentBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    textBox: {
      minHeight: "90px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    setingText: {
      transform: "scale(0)",
      height: "0px",
    },
  };
});

function OtherSettings({ roles }) {
  const { paperBox, paperContentBox, contentBox, textBox, setingText } =
    useStyle();
  const navigate = useNavigate();
  return (
    <Box mb={2}>
      <Typography sx={{ fontWeight: "bold" }}>Other Settings</Typography>
      <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
        Manage all your extra settings in the CRM
      </Typography>
      <Paper
        className={paperBox}
        elevation={3}
        sx={{}}
      >
        <Box
          className={paperContentBox}
          sx={{}}
        >
          {/* <Box onClick={()=>{navigate("/settings/groups");}} className="settingBoxA" sx={{ padding: "10px",cursor:"pointer" }}>
                <Box className={contentBox} sx={{}}>
                    <ListAltIcon fontSize='large' sx={{ color: theme.palette.primary.main, }} />
                    <Box className={textBox} sx={{}}>
                        <Typography variant='h6'>Web Forms</Typography>
                        <Typography variant='body2' className={`${setingText} settingBoxAhoverEffect`} sx={{}}>Add,edit or delete groups from  </Typography>
                        <Typography variant='body2' className={`${setingText} settingBoxAhoverEffect`} sx={{}}>CRM</Typography>
                    </Box>
                </Box>

            </Box> */}

          {roles?.some((role) => role === "Tags") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/tags");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-tags"
              >
                <Box
                  className={contentBox}
                  sx={{}}
                >
                  <SellIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box
                    className={textBox}
                    sx={{}}
                  >
                    <Typography variant="h6">Tags</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add,edit or delete Tags from{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      CRM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {roles?.some((role) => role === "Other Settings_Business") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/business");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-business"
              >
                <Box
                  className={contentBox}
                  sx={{}}
                >
                  <WorkIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box
                    className={textBox}
                    sx={{}}
                  >
                    <Typography variant="h6">Business</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      View or Edit Business from CRM{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {roles?.some((role) => role === "Other Settings_Facebook Page") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/fbpagetoken");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-business"
              >
                <Box
                  className={contentBox}
                  sx={{}}
                >
                  <TokenIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box
                    className={textBox}
                    sx={{}}
                  >
                    <Typography variant="h6">Facebook Page</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add Facebook Acess Token or{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Page ID
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default OtherSettings;
OtherSettings.propTypes = {
  roles: PropTypes.array,
};
