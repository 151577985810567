import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFacebookPages,
  fetchPagesPopulate,
  getFacebookPageDetails,
} from "../../Redux/Slice/Settings/FacebookPageSlice/FacebookPageSlice";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});
function FbTokenFilterDrawer({
  setcount,
  setfilterVal,
  filterVal,
  handleChangeVal,
  // setallCampaign,
  // rows,
  setdrawerOpen,
  drawerOpen,
}) {
  const { contentBox, fieldBox } = useStyles();
  const { FacebookPages, PagesPopulate } = useSelector(getFacebookPageDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFacebookPages());
    dispatch(fetchPagesPopulate());
  }, [dispatch]);
  const handleClose = () => {
    setdrawerOpen(!open);
  };
  console.log(filterVal);
  const handleRemoveAll = () => {
    for (const value in filterVal) {
      filterVal[value] = "";
    }
    setcount(0);
  };
  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Filter</Typography>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  // setallCampaign(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            <Box>
              <Typography>Organization</Typography>
              <Box className={fieldBox}>
                <Select
                  name="organization"
                  variant="outlined"
                  size="small"
                  // defaultValue={filterVal?.salesperson}
                  value={filterVal?.organization}
                  onChange={(e, val) => {
                    console.log(e.target);
                    console.log(val);
                    handleChangeVal(e);
                  }}
                  // organization="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Organization"
                  className="input_border"
                  select
                  // onChange={(e) => { handleChangeVal(e); }}
                >
                  <MenuItem value="">select</MenuItem>
                  {PagesPopulate?.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={item?.Name}
                      >
                        {item?.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      organization: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Page Name</Typography>
              <Box className={fieldBox}>
                <Select
                  name="pageName"
                  variant="outlined"
                  size="small"
                  // defaultValue={filterVal?.salesperson}
                  value={filterVal?.pageName}
                  onChange={(e, val) => {
                    console.log(e.target);
                    console.log(val);
                    handleChangeVal(e);
                  }}
                  // pageName="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Page Name"
                  className="input_border"
                  select
                  // onChange={(e) => { handleChangeVal(e); }}
                >
                  <MenuItem value="">select</MenuItem>
                  {FacebookPages?.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={item?.PageTitle}
                      >
                        {item?.PageTitle}
                      </MenuItem>
                    );
                  })}
                </Select>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      pageName: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Page ID</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="pageId"
                  variant="outlined"
                  size="small"
                  value={filterVal?.pageId}
                  type="number"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Page ID"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      pageId: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default FbTokenFilterDrawer;
FbTokenFilterDrawer.propTypes = {
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  setallCampaign: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
};
