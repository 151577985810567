import React from "react";
import CreateOrganization from "../Components/Contacts/Organizations/CreateOrganization/CreateOrganization";
import CreatePersonForm from "../Components/Contacts/Persons/CreatePersonForm/CreatePersonForm";
import CreateProduct from "../Components/Products/CreateProduct/CreateProduct";
import CreateGroup from "../Components/Settings/User/Groups/CreateGroup";
import Groups from "../Components/Settings/User/Groups/Groups";
import Lead from "../Components/Settings/Lead/Lead";
import CreateRole from "../Components/Settings/User/Roles/CreateRole";
import Roles from "../Components/Settings/User/Roles/Roles";
import CreateUsers from "../Components/Settings/User/Users/CreateUsers";
import Users from "../Components/Settings/User/Users/Users";
import Organizations from "../Pages/Contacts/Organizations/Organizations";
import Persons from "../Pages/Contacts/Persons/Persons";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Compose from "../Pages/Email/Compose";
import Draft from "../Pages/Email/Draft";
import Sent from "../Pages/Email/Sent";
import Products from "../Pages/Products/Products";
import Settings from "../Pages/Settings/Settings";
import UseContainer from "../Pages/UserContainer/UserContainer";
import Quotes from "../Pages/Quotes/Quotes";
import CreateQuotes from "../Components/Quotes/CreateQuotes/CreateQuotes";
import Leads from "../Pages/Leads/Leads";
import CreateLeads from "../Components/Leads/CreateLeads";
import Piplines from "../Components/Settings/Lead/Piplines/Piplines";
import CreatePiplines from "../Components/Settings/Lead/Piplines/CreatePiplines";
import Sources from "../Components/Settings/Lead/Sources/Sources";
import CreateSources from "../Components/Settings/Lead/Sources/CreateSources";
import Types from "../Components/Settings/Lead/Types/Types";
import CreateTypes from "../Components/Settings/Lead/Types/CreateTypes";
import Inbox from "../Pages/Email/Inbox";
import OutBox from "../Pages/Email/OutBox";
import Trash from "../Pages/Email/Trash";
import Activities from "../Pages/Activities/Activities";
import LoginContent from "../Pages/Login/LoginContent";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import Tags from "../Components/Settings/OtherSettings/Tags/Tags";
import CreateTags from "../Components/Settings/OtherSettings/Tags/CreateTags";
import LeadView from "../Components/Leads/LeadView/LeadView";
import EditActivities from "../Components/Activities/EditActivities";
import Template from "../Pages/Campaign/templates/templates";
import CreateTemplate from "../Components/Campaign/Templates/CreateTemplate";
import CampaignGroups from "../Pages/Campaign/Groups/CampaignGroups";
import CreateCampaignGroups from "../Components/Campaign/CampaignGroups/CreateCampaignGroups";
import Campaigns from "../Pages/Campaign/Campaigns/Campaigns";
import CreateCampaigns from "../Components/Campaign/Campaigns/CreateCampaigns";
import Service from "../Pages/Service/Service";
import CreateService from "../Components/Service/CreateService/CreateService";
import Business from "../Components/Settings/OtherSettings/Business/Business";
import FacebookPage from "../Pages/FacebookPage/FacebookPage";
import FbToken from "../Pages/FbToken/FbToken";
import FbTokenTable from "../Pages/FbToken/FbTokenTable";
import PublicLeads from "../Pages/PublicLeads/PublicLeads";
import Unit from "../Pages/Inventory/Unit/Unit";
import CreateUnit from "../Components/Inventory/Unit/CreateUnit";
import Descriptions from "../Components/Settings/Lead/Descriptions/Descriptions";
import DescriptionCreateForm from "../Components/Settings/Lead/Descriptions/DescriptionCreateForm/DescriptionCreateForm";
import Target from "../Pages/Targets/Target";
import CreateTarget from "../Components/Targets/CreateTarget/CreateTarget";
import Section from "../Components/Settings/Inventory/Section/Section";
import CreateSection from "../Components/Settings/Inventory/Section/CreateSection";
import CreateProject from "../Components/Settings/Inventory/Project/CreateProject";
import Project from "../Components/Settings/Inventory/Project/Project";
import BookedUnits from "../Pages/Inventory/BookedUnit/BookedUnit";
import Category from "../Components/Settings/Inventory/Category/Category";
import CategoryCreateUpdateForm from "../Components/Settings/Inventory/Category/CategoryCreateUpdateForm/CategoryCreateUpdateForm";
import RecycleLeads from "../Pages/RecycleLeads/RecycleLeads";
import AssignedTargets from "../Pages/AssignedTargets/AssignedTargets";
import UpdateProfile from "../Pages/Profile/UpdateProfile";
import UserPerformanceReport from "../Pages/Reports/UserPerformanceReport/UserPerformanceReport";
import UserLeadsReport from "../Pages/Reports/UserLeadsReport/UserLeadsReport";
import UserActivitiesReport from "../Pages/Reports/UserActivitiesReport/UserActivitiesReport";
import UserWonLeadsReport from "../Pages/Reports/UserWonLeadsReport/UserWonLeadsReport";
import UserTargetReport from "../Pages/Reports/UserTargetReport/UserTargetReport";
import GroupPerformanceReport from "../Pages/Reports/GroupPerformanceReport/GroupPerformanceReport";
import ProfitReport from "../Pages/Reports/ProfitReport/ProfitReport";
import SaleReport from "../Pages/Reports/SalesReport/SaleReport";
import TransferLeads from "../Pages/TansferLeads/TransferLeads";
import Meetings from "../Pages/Meetings/Meetings";
import MeetingsCreate from "../Components/Meetings/MeetingsCreate/MeetingsCreate";

export const AdminRoutes = [
  {
    path: "/",
    component: <LoginContent />,
  },
  {
    path: "/dashboard",
    component: (
      <UseContainer
        activePage={<Dashboard />}
        headerTitle={"Dashboard"}
        breadCrumb={"Dashboard"}
      />
    ),
    joyrideSteps: [
      {
        target: ".to",
        content: "Welcome!! Please spare a minute to learn about our page",
        // Add this
        disableBeacon: true,
      },
      {
        target: ".get",
        content: "You can log in here",
      },
    ],
  },
  {
    path: "/profile",
    component: (
      <UseContainer
        activePage={<UpdateProfile />}
        headerTitle={"Profile"}
        breadCrumb={"Profile"}
        disableTitleBar={true}
      />
    ),
  },
  {
    path: "/forgotpassword",
    component: <ForgotPassword />,
  },
  {
    path: "/email/compose",
    component: (
      <UseContainer
        activePage={<Compose />}
        headerTitle={"Compose"}
        breadCrumb={"Compose"}
        disableTitleBar={true}
      />
    ),
  },
  {
    path: "/email/inbox",
    component: (
      <UseContainer
        activePage={<Inbox />}
        headerTitle={"Inbox"}
        breadCrumb={"Inbox"}
      />
    ),
  },
  {
    path: "/email/draft",
    component: (
      <UseContainer
        activePage={<Draft />}
        headerTitle={"Draft"}
        breadCrumb={"Draft"}
      />
    ),
  },
  {
    path: "/email/outbox",
    component: (
      <UseContainer
        activePage={<OutBox />}
        headerTitle={"Outbox"}
        breadCrumb={"Outbox"}
      />
    ),
  },
  {
    path: "/email/sent",
    component: (
      <UseContainer
        activePage={<Sent />}
        headerTitle={"Sent"}
        breadCrumb={"Sent"}
      />
    ),
  },
  {
    path: "/email/trash",
    component: (
      <UseContainer
        activePage={<Trash />}
        headerTitle={"Trash"}
        breadCrumb={"Trash"}
      />
    ),
  },

  {
    path: "/activities",
    component: (
      <UseContainer
        activePage={<Activities />}
        headerTitle={"Activities"}
        breadCrumb={"Activities"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/meetings",
    component: (
      <UseContainer
        activePage={<Meetings />}
        headerTitle={"Internal Meetings"}
        breadCrumb={"Internal Meetings"}
        buttons={[
          {
            content: "New",
            RoleName: "Persons_Create",
            Path: "/meetings/create",
          },
        ]}
      />
    ),
  },

  {
    path: "/meetings/create",
    component: (
      <UseContainer
        activePage={<MeetingsCreate />}
        disableTitleBar={true}
        headerTitle={"New"}
        breadCrumb={"New"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/meetings/create/:id",
    component: (
      <UseContainer
        activePage={<MeetingsCreate />}
        disableTitleBar={true}
        headerTitle={"New"}
        breadCrumb={"New"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/edit/activities",
    component: (
      <UseContainer
        activePage={<EditActivities />}
        disableTitleBar={true}
        headerTitle={"Edit Acitivities"}
        breadCrumb={"Edit Acitivities"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/products",
    component: (
      <UseContainer
        activePage={<Products />}
        headerTitle={"Products"}
        breadCrumb={"Products"}
        buttons={[
          {
            content: "New",
            RoleName: "Products_Create",
            Path: "/products/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/products/create",
    component: (
      <UseContainer
        activePage={<CreateProduct />}
        disableTitleBar={true}
        headerTitle={"Products"}
        breadCrumb={"Products"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/service",
    component: (
      <UseContainer
        activePage={<Service />}
        headerTitle={"Service"}
        breadCrumb={"Service"}
        buttons={[
          {
            content: "New",
            RoleName: "Products_Create",
            Path: "/service/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/service/create",
    component: (
      <UseContainer
        activePage={<CreateService />}
        disableTitleBar={true}
        headerTitle={"Service"}
        breadCrumb={"Service"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/TransferLeads",
    component: (
      <UseContainer
        activePage={<TransferLeads />}
        disableTitleBar={true}
        headerTitle={"Quotes"}
        breadCrumb={"Quotes"}
        buttons={[]}
        // headerTitle={"Transfer Leads"}
        // breadCrumb={"Transfer Leads"}
        // linkbreadCrumb2={"/leads"}
      />
    ),
  },
  {
    path: "/leads",
    component: (
      <UseContainer
        activePage={<Leads />}
        headerTitle={"Leads"}
        breadCrumb={"Leads"}
        buttons={[
          {
            content: "New",
            RoleName: "Leads_Create",
            Path: "/leads/create",
          },
        ]}
      />
    ),
    joyrideSteps: [
      {
        target: ".leadsIntro",
        content: "Welcome!! Please spare a minute to learn about our page",
        // Add this
        disableBeacon: true,
      },
    ],
  },
  {
    path: "/leads/view",
    component: (
      <UseContainer
        activePage={<LeadView />}
        headerTitle={"Lead View"}
        breadCrumb={"Lead View"}
        breadCrumb2={"Lead"}
        linkbreadCrumb2={"/leads"}

        // buttons={[
        //   {
        //     content: "Edit",
        //     Path: "/leads/create",
        //   },
        // ]}
      />
    ),
  },
  {
    path: "/leads/create",
    component: (
      <UseContainer
        activePage={<CreateLeads />}
        disableTitleBar={true}
        headerTitle={"Quotes"}
        breadCrumb={"Quotes"}
        buttons={[]}
      />
    ),
  },

  {
    path: "/recycle-leads",
    component: (
      <UseContainer
        activePage={<RecycleLeads />}
        headerTitle={"Recycle Leads"}
        breadCrumb={"Recycle Leads"}
        buttons={[]}
      />
    ),
    joyrideSteps: [
      {
        target: ".leadsRecycleIntro",
        content: "Welcome!! Please spare a minute to learn about our page",
        // Add this
        disableBeacon: true,
      },
    ],
  },
  {
    path: "/quotes",
    component: (
      <UseContainer
        activePage={<Quotes />}
        headerTitle={"Quotes"}
        breadCrumb={"Quotes"}
        buttons={[
          {
            content: "New",
            RoleName: "Quotes_Create",
            Path: "/quotes/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/quotes/create",
    component: (
      <UseContainer
        activePage={<CreateQuotes />}
        disableTitleBar={true}
        headerTitle={"Quotes"}
        breadCrumb={"Quotes"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/contacts/persons",
    component: (
      <UseContainer
        activePage={<Persons screen="Customer" />}
        headerTitle={"Customers"}
        breadCrumb={"Customers"}
        buttons={[
          {
            content: "New",
            RoleName: "Persons_Create",
            Path: "/contacts/persons/createPerson",
          },
        ]}
      />
    ),
  },
  {
    path: "/contacts/organizations",
    component: (
      <UseContainer
        activePage={<Organizations />}
        headerTitle={"Organizations"}
        breadCrumb={"Organizations"}
        buttons={[
          {
            content: "New",
            RoleName: "Organizations_Create",
            Path: "/contacts/organizations/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/contacts/affiliate",
    component: (
      <UseContainer
        activePage={<Persons screen="Affiliate" />}
        headerTitle={"Affiliate"}
        breadCrumb={"Affiliate"}
        buttons={[
          {
            content: "New",
            RoleName: "Affiliates_Create",
            Path: "/contacts/affiliate/createAffiliate",
          },
        ]}
      />
    ),
  },
  {
    path: "/contacts/organizations/create",
    component: (
      <UseContainer
        activePage={<CreateOrganization />}
        disableTitleBar={true}
        headerTitle={"Organizations"}
        breadCrumb={"Organizations"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/contacts/persons/createPerson",
    component: (
      <UseContainer
        activePage={<CreatePersonForm screen="Customer" />}
        disableTitleBar={true}
        headerTitle={"New"}
        breadCrumb={"New"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/contacts/affiliate/createAffiliate",
    component: (
      <UseContainer
        activePage={<CreatePersonForm screen="Affiliate" />}
        disableTitleBar={true}
        headerTitle={"New"}
        breadCrumb={"New"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/campaigns/campaign",
    component: (
      <UseContainer
        activePage={<Campaigns />}
        headerTitle={"Campaigns"}
        breadCrumb={"Campaigns"}
        buttons={[
          {
            content: "New",
            RoleName: "Campaigns_Create",
            Path: "/campaigns/campaign/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/campaigns/campaign/create",
    component: (
      <UseContainer
        activePage={<CreateCampaigns />}
        disableTitleBar={true}
        headerTitle={"Campaigns"}
        breadCrumb={"Campaigns"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/campaigns/templates",
    component: (
      <UseContainer
        activePage={<Template />}
        headerTitle={"Templates"}
        breadCrumb={"Templates"}
        buttons={[
          {
            content: "New",
            RoleName: "Templates_Create",
            Path: "/campaigns/templates/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/campaigns/templates/create",
    component: (
      <UseContainer
        activePage={<CreateTemplate />}
        disableTitleBar={true}
        headerTitle={"Templates"}
        breadCrumb={"Templates"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/campaigns/groups",
    component: (
      <UseContainer
        activePage={<CampaignGroups />}
        headerTitle={"Campaign Groups"}
        breadCrumb={"Campaign Groups"}
        buttons={[
          {
            content: "New",
            RoleName: "Campaign_Groups_Create",
            Path: "/campaigns/groups/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/campaigns/groups/create",
    component: (
      <UseContainer
        activePage={<CreateCampaignGroups />}
        disableTitleBar={true}
        headerTitle={"Campaign Groups"}
        breadCrumb={"Campaign Groups"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/reports/user-performance",
    component: (
      <UseContainer
        activePage={<UserPerformanceReport />}
        headerTitle={"User Performance Report"}
        breadCrumb={"User Performance Report"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/reports/user-leads",
    component: (
      <UseContainer
        activePage={<UserLeadsReport />}
        headerTitle={"User Leads Report"}
        breadCrumb={"User Leads Report"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/reports/user-activity",
    component: (
      <UseContainer
        activePage={<UserActivitiesReport />}
        headerTitle={"User Activities Report"}
        breadCrumb={"User Activities Report"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/reports/user-won-leads",
    component: (
      <UseContainer
        activePage={<UserWonLeadsReport />}
        headerTitle={"User Won Leads Report"}
        breadCrumb={"User Won Leads Report"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/reports/user-targets",
    component: (
      <UseContainer
        activePage={<UserTargetReport />}
        headerTitle={"User Targets Report"}
        breadCrumb={"User Targets Report"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/reports/group-performance",
    component: (
      <UseContainer
        activePage={<GroupPerformanceReport />}
        headerTitle={"Group Performance Report"}
        breadCrumb={"Group Performance Report"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/reports/profit",
    component: (
      <UseContainer
        activePage={<ProfitReport />}
        headerTitle={"Profit Report"}
        breadCrumb={"Profit Report"}
        buttons={[]}
      />
    ),
  },
  //////
  {
    path: "/reports/SaleReport",
    component: (
      <UseContainer
        activePage={<SaleReport />}
        headerTitle={"Sales Report"}
        breadCrumb={"Sales Report"}
        buttons={[]}
      />
    ),
  },

  {
    path: "/settings",
    component: (
      <UseContainer
        activePage={<Settings />}
        headerTitle={"Settings"}
        breadCrumb={"Settings"}
        transparentBackground={true}
      />
    ),
  },
  {
    path: "/settings/groups",
    component: (
      <UseContainer
        activePage={<Groups />}
        headerTitle={"Groups"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        breadCrumb={"Groups"}
        buttons={[
          {
            content: "New",
            RoleName: "Groups_Create",
            Path: "/create/group",
          },
        ]}
      />
    ),
  },
  {
    path: "/create/group",
    component: (
      <UseContainer
        activePage={<CreateGroup />}
        disableTitleBar={true}
        headerTitle={"Create Group"}
        breadCrumb={"Create Group"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/business",
    component: (
      <UseContainer
        activePage={<Business />}
        disableTitleBar={true}
        headerTitle={"Business"}
        breadCrumb={"Business"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/fbpagetoken",
    component: (
      <UseContainer
        activePage={<FbTokenTable />}
        headerTitle={"Facebook Token"}
        breadCrumb={"Facebook Token"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Facebook Page_Create",
            Path: "/create/fbpagetoken",
          },
        ]}
      />
    ),
  },
  {
    path: "/publicleads",
    component: (
      <UseContainer
        activePage={<PublicLeads />}
        headerTitle={"Public Leads"}
        breadCrumb={"Public Leads"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/create/fbpagetoken",
    component: (
      <UseContainer
        activePage={<FbToken />}
        disableTitleBar={true}
        breadCrumb={"Facebook Token"}
        headerTitle={"Facebook Token"}
        buttons={[]}
      />
    ),
  },
  // {
  //   path: "/settings/fbpagetoken",
  //   component: (
  //     <UseContainer
  //       activePage={<FbToken />}
  //       disableTitleBar={true}
  //       headerTitle={"Facebook Token"}
  //       buttons={[]}
  //     />
  //   ),
  // },
  {
    path: "/settings/roles",
    component: (
      <UseContainer
        activePage={<Roles />}
        headerTitle={"Roles"}
        breadCrumb={"Roles"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Roles_Create",
            Path: "/create/roles",
          },
        ]}
      />
    ),
  },
  {
    path: "/create/roles",
    component: (
      <UseContainer
        activePage={<CreateRole />}
        disableTitleBar={true}
        headerTitle={"Create Roles"}
        breadCrumb={"Create Roles"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/users",
    component: (
      <UseContainer
        activePage={<Users />}
        headerTitle={"Users"}
        breadCrumb={"Users"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Users_Create",
            Path: "/create/users",
          },
        ]}
      />
    ),
  },
  {
    path: "/create/users",
    component: (
      <UseContainer
        activePage={<CreateUsers />}
        disableTitleBar={true}
        headerTitle={"Create Users"}
        breadCrumb={"Create Users"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/lead",
    component: (
      <UseContainer
        activePage={<Lead />}
        headerTitle={"Settings Lead"}
        breadCrumb={"Settings Lead"}
        buttons={[
          {
            content: "New",
            RoleName: "Users_Create",
            Path: "/create/users",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/piplines",
    component: (
      <UseContainer
        activePage={<Piplines />}
        headerTitle={"Piplines"}
        breadCrumb={"Piplines"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Pipelines_Create",
            Path: "/settings/piplines/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/piplines/create",
    component: (
      <UseContainer
        activePage={<CreatePiplines />}
        disableTitleBar={true}
        breadCrumb={"Piplines / Creat Piplines"}
        // headerTitle={"Create Roles"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/sources",
    component: (
      <UseContainer
        activePage={<Sources />}
        headerTitle={"Sources"}
        breadCrumb={"Sources"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Sources_Create",
            Path: "/settings/sources/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/sources/create",
    component: (
      <UseContainer
        activePage={<CreateSources />}
        disableTitleBar={true}
        headerTitle={"Create Source"}
        breadCrumb={"Sources / Create Source"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/Types",
    component: (
      <UseContainer
        activePage={<Types />}
        headerTitle={"Types"}
        breadCrumb={"Types"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Types_Create",
            Path: "/settings/types/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/types/create",
    component: (
      <UseContainer
        activePage={<CreateTypes />}
        disableTitleBar={true}
        headerTitle={"Create Types"}
        breadCrumb={"Types / Create Types"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/Tags",
    component: (
      <UseContainer
        activePage={<Tags />}
        headerTitle={"Tags"}
        breadCrumb={"Tags"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Tags_Create",
            Path: "/settings/tags/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/tags/create",
    component: (
      <UseContainer
        activePage={<CreateTags />}
        disableTitleBar={true}
        headerTitle={"Create Tags"}
        breadCrumb={"Tags / Create Tags"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/descriptions",
    component: (
      <UseContainer
        activePage={<Descriptions />}
        headerTitle={"Leads Description"}
        breadCrumb={"Descriptions"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Descriptions_Create",
            Path: "/settings/descriptions/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/descriptions/create",
    component: (
      <UseContainer
        activePage={<DescriptionCreateForm />}
        disableTitleBar={true}
        headerTitle={"Create Descriptions"}
        breadCrumb={"Descriptions / Create Descriptions"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/chats/facebookpage",
    component: (
      <UseContainer
        activePage={<FacebookPage />}
        headerTitle={"FACEBOOK PAGE"}
        breadCrumb={"Facebook Page"}
        buttons={
          [
            // {
            //   content: "New",
            //   RoleName: "Tags_Create",
            //   Path: "/settings/tags/create",
            // },
          ]
        }
      />
    ),
  },
  {
    path: "/settings/inventory/project",
    component: (
      <UseContainer
        activePage={<Project />}
        headerTitle={"Projects"}
        breadCrumb={"Projects"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Projects_Create",
            Path: "/settings/inventory/project/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/inventory/project/create",
    component: (
      <UseContainer
        activePage={<CreateProject />}
        disableTitleBar={true}
        headerTitle={"Projects"}
        breadCrumb={"Projects"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/inventory/unit",
    component: (
      <UseContainer
        activePage={<Unit />}
        headerTitle={"Units"}
        breadCrumb={"Units"}
        buttons={[
          {
            content: "New",
            RoleName: "Unit_Create",
            Path: "/inventory/unit/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/inventory/booked-unit",
    component: (
      <UseContainer
        activePage={<BookedUnits />}
        headerTitle={"Booked Units"}
        breadCrumb={"Booked Units"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/inventory/unit/create",
    component: (
      <UseContainer
        activePage={<CreateUnit />}
        disableTitleBar={true}
        headerTitle={"Units"}
        breadCrumb={"Units"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/inventory/section",
    component: (
      <UseContainer
        activePage={<Section />}
        headerTitle={"Section"}
        breadCrumb={"Section"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Sections_Create",
            Path: "/settings/inventory/section/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/inventory/section/create",
    component: (
      <UseContainer
        activePage={<CreateSection />}
        disableTitleBar={true}
        headerTitle={"Section"}
        breadCrumb={"Section"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/settings/inventory/category",
    component: (
      <UseContainer
        activePage={<Category />}
        headerTitle={"Category"}
        breadCrumb={"Category"}
        breadCrumb2={"Settings"}
        linkbreadCrumb2={"/settings"}
        buttons={[
          {
            content: "New",
            RoleName: "Category_Create",
            Path: "/settings/inventory/category/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/settings/inventory/category/create",
    component: (
      <UseContainer
        activePage={<CategoryCreateUpdateForm />}
        disableTitleBar={true}
        headerTitle={"Create Category"}
        breadCrumb={"Category / Create Category"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/target",
    component: (
      <UseContainer
        activePage={<Target />}
        headerTitle={"Targets"}
        breadCrumb={"Targets"}
        buttons={[
          {
            content: "New",
            RoleName: "Targets_Create",
            Path: "/target/create",
          },
        ]}
      />
    ),
  },
  {
    path: "/target/create",
    component: (
      <UseContainer
        activePage={<CreateTarget />}
        disableTitleBar={true}
        headerTitle={"Targets"}
        breadCrumb={"Targets"}
        buttons={[]}
      />
    ),
  },
  {
    path: "/assigned-target",
    component: (
      <UseContainer
        activePage={<AssignedTargets />}
        headerTitle={"Assigned Targets"}
        breadCrumb={"Assigned Targets"}
        buttons={[]}
      />
    ),
  },
];
