import { Box, Chip, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CategoryIcon from "@mui/icons-material/Category";
import AppTooltip from "../../../Utils/AppTooltip";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import WindowIcon from "@mui/icons-material/Window";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "20px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
    },
    iconBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };
});
function BookedUnitInfoModal({
  unitInfoModal,
  setunitInfoModal,
  setunitRow,
  unitRow,
}) {
  const { Container, ModalContainer, TableHeader, profile, iconBox } =
    useStyles();
  return (
    <>
      <Modal
        open={unitInfoModal}
        onClose={() => {
          setunitInfoModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Booked Unit Details
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setunitRow(null);
                  setunitInfoModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box className={profile}>
                <Box sx={{ width: "100%" }}>
                  <Box className={iconBox}>
                    <AppTooltip title={"Project"}>
                      <AccountTreeIcon
                        sx={{
                          fontSize: "20px",
                          color: "grey",
                          cursor: "pointer",
                        }}
                      />
                    </AppTooltip>
                    <Typography>{unitRow?.Project?.ProjectName}</Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{ width: "100%", maxWidth: "250px" }}
                      className={iconBox}
                    >
                      <AppTooltip title={"Section"}>
                        <WindowIcon
                          sx={{
                            fontSize: "20px",
                            color: "grey",
                            cursor: "pointer",
                          }}
                        />
                      </AppTooltip>
                      <Typography>{unitRow?.Section?.SectionName}</Typography>
                    </Box>
                    {unitRow?.Category?.CategoryName && (
                      <Box
                        sx={{ width: "100%", maxWidth: "250px" }}
                        className={iconBox}
                      >
                        <AppTooltip title={"Category"}>
                          <CategoryIcon
                            sx={{
                              fontSize: "20px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                          />
                        </AppTooltip>
                        <Typography>
                          {unitRow?.Category?.CategoryName}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{ width: "100%", maxWidth: "250px" }}
                      className={iconBox}
                    >
                      <AppTooltip title={"Unit"}>
                        <ApartmentIcon
                          sx={{
                            fontSize: "20px",
                            color: "grey",
                            cursor: "pointer",
                          }}
                        />
                      </AppTooltip>
                      <Typography>{unitRow?.unit}</Typography>
                    </Box>
                    <Box className={iconBox}>
                      <AppTooltip title={"Price"}>
                        <AttachMoneyIcon
                          sx={{
                            fontSize: "20px",
                            color: "grey",
                            cursor: "pointer",
                          }}
                        />
                      </AppTooltip>
                      <Typography>{unitRow?.totalValue}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Area:</Typography>
                    <Typography
                      sx={{
                        paddingLeft: "100px",
                      }}
                    >
                      {unitRow?.area}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Dimension:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "100px",
                      }}
                    >
                      {unitRow?.dimension}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Net Marla:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "100px",
                      }}
                    >
                      {parseFloat(unitRow?.NetMarla).toLocaleString()}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Net Sqft:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "100px",
                      }}
                    >
                      {parseFloat(unitRow?.NetSqft).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Total Length:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "100px",
                      }}
                    >
                      {unitRow?.DimensionLength}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Total Width:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "100px",
                      }}
                    >
                      {unitRow?.DimensionWidth}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Total Cost:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "100px",
                      }}
                    >
                      {parseFloat(unitRow?.TotalCost).toLocaleString()}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Sale Rate:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "100px",
                      }}
                    >
                      {parseFloat(unitRow?.RatePerMarla).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Tags:</Typography>
                  <div
                    style={{
                      overflowX: "auto",
                      //   whiteSpace: "nowrap",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {unitRow.UnitTags.map((tag, idx) => (
                      <Chip
                        key={idx}
                        label={tag?.Tag?.Name}
                        sx={{
                          margin: "2px",
                          backgroundColor: tag?.Tag?.Color,
                          color: "#fff",
                        }}
                      />
                    ))}
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Person:</Typography>
                    <Typography
                      sx={{
                        paddingLeft: "50px",
                      }}
                    >
                      {unitRow?.person}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Booked Amount:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "50px",
                      }}
                    >
                      {unitRow?.amount?.toLocaleString()}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Duration:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "50px",
                      }}
                    >
                      {unitRow?.duration}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Lead Title:
                  </Typography>
                  <Typography
                    sx={{
                      paddingLeft: "27px",
                    }}
                  >
                    {unitRow?.title}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default BookedUnitInfoModal;
BookedUnitInfoModal.propTypes = {
  unitInfoModal: PropTypes.any,
  setunitInfoModal: PropTypes.any,
  unitRow: PropTypes.any,
  setunitRow: PropTypes.any,
};
