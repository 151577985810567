import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Badge, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment/moment";
import UserFilterDrawer from "./UserFilterDrawer";
import {
  fetchUser,
  getUserDetails,
} from "../../../../Redux/Slice/Settings/UserSlice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { DeleteUserApi } from "../../../../Api/Settings/UsersApi/DeleteUserApi";
import PropTypes from "prop-types";
import { RemoveSCFromInputs } from "../../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function Users({ roles }) {
  const { header } = useStyles();
  const dispatch = useDispatch();
  const { totalRows } = useContext(PageNumberContext);
  const [pagesize, setpagesize] = useState(totalRows);
  const [rows, setrows] = useState([]);
  const [allUser, setallUser] = useState([]);
  const [count, setcount] = useState(0);
  const { User, status } = useSelector(getUserDetails);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showLoading, setshowLoading] = useState(false);
  // console.log(setshowLoading);
  const [filterVal, setfilterVal] = useState({
    name: "",
    email: "",
    status: "",
    createdate: "",
    enddate: "",
  });
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const navigate = useNavigate();
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box>
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filter
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  const deleteUser = async (id) => {
    setshowLoading(true);
    let res = await DeleteUserApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);

    if (res.data.IsSuccess) {
      dispatch(fetchUser());
    }
    setsnackBarData(res.snackBarData);
  };
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    let rowData = [];
    if (User) {
      User.map((user, i) => {
        rowData.push({
          ...user,
          id: i + 1,
          ID: user?.Id,
          Status: user?.Status,
          CreatedAt: user?.CreatedAt
            ? moment(user?.CreatedAt).format("DD-MMM-YYYY")
            : null,
        });
      });
    }
    setrows(rowData);
    setallUser(rowData);
  }, [User]);
  const columns =
    roles?.some((role) => role === "Users_Edit") ||
    roles?.some((role) => role === "Users_Delete")
      ? [
          { field: "id", headerName: "Sr", width: 90, headerClassName: header },
          {
            field: "FullName",
            headerName: "Name",
            width: 200,
            headerClassName: header,
          },
          {
            field: "Email",
            headerName: "Email",
            width: 250,
            headerClassName: header,
          },
          {
            field: "Status",
            headerName: "Status",
            width: 150,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor:
                        celval.row.Status === true ? "green" : "red",
                      borderRadius: "100%",
                    }}
                  ></Box>
                  <Typography>
                    {celval.row.Status === true ? "Active" : "Inactive"}
                  </Typography>
                </Box>
              );
            },
          },
          // {
          //   field: "CreatedAt",
          //   headerName: "Created Date",
          //   width: 150,
          //   headerClassName: header,
          // },
          {
            field: "action",
            headerName: "Action",
            width: 150,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <Box key={i}>
                  {roles?.some((role) => role === "Users_Edit") && (
                    <Tooltip title="Edit">
                      <IconButton
                        key={i}
                        onClick={() => {
                          handleEdit(celval.row.ID);
                        }}
                      >
                        <EditIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {roles?.some((role) => role === "Users_Delete") && (
                    <Tooltip title="Delete">
                      <IconButton
                        key={i}
                        onClick={() => {
                          setselectedId(celval.row.ID);
                          setshowConfirmationIcons(true);
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : [
          { field: "id", headerName: "Sr", width: 90, headerClassName: header },
          {
            field: "FullName",
            headerName: "Name",
            width: 200,
            headerClassName: header,
          },
          {
            field: "Email",
            headerName: "Email",
            width: 250,
            headerClassName: header,
          },
          {
            field: "Status",
            headerName: "Status",
            width: 150,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor:
                        celval.row.Status === true ? "green" : "red",
                      borderRadius: "100%",
                    }}
                  ></Box>
                  <Typography>
                    {celval.row.Status === true ? "Active" : "Inactive"}
                  </Typography>
                </Box>
              );
            },
          },
          // {
          //   field: "CreatedAt",
          //   headerName: "Created Date",
          //   width: 150,
          //   headerClassName: header,
          // },
        ];

  const handleEdit = (val) => {
    navigate("/create/users", { state: val });
  };
  const [drawerOpen, setdrawerOpen] = useState(false);
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  console.log(filterVal?.status);
  useEffect(() => {
    if (
      filterVal?.name === "" &&
      filterVal?.email === "" &&
      filterVal?.status === ""
      // filterVal?.createdate === "" &&
      // filterVal?.enddate === ""
    ) {
      // console.log(salesPersonRef.current?.value);
      setallUser(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(filterVal?.name, "i");
      let regex3 = new RegExp(filterVal?.email, "i");
      let regex4 = new RegExp(String(filterVal?.status), "i");
      // let regex5 = new RegExp(filterVal?.createdate, "i");
      // let regex6 = new RegExp(filterVal?.enddate, "i");

      suggestions = rows?.filter(
        (val) =>
          (filterVal?.name === "" || regex1.test(val?.FullName)) &&
          (filterVal?.email === "" || regex3.test(val?.Email)) &&
          (filterVal?.status === "" || regex4.test(String(val?.Status)))
        // (filterVal?.createdate === "" ||
        //   regex5.test(moment(val?.createdDate).format("DD-MM-YYYY"))) &&
        // (filterVal?.enddate === "" ||
        //   regex6.test(moment(val?.endDate).format("DD-MM-YYYY")))
      );
      setallUser(suggestions);
    }
  }, [filterVal, rows]);
  return (
    <>
      <Box>
        <UserFilterDrawer
          setcount={setcount}
          handleChangeVal={handleChangeVal}
          rows={rows}
          setallUser={setallUser}
          filterVal={filterVal}
          setfilterVal={setfilterVal}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allUser}
            columns={columns}
          />
          {status === "loading" || showLoading ? <Loader /> : null}
        </Box>
      </Box>

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteUser(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete User"
        title={`Are you sure, you want to Delete User?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Users;
Users.propTypes = {
  roles: PropTypes.array,
};
