import {
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
    headingFont: {
      fontWeight: "bold !important",
    },
  };
});
function WonLeadReportModal({ showModal, setShowModal, units }) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    headingFont,
  } = useStyles();

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <Box className={ModalContainer}>
        <Box className={Container}>
          <Box className={TableHeader}>
            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
              Won Lead Details
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(false);
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Box>
          <Box className={container}>
            <Box className={subContainer}>
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography className={headingFont}>Unit</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={headingFont}>
                          Payment Type
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={headingFont}>
                          Received Amount
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={headingFont}>
                          Net Amount
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={headingFont}>
                          Remaining Amount
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {units?.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell>{row?.UnitName}</TableCell>
                        <TableCell>{row?.PaymentType}</TableCell>
                        <TableCell>
                          {row?.ReceivedAmount?.toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {row?.NetAmount?.toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {row?.RemainingAmount?.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default WonLeadReportModal;

WonLeadReportModal.propTypes = {
  showModal: PropTypes.any,
  setShowModal: PropTypes.any,
  units: PropTypes.any,
};
