import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  OutBox: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchOutBox = createAsyncThunk("/Mail/OutboxMails", async () => {
  const response = await axiosInstance.get(`/Mail/OutboxMails`);
  console.log(response);
  return response;
});

const OutBoxSlice = createSlice({
  name: "OutBox",
  initialState,
  reducers: {
    OutBoxAdded: {
      reducer(state, action) {
        console.log(action.payload);
        state.OutBox?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchOutBox.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOutBox.fulfilled, (state, action) => {
        console.log(action.payload.Data);
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.OutBox = action.payload.data.Data;
      })
      .addCase(fetchOutBox.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getOutBoxDetails = (state) => state.OutBox;

export const { OutBoxAdded } = OutBoxSlice.actions;

export default OutBoxSlice.reducer;
