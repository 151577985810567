import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  LeadsDescriptionData: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchLeadsDescriptionData = createAsyncThunk(
  "/Lead/getLeadDescriptions",
  async () => {
    const response = await axiosInstance.get(`/Lead/getLeadDescriptions`);
    return response;
  }
);

const DescriptionSlice = createSlice({
  name: "DescriptionSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchLeadsDescriptionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeadsDescriptionData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.LeadsDescriptionData = action.payload.data.Data;
      })
      .addCase(fetchLeadsDescriptionData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.LeadsDescriptionData = [];
      });
  },
});

export const getLeadsDescription = (state) => state.LeadsDescription;
export default DescriptionSlice.reducer;
