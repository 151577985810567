import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import * as Yup from "yup";
import { AddTargetNote } from "../../../Api/TargetApi/AddTargetNoteApi";
import { useFormik } from "formik";
import { fetchTargets } from "../../../Redux/Slice/TargetSlice/TargetSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "20px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
    },
    iconBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };
});
function AddNotesModal({
  addNoteModal,
  setAddNoteModal,
  setTargetId,
  targetId,
  editnotesRow,
  seteditnotesRow,
}) {
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const { Container, ModalContainer, TableHeader, formContainer } = useStyles();
  const initialValues = {
    title: "",
    description: "",
  };
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required("Please Enter Title"),
    description: Yup.string().required("Please Enter Description"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (editnotesRow) {
      formik.setFieldValue("title", editnotesRow?.NoteTitle);
      formik.setFieldValue("description", editnotesRow?.Description);
    }
  }, [editnotesRow]);
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const data = {
      targetId: targetId,
      notetitle: values?.title,
      description: values.description,
      targetNoteId: editnotesRow?.TargetNoteId || null,
    };

    const response = await AddTargetNote({ ...data });

    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      dispatch(fetchTargets());
      setSubmitting(false);
      resetForm();
      setAddNoteModal(false);
      setTargetId(null);
      seteditnotesRow(null);
    }
  };
  return (
    <>
      <Modal
        open={addNoteModal}
        onClose={() => {
          setAddNoteModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Add Note
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setTargetId && setTargetId(null);
                  setAddNoteModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box className={formContainer}>
                <Box display="flex" gap={1}>
                  <FormControl fullWidth>
                    <Typography>Title</Typography>
                    <TextField
                      name="title"
                      value={formik.values?.title}
                      size={"small"}
                      fullWidth
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.title) &&
                        Boolean(formik.touched.title)
                      }
                      helperText={
                        Boolean(formik.errors.title) &&
                        Boolean(formik.touched.title) &&
                        formik.errors.title
                      }
                    />
                  </FormControl>
                </Box>

                <FormControl fullWidth>
                  <Typography>Description</Typography>
                  <TextField
                    name="description"
                    value={formik.values?.description}
                    size={"small"}
                    multiline
                    rows={4}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.description) &&
                      Boolean(formik.touched.description)
                    }
                    helperText={
                      Boolean(formik.errors.description) &&
                      Boolean(formik.touched.description) &&
                      formik.errors.description
                    }
                  />
                </FormControl>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      {formik.isSubmitting ? (
                        <Box>
                          {/* <Loader/> */}
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button variant="contained" type="submit">
                          <Typography sx={{ fontWeight: "bold" }}>
                            {"Add Note"}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default AddNotesModal;
AddNotesModal.propTypes = {
  addNoteModal: PropTypes.any,
  setAddNoteModal: PropTypes.any,
  targetId: PropTypes.any,
  setTargetId: PropTypes.any,
  editnotesRow: PropTypes.any,
  seteditnotesRow: PropTypes.any,
};
