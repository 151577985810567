import axios from "axios";
// import Cookies from "universal-cookie";

// const cookies = new Cookies();

 //let type = "test";
const BaseUrl =
  process.env.REACT_APP_API_URL_TYPE === "test"
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_API_URL_TYPE === "local"
    ? process.env.REACT_APP_API_URL_LOCAL
    :  process.env.REACT_APP_API_URL_TYPE === "ngrok"
    ? process.env.REACT_APP_API_URL_NGROK
    : "";
const axiosInstance = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
  },
});

// some way of changing it
// export const setToken = (newLocale) => {
//   token = newLocale;
// };

// register a synchronous request interceptor
axiosInstance.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  }),
  null,
  { synchronous: true }
);

const axiosInstanceForm = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
  },
});
axiosInstanceForm.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  }),
  null,
  { synchronous: true }
);
export { axiosInstance, axiosInstanceForm };
