import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import theme from "../../../theme";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import moment from "moment";
import {
  fetchTargetPopulate,
  getTargetDetails,
} from "../../../Redux/Slice/TargetSlice/TargetSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { CreateTargetApi } from "../../../Api/TargetApi/CreateTargetApi";
// import { fetchProducts } from '../../../Redux/Slice/ProductSlice/ProductSlice';

// import Loader from '../../Loader/Loader';
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function CreateTarget() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [groups, setGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();
  const { TargetPopulate, status1 } = useSelector(getTargetDetails);

  const [renderComp, setrenderComp] = useState(false);
  const [selected, setSelected] = useState([
    { projectId: null, categoryId: null },
  ]);
  const [targetDetail, settargetDetail] = useState([
    {
      category: null,
      numberOfPlots: 0,
      project: null,
    },
  ]);
  console.log(targetDetail);

  const initialValues = {
    title: "",
    description: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: "",
    group: null,
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (state && projects?.length > 0 && categories?.length > 0) {
      handleEdit(state);
    }
  }, [projects, categories]);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "title",
        value: values?.Title || "",
      },
    });

    formik.handleChange({
      target: {
        name: "endDate",
        value: moment(values?.EndDate).format("YYYY-MM-DD") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "startDate",
        value: moment(values?.StartDate).format("YYYY-MM-DD") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "description",
        value: values?.Description || "",
      },
    });

    formik.handleChange({
      target: {
        name: "group",
        value: values?.Group || "",
      },
    });
    let arr = [];
    let arr2 = [];
    if (values?.TargetDetail) {
      values?.TargetDetail?.map((target) => {
        arr.push({
          project: {
            ProjectId: target?.Project?.ProjectId,
            ProjectName: target?.Project?.ProjectName,
          },
          category: {
            CategoryId: target?.Category?.CategoryId,
            CategoryName: target?.Category?.CategoryName,
          },
          //   projectId: target?.Project?.ProjectId,
          //   projectName: target?.Project?.ProjectName,
          numberOfPlots: target?.NumberOfPlots,
          targetDetailId: target?.TargetDetailId,
        });
      });
      values?.TargetDetail?.map((target) => {
        arr2.push({
          categoryId: target?.Category?.CategoryId,
          projectId: target?.Project?.ProjectId,
        });
      });
      settargetDetail(arr);
      setSelected(arr2);
    }
  };
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let arr = [];
    targetDetail?.map((item) => {
      arr.push({
        categoryId: item?.category?.CategoryId,
        projectId: item?.project?.ProjectId,
        numberOfPlots: item?.numberOfPlots,
        targetDetailId: item?.targetDetailId || null,
      });
    });
    const data = {
      targetId: state?.ID || null,
      title: values?.title,
      groupId: values.group.Id,
      startDate: values.startDate,
      endDate: values.endDate,
      description: values.description,
      tragetDetails: arr,
    };
    if (state) {
      const response = await CreateTargetApi({ ...data });

      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        // dispatch(fetchProducts());
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
    } else {
      const response = await CreateTargetApi(data);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        // dispatch(fetchProducts());
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
      setSubmitting(false);
    }
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required("Please Enter Title"),
    description: Yup.string().required("Please Enter Description"),
    endDate: Yup.string().required("Please Enter End Date"),
    startDate: Yup.string().required("Please Enter Start Date"),
    group: Yup.object().nullable().required("Please Select Group"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (TargetPopulate) {
      setGroups(TargetPopulate?.Groups);
      setProjects(TargetPopulate?.Projects);
      setCategories(TargetPopulate?.Categories);
    }
  }, [TargetPopulate]);
  useEffect(() => {
    dispatch(fetchTargetPopulate());
  }, [dispatch]);
  const handleDel = (row, i) => {
    let value = JSON.parse(JSON.stringify(targetDetail));
    let value2 = JSON.parse(JSON.stringify(selected));

    value.splice(i, 1);
    value2.splice(i, 1);

    settargetDetail(value);
    setSelected(value2);
  };
  const handleAddField = () => {
    settargetDetail([
      ...targetDetail,
      {
        category: null,
        project: null,
        numberOfPlots: 0,
      },
    ]);
    setSelected([
      ...selected,
      {
        categoryId: null,
        projectId: null,
      },
    ]);
  };
  return (
    <Box className={container}>
      <Box className={subContainer}>
        {status1 === "loading" ? <Loader /> : null}

        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/service"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Target
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state ? "Update Target" : " Create Target"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state ? "Update Target" : " Create Target"}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Start Date</Typography>
                  <TextField
                    name="startDate"
                    value={formik.values?.startDate}
                    size={"small"}
                    type="date"
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.startDate) &&
                      Boolean(formik.touched.startDate)
                    }
                    helperText={
                      Boolean(formik.errors.startDate) &&
                      Boolean(formik.touched.startDate) &&
                      formik.errors.startDate
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>End Date</Typography>
                  <TextField
                    name="endDate"
                    value={formik.values?.endDate}
                    size={"small"}
                    type="date"
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.endDate) &&
                      Boolean(formik.touched.endDate)
                    }
                    helperText={
                      Boolean(formik.errors.endDate) &&
                      Boolean(formik.touched.endDate) &&
                      formik.errors.endDate
                    }
                  />
                </FormControl>
              </Box>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Title</Typography>
                  <TextField
                    name="title"
                    value={formik.values?.title}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.title) &&
                      Boolean(formik.touched.title)
                    }
                    helperText={
                      Boolean(formik.errors.title) &&
                      Boolean(formik.touched.title) &&
                      formik.errors.title
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Group</Typography>
                  <Autocomplete
                    name="group"
                    //   options={["hjehje"]}
                    options={groups}
                    getOptionLabel={(option) => option.Name}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          Boolean(formik.errors.group) &&
                          Boolean(formik.touched.group)
                        }
                        helperText={
                          Boolean(formik.errors.group) &&
                          Boolean(formik.touched.group) &&
                          formik.errors.group
                        }
                        //   name="personId"
                      />
                    )}
                    value={formik.values.group}
                    onChange={(__, value) => {
                      formik.setFieldValue("group", value);
                    }}
                  />
                </FormControl>
              </Box>

              <FormControl fullWidth>
                <Typography>Description</Typography>
                <TextField
                  name="description"
                  value={formik.values?.description}
                  size={"small"}
                  multiline
                  rows={4}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.description) &&
                    Boolean(formik.touched.description)
                  }
                  helperText={
                    Boolean(formik.errors.description) &&
                    Boolean(formik.touched.description) &&
                    formik.errors.description
                  }
                />
              </FormControl>
              <Box>
                <Typography>Target Details</Typography>

                <TableContainer component={Paper} sx={{}}>
                  <Table
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",

                            // color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Project
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            // color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            // color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          No. of Plots
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {targetDetail &&
                        targetDetail?.map((row, i) => {
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell size="small" align="left">
                                <Autocomplete
                                  fullWidth
                                  name="project"
                                  disabled={
                                    row?.category?.CategoryId ? true : false
                                  }
                                  //   options={["hjehje"]}
                                  options={projects}
                                  getOptionLabel={(option) =>
                                    option?.ProjectName || ""
                                  }
                                  size={"small"}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  value={row?.project}
                                  onChange={(__, values) => {
                                    let value = JSON.parse(
                                      JSON.stringify(targetDetail)
                                    );
                                    value[i].project = {
                                      ProjectId: values?.ProjectId,
                                      ProjectName: values?.ProjectName,
                                    };
                                    // value[i].projectId = values?.ProjectId;
                                    // value[i].projectName = values?.ProjectName;

                                    settargetDetail(value);

                                    setrenderComp(!renderComp);
                                  }}
                                />
                              </TableCell>
                              <TableCell size="small" align="left">
                                <Autocomplete
                                  fullWidth
                                  name="category"
                                  disabled={
                                    row?.project?.ProjectId ? false : true
                                  }
                                  options={categories?.filter(
                                    (category) =>
                                      !selected
                                        .filter(
                                          (item) =>
                                            item.projectId ===
                                            row?.project?.ProjectId
                                        )
                                        .some(
                                          (item) =>
                                            item.categoryId ===
                                            category.CategoryId
                                        )
                                  )}
                                  getOptionLabel={(option) =>
                                    option?.CategoryName || ""
                                  }
                                  size={"small"}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  value={row?.category}
                                  onChange={(__, values) => {
                                    let value = JSON.parse(
                                      JSON.stringify(targetDetail)
                                    );
                                    let select = JSON.parse(
                                      JSON.stringify(selected)
                                    );
                                    value[i].category = {
                                      CategoryName: values?.CategoryName,
                                      CategoryId: values?.CategoryId,
                                    };

                                    settargetDetail(value);
                                    select[i].projectId =
                                      value[i]?.project?.ProjectId;
                                    select[i].categoryId = values?.CategoryId;
                                    setSelected(select);
                                    setrenderComp(!renderComp);
                                  }}
                                />
                              </TableCell>
                              <TableCell size="small" align="left">
                                <TextField
                                  value={row?.numberOfPlots}
                                  type={"number"}
                                  name="numberOfPlots"
                                  size="small"
                                  onChange={(e) => {
                                    let value = JSON.parse(
                                      JSON.stringify(targetDetail)
                                    );
                                    value[i].numberOfPlots = Number(
                                      e.target.value
                                    );
                                    settargetDetail(value);
                                    setrenderComp(!renderComp);
                                  }}
                                />
                              </TableCell>
                              <TableCell size="small" align="left">
                                {targetDetail?.length > 1 ? (
                                  <DeleteForeverIcon
                                    fontSize="small"
                                    sx={{
                                      color: (theme) =>
                                        theme?.palette?.primary?.delete,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleDel(row, i)}
                                  />
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ width: "fit-content" }}>
                <Button
                  size="large"
                  color="info"
                  sx={{ borderRadius: "8px" }}
                  onClick={handleAddField}
                >
                  + Add More
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreateTarget;
