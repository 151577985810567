
import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  FormControl,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Autocomplete,
  Chip,
  Button,
  CircularProgress,
  IconButton,
  // ListItem,
} from "@mui/material";
import { useFormik } from "formik";
import AppTooltip from "../../../Utils/AppTooltip";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HistoryIcon from "@mui/icons-material/History";
import * as Yup from "yup";
import {
  fetchMettingTypes,
  getMeetingsDetails,
  fetchMeetingsSingle,
  fetchPopulateParticipants,
} from "../../../Redux/Slice/MeetingsSlice/MeetingsSlice";
import { CreateMeetingApi } from "../../../Api/MeetingsApi/CreateMeetingApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { UpdateMeetingApi } from "../../../Api/MeetingsApi/UpdateMeetingApi";
import { TypeAddApi } from "../../../Api/MeetingsApi/TypeAddApi";
import moment from 'moment';
function MeetingsCreate() {
  const { container, subContainer, formContainer } = useStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { MeetingsTypes, SingleMetting, PopulateParticipant } = useSelector(getMeetingsDetails);
  const [meetingType, setMeetingType] = useState([]);
  const [participantType, setParticipantType] = useState([]);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [existType, setExistType] = useState(true);
  const [addTypeValue, setAddTypeValue] = useState(""); 
  const [loader,setLoader]=useState(false);
  // Function to handle the type API call
 
  const handleAddType = async () => {
    setLoader(true);
    if (!addTypeValue) {
      console.error("Please enter a type before adding.");
      return;
    }
    try {
      const response = await TypeAddApi({
        name: addTypeValue,
      });
      setsnackBarData(response.snackBarData);
      if (response?.data.IsSuccess) {
        console.log("Type added successfully:", response);
        setLoader(false);
        setExistType(true);
        dispatch(fetchMettingTypes());
        const newType = response.data.Data; // Assuming the response contains the new type object
        if (newType) {
          formik.setFieldValue('generalMeetingTypeId', newType);
        }
        setAddTypeValue(""); 
      }
    } catch (error) {
      console.error("Failed to add type:", error);
    }
  };
  useEffect(() => {
    dispatch(fetchMettingTypes());
    dispatch(fetchPopulateParticipants());
    if (id) {
      dispatch(fetchMeetingsSingle(id));
    }
  }, [dispatch]);
  const handleNavigate = () => {
    navigate(-1);
  };
  useEffect(() => {
    const MeetingTypedata = [];
    MeetingsTypes?.map((item) => {
      MeetingTypedata.push({
        id: item.Id,
        name: item.Name
      });
    });
    setMeetingType(MeetingTypedata);
  }, [MeetingsTypes]);
  useEffect(() => {
    const MeetingTypedata = [];
    PopulateParticipant?.map((item) => {
      MeetingTypedata.push({
        userid: item.Id,
        name: item.FullName
      });
    });
    setParticipantType(MeetingTypedata);
  }, [PopulateParticipant]);

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    startDate: Yup.date().required('Start date is required'),
    duration: Yup.number().required('Time is required').positive('Time must be a positive number')
      .integer('Time must be an integer'),
    priority: Yup.string().required('Priority is required'),
    generalMeetingTypeId: Yup.string().required('Type is required'),
    agenda: Yup.string().required('Agenda is required'),
    generalMeetingParticipants: Yup.array().min(1, 'At least one participant is required'),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      startDate: "",
      duration: 0,
      generalMeetingTypeId: "",
      location: "",
      agenda: "",
      notes: "",
      priority: "",
      generalMeetingParticipants: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const meetingData = {
        ...values,
        generalMeetingParticipants: values.generalMeetingParticipants.map(participant => ({
          userid: participant.userid,
        })),
      };

      if (id) {
        meetingData.id = id;
      }

      try {
        const response = id
          ? await UpdateMeetingApi(meetingData, id)
          : await CreateMeetingApi(meetingData);

        setsnackBarData(response.snackBarData);

        if (response?.data.IsSuccess) {
          resetForm();
          navigate(-1);
        }
      } catch (error) {
        console.error(`There was an error ${id ? "updating" : "creating"} the meeting!`, error);
      } finally {
        setSubmitting(false);
        resetForm();
      }
    },
  });
  useEffect(() => {
    if (id && SingleMetting) {
      formik.setValues({
        // id: SingleMetting.Id,
        title: SingleMetting.Title,
        startDate: SingleMetting.StartDate ? moment(SingleMetting.StartDate).format('YYYY-MM-DDTHH:mm') : '',
        duration: SingleMetting.Duration,
        generalMeetingTypeId: SingleMetting.GeneralMeetingTypeId,
        location: SingleMetting.Location,
        agenda: SingleMetting.Agenda,
        notes: SingleMetting.Notes,
        priority: SingleMetting.Priority,
        generalMeetingParticipants: SingleMetting?.Participant?.map((item) => ({
          userid: item.Userid,
        })),
      });
    }
  }, [SingleMetting, id]);
  console.log("formik vlaues",formik.values);
  return (
    <>
      <Box className={container}>
        <Box className={subContainer}>
          <Box>
            <Box role="presentation" onClick={(e) => e.preventDefault()}>
              <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
                <Link to="/dashboard" style={{ color: "#33A5FC", textDecoration: "none" }}>
                  Dashboard
                </Link>
                <Link to="/Meetings" style={{ color: "#33A5FC", textDecoration: "none" }}>
                  Meeting
                </Link>
                <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {id ? "Edit Meeting": "Create Meeting"}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h5" sx={{ fontWeight: 400 }}>
              {id ? "Edit Meeting": "Create Meeting"}
            </Typography>
          </Box>
          <Paper elevation={3} sx={{ padding: "20px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box className={formContainer}>
                <FormControl fullWidth>
                  <Typography>Title</Typography>
                  <TextField
                    name="title"
                    value={formik.values.title}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Type</Typography>
                  {existType ? (
                    <>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          name="generalMeetingTypeId"
                          value={formik.values.generalMeetingTypeId}
                          size="small"
                          select
                          fullWidth
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.generalMeetingTypeId && Boolean(formik.errors.generalMeetingTypeId)}
                          helperText={formik.touched.generalMeetingTypeId && formik.errors.generalMeetingTypeId}
                        >
                          <MenuItem value="">Select Type</MenuItem>
                          {meetingType?.map((type, i) => (
                            <MenuItem key={i} value={type?.id}>
                              {type?.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <AppTooltip title="Add New">
                          <IconButton
                            size="small"
                            onClick={() => setExistType(false)}
                          >
                            <AddCircleIcon fontSize="small" />
                          </IconButton>
                        </AppTooltip>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>

                      <Box sx={{ width: '50%' }}>
                        <FormControl fullWidth>
                          <TextField
                            name="generalMeetingTypeId"
                            value={formik.values.generalMeetingTypeId}
                            size="small"
                            select
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.generalMeetingTypeId && Boolean(formik.errors.generalMeetingTypeId)}
                            helperText={formik.touched.generalMeetingTypeId && formik.errors.generalMeetingTypeId}
                          >
                            <MenuItem value="">Select Type</MenuItem>
                            {meetingType?.map((type, i) => (
                              <MenuItem key={i} value={type?.id}>
                                {type?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Box>
                      <Box sx={{ width: '50%' }}>
                        <FormControl fullWidth>
                          <TextField
                            name="AddType"
                            type="text"
                            size="small"
                            value={addTypeValue}
                            onChange={(e) => setAddTypeValue(e.target.value)} // Update state on change
                            placeholder="Enter new type"
                          />
                        </FormControl>
                      </Box>
                      {loader ? (
                        <Box>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                        sx={{ alignSelf: "center" }}
                        variant="contained"
                        size="small"
                        onClick={handleAddType} 
                      >
                        Add
                      </Button>
                      )}
                      <AppTooltip title="Exist type">
                        <IconButton
                          size="small"
                          onClick={() => setExistType(true)}
                        >
                          <HistoryIcon fontSize="small" />
                        </IconButton>
                      </AppTooltip>

                    </Box>
                  )}
                </FormControl>




                <Box sx={{ width: '100%', display: 'flex', gap: '10px' }}>
                  <Box sx={{ width: '50%' }}>
                    <FormControl fullWidth>
                      <Typography>Start Date and Time</Typography>
                      <TextField
                        name="startDate"
                        type="datetime-local"
                        size="small"
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.touched.startDate && formik.errors.startDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ width: '50%' }}>
                    <FormControl fullWidth>
                      <Typography>Time In Minutes</Typography>
                      <TextField
                        name="duration"
                        type="number"
                        size="small"
                        value={formik.values.duration}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.duration && Boolean(formik.errors.duration)}
                        helperText={formik.touched.duration && formik.errors.duration}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <FormControl fullWidth>
                  <Typography>Agenda</Typography>
                  <TextField
                    name="agenda"
                    value={formik.values.agenda}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.agenda && Boolean(formik.errors.agenda)}
                    helperText={formik.touched.agenda && formik.errors.agenda}
                  />
                </FormControl>
                <Box sx={{ width: '100%' }}>
                  <FormControl fullWidth>
                    <Typography>Periority</Typography>
                    <TextField
                      name="priority"
                      value={formik.values.priority}
                      size="small"
                      select
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.priority && Boolean(formik.errors.priority)}
                      helperText={formik.touched.priority && formik.errors.priority}
                    >
                      <MenuItem value="">Select Priority</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="low">Low</MenuItem>
                    </TextField>
                  </FormControl>
                </Box>
                <FormControl fullWidth>
                  <Typography>Location</Typography>
                  <TextField
                    name="location"
                    value={formik.values.location}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Description</Typography>
                  <TextField
                    name="notes"
                    value={formik.values.notes}
                    multiline
                    rows={4}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Participants</Typography>
                  <Autocomplete
                    multiple
                    name=" generalMeetingParticipants"
                    options={participantType}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={formik.touched.generalMeetingParticipants && Boolean(formik.errors.generalMeetingParticipants)}
                        helperText={formik.touched.generalMeetingParticipants && formik.errors.generalMeetingParticipants}
                      />
                    )}
                    value={participantType.filter(option => formik.values?.generalMeetingParticipants?.some(p => p.userid === option.userid))}
                    onChange={(event, value) => formik.setFieldValue("generalMeetingParticipants", value.map(option => ({ userid: option.userid })))}
                    onBlur={() => formik.setFieldTouched("generalMeetingParticipants", true)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />

                </FormControl>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <Button onClick={handleNavigate}>
                        <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                      </Button>
                    </Box>
                    <Box>
                      {formik.isSubmitting ? (
                        <Box>
                          {/* <Loader/> */}
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button variant="contained" type="submit">
                          <Typography sx={{ fontWeight: "bold" }}>
                            Submit
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default MeetingsCreate;

const useStyle = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    width: "100%",
    maxWidth: "1000px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    alignItems: "flex-start",
  },
}));

