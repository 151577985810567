import React, { useContext, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import theme from "../../../../theme";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useDispatch, useSelector } from "react-redux";
import InventoryIcon from "@mui/icons-material/Inventory";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import Cookies from "universal-cookie";
import {
  fetchBusiness,
  getBusinessDetails,
} from "../../../../Redux/Slice/BusinessSlice/BusinessSlice";
import Loader from "../../../Loader/Loader";
import { UpdateBusiness } from "../../../../Api/Settings/Business/UpdateBusiness";

const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});
function Business() {
  const { Business, status } = useSelector(getBusinessDetails);

  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  // const cookies = new Cookies();
  const initialValues = {
    name: "",
    address: "",
    phone: "",
    email: "",
    password: "",
    image: null,
    imageName: "",
  };
  useEffect(() => {
    if (Business) {
      handleEdit(Business);
    }
  }, [Business]);
  useEffect(() => {
    dispatch(fetchBusiness(sessionStorage.getItem("BusinessId")));
  }, [dispatch]);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "name",
        value: values?.BusinessName || "",
      },
    });
    formik.handleChange({
      target: {
        name: "address",
        value: values?.Address || "",
      },
    });
    formik.handleChange({
      target: {
        name: "email",
        value: values?.Email || "",
      },
    });
    formik.handleChange({
      target: {
        name: "phone",
        value: values?.PhoneNumber || "",
      },
    });

    formik.handleChange({
      target: {
        name: "imageName",
        value: values?.BusinessImageName
          ? values?.BusinessImageName
          : null || "",
      },
    });

    const imagePreview = document.getElementById("image-preview");
    if (values.BusinessImage) {
      imagePreview.src = values.BusinessImage;
    }
  };
  const navigate = useNavigate();
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let formData = new FormData();
    formData.append("BusinessName", values.name);
    formData.append("Address", values.address);
    if (values.image) {
      formData.append("LogoName", values.imageName);
    }
    formData.append("Logo", values.image);
    formData.append("PhoneNumber", values.phone);

    formData.append("BusinessId", Business.BusinessId);

    const response = await UpdateBusiness(formData);
    setsnackBarData(response.snackBarData);
    if (response?.data.IsSuccess) {
      setSubmitting(false);
      // resetForm();
      // dispatch(fetchBusiness(sessionStorage.getItem));
    }

    setSubmitting(false);
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Business Name"),
    address: Yup.string().required("Please Enter Address"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const handleClearImage = () => {
    // formik.setValues({ image: null });
    formik.setFieldValue("image", null);
    formik.setFieldValue("imageName", null);
    document.getElementById("imageInput").value = null;
    const imagePreview = document.getElementById("image-preview");
    if (imagePreview) {
      imagePreview.src = null;
    }
  };
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Typography
          variant="h5"
          sx={{ textAlign: "left", fontWeight: 400 }}
        >
          Update Business
        </Typography>
        <Paper
          elevation={3}
          sx={{ padding: "20px" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={0}
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="imageInput"
                    type="file"
                    onChange={(event) => {
                      const selectedFile = event.currentTarget.files[0];

                      formik.setFieldValue("image", selectedFile);

                      const imagePreview =
                        document.getElementById("image-preview");
                      if (imagePreview && selectedFile) {
                        imagePreview.src = URL.createObjectURL(selectedFile);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {(formik.values.image || formik.values.imageName) && (
                    <Button
                      onClick={handleClearImage}
                      style={{
                        // position: "absolute",
                        top: "15px",
                        left: "45px",
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      color="primary"
                    >
                      <HighlightOffIcon />
                    </Button>
                  )}
                  <Box
                    mt={0}
                    alignItems="center"
                  >
                    <img
                      id="image-preview"
                      alt="Not Selected"
                      width="80px"
                      height="80px"
                      style={{
                        display:
                          formik.values.image || formik.values.imageName
                            ? ""
                            : "none",
                        borderRadius: "100%",
                      }}
                    />
                    <Avatar
                      sx={{
                        display:
                          formik.values.image || formik.values.imageName
                            ? "none"
                            : "",
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      <InventoryIcon fontSize="large" />
                    </Avatar>
                  </Box>

                  <label htmlFor="imageInput">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        mt: 1,

                        textTransform: "none",
                      }}
                    >
                      {"Update Image"}
                    </Button>
                  </label>
                  {formik.touched.image ? (
                    <Typography color="error">{formik.errors.image}</Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </FormControl>
              <Box
                display="flex"
                gap={1}
              >
                <FormControl fullWidth>
                  <Typography>Name</Typography>
                  <TextField
                    name="name"
                    value={formik.values?.name}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name)
                    }
                    helperText={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name) &&
                      formik.errors.name
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Phone</Typography>
                  <TextField
                    name="phone"
                    value={formik.values?.phone}
                    size={"small"}
                    type="number"
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.phone) &&
                      Boolean(formik.touched.phone)
                    }
                    helperText={
                      Boolean(formik.errors.phone) &&
                      Boolean(formik.touched.phone) &&
                      formik.errors.phone
                    }
                  />
                </FormControl>
              </Box>
              <FormControl fullWidth>
                <Typography>Address</Typography>
                <TextField
                  name="address"
                  value={formik.values?.address}
                  size={"small"}
                  multiline
                  rows={4}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.address) &&
                    Boolean(formik.touched.address)
                  }
                  helperText={
                    Boolean(formik.errors.address) &&
                    Boolean(formik.touched.address)
                      ? formik.errors.address
                      : ""
                  }
                />
              </FormControl>
              <Box
                display="flex"
                gap={1}
              >
                <FormControl fullWidth>
                  <Typography>Email</Typography>
                  <TextField
                    disabled
                    name="email"
                    value={formik.values?.email}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.email) &&
                      Boolean(formik.touched.email)
                    }
                    helperText={
                      Boolean(formik.errors.email) &&
                      Boolean(formik.touched.email)
                        ? formik.errors.email
                        : ""
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Password</Typography>
                  <TextField
                    name="password"
                    disabled
                    value="********"
                    size={"small"}
                    type="password"
                    fullWidth
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button
                        variant="contained"
                        type="submit"
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
      {status === "loading" ? <Loader /> : null}
    </Box>
  );
}

export default Business;
