import React, { useContext, useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  useMediaQuery,
  useScrollTrigger,
  Avatar,
  // MenuItem,
  // Divider,
  // ListItemIcon,
  // Menu,
  Badge,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { FacebookPageDetailContext } from "../../../Context/FacebookPageDetailContext/FacebookPageDetailContext";
import AppTooltip from "../../../Utils/AppTooltip";
// import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import {
  fetchUserProfile,
  getUserProfile,
} from "../../../Redux/Slice/Profile/ProfileSlice";
// import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import { NotificationContext } from "../../../Context/NotificationContext/NotificationContext";
// import SellIcon from "@mui/icons-material/Sell";
// import SignalRContext from "../../../Context/SignalRContext/SignalRContext";
import NotificationsDrawer from "./NotificationsDrawer";
import {
  fetchNotificationsbyUser,
  getNotifications,
} from "../../../Redux/Slice/Notifications/GetNotification";

const useStyles = makeStyles(() => {
  return {
    appbar_main: {
      padding: "0px !important",
      display: "flex",
      justifyContent: "space-between",
    },
    topbar_title: {
      height: "100%",
      color: theme.palette.secondary.main,
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        // display: "none",
      },
    },

    topbar_username: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  };
});
const ScrollHandler = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger ? "white" : "transparent",
    },
  });
};

const TitleBar = ({
  headerTitle,
  buttons,
  roles,
  setOpen,
  openSidebar,
  breadCrumb,
  breadCrumb2,
  linkbreadCrumb2,
}) => {
  const { appbar_main, topbar_title } = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:650px)");
  const { pageData } = useContext(FacebookPageDetailContext);
  const navigate = useNavigate();
  // const cookies = new Cookies();
  // let businessType = cookies.get("BusinessType");
  let businessType = sessionStorage.getItem("BusinessType");
  const handleClick = (obj) => {
    console.log(obj);
    navigate(obj?.Path);
  };
  const { UserProfile } = useSelector(getUserProfile);
  const { Notifications } = useSelector(getNotifications);

  const { Id } = UserProfile;
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [notification, setNotifications] = useState(null);
  console.log("Notifications", Notifications);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (connection) {
  //     // connection
  //     //   .start()
  //     //   .then(() => console.log("SignalR Connected"))
  //     //   .catch((err) => console.error("SignalR Connection Error: ", err));

  //     connection.on("ReceiveNotification", (message) => {
  //       setNotifications(message);
  //     });
  //   }
  // }, [connection]);

  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(fetchNotificationsbyUser());
  }, [dispatch]);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  // console.log("open", open);
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const NotiCount= Notifications?.filter((noti)=> noti.Status==="Pending");
  const Count = NotiCount?.length;
  return (
    <>
      <ScrollHandler threshold={0}>
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "5px",
            boxShadow: "none !important",
          }}
        >
          <Toolbar className={appbar_main}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                padding: "0px 10px 0px 10px",
              }}
            >
              <Box role="presentation" onClick={(e) => e.preventDefault()}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  style={{ fontSize: "12px" }}
                >
                  <Link
                    to="/dashboard"
                    style={{ color: "#33A5FC", textDecoration: "none" }}
                    className="link"
                    underline="hover"
                  >
                    Dashboard
                  </Link>
                  {breadCrumb2 && (
                    <Link
                      to={linkbreadCrumb2}
                      style={{ color: "#33A5FC", textDecoration: "none" }}
                      className="link"
                      underline="hover"
                    >
                      {breadCrumb2}
                    </Link>
                  )}

                  <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                    {/* {headerTitle == "Dashboard" ? "Home" :  headerTitle=="FACEBOOK PAGE"?pageData?.name:headerTitle} */}
                    {breadCrumb == "Dashboard"
                      ? "Home"
                      : breadCrumb == "FACEBOOK PAGE"
                      ? pageData?.name
                      : breadCrumb === "Organizations"
                      ? businessType === "RealState"
                        ? "Developers"
                        : breadCrumb
                      : breadCrumb}
                  </Typography>
                </Breadcrumbs>
                {headerTitle == "FACEBOOK PAGE" ? null : (
                  // <Box sx={{display:"flex",gap:"15px",alignItems:"center",paddingTop:"20px"}}>
                  //   <Box sx={{width:"100%",maxWidth:"50px",borderRadius:"50%"}}>
                  //     <img src={pageData?.imageUrl} alt="Image" style={{width:"100%",height:"100%",borderRadius:"50%"}} />
                  //     </Box>
                  //   <Typography variant="h5" noWrap href="/" className={topbar_title}>
                  //   {pageData?.name}
                  // </Typography>
                  //   </Box>
                  <Typography
                    variant="h5"
                    noWrap
                    href="/"
                    className={topbar_title}
                  >
                    {headerTitle === "Organizations"
                      ? businessType === "RealState"
                        ? "Developers"
                        : headerTitle
                      : headerTitle}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "flex", gap: "20px" }}>
                {/* <AppTooltip title="Notifications">
                <Badge
                  color="secondary"
                  overlap="circular"
                  badgeContent={notifications?.length}
                  showZero
                >
                  <IconButton
                    onClick={handleClickNoti}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.main,
                        width: 40,
                        height: 40,
                      }}
                    >
                      <NotificationsIcon
                        sx={{ color: (theme) => theme.palette.background.main }}
                      />
                    </Avatar>
                  </IconButton>
                </Badge>
              </AppTooltip> */}
                {/* <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      width: "500",
                      maxHeight: "80%",
                      overflowY: "auto",
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Box
                    sx={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" noWrap>
                      Notifications
                    </Typography>
                    <Button variant="text">Mark as Read</Button>
                  </Box>
                  <Divider />
                  {notifications?.map((notification, index) => (
                    <MenuItem
                      key={index}
                      onClick={handleClose}
                      sx={[
                        {
                          "&:hover": {
                            backgroundColor: "#fff9c4",
                            cursor: "default",
                          },
                        },
                        {
                          backgroundColor: "#fff9c4",
                          margin: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      ]}
                    >
                      <ListItemIcon fontSize="small">
                        <SellIcon sx={{ color: theme.palette.primary.main }} />
                      </ListItemIcon>
                      <div style={{ flex: 1 }}>
                        <Typography
                          width={500}
                          style={{ overflow: "hidden", whiteSpace: "normal" }}
                        >
                          {notification?.message
                            ? notification?.message
                            : notification}
                        </Typography>
                      </div>
                      <ListItemIcon fontSize="small">
                        <MarkChatReadIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      </ListItemIcon>
                    </MenuItem>
                  ))}
                </Menu> */}
                <AppTooltip title="Notifications">
                  <Badge
                    color="secondary"
                    overlap="circular"
                    badgeContent={Count}
                    showZero
                  >
                    <IconButton
                      onClick={() => {
                        setdrawerOpen(true);
                        setNotifications(Notifications);
                      }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar
                        sx={{
                          bgcolor: (theme) => theme.palette.primary.main,
                          width: 40,
                          height: 40,
                        }}
                      >
                        <NotificationsIcon
                          sx={{
                            color: (theme) => theme.palette.background.main,
                          }}
                        />
                      </Avatar>
                    </IconButton>
                  </Badge>
                </AppTooltip>
                <AppTooltip title="Profile">
                  <IconButton
                    onClick={() => {
                      navigate("/profile");
                    }}
                    size="small"
                    sx={{ p: 0 }}
                    className="ml-2"
                  >
                    <Avatar
                      alt="Adison Jeck"
                      src={UserProfile?.Image ? UserProfile?.Image : null}
                      sx={{
                        width: 40,
                        height: 40,
                        border: "0.1px solid",
                        borderColor: (theme) => theme.palette.primary.main,
                      }}
                    />
                  </IconButton>
                </AppTooltip>
                <Box sx={{ mt: "8px" }}>
                  {buttons &&
                    buttons?.map((button, i) => {
                      if (roles?.some((role) => role === button?.RoleName)) {
                        return (
                          <Box key={i}>
                            <Button
                              variant="contained"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClick(button);
                              }}
                            >
                              {button?.content}
                            </Button>
                          </Box>
                        );
                      }
                    })}
                </Box>
              </Box>
            </Box>
            {isSmallScreen && (
              <IconButton onClick={() => setOpen(!openSidebar)}>
                <DehazeIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        {/* {open && (
        <NotificationMenu
          open={open}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
      )} */}
      </ScrollHandler>
      <NotificationsDrawer
        setdrawerOpen={setdrawerOpen}
        Id={Id}
        Notifications={notification}
        drawerOpen={drawerOpen}
      />
    </>
  );
};
export default TitleBar;

TitleBar.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  buttons: PropTypes.any,
  breadCrumb: PropTypes.any,
  breadCrumb2: PropTypes.any,
  linkbreadCrumb2: PropTypes.any,
  roles: PropTypes.array,
  setOpen: PropTypes.func,
  openSidebar: PropTypes.any,
};
