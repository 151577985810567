import {
  Box,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "20px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
    },
    iconBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };
});
function SectionInfoModal({
  sectionInfoModal,
  setsectionInfoModal,
  setsectionRow,
  sectionRow,
}) {
  const { Container, ModalContainer, TableHeader } = useStyles();
  return (
    <>
      <Modal
        open={sectionInfoModal}
        onClose={() => {
          setsectionInfoModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Section Info
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setsectionRow(null);
                  setsectionInfoModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box
                sx={{
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Discount Details:
                  </Typography>
                  <TableContainer
                    sx={{
                      maxHeight: "300px",
                    }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: "bold",

                              // color: (theme) => theme.palette.grey["500"],
                            }}
                          >
                            Payment
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: "bold",
                              // color: (theme) => theme.palette.grey["500"],
                            }}
                          >
                            Discount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sectionRow?.discounts &&
                          sectionRow?.discounts?.map((row, i) => {
                            return (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  {row?.PaidPer}%
                                </TableCell>
                                <TableCell align="left">
                                  {row?.DiscountPer}%
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default SectionInfoModal;
SectionInfoModal.propTypes = {
  sectionInfoModal: PropTypes.any,
  setsectionInfoModal: PropTypes.any,
  sectionRow: PropTypes.any,
  setsectionRow: PropTypes.any,
};
