import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Units: [],
  UnitsPopulate: [],
  BookUnitPopulate: [],
  BookedUnits: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchUnits = createAsyncThunk("/Units/GetUnits", async () => {
  const response = await axiosInstance.get(`/Units/GetUnits`);
  return response;
});

export const fetchUnitPopulate = createAsyncThunk(
  "/Units/PopulateUnit",
  async () => {
    const response = await axiosInstance.get(`/Units/PopulateUnit`);
    return response;
  }
);
export const fetchBookUnit = createAsyncThunk(
  "/Units/GetBookedUnits",
  async () => {
    const response = await axiosInstance.get(`/Units/GetBookedUnits`);
    return response;
  }
);

export const fetchBookUnitPopulate = createAsyncThunk(
  "/Units/PopulateUnitBooked",
  async () => {
    const response = await axiosInstance.get(`/Units/PopulateUnitBooked`);
    return response;
  }
);

const UnitSlice = createSlice({
  name: "Units",
  initialState,
  reducers: {
    UnitsAdded: {
      reducer(state, action) {
        state.Units?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchUnits.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUnits.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Units = action.payload.data.Data;
      })
      .addCase(fetchUnits.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchUnitPopulate.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchUnitPopulate.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UnitsPopulate = action.payload.data.Data;
      })
      .addCase(fetchUnitPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status1 = "failed";
      })
      .addCase(fetchBookUnitPopulate.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchBookUnitPopulate.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.BookUnitPopulate = action.payload.data.Data;
      })
      .addCase(fetchBookUnitPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status1 = "failed";
        state.BookedUnits = [];
      })
      .addCase(fetchBookUnit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBookUnit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.BookedUnits = action.payload.data.Data;
      })
      .addCase(fetchBookUnit.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
        state.BookedUnits = [];
      });
  },
});

export const getUnitsDetails = (state) => state.Units;

export const { UnitsAdded } = UnitSlice.actions;

export default UnitSlice.reducer;
