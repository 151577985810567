import { axiosInstance } from "../../Utils/AxiosInstance";

export const AddUpdateLeadDescriptionApi = async (obj) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(`/Lead/LeadDescription`, obj);

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const DeleteLeadDescriptionApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(
      `/Lead/Description/Delete?Id=${id}`
    );

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
