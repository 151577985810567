import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  DashboardData: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchDashboardData = createAsyncThunk(
  "/Dashbord",
  async ({ startDate, endDate }) => {
    const response = await axiosInstance.get(
      `/Dashbord?startDate=${startDate}&endDate=${endDate}`
    );
    console.log(response.data);
    return response;
  }
);

const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {
    // DashboardAdded: {
    //   reducer(state, action) {
    //     state.DashboardData?.push(action.payload);
    //   },
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchDashboardData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.DashboardData = action.payload.data;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getDashboardDetails = (state) => state.Dashboard;

// export const { DashboardAdded } = DashboardSlice.actions;

export default DashboardSlice.reducer;
