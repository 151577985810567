import {
  Box,
  // Button,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment/moment";
import React, { useContext, useState } from "react";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import { DeleteLeadNoteApi } from "../../../../../Api/LeadsApi/DeleteLeadNoteApi";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import theme from "../../../../../theme";
import EditModal from "./EditModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppConfirmationDialog from "../../../../AppConfirmationDialog/AppConfirmationDialog";
import { fetchTargets } from "../../../../../Redux/Slice/TargetSlice/TargetSlice";
import ActivityInfoModal from "../../../../Activities/ActivityInfoModal";

const useStyle = makeStyles(() => {
  return {
    noteBox: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      padding: "10px",
      width: "100%",
    },
    notesubboxMain: {
      display: "flex",
      gap: "40px",
      width: "100%",
      padding: "0px 10px 0px 20px",
    },
    iconlineMain: {
      width: "2px",
      minHeight: "100%",
      backgroundColor: theme.palette.primary.main,
      position: "relative",
    },
    iconBox: {
      width: "40px",
      height: "40px",
      border: `1px solid ${theme?.palette.primary.main}`,
      transform: "translate(-50%,0%)",
      position: "absolute",
      top: "0%",
      left: "50%",
      color: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white !important",
    },
  };
});

function LeadNotes({ singleLead, roles }) {
  const { noteBox, notesubboxMain, iconlineMain, iconBox } = useStyle();
  const [loading, setloading] = useState(false);
  const [opens, setOpens] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [activityRow, setactivityRow] = useState(null);
  const [activityInfoModal, setactivityInfoModal] = useState(false);
  const [notesRow, setnotesRow] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event, note) => {
    setAnchorEl(event.currentTarget);
    setnotesRow(note);
  };
  // useEffect(()=>{
  //   dispatch(fetchLeadsSingle(singleLeadId));
  // },[dispatch]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClosed = () => {
    setOpens(false);
  };
  const deleteNote = async () => {
    setloading(true);
    const response = await DeleteLeadNoteApi(notesRow?.LeadNoteId);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setloading(false);
      setnotesRow(null);
      handleClosed();
      dispatch(fetchTargets());
    }
    setloading(false);
  };
  return (
    <>
      {singleLead?.leadNote?.map((note, i) => {
        return (
          <Box key={i} className={notesubboxMain}>
            <Box className={iconlineMain}>
              <Box className={iconBox}>
                <EventNoteIcon sx={{}} />
              </Box>
            </Box>
            <Box sx={{ width: "100%", paddingBottom: "10px" }}>
              <Paper key={i} elevation={3} className={noteBox}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Note Added</Typography>
                  <MoreVertIcon
                    onClick={(e) => {
                      if (roles?.some((role) => role === "Leads_Edit")) {
                        handleClick(e, note);
                      }
                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                      display:
                        singleLead?.Status === "Won" ||
                        singleLead?.Status === "Lost"
                          ? "none"
                          : "",
                    }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      sx: {
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                        setshowModal(true);
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleClickOpen();
                      }}
                    >
                      Remove
                    </MenuItem>
                    <MenuItem
                      key={i}
                      onClick={() => {
                        setactivityInfoModal(true);
                        setactivityRow(note);
                      }}
                    >
                      View Detail
                    </MenuItem>
                  </Menu>
                </Box>
                <Box sx={{ backgroundColor: "#fff9c4", padding: "10px" }}>
                  <Typography>{note?.Note}</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    {moment(note?.CreatedAt).format("Do MMMM YYYY, h:mm a")}
                  </Typography>
                  <Typography sx={{ cursor: "pointer", color: "#0e90d9" }}>
                    {note?.FullName}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Box>
        );
      })}
      {/* <Dialog
        open={opens}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Delete <b>{notesRow?.Note}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                setnotesRow(null);
                handleClosed();
              }}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  deleteNote();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}
      {activityInfoModal && (
        <ActivityInfoModal
          activityInfoModal={activityInfoModal}
          setactivityInfoModal={setactivityInfoModal}
          setactivityRow={setactivityRow}
          activityRow={activityRow}
        />
      )}
      <AppConfirmationDialog
        open={opens}
        onDeny={() => {
          setnotesRow(null);
          handleClosed();
        }}
        onConfirm={() => {
          deleteNote();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Note"
        title={`Are you sure you want to Delete "${notesRow?.Note}"?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />

      <EditModal
        singleLead={singleLead}
        editnotesRow={notesRow}
        seteditnotesRow={setnotesRow}
        showModal={showModal}
        setshowModal={setshowModal}
      />
    </>
  );
}

export default LeadNotes;
LeadNotes.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
