import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { AddSubCat } from "../../../../Api/SectionApi/AddSubCat";
import { fetchSectionPopulate } from "../../../../Redux/Slice/SectionSlice.jsx/SectionSlice";
import { ReactSelectTheme } from "../../../../Utils/ReactSelectTheme";

function AddSubCatModal({
  showModal,
  handleClosed,
  setsnackBarData,
  dispatch,
  catOptions,
}) {
  console.log("catOptions", catOptions);
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Sub Category Name"),
    category: Yup.object().required("Please Select Category"),
  });
  const initialValues = {
    name: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let data = {
      subcategoryName: values.name,
      categoryId: values.category.value,
    };
    console.log(data);
    const response = await AddSubCat(data);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      dispatch(fetchSectionPopulate());
      setSubmitting(false);
      resetForm();
    }
    setSubmitting(false);
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            Add Sub Category
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "600px" }}>
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            fullWidth
            error={
              Boolean(formik.errors.category) &&
              Boolean(formik.touched.category)
            }
          >
            <Typography>Category</Typography>
            <Select
              // styles={{
              //   control: (baseStyles) => ({
              //     ...baseStyles,
              //     borderColor:
              //       Boolean(formik.errors.category) &&
              //       Boolean(formik.touched.category)
              //         ? "red"
              //         : "#bdbdbd",
              //   }),
              // }}
              styles={ReactSelectTheme(
                Boolean(formik.errors.category) &&
                  Boolean(formik.touched.category)
              )}
              name="category"
              size={"small"}
              options={catOptions}
              value={formik.values.category}
              onChange={(value) => {
                formik.setFieldValue("category", value);
              }}
              onBlur={formik.handleBlur}
              placeholder="Select Category"
            />
            <FormHelperText>
              {Boolean(formik.errors.category) &&
              Boolean(formik.touched.category)
                ? formik.errors.category
                : ""}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <Typography>Sub Category Name</Typography>
            <TextField
              name="name"
              value={formik.values?.name}
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.name) && Boolean(formik.touched.name)
              }
              helperText={
                Boolean(formik.errors.name) &&
                Boolean(formik.touched.name) &&
                formik.errors.name
              }
            />
          </FormControl>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button onClick={handleClosed}>
                  <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                </Button>
              </Box>
              <Box>
                {formik.isSubmitting ? (
                  <Box>
                    {/* <Loader/> */}
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button variant="contained" type="submit">
                    <Typography sx={{ fontWeight: "bold" }}>Save</Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddSubCatModal;

AddSubCatModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  product: PropTypes.any,
  setsnackBarData: PropTypes.any,
  dispatch: PropTypes.any,
  catOptions: PropTypes.any,
};
