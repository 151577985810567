import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../../Assets/logo.png";
import imbl from "../../Assets/login-img.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
// import Cookies from "universal-cookie";
import { LoginApi } from "../../Api/LoginApi/LoginApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { fetchUserRoles } from "../../Redux/Slice/Settings/UserSlice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyle = makeStyles((theme) => {
  return {
    Container: {
      display: "flex",
      width: "100%",
      height: "100vh",
      // backgroundColor:"red",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "160px",
    },
    loginBox: {
      width: "100%",
      maxWidth: "450px",
      display: "flex",
      alignSelf: "center",
      flexDirection: "column",
      gap: "20px",

      // backgroundColor:"green",
    },
    imgBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      padding: "10px",
    },
    loginBut: {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      fontSize: "x-large !important",
      // color:theme.palette.primary.contrastText,
      textTransform: "capitalize !important",
      "&:hover": {
        opacity: theme.palette.action.hoverOpacity,
      },
    },
    paperBox: {
      width: "100%",
      padding: "20px",
      display: "flex",
      gap: "10px",
      flexDirection: "column",
    },
  };
});
function LoginContent() {
  const { Container, loginBox, imgBox, loginBut, paperBox } = useStyle();
  // const cookies = new Cookies();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showPassword, setshowPassword] = useState(false);
  const { UserRoles } = useSelector((state) => state?.User);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const initialValues = {
    userName: "",
    password: "",
  };

  useEffect(() => {
    if (queryParams.size > 0) {
      // cookies.set("access_token", queryParams.get("tokenNew"));
      // cookies.set("user", queryParams.get("Roles"));
      // cookies.set("LoginId", queryParams.get("LoginIdNew"));
      // cookies.set("LogoutDisable", true);

      sessionStorage.setItem("access_token", queryParams.get("tokenNew"));
      sessionStorage.setItem("user", queryParams.get("Roles"));
      sessionStorage.setItem("LoginId", queryParams.get("LoginIdNew"));
      sessionStorage.setItem("LogoutDisable", true);
    }
  }, [queryParams]);

  useEffect(() => {
    // const token = cookies.get("access_token");
    // const user = cookies.get("user");

    const token = sessionStorage.getItem("access_token");
    const user = sessionStorage.getItem("user");

    if (token && user) {
      // dispatch(fetchUserRoles(cookies.get("LoginId")));
      navigate(
        UserRoles?.some((role) => role === "Dashboard")
          ? "/dashboard"
          : UserRoles?.some((role) => role === "Leads")
          ? "/leads"
          : UserRoles?.some((role) => role === "Quotes")
          ? "/quotes"
          : UserRoles?.some((role) => role === "Activities")
          ? "/activities"
          : UserRoles?.some((role) => role === "Contacts")
          ? UserRoles?.some((role) => role === "Persons")
            ? "/contacts/persons"
            : "/contacts/organizations"
          : UserRoles?.some((role) => role === "Campaign")
          ? UserRoles?.some((role) => role === "Campaign_Campaigns")
            ? "/campaigns/campaign"
            : UserRoles?.some((role) => role === "Campaign_Templates")
            ? "/campaigns/templates"
            : "/campaigns/groups"
          : UserRoles?.some((role) => role === "Products")
          ? "/products"
          : UserRoles?.some((role) => role === "Chats")
          ? "/chats/facebookpage"
          : UserRoles?.some((role) => role === "Inventory")
          ? UserRoles?.some((role) => role === "Inventory_Unit")
            ? "/inventory/unit"
            : "/inventory/booked-unit"
          : UserRoles?.some((role) => role === "Inventory")
          ? "/inventory/unit"
          : UserRoles?.some((role) => role === "Settings")
          ? "/settings"
          : "/"
      );
    }
  }, [UserRoles]);

  const ValidationSchema = Yup.object().shape({
    userName: Yup.string()
      // .userName("Please Enter A Valid userName Address")
      .required("Please Enter userName"),
    password: Yup.string().required("Please Enter password"),
  });
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const response = await LoginApi(values);

    setsnackBarData(response.snackBarData);
    setSubmitting(false);

    console.log("LoginApi", response);
    if (response.data.IsSuccess) {
      resetForm();
      setSubmitting(false);
      dispatch(fetchUserRoles(response.data.LoginId));
      // cookies.set("BusinessId", response.data.BusinessId);
      // cookies.set("BusinessType", response.data.BusinessType);
      // cookies.set("guide", response.data.IsGuide);

      sessionStorage.setItem("BusinessId", response.data.BusinessId);
      sessionStorage.setItem("BusinessType", response.data.BusinessType);
      sessionStorage.setItem("guide", response.data.IsGuide);

      navigate(
        response.navigate
        //     {
        //     state: {
        //       empId: response?.data?.Data?.Emp_id,
        //       user_id: response?.data?.Data?.user_id,
        //     },
        //   }
      );
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Box className={Container}>
      <form style={{display:"flex", justifyContent: "space-between", width: "100%"}}onSubmit={formik.handleSubmit}>
        <Box className={loginBox}>
          <Box className={imgBox}>
            <img
              style={{ width: "200px" }}
              src={logo}
              alt="logo"
            />
            
          </Box>
          <Paper
            elevation={3}
            sx={{ padding: "30px 20px" }}
          >
            <Box className={paperBox}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: (theme) => theme.palette.grey[800],
                }}
              >
                Welcome Back
              </Typography>
              <Box>
                <Typography variant="label">Email</Typography>
                <TextField
                  type={"text"}
                  size="small"
                  fullWidth
                  name="userName"
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.userName) &&
                    Boolean(formik.touched.userName)
                  }
                  helperText={
                    Boolean(formik.errors.userName) && formik.errors.userName
                  }
                />
              </Box>
              <Box>
                <Typography variant="label">Password</Typography>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  size="small"
                  fullWidth
                  name="password"
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={
                    Boolean(formik.errors.password) &&
                    Boolean(formik.touched.password)
                  }
                  helperText={
                    Boolean(formik.errors.password) && formik.errors.password
                  }
                />

                <Typography
                  variant="small"
                  onClick={() => navigate("/forgotpassword")}
                  sx={{
                    color: (theme) => theme.palette.blue.main,
                    cursor: "pointer",
                    fontSize: "x-small"
                  }}
                >
                  Forgot Password?
                </Typography>
              </Box>
              {formik.isSubmitting ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  type="submit"
                  sx={{ color: "white !important" }}
                  className={loginBut}
                >
                  Login
                </Button>
              )}
            </Box>
          </Paper>
        </Box>
        <Box style={{paddingRight: "160px",}}>
          <img src={imbl} alt="image" />
        </Box>
        
      </form>
    </Box>
  );
}

export default LoginContent;
