import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Box, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import theme from "../../theme";
import {
  fetchPiplines,
  getPiplinesDetails,
} from "../../Redux/Slice/Settings/PiplinesSlice/PiplinesSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsDetails } from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import PropTypes from "prop-types";
import { AppContext } from "../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
import RecycleLeadsTable from "../../Components/RecycleLeads/RecycleLeadsTable/RecycleLeadsTable";

function RecycleLeads({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { LeadViewContainer, topBox } = useStyles();

  const [defaultpipline, setdefaultpipline] = useState(null);
  const { setState, state } = useContext(AppContext);

  const dispatch = useDispatch();
  const { Piplines } = useSelector(getPiplinesDetails);
  const { status } = useSelector(getLeadsDetails);

  useEffect(() => {
    dispatch(fetchPiplines());
  }, [dispatch]);

  useEffect(() => {
    Piplines?.map((pipline) => {
      if (pipline?.IsDefualt === true) {
        setdefaultpipline(pipline);
      }
    });
  }, [Piplines, dispatch]);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Leads");
    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);

  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Leads Recycle"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}
      </Box>
      <Box
        id="leadsRecycle"
        className={`leadsRecycleIntro ${LeadViewContainer}`}
      >
        <Box
          className={topBox}
          sx={{}}
        >
          <TextField
            id="leads-pipelines-select"
            defaultValue={defaultpipline}
            value={defaultpipline}
            onChange={(e) => {
              setdefaultpipline(e.target.value);
            }}
            select
            size={"small"}
          >
            {Piplines?.map((pipline, i) => {
              return (
                <MenuItem
                  key={i}
                  value={pipline}
                >
                  {pipline?.Name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>

        <RecycleLeadsTable
          roles={roles}
          defaultpipline={defaultpipline}
        />
      </Box>
    </>
  );
}

export default RecycleLeads;
RecycleLeads.propTypes = {
  roles: PropTypes.array,
};

const steps = [
  {
    target: "#leadsRecycle",
    content: (
      <>
        <p size="large">Welcome to the Leads Recycle Screen!</p>
        <p>
          Here, you have the power to manage your leads efficiently, ensuring
          that your database remains organized and up-to-date. In this
          interface, you are presented with the option to either permanently
          delete a lead or to recover a previously deleted lead. Your choices
          here are pivotal in maintaining a clean and functional database.
          Choose wisely as you navigate through this critical aspect of lead
          management.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#leads-pipelines-select",
    content: (
      <>
        <p size="large">Select Pipeline</p>
        <p>
          Utilize the dropdown menu to effortlessly switch between pipelines and
          tailor your view. Select the specific pipeline you want to explore.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];

const useStyles = makeStyles(() => {
  return {
    LeadViewContainer: {
      width: "100%",
    },
    iconBox: {
      padding: "5px 10px 5px 10px",
      border: "1px solid black",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    topBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "end",
    },

    customTabSelected: {
      padding: "5px 10px 1px 10px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
      alignItems: "center",
    },
    customTab: {
      padding: "5px 10px 1px 10px",
      backgroundColor: "white",
      border: "1px solid #707070",
      cursor: "pointer",
      alignItems: "center",
      color: theme.palette.primary.main,
    },
  };
});
