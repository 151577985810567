import { Box, } from "@mui/material";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getReportsDetails } from "../../../Redux/Slice/ReportSlice/ReportSlice";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
function SaleReportChart() {
  const { SalesReport } = useSelector(getReportsDetails);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "sales-funnel",
        stacked: true,
      },
      colors: ["#55B88B"],
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          vertical: true,
          columnWidth: "30%",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["white"]
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    },
    series: [],
  });
  useEffect(() => {
    if (SalesReport && SalesReport.SumTotalLeads || SalesReport.SumOnsiteLeads || SalesReport.SumleadtoOnsitePer) {
      setChartData({
        options: {
          ...chartData.options,
          xaxis: {
            categories: ["Total Leads", "Onsite Leads", "Won Leads"],
          },
        },
        series: [
          {
            name: "Leads Data",
            data: [SalesReport.SumTotalLeads || 0, SalesReport.SumOnsiteLeads || 0, SalesReport.SumWonLeads || 0],
            // data: [SalesReport.SumTotalLeads, SalesReport.SumOnsiteLeads, SalesReport.SumWonLeads],
          },
        ],
      });
    }
    else {
      setChartData({
        options: {
          ...chartData.options,
          xaxis: {
            categories: ["Total Leads", "Onsite Leads", "Won Leads"],
          },
        },
        series: [
          {
            name: "Leads Data",
            data: [0, 0, 0],
          },
        ],
      });
    }
  }, [SalesReport]);
  return (
    <Box sx={{ width: "100%", position: "relative", }} id="dashboard-sales-funnel">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={300}
      />
      <Box sx={{ position: "absolute", top: "50%", left: "30%" }}>
        <p>
          {`${Math.floor(SalesReport.SumleadtoOnsitePer)}%`} <br></br>
          <ArrowForwardIcon style={{ transform: 'scaleY(1) scaleX(2)', color: "grey", fontSize: "20", }} />
        </p>
      </Box>
      <Box sx={{ position: "absolute", top: "50%", left: "65%" }}>
        <p>
          {`${SalesReport.SumOnsitetoWonPer}%`} <br></br>
          <ArrowForwardIcon style={{ transform: 'scaleY(1) scaleX(2)', color: "grey", fontSize: "20", }} />
        </p>
      </Box>
    </Box>
  );
}
SaleReportChart.propTypes = {
  DashboardData: PropTypes.any,
};
export default SaleReportChart;
