import { axiosInstance } from "../../Utils/AxiosInstance";

export const CreateCampaignApi = async (data) => {
  console.log(data);
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Campaigns", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
