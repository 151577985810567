import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Business: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchBusiness = createAsyncThunk("/Business", async (id) => {
  const response = await axiosInstance.get(
    `/Business/getBusiness?BusinessId=${id}`
  );
  console.log(response);
  return response;
});

const Businesslice = createSlice({
  name: "Business",
  initialState,
  reducers: {
    BusinessAdded: {
      reducer(state, action) {
        state.Business?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchBusiness.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBusiness.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Business = action.payload.data.Data;
      })
      .addCase(fetchBusiness.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getBusinessDetails = (state) => state.Business;

export const { BusinessAdded } = Businesslice.actions;

export default Businesslice.reducer;
