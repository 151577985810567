import { configureStore } from "@reduxjs/toolkit";
import ActivitiesSlice from "./Slice/ActivitiesSlice/ActivitiesSlice";
import Dashboardslice from "./Slice/DashboardSlice/Dashboardslice";
import DraftSlice from "./Slice/Email/DraftSlice";
import OutBoxSlice from "./Slice/Email/OutBoxSlice";
import LeadsSlice from "./Slice/LeadsSlice/LeadsSlice";
import MeetingsSlice from "./Slice/MeetingsSlice/MeetingsSlice";
import OrganizationSlice from "./Slice/OrganizationSlice/OrganizationSlice";
import PersonSlice from "./Slice/PersonSlice/PersonSlice";
import ProductSlice from "./Slice/ProductSlice/ProductSlice";
import QuoteSlice from "./Slice/QuoteSlice/QuoteSlice";
import GroupsSlice from "./Slice/Settings/GroupsSlice/GroupsSlice";
import PiplinesSlice from "./Slice/Settings/PiplinesSlice/PiplinesSlice";
import RolesSlice from "./Slice/Settings/RolesSlice/RolesSlice";
import SourcesSlice from "./Slice/Settings/SourcesSlice/SourcesSlice";
import TagsSlice from "./Slice/Settings/TagsSlice/TagsSlice";
import TypesSlice from "./Slice/Settings/TypesSlice/TypesSlice";
import UserSlice from "./Slice/Settings/UserSlice/UserSlice";
import ScreensSlice from "./Slice/Settings/ScreensSlice/ScreensSlice";
import TemplateSlice from "./Slice/TemplateSlice/TemplateSlice";
import CampaignGroupsSlice from "./Slice/CampaignGroupsSlice/CampaignGroupsSlice";
import ServiceSlice from "./Slice/ServiceSlice/ServiceSlice";
import CampaignsSlice from "./Slice/CampaignsSlice/CampaignsSlice";
import BusinessSlice from "./Slice/BusinessSlice/BusinessSlice";
import FacebookPageSlice from "./Slice/Settings/FacebookPageSlice/FacebookPageSlice";
import ProjectSlice from "./Slice/ProjectSlice/ProjectSlice";
import SectionSlice from "./Slice/SectionSlice.jsx/SectionSlice";
import UnitSlice from "./Slice/UnitSlice/UnitSlice";
import DescriptionSlice from "./Slice/Settings/DescriptionSlice/DescriptionSlice";
import TargetSlice from "./Slice/TargetSlice/TargetSlice";
import CategorySlice from "./Slice/Settings/CategorySlice/CategorySlice";
import ProfileSlice from "./Slice/Profile/ProfileSlice";
import NotificationSlice from "./Slice/Notifications/GetNotification";
import ReportSlice from "./Slice/ReportSlice/ReportSlice";

export const store = configureStore({
  reducer: {
    Organization: OrganizationSlice,
    Template: TemplateSlice,
    CampaignGroups: CampaignGroupsSlice,
    Products: ProductSlice,
    Services: ServiceSlice,
    Business: BusinessSlice,
    Groups: GroupsSlice,
    Piplines: PiplinesSlice,
    Sources: SourcesSlice,
    Types: TypesSlice,
    Persons: PersonSlice,
    Roles: RolesSlice,
    Quotes: QuoteSlice,
    Tags: TagsSlice,
    Draft: DraftSlice,
    OutBox: OutBoxSlice,
    User: UserSlice,
    Leads: LeadsSlice,
    Meetings: MeetingsSlice,
    Activities: ActivitiesSlice,
    Dashboard: Dashboardslice,
    Screens: ScreensSlice,
    CampaignsSlice: CampaignsSlice,
    FacebookPageSlice: FacebookPageSlice,
    Projects: ProjectSlice,
    Sections: SectionSlice,
    Units: UnitSlice,
    LeadsDescription: DescriptionSlice,
    Targets: TargetSlice,
    SectionsCategories: CategorySlice,
    UserProfile: ProfileSlice,
    Notifications: NotificationSlice,
    Reports: ReportSlice,
  },
});
