import React from "react";
import PropTypes from "prop-types";
import Zoom from "@mui/material/Zoom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "@emotion/styled";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  //margin: 4,
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: 200,
    // height: 100,
    // fontFamily: "'Grape Nuts', Helvetica",
    backgroundColor: "#298B8B",
    //color: "deepskyblue", see sx value
    margin: 4,
    padding: 8,
    whiteSpace: "pre-line",
    //border: "solid yellow 1px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#298B8B",
    // "&:before": {
    //   boxShadow: theme.shadows[1],
    // },
  },
}));

const AppTooltip = ({ title, children, placement = "top" }) => {
  return (
    <LightTooltip
      title={title}
      TransitionComponent={Zoom}
      placement={placement}
      arrow
    >
      {children}
    </LightTooltip>
  );
};
export default AppTooltip;

AppTooltip.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  placement: PropTypes.string,
  children: PropTypes.node,
};
