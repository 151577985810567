import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    Modal,
    TextField,
    Typography,
  } from "@mui/material";
  import React from "react";
  import PropTypes from "prop-types";
  import { Box } from "@mui/system";
  import { makeStyles } from "@mui/styles";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { fetchLeadsSingle } from "../../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { UpdateLeadNotesApi } from "../../../../../Api/LeadsApi/EditLeadsNoteApi";
import { useContext } from "react";

import { useDispatch } from "react-redux";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
const useStyles = makeStyles(() => {
    return {
      ModalContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "10px",
        width: "100%",
        backgroundColor:"rgba(0,0,0,0.4)"
      },
      Container: {
        width: "800px",
        padding: "20px",
        border: "none",
        position: "relative",
        background: "white",
        borderRadius: "10px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      },

      TableHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      formContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "20px",
        alignItems: "flex-start",
      },
    };
  });
function EditModal({showModal,setshowModal,editnotesRow,seteditnotesRow,singleLead}) {
  const {  Container, ModalContainer, TableHeader,formContainer } = useStyles();
  const initialValues = {
    note: "",
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch=useDispatch();

useEffect(()=>{
if(editnotesRow){
    formik.setFieldValue("note",editnotesRow?.Note);
}
},[editnotesRow]);
  const handleSubmit = async (values, resetForm, setSubmitting) => {

    const obj = { ...values,leadId:singleLead?.Id };
      const response = await UpdateLeadNotesApi({obj:obj,id:editnotesRow?.LeadNoteId});
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
        resetForm();
        setSubmitting(false);
      }
    setSubmitting(false);
  };

  const ValidationSchema = Yup.object().shape({
    note: Yup.string().required("Please Enter  note"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Modal open={showModal}>
      <Box className={ModalContainer}>
        <Box className={Container}>
          <Box className={TableHeader}>
            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
              Edit Notes
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                seteditnotesRow(null);
                setshowModal(false);
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Box>
          <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <FormControl fullWidth>
            <Typography>Note</Typography>
            <TextField
              name="note"
              value={formik.values?.note}
              size={"small"}
              multiline
              rows={4}
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.note) && Boolean(formik.touched.note)
              }
              helperText={Boolean(formik.errors.note) && formik.errors.note}
            />
          </FormControl>
          {
            formik?.isSubmitting?<CircularProgress/>:
         
          <Button
            variant="outlined"
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: "white !important",
            }}
            type="submit"
          >
            Edit
          </Button>
           }
        </Box>
      </form>
        </Box>
      </Box>
    </Modal>
  );
}

export default EditModal;
EditModal.propTypes = {
    showModal: PropTypes.any,
    setshowModal: PropTypes.any,
    editnotesRow: PropTypes.any,
    seteditnotesRow: PropTypes.any,
    singleLead: PropTypes.any,
  };
  