import React, { useEffect, useState } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import PlannedAll from "./PlannedAll";
import DoneAll from "./DoneAll";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      height: "700px",
      //   gap: "20px",
      padding: "20px",
      overflow: "auto",
      //   alignItems: "flex-start",
    },

    Border: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      // gap: "20px",
    },
    plannedBox: {
      display: "flex",
      //   flexDirection:"column",
      gap: "10px",
      width: "100%",
      alignItems: "center",
    },
  };
});
function All({ singleLead, roles }) {
  const { formContainer, Border, plannedBox } = useStyle();
  const [plannedAll, setplannedAll] = useState([]);
  const [doneAll, setdoneAll] = useState([]);
  // useEffect(() => {
  //   let arr = [];
  //   let arr2 = [];
  //   singleLead?.leadActivity?.map((meeting) => {
  //     if (meeting?.Type === "Meeting" && meeting?.StatusDone === false) {
  //       arr.push(meeting);
  //     }
  //     if (meeting?.Type === "Call" && meeting?.StatusDone === false) {
  //       arr.push(meeting);
  //     }
  //     if (meeting?.Type === "Email" && meeting?.StatusDone === false) {
  //       arr.push(meeting);
  //     }
  //     if (meeting?.Type === "Meeting" && meeting?.StatusDone === true) {
  //       arr2.push(meeting);
  //     }
  //     if (meeting?.Type === "Call" && meeting?.StatusDone === true) {
  //       arr2.push(meeting);
  //     }
  //     if (meeting?.Type === "Email" && meeting?.StatusDone === true) {
  //       arr2.push(meeting);
  //     }
  //   });
  //   setplannedAll(arr);
  //   setdoneAll(arr2);
  // }, [singleLead]);
  useEffect(() => {
    let arr = [];
    let arr2 = [];
    singleLead?.leadActivity?.map((meeting) => {
      if (meeting?.StatusDone === false) {
        arr.push(meeting);
      }

      if (meeting?.StatusDone === true) {
        arr2.push(meeting);
      }
    });
    setplannedAll(arr);
    setdoneAll(arr2);
  }, [singleLead]);

  return (
    <>
      <Paper elevation={3}>
        <Box className={formContainer}>
          <Box className={Border} sx={{}}>
            <Box>
              <Box className={plannedBox}>
                <Typography
                  sx={{ fontWeight: "bold", color: "rgb(84, 110, 122)" }}
                >
                  Planned
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <Divider />
                </Box>
              </Box>
              <PlannedAll singleLead={singleLead} roles={roles} />
              {plannedAll?.length > 0 ? null : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ color: "rgb(84, 110, 122)" }}>
                    You have no planned activities.
                  </Typography>
                </Box>
              )}
            </Box>

            <Box>
              <Box className={plannedBox}>
                <Typography
                  sx={{ fontWeight: "bold", color: "rgb(84, 110, 122)" }}
                >
                  Done
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <Divider />
                </Box>
              </Box>
              <DoneAll singleLead={singleLead} roles={roles} />

              {doneAll?.length > 0 ? null : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ color: "rgb(84, 110, 122)" }}>
                    You have no done activities.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {/* </Box> */}
        </Box>
      </Paper>
    </>
  );
}

export default All;
All.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
