import { axiosInstance } from "../../Utils/AxiosInstance";
// import Cookies from "universal-cookie";

// const cookies = new Cookies();
export const TourCheckApi = async (value) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(
      `Auth/UserGuidStatusChange?isGuide=${value}`
    );
    if (response?.data?.IsSuccess) {
      // cookies.set("guide", value);
      sessionStorage.setItem("guide", value);
    }
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
