import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { fetchLeadsSingle } from "../../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import { EditFileApi } from "../../../../../Api/LeadsApi/EditFileApi";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },

    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "10px",
      alignItems: "flex-start",
    },
  };
});
function EditFilesModal({
  showModal,
  setshowModal,
  fileRow,
  setfileRow,
  singleLead,
}) {
  const { Container, ModalContainer, TableHeader, formContainer } = useStyles();
  const initialValues = {
    Name: "",
    Description: "",
    File: "",
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fileRow) {
      formik.setFieldValue("Name", fileRow?.Name);
      formik.setFieldValue("Description", fileRow?.Description);
      //   formik.setFieldValue("File", fileRow?.image);
    }
  }, [fileRow]);

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    // const obj = { ...values, LeadId: singleLead?.Id };

    const formdata = new FormData();

    formdata.append("LeadId", singleLead?.Id);
    formdata.append("Description", values?.Description);
    formdata.append("File", values?.File);
    const response = await EditFileApi({
      obj: formdata,
      id: fileRow?.LeadFileId,
    });
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
      resetForm();
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  const ValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please Enter  Name"),
    Description: Yup.string().required("Please Enter  Description"),
    File: Yup.string().required("Please add a File"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Modal open={showModal}>
      <Box className={ModalContainer}>
        <Box className={Container}>
          <Box className={TableHeader}>
            <Typography
              variant="h1"
              sx={{ fontSize: "18px !important" }}
            >
              Edit File
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setfileRow(null);
                setshowModal(false);
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  disabled
                  name="Name"
                  value={formik.values?.Name}
                  size={"small"}
                  fullWidth
                  //   onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.Name) && Boolean(formik.touched.Name)
                  }
                  helperText={Boolean(formik.errors.Name) && formik.errors.Name}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Description</Typography>
                <TextField
                  name="Description"
                  value={formik.values?.Description}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.Description) &&
                    Boolean(formik.touched.Description)
                  }
                  helperText={
                    Boolean(formik.errors.Description) &&
                    formik.errors.Description
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>File</Typography>
                <TextField
                  name="File"
                  // value={formik.values?.File}
                  size={"small"}
                  type={"file"}
                  fullWidth
                  onChange={(e) => {
                    formik.setFieldValue("File", e.target.files[0]);
                  }}
                  error={
                    Boolean(formik.errors.File) && Boolean(formik.touched.File)
                  }
                  helperText={Boolean(formik.errors.File) && formik.errors.File}
                />
              </FormControl>

              {formik?.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: "white !important",
                  }}
                  type="submit"
                >
                  Edit
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}

export default EditFilesModal;
EditFilesModal.propTypes = {
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
  fileRow: PropTypes.any,
  setfileRow: PropTypes.any,
  singleLead: PropTypes.any,
};
