import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Services: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchServices = createAsyncThunk("/Product", async () => {
  const response = await axiosInstance.get(`/Product?type=Service`);
  console.log(response);
  return response;
});

const ServiceSlice = createSlice({
  name: "Services",
  initialState,
  reducers: {
    ServicesAdded: {
      reducer(state, action) {
        state.Services?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchServices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Services = action.payload.data.Data;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getServicesDetails = (state) => state.Services;

export const { ServicesAdded } = ServiceSlice.actions;

export default ServiceSlice.reducer;
