import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CategoryIcon from "@mui/icons-material/Category";
import WindowIcon from "@mui/icons-material/Window";

function Inventory({ roles }) {
  const { paperBox, paperContentBox, contentBox, textBox, setingText } =
    useStyle();

  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={{ fontWeight: "bold" }}>Inventory</Typography>
      <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
        Manage all your Inventory related settings in the CRM.
      </Typography>

      <Paper
        className={paperBox}
        elevation={3}
        sx={{}}
      >
        <Box
          className={paperContentBox}
          sx={{}}
        >
          {roles?.some((role) => role === "Inventory Settings_Projects") && (
            <Box
              onClick={() => {
                navigate("/settings/inventory/project");
              }}
              className="settingBoxA"
              sx={{ padding: "10px", cursor: "pointer" }}
              id="settings-pipelines"
            >
              <Box
                className={contentBox}
                sx={{}}
              >
                <AccountTreeIcon
                  fontSize="large"
                  sx={{ color: theme.palette.primary.main }}
                />
                <Box
                  className={textBox}
                  sx={{}}
                >
                  <Typography variant="h6">Projects</Typography>
                  <Typography
                    variant="body2"
                    className={`${setingText} settingBoxAhoverEffect`}
                    sx={{}}
                  >
                    Add,edit or delete Projects from{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${setingText} settingBoxAhoverEffect`}
                    sx={{}}
                  >
                    CRM
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {roles?.some((role) => role === "Inventory Settings_Sections") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/inventory/section");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-sources"
              >
                <Box
                  className={contentBox}
                  sx={{}}
                >
                  <WindowIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box
                    className={textBox}
                    sx={{}}
                  >
                    <Typography variant="h6">Sections</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add,edit or delete Sections from{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      CRM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {roles?.some((role) => role === "Inventory Settings_Category") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/inventory/category");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-sources"
              >
                <Box
                  className={contentBox}
                  sx={{}}
                >
                  <CategoryIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box
                    className={textBox}
                    sx={{}}
                  >
                    <Typography variant="h6">Category</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add,edit or delete Category from{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      CRM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
export default Inventory;
Inventory.propTypes = {
  roles: PropTypes.array,
};

const useStyle = makeStyles(() => {
  return {
    paperBox: {
      width: "100%",
      maxWidth: "1280px",
      padding: "20px",
      marginTop: "20px",
    },
    paperContentBox: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: "10px",
    },
    contentBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    textBox: {
      minHeight: "90px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    setingText: {
      transform: "scale(0)",
      height: "0px",
    },
  };
});
