import React, { useEffect, useState } from "react";
import {
  Box,
  // Button,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import theme from "../../../../../theme";
import GroupsIcon from "@mui/icons-material/Groups";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment/moment";
import { makeStyles } from "@mui/styles";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { DeleteLeadActivityApi } from "../../../../../Api/LeadsApi/DeleteActivityApi";
import { fetchLeadsSingle } from "../../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppConfirmationDialog from "../../../../AppConfirmationDialog/AppConfirmationDialog";
import ActivityInfoModal from "../../../../Activities/ActivityInfoModal";

const useStyle = makeStyles(() => {
  return {
    notesubboxMain: {
      display: "flex",
      gap: "40px",
      width: "100%",
      padding: "0px 10px 0px 20px",
    },
    iconlineMain: {
      width: "2px",
      minHeight: "100%",
      // backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      position: "relative",
    },
    iconBox: {
      width: "40px",
      height: "40px",
      border: `1px solid ${theme?.palette.primary.main}`,
      transform: "translate(-50%,0%)",
      position: "absolute",
      top: "0%",
      left: "50%",
      color: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white !important",
    },
    noteBox: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      padding: "10px",
      width: "100%",
    },
    scheduleBox: {
      display: "flex",
      gap: "5px",
    },
    scheduled: {
      fontSize: "15px !important",
      color: theme.palette.primary.main,
    },
  };
});
function DoneMeetings({ singleLead, roles }) {
  const {
    notesubboxMain,
    iconlineMain,
    iconBox,
    noteBox,
    scheduleBox,
    scheduled,
  } = useStyle();
  const [doneMeetingLeads, setdoneMeetingLeads] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setloading] = useState(false);
  const [opens, setOpens] = useState(false);
  const [doneMeetingRow, setdoneMeetingRow] = useState(null);
  const [activityRow, setactivityRow] = useState(null);
  const [activityInfoModal, setactivityInfoModal] = useState(false);

  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event, meeting) => {
    setAnchorEl(event.currentTarget);
    setdoneMeetingRow(meeting);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    let arr = [];
    singleLead?.leadActivity?.map((meetings) => {
      if (meetings?.Type === "Meeting" && meetings?.StatusDone === true) {
        arr.push(meetings);
      }
    });
    setdoneMeetingLeads(arr);
  }, [singleLead]);
  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClosed = () => {
    setOpens(false);
  };
  const deleteDoneMeeting = async () => {
    setloading(true);
    const response = await DeleteLeadActivityApi(doneMeetingRow?.ActivtyId);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setdoneMeetingRow(null);
      setloading(false);
      handleClosed();
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
    }
    setloading(false);
  };
  return (
    <>
      <Box>
        {doneMeetingLeads?.map((meeting, i) => {
          // let date = moment(meeting?.ScheduleTo.split("T")[0]).format(
          //   "DD-MM-YYYY"
          // );
          // let time = moment(
          //   meeting?.ScheduleTo.split("T")[1].split("Z")[0],
          //   "hh:mm A"
          // ).format("hh:mm A");
          let date2 = moment(meeting?.ScheduleFrom.split("T")[0]).format(
            "DD-MM-YYYY"
          );
          let time2 = moment(
            meeting?.ScheduleFrom.split("T")[1].split("Z")[0],
            "hh:mm A"
          ).format("hh:mm A");
          // let schTo = date.concat(" ").concat(time);
          let schFrom = date2.concat(" ").concat(time2);
          return (
            <Box key={i} className={notesubboxMain}>
              <Box className={iconlineMain}>
                <Box className={iconBox}>
                  <GroupsIcon sx={{}} />
                </Box>
              </Box>
              <Box sx={{ width: "100%", paddingBottom: "10px" }}>
                <Paper elevation={3} className={noteBox}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {meeting?.Title}
                    </Typography>
                    <MoreVertIcon
                      onClick={
                        // e.stopPropagation();
                        (e) => {
                          if (roles?.some((role) => role === "Leads_Edit")) {
                            handleClick(e, meeting);
                          }
                        }
                      }
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                        display:
                          singleLead?.Status === "Won" ||
                          singleLead?.Status === "Lost"
                            ? "none"
                            : "",
                      }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClose();
                          navigate("/edit/activities", {
                            state: doneMeetingRow,
                          });
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          handleClickOpen();
                        }}
                      >
                        Remove
                      </MenuItem>
                      <MenuItem
                        key={i}
                        onClick={() => {
                          setactivityInfoModal(true);
                          setactivityRow(meeting);
                        }}
                      >
                        View Detail
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box className={scheduleBox}>
                    <Typography className={scheduled}>
                      Meeting scheduled at
                    </Typography>
                    <Typography className={scheduled}>{schFrom}</Typography>
                  </Box>
                  <Box sx={{ backgroundColor: "#fff9c4", padding: "10px" }}>
                    <Typography>{meeting?.Description}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      Added on:{" "}
                      {moment(new Date()).format("Do MMMM YYYY, h:mm a")}
                    </Typography>
                    <Typography sx={{ cursor: "pointer", color: "#0e90d9" }}>
                      {meeting?.FullName}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
          );
        })}
      </Box>

      {/* <Dialog
        open={opens}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Delete <b>{doneMeetingRow?.Title}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                setdoneMeetingRow(null);
                handleClosed();
              }}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  deleteDoneMeeting();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}
      {activityInfoModal && (
        <ActivityInfoModal
          activityInfoModal={activityInfoModal}
          setactivityInfoModal={setactivityInfoModal}
          setactivityRow={setactivityRow}
          activityRow={activityRow}
        />
      )}
      <AppConfirmationDialog
        open={opens}
        onDeny={() => {
          setdoneMeetingRow(null);
          handleClosed();
        }}
        onConfirm={() => {
          deleteDoneMeeting();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Meeting"
        title={`Are you sure you want to Delete "${doneMeetingRow?.Title}"?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />
    </>
  );
}

export default DoneMeetings;
DoneMeetings.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
