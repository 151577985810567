import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
// import countryList from "react-select-country-list";
import { Country, State, City } from "country-state-city";
import * as Yup from "yup";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CreateOrganizationApi } from "../../../../Api/OrganizationApis/CreateOrganizationApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { UpdateOrganizationApi } from "../../../../Api/OrganizationApis/UpdateOrganizationApi";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";

import {
  fetchOrganization,
  fetchSingleOrganization,
  getOrganizationDetails,
} from "../../../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import { ReactSelectTheme } from "../../../../Utils/ReactSelectTheme";
// import Cookies from "universal-cookie";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textAreaContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    AddressContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    LoadingContainer: {
      height: "70vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please Enter Organization Name"),
  address: Yup.string().required("Please Enter Address"),
  // state: Yup.string().required("Please Enter State"),
  // city: Yup.string().required("Please Enter City"),
  email: Yup.string()
    .email("Please Enter A Valid Email Address")
    .required("Please Enter Email"),
  postCode: Yup.string().required("Please Enter Postal Code"),
  // phone: Yup.string().required("Please Enter Phone Number"),
  // contact: Yup.string().required("Please Enter Contact Number"),
  // website: Yup.string().required("Please Enter Website"),
  // description: Yup.string().required("Please Enter Description"),
  // country: Yup.object().required("Please Select country"),
  industry: Yup.object().required("Please Select Industry"),
  types: Yup.object().required("Please Select Type"),
});

const CreateOrganization = () => {
  const {
    container,
    subContainer,
    formContainer,
    textAreaContainer,
    AddressContainer,
  } = useStyle();
  const { setsnackBarData } = useContext(SnackBarContext);
  // const [value, setValue] = useState("");
  const { SingleOrganization } = useSelector(getOrganizationDetails);
  console.log("SingleOrganization", SingleOrganization);
  const [allcountries, setallcountries] = useState([]);
  const [allstates, setallstates] = useState([]);
  const [allcities, setallcities] = useState([]);
  const [country, setcountry] = useState(null);
  const [states, setstates] = useState(null);
  const [city, setcity] = useState(null);
  const [errors, seterrors] = useState(null);
  const [phone, setphone] = useState(null);
  const [dialCode, setDialCode] = useState();
  const [phone2, setphone2] = useState(null);
  const [dialCode2, setDialCode2] = useState();
  console.log(dialCode);
  console.log(dialCode2);
  // const cookies = new Cookies();
  // let businessType = cookies.get("BusinessType");
  let businessType = sessionStorage.getItem("BusinessType");
  const handleMobile = (val, dial) => {
    setphone(val);
    setDialCode(dial);
  };
  const handleMobile2 = (val, dial) => {
    setphone2(val);
    setDialCode2(dial);
  };
  useEffect(() => {
    let value = Country.getAllCountries()?.map((data) => {
      let obj = {
        label: data?.name,
        value: data,
      };
      return obj;
    });
    setallcountries(value);
  }, []);
  useEffect(() => {
    let value = State.getStatesOfCountry(country?.isoCode);
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data,
      };
      return obj;
    });
    setallstates(value);
  }, [country]);
  useEffect(() => {
    let value = City.getCitiesOfState(states?.countryCode, states?.isoCode);
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.name,
      };
      return obj;
    });
    setallcities(value);
  }, [states]);
  console.log("states,states", states);
  // const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  const typesOptions = [
    { value: "Analyst", label: "Analyst" },
    { value: "Competitor", label: "Competitor" },
    { value: "Customer", label: "Customer" },
    { value: "Distributor", label: "Distributor" },
    { value: "Integrator", label: "Integrator" },
    { value: "Investor", label: "Investor" },
    { value: "Partner", label: "Partner" },
    { value: "Press", label: "Press" },
    { value: "Prospect", label: "Prospect" },
    { value: "Reseller", label: "Reseller" },
    { value: "Supplier", label: "Supplier" },
    { value: "Vendor", label: "Vendor" },
    { value: "Other", label: "Other" },
  ];
  const industryOptions = [
    {
      value: "ASP(Application Service Provider)",
      label: "ASP(Application Service Provider)",
    },
    { value: "Network Equipment ISV", label: "Network Equipment ISV" },
    { value: "Data/Telecom OEM", label: "Data/Telecom OEM" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Communications", label: "Communications" },
    {
      value: "ERP(Enterprise Resource Planning)",
      label: "ERP(Enterprise Resource Planning)",
    },
    { value: "Government/Military", label: "Government/Military" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Technology", label: "Technology" },
    { value: "Education", label: "Education" },
    { value: "Management ISV", label: "Management ISV" },
    { value: "Financial Service", label: "Financial Service" },
    { value: "Wireless Industry", label: "Wireless Industry" },
    { value: "MSP(Management Service)", label: "MSP(Management Service)" },
  ];

  const initialValues = {
    name: "",
    address: "",
    // country: "",
    // state: "",
    // city: "",
    email: "",
    postCode: "",
    types: "",
    industry: "",
    // phone: "",
    // contact: "",
    website: "",
    description: "",
  };
  const { state } = useLocation();
  console.log("state", state);
  console.log("state", SingleOrganization);
  const navigate = useNavigate();
  // const changeHandler = (value) => {
  //   setValue(value);
  // };
  useEffect(() => {
    if (state?.ID) {
      dispatch(fetchSingleOrganization(state?.ID));
    }
  }, [state]);
  useEffect(() => {
    if (state?.ID) {
      formik?.setFieldValue("name", SingleOrganization?.Name);
      formik?.setFieldValue("email", SingleOrganization?.Email);
      formik?.setFieldValue(
        "address",
        SingleOrganization?.Address?.AddressDetail
      );
      formik?.setFieldValue("postCode", SingleOrganization?.Address?.Postcode);
      formik?.setFieldValue("industry", {
        value: SingleOrganization?.Industry,
        label: SingleOrganization?.Industry,
      });
      formik?.setFieldValue("types", {
        value: SingleOrganization?.Type,
        label: SingleOrganization?.Type,
      });
      // formik?.setFieldValue("phone", SingleOrganization?.Phone);
      setphone(SingleOrganization?.ContactNumber);
      setphone2(SingleOrganization?.Phone);
      // formik?.setFieldValue("contact", SingleOrganization?.ContactNumber);
      formik?.setFieldValue("description", SingleOrganization?.Description);
      formik?.setFieldValue("website", SingleOrganization?.Website);

      // let cntry = options?.find(
      //   (itm) => itm.label == SingleOrganization?.Address?.Country
      // );
      // if (cntry) {
      //   setValue(cntry);
      //   formik.handleChange({
      //     target: {
      //       name: "country",
      //       value: cntry?.value || "",
      //     },
      //   });
      // }
      //  formik?.setFieldValue("country",SingleOrganization?.Address?.Country);
    } else if (state) {
      handleEdit(state);
    }
  }, [state, SingleOrganization]);
  useEffect(() => {
    if (state) {
      allcountries?.map((country) => {
        if (SingleOrganization?.Address?.Country == country?.label) {
          setcountry({
            value: country.label,
            label: country.label,
            isoCode: country?.value?.isoCode,
          });
        }
      });
    }
  }, [state, SingleOrganization, allcountries]);
  useEffect(() => {
    if (state) {
      allstates?.map((stat) => {
        if (stat?.label == SingleOrganization?.Address?.State) {
          setstates({
            label: stat?.label,
            value: stat?.label,
            isoCode: stat?.value?.isoCode,
            countryCode: stat?.value?.countryCode,
          });
        }
      });
    }
  }, [state, SingleOrganization, allstates]);
  useEffect(() => {
    allcities?.map((cit) => {
      if (cit?.label == SingleOrganization?.Address?.City) {
        setcity({
          label: cit?.label,
          value: cit?.label,
          isoCode: cit?.value?.isoCode,
          countryCode: cit?.value?.countryCode,
        });
      }
    });
  }, [state, SingleOrganization, allcities]);
  useEffect(() => {
    if (country != null) {
      seterrors({ ...errors, country: false });
      return;
    }
  }, [country]);
  useEffect(() => {
    if (states != null) {
      seterrors({ ...errors, state: false });
      return;
    }
  }, [states]);
  useEffect(() => {
    if (city != null) {
      seterrors({ ...errors, city: false });
      return;
    }
  }, [city]);
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let countryValidation = false;
    let stateValidation = false;
    let cityValidation = false;
    let phoneValidation = false;
    let phoneValidation2 = false;
    if (country == null || country == "") {
      countryValidation = true;
      setSubmitting(false);
      seterrors({ ...errors, country: true });
      return;
    } else if (states == null || states == "") {
      stateValidation = true;
      setSubmitting(false);
      seterrors({ ...errors, state: true });
      return;
    } else if (city == null || city == "") {
      cityValidation = true;
      setSubmitting(false);
      seterrors({ ...errors, city: true });
      return;
    } else if (phone == null || phone?.length < 4) {
      phoneValidation = true;
      setSubmitting(false);
      seterrors({ ...errors, phone: true });
      return;
    } else if (phone2 == null || phone2?.length < 4) {
      phoneValidation2 = true;
      setSubmitting(false);
      seterrors({ ...errors, phone2: true });
      return;
    }
    if (
      countryValidation ||
      stateValidation ||
      cityValidation ||
      phoneValidation ||
      phoneValidation2
    ) {
      setSubmitting(false);
      return;
    } else {
      const obj = {
        name: values.name,
        email: values.email,
        address: {
          AddressDetail: values.address,
          country: country.label,
          state: states.label,
          city: city?.label,
          postcode: values?.postCode?.toString(),
        },
        phone: phone2,
        contactNumber: phone,
        description: values?.description,
        website: values?.website,
        industry: values?.industry.label,
        type: values?.types.label,
      };
      // const obj = { ...values, country: value?.label };
      if (state) {
        const response = await UpdateOrganizationApi({
          ...obj,
          id: state?.OrganizationId ? state?.OrganizationId : state.ID,
        });
        setsnackBarData(response.snackBarData);
        if (response?.data?.IsSuccess) {
          setSubmitting(false);
          dispatch(fetchOrganization());
          resetForm();
          navigate(-1);
        }
      } else {
        const response = await CreateOrganizationApi(obj);
        setsnackBarData(response.snackBarData);
        if (response?.data?.IsSuccess) {
          setSubmitting(false);
          dispatch(fetchOrganization());
          resetForm();
          navigate(-1);
        }
      }
      setSubmitting(false);
    }
    // resetForm();
    // setValue("");
  };
  const handleNavigate = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleEdit = (values) => {
    console.log("values", values);
    // let cntry = options?.find((itm) => itm.label == values.addressObj?.Country);
    let cntry = allcountries?.find(
      (itm) => itm.label == values.addressObj?.Country
    );
    if (cntry) {
      // setValue(cntry);
      formik.handleChange({
        target: {
          name: "country",
          value: cntry?.value || "",
        },
      });
    }
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
    formik.handleChange({
      target: {
        name: "address",
        value: values?.addressObj?.AddressDetail || "",
      },
    });
    formik.handleChange({
      target: {
        name: "country",
        value: values?.addressObj?.Country || "",
      },
    });
    formik.handleChange({
      target: {
        name: "state",
        value: values?.addressObj?.State || "",
      },
    });
    formik.handleChange({
      target: {
        name: "city",
        value: values?.addressObj?.City || "",
      },
    });
    formik.handleChange({
      target: {
        name: "email",
        value: values?.email || "",
      },
    });
    formik.handleChange({
      target: {
        name: "postCode",
        value: values?.addressObj?.Postcode || "",
      },
    });
  };
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/contacts/organizations"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                {businessType === "RealState" ? "Developers" : "Organizations"}
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state
                  ? `Update ${
                      businessType === "RealState"
                        ? "Developers"
                        : "Organizations"
                    }`
                  : `Create ${
                      businessType === "RealState"
                        ? "Developers"
                        : "Organizations"
                    }`}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state
              ? `Update ${
                  businessType === "RealState" ? "Developers" : "Organizations"
                }`
              : `Create ${
                  businessType === "RealState" ? "Developers" : "Organizations"
                }`}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  value={formik.values?.name}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                  }
                  helperText={
                    Boolean(formik.errors.name) &&
                    Boolean(formik.touched.name) &&
                    formik.errors.name
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Email</Typography>
                <TextField
                  name="email"
                  value={formik.values?.email}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.email) &&
                    Boolean(formik.touched.email)
                  }
                  helperText={
                    Boolean(formik.errors.email) &&
                    Boolean(formik.touched.email) &&
                    formik.errors.email
                  }
                />
              </FormControl>
              <Box>
                <Box>
                  <Typography>Address</Typography>
                </Box>
                <Box className={textAreaContainer}>
                  <Box className={AddressContainer}>
                    <FormControl fullWidth>
                      <TextField
                        name="address"
                        value={formik.values?.address}
                        multiline
                        minRows={8}
                        size={"small"}
                        fullWidth
                        onChange={formik.handleChange}
                        error={
                          Boolean(formik.errors.address) &&
                          Boolean(formik.touched.address)
                        }
                        helperText={
                          Boolean(formik.errors.address) &&
                          Boolean(formik.touched.address) &&
                          formik.errors.address
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box className={AddressContainer}>
                    <FormControl
                      fullWidth
                      // error={
                      //   Boolean(formik.errors.country) &&
                      //   Boolean(formik.touched.country)
                      // }
                    >
                      <Select
                        // options={options}
                        options={allcountries}
                        value={country}
                        onChange={(e) => {
                          setcountry({
                            value: e.label,
                            label: e.label,
                            isoCode: e?.value?.isoCode,
                          });
                          setstates(null);
                          setcity(null);
                        }}
                        placeholder="Select Country"
                        styles={ReactSelectTheme(false)}
                      />
                      {errors?.country ? (
                        <Typography color={"error"} sx={{ fontSize: "12px" }}>
                          Please Enter Country
                        </Typography>
                      ) : null}
                      {/* <Select
                        // options={options}
                        options={allcountries}
                        value={formik.values.country}
                        onChange={(value) => {
                          formik.setFieldValue("country", value);
                        }}
                        placeholder="Select Country"
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor:
                              Boolean(formik.errors.country) &&
                              Boolean(formik.touched.country)
                                ? "red"
                                : "#bdbdbd",
                          }),
                        }}
                      />
                      <FormHelperText>
                        {Boolean(formik.errors.country) &&
                        Boolean(formik.touched.country)
                          ? formik.errors.country
                          : ""}
                      </FormHelperText> */}
                    </FormControl>
                    <FormControl fullWidth>
                      <Select
                        isDisabled={!country ? true : false}
                        options={allstates}
                        value={states}
                        placeholder="Select State"
                        onChange={(e) => {
                          console.log(e);
                          setstates({
                            label: e?.label,
                            value: e?.label,
                            isoCode: e?.value?.isoCode,
                            countryCode: e?.value?.countryCode,
                          });
                          setcity(null);
                        }}
                        styles={ReactSelectTheme(false)}
                      />
                      {errors?.state ? (
                        <Typography color={"error"} sx={{ fontSize: "12px" }}>
                          Please Enter State
                        </Typography>
                      ) : null}
                      {/* <TextField
                        name="state"
                        value={formik.values?.state}
                        placeholder="State"
                        size={"small"}
                        fullWidth
                        onChange={formik.handleChange}
                        error={
                          Boolean(formik.errors.state) &&
                          Boolean(formik.touched.state)
                        }
                        helperText={
                          Boolean(formik.errors.state) &&
                          Boolean(formik.touched.state) &&
                          formik.errors.state
                        }
                      /> */}
                    </FormControl>
                    <FormControl fullWidth>
                      <Select
                        isDisabled={!states ? true : false}
                        options={allcities}
                        value={city}
                        placeholder="Select City"
                        onChange={(e) => {
                          console.log(e);
                          setcity({
                            label: e?.label,
                            value: e?.label,
                            isoCode: e?.value?.isoCode,
                            countryCode: e?.value?.countryCode,
                          });
                        }}
                        styles={ReactSelectTheme(false)}
                      />
                      {errors?.city ? (
                        <Typography color={"error"} sx={{ fontSize: "12px" }}>
                          Please Enter City
                        </Typography>
                      ) : null}
                      {/* <TextField
                        name="city"
                        value={formik.values?.city}
                        placeholder="City"
                        size={"small"}
                        onChange={formik.handleChange}
                        fullWidth
                        error={
                          Boolean(formik.errors.city) &&
                          Boolean(formik.touched.city)
                        }
                        helperText={
                          Boolean(formik.errors.city) &&
                          Boolean(formik.touched.city) &&
                          formik.errors.city
                        }
                      /> */}
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        name="postCode"
                        value={formik.values?.postCode}
                        onChange={formik.handleChange}
                        type="number"
                        placeholder={"Postal Code"}
                        size={"small"}
                        fullWidth
                        error={
                          Boolean(formik.errors.postCode) &&
                          Boolean(formik.touched.postCode)
                        }
                        helperText={
                          Boolean(formik.errors.postCode) &&
                          Boolean(formik.touched.postCode) &&
                          formik.errors.postCode
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box className={textAreaContainer}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.industry) &&
                    Boolean(formik.touched.industry)
                  }
                >
                  <Typography>Industry</Typography>
                  <Select
                    name="industry"
                    options={industryOptions}
                    value={formik.values.industry}
                    onChange={(value) => {
                      formik.setFieldValue("industry", value);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select Industry"
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.industry) &&
                    //       Boolean(formik.touched.industry)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.industry) &&
                        Boolean(formik.touched.industry)
                    )}
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.industry) &&
                    Boolean(formik.touched.industry)
                      ? formik.errors.industry
                      : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.types) &&
                    Boolean(formik.touched.types)
                  }
                >
                  <Typography>Types</Typography>
                  <Select
                    name="types"
                    options={typesOptions}
                    value={formik.values.types}
                    onBlur={formik.handleBlur}
                    onChange={(value) => {
                      formik.setFieldValue("types", value);
                    }}
                    placeholder="Select Types"
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.types) &&
                    //       Boolean(formik.touched.types)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.types) &&
                        Boolean(formik.touched.types)
                    )}
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.types) &&
                    Boolean(formik.touched.types)
                      ? formik.errors.types
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box className={textAreaContainer}>
                <Box className={AddressContainer}>
                  <FormControl fullWidth>
                    <Typography>Contact Number</Typography>
                    <PhoneInput
                      inputStyle={{
                        // backgroundColor:"yellow",
                        width: "100%",
                        height: "40px",
                      }}
                      country={"pk"}
                      value={phone}
                      // onChange={(e, data) => handleMobile(e, data.dialCode)}
                      onChange={(e, data) => handleMobile(e, data.dialCode)}
                      containerStyle={{ width: "100%", height: "40px" }}
                    />
                    {errors?.phone ? (
                      <Typography color={"error"} sx={{ fontSize: "12px" }}>
                        Please Enter Contact Number
                      </Typography>
                    ) : null}
                    {/* <TextField
                      type="number"
                      name="contact"
                      value={formik.values?.contact}
                      size={"small"}
                      fullWidth
                      onChange={(e) => {
                        if (e.target.value?.length > 14) {
                          return setsnackBarData({
                            type: "warning",
                            message: "Maximum length for Contact No Reached",
                            openToast: true,
                          });
                        } else {
                          formik.handleChange({
                            target: {
                              name: "contact",
                              value: e.target.value,
                            },
                          });
                        }
                      }}
                      error={
                        Boolean(formik.errors.contact) &&
                        Boolean(formik.touched.contact)
                      }
                      helperText={
                        Boolean(formik.errors.contact) &&
                        Boolean(formik.touched.contact) &&
                        formik.errors.contact
                      }
                    /> */}
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography>Phone</Typography>
                    <PhoneInput
                      inputStyle={{
                        // backgroundColor:"yellow",
                        width: "100%",
                        height: "40px",
                      }}
                      country={"pk"}
                      value={phone2}
                      // onChange={(e, data) => handleMobile(e, data.dialCode)}
                      onChange={(e, data) => handleMobile2(e, data.dialCode)}
                      containerStyle={{ width: "100%", height: "40px" }}
                    />
                    {errors?.phone2 ? (
                      <Typography color={"error"} sx={{ fontSize: "12px" }}>
                        Please Enter Phone
                      </Typography>
                    ) : null}
                    {/* <TextField
                      type="number"
                      name="phone"
                      value={formik.values?.phone}
                      size={"small"}
                      fullWidth
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.phone) &&
                        Boolean(formik.touched.phone)
                      }
                      helperText={
                        Boolean(formik.errors.phone) &&
                        Boolean(formik.touched.phone) &&
                        Boolean(formik.errors.phone)
                      }
                    /> */}
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography>Website</Typography>
                    <TextField
                      name="website"
                      value={formik.values?.website}
                      size={"small"}
                      fullWidth
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>

                <Box className={AddressContainer}>
                  <FormControl fullWidth>
                    <Typography>Description</Typography>
                    <TextField
                      name="description"
                      value={formik.values?.description}
                      multiline
                      minRows={8}
                      size={"small"}
                      fullWidth
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default CreateOrganization;
