import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  FormControl,
  Autocomplete,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import { CreateLeadspi } from "../../Api/LeadsApi/CreateLeadsApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useEffect } from "react";
import { UpdateLeadApi } from "../../Api/LeadsApi/UpdateLeadApi";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppTooltip from "../../Utils/AppTooltip";

import { ReactSelectTheme } from "../../Utils/ReactSelectTheme";
import Activity from "./LeadView/Activity/Activity";
// import Cookies from "universal-cookie";

const options = [
  { value: "Product", label: "Product" },
  { value: "Service", label: "Service" },
];

const validationSchema = Yup.object().shape({
  leadUnits: Yup.array().when("businessType", {
    is: (value) => value !== "General",
    then: () =>
      Yup.array().of(
        Yup.object().shape({
          projectId: Yup.string().required("Field Required!"),
          categoryId: Yup.string().required("Field Required!"),
          sectionId: Yup.string(),
          unitId: Yup.string(),
          leadUnitId: Yup.string(),
          allSections: Yup.array(),
          allUnits: Yup.array(),
        })
      ),
    otherwise: () =>
      Yup.array().of(
        Yup.object().shape({
          projectId: Yup.string(),
          categoryId: Yup.string(),
          unitId: Yup.string(),
          sectionId: Yup.string(),
          leadUnitId: Yup.string(),
          allSections: Yup.array(),
          allUnits: Yup.array(),
        })
      ),
  }),
  leadValue: Yup.string().when("businessType", {
    is: (value) => value !== "General",
    then: () => Yup.string().required("Field Required!"),
    otherwise: () => Yup.string(),
  }),
});

function Products({
  handleBack,
  leadsPopulate,
  // contactPersonData,
  detailsData,
  leadsViewData,
  leadsPiplineData,
  setCustomer,
  customer,
  // state,
  title,
  setTitle,
  publicLeadData,
}) {
  const { formContainer } = useStyle();
  // const cookies = new Cookies();

  const [product, setproduct] = useState([
    { Name: "", productId: "", price: 0, quantity: 0, amount: 0 },
  ]);
  const [formErrors, setFormErrors] = useState(null);
  const [type, setType] = useState([{ value: "", label: "" }]);
  const [loading, setloading] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [leadId, setLeadId] = useState(null);

  const naviagate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);

  const handleAddContactField = () => {
    setproduct([
      ...product,
      { Name: "", productId: "", price: 0, quantity: 0, amount: 0 },
    ]);
    setType([...type, { value: "", label: "" }]);
  };

  useEffect(() => {
    let arr = [];
    let arr2 = [];
    if (leadsViewData) {
      leadsViewData?.LeadProducts?.map((prod) => {
        leadsPopulate?.productData?.find(
          (item) => prod?.ProductId === item?.Id
        );
        // arr2.push(abc);
        arr.push({
          Id: prod?.Id,
          Name: prod?.Name,
          productId: prod?.ProductId,
          price: prod?.Price,
          quantity: prod?.Quantity,
          amount: prod?.Amount,
        });
        if (prod.Type === "Good") {
          arr2.push({ value: "Product", label: "Product" });
        } else if (prod.Type === "Service") {
          arr2.push({ value: "Service", label: "Service" });
        }
      });

      setproduct(arr);
      setType(arr2);
    }
  }, [leadsViewData]);

  useEffect(() => {
    product?.map((item, i) => {
      if (!product[i].Name == "") {
        setFormErrors({ ...formErrors, [`Name${i}`]: false });
        return;
      }
      if (product[i].price != 0) {
        setFormErrors({ ...formErrors, [`price${i}`]: false });
      }
    });
    product?.map((item, i) => {
      if (product[i].price != 0) {
        setFormErrors({ ...formErrors, [`price${i}`]: false });
      }
    });
    product?.map((item, i) => {
      if (product[i].quantity != 0 || product[i].quantity != null) {
        setFormErrors({ ...formErrors, [`quantity${i}`]: false });
      }
    });
  }, [product]);

  const handleSubmit = async (values, { resetForm }) => {
    if (values.businessType === "General") {
      let nameValidation = false;
      let priceValidation = false;
      let quantityValidation = false;
      product?.map((item, i) => {
        if (product[i].Name == "" || product[i].Name == null) {
          nameValidation = true;
          setFormErrors({ ...formErrors, [`Name${i}`]: true });
          return;
        } else if (product[i].price == "" || product[i].price == 0) {
          priceValidation = true;
          setFormErrors({ ...formErrors, [`price${i}`]: true });
        } else if (
          product[i].quantity == "" ||
          product[i].quantity == 0 ||
          product[i].quantity == null
        ) {
          quantityValidation = true;
          setFormErrors({ ...formErrors, [`quantity${i}`]: true });
        }
      });

      if (nameValidation || priceValidation || quantityValidation) {
        return;
      }
    }

    setloading(true);
    let arr = [];
    let arr2 = [];
    let coUserArr = [];
    const obj = {
      ...detailsData,
    };
    const obj2 = {
      ...detailsData,
    };

    if (values.businessType === "General") {
      product?.map((produ) => {
        arr.push({
          id: produ?.Id ? produ?.Id : 0,
          productId: produ?.productId,
          price: produ?.price,
          quantity: produ?.quantity,
          amount: produ?.amount,
        });
      });
      product?.map((produ) => {
        arr2.push({
          productId: produ?.productId,
          price: produ?.price,
          quantity: produ?.quantity,
          amount: produ?.amount,
        });
      });
      obj.leadProducts = arr2;
      obj2.leadProducts = arr;

      // const obj = {
      //   leadProducts: arr2,
      //   ...detailsData,
      //   personId: contactPersonData?.names?.Id,
      // };
      // const obj2 = {
      //   leadProducts: arr,
      //   ...detailsData,
      //   personId: contactPersonData?.names?.Id,
      //    };
    } else {
      values.leadUnits?.forEach((item) => {
        let unit = {
          leadUnitId: item?.leadUnitId,
          projectId: item?.projectId,
          categoryId: item?.categoryId,
        };

        if (item?.sectionId) {
          unit.sectionId = item?.sectionId;
        }

        if (item?.unitId) {
          unit.unitId = item?.unitId;
        }
        arr.push(unit);
      });

      values.leadUnits?.forEach((item) => {
        let unit = {
          projectId: item?.projectId,
          categoryId: item?.categoryId,
        };

        if (item?.sectionId) {
          unit.sectionId = item?.sectionId;
        }

        if (item?.unitId) {
          unit.unitId = item?.unitId;
        }
        arr2.push(unit);
      });
      console.log("values", values);
      detailsData?.coLeadUsers?.forEach((item) => {
        console.log("item", item);
        coUserArr.push({ userId: item?.Id });
      });
      console.log("couserArr", coUserArr);
      obj.leadUnits = arr2;
      obj.leadValue = values.leadValue;
      obj.otherLeadUsers = coUserArr;

      obj2.leadUnits = arr2;
      obj2.leadValue = values.leadValue;
      obj2.otherLeadUsers = coUserArr;
    }

    if (leadsViewData) {
      const response = await UpdateLeadApi({
        id: leadsViewData?.Id,
        obj: obj2,
      });
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        resetForm();
        setloading(false);
        naviagate(-1);
      }
    } else if (leadsPiplineData) {
      const obj2 = {
        ...detailsData,
        // leadProducts: arr,
        // personId: contactPersonData?.names?.Id,
        pipelineId: leadsPiplineData?.LeadPipelineId,
        pipelineStageId: leadsPiplineData?.Id,
        leadValue: values.leadValue,
      };
      if (values.businessType === "General") {
        obj2.leadProducts = arr;
      } else {
        obj2.leadUnits = arr2;
        obj2.otherLeadUsers = coUserArr;
      }
      console.log("obj2", obj2);
      const response = await CreateLeadspi(obj2);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        resetForm();
        setloading(false);
        setshowModal(true);
        setLeadId(response?.data?.Data);
        // naviagate(-1);
      }
    } else {
      console.log("obj", obj);
      const response = await CreateLeadspi(obj);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        resetForm();
        setloading(false);
        setshowModal(true);
        setLeadId(response?.data?.Data);

        // naviagate(-1);
      }
    }
    setloading(false);
  };

  const handleNavigate = () => {
    handleBack();
  };

  const calculateUnitsTotal = (values, setFieldValue, index) => {
    let leadValue = 0;
    values.leadUnits?.forEach((unit, idx) => {
      if (index === idx) {
        leadValue += 0;
      } else {
        leadValue +=
          leadsPopulate?.Data?.categoryData
            ?.find((item) => item?.CategoryId === unit?.categoryId)
            ?.Sections?.find((item) => item?.SectionId === unit?.sectionId)
            ?.Units?.find((item) => item?.UnitId === unit?.unitId)?.Price || 0;
      }
    });
    setFieldValue("leadValue", leadValue == 0 ? "" : leadValue);
  };
  return (
    <Paper elevation={3} sx={{ padding: "20px" }}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          businessType: sessionStorage.getItem("BusinessType"),
          leadValue: leadsViewData?.LeadValue || "",
          leadUnits: leadsViewData?.LeadUnits?.length
            ? leadsViewData?.LeadUnits?.map((unit) => {
                return {
                  leadUnitId: unit?.LeadUnitId || "",
                  projectId: unit?.Project?.ProjectId || "",
                  categoryId: unit?.Category?.CategoryId || "",
                  sectionId: unit?.Section?.SectionId || "",
                  unitId: unit?.Unit?.UnitId || "",
                  allSections:
                    leadsPopulate?.Data?.categoryData?.find(
                      (item) => item?.CategoryId === unit?.Category?.CategoryId
                    )?.Sections || [],
                  allUnits:
                    leadsPopulate?.Data?.categoryData
                      ?.find(
                        (item) =>
                          item?.CategoryId === unit?.Category?.CategoryId
                      )
                      ?.Sections?.find(
                        (item) => item?.SectionId === unit?.Section?.SectionId
                      )?.Units || [],
                };
              })
            : publicLeadData?.Project
            ? [
                {
                  leadUnitId: "",
                  projectId: publicLeadData?.Project?.ProjectId || "",
                  categoryId: publicLeadData?.Category?.CategoryId || "",
                  sectionId: publicLeadData?.Section?.SectionId || "",
                  unitId: publicLeadData?.Unit?.UnitId || "",
                  allSections:
                    leadsPopulate?.Data?.categoryData?.find(
                      (item) =>
                        item?.CategoryId ===
                        publicLeadData?.Category?.CategoryId
                    )?.Sections || [],
                  allUnits:
                    leadsPopulate?.Data?.categoryData
                      ?.find(
                        (item) =>
                          item?.CategoryId ===
                          publicLeadData?.Category?.CategoryId
                      )
                      ?.Sections?.find(
                        (item) =>
                          item?.SectionId === publicLeadData?.Section?.SectionId
                      )?.Units || [],
                },
              ]
            : [
                {
                  leadUnitId: "",
                  projectId: "",
                  categoryId: "",
                  sectionId: "",
                  unitId: "",
                  allSections: [],
                  allUnits: [],
                },
              ],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => {
          return (
            <Form noValidate>
              <Box className={formContainer}>
                {values.businessType === "General" ? (
                  <>
                    {product &&
                      product?.map((prod, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <AppTooltip title="Remove">
                                <IconButton
                                  disabled={product?.length == 1}
                                  onClick={() => {
                                    let productsArr = [...product];
                                    let typesArr = [...type];

                                    productsArr?.splice(i, 1);
                                    typesArr?.splice(i, 1);
                                    setproduct(productsArr);
                                    setType(typesArr);
                                  }}
                                >
                                  <DeleteForeverIcon
                                    sx={{
                                      color: (theme) =>
                                        theme?.palette?.primary?.delete,
                                      cursor: "pointer",
                                    }}
                                  />
                                </IconButton>
                              </AppTooltip>
                            </Box>

                            <FormControl fullWidth>
                              <Typography>Type</Typography>
                              <Select
                                name="serviceCat"
                                options={options}
                                value={type[i]}
                                onChange={(e) => {
                                  let value = JSON.parse(
                                    JSON.stringify(type || [])
                                  );
                                  // formik.setFieldValue("productId",valuess);
                                  value[i].value = e.label;
                                  value[i].label = e.label;

                                  setType(value);
                                  let value2 = JSON.parse(
                                    JSON.stringify(product || [])
                                  );

                                  value2[i].Name = "";
                                  value2[i].quantity = 0;
                                  value2[i].price = 0;
                                  value2[i].amount = 0;

                                  setproduct(value2);
                                }}
                                styles={ReactSelectTheme(false)}
                                placeholder="Select Type"
                              />
                            </FormControl>

                            <FormControl fullWidth>
                              <Typography>Item</Typography>
                              <Autocomplete
                                options={
                                  type[i]?.label === "Product"
                                    ? leadsPopulate?.Data?.productData
                                      ? leadsPopulate?.Data?.productData
                                      : []
                                    : type[i]?.label === "Service"
                                    ? leadsPopulate?.Data?.serviceData
                                      ? leadsPopulate?.Data?.serviceData
                                      : []
                                    : []
                                }
                                getOptionLabel={(option) => option.Name || ""}
                                name="productId"
                                value={prod}
                                // value={leadsViewData?product:null}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="type to search records"
                                  />
                                )}
                                size={"small"}
                                fullWidth
                                onChange={(__, valuess) => {
                                  // formik.setFieldValue("productId",valuess);
                                  let abc = false;
                                  product?.map((item) => {
                                    if (item?.Name == valuess?.Name) {
                                      abc = true;
                                      setsnackBarData({
                                        type: "error",
                                        message:
                                          "You Already Added this Product ",
                                        openToast: true,
                                      });
                                    }
                                  });
                                  if (abc) {
                                    return;
                                  } else {
                                    let value = JSON.parse(
                                      JSON.stringify(product || [])
                                    );

                                    value[i].Name = valuess?.Name;
                                    value[i].productId = valuess?.Id;
                                    value[i].quantity = valuess?.Id
                                      ? valuess?.Quantity
                                      : 0;
                                    value[i].price = valuess?.Id
                                      ? valuess?.Price
                                      : 0;
                                    value[i].amount = valuess?.Id
                                      ? valuess?.Price * valuess?.Quantity
                                      : 0;

                                    setproduct(value);
                                  }
                                }}
                              />
                              {formErrors && formErrors[`Name${i}`] ? (
                                <Typography
                                  color={"error"}
                                  sx={{ fontSize: "12px" }}
                                >
                                  Please Enter Name
                                </Typography>
                              ) : null}
                            </FormControl>

                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <FormControl fullWidth>
                                <Typography>Price</Typography>
                                <TextField
                                  name="price"
                                  size={"small"}
                                  type={"number"}
                                  value={prod?.price}
                                  fullWidth
                                  onChange={(e) => {
                                    let value = JSON.parse(
                                      JSON.stringify(product)
                                    );
                                    value[i].price = Number(e.target.value);
                                    value[i].amount =
                                      Number(e.target.value) *
                                      Number(prod?.quantity);
                                    setproduct(value);
                                  }}
                                />
                                {formErrors && formErrors[`price${i}`] ? (
                                  <Typography
                                    color={"error"}
                                    sx={{ fontSize: "12px" }}
                                  >
                                    Please Enter Price
                                  </Typography>
                                ) : null}
                              </FormControl>
                              <FormControl fullWidth>
                                <Typography>Quantity</Typography>
                                <TextField
                                  name="quantity"
                                  type={"number"}
                                  size={"small"}
                                  value={prod?.quantity}
                                  fullWidth
                                  onChange={(e) => {
                                    if (e.target.value >= 0) {
                                      let value = JSON.parse(
                                        JSON.stringify(product)
                                      );
                                      value[i].quantity = Number(
                                        e.target.value
                                      );
                                      value[i].amount =
                                        Number(e.target.value) *
                                        Number(prod?.price);
                                      setproduct(value);
                                    } else {
                                      let value = JSON.parse(
                                        JSON.stringify(product)
                                      );
                                      value[i].quantity = 0;
                                      setproduct(value);

                                      return;
                                    }
                                  }}

                                  // error={Boolean(formik.errors.item) && Boolean(formik.touched.item)}
                                  // helperText={Boolean(formik.errors.item) && formik.errors.item}
                                />
                                {formErrors && formErrors[`quantity${i}`] ? (
                                  <Typography
                                    color={"error"}
                                    sx={{ fontSize: "12px" }}
                                  >
                                    Please Enter Price
                                  </Typography>
                                ) : null}
                              </FormControl>
                              <FormControl fullWidth>
                                <Typography>Amount</Typography>
                                <TextField
                                  name="amount"
                                  type={"number"}
                                  value={prod?.amount}
                                  size={"small"}
                                  disabled
                                  fullWidth
                                  onChange={(e) => {
                                    let value = JSON.parse(
                                      JSON.stringify(product)
                                    );
                                    value[i].amount = Number(e.target.value);
                                    setproduct(value);
                                  }}

                                  // error={Boolean(formik.errors.item) && Boolean(formik.touched.item)}
                                  // helperText={Boolean(formik.errors.item) && formik.errors.item}
                                />
                              </FormControl>
                            </Box>

                            <Divider
                              sx={{
                                width: "80%",
                                backgroundColor: (theme) =>
                                  theme.palette.primary.main,
                                marginTop: "10px",
                                marginInline: "auto",
                              }}
                            />
                          </Box>
                        );
                      })}
                    <Box sx={{ width: "fit-content" }}>
                      <Button
                        size="large"
                        color="info"
                        sx={{ borderRadius: "8px" }}
                        onClick={handleAddContactField}
                      >
                        + Add More
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <FieldArray name="leadUnits">
                      {({ push, remove }) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          {values.leadUnits?.map((unit, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <AppTooltip title="Remove">
                                  <IconButton
                                    disabled={values.leadUnits?.length == 1}
                                    onClick={() => {
                                      remove(index);
                                      if (values.leadUnits[index]?.unitId) {
                                        calculateUnitsTotal(
                                          values,
                                          setFieldValue,
                                          index
                                        );
                                      }
                                    }}
                                  >
                                    <DeleteForeverIcon
                                      sx={{
                                        color: (theme) =>
                                          theme?.palette?.primary?.delete,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </IconButton>
                                </AppTooltip>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: { xs: "column", sm: "row" },
                                  gap: "8px",
                                }}
                              >
                                <FormControl fullWidth>
                                  <Typography>Project</Typography>
                                  <Autocomplete
                                    options={
                                      leadsPopulate?.Data?.projectData || []
                                    }
                                    getOptionLabel={(option) =>
                                      option?.ProjectName || ""
                                    }
                                    sx={{ flex: 1 }}
                                    size={"small"}
                                    fullWidth
                                    value={
                                      leadsPopulate?.Data?.projectData?.find(
                                        (item) =>
                                          item?.ProjectId ===
                                          values.leadUnits[index]?.projectId
                                      ) || null
                                    }
                                    onChange={(event, value) => {
                                      if (value) {
                                        setFieldValue(
                                          `leadUnits[${index}].projectId`,
                                          value?.ProjectId
                                        );
                                      } else {
                                        setFieldValue(
                                          `leadUnits[${index}].projectId`,
                                          ""
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name={`leadUnits[${index}].projectId`}
                                        onBlur={handleBlur}
                                        error={
                                          errors?.leadUnits &&
                                          touched?.leadUnits
                                            ? errors?.leadUnits[index]
                                                ?.projectId &&
                                              touched?.leadUnits[index]
                                                ?.projectId
                                            : false
                                        }
                                        helperText={
                                          errors?.leadUnits &&
                                          touched?.leadUnits
                                            ? errors?.leadUnits[index]
                                                ?.projectId &&
                                              touched?.leadUnits[index]
                                                ?.projectId
                                              ? errors?.leadUnits[index]
                                                  ?.projectId
                                              : ""
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </FormControl>

                                <FormControl fullWidth>
                                  <Typography>Category</Typography>
                                  <Autocomplete
                                    options={
                                      leadsPopulate?.Data?.categoryData || []
                                    }
                                    getOptionLabel={(option) =>
                                      option?.CategoryName
                                    }
                                    sx={{ flex: 1 }}
                                    size={"small"}
                                    fullWidth
                                    value={
                                      leadsPopulate?.Data?.categoryData?.find(
                                        (item) =>
                                          item?.CategoryId ===
                                          values.leadUnits[index]?.categoryId
                                      ) || null
                                    }
                                    onChange={(event, value) => {
                                      if (value) {
                                        setFieldValue(
                                          `leadUnits[${index}].categoryId`,
                                          value?.CategoryId
                                        );
                                        setFieldValue(
                                          `leadUnits[${index}].allSections`,
                                          value?.Sections
                                        );
                                      } else {
                                        setFieldValue(
                                          `leadUnits[${index}].categoryId`,
                                          ""
                                        );
                                        setFieldValue(
                                          `leadUnits[${index}].allSections`,
                                          []
                                        );
                                      }

                                      setFieldValue(
                                        `leadUnits[${index}].sectionId`,
                                        ""
                                      );
                                      setFieldValue(
                                        `leadUnits[${index}].unitId`,
                                        ""
                                      );

                                      if (values.leadUnits[index]?.unitId) {
                                        calculateUnitsTotal(
                                          values,
                                          setFieldValue,
                                          index
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name={`leadUnits[${index}].categoryId`}
                                        onBlur={handleBlur}
                                        error={
                                          errors?.leadUnits &&
                                          touched?.leadUnits
                                            ? errors?.leadUnits[index]
                                                ?.categoryId &&
                                              touched?.leadUnits[index]
                                                ?.categoryId
                                            : false
                                        }
                                        helperText={
                                          errors?.leadUnits &&
                                          touched?.leadUnits
                                            ? errors?.leadUnits[index]
                                                ?.categoryId &&
                                              touched?.leadUnits[index]
                                                ?.categoryId
                                              ? errors?.leadUnits[index]
                                                  ?.categoryId
                                              : ""
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: { xs: "column", sm: "row" },
                                  gap: "8px",
                                }}
                              >
                                <FormControl fullWidth>
                                  <Typography>Section</Typography>
                                  <Autocomplete
                                    options={
                                      values.leadUnits[index]?.allSections || []
                                    }
                                    getOptionLabel={(option) =>
                                      option?.SectionName
                                    }
                                    sx={{ flex: 1 }}
                                    size={"small"}
                                    fullWidth
                                    disabled={
                                      !values.leadUnits[index]?.categoryId
                                    }
                                    value={
                                      values.leadUnits[
                                        index
                                      ]?.allSections?.find(
                                        (item) =>
                                          item?.SectionId ===
                                          values.leadUnits[index]?.sectionId
                                      ) || null
                                    }
                                    onChange={(event, value) => {
                                      if (value) {
                                        setFieldValue(
                                          `leadUnits[${index}].sectionId`,
                                          value?.SectionId
                                        );
                                        setFieldValue(
                                          `leadUnits[${index}].allUnits`,
                                          value?.Units
                                        );
                                      } else {
                                        setFieldValue(
                                          `leadUnits[${index}].sectionId`,
                                          ""
                                        );
                                        setFieldValue(
                                          `leadUnits[${index}].allUnits`,
                                          []
                                        );
                                      }
                                      setFieldValue(
                                        `leadUnits[${index}].unitId`,
                                        ""
                                      );

                                      if (values.leadUnits[index]?.unitId) {
                                        calculateUnitsTotal(
                                          values,
                                          setFieldValue,
                                          index
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name={`leadUnits[${index}].sectionId`}
                                        error={
                                          errors?.leadUnits &&
                                          touched?.leadUnits
                                            ? errors?.leadUnits[index]
                                                ?.sectionId &&
                                              touched?.leadUnits[index]
                                                ?.sectionId
                                            : false
                                        }
                                        helperText={
                                          errors?.leadUnits &&
                                          touched?.leadUnits
                                            ? errors?.leadUnits[index]
                                                ?.sectionId &&
                                              touched?.leadUnits[index]
                                                ?.sectionId
                                              ? errors?.leadUnits[index]
                                                  ?.sectionId
                                              : ""
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </FormControl>

                                <FormControl fullWidth>
                                  <Typography>Unit</Typography>
                                  <Autocomplete
                                    options={
                                      values.leadUnits[index]?.allUnits?.filter(
                                        (item) =>
                                          !values.leadUnits?.some(
                                            (unit) =>
                                              unit?.unitId === item?.UnitId
                                          )
                                      ) || []
                                    }
                                    getOptionLabel={(option) =>
                                      option?.UnitName
                                    }
                                    sx={{ flex: 1 }}
                                    size={"small"}
                                    fullWidth
                                    disabled={
                                      !values.leadUnits[index]?.sectionId
                                    }
                                    value={
                                      values.leadUnits[index]?.allUnits?.find(
                                        (item) =>
                                          item?.UnitId ===
                                          values.leadUnits[index]?.unitId
                                      ) || null
                                    }
                                    onChange={(event, value) => {
                                      if (value) {
                                        setFieldValue(
                                          `leadUnits[${index}].unitId`,
                                          value?.UnitId
                                        );

                                        let leadValue = 0;
                                        values.leadUnits?.forEach(
                                          (unit, idx) => {
                                            if (index === idx) {
                                              leadValue += value?.Price;
                                            } else {
                                              leadValue +=
                                                leadsPopulate?.Data?.categoryData
                                                  ?.find(
                                                    (item) =>
                                                      item?.CategoryId ===
                                                      unit?.categoryId
                                                  )
                                                  ?.Sections?.find(
                                                    (item) =>
                                                      item?.SectionId ===
                                                      unit?.sectionId
                                                  )
                                                  ?.Units?.find(
                                                    (item) =>
                                                      item?.UnitId ===
                                                      unit?.unitId
                                                  )?.Price || 0;
                                            }
                                          }
                                        );
                                        setFieldValue(
                                          "leadValue",
                                          leadValue == 0 ? "" : leadValue
                                        );
                                      } else {
                                        setFieldValue(
                                          `leadUnits[${index}].unitId`,
                                          ""
                                        );

                                        calculateUnitsTotal(
                                          values,
                                          setFieldValue,
                                          index
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name={`leadUnits[${index}].unitId`}
                                        error={
                                          errors?.leadUnits &&
                                          touched?.leadUnits
                                            ? errors?.leadUnits[index]
                                                ?.unitId &&
                                              touched?.leadUnits[index]?.unitId
                                            : false
                                        }
                                        helperText={
                                          errors?.leadUnits &&
                                          touched?.leadUnits
                                            ? errors?.leadUnits[index]
                                                ?.unitId &&
                                              touched?.leadUnits[index]?.unitId
                                              ? errors?.leadUnits[index]?.unitId
                                              : ""
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Box>

                              <Divider
                                sx={{
                                  width: "80%",
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main,
                                  marginTop: "10px",
                                  marginInline: "auto",
                                }}
                              />
                            </Box>
                          ))}
                          <Box sx={{ width: "fit-content" }}>
                            <Button
                              size="large"
                              color="info"
                              sx={{ borderRadius: "8px" }}
                              onClick={() => {
                                push({
                                  unitId: "",
                                  sectionId: "",
                                  projectId: "",
                                  categoryId: "",
                                  allSections: [],
                                  allUnits: [],
                                });
                              }}
                            >
                              + Add More
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </FieldArray>

                    <FormControl fullWidth>
                      <Typography>Lead Value</Typography>
                      <TextField
                        type={"number"}
                        size={"small"}
                        fullWidth
                        value={Math.round(values?.leadValue)}
                        name="leadValue"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors?.leadValue && touched?.leadValue}
                        helperText={
                          errors?.leadValue &&
                          touched?.leadValue &&
                          errors?.leadValue
                        }
                      />
                    </FormControl>
                  </>
                )}

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <Button onClick={handleNavigate}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Back
                        </Typography>
                      </Button>
                    </Box>
                    {loading ? (
                      <Box>
                        <CircularProgress />{" "}
                      </Box>
                    ) : (
                      <Box>
                        <Button
                          type="submit"
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.primary.main,
                            color: (theme) => theme.palette.white.main,
                          }}
                        >
                          {leadsViewData ? "Update" : "Save"}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
      {showModal && (
        <Activity
          leadId={leadId}
          setshowModal={setshowModal}
          showModal={showModal}
          customer={customer}
          setCustomer={setCustomer}
          title={title}
          setTitle={setTitle}
          activityModal
        />
      )}
    </Paper>
  );
}

export default Products;
Products.propTypes = {
  handleBack: PropTypes.any,
  leadsPopulate: PropTypes.any,
  detailsData: PropTypes.any,
  contactPersonData: PropTypes.any,
  leadsViewData: PropTypes.any,
  // state: PropTypes.any,
  leadsPiplineData: PropTypes.any,
  customer: PropTypes.any,
  setCustomer: PropTypes.any,
  title: PropTypes.any,
  setTitle: PropTypes.any,
  publicLeadData: PropTypes.any,
};

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
});
