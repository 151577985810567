import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import Loader from "../../Components/Loader/Loader";
import { Badge, Button, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../Redux/Slice/ProductSlice/ProductSlice";
import ProductFilterDrawer from "../../Components/Products/ProductFilterDrawer/ProductFilterDrawer";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { getProductsDetails } from "../../Redux/Slice/ProductSlice/ProductSlice";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DeleteProductApi } from "../../Api/ProductsApi/DeleteProductApi";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ProductsInfoModal from "./ProductsInfoModal";
import DocumentModal from "../../Components/DocumentModal/DocumentModal";
import DocumentDetailModal from "../../Components/DocumentModal/DocumentDetailModal";
import {
  fetchDocuments,
  getOrganizationDetails,
} from "../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import ArticleIcon from "@mui/icons-material/Article";
import StockModal from "../../Components/Products/CreateProduct/StockModal";
import { AppContext } from "../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import AppTooltip from "../../Utils/AppTooltip";
import ProductsInfoModal2 from "./ProductsInfoModal2";
// import Cookies from "universal-cookie";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
import AppConfirmationDialog from "../../Components/AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

function Products({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const navigate = useNavigate();
  const [pagesize, setpagesize] = useState(totalRows);
  const dispatch = useDispatch();
  const [rows, setrows] = React.useState([]);
  const [allProduct, setallProduct] = useState([]);
  const [product, setProduct] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const { Products, status } = useSelector(getProductsDetails);
  const [showLoading, setshowLoading] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const SKU = useRef();
  const name = useRef();
  const priceRef = useRef();
  const quantityRef = useRef();
  const costRef = useRef();
  console.log(setProduct);
  const [showModal, setShowModal] = useState(false);
  const [showStockModal, setShowStockModal] = useState(false);
  const [state1, setState1] = useState(null);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [documentDetailModal, setdocumentDetailModal] = useState(false);
  const { DocumentsData, status2 } = useSelector(getOrganizationDetails);
  const { setState, state } = useContext(AppContext);
  const [productInfoModal, setproductInfoModal] = useState(false);
  const [productRow, setproductRow] = useState(null);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Products");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  const handleClosed = () => {
    setShowModal(false);
  };
  const handleCloseStock = () => {
    setShowStockModal(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: "30%" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "end",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "2px",
                    backgroundColor: "green",
                    cursor: "pointer",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "14px" }}>Available</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "2px",
                    backgroundColor: "#dd284c",
                    cursor: "pointer",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "14px" }}>Not Available</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    // if (Products == null) {
    dispatch(fetchProducts());
    // }
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    Products?.map((product, i) => {
      console.log("product", product);
      rowData.push({
        id: i + 1,
        sku: product.Sku,
        name: product.Name,
        price: product.Price?.toLocaleString(),
        cost: product.Cost?.toLocaleString(),
        quantity: product.Quantity,
        ID: product.Id,
        Description: product.Description,
        Uom: product.Uom,
        Tax: product.Tax,
        ProductCode: product.ProductCode,
        ProductCategory: product.ProductCategory,
        CommissionPercentage: product.CommissionPercentage,
        Active: product.Active,
        Image: product.Image,
        ImageName: product.ImageName,
        IsDocument: product?.IsDocument,
      });
    });
    setrows(rowData);
    setallProduct(rowData);
  }, [Products]);
  const deleteProduct = async (id) => {
    setshowLoading(true);
    let res = await DeleteProductApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);
    if (res.data.IsSuccess) {
      dispatch(fetchProducts());
    }
    setsnackBarData(res.snackBarData);
  };
  const columns = roles?.some((role) => role === "Products_Edit")
    ? [
        { field: "id", headerName: "Sr", width: 90 },
        {
          field: "name",
          headerName: "Name",
          width: 150,
        },
        {
          field: "price",
          headerName: "Price",
          width: 110,
        },
        {
          field: "quantity",
          headerName: "Quantity",
          width: 160,
        },
        {
          field: "sku",
          headerName: "SKU",
          width: 180,
        },
        {
          field: "cost",
          headerName: "Cost",
          width: 180,
        },
        {
          field: "IsDocument",
          headerName: "Documents",
          width: 150,
          renderCell: (celval, i) => {
            return (
              <>
                <AppTooltip
                  title={
                    celval?.row?.IsDocument == true
                      ? "Docements Available"
                      : "No Docements"
                  }
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "2px",
                      backgroundColor:
                        celval?.row?.IsDocument == true ? "green" : "#dd284c",
                      cursor: "pointer",
                    }}
                    key={i}
                  ></Box>
                </AppTooltip>
              </>
            );
          },
        },
        {
          field: "stock",
          headerName: "Stock",
          width: 100,
          renderCell: (celval, i) => {
            return (
              <button
                id="product-stock"
                key={i}
                onClick={() => {
                  setState1(celval.row);
                  setShowStockModal(true);
                }}
                style={{
                  // Default MUI theme styles for outlined variant
                  border: `1px solid #298b8b`,
                  color: "#298b8b",
                  padding: "8px 16px",
                  cursor: "pointer",
                  background: "transparent",
                  borderRadius: "4px",
                }}
              >
                Add
              </button>
            );
          },
        },
        {
          field: "action",
          headerName: "Action",
          width: 160,
          renderCell: (celval, i) => {
            return (
              <Box key={i}>
                {roles?.some((role) => role === "Products_Edit") && (
                  <Tooltip title="Edit">
                    <IconButton
                      key={i}
                      onClick={() => {
                        handleEdit(celval.row);
                      }}
                    >
                      <EditIcon
                        sx={{ color: (theme) => theme?.palette?.primary?.edit }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {roles?.some((role) => role === "Products_Delete") && (
                  <Tooltip title="Delete">
                    <IconButton
                      key={i}
                      onClick={() => {
                        setselectedId(celval.row.ID);
                        setshowConfirmationIcons(true);
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.delete,
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="View">
                  <IconButton
                    key={i}
                    onClick={() => {
                      console.log("celval.row", celval.row);
                      setproductRow(celval.row);
                      setproductInfoModal(true);
                      // setShowModal(true);
                      // setProduct(celval.row);
                      // dispatch(fetchproduct(celval.row?.ID));
                    }}
                  >
                    <VisibilityIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="View Document">
                  <IconButton
                    key={i}
                    onClick={async () => {
                      // setShowModal(true);
                      const response = await dispatch(
                        fetchDocuments({
                          type: "Products",
                          id: celval.row?.ID,
                        })
                      );
                      if (response?.payload?.data?.IsSuccess == true) {
                        setdocumentDetailModal(true);
                      } else {
                        setsnackBarData({
                          type: "error",
                          message: "Something wrong",
                          openToast: true,
                        });
                      }
                      console.log(response);

                      // setdocumentDetailModal(true);
                      setselectedRow(celval.row);
                      // setselectedId(celval.row.ID);
                    }}
                  >
                    <ArticleIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        },
      ]
    : [
        { field: "id", headerName: "Sr", width: 90 },
        {
          field: "name",
          headerName: "Name",
          width: 150,
        },
        {
          field: "price",
          headerName: "Price",
          width: 110,
        },
        {
          field: "quantity",
          headerName: "Quantity",
          width: 160,
        },
        {
          field: "sku",
          headerName: "SKU",
          width: 180,
        },
        {
          field: "cost",
          headerName: "Cost",
          width: 180,
        },
        {
          field: "IsDocument",
          headerName: "Documents",
          width: 150,
          renderCell: (celval, i) => {
            return (
              <>
                <AppTooltip
                  title={
                    celval?.row?.IsDocument == true
                      ? "Docements Available"
                      : "No Docements"
                  }
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "2px",
                      backgroundColor:
                        celval?.row?.IsDocument == true ? "green" : "#dd284c",
                      cursor: "pointer",
                    }}
                    key={i}
                  ></Box>
                </AppTooltip>
              </>
            );
          },
        },
        {
          field: "action",
          headerName: "Action",
          width: 160,
          renderCell: (celval, i) => {
            return (
              <Box key={i}>
                {roles?.some((role) => role === "Products_Edit") && (
                  <Tooltip title="Edit">
                    <IconButton
                      key={i}
                      onClick={() => {
                        handleEdit(celval.row);
                      }}
                    >
                      <EditIcon
                        sx={{ color: (theme) => theme?.palette?.primary?.edit }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {roles?.some((role) => role === "Products_Delete") && (
                  <Tooltip title="Delete">
                    <IconButton
                      key={i}
                      onClick={() => {
                        setselectedId(celval.row.ID);
                        setshowConfirmationIcons(true);
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.delete,
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="View">
                  <IconButton
                    key={i}
                    onClick={() => {
                      console.log("celval.row", celval.row);
                      setproductRow(celval.row);
                      setproductInfoModal(true);
                      // setShowModal(true);
                      // setProduct(celval.row);
                      // dispatch(fetchproduct(celval.row?.ID));
                    }}
                  >
                    <VisibilityIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="View Document">
                  <IconButton
                    key={i}
                    onClick={async () => {
                      // setShowModal(true);
                      const response = await dispatch(
                        fetchDocuments({
                          type: "Products",
                          id: celval.row?.ID,
                        })
                      );
                      if (response?.payload?.data?.IsSuccess == true) {
                        setdocumentDetailModal(true);
                      } else {
                        setsnackBarData({
                          type: "error",
                          message: "Something wrong",
                          openToast: true,
                        });
                      }
                      console.log(response);

                      // setdocumentDetailModal(true);
                      setselectedRow(celval.row);
                      // setselectedId(celval.row.ID);
                    }}
                  >
                    <ArticleIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
        },
      ];

  const handleEdit = (val) => {
    navigate("/products/create", { state1: val });
  };
  const handleChangeVal = (e) => {
    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value,
    });
  };
  const onTextChange = () => {
    if (
      SKU.current?.value === "" &&
      name.current?.value === "" &&
      priceRef.current?.value === "" &&
      quantityRef.current?.value === "" &&
      costRef.current?.value === ""
    ) {
      setallProduct(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(SKU.current.value, "i");
      let regex2 = new RegExp(name.current.value, "i");
      let regex3 = new RegExp(priceRef.current.value, "i");
      let regex4 = new RegExp(quantityRef.current.value, "i");
      let regex5 = new RegExp(costRef.current.value, "i");
      suggestions = rows?.filter(
        (val) =>
          (SKU.current.value === "" || regex1.test(val.sku)) &&
          (name.current.value === "" || regex2.test(val?.name)) &&
          (priceRef.current.value === "" ||
            regex3.test(val?.price.replace(/,/g, ""))) &&
          (quantityRef.current.value === "" ||
            regex4.test(val?.quantity.replace(/,/g, ""))) &&
          (costRef.current.value === "" ||
            regex5.test(val?.cost.replace(/,/g, "")))
      );
      setallProduct(suggestions);
    }
  };
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Products"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="product">
        <ProductFilterDrawer
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          priceRef={priceRef}
          quantityRef={quantityRef}
          costRef={costRef}
          setcount={setcount}
          setallProduct={setallProduct}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          SKU={SKU}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allProduct}
            columns={columns}
          />
          {status === "loading" || status2 === "loading" || showLoading ? (
            <Loader />
          ) : null}
        </Box>
      </Box>
      {showModal2 && (
        <DocumentModal
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Products"
          setShowModal={setShowModal2}
          showModal={showModal2}
        />
      )}
      {documentDetailModal && (
        <DocumentDetailModal
          DocumentsData={DocumentsData}
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Products"
          setShowModal={setdocumentDetailModal}
          setformModal={setShowModal2}
          showModal={documentDetailModal}
        />
      )}
      {productInfoModal && (
        <ProductsInfoModal2
          productRow={productRow}
          setproductRow={setproductRow}
          setproductInfoModal={setproductInfoModal}
          productInfoModal={productInfoModal}
        />
      )}
      <ProductsInfoModal
        handleClosed={handleClosed}
        showModal={showModal}
        product={product}
      />
      <StockModal
        handleClosed={handleCloseStock}
        showModal={showStockModal}
        state1={state1}
      />

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteProduct(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Product"
        title={`Are you sure, you want to Delete Product?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Products;
Products.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#product",
    content: (
      <>
        <p size="large">Products Page</p>
        <p>
          Welcome to the Products Page – your hub for managing an array of
          products. Each product is a key component of your inventory, and you
          have the power to control and track their stock levels.
          <br />
          <br />
          Need to add a new product? Click &apos;New&apos; to expand your
          database.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#product-stock",
    content: (
      <>
        <p size="large">Stock Button</p>
        <p>Click to efficiently add stock for a particular product.</p>
      </>
    ),
    disableBeacon: true,
  },
];
