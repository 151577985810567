import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchAssignedTargetsData,
  getTargetDetails,
} from "../../Redux/Slice/TargetSlice/TargetSlice";
import Loader from "../../Components/Loader/Loader";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Badge, Button } from "@mui/material";
import AssignedTargetsFilterDrawer from "../../Components/AssignedTargets/AssignedTargetsFilterDrawer/AssignedTargetsFilterDrawer";
import { RemoveSCFromInputs } from "../../Utils/RemoveSCFromInputs";
import { PageNumberContext } from "../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

function AssignedTargets({ roles }) {
  const { header } = useStyles();
  const { totalRows } = useContext(PageNumberContext);
  const [pagesize, setpagesize] = useState(totalRows);

  const [rows, setrows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [filterVal, setfilterVal] = useState({
    user: "",
    project: "",
    category: "",
    noOfPlots: "",
  });

  const dispatch = useDispatch();
  const { AssignTargetsData, status } = useSelector(getTargetDetails);

  useEffect(() => {
    dispatch(fetchAssignedTargetsData());
  }, [dispatch]);

  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
    onTextChange();
  }, [filterVal]);

  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };

  const onTextChange = () => {
    if (
      filterVal?.user === "" &&
      filterVal?.project === "" &&
      filterVal?.category === "" &&
      filterVal?.noOfPlots === ""
    ) {
      setFilteredRows(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(filterVal?.user, "i");
      let regex2 = new RegExp(filterVal?.project, "i");
      let regex3 = new RegExp(filterVal?.category, "i");
      let regex4 = new RegExp(filterVal?.noOfPlots, "i");
      suggestions = rows?.filter(
        (val) =>
          (filterVal?.user === "" || regex1.test(val?.user)) &&
          (filterVal?.project === "" || regex2.test(val?.project)) &&
          (filterVal?.category === "" || regex3.test(val?.category)) &&
          (filterVal?.noOfPlots === "" || regex4.test(val?.numberOfPlots))
      );
      setFilteredRows(suggestions);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport csvOptions={{ fileName: "Asigned Leads" }} />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box>
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "Sr",
      headerClassName: header,
      width: 50,
    },
    {
      field: "user",
      headerName: "User",
      headerClassName: header,
      width: 180,
    },
    {
      field: "project",
      headerName: "Project",
      headerClassName: header,
      width: 180,
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: header,
      width: 180,
    },
    {
      field: "numberOfPlots",
      headerName: "Number Of Plots",
      headerClassName: header,
      width: 180,
    },
  ];

  useEffect(() => {
    let arr = [];
    if (AssignTargetsData) {
      AssignTargetsData?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          targetAssignId: item?.TargetAssignId,
          user: item?.GroupUser?.User?.FullName || "",
          project: item?.TargetDetail?.Project?.ProjectName || "",
          category: item?.TargetDetail?.Category?.CategoryName || "",
          numberOfPlots: item?.NumberOfPlots || "",
        });
      });
    }
    setrows(arr);
    setFilteredRows(arr);
  }, [AssignTargetsData]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 77px)",
          overflowX: "visible",
          backgroundColor: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={pagesize}
          onPageSizeChange={(newPage) => {
            setpagesize(newPage);
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => CustomNoRowsOverlay(),
          }}
          disableSelectionOnClick
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
        {status === "loading" ? <Loader /> : null}
      </Box>

      <AssignedTargetsFilterDrawer
        drawerOpen={drawerOpen}
        setdrawerOpen={setdrawerOpen}
        rows={rows}
        filterVal={filterVal}
        setfilterVal={setfilterVal}
        handleChangeVal={handleChangeVal}
        setcount={setcount}
        setFilteredRows={setFilteredRows}
      />
    </>
  );
}

export default AssignedTargets;
AssignedTargets.propTypes = {
  roles: PropTypes.array,
};

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
