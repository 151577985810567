import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPiplines,
  getPiplinesDetails,
} from "../../../../Redux/Slice/Settings/PiplinesSlice/PiplinesSlice";
import PiplinesFilterDrawer from "./PiplinesFilterDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useRef } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import Loader from "../../../Loader/Loader";
import { DeletePiplinesApi } from "../../../../Api/Settings/PiplinesApi/DeletePiplinesApi";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { RemoveSCFromInputs } from "../../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function Piplines({ roles }) {
  const { totalRows } = useContext(PageNumberContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [pagesize, setpagesize] = useState(totalRows);
  const { Piplines, status } = useSelector(getPiplinesDetails);
  const [allPiplines, setallPiplines] = useState([]);
  const [rows, setrows] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const [showLoading, setshowLoading] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const name = useRef();
  const RottenDaysRef = useRef();
  // const isDefaultRef = useRef();

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    dispatch(fetchPiplines());
  }, [dispatch]);
  useEffect(() => {
    let arr = [];
    if (Piplines) {
      Piplines?.forEach((pipline, idx) => {
        return arr.push({
          ...pipline,
          id: idx + 1,
          name: pipline?.Name,
          rottendays: pipline?.RottenDays,
          isdefault: pipline?.IsDefualt,
          ID: pipline?.Id,
        });
      });
    }
    setrows(arr);
    setallPiplines(arr);
  }, [Piplines]);
  const deleteGroup = async (id) => {
    setshowLoading(true);
    let res = await DeletePiplinesApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);

    if (res.data.IsSuccess) {
      dispatch(fetchPiplines());
    }
    setsnackBarData(res.snackBarData);
  };
  const handleEdit = (val) => {
    navigate("/settings/piplines/create", { state: val });
  };
  const columns =
    roles?.some((role) => role === "Pipelines_Edit") ||
    roles?.some((role) => role === "Pipelines_Delete")
      ? [
          { field: "id", headerName: "Sr", width: 90, headerClassName: header },
          {
            field: "name",
            headerName: "Name",
            width: 200,
            headerClassName: header,
          },
          {
            field: "rottendays",
            headerName: "Idle Days",
            width: 150,
            headerClassName: header,
          },
          {
            field: "isdefault",
            headerName: "Is Default",
            width: 150,
            headerClassName: header,
          },
          {
            field: "action",
            headerName: "Action",
            width: 150,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <Box key={i}>
                  {roles?.some((role) => role === "Pipelines_Edit") && (
                    <Tooltip title="Edit">
                      <IconButton
                        key={i}
                        onClick={() => {
                          handleEdit(celval.row);
                        }}
                      >
                        <EditIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {roles?.some((role) => role === "Pipelines_Delete") && (
                    <Tooltip title="Delete">
                      <IconButton
                        key={i}
                        onClick={() => {
                          setselectedId(celval.row.ID);
                          setshowConfirmationIcons(true);
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : [
          { field: "id", headerName: "Sr", width: 90, headerClassName: header },
          {
            field: "name",
            headerName: "Name",
            width: 200,
            headerClassName: header,
          },
          {
            field: "rottendays",
            headerName: "Idle Days",
            width: 150,
            headerClassName: header,
          },
          {
            field: "isdefault",
            headerName: "Is Default",
            width: 150,
            headerClassName: header,
          },
        ];

  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  console.log(filterVal?.isdefault);
  const onTextChange = () => {
    if (
      name.current?.value === "" &&
      RottenDaysRef.current?.value === ""
      //  &&
      // filterVal?.isdefault === "" || filterVal?.isdefault === undefined
    ) {
      setallPiplines(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(RemoveSCFromInputs(name.current.value), "i");
      let regex3 = new RegExp(
        RemoveSCFromInputs(RottenDaysRef.current.value),
        "i"
      );
      // let regex4 = new RegExp(filterVal?.isdefault, "i");
      suggestions = rows?.filter(
        (val) =>
          (name.current.value === "" || regex2.test(val?.name)) &&
          (RottenDaysRef.current.value === "" || regex3.test(val?.rottendays))
        //    &&
        // (filterVal?.isdefault === "" || filterVal?.isdefault === undefined || regex4.test(String(val?.isdefault)))
      );
      setallPiplines(suggestions);
    }
  };
  return (
    <>
      <Box>
        <PiplinesFilterDrawer
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          RottenDaysRef={RottenDaysRef}
          setcount={setcount}
          setallPiplines={setallPiplines}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          // isDefaultRef={isDefaultRef}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allPiplines}
            columns={columns}
          />
          {status === "loading" || showLoading ? <Loader /> : null}
        </Box>
      </Box>

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteGroup(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Pipline"
        title={`Are you sure, you want to Delete Pipline?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Piplines;
Piplines.propTypes = {
  roles: PropTypes.array,
};
