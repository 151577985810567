import {
  Box,
  Button,
  CircularProgress,
  FormControl,
//   FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React,{useContext,useState} from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import theme from "../../../theme";
import CancelIcon from "@mui/icons-material/Cancel";
import { UpdateMeetingStatusApi } from "../../../Api/MeetingsApi/UpdateMeetingStatusApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useDispatch } from "react-redux";
import {
  fetchMeetings,
} from "../../../Redux/Slice/MeetingsSlice/MeetingsSlice";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
function MeetingDoneModal({
  showModal,
  setShowModal,
  userid,
}) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyles();
  const initialValues = {
    actualDuration: "",
    status: "",
    Image:"",
    Reason:"",
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  const [logo, setlogo] = useState(null);
  const [selectedDocument, setselectedDocument] = useState(null);
  const dispatch = useDispatch();
const handleLogo = (e) => {
    const file = e.target.files[0];
      setlogo({
        url: URL.createObjectURL(e.target.files[0]),
        file: e.target.files,
      });
      formik.setFieldValue("Image", file);
  };
  const handleDelImg = () => {
    document.getElementById("file-input").value = ""; // Clear the file input field
    setlogo(null);
  };
  const setTime = (hh, mm) => {
    let hrs = Number(hh || 0) * 60;
    let total = hrs + Number(mm || 0) ;
    total > 0
      ? formik.setFieldValue("actualDuration", total)
      : formik.setFieldValue("actualDuration", "");
  };
  const validationSchema = Yup.lazy((values) =>
    values?.status === "Completed"
      ? Yup.object().shape({
          status: Yup.string().required("Please Select Status"),
          actualDuration: Yup.string().required("Please Enter Duration"),
          Image:Yup.string().required('Image is required'),
        })
      : Yup.object().shape({
          status: Yup.string().required("Please Select Status"),
          Reason: Yup.string().required("Please Enter Reason"),
        })
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      let statuscomplt;
      
      if (values?.status === "Completed") {
        statuscomplt = new FormData();
        statuscomplt.append("Status", values?.status);
        statuscomplt.append("ActualDuration", values?.actualDuration);
        if (values?.Image) {
          statuscomplt.append("Image", values.Image);
        }
      } else {
        statuscomplt = {
          Status: values?.status,
          Reason: values?.Reason,
        };
      }
      const response = await UpdateMeetingStatusApi(statuscomplt, userid);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        dispatch(fetchMeetings());
        setSubmitting(false);
        resetForm();
        setlogo(null);  
        setShowModal(false);
      }
    },
  });
  

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setselectedDocument(null);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography
                variant="h1"
                sx={{ fontSize: "18px !important" }}
              >
                Status Update
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(false);
                  setselectedDocument(null);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <FormControl fullWidth>
                      <Typography>Status</Typography>
                      <TextField
                        name="status"
                        value={formik.values?.status}
                        size={"small"}
                        select
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          Boolean(formik.errors.status) &&
                          Boolean(formik.touched.status)
                        }
                        helperText={
                          Boolean(formik.errors.status) &&
                          Boolean(formik.touched.status) &&
                          formik.errors.status
                        }
                      >
                        <MenuItem value="">Select Status</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                      </TextField>
                    </FormControl>
                      <>
                      {formik.values?.status === "Cancelled" && (
                        <FormControl fullWidth>
                          <Typography>Reason</Typography>
                          <Box
                            sx={{ width: "100%"}}
                          >
                            <TextField
                              fullWidth
                              size="small"
                              name="Reason"
                              type="text"
                              placeholder="Reason"
                              onBlur={formik.handleBlur}
                              value={formik.values?.Reason}
                              onChange={formik.handleChange}
                              variant="outlined"
                              error={
                                Boolean(formik.touched.Reason) &&
                                Boolean(formik.errors.Reason)
                              }
                              helperText={
                                Boolean(formik.touched.Reason) &&
                                formik.errors.Reason
                              }
                            />
                          </Box>
                        </FormControl>
                            )}
                      {formik.values?.status !== "Cancelled" && (
                        <FormControl fullWidth>
                          <Typography>Duration</Typography>
                          <Box
                            sx={{ width: "100%", display: "flex", gap: "10px" }}
                          >
                            <TextField
                              sx={{
                                textAlign: "left",
                                input: { color: "black" },
                              }}
                              fullWidth
                              size="small"
                              name="hrs"
                              type="number"
                              placeholder="Hours"
                              onBlur={formik.handleBlur}
                              value={formik.values?.hrs}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setTime(
                                  e.target.value,
                                  formik.values?.mins,
                                  formik.values?.seconds
                                );
                              }}
                              variant="outlined"
                              error={
                                Boolean(formik.touched.actualDuration) &&
                                Boolean(formik.errors.actualDuration)
                              }
                              helperText={
                                Boolean(formik.touched.actualDuration) &&
                                formik.errors.actualDuration
                              }
                            />
                            <Typography variant="h5">:</Typography>
                            <TextField
                              sx={{
                                textAlign: "left",
                                input: { color: "black" },
                              }}
                              fullWidth
                              size="small"
                              name="mins"
                              type="number"
                              placeholder="Minutes"
                              onBlur={formik.handleBlur}
                              value={formik.values?.mins}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setTime(
                                  formik.values?.hrs,
                                  e.target.value,
                                  formik.values?.seconds
                                );
                              }}
                              variant="outlined"
                              error={
                                Boolean(formik.touched.actualDuration) &&
                                Boolean(formik.errors.actualDuration)
                              }
                              helperText={
                                Boolean(formik.touched.actualDuration) &&
                                formik.errors.actualDuration
                              }
                            />
                            <Typography variant="h5">:</Typography>
                            <TextField
                              sx={{
                                textAlign: "left",
                                input: { color: "black" },
                              }}
                              placeholder="Seconds"
                              fullWidth
                              size="small"
                              name="seconds"
                              type="number"
                              onBlur={formik.handleBlur}
                              value={formik.values?.seconds}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setTime(
                                  formik.values?.hrs,
                                  formik.values?.mins,
                                  e.target.value
                                );
                              }}
                              variant="outlined"
                              error={
                                Boolean(formik.touched.actualDuration) &&
                                Boolean(formik.errors.actualDuration)
                              }
                              helperText={
                                Boolean(formik.touched.actualDuration) &&
                                formik.errors.actualDuration
                              }
                            />
                          </Box>
                        </FormControl>
                            )}
                     {formik.values?.status !== "Cancelled" && (
                         <FormControl
                          fullWidth
                          error={
                            Boolean(formik.touched.Image) &&
                            Boolean(formik.errors.Image)
                          }
                          helperText={
                            Boolean(formik.touched.Image) &&
                            formik.errors.Image
                          }
                        //   error={logoError}
                        >
                          <Typography>File</Typography>
                          <TextField
                            id="file-input"
                            sx={{
                              textAlign: "left",
                              input: { color: "black" },
                            }}
                            fullWidth
                            size="small"
                            error={
                                Boolean(formik.touched.Image) &&
                                Boolean(formik.errors.Image)
                              }
                              helperText={
                                Boolean(formik.touched.Image) &&
                                formik.errors.Image
                              }
                            name="Image"
                            type="file"
                            InputProps={{
                              inputProps: {
                                accept: "image/*",
                              },
                            }}
                            // accept={formik.values?.Type==="Image"?"image/*":".pdf, .doc, .docx"}
                            onChange={handleLogo}
                            variant="outlined"
                          />
                    
                        </FormControl>
                        )}
                        {(logo && formik.values?.status !== "Cancelled")  &&(
                          <Box
                            sx={{
                              position: "relative",
                              //   height: "20%",
                              width: "50%",
                              borderRadius: "5px",
                              border: "1px solid rgb(214, 214, 214)",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer !important",
                              justifyContent: "center",
                              padding: "10px",
                            }}
                          >
                            <IconButton
                              color="error"
                              sx={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                              onClick={handleDelImg}
                            >
                              <CancelIcon />
                            </IconButton>
                            <img
                              src={
                                selectedDocument
                                  ? selectedDocument?.File
                                  : logo?.url
                              }
                              alt="Image"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Box>
                        )}
                    
                      </>
                            

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                            >
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default MeetingDoneModal;

MeetingDoneModal.propTypes = {
  showModal: PropTypes.any,
  setShowModal: PropTypes.any,
  userid: PropTypes.any,
//   selectedRow: PropTypes.any,
//   setselectedRow: PropTypes.any,
//   leadId: PropTypes.any,
//   activityForm: PropTypes.any,
//   values: PropTypes.any,
//   formSubmit: PropTypes.any,
//   pageNo: PropTypes.number,
//   pageSize: PropTypes.number,
};
