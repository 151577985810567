import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  Sources: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchSources = createAsyncThunk("/LeadSource", async () => {
  const response = await axiosInstance.get(`/LeadSource`);
  console.log(response);
  return response;
});

const SourcesSlice = createSlice({
  name: "Sources",
  initialState,
  reducers: {
    SourcesAdded: {
      reducer(state, action) {
        state.Sources?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchSources.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSources.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Sources = action.payload.data.Data;
      })
      .addCase(fetchSources.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getSourcesDetails = (state) => state.Sources;

export const { SourcesAdded } = SourcesSlice.actions;

export default SourcesSlice.reducer;
