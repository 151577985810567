import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AttachmentIcon from "@mui/icons-material/Attachment";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function Trash({ roles }) {
  const { header } = useStyles();
  const [pagesize, setpagesize] = useState(5);
  const [rows, setrows] = React.useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  React.useEffect(() => {
    let rowData = [];
    Array(100)
      .fill("")
      .map((data, i) => {
        rowData.push({
          id: i + 1,
          from: `Aiksol${i + 1}`,
          subject: `math${i + 1}`,
          createddate: `02-04-201${i + 1}`,
          icon: `a${i + 1}`,
        });
      });
    setrows(rowData);
  }, []);
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: header },
    {
      field: "icon",
      headerName: <AttachmentIcon fontSize="small" />,
      width: 70,
      editable: true,
      headerClassName: header,
      renderCell: (cellval) => {
        return (
          <Tooltip title={cellval.row.icon}>
            <IconButton
            //  onClick={() => { handleEdit(cellVal.row); }}
            >
              <AttachmentIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "from",
      headerName: "From",
      width: 150,
      editable: true,
      headerClassName: header,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 150,
      editable: true,
      headerClassName: header,
    },
    {
      field: "createddate",
      headerName: "Created Date",
      width: 150,
      editable: true,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      editable: true,
      headerClassName: header,
      renderCell: () => {
        return (
          <Box>
            <Tooltip title="View">
              <IconButton
                //  onClick={() => { handleEdit(cellVal.row); }}
                disabled={
                  roles?.some((role) => role === "Mail_View") ? false : true
                }
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                disabled={
                  roles?.some((role) => role === "Mail_Delete") ? false : true
                }
              >
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          height: "calc(100vh - 77px)",
          width: "100%",
          overflowX: "visible",
          backgroundColor: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <DataGrid
          pageSize={pagesize}
          onPageSizeChange={(newPage) => {
            setpagesize(newPage);
          }}
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={rows}
          columns={columns}
        />
      </Box>
    </Box>
  );
}

export default Trash;
Trash.propTypes = {
  roles: PropTypes.array,
};
