import {
  //   Avatar,
  Box,
  IconButton,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CallIcon from "@mui/icons-material/Call";
import org from "../../../../Assets/org2.png";
import ConstructionIcon from "@mui/icons-material/Construction";
import AppTooltip from "../../../../Utils/AppTooltip";
import theme from "../../../../theme";
const useStyles = makeStyles(() => {
  return {
    RowInField: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "start",
      gap: "10px",
      width: "100%",
      flexDirection: "row",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    textAreaContainer: {
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
      gap: "10px",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        width: "100%",
      },
    },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "40px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    iconBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };
});
function OrganizationDetailModal({
  showInfoModal,
  setShowInfoModal,
  SingleOrganization,
}) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    profile,
    iconBox,
    RowInField,
    textAreaContainer,
  } = useStyles();

  console.log(SingleOrganization);
  return (
    <>
      <Modal
        open={showInfoModal}
        onClose={() => {
          setShowInfoModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography
                variant="h1"
                sx={{ fontSize: "18px !important" }}
              >
                Organization Details
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInfoModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box className={profile}>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "100px",
                    height: "100px",
                    borderRadius: "50%",
                  }}
                >
                  {/* {personRow?.Image ? ( */}
                  <img
                    src={org}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    alt="Image"
                  />
                  {/* //   ) : (
                //     <Avatar sx={{ width: "100%", height: "100%" }} src={"./"} />
                //   )} */}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    {SingleOrganization?.Name}
                  </Typography>
                  <Box className={iconBox}>
                    <EmailIcon sx={{ fontSize: "20px", color: "grey" }} />
                    <Typography>{SingleOrganization?.Email}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Box className={iconBox}>
                      <PhoneAndroidIcon
                        sx={{ fontSize: "20px", color: "grey" }}
                      />
                      <Typography>
                        +{SingleOrganization?.ContactNumber}
                      </Typography>
                    </Box>
                    <Box className={iconBox}>
                      <CallIcon sx={{ fontSize: "20px", color: "grey" }} />
                      <Typography>
                        +{SingleOrganization?.ContactNumber}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={iconBox}>
                    <ConstructionIcon
                      sx={{ fontSize: "20px", color: "grey" }}
                    />
                    <AppTooltip title={"Industry"}>
                      <Typography sx={{ cursor: "pointer" }}>
                        {SingleOrganization?.Industry}
                      </Typography>
                    </AppTooltip>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                {/* <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Industry:</Typography>
                  <Typography
                    sx={{
                      paddingLeft: "60px",
                    }}
                  >
                   {SingleOrganization?.Industry}
                  </Typography>
                </Box> */}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Website:</Typography>
                  <Link
                    sx={{ textDecoration: "none" }}
                    target="_blank"
                    href={SingleOrganization?.Website}
                  >
                    <Typography
                      sx={{
                        paddingLeft: "60px",
                      }}
                    >
                      {SingleOrganization?.Website}
                    </Typography>
                  </Link>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Description:
                  </Typography>

                  <Typography
                    sx={{
                      height:
                        SingleOrganization?.Description?.length > 290
                          ? "140px"
                          : "",
                      overflowY: "auto",
                      paddingLeft: "60px",
                    }}
                  >
                    {SingleOrganization?.Description}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                {/* <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Industry:</Typography>
                  <Typography
                    sx={{
                      paddingLeft: "60px",
                    }}
                  >
                   {SingleOrganization?.Industry}
                  </Typography>
                </Box> */}
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Address:</Typography>

                  <Typography
                    sx={{
                      paddingLeft: "60px",
                    }}
                  >
                    {SingleOrganization?.Address?.AddressDetail}
                  </Typography>
                </Box>
                <Box className={RowInField}>
                  <Box className={textAreaContainer}>
                    <Typography
                      sx={{ width: "90px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      Country:
                    </Typography>
                    <Typography>
                      {SingleOrganization?.Address?.Country}
                    </Typography>
                  </Box>
                  <Box className={textAreaContainer}>
                    <Typography
                      sx={{ width: "90px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      State:
                    </Typography>
                    <Typography>
                      {SingleOrganization?.Address?.State}
                    </Typography>
                  </Box>
                </Box>
                <Box className={RowInField}>
                  <Box className={textAreaContainer}>
                    <Typography
                      sx={{ width: "90px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      City:
                    </Typography>
                    <Typography>{SingleOrganization?.Address?.City}</Typography>
                  </Box>
                  <Box className={textAreaContainer}>
                    <Typography
                      sx={{ width: "90px" }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      Post Code:
                    </Typography>
                    <Typography>
                      {SingleOrganization?.Address?.Postcode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default OrganizationDetailModal;
OrganizationDetailModal.propTypes = {
  showInfoModal: PropTypes.any,
  setShowInfoModal: PropTypes.any,
  SingleOrganization: PropTypes.any,
};
