import React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import theme from "../../../../theme";
import { useState } from "react";
import { CreateTagsApi } from "../../../../Api/Settings/CreateTagsApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { TagsAdded } from "../../../../Redux/Slice/Settings/TagsSlice/TagsSlice";
import { useEffect } from "react";
import { UpdateTagApi } from "../../../../Api/Settings/TagsApi/UpdateTagApi";

const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function CreateTags() {
  const { container, subContainer, formContainer } = useStyle();

  const initialValues = {
    name: "",
  };
  const dispatch = useDispatch();
  const [colors] = useState([
    "rgb(50,205,50)",
    "rgb(250,218,94)",
    " rgb(219,112,147)",
    "rgb(39, 182, 187)",
    "rgb(251, 138, 63)",
    "rgb(51, 124, 255)",
  ]);
  const [borderColor, setborderColor] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [selectedColor, setselectedColor] = useState("");

  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      handleEdit(state);
      setselectedColor(state?.backColor);
    }
  }, []);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
  };
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const obj = { ...values, color: selectedColor, tagType: "Lead" };
    if (state) {
      const response = await UpdateTagApi({
        ...values,
        color: selectedColor,
        id: state.ID,
        tagType: "Lead",
      });
      setsnackBarData(response.snackBarData);

      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        dispatch(TagsAdded());
        navigate(-1);
      }
    } else {
      const response = await CreateTagsApi(obj, "Lead");
      setsnackBarData(response.snackBarData);

      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        dispatch(TagsAdded());
        navigate(-1);
      }
    }
    setSubmitting(false);
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter  Name"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/settings"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Settings
              </Link>
              <Link
                to="/settings/tags/Create"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Tags
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state ? "Update Tags" : "Create Tags"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state ? "Update Tags" : "Create Tags"}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  value={formik.values?.name}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                  }
                  helperText={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                      ? formik.errors.name
                      : ""
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Color</Typography>

                <Box sx={{ display: "flex", gap: "10px" }}>
                  {colors?.map((clr, idx) => {
                    return (
                      <Box
                        key={idx}
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: clr,
                          borderRadius: "100%",
                          border: borderColor === idx ? "2px solid white" : clr,
                          boxShadow:
                            borderColor === idx
                              ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                              : null,
                          transform:
                            borderColor === idx ? "scale(0.9.5)" : null,
                          transition: "0.2s",
                        }}
                        onClick={() => {
                          setborderColor(idx);
                          setselectedColor(clr);
                        }}
                      ></Box>
                    );
                  })}
                </Box>
              </FormControl>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreateTags;
