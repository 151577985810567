import React from 'react';
import {
    Box,
    Button,
    Paper,

} from "@mui/material";
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';

const useStyle = makeStyles(() => {
    return {
        formContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
            alignItems: "flex-start"
        },
    };
});
function Quote({state,singleLead}) {
    const {
        formContainer,
    } = useStyle();
    const navigate=useNavigate();
    console.log(state);

  return (
    <Paper elevation={3}>

        <Box className={formContainer}>
            <Button variant='outlined' sx={{ backgroundColor: (theme) => theme.palette.primary.main, color: "white !important" }} onClick={()=>{navigate("/quotes/create",{state:{singleLead:singleLead,isLead:true}});}}>
                Create Quote
            </Button>
        </Box>

</Paper>
  );
}

export default Quote;
Quote.propTypes = {
    singleLead: PropTypes.any,
    state: PropTypes.any,
  
  };