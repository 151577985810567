import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  //   DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Button, TextField, MenuItem, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useContext } from "react";
import PropTypes from "prop-types";
import Joyride from "react-joyride";
import { AppContext } from "../../../Context/TourContext/TourContext";
import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import Loader from "../../../Components/Loader/Loader";
import {
  fetchUserWonLeads,
  getReportsDetails,
} from "../../../Redux/Slice/ReportSlice/ReportSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  fetchleadActivityTypePopulate,
  fetchLeadsPopulate,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import { StripedDataGrid } from "../../../Utils/StrippedDataGrid";
import { useLocation } from "react-router-dom";
import AppTooltip from "../../../Utils/AppTooltip";
import WonLeadReportModal from "../../../Components/Reports/WonLeadReportModal";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

function UserWonLeadsReport({ roles }) {
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);
  const [pagesize, setpagesize] = useState(totalRows);
  // const [rows, setrows] = React.useState([]);
  const [allReport, setallReport] = useState([]);
  // const [drawerOpen, setdrawerOpen] = useState(false);
  // const [filterVal, setfilterVal] = useState(null);
  // const [count, setcount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [units, setUnits] = useState(false);

  const { setState, state } = useContext(AppContext);

  const dispatch = useDispatch();

  const { UserWonLeads, status3 } = useSelector(getReportsDetails);
  const { LeadsPopulate, status4 } = useSelector(getLeadsDetails);
  let UserState = useLocation();
  const [user, setUser] = useState(null);
  console.log("LeadsPopulate", LeadsPopulate);
  useEffect(() => {
    if (!LeadsPopulate?.Data) dispatch(fetchLeadsPopulate());
    dispatch(fetchleadActivityTypePopulate());
  }, [dispatch]);
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "UserWonLeadsReport");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  const ValidationSchema = Yup.object().shape({
    // from: Yup.string().required("Please Enter From Date"),
    // to: Yup.string().required("Please Enter To Date"),
  });
  const initialValues = {
    from: "",
    to: "",
    userId: "",
    type: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      dispatch(fetchUserWonLeads(values));
    },
  });
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingRight: "16px", // Add padding to align with right end
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {roles?.some((role) => role === "export_Create") && (
                <GridToolbarExport
                  csvOptions={{ fileName: "Active Employees" }}
                />
              )}
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarColumnsButton />
              {/* <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button> */}
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "end",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <TextField
                  name="userId"
                  value={formik.values?.userId}
                  size={"small"}
                  select
                  label="Select User"
                  sx={{ minWidth: "150px" }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setUser(null);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors?.userId) &&
                    Boolean(formik.touched?.userId)
                  }
                  helperText={
                    Boolean(formik.errors?.userId) &&
                    Boolean(formik.touched?.userId) &&
                    formik.errors?.userId
                  }
                >
                  <MenuItem value="">Select</MenuItem>
                  {LeadsPopulate?.Data?.userData?.map((user, i) => {
                    return (
                      <MenuItem key={i} value={user?.Id}>
                        {user?.FullName}
                      </MenuItem>
                    );
                  })}
                </TextField>

                <TextField
                  id="dashboard-from"
                  label="From"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="from"
                  value={formik.values.from}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  size="small"
                  type={"date"}
                  error={
                    Boolean(formik.errors.from) && Boolean(formik.touched.from)
                  }
                  helperText={
                    Boolean(formik.errors.from) &&
                    Boolean(formik.touched.from) &&
                    formik.errors.from
                  }
                />
                <TextField
                  name="to"
                  id="dashboard-to"
                  label="To"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.to}
                  variant="outlined"
                  size="small"
                  type={"date"}
                  error={
                    Boolean(formik.errors.to) && Boolean(formik.touched.to)
                  }
                  helperText={
                    Boolean(formik.errors.to) &&
                    Boolean(formik.touched.to) &&
                    formik.errors.to
                  }
                />
                <Button
                  id="dashboard-get"
                  variant="contained"
                  className="get"
                  type="submit"
                >
                  Get
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  // useEffect(() => {
  //   let sum = 0;
  //   for (const value in filterVal) {
  //     if (filterVal[value] !== "") {
  //       sum = sum + 1;
  //     }
  //   }
  //   setcount(sum);
  // }, [filterVal]);

  useEffect(() => {
    let rowData = [];
    UserWonLeads?.map((item, i) => {
      rowData.push({
        id: i + 1,
        LeadValue: item?.LeadValue?.toLocaleString(),
        LeadTitle: item?.LeadTitle,
        SourceName: item?.SourceName,
        Units: item?.Stages,
      });
    });
    // setrows(rowData);
    setallReport(rowData);
  }, [UserWonLeads]);

  const columns = [
    { field: "id", headerName: "Sr", width: 90 },
    {
      field: "LeadTitle",
      headerName: "Lead Title",
      width: 250,
    },
    {
      field: "LeadValue",
      headerName: "Lead Value",
      width: 180,
      renderCell: (celval) => {
        return celval.formattedValue?.toLocaleString();
      },
    },
    {
      field: "SourceName",
      headerName: "Source Name",
      width: 150,
    },

    {
      field: "action",
      headerName: "Action",
      width: 110,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <AppTooltip title="View">
              <IconButton
                key={i}
                onClick={() => {
                  setShowModal(true);
                  setUnits(celval.row?.Units);
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </AppTooltip>
          </Box>
        );
      },
    },
  ];

  // const handleChangeVal = (e) => {
  //   let value = RemoveSCFromInputs(e.target.value);

  //   setfilterVal({
  //     ...filterVal,
  //     [e.target.name]: value || "",
  //   });
  // };

  // const onTextChange = () => {
  //   if (
  //     person.current?.value === "" &&
  //     item.current?.value === "" &&
  //     section.current?.value === "" &&
  //     project.current?.value === "" &&
  //     leadTitle.current?.value === ""
  //   ) {
  //     setallReport(rows);
  //   } else {
  //     let suggestions = [];
  //     let regex1 = new RegExp(RemoveSCFromInputs(project.current.value), "i");
  //     let regex2 = new RegExp(RemoveSCFromInputs(section.current.value), "i");
  //     let regex3 = new RegExp(RemoveSCFromInputs(item.current.value), "i");
  //     let regex4 = new RegExp(RemoveSCFromInputs(person.current.value), "i");
  //     let regex5 = new RegExp(RemoveSCFromInputs(leadTitle.current.value), "i");
  //     suggestions = rows?.filter(
  //       (val) =>
  //         (project.current.value === "" ||
  //           regex1.test(val?.Project?.ProjectName)) &&
  //         (section.current.value === "" ||
  //           regex2.test(val?.Section?.SectionName)) &&
  //         (item.current.value === "" || regex3.test(val?.item)) &&
  //         (person.current.value === "" || regex4.test(val?.person)) &&
  //         (leadTitle.current.value === "" || regex5.test(val?.title))
  //     );
  //     setallReport(suggestions);
  //   }
  // };
  useEffect(() => {
    UserState?.state?.Id && setUser(UserState?.state?.Id);
  }, [UserState]);
  useEffect(() => {
    user && formik.setFieldValue("userId", user);
  }, [user]);
  useEffect(() => {
    user && formik.values.userId && dispatch(fetchUserWonLeads(formik.values));
  }, [formik.values.userId]);
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "UserWonLeadsReport"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}
      </Box>
      <Box id="item">
        {/* <BookedUnitFilterDrawer
          handleChangeVal={handleChangeVal}
          rows={rows}
          project={project}
          section={section}
          person={person}
          item={item}
          leadTitle={leadTitle}
          setcount={setcount}
          setallReport={setallReport}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        /> */}
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <StripedDataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () =>
                CustomNoRowsOverlay("No Data. Please Select User."),
            }}
            rows={allReport}
            columns={columns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
          {status4 === "loading" || status3 === "loading" ? <Loader /> : null}
        </Box>
      </Box>
      {showModal && (
        <WonLeadReportModal
          units={units}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
    </>
  );
}

export default UserWonLeadsReport;
UserWonLeadsReport.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#item",
    content: (
      <>
        <p size="large">Booked Units Page</p>
        <p>
          Welcome to the User Won Leads Report! This section highlights the
          leads you&apos;ve successfully converted, detailing the lead titles,
          sources, and their values. Use this report to review your successes
          and identify the most effective sources of your leads.
          <br />
          <br />
          Select User to Get Started!
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
