import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
//   import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
function UserInfoModal({ showModal, handleClosed, users }) {
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            All Users
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {users?.map((data, index) => (
            <Chip key={index} label={data?.User?.FullName} variant="outlined" />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UserInfoModal;
UserInfoModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  users: PropTypes.any,
};
