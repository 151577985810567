import {
  Box,
  // Button,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Link,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
import React, { useContext } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import theme from "../../../../../theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import { DeleteLeadFileApi } from "../../../../../Api/LeadsApi/DeleteLeadFileApi";
import PropTypes from "prop-types";
import { fetchLeadsSingle } from "../../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";
import EditFilesModal from "./EditFilesModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppConfirmationDialog from "../../../../AppConfirmationDialog/AppConfirmationDialog";

const useStyle = makeStyles(() => {
  return {
    noteBox: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      padding: "10px",
      width: "100%",
    },
    notesubboxMain: {
      display: "flex",
      gap: "40px",
      width: "100%",
      padding: "0px 10px 0px 20px",
    },
    iconlineMain: {
      width: "2px",
      minHeight: "100%",
      backgroundColor: theme.palette.primary.main,
      position: "relative",
    },
    iconBox: {
      width: "40px",
      height: "40px",
      border: `1px solid ${theme?.palette.primary.main}`,
      transform: "translate(-50%,0%)",
      position: "absolute",
      top: "0%",
      left: "50%",
      color: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white !important",
    },
  };
});
function AllFiles({ singleLead, roles }) {
  const { noteBox, notesubboxMain, iconlineMain, iconBox } = useStyle();
  const [loading, setloading] = useState(false);
  const [fileRow, setfileRow] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [opens, setOpens] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const dispatch = useDispatch();
  const deleteFile = async () => {
    setloading(true);
    const response = await DeleteLeadFileApi(fileRow?.LeadFileId);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setfileRow(null);
      setloading(false);
      handleClosed();
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
    }
    setloading(false);
  };
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClosed = () => {
    setOpens(false);
  };
  return (
    <>
      {singleLead?.leadFile?.map((filee, i) => {
        return (
          <Box
            key={i}
            className={notesubboxMain}
          >
            <Box className={iconlineMain}>
              <Box className={iconBox}>
                <AttachFileIcon sx={{}} />
              </Box>
            </Box>
            <Box sx={{ width: "100%", paddingBottom: "10px" }}>
              <Paper
                key={i}
                elevation={3}
                className={noteBox}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Files Added</Typography>
                  <MoreVertIcon
                    onClick={(e) => {
                      if (roles?.some((role) => role === "Leads_Edit")) {
                        setfileRow(filee);
                        handleClick(e);
                      }
                    }}
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      sx: {
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                        setshowModal(true);
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleClickOpen();
                      }}
                    >
                      Remove
                    </MenuItem>
                  </Menu>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <AttachmentIcon />
                  <Typography sx={{ cursor: "pointer" }}>
                    <Link
                      href={filee?.image}
                      target="_blank"
                      underline="none"
                    >
                      {filee?.Name}{" "}
                    </Link>
                  </Typography>
                </Box>
                <Box sx={{ backgroundColor: "#fff9c4", padding: "10px" }}>
                  <Typography>{filee?.Description}</Typography>
                </Box>

                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    {moment(filee?.CreatedAt).format("Do MMMM YYYY, h:mm a")}
                  </Typography>
                  <Typography sx={{ cursor: "pointer", color: "#0e90d9" }}>
                    {filee?.FullName}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Box>
        );
      })}

      {/* <Dialog
        open={opens}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Delete <b>{fileRow?.Name}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={handleClosed}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  deleteFile();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}

      <AppConfirmationDialog
        open={opens}
        onDeny={() => {
          handleClosed();
        }}
        onConfirm={() => {
          deleteFile();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete File"
        title={`Are you sure you want to Delete "${fileRow?.Name}"?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />

      <EditFilesModal
        fileRow={fileRow}
        setfileRow={setfileRow}
        singleLead={singleLead}
        showModal={showModal}
        setshowModal={setshowModal}
      />
    </>
  );
}

export default AllFiles;
AllFiles.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
