import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Divider,
  InputAdornment,
  TextField,
  Typography,
  Autocomplete,
  ListItem,
  Button,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import {
  fetchLeadsPopulate,
  getLeadsDetails,
} from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getActivitiesDetails } from "../../Redux/Slice/ActivitiesSlice/ActivitiesSlice";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});

function ActivitiesFilterDrawer({
  setcount,
  setfilterVal,
  filterVal,
  handleChangeVal,
  setallActivities,
  setTotalCount,
  rows,
  setdrawerOpen,
  drawerOpen,
  searchRefreshData,
}) {
  const { contentBox, fieldBox } = useStyles();
  const dispatch = useDispatch();
  const { LeadsPopulate } = useSelector(getLeadsDetails);
  const { Activities } = useSelector(getActivitiesDetails);

  useEffect(() => {
    dispatch(fetchLeadsPopulate());
  }, [dispatch]);

  const handleClose = () => {
    setdrawerOpen(!open);
  };
  const handleRemoveAll = () => {
    for (const value in filterVal) {
      // if (value === "projects" || value === "users") {
      //   filterVal[value] = [];
      // } else {
      filterVal[value] = "";
      // }
    }
    setcount(0);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Filter</Typography>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  setTotalCount(Activities?.TotalRecords);
                  setallActivities(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            {/* <Box>
              <Typography>Is Done</Typography>
              <Box className={fieldBox}>
                <TextField
                  name="isdone"
                  variant="outlined"
                  size="small"
                  value={filterVal?.isdone}
                  type="text"
                  select
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Subject"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </TextField>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      isdone: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box> */}

            <Box>
              <Typography>Lead</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="lead"
                  variant="outlined"
                  size="small"
                  value={filterVal?.lead}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Lead"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      lead: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>

            {/* <Box>
              <Typography>Projects</Typography>
              <Box className={fieldBox}>
                <Autocomplete
                  // multiple
                  options={LeadsPopulate?.Data?.projectData || []}
                  getOptionLabel={(option) => option?.ProjectName}
                  listStyle={{ maxHeight: 200, overflow: "auto" }}
                  sx={{ width: "100%", maxWidth: "325px" }}
                  fullWidth
                  name="projects"
                  // value={filterVal?.projects}
                  value={
                    LeadsPopulate?.Data?.projectData?.find(
                      (item) => item?.ProjectId === filterVal?.projects
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setfilterVal({
                        ...filterVal,
                        projects: value?.ProjectId,
                      });
                    } else {
                      setfilterVal({
                        ...filterVal,
                        projects: "",
                      });
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="input_border"
                    />
                  )}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      projects: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box> 
            </Box> */}

            <Box>
              <Typography>Users</Typography>
              <Box className={fieldBox}>
                <Autocomplete
                  // multiple
                  options={LeadsPopulate?.Data?.userData || []}
                  getOptionLabel={(option) => option?.FullName}
                  listStyle={{ maxHeight: 200, overflow: "auto" }}
                  sx={{ width: "100%", maxWidth: "325px" }}
                  fullWidth
                  name="users"
                  // value={filterVal?.users}
                  value={
                    LeadsPopulate?.Data?.userData?.find(
                      (item) => item?.Id === filterVal?.users
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setfilterVal({
                        ...filterVal,
                        users: value?.Id,
                      });
                    } else {
                      setfilterVal({
                        ...filterVal,
                        users: "",
                      });
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="input_border"
                    />
                  )}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      users: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>

            <Box>
              <Typography>Customer</Typography>
              <Box className={fieldBox}>
                <Autocomplete
                  options={LeadsPopulate?.Data?.personData || []}
                  getOptionLabel={(option) => option?.Name}
                  renderOption={(props, option) => {
                    return (
                      <ListItem {...props} key={option.Id}>
                        <Typography>
                          {option?.Name} ({option?.ContactNumber})
                        </Typography>
                      </ListItem>
                    );
                  }}
                  listStyle={{ maxHeight: 200, overflow: "auto" }}
                  sx={{ width: "100%", maxWidth: "325px" }}
                  fullWidth
                  name="customer"
                  value={
                    LeadsPopulate?.Data?.personData?.find(
                      (item) => item?.Id === filterVal?.customer
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setfilterVal({
                        ...filterVal,
                        customer: value?.Id,
                      });
                    } else {
                      setfilterVal({
                        ...filterVal,
                        customer: "",
                      });
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="input_border"
                    />
                  )}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      customer: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>

            <Box>
              <Typography>Activity Title</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="title"
                  variant="outlined"
                  size="small"
                  value={filterVal?.title}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Title"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      title: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Type</Typography>
              <Box className={fieldBox}>
                <TextField
                
                  name="type"
                  variant="outlined"
                  size="small"
                  value={filterVal?.type}
                  select
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Type"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                >
                  <MenuItem value={"Meeting"}>Meeting</MenuItem>
                  <MenuItem value={"Call"}>Call</MenuItem>
                  <MenuItem value={"Email"}>Email</MenuItem>
                  <MenuItem value={"Message"}>Message</MenuItem>
                </TextField>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      type: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Sub Type</Typography>
              <Box className={fieldBox}>
                <TextField
                  
                  name="subtype"
                  variant="outlined"
                  size="small"
                  value={filterVal?.subtype}
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Sub Type"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                  select
                >
                  <MenuItem value={"Cold Call"}>Cold Call</MenuItem>
                  <MenuItem value={"Follow Up"}>Follow Up</MenuItem>
                  <MenuItem value={"Onsite"}>Onsite</MenuItem>
                  <MenuItem value={"Outdoor"}>Outdoor</MenuItem>
                  <MenuItem value={"Sale Event"}>Sale Event</MenuItem>
                  <MenuItem value={"Regional"}>Regional</MenuItem>
                  <MenuItem value={"Video Meeting"}>Video Meeting</MenuItem>
                  <MenuItem value={"Email"}>Email</MenuItem>
                  <MenuItem value={"SMS"}>SMS</MenuItem>
                  <MenuItem value={"WhatsApp"}>WhatsApp</MenuItem>
                </TextField>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      subtype: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Status</Typography>
              <Box className={fieldBox}>
                <TextField
                
                  name="status"
                  variant="outlined"
                  size="small"
                  value={filterVal?.status}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Status"
                  className="input_border"
                  select
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                >
                  <MenuItem value={""}>Select Status</MenuItem>

                  <MenuItem value={"Done"}>Done</MenuItem>
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"Attempt"}>Attempt</MenuItem>
                  <MenuItem value={"Sent"}>Sent</MenuItem>
                  <MenuItem value={"Connected"}>Connected</MenuItem>
                </TextField>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      status: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Button
                variant="outlined"
                color="success"
                size="small"
                startIcon={<AutorenewIcon fontSize="small" />}
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleRemoveAll();
                  handleClose();
                  searchRefreshData("refresh");
                }}
              >
                Refresh
              </Button>

              <Button
                variant="contained"
                size="small"
                startIcon={<SearchIcon fontSize="small" />}
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleClose();
                  searchRefreshData("search");
                }}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default ActivitiesFilterDrawer;
ActivitiesFilterDrawer.propTypes = {
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  setallActivities: PropTypes.any,
  setTotalCount: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
  searchRefreshData: PropTypes.func,
};
