import chroma from "chroma-js";

export const ReactSelectTheme = (error) => {
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: error ? "red" : "#bdbdbd",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma("#298b8b");
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#298b8b"
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : "#298b8b",
        cursor: isDisabled ? "not-allowed" : "default",
        borderColor: "#298b8b",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#298b8b"
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({ ...styles }),
  };
  return colourStyles;
};
