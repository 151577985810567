import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSources,
  getSourcesDetails,
} from "../../../../Redux/Slice/Settings/SourcesSlice/SourcesSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useRef } from "react";
import SourcesFilterDrawer from "./SourcesFilterDrawer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { DeleteSourceApi } from "../../../../Api/Settings/SourcesApi/DeleteSourceApi";
import Loader from "../../../Loader/Loader";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import PropTypes from "prop-types";
import { RemoveSCFromInputs } from "../../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function Sources({ roles }) {
  const { totalRows } = useContext(PageNumberContext);
  const { header } = useStyles();
  const [pagesize, setpagesize] = useState(totalRows);
  const { Sources, status } = useSelector(getSourcesDetails);
  const [allSources, setallSources] = useState([]);
  const [rows, setrows] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const [showLoading, setshowLoading] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const name = useRef();
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSources());
  }, [dispatch]);
  const navigate = useNavigate();

  useEffect(() => {
    let arr = [];
    if (Sources) {
      Sources?.forEach((source, idx) => {
        return arr.push({
          id: idx + 1,
          name: source?.Name,
          ID: source?.Id,
        });
      });
    }
    setrows(arr);
    setallSources(arr);
  }, [Sources]);
  const deleteSource = async (id) => {
    setshowLoading(true);
    let res = await DeleteSourceApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);
    if (res.data.IsSuccess) {
      dispatch(fetchSources());
    }
    setsnackBarData(res.snackBarData);
  };

  const columns =
    roles?.some((role) => role === "Sources_Edit") ||
    roles?.some((role) => role === "Sources_Delete")
      ? [
          { field: "id", headerName: "Sr", width: 90, headerClassName: header },
          {
            field: "name",
            headerName: "Name",
            width: 250,
            headerClassName: header,
          },
          {
            field: "action",
            headerName: "Action",
            width: 150,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <Box key={i}>
                  {roles?.some((role) => role === "Sources_Edit") && (
                    <Tooltip title="Edit">
                      <IconButton
                        key={i}
                        onClick={() => {
                          handleEdit(celval.row);
                        }}
                      >
                        <EditIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {roles?.some((role) => role === "Sources_Delete") && (
                    <Tooltip title="Delete">
                      <IconButton
                        key={i}
                        onClick={() => {
                          setselectedId(celval.row.ID);
                          setshowConfirmationIcons(true);
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : [
          { field: "id", headerName: "Sr", width: 90, headerClassName: header },
          {
            field: "name",
            headerName: "Name",
            width: 250,
            headerClassName: header,
          },
        ];

  const handleEdit = (val) => {
    navigate("/settings/sources/create", { state: val });
  };
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  const onTextChange = () => {
    if (name.current?.value === "") {
      setallSources(rows);
    } else {
      let suggestions = [];
      // let regex1 = new RegExp(ID.current.value, "i");
      let regex2 = new RegExp(RemoveSCFromInputs(name.current.value), "i");
      suggestions = rows?.filter(
        (val) => name.current.value === "" || regex2.test(val?.name)
      );
      setallSources(suggestions);
    }
  };

  return (
    <>
      <Box>
        <SourcesFilterDrawer
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          setcount={setcount}
          setallSources={setallSources}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allSources}
            columns={columns}
          />
          {status === "loading" || showLoading ? <Loader /> : null}
        </Box>
      </Box>

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteSource(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Source"
        title={`Are you sure, you want to Delete Source?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Sources;
Sources.propTypes = {
  roles: PropTypes.array,
};
