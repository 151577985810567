import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const NotificationContext = createContext();

const NotificationContextProvider = (props) => {
  const [notifications, setNotifications] = useState([]);

  return (
    <NotificationContext.Provider value={{ notifications, setNotifications }}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;

NotificationContextProvider.propTypes = {
  children: PropTypes.any,
};
