import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
//   import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
function EmailInfoModal({ showModal, handleClosed, email, emailorContact }) {
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            {emailorContact === "email"
              ? "Emails Info"
              : emailorContact === "contact"
              ? "Contacts Info"
              : null}
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        {/* <Box className={textContainer}>
        <Box className={textAreaContainer}>
          <Typography variant="body1" fontWeight="bold">
            Name:
          </Typography>
          <Typography>{email?.name}</Typography>
        </Box>
        <Box className={textAreaContainer}>
          <Typography variant="body1" fontWeight="bold">
            Type:
          </Typography>
          <Typography>{email?.type}</Typography>
        </Box>
      </Box>
      <Typography variant="body1" fontWeight="bold">
        File Content:
      </Typography> */}
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {emailorContact === "email"
            ? email?.allEmails?.map((data, index) => (
                <Chip key={index} label={data?.Email} variant="outlined" />
              ))
            : email?.allContact?.map((data, index) => (
                <Chip
                  key={index}
                  label={data?.ContactNumber}
                  variant="outlined"
                />
              )) ||
              email?.PersonContacts?.map((data, index) => (
                <Chip
                  key={index}
                  label={data?.ContactNumber}
                  variant="outlined"
                />
              ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EmailInfoModal;
EmailInfoModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  email: PropTypes.any,
  emailorContact: PropTypes.any,
};
