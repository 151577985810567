import React, { useContext, useEffect } from "react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  // CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InventoryIcon from "@mui/icons-material/Inventory";
import theme from "../../../theme";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { CreateProductApi } from "../../../Api/ProductsApi/CreateProductApi";
import { UpdateProductApi } from "../../../Api/ProductsApi/UpdateProductApi";
import { ReactSelectTheme } from "../../../Utils/ReactSelectTheme";
// import { fetchProducts } from '../../../Redux/Slice/ProductSlice/ProductSlice';

// import Loader from '../../Loader/Loader';
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function CreateProduct() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const initialValues = {
    name: "",
    description: "",
    sku: "",
    cost: "",
    quantity: "",
    price: "",
    productCat: "",
    productCode: "",
    uomValue: "",
    commissionPercentage: "",
    tax: false,
    active: false,
    image: null,
    imageName: "",
  };
  const uomOptions = [
    { value: "Box", label: "Box" },
    { value: "Carton", label: "Carton" },
    { value: "Dozen", label: "Dozen" },
    { value: "Each", label: "Each" },
    { value: "Hour(s)", label: "Hour(s)" },
    { value: "Impressions", label: "Impressions" },
    { value: "Lb", label: "Lb" },
    { value: "M", label: "M" },
    { value: "Pack", label: "Pack" },
    { value: "Pages", label: "Pages" },
    { value: "Pieces", label: "Pieces" },
    { value: "Quantity", label: "Quantity" },
    { value: "Reams", label: "Reams" },
    { value: "Sheet", label: "Sheet" },
    { value: "Spiral Blinder", label: "Spiral Blinder" },
    { value: "Square Feet", label: "Square Feet" },
  ];
  const catOptions = [
    { value: "Hardware", label: "Hardware" },
    { value: "Software", label: "Software" },
    { value: "CRM Applications", label: "CRM Applications" },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      handleEdit(state);
    }
  }, []);
  const handleEdit = (values) => {
    console.log("values", values);
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
    formik.handleChange({
      target: {
        name: "sku",
        value: values?.sku || "",
      },
    });
    formik.handleChange({
      target: {
        name: "quantity",
        value: values?.quantity || "",
      },
    });
    formik.handleChange({
      target: {
        name: "price",
        value: values?.price?.replace(/,/g, "") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "cost",
        value: values?.cost?.replace(/,/g, "") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "description",
        value: values?.Description || "",
      },
    });
    formik.handleChange({
      target: {
        name: "uomValue",
        value: { value: values?.Uom, label: values?.Uom } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "tax",
        value: values.Tax,
      },
    });
    formik.handleChange({
      target: {
        name: "productCode",
        value: values?.ProductCode || "",
      },
    });
    formik.handleChange({
      target: {
        name: "productCat",
        value:
          { value: values?.ProductCategory, label: values?.ProductCategory } ||
          "",
      },
    });
    formik.handleChange({
      target: {
        name: "commissionPercentage",
        value: values?.CommissionPercentage || "",
      },
    });
    formik.handleChange({
      target: {
        name: "active",
        value: values?.Active,
      },
    });
    formik.handleChange({
      target: {
        name: "imageName",
        value: values?.ImageName || "",
      },
    });
    console.log("state.Image", state.Image);
    const imagePreview = document.getElementById("image-preview");
    if (state.Image) {
      imagePreview.src = state.Image;
    }
  };
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    // const data = {
    //   ...values,
    //   uom: values.uomValue?.label,
    //   productCategory: values.productCat?.label,
    // };
    console.log("values555", values);
    let formData = new FormData();
    formData.append("Name", values.name);
    formData.append("Sku", values.sku);
    formData.append("ImageName", values.imageName);
    formData.append("Image", values.image);
    formData.append("Cost", values.cost);
    formData.append("Price", values.price);
    formData.append("CommissionPercentage", values.commissionPercentage);
    formData.append("Uom", values.uomValue?.label);
    formData.append("ProductCode", values.productCode);
    formData.append("ProductCategory", values.productCat?.label);
    formData.append("Tax", values.tax);
    formData.append("Active", values.active);
    formData.append("Quantity", values.quantity);
    formData.append("Description", values.description);

    if (state) {
      // const response = await UpdateProductApi({ ...values, id: state.ID });
      const response = await UpdateProductApi(formData, state.ID);

      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        // dispatch(fetchProducts());
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
      setSubmitting(false);
    } else {
      const response = await CreateProductApi(formData);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        // dispatch(fetchProducts());
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
      setSubmitting(false);
    }
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Product Name"),
    description: Yup.string().required("Please Enter Description"),
    sku: Yup.string().required("Please Enter sku"),
    quantity: Yup.string().required("Please Enter quantity"),
    price: Yup.string().required("Please Enter Price"),
    cost: Yup.string().required("Please Enter Cost"),

    productCat: Yup.object().required("Please Product Category"),
    uomValue: Yup.object().required("Please UOM"),
    // image: Yup.mixed().test(
    //   "fileSize",
    //   "File size must be less than 1MB",
    //   (value) => {
    //     if (!value) return true;
    //     return value.size <= 1024 * 1024;
    //   }
    // ),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const handleClearImage = () => {
    // formik.setValues({ image: null });
    formik.setFieldValue("image", null);
    formik.setFieldValue("imageName", null);
    document.getElementById("imageInput").value = null;
    const imagePreview = document.getElementById("image-preview");
    if (imagePreview) {
      imagePreview.src = null;
    }
  };
  console.log("formik.values.image", formik.values.image);
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/products"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Products
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state ? "Update Product" : " Create Product"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state ? "Update Product" : " Create Product"}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={0}
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="imageInput"
                    type="file"
                    onChange={(event) => {
                      const selectedFile = event.currentTarget.files[0];

                      formik.setFieldValue("image", selectedFile);

                      const imagePreview =
                        document.getElementById("image-preview");
                      if (imagePreview && selectedFile) {
                        imagePreview.src = URL.createObjectURL(selectedFile);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {(formik.values.image || formik.values.imageName) && (
                    <Button
                      onClick={handleClearImage}
                      style={{
                        // position: "absolute",
                        top: "15px",
                        left: "45px",
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      color="primary"
                    >
                      <HighlightOffIcon />
                    </Button>
                  )}
                  <Box mt={0} alignItems="center">
                    <img
                      id="image-preview"
                      alt="Not Selected"
                      width="80px"
                      height="80px"
                      style={{
                        display:
                          formik.values.image || formik.values.imageName
                            ? ""
                            : "none",
                        borderRadius: "100%",
                      }}
                    />
                    <Avatar
                      sx={{
                        display:
                          formik.values.image || formik.values.imageName
                            ? "none"
                            : "",
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      <InventoryIcon fontSize="large" />
                    </Avatar>
                  </Box>

                  <label htmlFor="imageInput">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        mt: 1,

                        textTransform: "none",
                      }}
                    >
                      {state ? "Update Image" : "Upload Image"}
                    </Button>
                  </label>
                  {formik.touched.image ? (
                    <Typography color="error">{formik.errors.image}</Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </FormControl>

              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  value={formik.values?.name}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                  }
                  helperText={
                    Boolean(formik.errors.name) &&
                    Boolean(formik.touched.name) &&
                    formik.errors.name
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Description</Typography>
                <TextField
                  name="description"
                  value={formik.values?.description}
                  size={"small"}
                  multiline
                  rows={4}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.description) &&
                    Boolean(formik.touched.description)
                  }
                  helperText={
                    Boolean(formik.errors.description) &&
                    Boolean(formik.touched.description) &&
                    formik.errors.description
                  }
                />
              </FormControl>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>SKU</Typography>
                  <TextField
                    name="sku"
                    type="text"
                    value={formik.values?.sku}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.sku) && Boolean(formik.touched.sku)
                    }
                    helperText={
                      Boolean(formik.errors.sku) &&
                      Boolean(formik.touched.sku) &&
                      formik.errors.sku
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Cost</Typography>
                  <TextField
                    name="cost"
                    value={formik.values?.cost}
                    size={"small"}
                    type="number"
                    fullWidth
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        formik.setFieldValue("cost", e.target.value);
                      } else {
                        return;
                      }
                    }}
                    disabled={state ? true : false}
                    error={
                      Boolean(formik.errors.cost) &&
                      Boolean(formik.touched.cost)
                    }
                    helperText={
                      Boolean(formik.errors.cost) &&
                      Boolean(formik.touched.cost) &&
                      formik.errors.cost
                    }
                  />
                </FormControl>
              </Box>

              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Quantity</Typography>
                  <TextField
                    name="quantity"
                    value={formik.values?.quantity}
                    size={"small"}
                    disabled={state ? true : false}
                    fullWidth
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        formik.setFieldValue("quantity", e.target.value);
                      } else {
                        return;
                      }
                    }}
                    type="number"
                    error={
                      Boolean(formik.errors.quantity) &&
                      Boolean(formik.touched.quantity)
                    }
                    helperText={
                      Boolean(formik.errors.quantity) &&
                      Boolean(formik.touched.quantity) &&
                      formik.errors.quantity
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Price</Typography>
                  <TextField
                    name="price"
                    value={formik.values?.price}
                    size={"small"}
                    type="number"
                    fullWidth
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        formik.setFieldValue("price", e.target.value);
                      } else {
                        return;
                      }
                    }}
                    disabled={state ? true : false}
                    error={
                      Boolean(formik.errors.price) &&
                      Boolean(formik.touched.price)
                    }
                    helperText={
                      Boolean(formik.errors.price) &&
                      Boolean(formik.touched.price) &&
                      formik.errors.price
                    }
                  />
                </FormControl>
              </Box>
              <FormControl
                fullWidth
                error={
                  Boolean(formik.errors.productCat) &&
                  Boolean(formik.touched.productCat)
                }
              >
                <Typography>Product Category</Typography>
                <Select
                  // styles={{
                  //   control: (baseStyles) => ({
                  //     ...baseStyles,
                  //     borderColor:
                  //       Boolean(formik.errors.productCat) &&
                  //       Boolean(formik.touched.productCat)
                  //         ? "red"
                  //         : "#bdbdbd",
                  //   }),
                  // }}
                  styles={ReactSelectTheme(
                    Boolean(formik.errors.productCat) &&
                      Boolean(formik.touched.productCat)
                  )}
                  name="productCat"
                  options={catOptions}
                  value={formik.values.productCat}
                  onChange={(value) => {
                    formik.setFieldValue("productCat", value);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Select Product Category"
                />
                <FormHelperText>
                  {Boolean(formik.errors.productCat) &&
                  Boolean(formik.touched.productCat)
                    ? formik.errors.productCat
                    : ""}
                </FormHelperText>
              </FormControl>

              <FormControl
                fullWidth
                error={
                  Boolean(formik.errors.uomValue) &&
                  Boolean(formik.touched.uomValue)
                }
              >
                <Typography>UOM</Typography>

                <Select
                  // styles={{
                  //   control: (baseStyles) => ({
                  //     ...baseStyles,
                  //     borderColor:
                  //       Boolean(formik.errors.uomValue) &&
                  //       Boolean(formik.touched.uomValue)
                  //         ? "red"
                  //         : "#bdbdbd",
                  //   }),
                  // }}
                  styles={ReactSelectTheme(
                    Boolean(formik.errors.uomValue) &&
                      Boolean(formik.touched.uomValue)
                  )}
                  name="uomValue"
                  options={uomOptions}
                  value={formik.values.uomValue}
                  onChange={(value) => {
                    formik.setFieldValue("uomValue", value);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Select UOM"
                />
                <FormHelperText>
                  {Boolean(formik.errors.uomValue) &&
                  Boolean(formik.touched.uomValue)
                    ? formik.errors.uomValue
                    : ""}
                </FormHelperText>
              </FormControl>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Product Code</Typography>
                  <TextField
                    name="productCode"
                    value={formik.values?.productCode}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Commission Percentage</Typography>
                  <TextField
                    name="commissionPercentage"
                    value={formik.values?.commissionPercentage}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box>
              <FormGroup style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="active"
                      onChange={(e) => {
                        formik.setFieldValue("active", e.target.checked);
                      }}
                      checked={formik.values?.active}
                    />
                  }
                  label="Active"
                />
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      name="tax"
                      onChange={(e) => {
                        formik.setFieldValue("tax", e.target.checked);
                      }}
                      checked={formik.values?.tax}
                    />
                  }
                  label="Tax"
                />
              </FormGroup>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreateProduct;
