import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useContext } from "react";
import PropTypes from "prop-types";

import Joyride from "react-joyride";
import { getOrganizationDetails } from "../../../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import { AppContext } from "../../../../Context/TourContext/TourContext";
import DocumentModal from "../../../DocumentModal/DocumentModal";
import DocumentDetailModal from "../../../DocumentModal/DocumentDetailModal";
import ProductsInfoModal2 from "../../../../Pages/Products/ProductsInfoModal2";
import Loader from "../../../Loader/Loader";
// import Cookies from "universal-cookie";

import {
  fetchProjects,
  getProjectsDetails,
} from "../../../../Redux/Slice/ProjectSlice/ProjectCategorySlice";
import { RemoveSCFromInputs } from "../../../../Utils/RemoveSCFromInputs";
import { TourCheckApi } from "../../../../Api/TourCheckApi/TourCheckApi";
import ProjectFilterDrawer from "./ProjectFilterDrawer/ProjectFilterDrawer";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";

function Project({ roles }) {
  const { totalRows } = useContext(PageNumberContext);
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const navigate = useNavigate();
  const [pagesize, setpagesize] = useState(totalRows);
  const dispatch = useDispatch();
  const [rows, setrows] = React.useState([]);
  const [allProject, setallProject] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const { Projects, status } = useSelector(getProjectsDetails);

  const owner = useRef();
  const name = useRef();
  const city = useRef();
  const location = useRef();
  const category = useRef();
  const [showModal2, setShowModal2] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [documentDetailModal, setdocumentDetailModal] = useState(false);
  const { DocumentsData, status2 } = useSelector(getOrganizationDetails);
  const { setState, state } = useContext(AppContext);
  const [productInfoModal, setproductInfoModal] = useState(false);
  const [productRow, setproductRow] = useState(null);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Projects");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    // if (Projects == null) {
    dispatch(fetchProjects());
    // }
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    Projects?.map((project, i) => {
      rowData.push({
        id: i + 1,
        name: project.ProjectName,
        owner: project.ProjectOwner?.Name,
        ProjectOwner: project?.ProjectOwner,
        location: project.ProjectLocation,
        city: project.ProjectCity,
        ID: project.ProjectId,
        category: project.ProjectCategory,
      });
    });
    setrows(rowData);
    setallProject(rowData);
  }, [Projects]);

  const columns = [
    { field: "id", headerName: "Sr", width: 90 },
    {
      field: "name",
      headerName: "Project Name",
      width: 180,
    },
    {
      field: "category",
      headerName: "Category",
      width: 200,
      renderCell: (celval) => {
        return celval.formattedValue.ProjectCategoryName;
      },
    },

    {
      field: "owner",
      headerName: "Owner",
      width: 180,
    },
    {
      field: "location",
      headerName: "Location",
      width: 180,
    },
    {
      field: "city",
      headerName: "City",
      width: 180,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (celval, i) => {
        return (
          <>
            {roles?.some((role) => role === "Projects_Edit") && (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row);
                  }}
                >
                  <EditIcon
                    sx={{ color: (theme) => theme?.palette?.primary?.edit }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}
            {/* <Tooltip title="Delete">
              <IconButton
                key={i}
                onClick={() => {
                  setshowConfirmationIcons(true);
                  setselectedId(celval.row.ID);
                }}
                disabled={
                  roles?.some((role) => role === "Products_Delete")
                    ? false
                    : true
                }
              >
                <DeleteForeverIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.delete,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="View">
              <IconButton
                key={i}
                onClick={() => {
                  console.log("celval.row", celval.row);
                  setproductRow(celval.row);
                  setproductInfoModal(true);
                  // setShowModal(true);
                  // setProduct(celval.row);
                  // dispatch(fetchproduct(celval.row?.ID));
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const handleEdit = (val) => {
    navigate("/settings/inventory/project/create", { state: val });
  };
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  const onTextChange = () => {
    if (
      city.current?.value === "" &&
      name.current?.value === "" &&
      location.current?.value === "" &&
      owner.current?.value === "" &&
      category.current?.value === ""
    ) {
      setallProject(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(RemoveSCFromInputs(city.current.value), "i");
      let regex2 = new RegExp(RemoveSCFromInputs(name.current.value), "i");
      let regex3 = new RegExp(RemoveSCFromInputs(location.current.value), "i");
      let regex4 = new RegExp(RemoveSCFromInputs(owner.current.value), "i");
      let regex5 = new RegExp(RemoveSCFromInputs(category.current.value), "i");
      suggestions = rows?.filter(
        (val) =>
          (city.current.value === "" || regex1.test(val.city)) &&
          (name.current.value === "" || regex2.test(val?.name)) &&
          (location.current.value === "" || regex3.test(val?.location)) &&
          (owner.current.value === "" || regex4.test(val?.owner)) &&
          (category.current.value === "" ||
            regex5.test(val?.category?.ProjectCategoryName))
      );
      setallProject(suggestions);
    }
  };
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Projects"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="project">
        <ProjectFilterDrawer
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          city={city}
          location={location}
          category={category}
          setcount={setcount}
          setallProject={setallProject}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          owner={owner}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allProject}
            columns={columns}
          />
          {status === "loading" || status2 === "loading" ? <Loader /> : null}
        </Box>
      </Box>
      {showModal2 && (
        <DocumentModal
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Projects"
          setShowModal={setShowModal2}
          showModal={showModal2}
        />
      )}
      {documentDetailModal && (
        <DocumentDetailModal
          DocumentsData={DocumentsData}
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Projects"
          setShowModal={setdocumentDetailModal}
          setformModal={setShowModal2}
          showModal={documentDetailModal}
        />
      )}
      {productInfoModal && (
        <ProductsInfoModal2
          productRow={productRow}
          setproductRow={setproductRow}
          setproductInfoModal={setproductInfoModal}
          productInfoModal={productInfoModal}
        />
      )}
    </>
  );
}

export default Project;
Project.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#project",
    content: (
      <>
        <p size="large">Projects Page</p>
        <p>
          Welcome to the Project Management screen – your hub for organizing and
          overseeing inventory projects. Here, you can create, manage, and track
          various projects related to inventory management, ensuring smooth
          execution and timely completion.
          <br />
          <br />
          Click &apos;New&apos; to create a new project and kickstart your
          inventory initiatives.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
