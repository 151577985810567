import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import AirlineSeatFlatAngledIcon from "@mui/icons-material/AirlineSeatFlatAngled";
import SourceIcon from "@mui/icons-material/Source";
import TypeSpecimenIcon from "@mui/icons-material/TypeSpecimen";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const useStyle = makeStyles(() => {
  return {
    paperBox: {
      width: "100%",
      maxWidth: "1280px",
      padding: "20px",
      marginTop: "20px",
    },
    paperContentBox: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: "10px",
    },
    contentBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    textBox: {
      minHeight: "90px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    setingText: {
      transform: "scale(0)",
      height: "0px",
    },
  };
});

function Lead({ roles }) {
  const { paperBox, paperContentBox, contentBox, textBox, setingText } =
    useStyle();
  const navigate = useNavigate();
  return (
    <Box>
      <Typography sx={{ fontWeight: "bold" }}>Lead</Typography>
      <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
        Manage all your leads related settings in the CRM.
      </Typography>
      <Paper className={paperBox} elevation={3} sx={{}}>
        <Box className={paperContentBox} sx={{}}>
          {roles?.some((role) => role === "Pipelines") && (
            <Box
              onClick={() => {
                navigate("/settings/piplines");
              }}
              className="settingBoxA"
              sx={{ padding: "10px", cursor: "pointer" }}
              id="settings-pipelines"
            >
              <Box className={contentBox} sx={{}}>
                <AirlineSeatFlatAngledIcon
                  fontSize="large"
                  sx={{ color: theme.palette.primary.main }}
                />
                <Box className={textBox} sx={{}}>
                  <Typography variant="h6">Piplines</Typography>
                  <Typography
                    variant="body2"
                    className={`${setingText} settingBoxAhoverEffect`}
                    sx={{}}
                  >
                    Add,edit or delete Piplines from{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${setingText} settingBoxAhoverEffect`}
                    sx={{}}
                  >
                    CRM
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {roles?.some((role) => role === "Sources") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/sources");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-sources"
              >
                <Box className={contentBox} sx={{}}>
                  <SourceIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box className={textBox} sx={{}}>
                    <Typography variant="h6">Sources</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add,edit or delete Sources from{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      CRM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {roles?.some((role) => role === "Types") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/Types");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-types"
              >
                <Box className={contentBox} sx={{}}>
                  <TypeSpecimenIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box className={textBox} sx={{}}>
                    <Typography variant="h6">Types</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add,edit or delete Types from{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      CRM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {roles?.some((role) => role === "Lead_Descriptions") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/descriptions");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-types"
              >
                <Box className={contentBox} sx={{}}>
                  <TypeSpecimenIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box className={textBox} sx={{}}>
                    <Typography variant="h6">Descriptions</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add,edit or View Descriptions from{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      CRM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default Lead;
Lead.propTypes = {
  roles: PropTypes.array,
};
