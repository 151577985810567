import {
  Box,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import TargetNotes from "./Notes/TargetNotes";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "20px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
    },
    iconBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    noteboxMain: {
      display: "flex",
      flexDirection: "column",
      //   gap: "10px",
      padding: "20px",
      maxHeight: "300px",
      overflow: "auto",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "40px",
      alignItems: "center",
      // width:"100%"
    },
  };
});
function TargetInfoModal({
  targetInfoModal,
  settargetInfoModal,
  settargetRow,
  targetRow,
  roles,
}) {
  const { Container, ModalContainer, TableHeader, noteboxMain, formContainer } =
    useStyles();
  return (
    <>
      <Modal
        open={targetInfoModal}
        onClose={() => {
          settargetInfoModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Target Info
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  settargetRow(null);
                  settargetInfoModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box
                sx={{
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "3px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Target Title:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "30px",
                        }}
                      >
                        {targetRow?.Title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "3px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Start Date:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "40px",
                        }}
                      >
                        {moment(targetRow?.StartDate).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "3px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Group Name:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "15px",
                        }}
                      >
                        {targetRow?.Group?.Name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "3px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        End Date:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "40px",
                        }}
                      >
                        {moment(targetRow?.EndDate).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Description:
                  </Typography>
                  <Typography
                    sx={{
                      paddingLeft: "30px",
                    }}
                  >
                    {targetRow?.Description}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Target Details:
                  </Typography>
                  <TableContainer
                    sx={{
                      maxHeight: "300px",
                    }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: "bold",

                              // color: (theme) => theme.palette.grey["500"],
                            }}
                          >
                            Project
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: "bold",
                              // color: (theme) => theme.palette.grey["500"],
                            }}
                          >
                            Category
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: "bold",
                              // color: (theme) => theme.palette.grey["500"],
                            }}
                          >
                            No. of Plots
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {targetRow?.TargetDetail &&
                          targetRow?.TargetDetail?.map((row, i) => {
                            return (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  {row?.Project?.ProjectName}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.Category?.CategoryName}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.NumberOfPlots}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Target Notes:
                  </Typography>
                  {targetRow?.TargetNotes?.length > 0 ? (
                    <Box className={noteboxMain}>
                      <TargetNotes target={targetRow} roles={roles} />
                    </Box>
                  ) : (
                    <Box className={formContainer}>
                      <Typography>You have no notes.</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default TargetInfoModal;
TargetInfoModal.propTypes = {
  targetInfoModal: PropTypes.any,
  settargetInfoModal: PropTypes.any,
  targetRow: PropTypes.any,
  settargetRow: PropTypes.any,
  roles: PropTypes.any,
};
