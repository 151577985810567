import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  Badge,
  Button,
  Chip,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  fetchDeletedLeadsData,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import moment from "moment/moment";
import PropTypes from "prop-types";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AppConfirmationDialog from "../../AppConfirmationDialog/AppConfirmationDialog";
import Loader from "../../Loader/Loader";
import {
  DeleteLeadPermanentlyApi,
  RecoverLeadApi,
} from "../../../Api/LeadsApi/DeleteLeadApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import LoopIcon from "@mui/icons-material/Loop";
import AppTooltip from "../../../Utils/AppTooltip";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import RecycleLeadsFilterDrawer from "../RecycleLeadsFilterDrawer/RecycleLeadsFilterDrawer";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

function RecycleLeadsTable({ defaultpipline, roles }) {
  const { header, customTabSelected, customTab, datGridBox } = useStyles();
  const { totalRows } = useContext(PageNumberContext);

  const [pagesize, setpagesize] = useState(totalRows);
  const [allLeads, setallLeads] = useState([]);
  const [rows, setrows] = useState([]);
  const [selectedId, setselectedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setshowDeleteConfirmation] = useState(false);
  const [showRecoverConfirmation, setshowRecoverConfirmation] = useState(false);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [selectedFilter, setselectedFilter] = useState("All");
  const [filterVal, setfilterVal] = useState({
    person: "",
    salesperson: "",
    subject: "",
    stage: "",
    leadValue: "",
    leadSource: "",
    createdate2: "",
    enddate: "",
    closeDate: "",
    tags: [],
    project: "",
  });

  const { setsnackBarData } = useContext(SnackBarContext);

  const dispatch = useDispatch();
  const { DeletedLeads, status } = useSelector(getLeadsDetails);

  useEffect(() => {
    if (defaultpipline) {
      dispatch(fetchDeletedLeadsData(defaultpipline?.Id));
    }
  }, [defaultpipline]);

  const handleChangeVal = (e) => {
    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value || "",
    });
  };

  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (typeof filterVal[value] === "number") {
        sum = sum + 1;
      } else {
        if (filterVal[value]?.length) {
          sum = sum + 1;
        }
      }
    }
    setcount(sum);
  }, [filterVal]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box>
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns =
    roles?.some((role) => role === "Recycle_Edit") ||
    roles?.some((role) => role === "Recycle_Delete")
      ? [
          {
            field: "id",
            headerName: "Sr.",
            width: 90,
            headerClassName: header,
          },
          {
            field: "Projects",
            headerName: "Projects",
            width: 300,
            renderCell: (celval) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {celval.formattedValue?.map((proj, idx) => (
                    <div key={idx} style={{ marginRight: "5px" }}>
                      {idx > 0 && "| "}
                      {proj?.ProjectName}
                    </div>
                  ))}
                </Box>
              );
            },
          },
          {
            field: "PipelineStage",
            headerName: "Stage",
            width: 200,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor:
                        cellVal.row.PipelineStage === "Won"
                          ? "#53c41a"
                          : cellVal.row.PipelineStage === "Lost"
                          ? "red"
                          : "#0e90d9",
                      borderRadius: "100%",
                    }}
                  ></Box>
                  <Typography>{cellVal.row.PipelineStage}</Typography>
                </Box>
              );
            },
          },
          {
            field: "Title",
            headerName: "Title",
            width: 180,

            headerClassName: header,
          },
          {
            field: "FullName",
            headerName: "Sales Person",
            width: 180,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Link underline="none" sx={{ cursor: "pointer" }}>
                  <Typography>{cellVal.row.FullName}</Typography>
                </Link>
              );
            },
          },
          {
            field: "PersonName",
            headerName: "Customer",
            width: 180,

            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Link underline="none" sx={{ cursor: "pointer" }}>
                  <Typography>{cellVal.row.PersonName}</Typography>
                </Link>
              );
            },
          },
          {
            field: "LeadValue",
            headerName: "Lead Value",
            width: 150,

            headerClassName: header,
          },
          {
            field: "LeadSource",
            headerName: "Lead Source",
            width: 180,

            headerClassName: header,
          },
          {
            field: "ExpectedCloseDate",
            headerName: "Expected Close Date",
            width: 170,

            headerClassName: header,
          },
          {
            field: "Tags",
            headerName: "Tags",
            width: 250,
            renderCell: (celval) => {
              return celval.formattedValue?.map((tag, idx) => (
                <div
                  key={idx}
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  <Chip
                    size="small"
                    label={tag?.Name}
                    sx={{
                      margin: "1px",
                      backgroundColor: tag?.Color,
                      color: "#fff",
                    }}
                  />
                </div>
              ));
            },
          },
          {
            field: "action",
            headerName: "Action",
            width: 180,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <Box>
                  {roles?.some((role) => role === "Recycle_Edit") && (
                    <AppTooltip title="Recover">
                      <IconButton
                        onClick={() => {
                          setselectedId(celval.row.ID);
                          setshowRecoverConfirmation(true);
                        }}
                      >
                        <LoopIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </AppTooltip>
                  )}

                  {roles?.some((role) => role === "Recycle_Delete") && (
                    <AppTooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setselectedId(celval.row.ID);
                          setshowDeleteConfirmation(true);
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </AppTooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : [
          {
            field: "id",
            headerName: "Sr.",
            width: 90,
            headerClassName: header,
          },
          {
            field: "Projects",
            headerName: "Projects",
            width: 300,
            renderCell: (celval) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {celval.formattedValue?.map((proj, idx) => (
                    <div key={idx} style={{ marginRight: "5px" }}>
                      {idx > 0 && "| "}
                      {proj?.ProjectName}
                    </div>
                  ))}
                </Box>
              );
            },
          },
          {
            field: "PipelineStage",
            headerName: "Stage",
            width: 200,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor:
                        cellVal.row.PipelineStage === "Won"
                          ? "#53c41a"
                          : cellVal.row.PipelineStage === "Lost"
                          ? "red"
                          : "#0e90d9",
                      borderRadius: "100%",
                    }}
                  ></Box>
                  <Typography>{cellVal.row.PipelineStage}</Typography>
                </Box>
              );
            },
          },
          {
            field: "Title",
            headerName: "Title",
            width: 180,

            headerClassName: header,
          },
          {
            field: "FullName",
            headerName: "Sales Person",
            width: 180,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Link underline="none" sx={{ cursor: "pointer" }}>
                  <Typography>{cellVal.row.FullName}</Typography>
                </Link>
              );
            },
          },
          {
            field: "PersonName",
            headerName: "Customer",
            width: 180,

            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Link underline="none" sx={{ cursor: "pointer" }}>
                  <Typography>{cellVal.row.PersonName}</Typography>
                </Link>
              );
            },
          },
          {
            field: "LeadValue",
            headerName: "Lead Value",
            width: 150,

            headerClassName: header,
          },
          {
            field: "LeadSource",
            headerName: "Lead Source",
            width: 180,

            headerClassName: header,
          },
          {
            field: "ExpectedCloseDate",
            headerName: "Expected Close Date",
            width: 170,

            headerClassName: header,
          },
          {
            field: "Tags",
            headerName: "Tags",
            width: 250,
            renderCell: (celval) => {
              return celval.formattedValue?.map((tag, idx) => (
                <div
                  key={idx}
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  <Chip
                    size="small"
                    label={tag?.Name}
                    sx={{
                      margin: "1px",
                      backgroundColor: tag?.Color,
                      color: "#fff",
                    }}
                  />
                </div>
              ));
            },
          },
        ];

  useEffect(() => {
    let rowData = [];
    DeletedLeads?.map((lead, i) => {
      rowData.push({
        ...lead,
        id: i + 1,
        ID: lead.Id,
        LeadSource: lead.LeadSource?.Name,
        LeadValue: lead.LeadValue
          ? Math.round(lead.LeadValue)?.toLocaleString()
          : "",
        PipelineStage: lead.PipelineStage?.Name,
        PersonName: lead?.PersonName,
        ExpectedCloseDate: lead.ExpectedCloseDate
          ? moment(lead.ExpectedCloseDate).format("DD-MMM-YYYY")
          : null,
        CreatedAt: lead.CreatedAt
          ? moment(lead.CreatedAt).format("DD-MMM-YYYY")
          : null,
        Tags: lead?.LeadTags,
        Projects: lead?.Projects,
      });
    });
    setrows(rowData);
    setallLeads(rowData);
  }, [DeletedLeads]);

  useEffect(() => {
    if (
      filterVal?.salesperson === "" &&
      filterVal?.person === "" &&
      filterVal?.leadValue === "" &&
      filterVal?.leadSource === "" &&
      filterVal?.stage === "" &&
      filterVal?.subject === "" &&
      filterVal?.closeDate === "" &&
      filterVal?.tags?.length === 0 &&
      filterVal?.project === ""
    ) {
      setallLeads(rows);
    } else {
      const leadValue = parseFloat(filterVal?.leadValue.replace(/,/g, ""));
      let suggestions = [];
      // let regex2 = new RegExp(filterVal?.salesperson, "i");
      // let regex3 = new RegExp(filterVal?.person, "i");
      let regex4 = new RegExp(leadValue, "i");
      let regex5 = new RegExp(filterVal?.leadSource, "i");
      let regex6 = new RegExp(filterVal?.stage, "i");
      let regex7 = new RegExp(filterVal?.subject, "i");
      let regex9 = new RegExp(
        moment(filterVal?.closeDate).format("DD-MMM-YYYY"),
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          (filterVal?.salesperson === "" ||
            val?.UserId === filterVal?.salesperson) &&
          (filterVal?.person === "" || val?.PersonId === filterVal?.person) &&
          (filterVal?.leadValue === "" ||
            regex4.test(parseFloat(val?.LeadValue.replace(/,/g, "")))) &&
          (filterVal?.leadSource === "" || regex5.test(val?.LeadSource)) &&
          (filterVal?.stage === "" || regex6.test(val?.PipelineStage)) &&
          (filterVal?.subject === "" || regex7.test(val?.Title)) &&
          (filterVal?.closeDate === "" ||
            regex9.test(val?.ExpectedCloseDate)) &&
          (filterVal?.tags?.length === 0 ||
            filterVal?.tags?.every((element) =>
              val.LeadTags?.some((tag) => tag?.TagId === element?.TagId)
            )) &&
          (filterVal?.project === "" ||
            val.Projects.some(
              (project) => project.ProjectId === filterVal?.project
            ))
      );
      setallLeads(suggestions);
    }
  }, [filterVal, rows]);

  useEffect(() => {
    if (selectedFilter === "All") {
      return setallLeads(rows);
    }
    let valuess = rows?.filter(
      (item) =>
        item?.PipelineStage?.toLowerCase() ===
        selectedFilter?.Name?.toLowerCase()
    );
    setallLeads(valuess);
  }, [selectedFilter]);

  const recoverLeads = async () => {
    setLoading(true);
    let res = await RecoverLeadApi(selectedId);
    setLoading(false);
    setselectedId(null);
    setshowRecoverConfirmation(false);
    if (res.data.IsSuccess) {
      dispatch(fetchDeletedLeadsData(defaultpipline?.Id));
    }
    setsnackBarData(res.snackBarData);
  };

  const deleteLeads = async () => {
    setLoading(true);
    let res = await DeleteLeadPermanentlyApi(selectedId);
    setLoading(false);
    setselectedId(null);
    setshowDeleteConfirmation(false);
    if (res.data.IsSuccess) {
      dispatch(fetchDeletedLeadsData(defaultpipline?.Id));
    }
    setsnackBarData(res.snackBarData);
  };

  return (
    <>
      <Box>
        <Box>
          <RecycleLeadsFilterDrawer
            setdrawerOpen={setdrawerOpen}
            drawerOpen={drawerOpen}
            rows={rows}
            setallLeads={setallLeads}
            handleChangeVal={handleChangeVal}
            setcount={setcount}
            setfilterVal={setfilterVal}
            filterVal={filterVal}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "6px",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <Box
              onClick={() => {
                setselectedFilter("All");
              }}
              className={
                selectedFilter === "All" ? customTabSelected : customTab
              }
            >
              <Typography
                sx={{
                  color: selectedFilter === "All" ? "white" : "#546e7a",
                  fontWeight: "bold",
                }}
              >
                All
              </Typography>
            </Box>
            {defaultpipline?.LeadPipelineStages?.map((pipline, i) => {
              return (
                <Box
                  key={i}
                  onClick={() => {
                    setselectedFilter(pipline);
                  }}
                  className={
                    selectedFilter?.Name === pipline.Name &&
                    selectedFilter?.Id === pipline.Id
                      ? customTabSelected
                      : customTab
                  }
                >
                  <Typography
                    sx={{
                      color:
                        selectedFilter?.Name === pipline.Name &&
                        selectedFilter?.Id === pipline.Id
                          ? "white"
                          : "#546e7a",
                      fontWeight: "bold",
                    }}
                  >
                    {pipline?.Name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          className={datGridBox}
          sx={{
            height: "calc(100vh - 160px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: "white !important",
          }}
        >
          <DataGrid
            rows={allLeads}
            columns={columns}
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
          />
          {status === "loading" ? <Loader /> : null}
        </Box>
      </Box>

      <AppConfirmationDialog
        open={showRecoverConfirmation}
        onDeny={() => {
          setselectedId(null);
          setshowRecoverConfirmation(false);
        }}
        onConfirm={() => {
          recoverLeads();
        }}
        iconContainerColor="#ebf9f9"
        icon={
          <LoopIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.primary?.main }}
          />
        }
        dialogTitle="Recover Lead"
        title={`Are you sure, you want to Recover Lead?`}
        successText="Yes"
        successBtnColor="primary"
        cancelText="No"
        loading={loading}
      />

      <AppConfirmationDialog
        open={showDeleteConfirmation}
        onDeny={() => {
          setselectedId(null);
          setshowDeleteConfirmation(false);
        }}
        onConfirm={() => {
          deleteLeads();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Lead"
        title={`Are you sure, you want to Delete Lead?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />
    </>
  );
}

export default RecycleLeadsTable;
RecycleLeadsTable.propTypes = {
  defaultpipline: PropTypes.any,
  roles: PropTypes.array,
};

const useStyles = makeStyles((theme) => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    customTabSelected: {
      padding: "5px 12px 5px 12px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
      alignItems: "center",
      borderRadius: "19px",
    },
    customTab: {
      padding: "5px 10px 1px 10px",
      borderRadius: "20px",
      cursor: "pointer",
      alignItems: "center",
    },
    datGridBox: {
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 200px) !important",
      },
    },
  };
});
