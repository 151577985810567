import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import theme from "../../../theme";
import { useEffect } from "react";
import { WonPiplineStageApi } from "../../../Api/LeadsApi/WonPiplineStageApi";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLeadByPiplineStage,
  fetchLeadWonPopulate,
  fetchLeadsSingle,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import moment from "moment";
import AppTooltip from "../../../Utils/AppTooltip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReactSelect from "react-select";
import { ReactSelectTheme } from "../../../Utils/ReactSelectTheme";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DiscountDeatilModal from "./DiscountDeatilModal";
// import Cookies from "universal-cookie";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "950px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
function WonModal({
  showModal2,
  setshowModal2,
  wonPiplines,
  singleLead,
  defaultpipline,
}) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [units, setUnits] = useState([]);
  const [formErrors, setFormErrors] = useState(null);
  // const [errors, setErrors] = useState(false);
  const { LeadWonPopulate } = useSelector(getLeadsDetails);

  const [options, setOptions] = useState([
    { value: "Partial Down Payment", label: "Partial Down Payment" },
    { value: "Complete Down Payment", label: "Complete Down Payment" },
    { value: "Payment", label: "Complete Payment" },
  ]);
  const [paymentDetail, setPaymentDetail] = useState([
    {
      unit: null,
      paymentType: "",
      receivedAmount: 0,
      discountAmount: 0,
      netAmount: 0,
      remainingAmount: 0,
      remainingAmountDueDate: "",
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [discount, setDiscount] = useState(false);

  console.log("paymentDetail", paymentDetail);
  // const cookies = new Cookies();
  // let businessType = cookies.get("BusinessType");
  let businessType = sessionStorage.getItem("BusinessType");
  const handleQuoteChange = (event) => {
    setSelectedQuote(event.target.value);
  };
  const initialValues = {
    wonValue: "",
    closedDate: "",
  };

  const validationSchema = Yup.object().shape({
    ...(businessType !== "RealState" && {
      wonValue: Yup.string().required("Please Enter Amount"),
    }),
    closedDate: Yup.string().required("Please Enter Date"),
  });

  const handleSave = async (values, setSubmitting, resetForm) => {
    if (!selectedQuote && businessType !== "RealState") {
      setsnackBarData({
        type: "error",
        message: "Please Add/Select Quote",
        openToast: true,
      });
      setSubmitting(false);

      return;
    } else {
      let error = false;
      console.log("paymentDetail2", paymentDetail);
      paymentDetail?.map((item, i) => {
        if (item.unit == null) {
          setFormErrors({ ...formErrors, [`unit${i}`]: true });
          setSubmitting(false);
          error = true;
          return;
        } else if (item.paymentType == "") {
          setFormErrors({ ...formErrors, [`paymentType${i}`]: true });
          setSubmitting(false);
          error = true;
          return;
        }
      });
      console.log("error", !error, !formErrors);
      if (Object.keys(formErrors).length === 0 && !error) {
        let arr = [];
        let total = 0;
        paymentDetail.map((item) => {
          total += Number(item?.unit?.price);
          arr.push({
            unitId: item?.unit?.value,
            paymentType: item?.paymentType?.value,
            receivedAmount: item?.receivedAmount,
            discountAmount: item?.discountAmount,
            remainingAmount: item?.remainingAmount,
            remainingAmountDueDate: item.remainingAmountDueDate || null,
            netAmount: item?.netAmount,
          });
        });
        const obj = {
          ...values,
          wonValue: values?.wonValue || total,
          pipelineStageId: wonPiplines?.Id,
          qouteId: selectedQuote,
          unitDetails: arr,
        };
        const response = await WonPiplineStageApi({
          obj: obj,
          id: singleLead?.Id,
        });
        if (response?.data?.IsSuccess) {
          setsnackBarData(response.snackBarData);
          resetForm();
          setSubmitting(false);
          dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
          dispatch(fetchLeadByPiplineStage(defaultpipline?.Id));
          setshowModal2(false);
          setPaymentDetail([
            {
              unit: null,
              paymentType: "",
              receivedAmount: 0,
              discountAmount: 0,
              netAmount: 0,
              remainingAmount: 0,
              remainingAmountDueDate: "",
            },
          ]);
          // setErrors(false);
          setFormErrors(null);
        } else {
          setSubmitting(false);
          setsnackBarData({
            message: "Something Went Wrong!",
            openToast: true,
            type: "error",
          });
        }
      } else {
        setSubmitting(false);
        setsnackBarData({
          message:
            "Kindly ensure all mandatory fields are completed or address any highlighted errors.",
          openToast: true,
          type: "error",
        });
      }
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (wonPiplines) {
      formik?.setFieldValue("wonValue", wonPiplines?.Probability);
    }
  }, [wonPiplines]);
  useEffect(() => {
    if (showModal2 === true && singleLead)
      dispatch(fetchLeadWonPopulate(singleLead?.Id));
  }, [showModal2, singleLead]);
  useEffect(() => {
    let arr = [];
    console.log("firstfirstfirstfirst", LeadWonPopulate);
    if (LeadWonPopulate) {
      LeadWonPopulate.forEach((item) => {
        arr.push({
          value: item?.Unit?.UnitId,
          label: item?.Unit?.UnitName,
          price: item?.Unit?.Price,
          section: item?.Section,
        });
      });
      setUnits(arr);
    }
    if (
      LeadWonPopulate?.Section?.DownPaymentPer > 0 &&
      LeadWonPopulate?.Section?.MinDownPaymentPer > 0
    ) {
      setOptions([
        { value: "Partial Down Payment", label: "Partial Down Payment" },
        { value: "Complete Down Payment", label: "Complete Down Payment" },
        { value: "Payment", label: "Complete Payment" },
      ]);
    }
  }, [LeadWonPopulate, LeadWonPopulate?.Section]);
  const handleAddUnits = () => {
    setPaymentDetail([
      ...paymentDetail,
      {
        unit: null,
        paymentType: "",
        receivedAmount: 0,
        discountAmount: 0,
        netAmount: 0,
        remainingAmount: 0,
        remainingAmountDueDate: "",
      },
    ]);
  };
  useEffect(() => {
    paymentDetail?.map((item, i) => {
      if (item?.unit !== null) {
        // setFormErrors({ ...formErrors, [`unit${i}`]: false });
        const newData = { ...formErrors };

        if (newData) {
          delete newData[`unit${i}`];
          setFormErrors(newData);
        }
      }
      if (item?.paymentType !== "" && item?.paymentType !== null) {
        // setFormErrors({ ...formErrors, [`paymentType${i}`]: false });
        const newData = { ...formErrors };

        if (newData) {
          delete newData[`paymentType${i}`];
          setFormErrors(newData);
        }
      }
    });
  }, [paymentDetail]);

  return (
    <>
      <Modal
        open={showModal2}
        onClose={() => {
          setshowModal2(false);
          setSelectedQuote(null);
          setPaymentDetail([
            {
              unit: null,
              paymentType: "",
              receivedAmount: 0,
              discountAmount: 0,
              netAmount: 0,
              remainingAmount: 0,
              remainingAmountDueDate: "",
            },
          ]);
          // setErrors(false);
          setFormErrors(null);
          formik.setValues(initialValues);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Change Stage
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setshowModal2(false);
                  setSelectedQuote(null);
                  formik.setValues(initialValues);

                  setPaymentDetail([
                    {
                      unit: null,
                      paymentType: "",
                      receivedAmount: 0,
                      discountAmount: 0,
                      netAmount: 0,
                      remainingAmount: 0,
                      remainingAmountDueDate: "",
                    },
                  ]);
                  // setErrors(false);
                  setFormErrors(null);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      {businessType !== "RealState" && (
                        <FormControl fullWidth>
                          <Typography>Won Value</Typography>
                          <TextField
                            sx={{
                              textAlign: "left",
                              input: { color: "black" },
                            }}
                            fullWidth
                            size="small"
                            name="wonValue"
                            type="number"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.wonValue}
                            variant="outlined"
                            error={
                              Boolean(formik.touched.wonValue) &&
                              Boolean(formik.errors.wonValue)
                            }
                            helperText={
                              Boolean(formik.touched.wonValue) &&
                              formik.errors.wonValue
                            }
                          />
                        </FormControl>
                      )}
                      <FormControl fullWidth>
                        <Typography>Close Date</Typography>
                        <TextField
                          sx={{ textAlign: "left", input: { color: "black" } }}
                          fullWidth
                          size="small"
                          name="closedDate"
                          type="date"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.closedDate}
                          variant="outlined"
                          error={
                            Boolean(formik.touched.closedDate) &&
                            Boolean(formik.errors.closedDate)
                          }
                          helperText={
                            Boolean(formik.touched.closedDate) &&
                            formik.errors.closedDate
                          }
                        />
                      </FormControl>
                    </Box>

                    {businessType === "RealState" ? (
                      <>
                        <Typography
                          variant="h1"
                          sx={{ fontSize: "15px !important" }}
                        >
                          Payment Details:
                        </Typography>
                        <Box sx={{ maxHeight: 480, overflowY: "auto" }}>
                          {paymentDetail &&
                            paymentDetail?.map((item, i) => {
                              return (
                                <Box
                                  key={i}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "15px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <AppTooltip title="Remove">
                                      <IconButton
                                        disabled={paymentDetail?.length == 1}
                                        onClick={() => {
                                          let arr = [...paymentDetail];

                                          arr?.splice(i, 1);
                                          setPaymentDetail(arr);
                                        }}
                                      >
                                        <DeleteForeverIcon
                                          sx={{
                                            color: (theme) =>
                                              theme?.palette?.primary?.delete,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </IconButton>
                                    </AppTooltip>
                                  </Box>
                                  <Box sx={{ display: "flex", gap: "10px" }}>
                                    <FormControl
                                      fullWidth
                                      error={
                                        formErrors && formErrors[`unit${i}`]
                                      }
                                    >
                                      <Typography>Units</Typography>
                                      <ReactSelect
                                        name="units"
                                        options={units.filter(
                                          (unit) =>
                                            !paymentDetail.some(
                                              (detail) =>
                                                detail?.unit?.value ===
                                                unit?.value
                                            )
                                        )}
                                        value={item?.unit}
                                        onChange={(val) => {
                                          let value = JSON.parse(
                                            JSON.stringify(paymentDetail || [])
                                          );
                                          value[i].unit = val;
                                          value[i].receivedAmount = 0;
                                          value[i].discountAmount = 0;
                                          value[i].remainingAmount = 0;
                                          setPaymentDetail(value);
                                        }}
                                        styles={ReactSelectTheme(false)}
                                        placeholder="Select Type"
                                      />
                                      <FormHelperText>
                                        {formErrors &&
                                          formErrors[`unit${i}`] &&
                                          "Please Select Units"}
                                      </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                      fullWidth
                                      error={
                                        formErrors &&
                                        formErrors[`paymentType${i}`]
                                      }
                                    >
                                      <Typography>Payment Type</Typography>
                                      <ReactSelect
                                        isDisabled={item?.unit ? false : true}
                                        name="paymentType"
                                        options={
                                          item?.unit?.section
                                            ?.MinDownPaymentPer &&
                                          item?.unit?.section?.DownPaymentPer
                                            ? options
                                            : [
                                                {
                                                  label: "Complete Payment",
                                                  value: "Payment",
                                                },
                                              ]
                                        }
                                        value={item?.paymentType}
                                        onChange={(val) => {
                                          let value = JSON.parse(
                                            JSON.stringify(paymentDetail || [])
                                          );

                                          value[i].paymentType = val;
                                          if (
                                            val?.value ===
                                            "Complete Down Payment"
                                          ) {
                                            value[i].receivedAmount =
                                              Math.round(
                                                (item?.unit?.price *
                                                  item?.unit?.section
                                                    ?.DownPaymentPer) /
                                                  100
                                              );
                                            value[i].remainingAmount = 0;
                                            value[i].discountAmount = 0;
                                            value[i].discountPer = 0;

                                            value[i].netAmount = Math.round(
                                              (item?.unit?.price *
                                                item?.unit?.section
                                                  ?.DownPaymentPer) /
                                                100
                                            );
                                          } else if (val?.value === "Payment") {
                                            value[i].receivedAmount = 0;
                                            value[i].discountAmount = 0;
                                            value[i].remainingAmount = 0;
                                            value[i].netAmount = 0;
                                          } else {
                                            value[i].receivedAmount = 0;
                                            value[i].discountAmount = 0;
                                            value[i].remainingAmount = 0;
                                            value[i].netAmount = 0;
                                          }
                                          setFormErrors(null);
                                          setPaymentDetail(value);
                                        }}
                                        styles={ReactSelectTheme(false)}
                                        placeholder="Select Type"
                                      />
                                      <FormHelperText>
                                        {formErrors &&
                                          formErrors[`paymentType${i}`] &&
                                          "Please Select Payment Type"}
                                      </FormHelperText>
                                    </FormControl>

                                    <FormControl fullWidth>
                                      <Typography>Due Date</Typography>
                                      <TextField
                                        name="dueDate"
                                        size={"small"}
                                        type={"date"}
                                        value={item?.remainingAmountDueDate}
                                        fullWidth
                                        onChange={(e) => {
                                          let value = JSON.parse(
                                            JSON.stringify(paymentDetail)
                                          );
                                          value[i].remainingAmountDueDate =
                                            e.target.value;
                                          setPaymentDetail(value);
                                        }}
                                      />
                                      {/* {formErrors && formErrors[`price${i}`] ? (
                                      <Typography
                                        color={"error"}
                                        sx={{ fontSize: "12px" }}
                                      >
                                        Please Enter Price
                                      </Typography>
                                    ) : null} */}
                                    </FormControl>
                                  </Box>

                                  <Box sx={{ display: "flex", gap: "10px" }}>
                                    <FormControl
                                      fullWidth
                                      error={
                                        formErrors &&
                                        formErrors[`receivedAmount${i}`]?.error
                                      }
                                    >
                                      <Typography>Amount</Typography>
                                      <TextField
                                        error={
                                          formErrors &&
                                          formErrors[`receivedAmount${i}`]
                                            ?.error
                                        }
                                        disabled={
                                          item?.unit?.price ? false : true
                                        }
                                        name="receivedAmount"
                                        size={"small"}
                                        type={"number"}
                                        value={item?.receivedAmount}
                                        fullWidth
                                        onChange={(e) => {
                                          let value = JSON.parse(
                                            JSON.stringify(paymentDetail)
                                          );
                                          value[i].receivedAmount = Number(
                                            e.target.value
                                          );
                                          value[i].netAmount = Number(
                                            e.target.value
                                          );
                                          setPaymentDetail(value);

                                          let percentage =
                                            (Number(e.target.value) /
                                              item.unit.price) *
                                            100;

                                          if (
                                            item?.paymentType?.value ===
                                            "Payment"
                                          ) {
                                            if (
                                              item?.unit?.section
                                                ?.SectionDiscounts &&
                                              item?.unit?.section
                                                ?.SectionDiscounts.length > 0
                                            ) {
                                              let maxDiscount = 0;
                                              // if (percentage < 100) {
                                              //   console.error(
                                              //     `Amount Can't be less than ${item?.unit?.price}`
                                              //   );
                                              //   setFormErrors({
                                              //     ...formErrors,
                                              //     [`receivedAmount${i}`]: {
                                              //       error: true,
                                              //       msg: `Amount Can't be greater than ${item?.unit?.price?.toLocaleString()}`,
                                              //     },
                                              //   });
                                              //   // setErrors(true);
                                              //   return;
                                              // } else
                                              if (
                                                item.unit.section
                                                  .DownPaymentPer > percentage
                                              ) {
                                                console.error(
                                                  `Amount must be be greater than ${Math.round(
                                                    (item?.unit?.price *
                                                      item?.unit?.section
                                                        ?.DownPaymentPer) /
                                                      100
                                                  )?.toLocaleString()}`
                                                );
                                                setFormErrors({
                                                  ...formErrors,
                                                  [`receivedAmount${i}`]: {
                                                    error: true,
                                                    msg: `Amount must be be greater than ${Math.round(
                                                      (item?.unit?.price *
                                                        item?.unit?.section
                                                          ?.DownPaymentPer) /
                                                        100
                                                    )?.toLocaleString()}`,
                                                  },
                                                });
                                                // setErrors(true);
                                                return;
                                              } else {
                                                const newData = {
                                                  ...formErrors,
                                                };

                                                if (newData) {
                                                  delete newData[
                                                    `receivedAmount${i}`
                                                  ];
                                                  setFormErrors(newData);
                                                }
                                              }
                                              for (const discount of item.unit
                                                .section.SectionDiscounts) {
                                                if (
                                                  percentage >=
                                                    discount.PaidPer &&
                                                  discount.DiscountPer >=
                                                    maxDiscount
                                                ) {
                                                  maxDiscount =
                                                    discount.DiscountPer;
                                                }
                                              }
                                              value[i].maxDiscount =
                                                maxDiscount;

                                              value[i].discountPer = 0;
                                              value[i].discountAmount = 0;

                                              value[i].remainingAmount =
                                                Math.round(
                                                  item.unit.price -
                                                    Number(e.target.value)
                                                );
                                            } else {
                                              value[i].remainingAmount =
                                                Math.round(
                                                  item.unit.price -
                                                    Number(e.target.value)
                                                );
                                            }
                                          } else if (
                                            item?.paymentType?.value ===
                                            "Partial Down Payment"
                                          ) {
                                            if (
                                              percentage >
                                              item.unit.section.DownPaymentPer
                                            ) {
                                              console.error(
                                                `Amount must be be less than ${Math.round(
                                                  (item?.unit?.price *
                                                    item?.unit?.section
                                                      ?.DownPaymentPer) /
                                                    100
                                                )?.toLocaleString()}`
                                              );
                                              setFormErrors({
                                                ...formErrors,
                                                [`receivedAmount${i}`]: {
                                                  error: true,
                                                  msg: `Amount must be be less than ${Math.round(
                                                    (item?.unit?.price *
                                                      item?.unit?.section
                                                        ?.DownPaymentPer) /
                                                      100
                                                  )?.toLocaleString()}`,
                                                },
                                              });
                                              // setErrors(true);
                                              return;
                                            } else if (
                                              percentage <
                                              item.unit.section
                                                .MinDownPaymentPer
                                            ) {
                                              console.error(
                                                `Amount must be be greater than ${Math.round(
                                                  (item?.unit?.price *
                                                    item?.unit?.section
                                                      ?.MinDownPaymentPer) /
                                                    100
                                                )?.toLocaleString()}`
                                              );
                                              setFormErrors({
                                                ...formErrors,
                                                [`receivedAmount${i}`]: {
                                                  error: true,
                                                  msg: `Amount must be be greater than ${Math.round(
                                                    (item?.unit?.price *
                                                      item?.unit?.section
                                                        ?.MinDownPaymentPer) /
                                                      100
                                                  )?.toLocaleString()}`,
                                                },
                                              });
                                              // setErrors(true);
                                              return;
                                            } else {
                                              const newData = { ...formErrors };

                                              if (newData) {
                                                delete newData[
                                                  `receivedAmount${i}`
                                                ];
                                                setFormErrors(newData);
                                              }
                                            }
                                            value[i].remainingAmount =
                                              Math.round(
                                                (item?.unit?.price *
                                                  item?.unit?.section
                                                    ?.DownPaymentPer) /
                                                  100 -
                                                  Number(e.target.value)
                                              );
                                            value[i].netAmount = Number(
                                              e.target.value
                                            );
                                          } else {
                                            if (
                                              item?.unit?.section
                                                ?.DownPaymentPer &&
                                              Number(e.target.value) !==
                                                Math.round(
                                                  (item?.unit?.price *
                                                    item?.unit?.section
                                                      ?.DownPaymentPer) /
                                                    100
                                                )
                                            ) {
                                              console.error(
                                                `Amount must be equal to ${Math.round(
                                                  (item?.unit?.price *
                                                    item?.unit?.section
                                                      ?.DownPaymentPer) /
                                                    100
                                                )}`
                                              );
                                              setFormErrors({
                                                ...formErrors,
                                                [`receivedAmount${i}`]: {
                                                  error: true,
                                                  msg: `Amount must be equal to ${Math.round(
                                                    (item?.unit?.price *
                                                      item?.unit?.section
                                                        ?.DownPaymentPer) /
                                                      100
                                                  )?.toLocaleString()}`,
                                                },
                                              });
                                              // setErrors(true);
                                              return;
                                            } else {
                                              const newData = { ...formErrors };

                                              if (newData) {
                                                delete newData[
                                                  `receivedAmount${i}`
                                                ];
                                                setFormErrors(newData);
                                              }
                                            }
                                            value[i].remainingAmount =
                                              Math.round(
                                                (item?.unit?.price *
                                                  item?.unit?.section
                                                    ?.DownPaymentPer) /
                                                  100 -
                                                  Number(e.target.value)
                                              );
                                            value[i].netAmount = Number(
                                              e.target.value
                                            );
                                          }
                                          setPaymentDetail(value);
                                        }}
                                      />
                                      {/* {formErrors && formErrors[`price${i}`] ? (
                                      <Typography
                                        color={"error"}
                                        sx={{ fontSize: "12px" }}
                                      >
                                        Please Enter Price
                                      </Typography>
                                    ) : null} */}
                                      <FormHelperText>
                                        {formErrors &&
                                          formErrors[`receivedAmount${i}`]
                                            ?.error &&
                                          formErrors[`receivedAmount${i}`]?.msg}
                                      </FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth>
                                      <Typography>Remaining Amount</Typography>
                                      <TextField
                                        name="remainingAmount"
                                        disabled
                                        type={"number"}
                                        size={"small"}
                                        value={item?.remainingAmount}
                                        fullWidth
                                        onChange={(e) => {
                                          let value = JSON.parse(
                                            JSON.stringify(paymentDetail)
                                          );
                                          value[i].remainingAmount = Number(
                                            e.target.value
                                          );
                                          setPaymentDetail(value);
                                        }}

                                        // error={Boolean(formik.errors.item) && Boolean(formik.touched.item)}
                                        // helperText={Boolean(formik.errors.item) && formik.errors.item}
                                      />
                                      {/* {formErrors &&
                                    formErrors[`quantity${i}`] ? (
                                      <Typography
                                        color={"error"}
                                        sx={{ fontSize: "12px" }}
                                      >
                                        Please Enter Price
                                      </Typography>
                                    ) : null} */}
                                    </FormControl>
                                    <FormControl fullWidth>
                                      <Typography>Net Amount</Typography>
                                      <TextField
                                        name="netAmount"
                                        disabled
                                        type={"number"}
                                        size={"small"}
                                        value={item?.netAmount}
                                        fullWidth
                                      />
                                    </FormControl>
                                  </Box>
                                  {item?.unit?.section?.SectionDiscounts
                                    ?.length > 0 && (
                                    <Box sx={{ display: "flex", gap: "10px" }}>
                                      <FormControl
                                        fullWidth
                                        error={
                                          formErrors &&
                                          formErrors[`discountPer${i}`]?.error
                                        }
                                      >
                                        <Typography>
                                          Discount %
                                          {
                                            <AppTooltip title="Discount Detail">
                                              <IconButton
                                                onClick={() => {
                                                  setShowModal(true);
                                                  setDiscount(
                                                    item?.unit?.section
                                                      ?.SectionDiscounts
                                                  );
                                                }}
                                                disabled={
                                                  !item?.unit?.section
                                                    ?.SectionDiscounts ||
                                                  item?.unit?.section
                                                    ?.SectionDiscounts
                                                    .length === 0
                                                }
                                              >
                                                <InfoOutlinedIcon
                                                  fontSize="small"
                                                  sx={{
                                                    color: (theme) =>
                                                      theme?.palette?.primary
                                                        ?.main,
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </IconButton>
                                            </AppTooltip>
                                          }
                                        </Typography>
                                        <TextField
                                          error={
                                            formErrors &&
                                            formErrors[`discountPer${i}`]?.error
                                          }
                                          name="discountPer"
                                          disabled={
                                            (item.paymentType === "Payment" ||
                                              item.paymentType.value ===
                                                "Payment") &&
                                            item?.maxDiscount > 0
                                              ? false
                                              : true
                                          }
                                          type={"number"}
                                          size={"small"}
                                          value={item?.discountPer}
                                          fullWidth
                                          onChange={(e) => {
                                            let value = JSON.parse(
                                              JSON.stringify(paymentDetail)
                                            );

                                            value[i].discountPer = Number(
                                              e.target.value
                                            );
                                            if (
                                              Number(e.target.value) >
                                              Number(item?.maxDiscount)
                                            ) {
                                              console.error(
                                                `Max Discount is ${item?.maxDiscount}%`
                                              );
                                              setFormErrors({
                                                ...formErrors,
                                                [`discountPer${i}`]: {
                                                  error: true,
                                                  msg: `Max Discount is ${item?.maxDiscount}%`,
                                                },
                                              });
                                              // setErrors(true);
                                            } else if (
                                              Number(e.target.value) < 0
                                            ) {
                                              setFormErrors({
                                                ...formErrors,
                                                [`discountPer${i}`]: {
                                                  error: true,
                                                  msg: `Value Can't be less than 0`,
                                                },
                                              });
                                              return;
                                            } else {
                                              const newData = {
                                                ...formErrors,
                                              };

                                              if (newData) {
                                                delete newData[
                                                  `discountPer${i}`
                                                ];
                                                setFormErrors(newData);
                                              }

                                              value[i].discountAmount =
                                                Math.round(
                                                  (item.unit.price *
                                                    Number(e.target.value)) /
                                                    100
                                                );
                                              value[i].netAmount = Math.round(
                                                item?.receivedAmount -
                                                  (item.unit.price *
                                                    Number(e.target.value)) /
                                                    100
                                              );
                                            }
                                            setPaymentDetail(value);
                                          }}
                                        />
                                        <FormHelperText>
                                          {formErrors &&
                                            formErrors[`discountPer${i}`]
                                              ?.error &&
                                            formErrors[`discountPer${i}`]?.msg}
                                        </FormHelperText>
                                      </FormControl>
                                      <FormControl fullWidth>
                                        <Typography>Discount Amount</Typography>
                                        <TextField
                                          name="discountAmount"
                                          type={"number"}
                                          value={item?.discountAmount}
                                          size={"small"}
                                          disabled
                                          fullWidth
                                          sx={{ mt: 1 }}
                                          onChange={(e) => {
                                            let value = JSON.parse(
                                              JSON.stringify(paymentDetail)
                                            );
                                            value[i].discountAmount = Number(
                                              e.target.value
                                            );
                                            setPaymentDetail(value);
                                          }}

                                          // error={Boolean(formik.errors.item) && Boolean(formik.touched.item)}
                                          // helperText={Boolean(formik.errors.item) && formik.errors.item}
                                        />
                                      </FormControl>
                                    </Box>
                                  )}

                                  <Divider
                                    sx={{
                                      width: "80%",
                                      backgroundColor: (theme) =>
                                        theme.palette.primary.main,
                                      marginTop: "10px",
                                      marginInline: "auto",
                                    }}
                                  />
                                </Box>
                              );
                            })}
                          <Box sx={{ width: "fit-content" }}>
                            <Button
                              size="large"
                              color="info"
                              sx={{ borderRadius: "8px" }}
                              onClick={handleAddUnits}
                              disabled={paymentDetail.length === units.length}
                            >
                              + Add More
                            </Button>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <TableContainer sx={{}}>
                        <Table
                          sx={{
                            minWidth: { md: "auto", xs: "auto" },
                            overflow: "scroll",
                          }}
                          aria-label="simple table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow size="small">
                              <TableCell
                                size="small"
                                align="left"
                                sx={{
                                  fontWeight: "bold",
                                  // color: (theme) => theme.palette.grey["500"],
                                }}
                              >
                                Subject
                              </TableCell>
                              <TableCell
                                size="small"
                                align="left"
                                sx={{
                                  fontWeight: "bold",
                                  // color: (theme) => theme.palette.grey["500"],
                                }}
                              >
                                Expired At
                              </TableCell>
                              <TableCell
                                size="small"
                                align="left"
                                sx={{
                                  fontWeight: "bold",
                                  // color: (theme) => theme.palette.grey["500"],
                                }}
                              >
                                Sub Total ($)
                              </TableCell>
                              <TableCell
                                size="small"
                                align="left"
                                sx={{
                                  fontWeight: "bold",
                                  // color: (theme) => theme.palette.grey["500"],
                                }}
                              >
                                Discount ($)
                              </TableCell>
                              <TableCell
                                size="small"
                                align="left"
                                sx={{
                                  fontWeight: "bold",
                                  // color: (theme) => theme.palette.grey["500"],
                                }}
                              >
                                Tax ($)
                              </TableCell>
                              <TableCell
                                size="small"
                                align="left"
                                sx={{
                                  fontWeight: "bold",
                                  // color: (theme) => theme.palette.grey["500"],
                                }}
                              >
                                Adjustment ($)
                              </TableCell>
                              <TableCell
                                size="small"
                                align="left"
                                sx={{
                                  fontWeight: "bold",
                                  // color: (theme) => theme.palette.grey["500"],
                                }}
                              >
                                Grand Total ($)
                              </TableCell>
                              <TableCell
                                size="small"
                                align="left"
                                sx={{
                                  fontWeight: "bold",
                                  // color: (theme) => theme.palette.grey["500"],
                                }}
                              >
                                Select
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {singleLead?.leadQuote?.map((quote, i) => {
                              return (
                                <TableRow key={i} size="small">
                                  <TableCell size="small" align="left">
                                    {quote?.Subject}
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    {moment(quote?.ExpiredAt).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    {quote?.SubTotal}
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    {quote?.DiscountAmount}
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    {quote?.TaxAmount}
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    {quote?.AdjustmentAmount}
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    {quote?.GrandTotal}
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      name="radio-buttons-group"
                                      value={selectedQuote}
                                      onChange={handleQuoteChange}
                                    >
                                      <FormControlLabel
                                        value={quote?.Id}
                                        control={<Radio />}
                                      />
                                    </RadioGroup>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            {singleLead?.leadQuote?.length > 0 ? null : (
                              <TableRow
                                sx={{ width: "100%", textAlign: "center" }}
                              >
                                <Typography>No records Found</Typography>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button type="submit" variant="contained">
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {showModal && (
        <DiscountDeatilModal
          setShowModal={setShowModal}
          showModal={showModal}
          details={discount}
        />
      )}
    </>
  );
}

export default WonModal;
WonModal.propTypes = {
  showModal2: PropTypes.any,
  setshowModal2: PropTypes.any,
  wonPiplines: PropTypes.any,
  singleLead: PropTypes.any,
  defaultpipline: PropTypes.any,
};
