import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddRemarksLeadsApi } from "../../../Api/LeadsApi/LeadsRemarksApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import {
  fetchNotificationsbyUser,
} from "../../../Redux/Slice/Notifications/GetNotification";
import { useDispatch} from "react-redux";
function AddRemarksModal({
  EditRemark,
  leadId,
  showModal,
  handleClosed,
  selectedLeads,
  refreshLeadsPage,
  RowsUpdate,
}) {
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNotificationsbyUser());
  }, [dispatch]);
  const ValidationSchema = Yup.object().shape({
    remarks: Yup.string().required("Remarks field is required!"),
    status: Yup.string().required("Status field is required!"),
    priority: Yup.string().required("Priority field is required!"),
    title: Yup.string().required("Title field is required!"),
  });
 
  useEffect(() => {
    if (EditRemark) {
      formik.setValues({
        remarks: EditRemark?.Remarks,
        status: EditRemark?.Status,
        priority: EditRemark?.Priority,
        title: EditRemark?.Title,
        Id: EditRemark?.Id,
      });
    } else {
      formik.setValues({
        remarks: "",
        status: "Pending",
        priority: "",
        title: "",
        Id: "",
      });
    }
  }, [EditRemark]);

  const formik = useFormik({
    initialValues: {
      remarks: "",
      status: "Pending",
      priority: "",
      title: "",
      Id: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      await handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    setSubmitting(true);
 
    const leadArr = leadId ? [leadId] : selectedLeads.map((lead) => lead?.ID);
    const data = {
      remarks: values.remarks,
      status: values.status,
      priority: values.priority,
      title: values.title,
      leadId: leadArr,
      ...(EditRemark?.Id && { Id: EditRemark.Id }),
    };
    console.log(data);
    const response = await AddRemarksLeadsApi(data);

    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      setSubmitting(false);
      resetForm();
      handleClosed();
      refreshLeadsPage();
      RowsUpdate([]);
      dispatch(fetchNotificationsbyUser());
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      open={showModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            {EditRemark?.Id
              ? "Update Remark"
              : leadId
              ? "Add Remark "
              : "Add Remarks for Selected Leads"}
          </Typography>
          <IconButton onClick={handleClosed}>
            <HighlightOffIcon color="primary" sx={{ fontSize: "28px" }} />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ width: "600px" }}>
          <TextField
            fullWidth
            id="title"
            name="title"
            label="Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            margin="normal"
          />
          <TextField
            fullWidth
            id="remarks"
            name="remarks"
            label="Remarks"
            multiline
            rows={4}
            value={formik.values.remarks}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.remarks && Boolean(formik.errors.remarks)}
            helperText={formik.touched.remarks && formik.errors.remarks}
            margin="normal"
          />
          <FormControl
            fullWidth
            margin="normal"
            error={formik.touched.priority && Boolean(formik.errors.priority)}
          >
            <Typography>Priority</Typography>
            <Select
              id="priority"
              name="priority"
              value={formik.values.priority}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              displayEmpty
            >
              <MenuItem value="">Select Priority</MenuItem>
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
            </Select>
            {formik.touched.priority && formik.errors.priority ? (
              <FormHelperText sx={{ color: "#C80707" }}>
                {formik.errors.priority}
              </FormHelperText>
            ) : null}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            {formik.isSubmitting ? (
              <Box>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Button
                  sx={{
                    background: "#cc6666",
                    color: "white !important",
                    fontSize: "13px",
                    textTransform: "none",
                  }}
                  onClick={handleClosed}
                >
                  Cancel
                </Button>

                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: "white !important",
                    fontSize: "13px",
                    textTransform: "none",
                  }}
                  type="submit"
                  autoFocus
                >
                  {EditRemark?.Id ? "Update" : "Add"}
                </Button>
              </>
            )}
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}

AddRemarksModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  selectedLeads: PropTypes.any,
  refreshLeadsPage: PropTypes.any,
  EditRemark: PropTypes.any,
  leadId: PropTypes.any,
  RowsUpdate: PropTypes.any,
};

export default AddRemarksModal;
