import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";

function CustomerDetailModal({ open, onClose, title, data }) {
  const { Container, ModalContainer, TableHeader } = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className={ModalContainer}>
        <Box className={Container}>
          <Box className={TableHeader}>
            <Typography
              variant="h1"
              sx={{ fontSize: "18px !important" }}
            >
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Box>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              container
              spacing={2}
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
              >
                <Box sx={{ width: "200px", height: "200px" }}>
                  {data?.Image ? (
                    <img
                      src={data?.Image}
                      alt="Test Photo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                        borderRadius: "20px",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "20px",
                      }}
                      src={"./"}
                    />
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                xl={8}
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                    Name
                  </Typography>
                  <Typography>{data?.Name || "NA"}</Typography>
                </Grid>

                {sessionStorage.getItem("BusinessType") === "General" && (
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                      Organization Name
                    </Typography>
                    <Typography>{data?.OrganizationName || "NA"}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>City</Typography>
              <Typography>{data?.City || "NA"}</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                Created At
              </Typography>
              <Typography>
                {data?.CreatedAt
                  ? moment(data?.CreatedAt).format("Do MMM-YY hh:mm A")
                  : "NA"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                Contacts
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {data?.PersonContacts?.length
                  ? data?.PersonContacts?.map((item, idx) => (
                      <Chip
                        key={idx}
                        label={`${item?.ContactNumber} (${item?.Description})`}
                      />
                    ))
                  : "NA"}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>Emails</Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {data?.PersonEmails?.length
                  ? data?.PersonEmails?.map((item, idx) => (
                      <Chip
                        key={idx}
                        label={`${item?.Email} (${item?.Description})`}
                      />
                    ))
                  : "NA"}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>Tag</Typography>
              {data?.Tag ? (
                <Chip
                  label={data?.Tag?.Name}
                  sx={{
                    backgroundColor: data?.Tag?.Color,
                    color: "#fff",
                  }}
                />
              ) : (
                <Typography>NA</Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

export default CustomerDetailModal;
CustomerDetailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  data: PropTypes.any,
};

const useStyles = makeStyles((theme) => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "350px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "450px",
      },
      [theme.breakpoints.down("xl")]: {
        maxWidth: "600px",
      },
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});
