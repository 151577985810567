import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Template: null,
  SingleTemplate: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchTemplate = createAsyncThunk("/Template", async () => {
  const response = await axiosInstance.get(`Templates/getAllTemplates`);
  return response;
});
export const fetchSingleTemplate = createAsyncThunk(
  "/Template/{id}",
  async (id) => {
    const response = await axiosInstance.get(
      `/Templates/getById?TemplateId=${id}`
    );
    return response;
  }
);

const TemplateSlice = createSlice({
  name: "Template",
  initialState,
  reducers: {
    TemplateAdded: {
      reducer(state, action) {
        state.Template?.push(action.payload?.Template?.data?.Data);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchTemplate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTemplate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.Template = action.payload.data?.Data;
      })
      .addCase(fetchTemplate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchSingleTemplate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingleTemplate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.SingleTemplate = action.payload.data?.Data;
      })
      .addCase(fetchSingleTemplate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});
export const getTemplateDetails = (state) => state.Template;

export const { TemplateAdded } = TemplateSlice.actions;

export default TemplateSlice.reducer;
