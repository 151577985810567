import {
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  // InputBase,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { CreatePersonApi } from "../../../../Api/CreatePersonApi.jsx/CreatePersonApi";
import { UpdatePersonApi } from "../../../../Api/CreatePersonApi.jsx/UpdatePersonApi";
import {
  fetchOrganization,
  getOrganizationDetails,
} from "../../../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPersonsPopulate,
  fetchSinglePersons,
  getPersonsDetails,
} from "../../../../Redux/Slice/PersonSlice/PersonSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CancelIcon from "@mui/icons-material/Cancel";
// import Cookies from "universal-cookie";
import { City } from "country-state-city";
import Select from "react-select";
import { ReactSelectTheme } from "../../../../Utils/ReactSelectTheme";
import PropTypes from "prop-types";

const initialValues = {
  name: "",
  organization: "",
  organizationId: null,
  tag: null,
  // email: "",
};

// const validationObj = Yup.object({
//   contact: Yup.string().required("Title is required"),
//   desc: Yup.string().required("Description is required"),
// });

const CreatePersonForm = ({ screen }) => {
  const { container, subContainer, formContainer } = useStyle();

  const [validationObj] = useState({
    name: Yup.string().required("Please Enter  Name"),
    // city: Yup.object().required("Please Select City"),

    // organizationId: Yup.object().required("Please Enter Organization Name"),
    // email: Yup.string()
    //   .email("Please Enter A Valid Email Address")
    //   .required("Please Enter Email"),
  });
  const [errors, seterrors] = useState(null);
  const [allcities, setallcities] = useState([]);
  const [tags, setTags] = useState([]);
  const [contact, setcontact] = useState([
    { contactNumber: "", description: "" },
  ]);
  const [logo, setlogo] = useState(null);

  const [PersonEmails, setPersonEmails] = useState([
    { email: "", description: "" },
  ]);
  const [allOrganization, setallOrganization] = useState([]);
  // const [validate, setvalidate] = useState(false);

  // const cookies = new Cookies();
  // let businessType = cookies.get("BusinessType");
  let businessType = sessionStorage.getItem("BusinessType");

  const navigate = useNavigate();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);

  const dispatch = useDispatch();
  const { SinglePersons, PersonsPopulate } = useSelector(getPersonsDetails);
  const { Organization } = useSelector(getOrganizationDetails);

  useEffect(() => {
    if (state?.PersonId) {
      dispatch(fetchSinglePersons(state?.PersonId));
    }
  }, [state]);

  useEffect(() => {
    if (Organization) {
      setallOrganization(Organization);
    }
  }, [Organization]);

  useEffect(() => {
    if (Organization == null) {
      dispatch(fetchOrganization());
    }
    dispatch(fetchPersonsPopulate());
  }, [dispatch]);

  useEffect(() => {
    if (PersonsPopulate) {
      let arr = [];
      PersonsPopulate?.Tags?.map((tag) => {
        arr.push({ value: tag?.TagId, label: tag?.Name });
      });
      setTags(arr);
    }
  }, [PersonsPopulate]);

  useEffect(() => {
    if (state?.PersonId) {
      let arr = [];
      let arr2 = [];
      SinglePersons?.PersonContacts?.map((cntct) => {
        let obj = {
          contactNumber: cntct?.ContactNumber,
          description: cntct?.Description,
          personContactId: cntct?.PersonContactId,
        };
        arr.push(obj);
      });
      SinglePersons?.PersonEmails?.map((email) => {
        let obj2 = {
          email: email?.Email,
          description: email?.Description,
          personEmailId: email?.PersonEmailId,
        };
        arr2.push(obj2);
      });
      formik?.setFieldValue(
        "name",
        SinglePersons?.Name ? SinglePersons?.Name : ""
      );
      formik?.setFieldValue(
        "city",
        SinglePersons
          ? {
              label: SinglePersons?.City,
              value: SinglePersons?.City,
            }
          : null
      );
      formik?.setFieldValue(
        "organizationId",
        SinglePersons
          ? {
              Id: SinglePersons?.OrganizationId,
              Name: SinglePersons?.OrganizationName,
            }
          : null
      );
      setcontact(arr);
      setPersonEmails(arr2);
    } else if (state) {
      let arr = [];
      let arr2 = [];
      state?.allContact?.map((cntct) => {
        let obj = {
          contactNumber: cntct?.ContactNumber,
          description: cntct?.Description,
          personContactId: cntct?.PersonContactId,
        };
        arr.push(obj);
      });
      state?.allEmails?.map((email) => {
        let obj2 = {
          email: email?.Email,
          description: email?.Description,
          personEmailId: email?.PersonEmailId,
        };
        arr2.push(obj2);
      });
      handleEdit(state);
      setcontact(arr);
      setPersonEmails(arr2);
      if (state?.Image && state?.ImageName) {
        setlogo({
          url: state?.Image,
          file: state?.ImageName,
        });
      }
    }
  }, [state, SinglePersons]);

  useEffect(() => {
    PersonEmails?.map((item, i) => {
      if (
        !(
          PersonEmails[i].email?.length > 0 && PersonEmails[i].description == ""
        )
      ) {
        seterrors({ ...errors, [`description${i}`]: false });
        return;
      }
    });
  }, [PersonEmails]);

  useEffect(() => {
    contact?.map((item, i) => {
      if (!contact[i].contactNumber == "") {
        seterrors({ ...errors, [`contactNumber${i}`]: false });
        return;
      }
    });
    contact?.map((item, i) => {
      if (!contact[i].description == "") {
        seterrors({ ...errors, [`descriptionNo${i}`]: false });
        return;
      }
    });
  }, [contact]);

  useEffect(() => {
    let value = City.getCitiesOfCountry("PK");
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.name,
      };
      return obj;
    });
    setallcities(value);
  }, []);

  // useEffect(() => {
  //   contact.map((m, i) => {
  //     setvalidationObj({
  //       ...validationObj,
  //       [`contact${i}`]: Yup.string().required(
  //         "Please Enter Organization Name"
  //       ),
  //       [`desc${i}`]: Yup.string().required(
  //         "Enter Desc"
  //       ),
  //     });
  //   });
  //   console.log(validationObj);
  // }, [contact]);

  const handleAddContactField = () => {
    setcontact([...contact, { contactNumber: "", description: "" }]);
  };

  const handleAddEmailField = () => {
    setPersonEmails([...PersonEmails, { email: "", description: "" }]);
  };

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let emailValidation = false;
    let contactValidation = false;
    let contactValidationDesc = false;
    // let toastData = null;
    PersonEmails?.map((item, i) => {
      if (
        PersonEmails[i].email?.length > 0 &&
        PersonEmails[i].description == ""
      ) {
        emailValidation = true;

        seterrors({ ...errors, [`description${i}`]: true });
        return;
      }
    });
    contact?.map((item, i) => {
      if (contact[i].contactNumber == "") {
        contactValidation = true;
        seterrors({ ...errors, [`contactNumber${i}`]: true });
        return;
      } else if (contact[i].description == "") {
        contactValidationDesc = true;
        seterrors({ ...errors, [`descriptionNo${i}`]: true });
        return;
      }
    });
    if (emailValidation || contactValidation || contactValidationDesc) {
      setSubmitting(false);
      return;
    }
    // PersonEmails?.find((item) => {
    //   if (item.email === "") {
    //     abc = true;
    //     toastData = {
    //       type: "error",
    //       message: "Please Enter Email",
    //       openToast: true,
    //     };
    //     return;
    //   }
    //   else if (item?.description === "") {
    //     abc = true;
    //     toastData = {
    //       type: "error",
    //       message: "Please Enter Description",
    //       openToast: true,
    //     };
    //     return;
    //   }
    // });
    // if (abc) {
    //   setSubmitting(false);
    //   setvalidate(true);
    //   setsnackBarData(toastData);
    //   return;
    // }else {
    //   setvalidate(false);

    // }
    let formData = new FormData();
    formData.append("name", values.name);
    if (screen === "Customer") {
      formData.append("Type", "Person");
    } else {
      formData.append("Type", "Affiliate");
    }

    if (values?.city?.value) {
      formData.append("City", values?.city?.value);
    }

    if (values?.tag?.value) {
      formData.append("TagId", values?.tag?.value);
    }

    if (values.organizationId?.Id) {
      formData.append("organizationId", Number(values.organizationId?.Id));
    }
    // formData.append("personContacts", JSON.stringify(contact));
    // formData.append("personEmails", JSON.stringify(PersonEmails));
    formData.append("Image", logo ? logo?.file : null);
    for (let i = 0; i < PersonEmails?.length; i++) {
      if (PersonEmails[i].email && PersonEmails[i].description) {
        formData.append(`personEmails[${i}].email`, PersonEmails[i].email);
        formData.append(
          `personEmails[${i}].description`,
          PersonEmails[i].description
        );
      }
    }
    for (let i = 0; i < contact?.length; i++) {
      formData.append(
        `personContacts[${i}].contactNumber`,
        contact[i].contactNumber
      );
      formData.append(
        `personContacts[${i}].description`,
        contact[i].description
      );
      formData.append(`personContacts[${i}].dialCode`, contact[i].dialCode);
    }
    // formData.append("ImageName", null);
    // PersonEmails?.map((item, i) => {
    //   formData.append(`email[${i}]`, item?.email);
    //   formData.append(`description[${i}]`, item?.description);
    // });
    // contact?.map((item, i) => {
    //   formData.append(`contactNumber[${i}]`, item?.contactNumber);
    //   formData.append(`description[${i}]`, item?.description);
    // });

    if (state) {
      formData.append("id", state?.PersonId ? state?.PersonId : state?.ID);
      if (logo) {
        formData.append("ImageName", state?.ImageName);
      }

      const response = await UpdatePersonApi(formData);
      // {
      //   ...obj,
      //   id: state?.PersonId ? state?.PersonId : state?.ID,
      // }
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        // dispatch(fetchProducts());
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
    } else {
      const response = await CreatePersonApi(formData);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
    }
    setSubmitting(false);
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  const ValidationSchema = Yup.object().shape(validationObj);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleDel = (contct, i) => {
    let value = JSON.parse(JSON.stringify(contact));
    value.splice(i, 1);

    // let value = contact.filter((cont)=>cont.id!==contct.id);

    setcontact(value);
  };

  const handleDelEmail = (email, i) => {
    let value = JSON.parse(JSON.stringify(PersonEmails));
    value.splice(i, 1);

    // let value = contact.filter((cont)=>cont.id!==contct.id);

    setPersonEmails(value);
  };

  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
    formik.handleChange({
      target: {
        name: "organizationId",
        value: state?.organizationID
          ? { Id: state?.organizationID, Name: state?.organization }
          : null,
      },
    });
    formik.handleChange({
      target: {
        name: "city",
        value: state?.city ? { label: state?.city, value: state?.city } : null,
      },
    });
    formik.handleChange({
      target: {
        name: "tag",
        value: state?.Tag
          ? { label: state?.Tag?.Name, value: state?.Tag?.TagId }
          : null,
      },
    });
  };

  const handleLogo = (e) => {
    setlogo({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              {screen === "Customer" ? (
                <Link
                  to="/contacts/persons"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Customers
                </Link>
              ) : (
                <Link
                  to="/contacts/affiliate"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Affiliates
                </Link>
              )}
              {screen === "Customer" ? (
                <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                  {state ? "Update Customer" : "Create Customer"}
                </Typography>
              ) : (
                <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                  {state ? "Update Affiliate" : "Create Affiliate"}
                </Typography>
              )}
            </Breadcrumbs>
          </Box>

          {screen === "Customer" ? (
            <Typography
              variant="h5"
              sx={{ textAlign: "left", fontWeight: 400 }}
            >
              {state ? "Update Customer" : "Create Customer"}
            </Typography>
          ) : (
            <Typography
              variant="h5"
              sx={{ textAlign: "left", fontWeight: 400 }}
            >
              {state ? "Update Affiliate" : "Create Affiliate"}
            </Typography>
          )}
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#f0f9fb",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100px",
                    height: "100px",
                    // height: "100%",
                    position: "relative",
                  }}
                >
                  {logo ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "100px",
                        // borderRadius: "5px",
                        // border: "1px solid rgb(214, 214, 214)",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer !important",
                        justifyContent: "center",
                        position: "relative",
                        borderRadius: "50%",
                        // padding: "10px",
                      }}
                    >
                      <img
                        src={logo?.url}
                        alt="Image"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      />
                      <CancelIcon
                        color="error"
                        onClick={() => {
                          setlogo(null);
                        }}
                        sx={{
                          position: "absolute",
                          top: "-2px",
                          right: "-2px",
                          fontSize: "16px",
                        }}
                      />
                    </Box>
                  ) : (
                    <Avatar sx={{ width: "100%", height: "100%" }} src={"./"} />
                  )}
                </Box>
                <Box sx={{ position: "relative", cursor: "pointer" }}>
                  <Typography
                    // onClick={handleLogo}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Upload Image
                  </Typography>{" "}
                  <input
                    type="file"
                    accept="image/*"
                    name="product_images"
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      top: "0px",
                      opacity: 0,
                    }}
                    onChange={handleLogo}
                  />
                </Box>
              </Box>

              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Name</Typography>
                  <TextField
                    name="name"
                    value={formik.values?.name}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name)
                    }
                    helperText={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name) &&
                      formik.errors.name
                    }
                  />
                </FormControl>
                {screen !== "Customer" && (
                  <FormControl fullWidth style={{ display: "none" }}>
                    <Typography>Organization</Typography>
                    <TextField
                      name="organization"
                      value={formik.values?.organization}
                      size={"small"}
                      fullWidth
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.organization) &&
                        Boolean(formik.touched.organization)
                      }
                      helperText={
                        Boolean(formik.errors.organization) &&
                        Boolean(formik.touched.organization) &&
                        formik.errors.organization
                      }
                    />
                  </FormControl>
                )}
              </Box>
              <Box display="flex" gap={1}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.city) && Boolean(formik.touched.city)
                  }
                >
                  <Typography>City</Typography>
                  <Select
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.city) &&
                    //       Boolean(formik.touched.city)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.city) &&
                        Boolean(formik.touched.city)
                    )}
                    name="city"
                    options={allcities}
                    value={formik.values.city}
                    onChange={(value) => {
                      formik.setFieldValue("city", value);
                    }}
                    placeholder="Select City"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.city) && Boolean(formik.touched.city)
                      ? formik.errors.city
                      : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.tag) && Boolean(formik.touched.tag)
                  }
                >
                  <Typography>Tags</Typography>
                  <Select
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.tag) &&
                    //       Boolean(formik.touched.tag)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.tag) && Boolean(formik.touched.tag)
                    )}
                    name="tag"
                    options={tags}
                    value={formik.values.tag}
                    onChange={(value) => {
                      formik.setFieldValue("tag", value);
                    }}
                    placeholder="Select Tags"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.tag) && Boolean(formik.touched.tag)
                      ? formik.errors.tag
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography>Contact Number</Typography>
                {contact?.map((contct, i) => {
                  return (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          // border: "1px solid rgba(200,200,200,0.9)",
                          width: "100%",
                          // padding: "3px",
                        }}
                      >
                        <FormControl fullWidth>
                          {/* <Typography>Mobile Numbers</Typography> */}
                          <PhoneInput
                            inputProps={{}}
                            inputStyle={{
                              // backgroundColor:"yellow",
                              width: "100%",
                              height: "40px",
                            }}
                            country={"pk"}
                            value={contct?.contactNumber}
                            // onChange={(e, data) => handleMobile(e, data.dialCode)}
                            onChange={(e, data) => {
                              let value = JSON.parse(JSON.stringify(contact));
                              value[i].contactNumber = e;
                              value[i].dialCode = data.dialCode;
                              setcontact(value);
                            }}
                            containerStyle={{ width: "100%", height: "40px" }}
                          />
                          {errors && errors[`contactNumber${i}`] ? (
                            <Typography
                              color={"error"}
                              sx={{ fontSize: "12px" }}
                            >
                              Please Enter Contact Number
                            </Typography>
                          ) : null}
                        </FormControl>
                        {/* <FormControl fullWidth>
                          <TextField
                            key={i}
                            size="small"
                            type="number"
                            value={contct?.contactNumber}
                            onChange={(e) => {
                              if (e.target.value?.length > 14) {
                                return setsnackBarData({
                                  type: "error",
                                  message: "Please Enter Valid Phone No",
                                  openToast: true,
                                });
                              } else {
                                let value = JSON.parse(JSON.stringify(contact));
                                value[i].contactNumber = e.target.value;
                                setcontact(value);
                              }
                            }}
                            name={`contactNumber`}
                            fullWidth
                            sx={{ borderRadius: "none" }}
                          />
              
                        </FormControl> */}
                      </Box>
                      <Box sx={{ width: "200px" }}>
                        <TextField
                          key={i}
                          name={`description`}
                          value={contct?.description}
                          select
                          fullWidth
                          size="small"
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            let value = JSON.parse(JSON.stringify(contact));
                            value[i].description = e.target.value;
                            setcontact(value);
                          }}
                          // error={
                          //   Boolean(formik.errors[`desc${i}`]) &&
                          //   Boolean(formik.touched[`desc${i}`])
                          // }
                          // helperText={
                          //   Boolean(formik.errors[`desc${i}`]) &&
                          //   formik.errors[`desc${i}`]
                          // }
                        >
                          <MenuItem value="Home">Home</MenuItem>
                          <MenuItem value="Work">Work</MenuItem>
                        </TextField>
                        {errors && errors[`descriptionNo${i}`] ? (
                          <Typography color={"error"} sx={{ fontSize: "12px" }}>
                            Please Enter Description
                          </Typography>
                        ) : null}
                      </Box>
                      {contact?.length > 1 ? (
                        <DeleteForeverIcon
                          sx={{
                            marginBottom: formik.errors[`description${i}`]
                              ? "25px"
                              : "0px",
                            color: (theme) => theme?.palette?.primary?.delete,
                            cursor: "pointer",
                          }}
                          onClick={() => handleDel(contct, i)}
                        />
                      ) : null}
                    </Box>
                  );
                })}
                <Box sx={{ width: "fit-content" }}>
                  <Button
                    size="large"
                    color="info"
                    sx={{ borderRadius: "8px" }}
                    onClick={handleAddContactField}
                  >
                    + Add More
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography>Emails</Typography>
                {PersonEmails?.map((email, i) => {
                  return (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          // border: "1px solid rgba(200,200,200,0.9)",
                          width: "100%",
                          // padding: "3px",
                          // border: validate ? "1px solid #d32f2f" : null,
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            key={i}
                            size="small"
                            type={"email"}
                            value={email?.email}
                            onChange={(e) => {
                              let value = JSON.parse(
                                JSON.stringify(PersonEmails)
                              );
                              value[i].email = e.target.value;
                              setPersonEmails(value);
                            }}
                            // variant="standard"
                            name={`email`}
                            fullWidth
                            // onChange={formik.handleChange}
                            sx={{ borderRadius: "none" }}
                            error={Boolean(formik.errors[`PersonEmails${i}`])}
                            helperText={
                              Boolean(formik.errors[`PersonEmails${i}`]) &&
                              formik.errors[`PersonEmails${i}`]
                            }
                          />
                          {/* {formik.errors[`contact`] && formik.touched[`contact`] ? (
                <div>{formik.errors[`contact`]}</div>
              ) : null} */}
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          width: "200px",
                          // border: validate ? "1px solid #d32f2f" : null,
                        }}
                      >
                        <TextField
                          key={i}
                          name={`description`}
                          value={email?.description}
                          select
                          fullWidth
                          size="small"
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            let value = JSON.parse(
                              JSON.stringify(PersonEmails)
                            );
                            value[i].description = e.target.value;
                            setPersonEmails(value);
                          }}
                          // error={
                          //   Boolean(formik.errors[`desc${i}`]) &&
                          //   Boolean(formik.touched[`desc${i}`])
                          // }
                          // helperText={
                          //   Boolean(formik.errors[`desc${i}`]) &&
                          //   formik.errors[`desc${i}`]
                          // }
                        >
                          <MenuItem value="Home">Home</MenuItem>
                          <MenuItem value="Work">Work</MenuItem>
                        </TextField>
                        {errors && errors[`description${i}`] ? (
                          <Typography color={"error"} sx={{ fontSize: "12px" }}>
                            Please Enter Description
                          </Typography>
                        ) : null}
                      </Box>

                      {PersonEmails?.length > 1 ? (
                        <DeleteForeverIcon
                          sx={{
                            marginBottom: formik.errors[`description${i}`]
                              ? "25px"
                              : "0px",
                            color: (theme) => theme?.palette?.primary?.delete,
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelEmail(email, i)}
                        />
                      ) : null}
                    </Box>
                  );
                })}
                <Box sx={{ width: "fit-content" }}>
                  <Button
                    size="large"
                    color="info"
                    sx={{ borderRadius: "8px" }}
                    onClick={handleAddEmailField}
                  >
                    + Add More
                  </Button>
                </Box>
              </Box>
              {businessType !== "RealState" ? (
                <FormControl fullWidth>
                  <Typography>Organization</Typography>
                  <Autocomplete
                    name="organizationId"
                    //   options={["hjehje"]}
                    options={allOrganization}
                    getOptionLabel={(option) => option.Name}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        //   name="personId"
                        // error={
                        //   Boolean(formik.errors.organizationId) &&
                        //   Boolean(formik.touched.organizationId)
                        // }
                        // helperText={
                        //   Boolean(formik.errors.organizationId) &&

                        //   Boolean(formik.touched.organizationId) && formik.errors.organizationId
                        // }
                      />
                    )}
                    value={
                      formik.values?.organizationId
                        ? formik.values?.organizationId
                        : null
                    }
                    onChange={(__, value) => {
                      formik.setFieldValue("organizationId", value);
                      // formik.values?.date=e.target.value
                    }}
                  />
                </FormControl>
              ) : (
                <></>
              )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default CreatePersonForm;
CreatePersonForm.propTypes = {
  screen: PropTypes.string,
};

const useStyle = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",

      width: "100%",
      maxWidth: "1000px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textAreaContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    AddressContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    LoadingContainer: {
      height: "70vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

// const top100Films = [
//   { label: 'The Shawshank Redemption', year: 1994, id: 1 },
//   { label: 'The Godfather', year: 1972, id: 2 },
//   { label: 'The Godfather: Part II', year: 1974, id: 3 },
//   { label: 'The Dark Knight', year: 2008, id: 4 },
//   { label: '12 Angry Men', year: 1957, id: 5 },
//   { label: "Schindler's List", year: 1993, id: 6 },
//   { label: 'Pulp Fiction', year: 1994, id: 7 },
//   { label: "Schindler's List", year: 1993, id: 8 },
//   { label: 'Pulp Fiction', year: 1994, id: 9 },
//   { label: "Schindler's List", year: 1993, id: 10 },
//   { label: 'Pulp Fiction', year: 1994, id: 11 },
//   { label: "Schindler's List", year: 1993, id: 12 },
//   { label: 'Pulp Fiction', year: 1994, id: 13 },
//   { label: "Schindler's List", year: 1993, id: 14 },
//   { label: 'Pulp Fiction', year: 1994, id: 15 },
// ];
