import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import theme from "../../theme";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { CreateDocumentApi } from "../../Api/DocumentApi/CreateDocument";
import {
  fetchDocumentPopulate,
  fetchDocuments,
  fetchOrganization,
  getOrganizationDetails,
} from "../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import ArticleIcon from "@mui/icons-material/Article";
import Loader from "../Loader/Loader";
import { fetchPersons } from "../../Redux/Slice/PersonSlice/PersonSlice";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
function DocumentModal({
  showModal,
  setShowModal,
  Type,
  setselectedRow,
  selectedRow,
}) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const [logo, setlogo] = useState(null);
  const [allDocument, setallDocument] = useState([]);
  const [selectedDocument, setselectedDocument] = useState(null);
  const [upload, setupload] = useState("Upload");
  const { populateDocumentsData, status2 } = useSelector(
    getOrganizationDetails
  );
  console.log(populateDocumentsData);
  console.log(setselectedRow);
  console.log(setallDocument);
  console.log(selectedRow);
  console.log(selectedDocument);
  // const extension = selectedDocument?.FileName?.split(".")[1];

  const initialValues = {
    Description: "",
    DocumentTitle: "",
    Type: "Image",
    Access: "",
  };
  const validationSchema = Yup.object().shape({
    Description: Yup.string().required("Please Enter Description"),
    DocumentTitle: Yup.string().required("Please Enter File Name"),
    Type: Yup.string().required("Please Enter Type"),
    Access: Yup.string().required("Please Enter Access"),
  });
  const handleLogo = (e) => {
    setlogo({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };
  const handleSave = async (values, setSubmitting, resetForm) => {
    const formData = new FormData();
    Object.keys(values).map((key) => {
      formData.append(key, values[key]);
    });
    formData.append("File", logo?.file ? logo?.file : null);
    formData.append("SourceId", selectedRow?.ID);
    formData.append(
      "DocumentId",
      selectedDocument ? selectedDocument?.DocumentId : 0
    );
    formData.append("SourceType", Type);

    const response = await CreateDocumentApi(formData);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      resetForm();
      setselectedDocument(null);
      setSubmitting(false);
      dispatch(fetchDocuments({ type: Type, id: selectedRow?.ID }));

      //   setselectedRow(null);
      if (Type === "Persons") {
        dispatch(fetchPersons("Person"));
      } else if (Type === "Organization") {
        dispatch(fetchOrganization());
      } else if (Type === "Affiliate") {
        dispatch(fetchPersons("Affiliate"));
      }
      setShowModal(false);
    }
    setSubmitting(false);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, setSubmitting, resetForm);
    },
  });
  useEffect(() => {
    if (upload === "Choose from Drive") {
      dispatch(fetchDocumentPopulate());
    }
  }, [upload]);
  useEffect(() => {
    if (populateDocumentsData) {
      console.log(populateDocumentsData);
      if (formik.values?.Type === "Image") {
        setallDocument(populateDocumentsData?.Images);
      } else {
        setallDocument(populateDocumentsData?.Documents);
      }
    }
  }, [populateDocumentsData, formik.values?.Type]);

  return (
    <>
      {status2 === "loading" && <Loader />}
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setselectedDocument(null);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Document Details
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(false);
                  setselectedDocument(null);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <FormControl fullWidth>
                      <Typography>Document Title</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="DocumentTitle"
                        type="text"
                        onBlur={formik.handleBlur}
                        value={formik.values.DocumentTitle}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.DocumentTitle) &&
                          Boolean(formik.errors.DocumentTitle)
                        }
                        helperText={
                          Boolean(formik.touched.DocumentTitle) &&
                          formik.errors.DocumentTitle
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography>Type</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="Type"
                        type="text"
                        select
                        onBlur={formik.handleBlur}
                        value={formik.values.Type}
                        onChange={(e) => {
                          setselectedDocument(null);
                          formik.handleChange(e);
                        }}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.Type) &&
                          Boolean(formik.errors.Type)
                        }
                        helperText={
                          Boolean(formik.touched.Type) && formik.errors.Type
                        }
                      >
                        <MenuItem value="Image">Image</MenuItem>
                        <MenuItem value="Document">Document</MenuItem>
                      </TextField>
                    </FormControl>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="Upload"
                        value={upload}
                        onChange={(e) => {
                          setupload(e.target.value);
                          if (e.target.value === "Upload") {
                            setselectedDocument(null);
                          } else if (e.target.value === "Choose from Drive") {
                            setlogo(null);
                            formik.setFieldValue("Access", "Public");
                          }
                        }}
                        name="radio-buttons-group"
                        row
                      >
                        <FormControlLabel
                          value="Upload"
                          control={<Radio />}
                          label="Upload"
                        />
                        <FormControlLabel
                          value="Choose from Drive"
                          control={<Radio />}
                          label="Choose from Drive"
                        />
                      </RadioGroup>
                    </FormControl>
                    {upload === "Upload" ? (
                      <FormControl fullWidth>
                        <Typography>File</Typography>
                        <TextField
                          sx={{ textAlign: "left", input: { color: "black" } }}
                          fullWidth
                          size="small"
                          name="FileUrl"
                          type="file"
                          InputProps={{
                            inputProps: {
                              accept:
                                formik.values?.Type === "Image"
                                  ? "image/*"
                                  : ".pdf, .doc, .docx",
                            },
                          }}
                          // accept={formik.values?.Type==="Image"?"image/*":".pdf, .doc, .docx"}
                          onChange={handleLogo}
                          variant="outlined"
                        />
                      </FormControl>
                    ) : (
                      <FormControl fullWidth>
                        <Typography>Drive</Typography>
                        <Autocomplete
                          name="organizationId"
                          //   options={["hjehje"]}
                          options={allDocument}
                          // getOptionLabel={(option) => `${option.DocumentTitle} ${extension?`( .${extension} )`:""}`}
                          getOptionLabel={(option) => `${option.DocumentTitle}`}
                          size={"small"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              //   name="personId"
                            />
                          )}
                          value={selectedDocument}
                          onChange={(__, value) => {
                            setselectedDocument(value);
                            // formik.values?.date=e.target.value
                          }}
                        />
                      </FormControl>
                    )}
                    {(logo && formik.values.Type == "Image") ||
                    (selectedDocument && formik.values.Type == "Image") ? (
                      <Box
                        sx={{
                          height: "70px",
                          width: "70px",
                          borderRadius: "5px",
                          border: "1px solid rgb(214, 214, 214)",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer !important",
                          justifyContent: "center",
                          position: "relative",
                          padding: "10px",
                        }}
                      >
                        <img
                          src={
                            selectedDocument
                              ? selectedDocument?.File
                              : logo?.url
                          }
                          alt="Image"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Box>
                    ) : (formik.values.Type == "Document" && logo) ||
                      (selectedDocument && formik.values.Type == "Document") ? (
                      selectedDocument && formik.values.Type == "Document" ? (
                        <Link target="_blank" href={selectedDocument?.File}>
                          <Box
                            sx={{
                              height: "70px",
                              width: "70px",
                              borderRadius: "5px",
                              border: "1px solid rgb(214, 214, 214)",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer !important",
                              justifyContent: "center",
                              position: "relative",
                              padding: "10px",
                            }}
                          >
                            <ArticleIcon
                              fontSize="small"
                              color="primary"
                              sx={{
                                fontSize: "36px",
                              }}
                            />
                          </Box>
                        </Link>
                      ) : (
                        <Box
                          sx={{
                            height: "70px",
                            width: "70px",
                            borderRadius: "5px",
                            border: "1px solid rgb(214, 214, 214)",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer !important",
                            justifyContent: "center",
                            position: "relative",
                            padding: "10px",
                          }}
                        >
                          <ArticleIcon
                            fontSize="small"
                            color="primary"
                            sx={{
                              fontSize: "36px",
                            }}
                          />
                        </Box>
                      )
                    ) : null}
                    <FormControl fullWidth>
                      <Typography>Access</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        disabled={upload === "Choose from Drive"}
                        size="small"
                        name="Access"
                        type="text"
                        select
                        onBlur={formik.handleBlur}
                        value={formik.values.Access}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.Access) &&
                          Boolean(formik.errors.Access)
                        }
                        helperText={
                          Boolean(formik.touched.Access) && formik.errors.Access
                        }
                      >
                        <MenuItem value="Public">Public</MenuItem>
                        <MenuItem value="Private">Private</MenuItem>
                      </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography>Description</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="Description"
                        type="text"
                        multiline
                        rows={5}
                        onBlur={formik.handleBlur}
                        value={formik.values.Description}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.Description) &&
                          Boolean(formik.errors.Description)
                        }
                        helperText={
                          Boolean(formik.touched.Description) &&
                          formik.errors.Description
                        }
                      />
                    </FormControl>

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button type="submit" variant="contained">
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default DocumentModal;

DocumentModal.propTypes = {
  showModal: PropTypes.any,
  setShowModal: PropTypes.any,
  Type: PropTypes.any,
  selectedRow: PropTypes.any,
  setselectedRow: PropTypes.any,
};
