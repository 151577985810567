import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  // Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import { CreateQuoteApi } from "../../../Api/CreateQuoteApi/CreateQuoteApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { UpdateQuoteApi } from "../../../Api/CreateQuoteApi/UpdateQuoteApi";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
});
function QuoteItems({
  handleBack,
  addressInfo,
  quoteInfo,
  quotesPopulate,
  state,
  leadId,
}) {
  const { formContainer } = useStyle();
  const navigate = useNavigate();
  const [renderComp, setrenderComp] = useState(false);
  const [type, setType] = useState([{ value: "", label: "" }]);
  const [products, setProducts] = useState([]);

  console.log("leadId", leadId);
  const [quoteItem, setquoteItem] = useState([
    {
      Name: "",
      productId: null,
      quantity: 0,
      price: 0,
      amount: 0,
      discountAmount: 0,
      taxAmount: 0,
      total: 0,
    },
  ]);
  console.log("quoteItem", quoteItem);
  const handleAddQuoteField = () => {
    setquoteItem([
      ...quoteItem,
      {
        Name: "",
        productId: null,
        quantity: 0,
        price: 0,
        amount: 0,
        discountAmount: 0,
        taxAmount: 0,
        total: 0,
      },
    ]);
  };

  const { setsnackBarData } = useContext(SnackBarContext);
  const initialValues = {
    subTotal: 0,
    discountAmount: 0,
    taxAmount: 0,
    adjustmentAmount: 0,
    grandTotal: 0,
  };

  useEffect(() => {
    let arr = [];
    if (state) {
      state?.QuoteProducts?.map((item) => {
        arr.push({
          id: item?.Id,
          Name: item?.Product?.Name,
          productId: item?.ProductId,
          quantity: item?.Quantity,
          price: item?.Price,
          amount: item?.Amount,
          discountAmount: item?.DiscountAmount,
          taxAmount: item?.TaxAmount,
          total: item?.Total,
        });
      });
      setquoteItem(arr);
      formik.setFieldValue("subTotal", state?.SubTotal ? state?.SubTotal : 0);
      formik.setFieldValue(
        "discountAmount",
        state?.DiscountAmount ? state?.DiscountAmount : 0
      );
      formik.setFieldValue(
        "taxAmount",
        state?.TaxAmount ? state?.TaxAmount : 0
      );
      formik.setFieldValue(
        "adjustmentAmount",
        state?.AdjustmentAmount ? state?.AdjustmentAmount : 0
      );
      formik.setFieldValue(
        "grandTotal",
        state?.GrandTotal ? state?.GrandTotal : 0
      );
    }
  }, [state]);
  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    let sumSubTotal = 0;
    let sumDiscount = 0;
    let sumTax = 0;
    let sumGrandTotal = 0;
    quoteItem?.map((abv) => {
      sumSubTotal = sumSubTotal + +abv.amount;
      sumDiscount = sumDiscount + +abv.discountAmount;
      sumTax = sumTax + +abv.taxAmount;
      sumGrandTotal = sumGrandTotal + +abv.total;
    });

    sumGrandTotal = sumGrandTotal + +formik.values?.adjustmentAmount;

    formik.setFieldValue("subTotal", sumSubTotal);
    formik.setFieldValue("discountAmount", sumDiscount);
    formik.setFieldValue("taxAmount", sumTax);
    formik.setFieldValue("grandTotal", sumGrandTotal);
  }, [quoteItem, formik.values?.adjustmentAmount]);

  const handleDel = (row, i) => {
    let value = JSON.parse(JSON.stringify(quoteItem));
    let value2 = JSON.parse(JSON.stringify(type));

    value.splice(i, 1);
    value2.splice(i, 1);

    // let value = contact.filter((cont)=>cont.id!==contct.id);
    // console.log(value);
    setquoteItem(value);
    setType(value2);
  };
  const handleNavigate = () => {
    handleBack();
  };
  console.log(addressInfo);
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    let arr = [];
    quoteItem?.map((quote) => {
      arr.push({
        amount: quote?.amount,
        discountAmount: quote?.discountAmount,
        price: quote?.price,
        productId: quote?.productId,
        quantity: quote?.quantity,
        taxAmount: quote?.taxAmount,
        total: quote?.total,
      });
    });
    let arr2 = [];
    quoteItem?.map((quote) => {
      arr2.push({
        id: quote?.id ? quote?.id : 0,
        amount: quote?.amount,
        discountAmount: quote?.discountAmount,
        price: quote?.price,
        productId: quote?.productId,
        quantity: quote?.quantity,
        taxAmount: quote?.taxAmount,
        total: quote?.total,
      });
    });
    let billingaddress = {
      addressDetail: addressInfo?.billingaddress,
      country: addressInfo?.country,
      state: addressInfo?.state,
      city: addressInfo?.city,
      postcode: JSON.stringify(addressInfo?.postCode),
    };
    let shippingAddress = {
      addressDetail: addressInfo?.shippingaddress,
      country: addressInfo?.country2,
      state: addressInfo?.state2,
      city: addressInfo?.city2,
      postcode: JSON.stringify(addressInfo?.postCode2),
    };
    const obj = {
      ...values,
      ...quoteInfo,
      leadId: quoteInfo?.leadId?.Id,
      personId: quoteInfo?.personId?.Id,
      billingAddressNavigation: billingaddress,
      quoteProducts: arr,
      shippingAddressNavigation: shippingAddress,
    };
    const obj2 = {
      ...values,
      ...quoteInfo,
      leadId: quoteInfo?.leadId?.Id,
      personId: quoteInfo?.personId?.Id,
      billingAddressNavigation: billingaddress,
      quoteProducts: arr2,
      shippingAddressNavigation: shippingAddress,
    };

    if (state?.singleLead && state?.isLead) {
      const response = await CreateQuoteApi(obj);
      setsnackBarData(response.snackBarData);
      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
    } else if (state) {
      const response = await UpdateQuoteApi({ ...obj2, id: state.ID });
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
        // dispatch(fetchProducts());
      }
    } else {
      const response = await CreateQuoteApi(obj);
      setsnackBarData(response.snackBarData);
      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
    }
    setSubmitting(false);
    // resetForm();
  };
  // const ValidationSchema = Yup.object().shape({
  //     subtotal: Yup.string().required("Please Enter user subtotal"),
  //     discount: Yup.string().required("Please Enter discount"),
  //     tax: Yup.string().required("Please Enter tax"),
  //     adjustment: Yup.string().required("Please Enter adjustment"),

  // });

  useEffect(() => {
    const selectedLead = quotesPopulate?.Data?.leadData?.find(
      (lead) => lead?.Id === leadId?.Id
    );

    const leadProducts = selectedLead ? selectedLead.LeadProducts : [];
    const newArray = leadProducts.map((leadProduct) => leadProduct.Product);
    setProducts(newArray);
  }, [quotesPopulate]);
  console.log("products", products);
  return (
    <Paper
      elevation={3}
      sx={{ padding: "20px" }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <Box>
            <TableContainer
              component={Paper}
              sx={{}}
            >
              <Table
                sx={{
                  minWidth: { md: "auto", xs: "auto" },
                  overflow: "scroll",
                }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow size="small">
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",

                        // color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        // color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        // color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Price
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        // color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        // color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Discount
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        // color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Tax
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        // color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quoteItem &&
                    quoteItem?.map((row, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            size="small"
                            align="left"
                          >
                            <Autocomplete
                              name="productId"
                              size="small"
                              value={row}
                              sx={{ width: "200px" }}
                              // options={
                              //   type[i].label === "Product"
                              //     ? quotesPopulate?.Data?.productData
                              //     : type[i].label === "Product"
                              //     ? quotesPopulate?.Data?.serviceData
                              //     : []
                              // }
                              options={products.filter(
                                (product) =>
                                  !quoteItem.some(
                                    (item) => item.Name === product.Name
                                  )
                              )}
                              getOptionLabel={(option) => option?.Name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // value={row?.productId ? row?.productId : null}
                                />
                              )}
                              onChange={(__, values) => {
                                console.log("values", values);

                                let value = JSON.parse(
                                  JSON.stringify(quoteItem)
                                );
                                value[i].Name = values?.Name || "";
                                value[i].productId = values?.Id;
                                value[i].quantity = values?.Id
                                  ? values?.Quantity
                                  : 0;
                                value[i].price = values?.Id ? values?.Price : 0;
                                value[i].amount = values?.Id
                                  ? values?.Price * values?.Quantity
                                  : 0;
                                value[i].total = values?.Id
                                  ? values?.Price * values?.Quantity
                                  : 0;
                                // setquoteAmount()
                                setquoteItem(value);
                                setrenderComp(!renderComp);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            size="small"
                            align="left"
                          >
                            <TextField
                              value={row?.quantity}
                              type={"number"}
                              name="quantity"
                              size="small"
                              onChange={(e) => {
                                let value = JSON.parse(
                                  JSON.stringify(quoteItem)
                                );
                                value[i].quantity = Number(e.target.value);
                                value[i].amount =
                                  Number(e.target.value) * Number(row?.price);
                                value[i].total =
                                  Number(e.target.value) * Number(row?.price) +
                                  row?.taxAmount -
                                  row?.discountAmount;

                                setquoteItem(value);
                                setrenderComp(!renderComp);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            size="small"
                            align="left"
                          >
                            <TextField
                              value={row?.price}
                              type={"number"}
                              name="price"
                              size="small"
                              onChange={(e) => {
                                let value = JSON.parse(
                                  JSON.stringify(quoteItem)
                                );
                                value[i].price = Number(e.target.value);
                                value[i].amount =
                                  Number(row?.quantity) *
                                  Number(e.target.value);
                                value[i].total =
                                  Number(row?.quantity) *
                                    Number(e.target.value) +
                                  row?.taxAmount -
                                  row?.discountAmount;
                                setquoteItem(value);
                                setrenderComp(!renderComp);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            size="small"
                            align="left"
                          >
                            <TextField
                              value={row?.amount}
                              type={"number"}
                              name="amount"
                              size="small"
                              // onChange={(e) => {
                              //
                              //     let value = JSON.parse(JSON.stringify(quoteItem));
                              //     value[i].amount = Number(e.target.value);
                              //     setquoteItem(value);

                              // }}
                            />
                          </TableCell>
                          <TableCell
                            size="small"
                            align="left"
                          >
                            <TextField
                              value={row?.discountAmount}
                              type={"number"}
                              name="discountAmount"
                              size="small"
                              onChange={(e) => {
                                let value = JSON.parse(
                                  JSON.stringify(quoteItem)
                                );
                                value[i].discountAmount = Number(
                                  e.target.value
                                );
                                value[i].total =
                                  Number(row?.amount) -
                                  Number(e.target.value) +
                                  Number(row?.taxAmount);

                                setquoteItem(value);
                                setrenderComp(!renderComp);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            size="small"
                            align="left"
                          >
                            <TextField
                              value={row?.taxAmount}
                              type={"number"}
                              size="small"
                              name="taxAmount"
                              onChange={(e) => {
                                let value = JSON.parse(
                                  JSON.stringify(quoteItem)
                                );
                                value[i].taxAmount = Number(e.target.value);
                                value[i].total =
                                  Number(row?.amount) +
                                  Number(e.target.value) -
                                  row.discountAmount;

                                setquoteItem(value);
                                setrenderComp(!renderComp);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            size="small"
                            align="left"
                          >
                            <TextField
                              type={"number"}
                              value={row?.total}
                              size="small"
                              sx={{ width: "110px" }}
                              name="total"
                              //  onChange={(e) => {
                              //
                              //     let value = JSON.parse(JSON.stringify(quoteItem));
                              //     value[i].total = Number(e.target.value);
                              //     setquoteItem(value);

                              // }}
                            />
                          </TableCell>
                          <TableCell
                            size="small"
                            align="left"
                          >
                            {quoteItem?.length > 1 ? (
                              <DeleteIcon
                                fontSize="small"
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                }}
                                onClick={() => handleDel(row, i)}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ width: "fit-content" }}>
            <Button
              size="large"
              color="info"
              sx={{ borderRadius: "8px" }}
              onClick={handleAddQuoteField}
            >
              + Add More
            </Button>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                maxWidth: "270px",
                justifyContent: "space-evenly",
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Sub Total ($)
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>-</Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  size="small"
                  sx={{ maxWidth: "120px" }}
                  name="subTotal"
                  value={formik.values?.subTotal}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                maxWidth: "270px",
                justifyContent: "space-evenly",
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Discount ($)
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>-</Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  sx={{ maxWidth: "120px" }}
                  size="small"
                  name="discountAmount"
                  value={formik.values?.discountAmount}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                maxWidth: "270px",
                justifyContent: "space-evenly",
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontWeight: "bold" }}>Tax ($)</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>-</Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  sx={{ maxWidth: "120px" }}
                  size="small"
                  name="taxAmount"
                  value={formik.values?.taxAmount}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                maxWidth: "270px",
                justifyContent: "space-evenly",
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Adjustment ($)
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>-</Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  sx={{ maxWidth: "120px" }}
                  size="small"
                  type={"number"}
                  name="adjustmentAmount"
                  value={formik.values?.adjustmentAmount}
                  onChange={formik.handleChange}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                maxWidth: "270px",
                justifyContent: "space-evenly",
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Grand Total ($)
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>-</Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  sx={{ maxWidth: "120px" }}
                  size="small"
                  name="grandTotal"
                  value={formik.values?.grandTotal}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button onClick={handleNavigate}>
                  <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                </Button>
              </Box>
              <Box>
                {formik.isSubmitting ? (
                  <Box>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {state && !state?.isLead && !state?.singleLead
                        ? "Update"
                        : "Save"}
                    </Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

export default QuoteItems;
QuoteItems.propTypes = {
  handleBack: PropTypes.any,
  quoteInfo: PropTypes.any,
  addressInfo: PropTypes.any,
  quotesPopulate: PropTypes.any,
  state: PropTypes.any,
  leadId: PropTypes.any,
};
// const top100Films = [
//     { label: 'The Shawshank Redemption', year: 1994,id:1 },
//     { label: 'The Godfather', year: 1972,id:2 },
//     { label: 'The Godfather: Part II', year: 1974,id:3 },
//     { label: 'The Dark Knight', year: 2008,id:4 },
//     { label: '12 Angry Men', year: 1957,id:5 },
//     { label: "Schindler's List", year: 1993,id:6 },
//     { label: 'Pulp Fiction', year: 1994,id:7 },
// ];
