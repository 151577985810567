import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Draft: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchDraft = createAsyncThunk("/Mail/DraftMails", async () => {
  const response = await axiosInstance.get(`/Mail/DraftMails`);
  console.log(response);
  return response;
});

const DraftSlice = createSlice({
  name: "Draft",
  initialState,
  reducers: {
    DraftAdded: {
      reducer(state, action) {
        console.log(action.payload);
        state.Draft?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchDraft.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDraft.fulfilled, (state, action) => {
        console.log(action.payload.Data);
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Draft = action.payload.data.Data;
      })
      .addCase(fetchDraft.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getDraftDetails = (state) => state.Draft;

export const { DraftAdded } = DraftSlice.actions;

export default DraftSlice.reducer;
