import React, { useState, useEffect, useContext } from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Badge, Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
// import AddIcon from '@mui/icons-material/Add';
// import { useRef } from "react";
// import moment from "moment";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
// import OrganizationFilterDrawer from "../../../Components/Contacts/Organizations/OrganizationFilterDrawer/OrganizationFilterDrawer";
import Loader from "../../../Components/Loader/Loader";
import {
  fetchTemplate,
  getTemplateDetails,
} from "../../../Redux/Slice/TemplateSlice/TemplateSlice";
import { DeleteTemplateApi } from "../../../Api/TemplateApi/DeleteTemplateApi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TemplatesInfoModal from "../../../Components/Campaign/Templates/TemplatesInfoModal";
import { AppContext } from "../../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import TempleteFilterDrawer from "./TempleteFilterDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import { RemoveSCFromInputs } from "../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../Components/AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

export default function Template({ roles }) {
  // const [rows, setrows] = useState([]);
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const allTemplate = useSelector(getTemplateDetails);
  const [pageSize, setpageSize] = useState(totalRows);
  const [showLoading, setshowLoading] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [rows, setrows] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { setState, state } = useContext(AppContext);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [filterVal, setfilterVal] = useState({
    type: "",
    title: "",
  });
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Templates");

    if (!isSkip && allTemplate?.status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [allTemplate?.status]);
  // const [drawerOpen, setdrawerOpen] = useState(false);
  // const [filterVal, setfilterVal] = useState(null);
  // const [count, setcount] = useState(0);
  // const ID = useRef();
  // const name = useRef();
  // const endDateRef = useRef();
  // const CreatedDateRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClosed = () => {
    setShowModal(false);
  };

  // useEffect(() => {
  //   let sum = 0;
  //   for (const value in filterVal) {
  //     if (filterVal[value] !== "") {
  //       sum = sum + 1;
  //     }
  //   }
  //   setcount(sum);
  // }, [filterVal]);
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchTemplate());
    }
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    allTemplate?.Template?.map((item, i) => {
      rowData.push({
        id: i + 1,
        title: item.Title,
        type: item.Type,
        body: item.Body,
        Placeholders: item.Placeholders,
        ID: item.TemplateId,
      });
    });
    setrows(rowData);
    setAllTemplates(rowData);
  }, [allTemplate]);

  const handleClick = (val) => {
    navigate("/campaigns/templates/create", { state: val });
  };
  const deleteOrg = async (id) => {
    setshowLoading(true);
    let res = await DeleteTemplateApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);

    if (res.data.IsSuccess) {
      dispatch(fetchTemplate());
    }
    setsnackBarData(res.snackBarData);
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (filterVal?.type === "" && filterVal?.title === "") {
      // console.log(salesPersonRef.current?.value);
      setAllTemplates(rows);
    } else {
      let suggestions = [];
      let regex3 = new RegExp(filterVal?.type, "i");
      let regex4 = new RegExp(filterVal?.title, "i");

      suggestions = rows?.filter(
        (val) =>
          (filterVal?.type === "" || regex3.test(val?.type)) &&
          (filterVal?.title === "" || regex4.test(val?.title))
      );
      setAllTemplates(suggestions);
    }
  }, [filterVal, rows]);
  const columns = [
    { field: "id", headerName: "Sr", width: 50 },
    { field: "title", headerName: "Title", width: 200 },
    { field: "type", headerName: "Type", width: 200 },
    // {
    //   field: "body",
    //   headerName: "Body",
    //   width: 250,
    //   renderCell: (celval) => {
    //     return (
    //       <div dangerouslySetInnerHTML={{ __html: celval?.formattedValue }} />
    //     );
    //   },
    // },

    {
      field: "actions",
      headerName: "Actions",
      width: 180,

      renderCell: (celval, i) => {
        return (
          <>
            <Box id="template-actions" key={i}>
              {roles?.some((role) => role === "Templates_Edit") && (
                <Tooltip title="Edit">
                  <IconButton
                    key={i}
                    onClick={() => {
                      handleClick(celval.row);
                      // setsnackBarData({
                      //   type: "success",
                      //   message: "hello",
                      //   openToast: true,
                      // });
                    }}
                  >
                    <EditIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.edit,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              )}

              {roles?.some((role) => role === "Templates_Delete") && (
                <Tooltip title="Delete">
                  <IconButton
                    key={i}
                    onClick={() => {
                      setselectedId(celval.row.ID);
                      setshowConfirmationIcons(true);
                    }}
                  >
                    <DeleteForeverIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.delete,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="View">
                <IconButton
                  key={i}
                  onClick={() => {
                    console.log("celval.row", celval.row);
                    setShowModal(true);
                    setTemplate(celval.row);
                    // dispatch(fetchproduct(celval.row?.ID));
                  }}
                >
                  <VisibilityIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];
  // const handleChangeVal = (e) => {
  //   setfilterVal({
  //     ...filterVal,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const onTextChange = () => {
  //   if (
  //     ID.current?.value === "" &&
  //     name.current?.value === "" &&
  //     CreatedDateRef.current?.value === "" &&
  //     endDateRef.current?.value === ""
  //   ) {
  //     setAllTemplates(rows);
  //   } else {
  //     let suggestions = [];
  //     let regex1 = new RegExp(ID.current.value, "i");
  //     let regex2 = new RegExp(name.current.value, "i");
  //     let regex3 = new RegExp(
  //       moment(CreatedDateRef.current.value).format("DD-MM-YYYY"),
  //       "i"
  //     );
  //     let regex4 = new RegExp(
  //       moment(endDateRef.current.value).format("DD-MM-YYYY"),
  //       "i"
  //     );

  //     suggestions = rows?.filter(
  //       (val) =>
  //         (ID.current.value === "" || regex1.test(val.id)) &&
  //         (name.current.value === "" || regex2.test(val?.name)) &&
  //         (CreatedDateRef.current.value === "" ||
  //           regex3.test(val?.createddate)) &&
  //         (endDateRef.current.value === "" || regex4.test(val?.createddate))
  //     );
  //     setAllTemplates(suggestions);
  //   }
  // };

  return (
    <>
      <Box>
        <TempleteFilterDrawer
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
          rows={rows}
          setAllTemplates={setAllTemplates}
          handleChangeVal={handleChangeVal}
          setcount={setcount}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
        />
      </Box>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Templates"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="template">
        {/* <OrganizationFilterDrawer
          CreatedDateRef={CreatedDateRef}
          endDateRef={endDateRef}
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          setcount={setcount}
          setAllTemplates={setAllTemplates}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          ID={ID}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        /> */}
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            rows={allTemplates}
            pageSize={pageSize}
            columns={columns}
            onPageSizeChange={(newPage) => {
              setpageSize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            // scrollbarSize={1}
            // scrollbarSize={'1px'}
          />
          {allTemplate?.status === "loading" || showLoading ? <Loader /> : null}
        </Box>
      </Box>
      <TemplatesInfoModal
        showModal={showModal}
        handleClosed={handleClosed}
        template={template}
      />

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteOrg(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Template"
        title={`Are you sure, you want to Delete Template?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}
const steps = [
  {
    target: "#template",
    content: (
      <>
        <p size="large">Templates Page</p>
        <p>
          Welcome to the Template Screen – your creative toolkit for crafting
          compelling campaigns. Templates serve as the foundation for your
          campaigns, providing a standardized and efficient way to communicate
          with your audience.
          <br />
          Creating a campaign? Streamline the process by selecting a template as
          the starting point. Tailor the template to your campaign&apos;s needs,
          saving time and ensuring consistency.
          <br />
          <br />
          Effortlessly manage your templates. Click &apos;New&apos; to add
          fresh, impactful templates to your collection. Keep your creative
          assets organized and ready for use.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#template-actions",
    content: (
      <>
        <p size="large">Templates Actions</p>
        <p>
          Following are the actions you can perform on this screen:
          <br />
          <br /> Content Preview: Get a glimpse of the template&apos;s structure
          and design.
          <br />
          Edit and Delete: Customize existing templates or remove those no
          longer needed.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
Template.propTypes = {
  roles: PropTypes.array,
};
