import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const PageNumberContext = createContext();

const PageNumberContextProvider = (props) => {
  const [totalRows, setTotalRows] = useState(50);

  return (
    <PageNumberContext.Provider value={{ totalRows, setTotalRows }}>
      {props.children}
    </PageNumberContext.Provider>
  );
};

export default PageNumberContextProvider;

PageNumberContextProvider.propTypes = {
  children: PropTypes.any,
};
