import { axiosInstance } from "../../Utils/AxiosInstance";
export const UpdateLeadApi = async ({ obj, id }) => {
  let snackBarData = {};
  try {
    console.log("object", obj);
    let response = await axiosInstance.put(`/Lead/${Number(id)}`, obj);
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
