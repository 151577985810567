import React from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import PlannedLunches from "./PlannedLunches";
import DoneLunches from "./DoneLunches";
import { useEffect } from "react";
import { useState } from "react";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      //   gap: "20px",
      padding: "20px",
      //   alignItems: "flex-start",
    },
    plannedBox: {
      display: "flex",
      //   flexDirection:"column",
      gap: "10px",
      width: "100%",
      alignItems: "center",
    },

    Border: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "20px",
    },
  };
});
function Lunches({ singleLead, roles }) {
  const { formContainer, plannedBox, Border } = useStyle();
  const [plannedlunchLeads, setplannedlunchLeads] = useState([]);
  const [doneLunchLeads, setdoneLunchLeads] = useState([]);

  useEffect(() => {
    let arr = [];
    let arr2 = [];
    singleLead?.leadActivity?.map((lunchs) => {
      if (lunchs?.Type === "Email" && lunchs?.StatusDone === false) {
        arr.push(lunchs);
      }
      if (lunchs?.Type === "Email" && lunchs?.StatusDone === true) {
        arr2.push(lunchs);
      }
    });
    setplannedlunchLeads(arr);
    setdoneLunchLeads(arr2);
  }, [singleLead]);
  return (
    <Paper elevation={3}>
      <Box className={formContainer}>
        <Box className={Border}>
          <Box>
            <Box className={plannedBox}>
              <Typography
                sx={{ fontWeight: "bold", color: "rgb(84, 110, 122)" }}
              >
                Planned
              </Typography>
              <Box sx={{ width: "100%" }}>
                <Divider />
              </Box>
            </Box>
            <PlannedLunches singleLead={singleLead} roles={roles} />
            {plannedlunchLeads?.length > 0 ? null : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "rgb(84, 110, 122)" }}>
                  You have no planned Emails.
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            <Box sx={{}} className={plannedBox}>
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", color: "rgb(84, 110, 122)" }}
                >
                  Done
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Divider />
              </Box>
            </Box>
            <DoneLunches singleLead={singleLead} roles={roles} />
            {doneLunchLeads?.length > 0 ? null : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "rgb(84, 110, 122)" }}>
                  You have no done Emails.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {/* </Box> */}
      </Box>
    </Paper>
  );
}

export default Lunches;
Lunches.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
