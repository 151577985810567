import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  // DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { Button, TextField, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import { useContext } from "react";
import PropTypes from "prop-types";
import Joyride from "react-joyride";
import { AppContext } from "../../../Context/TourContext/TourContext";
import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import Loader from "../../../Components/Loader/Loader";
import {
  fetchUserLeads,
  getReportsDetails,
} from "../../../Redux/Slice/ReportSlice/ReportSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  fetchLeadsPopulate,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import { StripedDataGrid } from "../../../Utils/StrippedDataGrid";
import { useLocation, useNavigate } from "react-router-dom";
import LeadReportModal from "../../../Components/Reports/LeadReportModal";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";
import moment from "moment";

function UserLeadsReport({ roles }) {
  let guide = sessionStorage.getItem("guide");
  
  const { totalRows } = useContext(PageNumberContext);
  const navigate = useNavigate();

  const [pagesize, setpagesize] = useState(totalRows);
  const [showModal, setShowModal] = useState(false);

  // const [rows, setrows] = React.useState([]);
  const [allReport, setallReport] = useState([]);
  // const [drawerOpen, setdrawerOpen] = useState(false);
  // const [filterVal, setfilterVal] = useState(null);
  // const [count, setcount] = useState(0);

  const { setState, state } = useContext(AppContext);

  const dispatch = useDispatch();
  const UserState = useLocation();
  const { UserLeads, status1 } = useSelector(getReportsDetails);
  const { LeadsPopulate, status4 } = useSelector(getLeadsDetails);
  const [user, setUser] = useState(null);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    if (!LeadsPopulate?.Data) dispatch(fetchLeadsPopulate());
  }, [dispatch]);
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "UserLeadsReport");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  const ValidationSchema = Yup.object().shape({
    // from: Yup.string().required("Please Enter From Date"),
    // to: Yup.string().required("Please Enter To Date"),
  });
  const initialValues = {
    from: "",
    to: "",
    userId: "",
    leadSourceId: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      dispatch(fetchUserLeads(values));
    },
  });
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingRight: "16px", // Add padding to align with right end
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {roles?.some((role) => role === "export_Create") && (
                <GridToolbarExport
                  csvOptions={{ fileName: "Active Employees" }}
                />
              )}
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarColumnsButton />
              {/* <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button> */}
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "end",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <TextField
                  name="userId"
                  value={formik.values?.userId}
                  size={"small"}
                  select
                  label="Select User"
                  sx={{ minWidth: "150px" }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setUser(null);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors?.userId) &&
                    Boolean(formik.touched?.userId)
                  }
                  helperText={
                    Boolean(formik.errors?.userId) &&
                    Boolean(formik.touched?.userId) &&
                    formik.errors?.userId
                  }
                >
                  <MenuItem value="">Select</MenuItem>
                  {LeadsPopulate?.Data?.userData?.map((user, i) => {
                    return (
                      <MenuItem key={i} value={user?.Id}>
                        {user?.FullName}
                      </MenuItem>
                    );
                  })}
                </TextField>

                <TextField
                  label="Select Source"
                  sx={{ minWidth: "150px" }}
                  name="leadSourceId"
                  value={formik.values?.leadSourceId}
                  size={"small"}
                  select
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors?.leadSourceId) &&
                    Boolean(formik.touched?.leadSourceId)
                  }
                  helperText={
                    Boolean(formik.errors?.leadSourceId) &&
                    Boolean(formik.touched?.leadSourceId) &&
                    formik.errors?.leadSourceId
                  }
                >
                  <MenuItem value="">Select</MenuItem>
                  {LeadsPopulate?.Data?.sourceData?.map((source, i) => {
                    return (
                      <MenuItem key={i} value={source?.Id}>
                        {source?.Name}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  id="dashboard-from"
                  label="From"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="from"
                  value={formik.values.from}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  size="small"
                  type={"date"}
                  error={
                    Boolean(formik.errors.from) && Boolean(formik.touched.from)
                  }
                  helperText={
                    Boolean(formik.errors.from) &&
                    Boolean(formik.touched.from) &&
                    formik.errors.from
                  }
                />
                <TextField
                  name="to"
                  id="dashboard-to"
                  label="To"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.to}
                  variant="outlined"
                  size="small"
                  type={"date"}
                  error={
                    Boolean(formik.errors.to) && Boolean(formik.touched.to)
                  }
                  helperText={
                    Boolean(formik.errors.to) &&
                    Boolean(formik.touched.to) &&
                    formik.errors.to
                  }
                />
                <Button
                  id="dashboard-get"
                  variant="contained"
                  className="get"
                  type="submit"
                >
                  Get
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  // useEffect(() => {
  //   let sum = 0;
  //   for (const value in filterVal) {
  //     if (filterVal[value] !== "") {
  //       sum = sum + 1;
  //     }
  //   }
  //   setcount(sum);
  // }, [filterVal]);

  useEffect(() => {
    let rowData = [];
    UserLeads?.map((item, i) => {
      rowData.push({
        id: i + 1,
        totalLeadActivities: item?.totalLeadActivities,
        SourceName: item?.SourceName,
        LeadValue: item?.LeadValue,
        LeadTitle: item?.LeadTitle,
        LeadStatus: item?.LeadStatus,
        LeadId: item?.leadId,
        Stages: item?.Stages,
        Customer: item?.PersonName,
        latestActivity: item?.latestActivity?.Type,
        latestActivityDate: item?.latestActivity?.CreatedAt,
        LeadTypeName: item?.LeadTypeName,
      });
    });
    // setrows(rowData);
    setallReport(rowData);
  }, [UserLeads]);

  const columns = [
    { field: "id", headerName: "Sr", width: 90 },
    {
      field: "Customer",
      headerName: "Customer",
      width: 250,
    },
    {
      field: "LeadTitle",
      headerName: "Lead Title",
      width: 220,
      renderCell: (celval) => {
        return (
          <Typography
            onClick={() => {
              roles?.some((role) => role === "Leads_View") &&
                navigate("/leads/view", {
                  state: celval?.row?.LeadId,
                });
            }}
            sx={{ color: "#298b8b", cursor: "pointer" }}
          >
            {celval.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: "LeadValue",
      headerName: "Lead Value",
      width: 180,
      renderCell: (celval) => {
        return celval.formattedValue?.toLocaleString();
      },
    },
    {
      field: "LeadStatus",
      headerName: "Lead Status",
      width: 150,
    },
    {
      field: "SourceName",
      headerName: "Lead Source",
      width: 150,
    },
    {
      field: "LeadTypeName",
      headerName: "Lead Type",
      width: 150,
    },
    {
      field: "totalLeadActivities",
      headerName: "Total Activities",
      width: 180,
      renderCell: (celval) => {
        return (
          <Typography
            onClick={() => {
              Number(celval.formattedValue) > 0 && setShowModal(true);
              setStages(celval.row);
            }}
            sx={{
              color: Number(celval.formattedValue) > 0 && "#298b8b",
              cursor: Number(celval.formattedValue) > 0 && "pointer",
              textDecoration: Number(celval.formattedValue) > 0 && "underline",
            }}
          >
            {celval.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: "latestActivity",
      headerName: "Latest Activity",
      width: 150,
    },
    {
      field: "latestActivityDate",
      headerName: "Latest Activity Date",
      width: 150,
      renderCell: (celval) => {
        return (
          celval?.formattedValue &&
          moment(celval?.formattedValue).format("DD MMMM YYYY")
        );
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 110,
    //   renderCell: (celval, i) => {
    //     return (
    //       <Box key={i}>
    //         &nbsp;&nbsp;&nbsp;&nbsp;
    //         {/* <Tooltip title="Edit">
    //           <IconButton
    //             key={i}
    //             onClick={() => {
    //               handleEdit(celval.row);
    //             }}
    //             disabled={
    //               roles?.some((role) => role === "Products_Edit") ? false : true
    //             }
    //           >
    //             <EditIcon
    //               sx={{ color: (theme) => theme?.palette?.primary?.edit }}
    //               fontSize="small"
    //             />
    //           </IconButton>
    //         </Tooltip> */}
    //         {/* <Tooltip title="Delete">
    //           <IconButton
    //             key={i}
    //             onClick={() => {
    //               setshowConfirmationIcons(true);
    //               setselectedId(celval.row.ID);
    //             }}
    //             disabled={
    //               roles?.some((role) => role === "Products_Delete")
    //                 ? false
    //                 : true
    //             }
    //           >
    //             <DeleteForeverIcon
    //               sx={{
    //                 color: (theme) => theme?.palette?.primary?.delete,
    //               }}
    //               fontSize="small"
    //             />
    //           </IconButton>
    //         </Tooltip>*/}
    //         <Tooltip title="View">
    //           <IconButton
    //             key={i}
    //             onClick={() => {
    //               setunitRow(celval.row);
    //               setunitInfoModal(true);
    //             }}
    //           >
    //             <VisibilityIcon
    //               sx={{
    //                 color: (theme) => theme?.palette?.primary?.main,
    //               }}
    //               fontSize="small"
    //             />
    //           </IconButton>
    //         </Tooltip>
    //       </Box>
    //     );
    //   },
    // },
  ];

  // const handleChangeVal = (e) => {
  //   let value = RemoveSCFromInputs(e.target.value);

  //   setfilterVal({
  //     ...filterVal,
  //     [e.target.name]: value || "",
  //   });
  // };

  // const onTextChange = () => {
  //   if (
  //     person.current?.value === "" &&
  //     item.current?.value === "" &&
  //     section.current?.value === "" &&
  //     project.current?.value === "" &&
  //     leadTitle.current?.value === ""
  //   ) {
  //     setallReport(rows);
  //   } else {
  //     let suggestions = [];
  //     let regex1 = new RegExp(RemoveSCFromInputs(project.current.value), "i");
  //     let regex2 = new RegExp(RemoveSCFromInputs(section.current.value), "i");
  //     let regex3 = new RegExp(RemoveSCFromInputs(item.current.value), "i");
  //     let regex4 = new RegExp(RemoveSCFromInputs(person.current.value), "i");
  //     let regex5 = new RegExp(RemoveSCFromInputs(leadTitle.current.value), "i");
  //     suggestions = rows?.filter(
  //       (val) =>
  //         (project.current.value === "" ||
  //           regex1.test(val?.Project?.ProjectName)) &&
  //         (section.current.value === "" ||
  //           regex2.test(val?.Section?.SectionName)) &&
  //         (item.current.value === "" || regex3.test(val?.item)) &&
  //         (person.current.value === "" || regex4.test(val?.person)) &&
  //         (leadTitle.current.value === "" || regex5.test(val?.title))
  //     );
  //     setallReport(suggestions);
  //   }
  // };
  useEffect(() => {
    UserState?.state?.Id && setUser(UserState?.state?.Id);
  }, [UserState]);
  useEffect(() => {
    user && formik.setFieldValue("userId", user);
  }, [user]);
  useEffect(() => {
    user && formik.values.userId && dispatch(fetchUserLeads(formik.values));
  }, [formik.values.userId]);
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "UserLeadsReport"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}
      </Box>
      <Box id="user-lead">
        {/* <BookedUnitFilterDrawer
          handleChangeVal={handleChangeVal}
          rows={rows}
          project={project}
          section={section}
          person={person}
          item={item}
          leadTitle={leadTitle}
          setcount={setcount}
          setallReport={setallReport}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        /> */}
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <StripedDataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () =>
                CustomNoRowsOverlay("No Data. Please Select User."),
            }}
            rows={allReport}
            columns={columns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
          {status4 === "loading" || status1 === "loading" ? <Loader /> : null}
        </Box>
      </Box>

      {showModal && (
        <LeadReportModal
          stages={stages}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
    </>
  );
}

export default UserLeadsReport;
UserLeadsReport.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#user-lead",
    content: (
      <>
        <p size="large">Booked Units Report</p>
        <p>
          Welcome to the User Leads Report! This section provides an overview of
          your leads, including customer details, lead titles, values, statuses,
          sources, types, and associated activities. Stay informed about your
          latest interactions and track your leads effectively to maximize your
          opportunities.
          <br />
          <br />
          Select User to Get Started!
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
