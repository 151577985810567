import React from "react";
import DoneCalls from "../Calls/DoneCalls";
import PropTypes from "prop-types";
import DoneMeetings from "../Meetings/DoneMeetings";
import DoneLunches from "../Lunches/DoneLunches";
import DoneMessages from "../Messages/DoneMessages";
// import LeadNotes from "../Notes/LeadNotes";
// import AllFiles from "../Files/AllFiles";

function DoneAll({ singleLead, roles }) {
  return (
    <>
      <DoneCalls singleLead={singleLead} roles={roles} />
      <DoneMeetings singleLead={singleLead} roles={roles} />
      <DoneLunches singleLead={singleLead} roles={roles} />
      <DoneMessages singleLead={singleLead} roles={roles} />
      {/* <AllFiles
        singleLead={singleLead}
        roles={roles}
      />
      <LeadNotes
        singleLead={singleLead}
        roles={roles}
      /> */}
    </>
  );
}

export default DoneAll;
DoneAll.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
