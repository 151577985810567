import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";
import theme from "../../theme";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";
function Products({ DashboardData }) {
  const [chartData] = useState({
    options: {
      chart: {
        id: "basic-line",
      },
      xaxis: {
        categories: DashboardData?.WeeksData,
      },
    },
    stroke: {
      curve: "smooth",
    },
    series: [
      {
        name: "Sales",
        data: DashboardData?.productCountData,
      },
    ],
  });
  return (
    <Box
      sx={{
        width: "33%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
      id="dashboard-product"
    >
      <Paper elevation={3} sx={{ height: "350px" }}>
        <Typography sx={{ padding: "15px", fontWeight: "bold" }}>
          Products
        </Typography>
        <Divider sx={{ width: "100%" }} />
        {DashboardData?.productCountData?.length > 0 ? (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height={290}
          />
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "60%",
              display: "flex",
              gap: "10px",
              padding: "10px",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            {CustomNoRowsOverlay("No Data Available")}
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default Products;
Products.propTypes = {
  DashboardData: PropTypes.any,
};
