import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "40px",
      // alignItems:"center",
      // width:"80%"
    },
  };
});
function Won({ singleLead }) {
  const { formContainer } = useStyle();
  return (
    <Paper elevation={3}>
      <Box className={formContainer}>
        <Paper elevation={3}>
          <TableContainer component={Paper} sx={{}}>
            <Table
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Unit Name
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Payment Type
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Received Amount
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Remaining Amount
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Discount Amount
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Net Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {singleLead?.LeadUnitDetails?.map((Won, i) => {
                  return (
                    <TableRow key={i} size="small">
                      <TableCell size="small" align="left">
                        {Won?.UnitName}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {Won?.PaymentType}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {Won?.ReceivedAmount?.toLocaleString()}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {Won?.RemainingAmount?.toLocaleString()}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {Won?.DiscountAmount?.toLocaleString()}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {Won?.NetAmount?.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {singleLead?.LeadUnitDetails?.length > 0 ? null : (
                  <TableRow sx={{ width: "100%", textAlign: "center" }}>
                    <Typography>No records Found</Typography>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Paper>
  );
}

export default Won;
Won.propTypes = {
  singleLead: PropTypes.any,
};
