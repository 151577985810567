import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import PropTypes from "prop-types";
import AppTooltip from "../../../../Utils/AppTooltip";
import {
  fetchSectionsCategoriesData,
  getSectionsCategories,
} from "../../../../Redux/Slice/Settings/CategorySlice/CategorySlice";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppConfirmationDialog from "../../../AppConfirmationDialog/AppConfirmationDialog";
import { DeleteCategoryApi } from "../../../../Api/Settings/CategoryApis/CategoryApis";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";

function Category({ roles }) {
  const { header } = useStyles();
  const { totalRows } = useContext(PageNumberContext);
  const [pagesize, setpagesize] = useState(totalRows);
  const [rows, setrows] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setsnackBarData } = useContext(SnackBarContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { AllSectionsCategoriesData, status } = useSelector(
    getSectionsCategories
  );

  useEffect(() => {
    dispatch(fetchSectionsCategoriesData());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Leads Description" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns =
    roles?.some((role) => role === "Category_Edit") ||
    roles?.some((role) => role === "Category_Delete")
      ? [
          {
            field: "id",
            headerName: "Sr",
            headerClassName: header,
            width: 50,
          },
          {
            field: "categoryName",
            headerName: "Category Name",
            headerClassName: header,
            width: 250,
          },
          {
            field: "action",
            headerName: "Action",
            headerClassName: header,
            width: 120,
            renderCell: (params) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {roles?.some((role) => role === "Category_Edit") && (
                    <AppTooltip title="Edit">
                      <IconButton
                        onClick={() => {
                          navigate("/settings/inventory/category/create", {
                            state: params.row,
                          });
                        }}
                      >
                        <EditIcon
                          fontSize="small"
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                          }}
                        />
                      </IconButton>
                    </AppTooltip>
                  )}

                  {roles?.some((role) => role === "Category_Delete") && (
                    <AppTooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setSelectedCategoryId(params.row?.categoryId);
                          setShowConfirmationDialog(true);
                        }}
                      >
                        <DeleteForeverIcon
                          fontSize="small"
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                          }}
                        />
                      </IconButton>
                    </AppTooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : [
          {
            field: "id",
            headerName: "Sr",
            headerClassName: header,
            width: 50,
          },
          {
            field: "categoryName",
            headerName: "Category Name",
            headerClassName: header,
            width: 250,
          },
        ];

  useEffect(() => {
    let arr = [];
    if (AllSectionsCategoriesData) {
      AllSectionsCategoriesData?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          categoryId: item?.CategoryId,
          categoryName: item?.CategoryName,
        });
      });
    }
    setrows(arr);
  }, [AllSectionsCategoriesData]);

  const closeConfirmationDialog = () => {
    setSelectedCategoryId(null);
    setShowConfirmationDialog(false);
  };

  const handleDeleteCategory = async () => {
    setLoading(true);
    const response = await DeleteCategoryApi(selectedCategoryId);
    setLoading(false);
    setsnackBarData(response.snackBarData);

    if (response?.data?.IsSuccess) {
      dispatch(fetchSectionsCategoriesData());
      closeConfirmationDialog();
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 77px)",
          overflowX: "visible",
          backgroundColor: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pagesize}
          onPageSizeChange={(newPage) => {
            setpagesize(newPage);
          }}
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => CustomNoRowsOverlay(),
          }}
          disableSelectionOnClick
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
        {status === "loading" ? <Loader /> : null}
      </Box>

      <AppConfirmationDialog
        open={showConfirmationDialog}
        onDeny={closeConfirmationDialog}
        onConfirm={() => {
          handleDeleteCategory();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Category"
        title={`Are you sure, you want to Delete Category?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />
    </>
  );
}

export default Category;
Category.propTypes = {
  roles: PropTypes.array,
};

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
