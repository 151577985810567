import React, { useState, useContext,useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { fetchLeadsSingle } from "../../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";
import {
  // AddRemarksLeadsApi,
  DeleteRemarksApi,
  UpdateStatusApi,
} from "../../../../../Api/LeadsApi/LeadsRemarksApi";
import {
  fetchNotificationsbyUser,
} from "../../../../../Redux/Slice/Notifications/GetNotification";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddRemarksModal from "../../../LeadsTable/AddRemarksModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Loader from "../../../../Loader/Loader";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import AppConfirmationDialog from "../../../../AppConfirmationDialog/AppConfirmationDialog";
import DoneIcon from "@mui/icons-material/Done";

function AllRemarks({ singleLead, roles }) {
  const [EditRemark, setEditedRemarks] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [DeleteRemark, setDeleteRemark] = useState(null);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [EditModal, SetEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("roles", roles);
  const { setsnackBarData } = useContext(SnackBarContext);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNotificationsbyUser());
  }, [dispatch]);
  const deleteRemarks = async (id) => {
    const response = await DeleteRemarksApi(id);
    if (response.data.IsSuccess) {
      setshowConfirmationIcons(false);
      setLoading(false);
      setShowLoading(true);
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
      setShowLoading(false);
      dispatch(fetchNotificationsbyUser());
    }
    setsnackBarData(response.snackBarData);
    setShowLoading(false);
  };

  const handleUpdateStatus = async (Remark) => {
    const response = await UpdateStatusApi(Remark.Id);
    if (response.data.IsSuccess) {
      setLoading(false);
      setShowLoading(true);
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
      setShowLoading(false);
    }
    setsnackBarData(response.snackBarData);
    setShowLoading(false);
  };
  return (
    <>
      <Box sx={{ p: 1 }}>
        <TableContainer
          component={Paper}
          sx={{ maxWidth: "100%", overflowX: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  Sr#
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#f5f5f5",
                    maxWidth: "300px",
                  }}
                >
                  Remarks
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  Priority
                </TableCell>
                {/* <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  Status
                </TableCell> */}
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  Mark Done
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  Action
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#f5f5f5",
                    paddingTop: "25px",
                  }}
                >
                  {roles?.some((role) => role === "Remarks_Create") && (
                    <Tooltip title="Add New">
                      <AddCircleOutlineIcon
                        sx={{
                          cursor: "pointer",

                          color: (theme) => theme?.palette?.primary?.green,
                        }}
                        onClick={() => {
                          SetEditModal(true);
                        }}
                        fontSize="small"
                      />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {singleLead?.LeadRemark?.map((Remark, index) => (
                <TableRow key={Remark.Id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{Remark.Title}</TableCell>
                  <TableCell sx={{ maxWidth: "300px" }}>
                    {Remark.Remarks}
                  </TableCell>
                  <TableCell>{Remark.Priority}</TableCell>
                  {/* <TableCell>{Remark.Status}</TableCell> */}
                  <TableCell>
                    {moment(Remark.CreatedOn).format("MMMM D, YYYY")}
                  </TableCell>
                  <TableCell>
                    {roles?.some((role) => role === "Remarks_Edit") && (
                      <>
                        {Remark?.Status === "Read" ? (
                          "Read"
                        ) : (
                          <IconButton
                            sx={{ marginLeft: "10px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedRemark(Remark);
                              setShowDoneModal(true);
                            }}
                          >
                            <DoneIcon
                              sx={{
                                color: (theme) =>
                                  theme?.palette?.primary?.green,
                              }}
                              fontSize="small"
                            />
                          </IconButton>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      {roles?.some((role) => role === "Remarks_Edit") && (
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => {
                              setEditedRemarks(Remark);
                              SetEditModal(true);
                            }}
                            disabled={Remark?.Status === "Read"}
                          >
                            <EditIcon
                              sx={{
                                color: (theme) =>
                                  Remark?.Status === "Read"
                                    ? theme.palette.action.disabled
                                    : theme?.palette?.primary?.edit,
                              }}
                              fontSize="small"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {roles?.some((role) => role === "Remarks_Delete") && (
                        <Tooltip title="delete">
                          <IconButton>
                            <DeleteForeverIcon
                              sx={{
                                cursor: "pointer",
                                color: (theme) =>
                                  theme?.palette?.primary?.delete,
                              }}
                              onClick={() => {
                                setshowConfirmationIcons(true);
                                setDeleteRemark(Remark?.Id);
                              }}
                              fontSize="small"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {showLoading && <Loader />}

      <AddRemarksModal
        leadId={singleLead?.Id}
        EditRemark={EditRemark}
        handleClosed={() => {
          SetEditModal(false);
          setEditedRemarks(null);
        }}
        showModal={EditModal}
        refreshLeadsPage={() => {
          dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
        }}
      />
      <AppConfirmationDialog
        open={showDoneModal}
        onDeny={() => {
          setShowDoneModal(false);
          setSelectedRemark(null);
        }}
        onConfirm={() => {
          setLoading(true);
          handleUpdateStatus(selectedRemark);
        }}
        loading={loading}
        iconContainerColor="#ebf9f9"
        icon={
          <CheckCircleIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.primary?.main }}
          />
        }
        dialogTitle="Mark Done"
        title={`Are you sure, you want to mark as done?`}
        successText="Yes"
        successBtnColor="primary"
        cancelText="No"
      />

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setDeleteRemark(null);
          setshowConfirmationIcons(false);
        }}
        loading={loading}
        onConfirm={() => {
          setLoading(true);
          deleteRemarks(DeleteRemark);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Remark"
        title={`Are you sure, you want to Delete Remark?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

AllRemarks.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.any,
};

export default AllRemarks;
