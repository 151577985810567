import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  UserPerformance: [],
  UserLeads: [],
  UserActivities: [],
  UserWonLeads: [],
  UserTargets: [],
  GroupPerformance: [],
  Profits: [],
  SalesReport:[],

  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status3t: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status5: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status4: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status6: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status7: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
  IsLogin: true,
};

export const fetchUserPerformance = createAsyncThunk(
  "/Reports/UserPerformanceReport",
  async (date) => {
    const response = await axiosInstance.get(
      `/Reports/UserPerformanceReport?from=${date?.from}&to=${date?.to}&groupId=${date?.groupId?date?.groupId:""}`
    );
    return response;
  }
);
export const fetchUserLeads = createAsyncThunk(
  "/Reports/UserLeadReport",
  async (data) => {
    const response = await axiosInstance.get(
      `/Reports/UserLeadReport?from=${data?.from}&to=${data?.to}&leadSourceId=${data?.leadSourceId}&userId=${data?.userId}`
    );
    return response;
  }
);
export const fetchUserActivities = createAsyncThunk(
  "/Reports/UserActivityReport",
  async (data) => {
    const response = await axiosInstance.get(
      `/Reports/UserActivityReport?from=${data?.from}&to=${data?.to}&activityType=${data?.type}&userId=${data?.userId}`
    );
    return response;
  }
);
export const fetchUserWonLeads = createAsyncThunk(
  "/Reports/WonLeadPaymentReport",
  async (data) => {
    const response = await axiosInstance.get(
      `/Reports/WonLeadPaymentReport?from=${data?.from}&to=${data?.to}&userId=${data?.userId}`
    );
    return response;
  }
);
export const fetchUserTargets = createAsyncThunk(
  "/Reports/UserTargetReport",
  async (data) => {
    const response = await axiosInstance.get(
      `/Reports/UserTargetReport?userId=${data?.userId}&projectId=${data?.projectId}`
    );
    return response;
  }
);
export const fetchGroupPerformance = createAsyncThunk(
  "/Reports/GroupPerformanceReport",
  async () => {
    const response = await axiosInstance.get(`/Reports/GroupPerformanceReport`);
    return response;
  }
);
//////////
export const fetchsalesreport = createAsyncThunk(
  "Reports/SaleReport",
  async (date) => {
    const response = await axiosInstance.get(
      `/Reports/SaleReport?from=${date?.from}&to=${date?.to}`
    );
    return response;
  }
);



export const fetchProfits = createAsyncThunk(
  "/Reports/ProfitReport",
  async (data) => {
    const response = await axiosInstance.get(
      `/Reports/ProfitReport?userId=${data?.userId}&projectId=${data?.projectId}&from=${data?.from}&to=${data?.to}`
    );
    return response;
  }
);
const ReportSlice = createSlice({
  name: "Reports",
  initialState,
  reducers: {
    ReportsAdded: {
      reducer(state, action) {
        state.UserPerformance?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchUserPerformance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserPerformance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserPerformance = action.payload.data.Data;
      })
      .addCase(fetchUserPerformance.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchUserLeads.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchUserLeads.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserLeads = action.payload.data.Data;
      })
      .addCase(fetchUserLeads.rejected, (state, action) => {
        state.error = action.error.message;
        state.status1 = "failed";
      })
      .addCase(fetchUserActivities.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchUserActivities.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserActivities = action.payload.data.Data;
      })
      .addCase(fetchUserActivities.rejected, (state, action) => {
        state.error = action.error.message;
        state.status2 = "failed";
      })
      .addCase(fetchUserWonLeads.pending, (state) => {
        state.status3 = "loading";
      })
      .addCase(fetchUserWonLeads.fulfilled, (state, action) => {
        state.status3 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserWonLeads = action.payload.data.Data;
      })
      .addCase(fetchUserWonLeads.rejected, (state, action) => {
        state.error = action.error.message;
        state.status3 = "failed";
      })
      .addCase(fetchUserTargets.pending, (state) => {
        state.status4t = "loading";
      })
      .addCase(fetchUserTargets.fulfilled, (state, action) => {
        state.status4t = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserTargets = action.payload.data.Data;
      })
      .addCase(fetchUserTargets.rejected, (state, action) => {
        state.error = action.error.message;
        state.status4t = "failed";
      })
      .addCase(fetchGroupPerformance.pending, (state) => {
        state.status5 = "loading";
      })
      .addCase(fetchGroupPerformance.fulfilled, (state, action) => {
        state.status5 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.GroupPerformance = action.payload.data.Data;
      })
      .addCase(fetchGroupPerformance.rejected, (state, action) => {
        state.error = action.error.message;
        state.status5 = "failed";
      })
      /////////
      .addCase(fetchsalesreport .pending, (state) => {
        state.status7 = "loading";
      })
      .addCase(fetchsalesreport .fulfilled, (state, action) => {
        state.status7 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        
        state.SalesReport = action.payload.data.Data;
      })
      .addCase(fetchsalesreport .rejected, (state, action) => {
        state.error = action.error.message;
        state.status7 = "failed";
      })
      /////

      




      .addCase(fetchProfits.pending, (state) => {
        state.status6 = "loading";
      })
      .addCase(fetchProfits.fulfilled, (state, action) => {
        state.status6 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Profits = action.payload.data.Data;
      })
      .addCase(fetchProfits.rejected, (state, action) => {
        state.error = action.error.message;
        state.status6 = "failed";
      });
  },
});

export const getReportsDetails = (state) => state.Reports;

export const { ReportsAdded } = ReportSlice.actions;

export default ReportSlice.reducer;
