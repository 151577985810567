import React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import logo from '../../Assets/logo.png';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
const useStyle = makeStyles((theme) => {
    return {
        Container: {
            display: "flex",
            width: "100%",
            height: "100vh",
            // backgroundColor:"red",
            justifyContent: "center",
            alignItems: "center",
        },
        loginBox: {
            width: "100%",
            maxWidth: "450px",
            display: "flex",
            flexDirection: "column",
            gap: "20px"

            // backgroundColor:"green",

        },
        imgBox: {
            display: "flex", flexDirection: "column", width: "100%", alignItems: "center", padding: "10px"
        },
        loginBut: {
            width: "100%",
            backgroundColor: theme.palette.primary.main,
            fontSize:"x-large !important",
            color:theme.palette.primary.contrastText,
            textTransform:"capitalize !important",
            '&:hover': {
                opacity: theme.palette.action.hoverOpacity
              },
        },
        paperBox: {
            width: "100%", padding: "20px", display: "flex", gap: "20px", flexDirection: "column"
        }
    };
});
function ForgotPassword() {
    const { Container, loginBox, imgBox, loginBut, paperBox } = useStyle();
    const navigate=useNavigate();
    const initialValues = {
        email: "",
      };
      const ValidationSchema = Yup.object().shape({
        email: Yup.string()
          .email("Please Enter A Valid Email Address")
          .required("Please Enter Email"),

      });
      const handleSubmit = async (values, resetForm, ) => {

        // setSubmitting(true);
        console.log(values);
        formik.setSubmitting(false);
        resetForm();
      };
      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ValidationSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
          handleSubmit(values, resetForm, setSubmitting);
        }
      });
  return (
    <Box className={Container}>
    <Box className={loginBox}>

        <Box className={imgBox} >
            <img style={{width:"200px"}} src={logo} alt="logo" />
            <Typography sx={{ color: (theme) => theme.palette.grey[700] }} variant='body'>One Perfect Solution </Typography>
        </Box>
        <Paper elevation={3} sx={{padding:"30px 20px"}}>
        <form onSubmit={formik.handleSubmit}>
            <Box className={paperBox} >
                <Typography variant='h5' sx={{ fontWeight: "bold", color: (theme) =>  theme.palette.grey[800] }}>Forgot Password?</Typography>
                <Box>
                    <Typography>Email</Typography>
                    <TextField  size='medium' fullWidth   name="email"  onChange={formik.handleChange}          
                        error={Boolean(formik.errors.email) && Boolean(formik.touched.email)}
                  helperText={Boolean(formik.errors.email) && formik.errors.email} />
                    <Typography variant='small' onClick={()=>navigate("/")} sx={{ color: (theme) => theme.palette.blue.main,cursor:"pointer" }}>Back to login</Typography>
                </Box>

                <Button type='submit' className={loginBut} sx={{ color: (theme)=>theme.palette.primary.contrastText }} >Send Reset Password Email</Button>
            </Box>
</form>

        </Paper>
    </Box>
</Box>
  );
}

export default ForgotPassword;