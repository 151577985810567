import { Box, Divider, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

const useStyles = makeStyles(() => {
  return {
    totalBox: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
    },
    contentBox: {
      display: "flex",
      gap: "10px",
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: "start",
    },
    circle: {
      width: "10px",
      height: "10px",
      borderRadius: "100%",
    },
  };
});
function LatestWon({ DashboardData }) {
  const { contentBox, totalBox, circle } = useStyles();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "33%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
      id="dashboard-top-leads"
    >
      <Paper elevation={3} sx={{ height: "350px" }}>
        <Typography sx={{ padding: "15px", fontWeight: "bold" }}>
          Latest Won Leads
        </Typography>
        <Divider sx={{ width: "100%" }} />
        {DashboardData?.TopWonLeadData?.length > 0 ? (
          <Box
            sx={{
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              height: "289px",
              overflow: "scroll",
              [theme.breakpoints.down("md")]: { gap: "30px" },
            }}
          >
            {DashboardData?.TopWonLeadData?.map((item, i) => {
              return (
                <>
                  <Box>
                    <Box key={i} className={contentBox}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flex: "1",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          onClick={() => {
                            navigate("/leads/view", { state: item?.Id });
                          }}
                          sx={{ color: "#0e90d9", cursor: "pointer" }}
                        >
                          {item?.Title} {`(${item?.LeadValue})`}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", flex: "1", flexWrap: "wrap" }}
                      >
                        <Typography>
                          {moment(item?.CreatedAt).format("DD-MMM-YYYY")}
                        </Typography>
                      </Box>
                      <Box
                        className={totalBox}
                        sx={{ display: "flex", flex: "1", flexWrap: "wrap" }}
                      >
                        <Box
                          sx={{
                            backgroundColor:
                              item?.Name === "Lost"
                                ? "red"
                                : item?.Name === "Won"
                                ? "#53c41a"
                                : "#0e90d9",
                          }}
                          className={circle}
                        ></Box>
                        <Typography>{item?.Name}</Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ width: "100%" }} />
                  </Box>
                </>
                // </Box>
              );
            })}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "60%",
              display: "flex",
              gap: "10px",
              padding: "10px",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            {CustomNoRowsOverlay("No Data Available")}
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default LatestWon;
LatestWon.propTypes = {
  DashboardData: PropTypes.any,
};
