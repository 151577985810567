import React, { useEffect, useState } from "react";
import {
  Box,
  // Button,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { fetchLeadsSingle } from "../../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
import theme from "../../../../../theme";
import CallIcon from "@mui/icons-material/Call";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteLeadActivityApi } from "../../../../../Api/LeadsApi/DeleteActivityApi";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppConfirmationDialog from "../../../../AppConfirmationDialog/AppConfirmationDialog";
import ActivityDoneModal from "../../../../Activities/ActivityDoneModal";
import ActivityInfoModal from "../../../../Activities/ActivityInfoModal";

const useStyle = makeStyles(() => {
  return {
    notesubboxMain: {
      display: "flex",
      gap: "40px",
      width: "100%",
      padding: "0px 10px 0px 20px",
    },
    iconlineMain: {
      width: "2px",
      minHeight: "100%",
      // backgroundColor: theme.palette.primary.main,
      border: `1px dashed ${theme.palette.primary.main}`,
      position: "relative",
    },
    iconBox: {
      width: "40px",
      height: "40px",
      border: `1px solid ${theme?.palette.primary.main}`,
      transform: "translate(-50%,0%)",
      position: "absolute",
      top: "0%",
      left: "50%",
      color: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white !important",
    },
    noteBox: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      padding: "10px",
      width: "100%",
    },
    scheduleBox: {
      display: "flex",
      gap: "5px",
    },
    scheduled: {
      fontSize: "15px !important",
      color: theme.palette.primary.main,
    },
  };
});
function PlannedCalls({ singleLead, roles }) {
  const {
    // plannedBox,
    notesubboxMain,
    iconlineMain,
    iconBox,
    noteBox,
    scheduleBox,
    scheduled,
  } = useStyle();
  const [showModal, setshowModal] = useState(false);
  const [callLeads, setcallLeads] = useState([]);
  const [loading, setloading] = useState(false);
  const [callLeadsRow, setcallLeadsRow] = useState(null);
  const [plannedCallRow, setplannedCallRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activityRow, setactivityRow] = useState(null);
  const [activityInfoModal, setactivityInfoModal] = useState(false);
  const [opens, setOpens] = useState(false);
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  useEffect(() => {
    let arr = [];
    singleLead?.leadActivity?.map((calls) => {
      if (calls?.Type === "Call" && calls?.StatusDone === false) {
        arr.push(calls);
      }
    });
    setcallLeads(arr);
  }, [singleLead]);
  const handleClick = (event, call) => {
    setAnchorEl(event.currentTarget);
    setplannedCallRow(call);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setshowModal(true);
  };

  const handleClosed = () => {
    setshowModal(false);
  };
  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleCloseds = () => {
    setOpens(false);
  };

  const deleteCalls = async () => {
    setloading(true);
    const response = await DeleteLeadActivityApi(plannedCallRow?.ActivtyId);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setplannedCallRow(null);
      setloading(false);
      handleClosed();
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
    }
    setloading(false);
  };

  return (
    <>
      <Box>
        {callLeads?.map((call, i) => {
          let date2 = moment(call?.ScheduleFrom?.split("T")[0])?.format(
            "DD-MM-YYYY"
          );
          let time2 = moment(
            call?.ScheduleFrom?.split("T")[1]?.split("Z")[0],
            "hh:mm A"
          )?.format("hh:mm A");

          let schFrom = date2?.concat(" ")?.concat(time2);

          return (
            <Box key={i} className={notesubboxMain}>
              <Box className={iconlineMain}>
                <Box className={iconBox}>
                  <CallIcon sx={{}} />
                </Box>
              </Box>
              <Box sx={{ width: "100%", paddingBottom: "10px" }}>
                <Paper key={i} elevation={3} className={noteBox}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {call?.Title}
                    </Typography>
                    <MoreVertIcon
                      onClick={
                        // e.stopPropagation();
                        (e) => {
                          if (roles?.some((role) => role === "Leads_Edit")) {
                            handleClick(e, call);
                            setcallLeadsRow(call);
                          }
                        }
                      }
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                        display:
                          singleLead?.Status === "Won" ||
                          singleLead?.Status === "Lost"
                            ? "none"
                            : "",
                      }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          handleClickOpen();
                        }}
                      >
                        Mark as Done
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClose();
                          navigate("/edit/activities", { state: callLeadsRow });
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          handleClickOpens();
                          // setnotesRow(note);
                        }}
                      >
                        Remove
                      </MenuItem>
                      <MenuItem
                        key={i}
                        onClick={() => {
                          setactivityInfoModal(true);
                          setactivityRow(call);
                        }}
                      >
                        View Detail
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box className={scheduleBox}>
                    <Typography className={scheduled}>
                      Call scheduled at
                    </Typography>
                    <Typography className={scheduled}>{schFrom}</Typography>
                    {/* <Typography
                      sx={{ fontWeight: "bold" }}
                      className={scheduled}
                    >
                      To
                    </Typography>
                    <Typography className={scheduled}>{schTo}</Typography> */}
                  </Box>
                  <Box sx={{ backgroundColor: "#fff9c4", padding: "10px" }}>
                    <Typography>{call?.Description}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      Added on:{" "}
                      {moment(call?.CreatedAt).format("Do MMMM YYYY, h:mm a")}
                    </Typography>
                    <Typography sx={{ cursor: "pointer", color: "#0e90d9" }}>
                      {call?.FullName}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
          );
        })}
      </Box>

      {/* <Dialog
        open={showModal}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to <b>{callLeadsRow?.Title}</b> mark as done ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={handleClosed}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  doneCallLead();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}

      {/* <Dialog
        open={opens}
        onClose={handleCloseds}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete <b>{plannedCallRow?.Title}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={handleCloseds}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  deleteCalls();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}

      {showModal && (
        <ActivityDoneModal
          showModal={showModal}
          setShowModal={setshowModal}
          selectedRow={plannedCallRow}
          setselectedRow={setplannedCallRow}
          leadId={singleLead?.Id}
        />
      )}
      {activityInfoModal && (
        <ActivityInfoModal
          activityInfoModal={activityInfoModal}
          setactivityInfoModal={setactivityInfoModal}
          setactivityRow={setactivityRow}
          activityRow={activityRow}
        />
      )}

      <AppConfirmationDialog
        open={opens}
        onDeny={() => {
          handleCloseds();
        }}
        onConfirm={() => {
          deleteCalls();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Call"
        title={`Are you sure you want to Delete "${plannedCallRow?.Title}"?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />
    </>
  );
}

export default PlannedCalls;
PlannedCalls.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
