import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  // fetchOrganizationPopulate,
  getOrganizationDetails,
} from "../../../../Redux/Slice/OrganizationSlice/OrganizationSlice";
// import Cookies from "universal-cookie";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
  fetchPersonsPopulate,
  getPersonsDetails,
} from "../../../../Redux/Slice/PersonSlice/PersonSlice";
import Loader from "../../../Loader/Loader";
import { ReactSelectTheme } from "../../../../Utils/ReactSelectTheme";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});
function PersonFilterDrawer({
  drawerOpen,
  setdrawerOpen,
  filterVal,
  setfilterVal,
  setallPersons,
  setcount,
  rows,
  handleChangeVal,
  searchRefreshData,
  setTotalCount,
}) {
  const { contentBox, fieldBox } = useStyles();
  const { OrganizationPopulate } = useSelector(getOrganizationDetails);
  const { Persons, status } = useSelector(getPersonsDetails);
  const [tags, setTags] = useState([]);
  const { PersonsPopulate } = useSelector(getPersonsDetails);
  const dispatch = useDispatch();
  console.log("tags", tags);

  useEffect(() => {
    if (PersonsPopulate) {
      let arr = [];
      PersonsPopulate?.Tags?.map((tag) => {
        arr.push({ value: tag?.TagId, label: tag?.Name });
      });
      setTags(arr);
    }
  }, [PersonsPopulate]);
  useEffect(() => {
    dispatch(fetchPersonsPopulate());
  }, [dispatch]);
  // const cookies = new Cookies();
  // useEffect(() => {
  //   dispatch(fetchOrganizationPopulate());
  // }, [dispatch]);
  const handleClose = () => {
    setdrawerOpen(!open);
  };
  const handleRemoveAll = () => {
    for (const value in filterVal) {
      filterVal[value] = "";
    }
    setcount(0);
  };
  return (
    <React.Fragment>
      {status == "loading" && <Loader />}

      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  setTotalCount(Persons?.TotalRecords);
                  setallPersons(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            <Box>
              <Typography>Name</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="name"
                  variant="outlined"
                  size="small"
                  value={filterVal?.name}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Name"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      name: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Email</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="email"
                  variant="outlined"
                  size="small"
                  value={filterVal?.email}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Email"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      email: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Contact</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="contact"
                  variant="outlined"
                  size="small"
                  value={filterVal?.contact}
                  type="number"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Contact"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      contact: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            {/* <Box>
              <Typography>City</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="city"
                  variant="outlined"
                  size="small"
                  value={filterVal?.city}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="City"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      city: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box> */}

            <Box>
              <Typography>Tag</Typography>
              <Box className={fieldBox}>
                <Select
                  size="small"
                  sx={{ width: "100%" }}
                  styles={ReactSelectTheme(false)}
                  name="tag"
                  value={filterVal?.tag || null}
                  onChange={(e) => {
                    handleChangeVal(e);
                    console.log(e.target.value);
                  }}
                  placeholder="Select Tags"
                >
                  {tags?.map((tag, idx) => (
                    <MenuItem key={idx} value={tag?.value}>
                      {tag?.label}
                    </MenuItem>
                  ))}
                </Select>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      tag: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>

            {sessionStorage.getItem("BusinessType") === "RealState" ? null : (
              <Box>
                <Typography>Organization Name</Typography>
                <Box className={fieldBox}>
                  <Select
                    name="organization"
                    variant="outlined"
                    size="small"
                    value={filterVal?.organization}
                    select
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    placeholder="Organization Name"
                    className="input_border"
                    onChange={(e) => {
                      handleChangeVal(e);
                    }}
                  >
                    <MenuItem value="">select</MenuItem>
                    {OrganizationPopulate?.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item?.Name}>
                          {item?.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* <TextField
                                    name="organization"
                                    variant="outlined"
                                    size='small'
                                    value={filterVal?.organization}
                                    select
                                    sx={{ width: "100%" }}
                                    autoComplete="off"
                                    placeholder="Organization Name"
                                    className="input_border"
                                    onChange={(e) => { handleChangeVal(e); }}
                                >
                                    <MenuItem value=''>select</MenuItem>
                                    <MenuItem value='org1'>org1</MenuItem>
                                    <MenuItem value='org2'>org2</MenuItem>
                                </TextField> */}
                  <CloseIcon
                    onClick={() => {
                      setfilterVal({
                        ...filterVal,
                        organization: "",
                      });
                    }}
                    fontSize="small"
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Button
                variant="outlined"
                color="success"
                size="small"
                startIcon={<AutorenewIcon fontSize="small" />}
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleRemoveAll();
                  handleClose();
                  searchRefreshData("refresh");
                }}
              >
                Refresh
              </Button>

              <Button
                variant="contained"
                size="small"
                startIcon={<SearchIcon fontSize="small" />}
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleClose();
                  searchRefreshData("search");
                }}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default PersonFilterDrawer;
PersonFilterDrawer.propTypes = {
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  setallPersons: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
  setTotalCount: PropTypes.func,
  searchRefreshData: PropTypes.func,
};
