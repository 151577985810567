import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
// import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import ActivityDoneModal from "../../../Activities/ActivityDoneModal";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      alignItems: "flex-start",
    },
    formContainer2: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
function ActivityForm({
  formik,
  // LeadsPersons,
  callOptions,
  messageOptions,
  emailOptions,
  meetingOptions,
  convertDateTimeToMinutes,
  getCurrentTime,
  LeadsUsers,
  currentDate,
  formattedDatetime,
  LeadsAffliate,
  LeadsActivityType,
  leadData,
  customer,
  // setTime,
  // handleLogo,
  // logoError,
  // handleDelImg,
  // logo,
  activityModal,
  handleSubmit,
  showDoneModal,
  setShowDoneModal,
}) {
  const { formContainer, formContainer2 } = useStyle();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={activityModal ? formContainer2 : formContainer}>
        <FormControl fullWidth>
          <Typography>Title</Typography>
          <TextField
            name="title"
            value={formik.values?.title}
            size={"small"}
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              Boolean(formik.errors.title) && Boolean(formik.touched.title)
            }
            helperText={
              Boolean(formik.errors.title) &&
              Boolean(formik.touched.title) &&
              formik.errors.title
            }
          />
        </FormControl>
        <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
          <FormControl fullWidth>
            <Typography>Type</Typography>
            <TextField
              name="type"
              value={formik.values?.type}
              size={"small"}
              select
              fullWidth
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue("subType", "");
              }}
              onBlur={formik.handleBlur}
              error={
                Boolean(formik.errors.type) && Boolean(formik.touched.type)
              }
              helperText={
                Boolean(formik.errors.type) &&
                Boolean(formik.touched.type) &&
                formik.errors.type
              }
            >
              <MenuItem value="">Select Type</MenuItem>
              {LeadsActivityType?.map((type, i) => {
                return (
                  <MenuItem key={i} value={type?.Name}>
                    {type?.Name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
          <FormControl fullWidth>
            <Typography>Sub Type</Typography>
            <TextField
              name="subType"
              value={formik.values?.subType}
              size={"small"}
              select
              fullWidth
              onChange={(e) => {
                formik.handleChange(e);
                if (
                  e.target.value === "Connected" ||
                  e.target.value === "Meeting Done"
                ) {
                  formik.setFieldValue("scheduleTo", "");
                  formik.setFieldValue("scheduleFrom", "");
                }
              }}
              onBlur={formik.handleBlur}
              error={
                Boolean(formik.errors.subType) &&
                Boolean(formik.touched.subType)
              }
              helperText={
                Boolean(formik.errors.subType) &&
                Boolean(formik.touched.subType) &&
                formik.errors.subType
              }
            >
              <MenuItem selected value="">
                Select Sub Type
              </MenuItem>
              {formik.values.type === "Call"
                ? callOptions?.map((type, i) => {
                    return (
                      <MenuItem key={i} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })
                : formik.values.type === "Meeting"
                ? meetingOptions?.map((type, i) => {
                    return (
                      <MenuItem key={i} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })
                : formik.values.type === "Email"
                ? emailOptions?.map((type, i) => {
                    return (
                      <MenuItem key={i} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })
                : formik.values.type === "Message"
                ? messageOptions?.map((type, i) => {
                    return (
                      <MenuItem key={i} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })
                : null}
            </TextField>
          </FormControl>
        </Box>

        <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
          <Box sx={{ width: "50%" }}>
            <FormControl fullWidth>
              <Typography>Date</Typography>
              <TextField
                name="scheduleFrom"
                type="date"
                size={"small"}
                value={formik?.values?.scheduleFrom}
                onChange={
                  formik.handleChange

                  // formik?.setFieldValue("scheduleFrom", moment(e?._d).format("YYYY-MM-DD").concat("T").concat(moment(e?._d).format("hh:mm:ss")).concat("Z"));
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: moment().subtract(1, "days").format("YYYY-MM-DD"), // Set min to yesterday
                    max:
                      formik.values.subType === "Connected" ||
                      formik.values.subType === "Meeting Done"
                        ? moment().format("YYYY-MM-DD")
                        : undefined,
                  },
                }}
                error={
                  Boolean(formik.errors.scheduleFrom) &&
                  Boolean(formik.touched.scheduleFrom)
                }
                helperText={
                  Boolean(formik.errors.scheduleFrom) &&
                  Boolean(formik.touched.scheduleFrom) &&
                  formik.errors.scheduleFrom
                }
              />

              {/* <TextField
                  name="scheduleFrom"
                  value={formik.values?.scheduleFrom}
                  placeholder="From"
                  type={"date"}
                  size={"small"}
                  fullWidth
                  inputProps={{
                    min: `${moment().add(0, "day").format("YYYY-MM-DD")}`,
                  }}
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.scheduleFrom) &&
                    Boolean(formik.touched.scheduleFrom)
                  }
                  helperText={
                    Boolean(formik.errors.scheduleFrom) &&
                    formik.errors.scheduleFrom
                  }
                /> */}
            </FormControl>
          </Box>
          <Box sx={{ width: "50%" }}>
            <FormControl fullWidth>
              <Typography>Time</Typography>
              <TextField
                value={formik?.values?.scheduleTo}
                name="scheduleTo"
                onChange={
                  formik.values.subType === "Connected" ||
                  formik.values.subType === "Meeting Done"
                    ? (e) => {
                        const selectedTime = e.target.value;
                        const currentTime = getCurrentTime();
                        const selectedDate = formik.values.scheduleFrom;
                        const selectedMinutes = convertDateTimeToMinutes(
                          `${selectedDate} ${selectedTime}`
                        );
                        const currentMinutes =
                          convertDateTimeToMinutes(currentTime);

                        if (selectedMinutes > currentMinutes) {
                          // formik.handleChange(e);
                          formik.setFieldError(
                            "scheduleTo",
                            "Selected time can not be greater than current time"
                          );
                        } else if (selectedMinutes <= currentMinutes) {
                          formik.handleChange(e);
                        }
                      }
                    : formik.handleChange
                }
                onBlur={formik.handleBlur}
                type="time"
                size={"small"}
                error={
                  Boolean(formik.errors.scheduleTo) &&
                  Boolean(formik.touched.scheduleTo)
                }
                helperText={
                  Boolean(formik.errors.scheduleTo) &&
                  Boolean(formik.touched.scheduleTo) &&
                  formik.errors.scheduleTo
                }
              />

              {/* <TextField
                  name="scheduleTo"
                  placeholder="To"
                  type={"date"}
                  value={formik.values?.scheduleTo}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  inputProps={{
                    min: `${moment().add(1, "day").format("YYYY-MM-DD")}`,
                  }}
                  error={
                    Boolean(formik.errors.scheduleTo) &&
                    Boolean(formik.touched.scheduleTo)
                  }
                  helperText={
                    Boolean(formik.errors.scheduleTo) &&
                    formik.errors.scheduleTo
                  }
                /> */}
            </FormControl>
          </Box>
        </Box>
        <FormControl fullWidth>
          <Typography>Location</Typography>
          <TextField
            name="location"
            value={formik.values?.location}
            size={"small"}
            fullWidth
            onChange={formik.handleChange}
          />
        </FormControl>
        <FormControl fullWidth>
          <Typography>Decsrption</Typography>
          <TextField
            name="description"
            value={formik.values?.description}
            multiline
            rows={4}
            size={"small"}
            fullWidth
            onChange={formik.handleChange}
          />
        </FormControl>
        {LeadsAffliate?.AffiliateId && (
          <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
            <Box sx={{ width: "50%" }}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Typography>Is Affiliate</Typography>
                <Checkbox
                  checked={formik.values.isAffiliate}
                  name="isAffiliate"
                  onChange={(event) => {
                    const { checked } = event.target;
                    formik.setFieldValue("isAffiliate", checked);
                    formik.setFieldValue(
                      "activityPersonParticipants",
                      checked
                        ? {
                            Id: LeadsAffliate?.AffiliateId,
                            Name: LeadsAffliate?.AffiliateName,
                          }
                        : {
                            Id: leadData?.PersonId || customer?.PersonId,
                            Name: leadData?.PersonName || customer?.Name,
                          }
                    );
                  }}
                />
              </FormControl>
            </Box>
          </Box>
        )}
        <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
          <Box sx={{ width: "50%" }}>
            <FormControl fullWidth>
              <Typography>Users</Typography>
              <Autocomplete
                multiple
                name="activityUserParticipants"
                //   options={["hjehje"]}
                options={LeadsUsers}
                getOptionLabel={(option) => option.FullName}
                size={"small"}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                value={formik.values?.activityUserParticipants}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={option.FullName}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(__, value) => {
                  formik.setFieldValue("activityUserParticipants", value);
                  // formik.values?.date=e.target.value
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ width: "50%" }}>
            {!formik.values.isAffiliate && (
              <>
                <Typography>Customer</Typography>
                <TextField
                  disabled
                  sx={{ width: "100%" }}
                  name="activityPersonParticipants"
                  size={"small"}
                  value={formik.values?.activityPersonParticipants?.Name || ""}
                />
              </>
            )}
            {formik.values.isAffiliate && (
              <>
                <Typography>Affiliate</Typography>
                <TextField
                  disabled
                  sx={{ width: "100%" }}
                  name="activityPersonParticipants"
                  size={"small"}
                  value={formik.values?.activityPersonParticipants?.Name || ""}
                />
              </>
            )}
          </Box>
        </Box>
        {formik?.values?.type !== "Email" &&
          formik.values?.type !== "Message" &&
          formattedDatetime &&
          moment(formattedDatetime).isBefore(currentDate) && (
            <>
              {/* <FormControl fullWidth>
                <Typography>Duration (hh:mm:ss)</Typography>
                <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
                  <TextField
                    sx={{ textAlign: "left", input: { color: "black" } }}
                    fullWidth
                    size="small"
                    name="hrs"
                    type="number"
                    placeholder="Hours"
                    onBlur={formik.handleBlur}
                    value={formik.values?.hrs}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setTime(
                        e.target.value,
                        formik.values?.mins,
                        formik.values?.seconds
                      );
                    }}
                    variant="outlined"
                    error={
                      Boolean(formik.touched.duration) &&
                      Boolean(formik.errors.duration)
                    }
                    helperText={
                      Boolean(formik.touched.duration) && formik.errors.duration
                    }
                  />
                  <Typography variant="h5">:</Typography>
                  <TextField
                    sx={{ textAlign: "left", input: { color: "black" } }}
                    fullWidth
                    size="small"
                    name="mins"
                    type="number"
                    placeholder="Minutes"
                    onBlur={formik.handleBlur}
                    value={formik.values?.mins}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setTime(
                        formik.values?.hrs,
                        e.target.value,
                        formik.values?.seconds
                      );
                    }}
                    variant="outlined"
                    error={
                      Boolean(formik.touched.duration) &&
                      Boolean(formik.errors.duration)
                    }
                    helperText={
                      Boolean(formik.touched.duration) && formik.errors.duration
                    }
                  />
                  <Typography variant="h5">:</Typography>
                  <TextField
                    sx={{ textAlign: "left", input: { color: "black" } }}
                    placeholder="Seconds"
                    fullWidth
                    size="small"
                    name="seconds"
                    type="number"
                    onBlur={formik.handleBlur}
                    value={formik.values?.seconds}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setTime(
                        formik.values?.hrs,
                        formik.values?.mins,
                        e.target.value
                      );
                    }}
                    variant="outlined"
                    error={
                      Boolean(formik.touched.duration) &&
                      Boolean(formik.errors.duration)
                    }
                    helperText={
                      Boolean(formik.touched.duration) && formik.errors.duration
                    }
                  />
                </Box>
              </FormControl>

              <FormControl fullWidth error={logoError}>
                <Typography>File</Typography>
                <TextField
                  id="file-input"
                  sx={{ textAlign: "left", input: { color: "black" } }}
                  fullWidth
                  size="small"
                  name="FileUrl"
                  type="file"
                  error={logoError}
                  InputProps={{
                    inputProps: {
                      accept: "image/*",
                    },
                  }}
                  // accept={formik.values?.Type==="Image"?"image/*":".pdf, .doc, .docx"}
                  onChange={handleLogo}
                  variant="outlined"
                />
                <FormHelperText>
                  {logoError ? "Please Select Image" : ""}
                </FormHelperText>
              </FormControl>
              {logo && (
                <Box
                  sx={{
                    position: "relative",
                    //   height: "20%",
                    width: "50%",
                    borderRadius: "5px",
                    border: "1px solid rgb(214, 214, 214)",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer !important",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <IconButton
                    color="error"
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                    }}
                    onClick={handleDelImg}
                  >
                    <CancelIcon />
                  </IconButton>
                  <img
                    src={logo?.url}
                    alt="Image"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              )} */}
              {showDoneModal && (
                <ActivityDoneModal
                  showModal={showDoneModal}
                  setShowModal={setShowDoneModal}
                  formSubmit={handleSubmit}
                  values={formik.values}
                  activityForm
                />
              )}
            </>
          )}
        {activityModal ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Box>
                {formik.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button type="submit" variant="contained">
                    <Typography>Save</Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        ) : formik.isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: "white !important",
            }}
            type="submit"
          >
            Save
          </Button>
        )}
      </Box>
    </form>
  );
}

export default ActivityForm;

ActivityForm.propTypes = {
  formik: PropTypes.any,
  LeadsActivityType: PropTypes.any,
  LeadsPersons: PropTypes.any,
  callOptions: PropTypes.any,
  messageOptions: PropTypes.any,
  emailOptions: PropTypes.any,
  meetingOptions: PropTypes.any,
  getCurrentTime: PropTypes.any,
  convertDateTimeToMinutes: PropTypes.any,
  LeadsUsers: PropTypes.any,
  formattedDatetime: PropTypes.any,
  currentDate: PropTypes.any,
  setTime: PropTypes.any,
  handleLogo: PropTypes.any,
  logoError: PropTypes.any,
  handleDelImg: PropTypes.any,
  logo: PropTypes.any,
  activityModal: PropTypes.any,
  handleSubmit: PropTypes.any,
  setShowDoneModal: PropTypes.any,
  showDoneModal: PropTypes.any,
  leadData: PropTypes.any,
  LeadsAffliate: PropTypes.any,
  customer: PropTypes.any,
};
