import { Box, Typography } from "@mui/material";
import React from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Person2Icon from "@mui/icons-material/Person2";
import { useNavigate } from "react-router-dom";
import { useDrag } from "react-dnd";

const useStyles = makeStyles(() => {
  return {
    mainBox: {
      display: "flex",
      gap: "30px",
      height: "calc(100vh - 120px)",
      overflow: "auto",
      width: "100%",
      maxWidth: "fit-content !important",
      paddingTop: "10px",
      //  " ::-webkit-scrollbar":{width:"3px",  height:"5px"}
    },
    paperBox: {
      minWidth: "270px !important",
      height: "47rem",
      overflow: "auto",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },

    cardsTop: {
      padding: "10px",
      display: "flex",
      gap: "10px",
      flexDirection: "column",
    },
    cardsIconBox: {
      display: "flex",
      gap: "5px",
      alignItems: "center",
    },
    iconBoxTypo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});
export default function CardBox({ lead, roles, mainBoxIdx }) {
  const { cardsIconBox, iconBoxTypo } = useStyles();
  const navigate = useNavigate();
  console.log(mainBoxIdx);
  // const myitem = { name: "ibr" };
  const [{ isDragging }, dragRef] = useDrag({
    type: "BOX",

    item: () => ({
      lead,
    }),

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box
      ref={dragRef}
      className="cardBox"
      sx={{
        backgroundColor: "#ffd0d6",
        opacity: isDragging ? "0.5" : "1",
        padding: "10px",
      }}
    >
      <Box className={iconBoxTypo}>
        <Typography sx={{ fontWeight: "bold" }}>{lead?.Title}</Typography>
        <Box
          className="iconhoverEffect"
          sx={{
            transform: "scale(0)",
            display: "flex",
            gap: "7px",
          }}
        >
          {roles?.some((role) => role === "Leads_View") && (
            <VisibilityIcon
              onClick={() => {
                navigate("/leads/view", {
                  state: { id: lead?.Id },
                });
              }}
              fontSize="small"
              sx={{
                color: (theme) => theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          )}

          <OpenWithIcon
            fontSize="small"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
        </Box>
      </Box>
      <Box className={cardsIconBox}>
        <Person2Icon
          fontSize="small"
          sx={{ color: (theme) => theme.palette.primary.main }}
        />
        <Typography>{lead?.personName}</Typography>
      </Box>
      <Box className={cardsIconBox}>
        <MonetizationOnIcon
          sx={{ color: (theme) => theme.palette.primary.main }}
          fontSize="small"
        />
        <Typography>
          {lead?.LeadValue ? Math.round(lead?.LeadValue)?.toLocaleString() : ""}
        </Typography>
      </Box>
    </Box>
  );
}
CardBox.propTypes = {
  lead: PropTypes.any,
  moveCard: PropTypes.any,
  index: PropTypes.any,
  roles: PropTypes.any,
  sourceMainBoxIdx: PropTypes.any,
  handleCardDrop: PropTypes.any,
  mainBoxIdx: PropTypes.any,
};
