import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PropTypes from "prop-types";
import LeadNotes from "./LeadNotes";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "40px",
      alignItems: "center",
      // width:"100%"
    },
    noteboxMain: {
      display: "flex",
      flexDirection: "column",
      //   gap: "10px",
      padding: "20px",
    },
  };
});
function Notes({ singleLead, roles }) {
  const { formContainer, noteboxMain } = useStyle();

  return (
    <>
      {singleLead?.leadNote?.length > 0 ? (
        <Paper elevation={3}>
          <Box className={noteboxMain}>
            <LeadNotes
              singleLead={singleLead}
              roles={roles}
            />
          </Box>
        </Paper>
      ) : (
        <Paper elevation={3}>
          <Box className={formContainer}>
            <Typography>You have no notes.</Typography>
          </Box>
        </Paper>
      )}
    </>
  );
}

export default Notes;
Notes.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
