import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Loader from "../../Components/Loader/Loader";
import {
  fetchOutBox,
  getOutBoxDetails,
} from "../../Redux/Slice/Email/OutBoxSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OutBoxFilterDrawer from "../../Components/Email/OutBoxFilterDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function OutBox({ roles }) {
  const { header } = useStyles();
  const [pagesize, setpagesize] = useState(5);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { OutBox, status } = useSelector(getOutBoxDetails);
  const [allOutBox, setallOutBox] = useState([]);
  const [showLoading, setshowLoading] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState({
    from: "",
    subject: "",
    createdate: "",
    enddate: "",
  });
  const [count, setcount] = useState(0);
  console.log(rows);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge
                badgeContent={count}
                color="primary"
              >
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    dispatch(fetchOutBox());
  }, [dispatch]);
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        console.log(value);
        sum = sum + 1;
      }
    }
    setcount(sum);
    console.log(sum);
  }, [filterVal]);
  useEffect(() => {
    let rowData = [];
    if (OutBox) {
      OutBox?.map((data, i) => {
        rowData.push({
          id: i + 1,
          from: `Aiksol${i + 1}`,
          subject: `math${i + 1}`,
          createddate: `02-04-201${i + 1}`,
          icon: `a${i + 1}`,
        });
      });
    }
    setrows(rowData);
    setallOutBox(rowData);
  }, [OutBox]);
  const deleteOutBox = async (id) => {
    setshowLoading(true);
    console.log(id);
    // let res = await DeleteTagApi(id);
    // setshowLoading(false);
    // setshowConfirmationIcons(false);
    // setsnackBarData(res.snackBarData);
    // if (res.data.IsSuccess) {
    //   dispatch(fetchDraft());
    // }
  };
  const handleEdit = (val) => {
    navigate("/settings/tags/create", { state: val });
    console.log(val);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: header },
    {
      field: "icon",
      headerName: <AttachmentIcon fontSize="small" />,
      width: 70,
      headerClassName: header,
      renderCell: (cellval) => {
        return (
          <Tooltip title={cellval.row.icon}>
            <IconButton
            //  onClick={() => { handleEdit(cellVal.row); }}
            >
              <AttachmentIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "from",
      headerName: "From",
      width: 150,
      editable: true,
      headerClassName: header,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 150,
      editable: true,
      headerClassName: header,
    },
    {
      field: "createddate",
      headerName: "Created Date",
      width: 150,
      editable: true,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      editable: true,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            {showConfirmationIcons && celval.row.ID === selectedId ? (
              <>
                <IconButton
                  key={i}
                  onClick={() => setshowConfirmationIcons(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                <IconButton
                  key={i}
                  onClick={() => deleteOutBox(celval.row.ID)}
                  color="primary"
                >
                  <DoneIcon fontSize="small" />
                </IconButton>{" "}
              </>
            ) : (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    key={i}
                    onClick={() => {
                      handleEdit(celval.row);
                    }}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    key={i}
                    onClick={() => {
                      setshowConfirmationIcons(true);
                      setselectedId(celval.row.ID);
                    }}
                  >
                    <DeleteForeverIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        );
      },
    },
  ];
  const handleChangeVal = (e) => {
    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value || "",
    });
  };
  useEffect(() => {
    console.log(rows);

    if (
      filterVal.from === "" &&
      filterVal.subject === "" &&
      filterVal.createdate === "" &&
      filterVal.enddate === ""
    ) {
      setallOutBox(rows);
    } else {
      console.log(filterVal.subject);
      let suggestions = [];
      let regex1 = new RegExp(filterVal.from, "i");
      let regex2 = new RegExp(filterVal.subject, "i");
      let regex3 = new RegExp(filterVal.createdate, "i");
      let regex4 = new RegExp(filterVal.enddate, "i");
      suggestions = rows?.filter(
        (val) =>
          (filterVal.from === "" || regex1.test(val.from)) &&
          (filterVal.subject === "" || regex2.test(val?.subject)) &&
          (filterVal.createdate === "" || regex3.test(val?.createdate)) &&
          (filterVal.enddate === "" || regex4.test(val?.createddate))
      );
      setallOutBox(suggestions);
    }
  }, [filterVal, rows]);
  return (
    <Box>
      <OutBoxFilterDrawer
        handleChangeVal={handleChangeVal}
        rows={rows}
        setcount={setcount}
        setallOutBox={setallOutBox}
        setfilterVal={setfilterVal}
        filterVal={filterVal}
        setdrawerOpen={setdrawerOpen}
        drawerOpen={drawerOpen}
      />

      <Box
        sx={{
          height: "calc(100vh - 77px)",
          width: "100%",
          overflowX: "visible",
          backgroundColor: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <DataGrid
          pageSize={pagesize}
          onPageSizeChange={(newPage) => {
            setpagesize(newPage);
          }}
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={allOutBox}
          columns={columns}
        />
        {status === "loading" || showLoading ? <Loader /> : null}
      </Box>
    </Box>
  );
}

export default OutBox;
OutBox.propTypes = {
  roles: PropTypes.array,
};
