// import { DateRangePicker } from "@mui/lab";
import { Box, Button, Skeleton, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
// import moment from "moment/moment";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActivitiesCard from "../../Components/Dashboard/ActivitiesCard";
import Customers from "../../Components/Dashboard/Customers";
import LeadsOverTime from "../../Components/Dashboard/LeadsOverTime";
import LeadsStarted from "../../Components/Dashboard/LeadsStarted";
import PiplinesCard from "../../Components/Dashboard/PiplinesCard";
import Products from "../../Components/Dashboard/Products";
import QuotesGraph from "../../Components/Dashboard/QuotesGraph";
import TopCustomers from "../../Components/Dashboard/TopCustomers";
import TopLeads from "../../Components/Dashboard/TopLeads";
import TopProducts from "../../Components/Dashboard/TopProducts";
// import Loader from "../../Components/Loader/Loader";
import {
  fetchDashboardData,
  getDashboardDetails,
} from "../../Redux/Slice/DashboardSlice/Dashboardslice";
import theme from "../../theme";
// import { AppContext } from "../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import { AppContext } from "../../Context/TourContext/TourContext";
// import Cookies from "universal-cookie";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
import LatestWon from "../../Components/Dashboard/LatestWon";
import { useNavigate } from "react-router-dom";
// import { Intro, Step } from 'intro.js-react';
// import { Hints, Steps } from 'intro.js-react';
// import 'intro.js/introjs.css';
// import {  DateRangePicker } from '@mui/lab';
// import { DateRange } from '@mui/lab/DateRangePicker';
// import AdapterMoment from '@mui/lab/AdapterMoment';

const useStyles = makeStyles(() => {
  return {
    mainBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    leadBox: {
      display: "flex",
      gap: "10px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});
function Dashboard() {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  // let businessType = cookies.get("BusinessType");

  let guide = sessionStorage.getItem("guide");
  let businessType = sessionStorage.getItem("BusinessType");

  const { mainBox, leadBox } = useStyles();
  const { DashboardData, status } = useSelector(getDashboardDetails);
  const currentDate = new Date(); // get current date

  const previousDate = new Date();
  previousDate.setDate(currentDate.getDate() - 30);
  const [from, setfrom] = useState(moment(previousDate).format("YYYY-MM-DD"));
  const [to, setto] = useState(moment(currentDate).format("YYYY-MM-DD"));
  const navigate = useNavigate();
  const { UserRoles, status1 } = useSelector((state) => state?.User);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchDashboardData({
        startDate: moment(previousDate).format("YYYY-MM-DD"),
        endDate: moment(currentDate).format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);
  const handleGet = () => {
    dispatch(fetchDashboardData({ startDate: from, endDate: to }));
  };
  // const [run, setRun] = useState(false);
  const { setState, state } = useContext(AppContext);

  // const handleClickStart = () => {
  //   console.log("started");
  //   setState({
  //     ...state,
  //     run: true,
  //     tourActive: true,
  //   });
  // };
  // console.log("state", state);
  // useEffect(() => {
  //   if (status === "succeeded") {
  //     handleClickStart();
  //   }
  // }, [status]);
  //   useEffect(() => {
  //   if (status === "succeeded") {
  //     setRun(true);
  //   }
  // }, [status]);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Dashboard");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  useEffect(() => {
    navigate(
      UserRoles?.some((role) => role === "Dashboard")
        ? "/dashboard"
        : UserRoles?.some((role) => role === "Leads")
        ? "/leads"
        : UserRoles?.some((role) => role === "Quotes")
        ? "/quotes"
        : UserRoles?.some((role) => role === "Activities")
        ? "/activities"
        : UserRoles?.some((role) => role === "Contacts")
        ? UserRoles?.some((role) => role === "Persons")
          ? "/contacts/persons"
          : "/contacts/organizations"
        : UserRoles?.some((role) => role === "Campaign")
        ? UserRoles?.some((role) => role === "Campaign_Campaigns")
          ? "/campaigns/campaign"
          : UserRoles?.some((role) => role === "Campaign_Templates")
          ? "/campaigns/templates"
          : "/campaigns/groups"
        : UserRoles?.some((role) => role === "Products")
        ? "/products"
        : UserRoles?.some((role) => role === "Chats")
        ? "/chats/facebookpage"
        : UserRoles?.some((role) => role === "Inventory")
        ? UserRoles?.some((role) => role === "Inventory_Unit")
          ? "/inventory/unit"
          : "/inventory/booked-unit"
        : UserRoles?.some((role) => role === "Inventory")
        ? "/inventory/unit"
        : UserRoles?.some((role) => role === "Settings")
        ? "/settings"
        : null
    );
  }, []);
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;

              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Dashboard"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="dashboard" className={mainBox}>
        {status === "loading" || status1 === "loading" ? (
          <>
            {/* <Loader /> */}
            <Box className={leadBox}>
              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />
              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />
              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />
            </Box>
            <Box className={leadBox}>
              {/* <EmailsCard DashboardData={DashboardData} /> */}
              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />

              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />
              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />
            </Box>
            <Box className={leadBox}>
              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />
              {/* <LeadsOverTime DashboardData={DashboardData} /> */}
              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />
              <Skeleton
                variant="rounded"
                sx={{
                  width: "33%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                  height: "350px",
                }}
              />
            </Box>
          </>
        ) : businessType !== "RealState" ? (
          <>
            <Box sx={{ display: "flex", justifyContent: "end", gap: "10px" }}>
              <TextField
                id="dashboard-from"
                label="From"
                InputLabelProps={{
                  shrink: true,
                }}
                value={from}
                onChange={(e) => {
                  setfrom(e.target.value);
                }}
                variant="outlined"
                size="small"
                type={"date"}
              />
              <TextField
                id="dashboard-to"
                // className="dashboard-to"
                label="To"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setto(e.target.value);
                }}
                value={to}
                variant="outlined"
                size="small"
                type={"date"}
                // className="to"
              />
              <Button
                id="dashboard-get"
                variant="contained"
                className="get"
                onClick={handleGet}
              >
                Get
              </Button>
            </Box>

            <Box className={leadBox}>
              <PiplinesCard DashboardData={DashboardData} />
              <TopLeads DashboardData={DashboardData} />
              <LeadsStarted DashboardData={DashboardData} />
            </Box>
            <Box className={leadBox}>
              {/* <EmailsCard DashboardData={DashboardData} /> */}
              <Products DashboardData={DashboardData} />

              <Customers DashboardData={DashboardData} />
              <TopCustomers DashboardData={DashboardData} />
            </Box>
            <Box className={leadBox}>
              <TopProducts DashboardData={DashboardData} />
              {/* <LeadsOverTime DashboardData={DashboardData} /> */}
              <ActivitiesCard DashboardData={DashboardData} />
              <QuotesGraph DashboardData={DashboardData} />
            </Box>
            <Box className={leadBox}></Box>
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "end", gap: "10px" }}>
              <TextField
                id="dashboard-from"
                label="From"
                InputLabelProps={{
                  shrink: true,
                }}
                value={from}
                onChange={(e) => {
                  setfrom(e.target.value);
                }}
                variant="outlined"
                size="small"
                type={"date"}
              />
              <TextField
                id="dashboard-to"
                // className="dashboard-to"
                label="To"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setto(e.target.value);
                }}
                value={to}
                variant="outlined"
                size="small"
                type={"date"}
                // className="to"
              />
              <Button
                id="dashboard-get"
                variant="contained"
                className="get"
                onClick={handleGet}
              >
                Get
              </Button>
            </Box>
            {DashboardData && (
              <>
                <Box className={leadBox}>
                  <PiplinesCard DashboardData={DashboardData} />
                  <TopLeads DashboardData={DashboardData} />
                  <LatestWon DashboardData={DashboardData} />
                </Box>
                <Box className={leadBox}>
                  {/* <EmailsCard DashboardData={DashboardData} /> */}
                  <LeadsStarted DashboardData={DashboardData} />

                  <LeadsOverTime DashboardData={DashboardData} />

                  <Customers DashboardData={DashboardData} />
                </Box>
                <Box className={leadBox}>
                  <TopCustomers DashboardData={DashboardData} />

                  <ActivitiesCard DashboardData={DashboardData} />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default Dashboard;

const steps = [
  {
    target: "#dashboard-from",
    content: (
      <>
        <p size="large">From Field</p>
        <p>
          Enter the starting date of the event or activity in the
          &apos;From&apos; date field. This represents when the event begins.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-to",
    content: (
      <>
        <p size="large">To Field</p>
        <p>
          Specify the ending date of the event or activity in the &apos;To&apos;
          date field. This indicates when the event concludes. Make sure the
          &apos;To&apos; date is equal to or later than the &apos;From&apos;
          date.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-get",
    content: (
      <>
        <p size="large">Get Button</p>
        <p>
          After entering the date range, click the &apos;Get&apos; button to
          retrieve relevant data. We will fetch information based on the
          specified &apos;From&apos; and &apos;To&apos; dates. Please ensure the
          dates are accurate before proceeding.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-pipeline",
    content: (
      <>
        <p size="large">Pipelines</p>
        <p>
          Track your lead management success effortlessly. Quickly view the
          completion status of each pipeline stage, understanding the number of
          steps completed out of the total. Stay on top of your sales journey
          with clear visualizations.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-top-leads",
    content: (
      <>
        <p size="large">Top Leads</p>
        <p>
          Explore your most promising leads at a glance with the Top Leads
          chart. This visual representation highlights the key leads making
          significant progress or displaying notable engagement. Quickly
          identify and prioritize your high-potential opportunities for focused
          and strategic actions. Use this chart to optimize your lead management
          and foster meaningful relationships.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-leads-started",
    content: (
      <>
        <p size="large">Leads Started</p>
        <p>
          Stay in tune with your lead generation momentum through the Leads
          Started chart. This visual representation offers a week-by-week
          breakdown of new leads initiated, providing insights into the trends
          and patterns of your lead acquisition efforts.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-product",
    content: (
      <>
        <p size="large">Products</p>
        <p>
          Dive into your product performance with the Products Chart. This
          visual representation offers a comprehensive overview of product
          sales, helping you identify top-performing items and understand
          overall trends.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-customer",
    content: (
      <>
        <p size="large">Customers</p>
        <p>
          Gain insights into customer acquisition trends with the Customers Per
          Week chart. This visual representation provides a weekly snapshot of
          new customers, allowing you to track the growth of your customer base.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-top-customer",
    content: (
      <>
        <p size="large">Top Customers</p>
        <p>
          Get a quick overview of your most valuable relationships with the Top
          Customers. This chart highlights the customers contributing
          significantly to your business.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-top-products",
    content: (
      <>
        <p size="large">Top Products</p>
        <p>
          Navigate product success effortlessly with the Top Products. This
          spotlights your best-performing products.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-lead-over-time",
    content: (
      <>
        <p size="large">Leads Over Time</p>
        <p>
          Visualize the lifecycle of your leads with the Leads Over Time chart.
          This dynamic representation illustrates the progression of leads,
          indicating their status over different periods. Easily identify trends
          in lead expiration or closure, empowering you to refine your
          engagement strategies and focus efforts where they matter most.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-activities",
    content: (
      <>
        <p size="large">Activities</p>
        <p>
          Stay on top of your tasks with the Current Activities section. This
          dynamic display provides a real-time overview of ongoing activities,
          ensuring you never miss a beat.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#dashboard-quotes",
    content: (
      <>
        <p size="large">Quotes</p>
        <p>
          Keep a pulse on your quoting activities with the Quotes Added Per Week
          chart. This visual representation offers insights into the frequency
          and trends of new quotes generated each week.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
