import React from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PropTypes from "prop-types";
import PlannedMeetings from "./PlannedMeetings";
import DoneMeetings from "./DoneMeetings";
import { useState } from "react";
import { useEffect } from "react";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      //   gap: "20px",
      padding: "20px",
      //   alignItems: "flex-start",
    },
    plannedBox: {
      display: "flex",
      //   flexDirection:"column",
      gap: "10px",
      width: "100%",
      alignItems: "center",
    },

    Border: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "20px",
    },
  };
});
function Meeting({ singleLead, roles }) {
  const { formContainer, plannedBox, Border } = useStyle();
  const [plannedMeetinglLeads, setplannedMeetinglLeads] = useState([]);
  const [doneMeetinglLeads, setdoneMeetinglLeads] = useState([]);
  useEffect(() => {
    let arr = [];
    let arr2 = [];
    singleLead?.leadActivity?.map((meeting) => {
      if (meeting?.Type === "Meeting" && meeting?.StatusDone === false) {
        arr.push(meeting);
      }
      if (meeting?.Type === "Meeting" && meeting?.StatusDone === true) {
        arr2.push(meeting);
      }
    });
    setplannedMeetinglLeads(arr);
    setdoneMeetinglLeads(arr2);
  }, [singleLead]);
  return (
    <Paper elevation={3}>
      <Box className={formContainer}>
        <Box className={Border}>
          <Box>
            <Box className={plannedBox}>
              <Typography
                sx={{ fontWeight: "bold", color: "rgb(84, 110, 122)" }}
              >
                Planned
              </Typography>
              <Box sx={{ width: "100%" }}>
                <Divider />
              </Box>
            </Box>
            <PlannedMeetings
              singleLead={singleLead}
              roles={roles}
            />
            {plannedMeetinglLeads?.length > 0 ? null : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "rgb(84, 110, 122)" }}>
                  You have no planned meetings.
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            <Box
              sx={{}}
              className={plannedBox}
            >
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", color: "rgb(84, 110, 122)" }}
                >
                  Done
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Divider />
              </Box>
            </Box>
            <DoneMeetings
              singleLead={singleLead}
              roles={roles}
            />
            {doneMeetinglLeads?.length > 0 ? null : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "rgb(84, 110, 122)" }}>
                  You have no done meetings.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {/* </Box> */}
      </Box>
    </Paper>
  );
}

export default Meeting;
Meeting.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
