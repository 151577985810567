// import Cookies from "universal-cookie";
import { axiosInstance } from "../../Utils/AxiosInstance";

// const cookies = new Cookies();

export const LoginApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/Login", data);
    console.log("/Auth/Login", response);

    if (response.data.IsSuccess) {
      // cookies.set("access_token", response.data.Token, {
      //   path: "/",
      // });
      // cookies.set("user", response.data.UserRoles, {
      //   path: "/",
      // });
      // cookies.set("LoginId", response.data.LoginId, {
      //   path: "/",
      // });

      sessionStorage.setItem("access_token", response.data.Token);
      sessionStorage.setItem("user", response.data.UserRoles);
      sessionStorage.setItem("LoginId", response.data.LoginId);
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
      const UserRoles = response?.data?.UserRoles;
      const navigate = UserRoles?.some((role) => role === "Dashboard")
        ? "/dashboard"
        : UserRoles?.some((role) => role === "Leads")
        ? "/leads"
        : UserRoles?.some((role) => role === "Quotes")
        ? "/quotes"
        : UserRoles?.some((role) => role === "Activities")
        ? "/activities"
        : UserRoles?.some((role) => role === "Contacts")
        ? UserRoles?.some((role) => role === "Persons")
          ? "/contacts/persons"
          : "/contacts/organizations"
        : UserRoles?.some((role) => role === "Campaign")
        ? UserRoles?.some((role) => role === "Campaign_Campaigns")
          ? "/campaigns/campaign"
          : UserRoles?.some((role) => role === "Campaign_Templates")
          ? "/campaigns/templates"
          : "/campaigns/groups"
        : UserRoles?.some((role) => role === "Products")
        ? "/products"
        : UserRoles?.some((role) => role === "Chats")
        ? "/chats/facebookpage"
        : UserRoles?.some((role) => role === "Inventory")
        ? UserRoles?.some((role) => role === "Inventory_Unit")
          ? "/inventory/unit"
          : "/inventory/booked-unit"
        : UserRoles?.some((role) => role === "Inventory")
        ? "/inventory/unit"
        : UserRoles?.some((role) => role === "Settings")
        ? "/settings"
        : "/";
      return { ...response, snackBarData, navigate };
    } else {
      snackBarData = {
        type: "error",
        message: response?.data?.Message,
        openToast: true,
      };
      return { ...response, snackBarData };
    }
  } catch (error) {
    console.log("error", error);
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error, snackBarData };
  }
};
