import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  FacebookPages: [],
  PagesPopulate: [],
  PublicLeadsPopulate: [],
  AllPublicLeads: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchFacebookPages = createAsyncThunk(
  "/Pages/getAllPages",
  async () => {
    const response = await axiosInstance.get(`/Pages/getAllPages`);
    return response;
  }
);
export const fetchPagesPopulate = createAsyncThunk(
  "/Pages/populate",
  async () => {
    const response = await axiosInstance.get(`/Pages/populate`);
    return response;
  }
);
export const fetchPublicLeadPopulate = createAsyncThunk(
  "/PublicLeads/populate",
  async () => {
    const response = await axiosInstance.get(`/PublicLeads/populate`);
    return response;
  }
);
export const fetchAllPublicLeads = createAsyncThunk(
  "/PublicLeads/getAllPublicLeads",
  async (type) => {
    const response = await axiosInstance.get(
      type === "RealState"
        ? "/PublicLeads/getAllPublicLeadUnit"
        : `/PublicLeads/getAllPublicLeads`
    );
    return response;
  }
);
const FacebookPageSlice = createSlice({
  name: "FacebookPageSlice",
  initialState,
  reducers: {
    TagsAdded: {
      reducer(state, action) {
        state.FacebookPages?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchFacebookPages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFacebookPages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.FacebookPages = action.payload.data.Data;
      })
      .addCase(fetchFacebookPages.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchPagesPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPagesPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.PagesPopulate = action.payload.data.Data;
      })
      .addCase(fetchPagesPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchPublicLeadPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPublicLeadPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.PublicLeadsPopulate = action.payload.data.Data;
      })
      .addCase(fetchPublicLeadPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAllPublicLeads.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPublicLeads.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AllPublicLeads = action.payload.data.Data;
      })
      .addCase(fetchAllPublicLeads.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getFacebookPageDetails = (state) => state.FacebookPageSlice;

export const { TagsAdded } = FacebookPageSlice.actions;

export default FacebookPageSlice.reducer;
