import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyle = makeStyles((theme) => {
  return {
    textContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    textAreaContainer: {
      display: "flex",
      //   justifyContent: "start",
      //   alignItems: "start",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
  };
});
function CampaignGroupsInfoModal({ showModal, handleClosed, group }) {
  const { textAreaContainer, textContainer } = useStyle();
  console.log("group", group);
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
          Campaign Group Info
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <Box className={textContainer}>
          <Box className={textAreaContainer}>
            <Typography variant="body1" fontWeight="bold">
              Name:
            </Typography>
            <Typography>{group?.name}</Typography>
          </Box>
          <Box className={textAreaContainer}>
            <Typography variant="body1" fontWeight="bold">
              Type:
            </Typography>
            <Typography>{group?.type}</Typography>
          </Box>
        </Box>
        <Typography variant="body1" fontWeight="bold">
          File Content:
        </Typography>
        {group?.file.map((data, index) => (
          <Chip sx={{ mr: 1 }} key={index} label={data} variant="outlined" />
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default CampaignGroupsInfoModal;

CampaignGroupsInfoModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  group: PropTypes.any,
};
