import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import PropTypes from "prop-types";
import { Divider,  InputAdornment,  TextField,  Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex", flexDirection: "column", gap: "10px",
    },
    fieldBox: {
      display: "flex", alignItems: "center", gap: "10px"
    }
  };
});

function ProductFilterDrawer({drawerOpen,setdrawerOpen,filterVal,setfilterVal,onTextChange,SKU,priceRef,quantityRef,
    setallProduct,setcount,rows,handleChangeVal,name,costRef}) {
        const { contentBox, fieldBox } = useStyles();
        const handleClose = () => {
          setdrawerOpen(!open);
        };
          const handleRemoveAll = () => {
            for (const value in filterVal) {
              filterVal[value] = '';
            }
            setcount(0);
          };
  return (
    <React.Fragment>
    <Drawer
      anchor={"right"}
      open={drawerOpen}
      onClose={handleClose}
      className="abc"
    >
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant='h6'>Filter</Typography>
          <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Typography sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}  onClick={() => { setallProduct(rows); handleRemoveAll(); }} >Remove All</Typography>
            <CloseIcon fontSize='small' sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
        </Box>
        <Divider sx={{ width: "100%", paddingTop: "10px", marginBottom: '10px' }} />
        <Box className={contentBox}>
        <Box>
                <Typography>Name</Typography>
                <Box className={fieldBox} >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: (theme) => theme.palette.grey.G2 }}
                          />
                        </InputAdornment>
                      ),
                    }}

                    name="name"
                    variant="outlined"
                    size='small'
                    value={filterVal?.name}
                    type="text"
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    placeholder="Name"
                    className="input_border"
                    inputRef={name}
                    onChange={(e) => { onTextChange(); handleChangeVal(e); }}
                  />
                  <CloseIcon onClick={() => {
                    name.current.value = '';
                    setfilterVal({
                      ...filterVal,
                      name: ''
                    });
                    onTextChange();

                  }} fontSize='small' sx={{ cursor: "pointer" }} />
                </Box>
              </Box>
              <Box>
                <Typography>Price</Typography>
                <Box className={fieldBox} >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: (theme) => theme.palette.grey.G2 }}
                          />
                        </InputAdornment>
                      ),
                    }}

                    name="price"
                    variant="outlined"
                    size='small'
                    value={filterVal?.price}
                    type="number"
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    placeholder="Price"
                    className="input_border"
                    inputRef={priceRef}
                    onChange={(e) => { onTextChange(); handleChangeVal(e); }}
                  />
                  <CloseIcon onClick={() => {
                    priceRef.current.value = '';
                    setfilterVal({
                      ...filterVal,
                      price: ''
                    });
                    onTextChange();

                  }} fontSize='small' sx={{ cursor: "pointer" }} />
                </Box>
              </Box>
              <Box>
                <Typography>Quantity</Typography>
                <Box className={fieldBox} >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: (theme) => theme.palette.grey.G2 }}
                          />
                        </InputAdornment>
                      ),
                    }}

                    name="quantity"
                    variant="outlined"
                    size='small'
                    value={filterVal?.quantity}
                    type="number"
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    placeholder="Quantity"
                    className="input_border"
                    inputRef={quantityRef}
                    onChange={(e) => { onTextChange(); handleChangeVal(e); }}
                  />
                  <CloseIcon onClick={() => {
                    quantityRef.current.value = '';
                    setfilterVal({
                      ...filterVal,
                      quantity: ''
                    });
                    onTextChange();

                  }} fontSize='small' sx={{ cursor: "pointer" }} />
                </Box>
              </Box>
        <Box>
                <Typography>SKU</Typography>
                <Box className={fieldBox} >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: (theme) => theme.palette.grey.G2 }}
                          />
                        </InputAdornment>
                      ),
                    }}

                    name="sku"
                    variant="outlined"
                    size='small'
                    value={filterVal?.sku}
                    type="text"
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    placeholder="SKU"
                    className="input_border"
                    inputRef={SKU}
                    onChange={(e) => { onTextChange(); handleChangeVal(e); }}
                  />
                  <CloseIcon onClick={() => {
                    SKU.current.value = '';
                    setfilterVal({
                      ...filterVal,
                      sku: ''
                    });
                    onTextChange();

                  }} fontSize='small' sx={{ cursor: "pointer" }} />
                </Box>
              </Box>
              <Box>
                <Typography>Cost</Typography>
                <Box className={fieldBox} >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: (theme) => theme.palette.grey.G2 }}
                          />
                        </InputAdornment>
                      ),
                    }}

                    name="cost"
                    variant="outlined"
                    size='small'
                    value={filterVal?.cost}
                    type="number"
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    placeholder="Cost"
                    className="input_border"
                    inputRef={costRef}
                    onChange={(e) => { onTextChange(); handleChangeVal(e); }}
                  />
                  <CloseIcon onClick={() => {
                    costRef.current.value = '';
                    setfilterVal({
                      ...filterVal,
                      cost: ''
                    });
                    onTextChange();

                  }} fontSize='small' sx={{ cursor: "pointer" }} />
                </Box>
              </Box>
       
          
        </Box>
      </Box>
    </Drawer>
  </React.Fragment>
  );
}

export default ProductFilterDrawer;
ProductFilterDrawer.propTypes = {
    drawerOpen: PropTypes.any,
    setdrawerOpen: PropTypes.any,
    filterVal: PropTypes.any,
    setfilterVal: PropTypes.any,
    onTextChange: PropTypes.any,
    SKU: PropTypes.any,
    setallProduct: PropTypes.any,
    setcount: PropTypes.any,
    rows: PropTypes.any,
    handleChangeVal: PropTypes.any,
    name: PropTypes.any,
    quantityRef: PropTypes.any,
    priceRef: PropTypes.any,
    costRef: PropTypes.any,
  };