import React, { useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  IconButton,
  FormHelperText,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HistoryIcon from "@mui/icons-material/History";
import AppTooltip from "../../Utils/AppTooltip";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { CreatePersonApi } from "../../Api/CreatePersonApi.jsx/CreatePersonApi";
import { useDispatch } from "react-redux";
import { fetchLeadsPopulate } from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import { FindCustomerLeadApi } from "../../Api/LeadsApi/FindCustomerLeadApi";

const ValidationSchema = Yup.object().shape({
  personId: Yup.string().required(`Please Select Customer`),
  newPersonName: Yup.string().when("addNewPerson", {
    is: (value) => value,
    then: () => Yup.string().required("Please Enter name"),
    otherwise: () => Yup.string(),
  }),
  newPersonPhone: Yup.string().when("addNewPerson", {
    is: (value) => value,
    then: () =>
      Yup.string()
        .min(9, "Invalid phone number")
        .required("Please Enter phone"),
    otherwise: () => Yup.string(),
  }),
});

function SelectCustomer({
  handleNext,
  leadsPopulate,
  selectedCustomer,
  setSelectedCustomer,
}) {
  const { formContainer } = useStyle();

  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const response = await FindCustomerLeadApi(values.personId);
    formik.setSubmitting(false);
    if (response?.data?.IsSuccess) {
      setSelectedCustomer(values.personId);
      handleNext();
    } else {
      setsnackBarData(response.snackBarData);
    }
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      personId: selectedCustomer || "",
      addNewPerson: false,
      newPersonName: "",
      newPersonDialCode: "",
      newPersonPhone: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleAddNewPerson = async (type) => {
    formik.setFieldTouched("newPersonName");
    formik.setFieldTouched("newPersonPhone");

    if (!formik.errors.newPersonName && !formik.errors.newPersonPhone) {
      formik.setSubmitting(true);
      let formData = new FormData();
      formData.append("name", formik.values.newPersonName);
      formData.append(
        `personContacts[0].dialCode`,
        formik.values.newPersonDialCode
      );
      formData.append(
        `personContacts[0].contactNumber`,
        formik.values.newPersonPhone
      );
      formData.append(`personContacts[0].description`, "Work");
      formData.append(`Type`, type);
      const response = await CreatePersonApi(formData);
      formik.setSubmitting(false);
      if (response?.data?.IsSuccess) {
        formik.setFieldValue("addNewPerson", false);
        formik.setFieldValue("newPersonName", "");
        formik.setFieldValue("newPersonDialCode", "");
        formik.setFieldValue("newPersonPhone", "");
        formik.setFieldValue("personId", response?.data?.Data);

        dispatch(fetchLeadsPopulate());
      }
      setsnackBarData(response.snackBarData);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: "20px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <FormControl fullWidth>
            <Typography>Customer</Typography>

            <Box sx={{ display: "flex", gap: "10px" }}>
              {formik.values.addNewPerson ? (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <FormControl sx={{ flex: 1 }} fullWidth>
                    <TextField
                      size={"small"}
                      fullWidth
                      placeholder="Name..."
                      name="newPersonName"
                      value={formik.values?.newPersonName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors?.newPersonName &&
                        formik.touched?.newPersonName
                      }
                      helperText={
                        formik.errors?.newPersonName &&
                        formik.touched?.newPersonName &&
                        formik.errors?.newPersonName
                      }
                    />
                  </FormControl>

                  <FormControl sx={{ flex: 1 }} fullWidth>
                    <PhoneInput
                      inputProps={{
                        name: "newPersonPhone",
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "40px",
                        borderColor:
                          formik.errors?.newPersonPhone &&
                          formik.touched?.newPersonPhone
                            ? "#d32f2f"
                            : "inherit",
                      }}
                      containerStyle={{
                        width: "100%",
                        height: "40px",
                      }}
                      country={"pk"}
                      value={formik.newPersonPhone}
                      onChange={(phone, data) => {
                        formik.setFieldValue(
                          "newPersonDialCode",
                          data.dialCode
                        );
                        formik.setFieldValue("newPersonPhone", phone);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors?.newPersonPhone &&
                      formik.touched?.newPersonPhone && (
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {formik.errors?.newPersonPhone}
                        </FormHelperText>
                      )}
                  </FormControl>

                  <Button
                    sx={{ alignSelf: "center" }}
                    variant="contained"
                    size="small"
                    onClick={() => handleAddNewPerson("Person")}
                  >
                    Add
                  </Button>
                </Box>
              ) : (
                <Autocomplete
                  options={leadsPopulate?.personData || []}
                  getOptionLabel={(option) => option?.Name}
                  renderOption={(props, option) => {
                    return (
                      <ListItem {...props} key={option.Id}>
                        <Typography>
                          {option?.Name} ({option?.ContactNumber})
                        </Typography>
                      </ListItem>
                    );
                  }}
                  sx={{ flex: 1 }}
                  size={"small"}
                  fullWidth
                  value={
                    leadsPopulate?.personData?.find(
                      (person) => person?.Id === formik.values.personId
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      formik.setFieldValue("personId", value?.Id);
                    } else {
                      formik.setFieldValue("personId", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="personId"
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors?.personId && formik.touched?.personId
                      }
                      helperText={
                        formik.errors?.personId && formik.touched?.personId
                          ? formik.errors?.personId
                          : ""
                      }
                    />
                  )}
                />
              )}
              <AppTooltip
                title={formik.values.addNewPerson ? "Add Existing" : "Add New"}
              >
                <IconButton
                  sx={{ alignSelf: "center" }}
                  size="small"
                  onClick={() => {
                    formik.setFieldValue(
                      "addNewPerson",
                      !formik.values.addNewPerson
                    );
                  }}
                >
                  {formik.values.addNewPerson ? (
                    <HistoryIcon fontSize="small" />
                  ) : (
                    <AddCircleIcon fontSize="small" />
                  )}
                </IconButton>
              </AppTooltip>
            </Box>
          </FormControl>

          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button onClick={handleNavigate}>
              <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
            </Button>

            {formik.isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.white.main,
                }}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

export default SelectCustomer;
SelectCustomer.propTypes = {
  handleNext: PropTypes.any,
  leadsPopulate: PropTypes.any,
  selectedCustomer: PropTypes.any,
  setSelectedCustomer: PropTypes.func,
};

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
});
