import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchusers,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { TransferLeadsApi } from "../../../Api/LeadsApi/TransferLeadsApi";

function TransferLeadsModal({
  showModal,
  handleClosed,
  selectedLeads,
  refreshLeadsPage,
}) {
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const { TransferUsers } = useSelector(getLeadsDetails);
  useEffect(() => {
    if (TransferUsers?.length === 0) dispatch(fetchusers());
  }, [dispatch, TransferUsers]);

  const ValidationSchema = Yup.object().shape({
    personName: Yup.array().min(1, "Please select user").required(""),
  });
  const initialValues = {
    personName: [],
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const idArr = values.personName.map((person) => ({
      userId: person.Id,
    }));

    const leadArr = selectedLeads.map((lead) => ({
      leadId: lead?.ID,
    }));

    const data = {
      transferUsers: idArr,
      transferLeads: leadArr,
    };

    const response = await TransferLeadsApi(data);

    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      // dispatch(fetchProducts());
      setSubmitting(false);
      resetForm();
      handleClosed();
      refreshLeadsPage();
    }

    setSubmitting(false);
  };
  const filteredTransferFromOptions = TransferUsers?.Data?.filter(
    (user) => user.Id !== formik.values.Firstuser
  );

  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            Transfer Selected Leads
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent style={{ width: "600px" }}>
          <FormControl fullWidth>
            <Box>
              <Typography>Transfer To</Typography>
              <Autocomplete
                multiple
                // limitTags={2}
                size="small"
                id="multiple-limit-tags-category"
                options={filteredTransferFromOptions || []}
                getOptionLabel={(option) => option?.FullName}
                isOptionEqualToValue={(option, value) =>
                  option?.Id === value?.Id
                }
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip
                      key={option.Id}
                      label={option?.FullName}
                      {...getTagProps({ index })}
                      style={{ backgroundColor: "#298989", color: "white" }}
                      deleteIcon={<CancelIcon style={{ color: "white" }} />}
                    />
                  ))
                }
                renderOption={(props, option) => (
                  <ListItem
                    {...props}
                    key={option?.Id}
                    disabled={formik.values.Firstuser === option.Id}
                  >
                    <Typography>{option?.FullName}</Typography>
                  </ListItem>
                )}
                listStyle={{ maxHeight: 200, overflow: "auto" }}
                sx={{ width: "100%" }}
                fullWidth
                name="personName"
                value={formik.values.personName}
                onChange={(event, value) =>
                  formik.setFieldValue("personName", value)
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="input_border"
                    error={
                      formik.touched.personName &&
                      Boolean(formik.errors.personName)
                    }
                    helperText={
                      formik.touched.personName && formik.errors.personName
                    }
                  />
                )}
              />
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            {formik.isSubmitting ? (
              <Box>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Button
                  sx={{
                    background: "#cc6666",
                    color: "white !important",
                    fontSize: "13px",
                    textTransform: "none",
                  }}
                  onClick={handleClosed}
                >
                  Cancel
                </Button>

                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: "white !important",
                    fontSize: "13px",
                    textTransform: "none",
                  }}
                  type="submit"
                  autoFocus
                >
                  Transfer
                </Button>
              </>
            )}
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default TransferLeadsModal;

TransferLeadsModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  selectedLeads: PropTypes.any,
  refreshLeadsPage: PropTypes.func,
};
