import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  Groups: [],
  GroupPopulate: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
  IsLogin: true,
};
export const fetchGroups = createAsyncThunk("/Groups", async () => {
  const response = await axiosInstance.get(`/Groups`);
  return response;
});
export const fetchGroupPopulate = createAsyncThunk(
  "/Groups/Populate",
  async () => {
    const response = await axiosInstance.get(`/Groups/Populate`);
    return response;
  }
);

const GroupsSlice = createSlice({
  name: "Groups",
  initialState,
  reducers: {
    GroupsAdded: {
      reducer(state, action) {
        console.log(action.payload);
        state.Groups?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchGroups.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Groups = action.payload.data.Data;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchGroupPopulate.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchGroupPopulate.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.GroupPopulate = action.payload.data.Data;
      })
      .addCase(fetchGroupPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status2 = "failed";
      });
  },
});

export const getGroupsDetails = (state) => state.Groups;

export const { GroupsAdded } = GroupsSlice.actions;

export default GroupsSlice.reducer;
