import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const AppContext = createContext();
const AppContextProvider = (props) => {
  const [state, setState] = useState({
    run: false,
    skip: [],
    // stepIndex: 0,
    // steps: [],
    tourActive: false,
  });

  return (
    <AppContext.Provider value={{ state, setState }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

AppContextProvider.propTypes = {
  children: PropTypes.any,
};
