import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Products: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchProducts = createAsyncThunk("/Product", async () => {
  const response = await axiosInstance.get(`/Product?type=Good`);
  console.log(response);
  return response;
});

const ProductSlice = createSlice({
  name: "Products",
  initialState,
  reducers: {
    ProductsAdded: {
      reducer(state, action) {
        state.Products?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Products = action.payload.data.Data;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getProductsDetails = (state) => state.Products;

export const { ProductsAdded } = ProductSlice.actions;

export default ProductSlice.reducer;
