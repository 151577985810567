import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  // CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ReactSelect from "react-select";
import theme from "../../../theme";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitPopulate,
  getUnitsDetails,
} from "../../../Redux/Slice/UnitSlice/UnitSlice";
import Loader from "../../Loader/Loader";
import { CreateUnitApi } from "../../../Api/UnitApi/CreateUnitApi";
import AddTagsModal from "./AddTagsModal";
import { ReactSelectTheme } from "../../../Utils/ReactSelectTheme";
// import { fetchProducts } from '../../../Redux/Slice/ProductSlice/ProductSlice';

// import Loader from '../../Loader/Loader';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function CreateUnit() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { UnitsPopulate, status1 } = useSelector(getUnitsDetails);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [projOptions, setProjOptions] = useState([]);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [catOptions, setCatOptions] = useState([]);

  const handleClosed = () => {
    setOpenTagModal(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUnitPopulate());
  }, [dispatch]);
  useEffect(() => {
    let val = [];
    let val1 = [];
    let val2 = [];

    UnitsPopulate?.Projects?.map((data) => {
      let obj = {
        label: data?.ProjectName,
        value: data?.ProjectId,
      };
      val.push(obj);
    });
    UnitsPopulate?.Sections?.map((data) => {
      let obj = {
        label: data?.SectionName,
        value: data?.SectionId,
      };
      val1.push(obj);
    });
    UnitsPopulate?.Categories?.map((data) => {
      let obj = {
        label: data?.CategoryName,
        value: data?.CategoryId,
      };
      val2.push(obj);
    });

    setCatOptions(val2);

    setProjOptions(val);
    setSectionOptions(val1);
  }, [UnitsPopulate]);
  const initialValues = {
    project: "",
    section: "",
    unit: "",
    lengthFeet: "",
    lengthInch: "",
    lengthTotal: "",
    widthFeet: "",
    widthInch: "",
    widthTotal: "",
    marla: "",
    sarsai: "",
    ratePer: "sqft",
    netSqft: "",
    netMarla: "",
    costRate: "",
    saleRate: "",
    totalCost: "",
    totalSale: "",
  };
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [tagUpdateIds, setTagUpdateIds] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("new")) {
      setOpenTagModal(true);
    } else {
      setTags(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };
  useEffect(() => {
    if (state) {
      handleEdit(state);
    }
  }, []);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "project",
        value:
          {
            label: values?.Project?.ProjectName,
            value: values?.Project?.ProjectId,
          } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "category",
        value:
          {
            value: values?.Category?.CategoryId,
            label: values?.Category?.CategoryName,
          } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "totalSale",
        value: values?.Price.toLocaleString() || "",
      },
    });
    formik.handleChange({
      target: {
        name: "totalCost",
        value: values?.TotalCost.toLocaleString() || "",
      },
    });
    formik.handleChange({
      target: {
        name: "saleRate",
        value: parseFloat(values?.RatePerMarla).toLocaleString() || "",
      },
    });
    formik.handleChange({
      target: {
        name: "costRate",
        value: parseFloat(values?.CostPerMarla).toLocaleString() || "",
      },
    });
    formik.handleChange({
      target: {
        name: "netMarla",
        value: parseFloat(values?.NetMarla).toLocaleString() || "",
      },
    });
    formik.handleChange({
      target: {
        name: "netSqft",
        value: parseFloat(values?.NetSqft).toLocaleString() || "",
      },
    });
    formik.handleChange({
      target: {
        name: "marla",
        value: Number(values?.Marla) || "",
      },
    });
    formik.handleChange({
      target: {
        name: "sarsai",
        value: values?.Sarsai || "",
      },
    });
    formik.handleChange({
      target: {
        name: "ratePer",
        value: values?.RatePerMarlaOrsqft?.toLowerCase() || "",
      },
    });
    formik.handleChange({
      target: {
        name: "widthTotal",
        value: parseFloat(values?.DimensionWidth).toLocaleString() || "",
      },
    });
    formik.handleChange({
      target: {
        name: "lengthTotal",
        value: parseFloat(values?.DimensionLength).toLocaleString(),
      },
    });
    formik.handleChange({
      target: {
        name: "unit",
        value: values?.UnitName,
      },
    });
    formik.handleChange({
      target: {
        name: "section",
        value:
          {
            label: values?.Section?.SectionName,
            value: values?.Section?.SectionId,
          } || "",
      },
    });
    let tags = [];
    let tagUpdate = [];
    values?.UnitTags?.map((tag) => {
      tags.push(tag?.Tag?.TagId);
      tagUpdate.push({ updateid: tag.UnitTagId, tagId: tag?.Tag?.TagId });
    });
    setTags(tags);
    setTagUpdateIds(tagUpdate);
  };
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let tagArr = [];
    if (state) {
      tags.forEach((tagId) => {
        tagArr.push({
          tagId: tagId,
        });
      });
      tagUpdateIds.forEach((update) => {
        // Find the corresponding tag in tagArr
        const tagToUpdate = tagArr.find((tag) => tag.tagId === update.tagId);
        // If tag is found, update its object
        if (tagToUpdate) {
          tagToUpdate.unitTagId = update.updateid;
        }
      });
    } else {
      tags.forEach((tagId) => {
        tagArr.push({
          tagId: tagId,
        });
      });
    }
    let data = {
      unitId: state?.ID || null,
      unitName: values.unit,
      dimensionLength: parseFloat(values.lengthTotal),
      dimensionWidth: parseFloat(values.widthTotal),
      sectionId: values.section.value,
      projectId: values.project.value,
      categoryId: values.category.value,
      price: Math.round(values.totalSale.replace(/,/g, "")),
      // dimensionUnitId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      // status: "string",
      marla: Number(values.marla),
      sarsai: values.sarsai,
      netMarla: values.netMarla,
      ratePerMarla: parseInt(values.saleRate.replace(/,/g, "")),
      costPerMarla: parseInt(values.costRate.replace(/,/g, "")),
      netSqft: parseFloat(values.netSqft.replace(/,/g, "")),
      ratePerMarlaOrsqft: values.ratePer === "marla" ? "Marla" : "Sqft",
      totalCost: Math.round(values.totalCost.replace(/,/g, "")),
      unitTags: tagArr,
    };
    const response = await CreateUnitApi(data);

    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      // dispatch(fetchProducts());
      setSubmitting(false);
      resetForm();
      navigate(-1);
    }
    setSubmitting(false);
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    unit: Yup.string().required("Please Enter Unit"),
    project: Yup.object().required("Please Select Project"),
    section: Yup.object().required("Please Select Section"),
    lengthTotal: Yup.string().required("Please Enter Total"),
    widthTotal: Yup.string().required("Please Enter Total"),
    netSqft: Yup.string().required("Please Enter Net Sqft"),
    netMarla: Yup.string().required("Please Enter Net Marla"),
    costRate: Yup.string().required("Please Enter Cost Rate"),
    saleRate: Yup.string().required("Please Enter Sale Rate"),
    marla: Yup.string().required("Please Enter Marla"),
    sarsai: Yup.string().required("Please Enter Sarsai"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const LengthCalculation = () => {
    let totallength =
      parseFloat(formik.values.lengthFeet) +
      parseFloat(formik.values.lengthInch) / 12;
    let totalwidth =
      parseFloat(formik.values.widthFeet) +
      parseFloat(formik.values.widthInch) / 12;

    totalwidth &&
      formik.setFieldValue("widthTotal", parseFloat(totalwidth).toFixed(4));
    totallength &&
      formik.setFieldValue("lengthTotal", parseFloat(totallength).toFixed(4));
    if (totallength && totalwidth) {
      let netsqft = parseFloat(
        parseFloat(totallength) * parseFloat(totalwidth)
      ).toLocaleString();
      formik.setFieldValue("netSqft", netsqft);

      let totalmarla = (parseFloat(totallength) * parseFloat(totalwidth)) / 270;
      formik.setFieldValue("netMarla", parseFloat(totalmarla).toFixed(4));

      if (formik.values.costRate && formik.values.ratePer) {
        let totalcost = Math.round(
          parseFloat(parseFloat(totallength) * parseFloat(totalwidth)) *
            parseFloat(formik.values.costRate.replace(/,/g, ""))
        ).toLocaleString();
        formik.setFieldValue("totalCost", totalcost);
      }

      if (formik.values.saleRate && formik.values.ratePer) {
        let plotNet = Math.round(
          parseFloat(parseFloat(totallength) * parseFloat(totalwidth)) *
            parseFloat(formik.values.saleRate.replace(/,/g, ""))
        ).toLocaleString();
        formik.setFieldValue("totalSale", plotNet);
      }
    }
  };
  function salerateonchange(e) {
    let currentval = parseInt(
      e.target.value.replace(/,/g, "")
    ).toLocaleString();
    formik.setFieldValue("saleRate", currentval);

    if (formik.values.ratePer === "marla") {
      let marla = formik.values.marla ? formik.values.marla : 0;
      let sarsai = formik.values.sarsai ? formik.values.sarsai : 0;
      // let marlasarsai = marla?.concat(".", sarsai, sarsai, sarsai, sarsai);
      let marlasarsai = `${marla}.${sarsai}${sarsai}${sarsai}${sarsai}`;

      let total =
        parseFloat(marlasarsai) * parseFloat(currentval.replace(/,/g, ""));
      let totalfinal = Math.round(total);
      let plotNet = totalfinal.toLocaleString();
      formik.setFieldValue("totalSale", plotNet);
    } else {
      let netsqft = parseFloat(formik.values.netSqft.replace(/,/g, ""));
      let plotNet = Math.round(
        parseFloat(netsqft) * parseFloat(currentval.replace(/,/g, ""))
      ).toLocaleString();
      formik.setFieldValue("totalSale", plotNet);
    }
  }
  function costrateonchange(e) {
    let currentval = parseInt(
      e.target.value.replace(/,/g, "")
    ).toLocaleString();
    formik.setFieldValue("costRate", currentval);
    if (formik.values.ratePer === "marla") {
      let marla = formik.values.marla ? formik.values.marla : 0;
      let sarsai = formik.values.sarsai ? formik.values.sarsai : 0;
      // let marlasarsai = marla?.concat(".", sarsai, sarsai, sarsai, sarsai);
      let marlasarsai = `${marla}.${sarsai}${sarsai}${sarsai}${sarsai}`;

      let total =
        parseFloat(marlasarsai) * parseFloat(currentval.replace(/,/g, ""));
      let totalfinal = Math.round(total);
      let totalcost = totalfinal.toLocaleString();
      formik.setFieldValue("totalCost", totalcost);
    } else {
      let netsqft = parseFloat(formik.values.netSqft.replace(/,/g, ""));
      let totalcost = Math.round(
        parseFloat(netsqft) * parseFloat(currentval.replace(/,/g, ""))
      ).toLocaleString();
      formik.setFieldValue("totalCost", totalcost);
    }
  }
  function radioonchange(e) {
    if (e.target.value === "marla") {
      let marla = formik.values.marla ? formik.values.marla : 0;
      let sarsai = formik.values.sarsai ? formik.values.sarsai : 0;
      let marlasarsai = marla + "." + sarsai + sarsai + sarsai + sarsai;
      let netmarlas = marlasarsai;

      formik.setFieldValue("netMarla", netmarlas);
    } else if (e.target.value === "sqft") {
      let dimenstionwidth = formik.values.widthTotal;
      let dimenstionlength = formik.values.lengthTotal;
      let totalmarla =
        (parseFloat(dimenstionwidth) * parseFloat(dimenstionlength)) / 270;
      let netmarlas = totalmarla.toFixed(4);
      formik.setFieldValue("netMarla", netmarlas);
    }
  }
  function changeNetMarla() {
    let enteredMarlas = formik.values.marla;
    let enteredSarsai = formik.values.sarsai;
    let marlaChecked = formik.values.ratePer;
    console.log("enteredMarlas", enteredMarlas);
    let cost = formik.values.costRate;
    let saleRate = formik.values.saleRate;

    if (marlaChecked === "marla") {
      let marlasarsai = `${enteredMarlas}.${enteredSarsai}${enteredSarsai}${enteredSarsai}${enteredSarsai}`;
      formik.setFieldValue("netMarla", marlasarsai);

      if (cost) {
        let totalcost = Math.round(
          parseFloat(marlasarsai) * parseFloat(cost.replace(/,/g, ""))
        ).toLocaleString();
        formik.setFieldValue("totalCost", totalcost);
      }

      if (saleRate) {
        let plotNet = Math.round(
          parseFloat(marlasarsai) * parseFloat(saleRate.replace(/,/g, ""))
        ).toLocaleString();
        formik.setFieldValue("totalSale", plotNet);
      }
    }
  }

  return (
    <Box className={container}>
      {status1 === "loading" ? <Loader /> : null}

      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/inventory/unit"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Unit
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state ? "Update Unit" : " Create Unit"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state ? "Update Unit" : " Create Unit"}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <Box display="flex" gap={1}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.project) &&
                    Boolean(formik.touched.project)
                  }
                >
                  <Typography>Project</Typography>
                  <ReactSelect
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.project) &&
                    //       Boolean(formik.touched.project)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.project) &&
                        Boolean(formik.touched.project)
                    )}
                    name="project"
                    options={projOptions}
                    value={formik.values.project}
                    onChange={(value) => {
                      formik.setFieldValue("project", value);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select Project"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.project) &&
                    Boolean(formik.touched.project)
                      ? formik.errors.project
                      : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.section) &&
                    Boolean(formik.touched.section)
                  }
                >
                  <Typography>Section</Typography>
                  <ReactSelect
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.section) &&
                    //       Boolean(formik.touched.section)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.section) &&
                        Boolean(formik.touched.section)
                    )}
                    name="section"
                    options={sectionOptions}
                    value={formik.values.section}
                    onChange={(value) => {
                      formik.setFieldValue("section", value);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select Section"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.section) &&
                    Boolean(formik.touched.section)
                      ? formik.errors.section
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box display="flex" gap={1}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.category) &&
                    Boolean(formik.touched.category)
                  }
                >
                  <Typography>Category</Typography>
                  <ReactSelect
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.category) &&
                    //       Boolean(formik.touched.category)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.category) &&
                        Boolean(formik.touched.category)
                    )}
                    name="category"
                    options={catOptions}
                    value={formik.values.category}
                    onChange={(value) => {
                      formik.setFieldValue("category", value);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select Category"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.category) &&
                    Boolean(formik.touched.category)
                      ? formik.errors.category
                      : ""}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                  <Typography>Unit No</Typography>
                  <TextField
                    name="unit"
                    type="text"
                    value={formik.values?.unit}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.unit) &&
                      Boolean(formik.touched.unit)
                    }
                    helperText={
                      Boolean(formik.errors.unit) &&
                      Boolean(formik.touched.unit) &&
                      formik.errors.unit
                    }
                  />
                </FormControl>
              </Box>
              <FormControl fullWidth>
                <Typography>Length</Typography>
                <Box display="flex" gap={1}>
                  <TextField
                    placeholder="feet"
                    name="lengthFeet"
                    value={formik.values?.lengthFeet}
                    size={"small"}
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={LengthCalculation}
                    error={
                      Boolean(formik.errors.lengthFeet) &&
                      Boolean(formik.touched.lengthFeet)
                    }
                    helperText={
                      Boolean(formik.errors.lengthFeet) &&
                      Boolean(formik.touched.lengthFeet) &&
                      formik.errors.lengthFeet
                    }
                  />
                  <TextField
                    onBlur={LengthCalculation}
                    placeholder="inch"
                    name="lengthInch"
                    value={formik.values?.lengthInch}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.lengthInch) &&
                      Boolean(formik.touched.lengthInch)
                    }
                    helperText={
                      Boolean(formik.errors.lengthInch) &&
                      Boolean(formik.touched.lengthInch) &&
                      formik.errors.lengthInch
                    }
                  />
                  <TextField
                    disabled
                    placeholder="Total"
                    name="lengthTotal"
                    value={formik.values?.lengthTotal}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.lengthTotal) &&
                      Boolean(formik.touched.lengthTotal)
                    }
                    helperText={
                      Boolean(formik.errors.lengthTotal) &&
                      Boolean(formik.touched.lengthTotal) &&
                      formik.errors.lengthTotal
                    }
                  />
                </Box>
              </FormControl>
              <FormControl fullWidth>
                <Typography>Width</Typography>
                <Box display="flex" gap={1}>
                  <TextField
                    placeholder="feet"
                    name="widthFeet"
                    value={formik.values?.widthFeet}
                    size={"small"}
                    fullWidth
                    onBlur={LengthCalculation}
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.widthFeet) &&
                      Boolean(formik.touched.widthFeet)
                    }
                    helperText={
                      Boolean(formik.errors.widthFeet) &&
                      Boolean(formik.touched.widthFeet) &&
                      formik.errors.widthFeet
                    }
                  />
                  <TextField
                    onBlur={LengthCalculation}
                    placeholder="inch"
                    name="widthInch"
                    value={formik.values?.widthInch}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.widthInch) &&
                      Boolean(formik.touched.widthInch)
                    }
                    helperText={
                      Boolean(formik.errors.widthInch) &&
                      Boolean(formik.touched.widthInch) &&
                      formik.errors.widthInch
                    }
                  />
                  <TextField
                    disabled
                    placeholder="Total"
                    name="widthTotal"
                    value={formik.values?.widthTotal}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.widthTotal) &&
                      Boolean(formik.touched.widthTotal)
                    }
                    helperText={
                      Boolean(formik.errors.widthTotal) &&
                      Boolean(formik.touched.widthTotal) &&
                      formik.errors.widthTotal
                    }
                  />
                </Box>
              </FormControl>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Area Value</Typography>
                  <Box display="flex" gap={1}>
                    <TextField
                      placeholder="marla"
                      type="number"
                      name="marla"
                      value={formik.values?.marla}
                      size={"small"}
                      fullWidth
                      onBlur={changeNetMarla}
                      onChange={(event) => {
                        const { value } = event.target;
                        if (/^\d*$/.test(value)) {
                          formik.handleChange(event);
                        }
                      }}
                      error={
                        Boolean(formik.errors.marla) &&
                        Boolean(formik.touched.marla)
                      }
                      helperText={
                        Boolean(formik.errors.marla) &&
                        Boolean(formik.touched.marla) &&
                        formik.errors.marla
                      }
                    />
                    <Typography variant="h4">.</Typography>
                    <TextField
                      placeholder="sarsai"
                      name="sarsai"
                      value={formik.values?.sarsai}
                      size={"small"}
                      fullWidth
                      onBlur={changeNetMarla}
                      onChange={(event) => {
                        const { value } = event.target;
                        if (/^\d*$/.test(value)) {
                          formik.handleChange(event);
                          
                        }
                      }}
                      error={
                        Boolean(formik.errors.sarsai) &&
                        Boolean(formik.touched.sarsai)
                      }
                      helperText={
                        Boolean(formik.errors.sarsai) &&
                        Boolean(formik.touched.sarsai) &&
                        formik.errors.sarsai
                      }
                    />
                  </Box>
                </FormControl>
                <Box></Box>
                <FormControl fullWidth>
                  <Typography>Rate Per</Typography>

                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="sqft"
                    name="ratePer"
                    style={{ display: "flex", flexDirection: "row" }}
                    onChange={(e) => {
                      formik.setFieldValue("costRate", "");
                      formik.setFieldValue("totalCost", "");
                      formik.setFieldValue("saleRate", "");
                      formik.setFieldValue("totalSale", "");

                      radioonchange(e);
                      formik.setFieldValue("ratePer", e.target.value);
                    }}
                    value={formik.values.ratePer}
                  >
                    <FormControlLabel
                      value="sqft"
                      control={<Radio />}
                      label="Sq ft."
                    />
                    <FormControlLabel
                      value="marla"
                      control={<Radio />}
                      label="Marla"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Net Sq/ft</Typography>
                  <TextField
                    disabled
                    name="netSqft"
                    type="text"
                    value={formik.values?.netSqft}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.netSqft) &&
                      Boolean(formik.touched.netSqft)
                    }
                    helperText={
                      Boolean(formik.errors.netSqft) &&
                      Boolean(formik.touched.netSqft) &&
                      formik.errors.netSqft
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Net Marla</Typography>
                  <TextField
                    disabled
                    name="netMarla"
                    type="text"
                    value={formik.values?.netMarla}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.netMarla) &&
                      Boolean(formik.touched.netMarla)
                    }
                    helperText={
                      Boolean(formik.errors.netMarla) &&
                      Boolean(formik.touched.netMarla) &&
                      formik.errors.netMarla
                    }
                  />
                </FormControl>
              </Box>{" "}
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Cost Rate</Typography>
                  <TextField
                    name="costRate"
                    type="text"
                    value={formik.values?.costRate}
                    size={"small"}
                    fullWidth
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        costrateonchange(e);
                      } else formik.handleChange(e);
                    }}
                    error={
                      Boolean(formik.errors.costRate) &&
                      Boolean(formik.touched.costRate)
                    }
                    helperText={
                      Boolean(formik.errors.costRate) &&
                      Boolean(formik.touched.costRate) &&
                      formik.errors.costRate
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Sale Rate</Typography>
                  <TextField
                    name="saleRate"
                    type="text"
                    value={formik.values?.saleRate}
                    size={"small"}
                    fullWidth
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        salerateonchange(e);
                      } else formik.handleChange(e);
                    }}
                    error={
                      Boolean(formik.errors.saleRate) &&
                      Boolean(formik.touched.saleRate)
                    }
                    helperText={
                      Boolean(formik.errors.saleRate) &&
                      Boolean(formik.touched.saleRate) &&
                      formik.errors.saleRate
                    }
                  />
                </FormControl>
              </Box>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Total Cost Amount</Typography>
                  <TextField
                    disabled
                    name="totalCost"
                    type="text"
                    value={formik.values?.totalCost}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.totalCost) &&
                      Boolean(formik.touched.totalCost)
                    }
                    helperText={
                      Boolean(formik.errors.totalCost) &&
                      Boolean(formik.touched.totalCost) &&
                      formik.errors.totalCost
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Total Sale Amount</Typography>
                  <TextField
                    disabled
                    name="totalSale"
                    type="text"
                    value={formik.values?.totalSale}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.totalSale) &&
                      Boolean(formik.touched.totalSale)
                    }
                    helperText={
                      Boolean(formik.errors.totalSale) &&
                      Boolean(formik.touched.totalSale) &&
                      formik.errors.totalSale
                    }
                  />
                </FormControl>
              </Box>
              <FormControl fullWidth>
                <Typography>Other Plot Info</Typography>
                <Select
                  placeholder="Select Other Plot Info"
                  id="demo-multiple-chip"
                  multiple
                  size="small"
                  value={tags}
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const selectedTag = UnitsPopulate?.Tags?.find(
                          (tag) => tag.TagId === value
                        );
                        return selectedTag ? (
                          <Chip
                            key={value}
                            label={selectedTag.Name}
                            sx={{
                              backgroundColor: selectedTag.Color,
                              color: "#fff",
                            }}
                          />
                        ) : null;
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"new"}>Add New</MenuItem>
                  {UnitsPopulate?.Tags?.map((tag, idx) => (
                    <MenuItem
                      key={idx}
                      value={tag?.TagId}
                      sx={
                        tags.includes(tag?.TagId)
                          ? { color: theme.palette.primary.main }
                          : null
                      }
                    >
                      {tag?.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
      {openTagModal > 0 && (
        <AddTagsModal
          showModal={openTagModal}
          handleClosed={handleClosed}
          setsnackBarData={setsnackBarData}
          dispatch={dispatch}
        />
      )}
    </Box>
  );
}

export default CreateUnit;
