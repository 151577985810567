import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Meetings: null,
  SingleMetting: [],
  MeetingsTypes: [],
  MettingParticpent: [],
  PopulateParticipant: [],
  MeetingsNotes: null,
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
  IsLogin: true,
};

export const fetchMeetings = createAsyncThunk(
  "/GeneralMeeting/GetAlldata",
  async () => {
    const response = await axiosInstance.get(`/GeneralMeeting/GetAlldata`);
    return response;
  }
);

export const fetchMeetingsSingle = createAsyncThunk(
  "/GeneralMeeting/id",
  async (id) => {
    const response = await axiosInstance.get(`/GeneralMeeting/id?id=${id}`);

    return response;
  }
);
export const fetchMettingTypes = createAsyncThunk(
  "/GeneralMeeting/GetAll",
  async () => {
    const response = await axiosInstance.get(`/GeneralMeeting/GetAll`);
 
    return response;
  }
);
export const fetchPopulateParticipants = createAsyncThunk(
  "GeneralMeeting/PopulateParticipants",
  async () => {
    const response = await axiosInstance.get(`GeneralMeeting/PopulateParticipants`);
    return response;
  }
);
const Meetingslice = createSlice({
  name: "Meetings",
  initialState,
  reducers: {
    MeetingsAdded: {
      reducer(state, action) {
        state.Meetings?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchMeetings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMeetings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Meetings = action.payload.data?.Data;
      })
      .addCase(fetchMeetings.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
        state.Meetings = null;
      })

      .addCase(fetchMeetingsSingle.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMeetingsSingle.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SingleMetting = action.payload.data.Data;
      })
      .addCase(fetchMeetingsSingle.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchMettingTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMettingTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.MeetingsTypes = action.payload.data.Data;
      })
      .addCase(fetchMettingTypes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchPopulateParticipants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPopulateParticipants.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.PopulateParticipant = action.payload.data.Data;
      })
      .addCase(fetchPopulateParticipants.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });

  },
});

export const getMeetingsDetails = (state) => state.Meetings;

export const { MeetingsAdded } = Meetingslice.actions;

export default Meetingslice.reducer;
