import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  Badge,
  Button,
  Chip,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  IconButton,
  Menu,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useEffect } from "react";
import ActivitiesFilterDrawer from "./ActivitiesFilterDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment";
import {
  fetchActivities,
  getActivitiesDetails,
} from "../../Redux/Slice/ActivitiesSlice/ActivitiesSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import DoneIcon from "@mui/icons-material/Done";
import { DoneLeadCallApi } from "../../Api/LeadsApi/DoneLeadCallApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { DeleteLeadActivityApi } from "../../Api/LeadsApi/DeleteActivityApi";
import {
  fetchleadActivityTypePopulate,
  getLeadsDetails,
} from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { AppContext } from "../../Context/TourContext/TourContext";
import { RemoveSCFromInputs } from "../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../AppConfirmationDialog/AppConfirmationDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppTooltip from "../../Utils/AppTooltip";
import ActivityDoneModal from "./ActivityDoneModal";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  fetchSinglePersons,
  getPersonsDetails,
} from "../../Redux/Slice/PersonSlice/PersonSlice";
import CustomerDetailModal from "./CustomerDetailsModal/CustomerDetailsModal";
import ActivityInfoModal from "./ActivityInfoModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { PageNumberContext } from "../../Context/PageNumberContext/PageNumberContext";
import Activity from "../Leads/LeadView/Activity/Activity";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

function ActivitiesTable({ roles }) {
  const {
    header,
    datepickBox,
    fieldBox,
    customTab,
    customTabsParent,
    customTabSelected,
    customTabSelected2,
    customTab2,
  } = useStyles();
  const { totalRows } = useContext(PageNumberContext);

  const [rows, setrows] = React.useState([]);
  const [selectedFilter, setselectedFilter] = useState();
  const [selectedFilter2, setselectedFilter2] = useState({
    Id: 10,
    Name: "All",
  });
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [allActivities, setallActivities] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showModal, setshowModal] = useState(false);
  const [showActivityModal, setshowActivityModal] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [title, setTitle] = useState(null);
  const [leadId, setleadId] = useState(null);
 const [activitiesRow, setactivitiesRow] = useState([]);
 
  const [showLoading, setshowLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [customFrom, setcustomFrom] = useState(null);
  const [customTo, setcustomTo] = useState(null);
  const [customerDetailsLoading, setCustomerDetailsLoading] = useState(false);
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] =
    useState(false);
  const [activityInfoModal, setactivityInfoModal] = useState(false);
  const [activityRow, setactivityRow] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(totalRows);
  const [totalCount, setTotalCount] = useState(0);
  const [activitiesData, setActivitiesData] = useState([]);
  const [getitem, setitem] = useState("");
  const [filterVal, setfilterVal] = useState({
    isdone: "",
    lead: "",
    // projects: [],
    projects: "",
    // users: [],
    users: "",
    customer: "",
    title: "",
    status: "",
    subtype: "",
    type: "",
  });
  const [filterArr] = useState([
    { name: "Yesterday", value: "Yesterday" },
    { name: "Today", value: "Today" },
    { name: "Tomorrow", value: "Tomorrow" },
    { name: "This Week", value: "ThisWeek" },
    { name: "This Month", value: "ThisMonth" },
    { name: "Custom", value: "Custom" },
  ]);

  const { setState, state } = useContext(AppContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Activities, status } = useSelector(getActivitiesDetails);
  const { LeadsActivityType } = useSelector(getLeadsDetails);
  const { SinglePersons } = useSelector(getPersonsDetails);

  const open = Boolean(anchorEl);

  const today = new Date();
  const yesterday = new Date(today);
  const tomorrow = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  tomorrow.setDate(today.getDate() + 1);
  const monday = new Date(today);
  monday.setDate(monday.getDate() - today.getDay() + 1);
  const weekLast = new Date(monday);
  weekLast.setDate(weekLast.getDate() - monday.getDay() + 7);
  const currentMonthFirst = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDateOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  );

  useEffect(() => {
    dispatch(
      fetchActivities({
        PageNumber: pageNo,
        PageSize: pageSize,
        activityType: selectedFilter2?.Name,
      })
    );
    dispatch(fetchleadActivityTypePopulate());
  }, [dispatch]);

  useEffect(() => {
    if (state?.tourActive && status === "succeeded") {
      setState({ ...state, run: true, stepIndex: 19 });
    }
  }, [status]);

  useEffect(() => {
    if (Activities) {
      let arr = [...activitiesData];

      Activities?.data?.forEach((activity) => {
        const index = arr.findIndex(
          (obj) => obj?.ActivtyId === activity?.ActivtyId
        );
        if (index !== -1) {
          // if item is found
          arr[index] = activity; // replace item at the found index
        } else {
          arr.push(activity);
        }
      });
      setActivitiesData([...arr]);
      setTotalCount(Activities?.TotalRecords);
    } else {
      setPageNo(1);
      setActivitiesData([]);
    }
  }, [Activities]);

  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (value === "customer" && typeof filterVal[value] === "number") {
        sum = sum + 1;
      } else {
        if (filterVal[value]?.length) {
          sum = sum + 1;
        }
      }
    }
    setcount(sum);
  }, [filterVal]);

  useEffect(() => {
    if (
      filterVal?.isdone === "" &&
      filterVal?.title === "" &&
      filterVal?.lead === "" &&
      // filterVal?.projects?.length === 0 &&
      filterVal?.projects === "" &&
      // filterVal?.users?.length === 0 &&
      filterVal?.users === "" &&
      filterVal?.customer === ""
    ) {
      setTotalCount(Activities?.TotalRecords);
      setallActivities(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(filterVal?.title, "i");
      let regex2 = new RegExp(filterVal?.lead, "i");

      suggestions = rows?.filter(
        (val) =>
          (filterVal?.isdone === "" ||
            (filterVal?.isdone === "yes"
              ? val?.StatusDone
              : !val?.StatusDone)) &&
          (filterVal?.title === "" || regex1.test(val.Title)) &&
          (filterVal?.lead === "" || regex2.test(val?.LeadTitle)) &&
          // (filterVal?.projects?.length === 0 ||
          //   filterVal?.projects?.every((element) =>
          //     val?.Projects?.some(
          //       (project) => project?.ProjectId === element?.ProjectId
          //     )
          //   ))
          (filterVal?.projects === "" ||
            val?.Projects?.some(
              (project) => project?.ProjectId === filterVal?.projects
            )) &&
          // (filterVal?.users?.length === 0 ||
          //   filterVal?.users?.every((element) =>
          //     val?.ActivitiesUserParticipants?.some(
          //       (user) => user?.UserId === element?.Id
          //     )
          //   ))
          (filterVal?.users === "" ||
            val?.ActivitiesUserParticipants?.some(
              (user) => user?.UserId === filterVal?.users
            )) &&
          (filterVal?.customer === "" ||
            val?.ActivitiesPersonParticipants?.some(
              (person) => person?.PersonId === filterVal?.customer
            ))
      );
      // setTotalCount(suggestions?.length);
      setallActivities(suggestions);
    }
  }, [filterVal, rows]);

  useEffect(() => {
    if (selectedFilter2?.Name === "All") {
      dispatch(
        fetchActivities({
          PageNumber: pageNo,
          PageSize: pageSize,
          activityType: selectedFilter2?.Name,
        })
      );
      setTotalCount(Activities?.TotalRecords);
      setallActivities(rows);
      setAnchorEl(null);
      setselectedFilter(null);
    } else {
      setAnchorEl(null);
      setselectedFilter(null);
      // let valuess = rows?.filter(
      //   (item) =>
      //     item?.Type?.toLowerCase() === selectedFilter2?.Name?.toLowerCase()
      // );
      dispatch(
        fetchActivities({
          PageNumber: 1,
          PageSize: pageSize,
          activityType: selectedFilter2?.Name,
        })
      );
      // setTotalCount(valuess?.length);
      // setallActivities(valuess);
      setActivitiesData([]);
    }
  }, [selectedFilter2]);

  console.log("customer", customer);
  useEffect(() => {
    let arr = [];
    if (activitiesData) {
      activitiesData?.forEach((activity, idx) => {
        let date2 = moment(activity?.ScheduleFrom?.split("T")[0])?.format(
          "DD-MMM-YYYY"
        );
        let time2 = moment(
          activity?.ScheduleFrom?.split("T")[1]?.split("Z")[0],
          "hh:mm A"
        )?.format("hh:mm A");
        let schFrom = date2?.concat(" ")?.concat(time2);
        arr.push({
          ...activity,
          id: idx + 1,
          ID: activity?.ActivtyId,
          ScheduleFrom: schFrom,
          // ScheduleTo: schTo,
          CreatedAt: moment(activity?.CreatedAt).format("DD-MMM-YYYY"),
          LeadId: activity?.LeadId,
          AddedBy: activity?.AddedBy?.FullName,
          Projects: activity?.project?.LeadUnits,
        });
      });
    }
    setrows(arr);
    setallActivities(arr);
  }, [activitiesData]);

  useEffect(() => {
    if (customerDetailsLoading) {
      if (SinglePersons) {
        setShowCustomerDetailsModal(true);
      }
      setCustomerDetailsLoading(false);
    }
  }, [SinglePersons]);
  console.log("this is activities data", activitiesData);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "id", headerName: "Sr", width: 90, headerClassName: header },
    // {
    //   field: "isdone",
    //   headerName: "Is Done",
    //   width: 70,
    //   headerClassName: header,
    //   renderCell: () => {
    //     return <FormControlLabel control={<Radio />} />;
    //   },
    // },
    {
      field: "LeadTitle",
      headerName: "Lead",
      width: 220,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <Typography
            onClick={() => {
              roles?.some((role) => role === "Leads_View") &&
                navigate("/leads/view", {
                  state: celval?.row?.LeadId,
                });
            }}
            // "#0e90d9"
            sx={{ color: "#298b8b", cursor: "pointer" }}
          >
            {celval.formattedValue}
          </Typography>
        );
      },
    },
    // {
    //   field: "Projects",
    //   headerName: "Projects",
    //   width: 250,
    //   renderCell: (celval) => {
    //     return celval.formattedValue?.map((proj, idx) => (
    //       <Chip
    //         key={idx}
    //         size="small"
    //         label={proj?.ProjectName}
    //         sx={{
    //           margin: "1px",
    //         }}
    //       />
    //     ));
    //   },
    // },
    {
      field: "LeadId",
      headerName: "Lead Id",
      width: 120,
    },
    {
      field: "ActivitiesUserParticipants",
      headerName: "Users",
      width: 250,
      headerClassName: header,
      renderCell: (celval) => {
        return celval.formattedValue?.map((user, idx) => (
          <Chip
            key={idx}
            size="small"
            label={user?.FullName}
            sx={{
              margin: "1px",
            }}
          />
        ));
      },
    },
    {
      field: "ActivitiesPersonParticipants",
      headerName: "Customer",
      width: 250,
      headerClassName: header,
      renderCell: (celval) => {
        return celval.formattedValue?.slice(0, 1)?.map((customer) => (
          <Box
            key={customer?.PersonId}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <Chip size="small" label={customer?.Name} />

            <AppTooltip title="View Customer">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(fetchSinglePersons(customer?.PersonId));
                  setCustomerDetailsLoading(true);
                }}
              >
                <HelpOutlineIcon color="primary" fontSize="small" />
              </IconButton>
            </AppTooltip>
          </Box>
        ));
      },
    },
    {
      field: "Title",
      headerName: "Activity Title",
      width: 250,

      headerClassName: header,
    },
    // {
    //   field: "FullName",
    //   headerName: "Created By",
    //   width: 150,

    //   headerClassName: header,
    // },
    // {
    //   field: "Description",
    //   headerName: "Description",
    //   width: 250,

    //   headerClassName: header,
    // },

    {
      field: "Type",
      headerName: "Type",
      width: 100,

      headerClassName: header,
    },
    {
      field: "SubType",
      headerName: "Sub Type",
      width: 150,

      headerClassName: header,
    },
    {
      field: "ScheduleFrom",
      headerName: "Activity Date",
      width: 200,

      headerClassName: header,
    },
    {
      field: "CreatedAt",
      headerName: "Entry Date",
      width: 150,

      headerClassName: header,
    },
    // {
    //   field: "ScheduleTo",
    //   headerName: "Schedule To",
    //   width: 200,

    //   headerClassName: header,
    // },

    {
      field: "status",
      headerName: "Status",
      width: 150,
      headerClassName: header,
      renderCell: (cellVal) => {
        return <Box>{cellVal?.row?.Status}</Box>;
      },
    },
    {
      field: "done",
      headerName: "Mark Done",
      width: 150,
      headerClassName: header,
      renderCell: (cellVal) => {
        return (
          <Box>
            {cellVal?.row?.StatusDone === true ? (
              "Completed"
            ) : (
              <AppTooltip title="Mark as done">
                <IconButton
                  disabled={!roles?.some((role) => role === "Activities_Edit")}
                  onClick={(e) => {
                    e.stopPropagation();
                    setactivitiesRow(cellVal?.row);
                    handleClickOpen();
                  }}
                >
                  <DoneIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.green,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </AppTooltip>
              // <Button
              //   onClick={(e) => {
              //     e.stopPropagation();
              //     setactivitiesRow(cellVal?.row);
              //     handleClickOpen();
              //   }}
              //   disabled={!roles?.some((role) => role === "Activities_Edit")}
              //   variant="contained"
              //   size="small"
              //   sx={{ textTransform: "none", color: "#fff" }}
              // >
              //   {cellVal?.row?.Type === "Email" ||
              //   cellVal?.row?.Type === "Message"
              //     ? "Mark As Done"
              //     : "Change Status"}
              // </Button>
            )}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 170,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            {roles?.some((role) => role === "Activities_Edit") && (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row);
                  }}
                >
                  <EditIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.edit,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            {roles?.some((role) => role === "Activities_Delete") && (
              <Tooltip title="Delete">
                <IconButton
                  key={i}
                  onClick={() => {
                    setselectedId(celval.row.ID);
                    setshowConfirmationIcons(true);
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.delete,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="View Details ">
              <IconButton
                key={i}
                onClick={() => {
                  setactivityRow(celval?.row);
                  setactivityInfoModal(true);
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>

            {roles?.some((role) => role === "Activities_Create") &&
              celval?.row?.LeadStatus !== "Won" &&
              celval?.row?.LeadStatus !== "Lost" && (
                <Tooltip title="New Activity">
                  <IconButton
                    key={i}
                    onClick={() => {
                      setCustomer(
                        celval?.row?.ActivitiesPersonParticipants?.[0]
                      );
                      setleadId(celval?.row?.LeadId);
                      setTitle(celval?.row?.LeadTitle);
                      setshowActivityModal(true);
                    }}
                  >
                    <LocalActivityIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.green,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              )}
          </Box>
        );
      },
    },
  ];

  const handleEdit = (val) => {
    navigate("/edit/activities", { state: { ActiviTable: val } });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };

  const handleClickOpen = () => {
    setshowModal(true);
  };

  const handleClosed = () => {
    setshowModal(false);
  };

  const doneActivities = async () => {
    setshowLoading(true);
    const formData = new FormData();

    formData.append("Status", "Sent");
    const response = await DoneLeadCallApi(activitiesRow?.ActivtyId, formData);
    setshowLoading(false);
    setactivitiesRow(null);
    handleClosed();

    if (response.data.IsSuccess) {
      dispatch(
        fetchActivities({
          PageNumber: pageNo,
          PageSize: pageSize,
          activityType: selectedFilter2?.Name,
        })
      );
    }
    setsnackBarData(response.snackBarData);
  };

  const deleteActivity = async (id) => {
    setshowLoading(true);
    const response = await DeleteLeadActivityApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);

    if (response.data.IsSuccess) {
      dispatch(
        fetchActivities({
          PageNumber: pageNo,
          PageSize: pageSize,
          activityType: selectedFilter2?.Name,
        })
      );
    }
    setsnackBarData(response.snackBarData);
  };

  const handleFilterInput = (item) => {
    setitem(item.value); // use in searchRefreshdata func
    if (item.value === "Yesterday") {
      setActivitiesData([]);
      setPageNo(1);
      dispatch(
        fetchActivities({
          startDate: moment(yesterday)
            .format("YYYY-MM-DD")
            .concat("T00:00:00.000Z"),
          endDate: moment(yesterday)
            .format("YYYY-MM-DD")
            .concat("T23:59:59.353Z"),
          activityType: selectedFilter2?.Name,
        })
      );
    } else if (item.value === "Today") {
      setActivitiesData([]);
      setPageNo(1);
      dispatch(
        fetchActivities({
          startDate: moment(new Date())
            .format("YYYY-MM-DD")
            .concat("T00:00:00.000Z"),
          endDate: moment(new Date())
            .format("YYYY-MM-DD")
            .concat("T23:59:59.353Z"),
          activityType: selectedFilter2?.Name,
        })
      );
    } else if (item.value === "Tomorrow") {
      setActivitiesData([]);
      setPageNo(1);
      dispatch(
        fetchActivities({
          startDate: moment(tomorrow)
            .format("YYYY-MM-DD")
            .concat("T00:00:00.000Z"),
          endDate: moment(tomorrow)
            .format("YYYY-MM-DD")
            .concat("T23:59:59.353Z"),
          activityType: selectedFilter2?.Name,
        })
      );
    } else if (item.value === "ThisWeek") {
      setActivitiesData([]);
      setPageNo(1);
      dispatch(
        fetchActivities({
          startDate: moment(monday)
            .format("YYYY-MM-DD")
            .concat("T00:00:00.000Z"),
          endDate: moment(weekLast)
            .format("YYYY-MM-DD")
            .concat("T23:59:59.353Z"),
          activityType: selectedFilter2?.Name,
        })
      );
    } else if (item.value === "ThisMonth") {
      setActivitiesData([]);
      setPageNo(1);
      dispatch(
        fetchActivities({
          startDate: moment(currentMonthFirst)
            .format("YYYY-MM-DD")
            .concat("T00:00:00.000Z"),
          endDate: moment(lastDateOfMonth)
            .format("YYYY-MM-DD")
            .concat("T23:59:59.353Z"),
          activityType: selectedFilter2?.Name,
        })
      );
    } else null;
  };

  const handleCustomFilter = () => {
    if (customFrom) {
      setActivitiesData([]);
      setPageNo(1);
      dispatch(
        fetchActivities({
          startDate: customFrom.concat("T00:00:00.000Z"),
          endDate: customTo.concat("T23:59:59.353Z"),
          activityType: selectedFilter2?.Name,
          projectId: filterVal?.projects || null,
          userid: filterVal?.users || null,
          personId: filterVal?.customer || null,
          activityName: filterVal?.title || null,
          leadTitle: filterVal?.lead || null,
          Status: filterVal?.status || null,
          subType: filterVal?.subtype || null,
          type: filterVal?.type || null,
        })
      );
    }
  };

  const searchRefreshData = (action) => {
    const filters = {
      PageNumber: 1,
      PageSize: pageSize,
      activityType: selectedFilter2?.Name,
      projectId: filterVal?.projects || null,
      userid: filterVal?.users || null,
      personId: filterVal?.customer || null,
      activityName: filterVal?.title || null,
      leadTitle: filterVal?.lead || null,
      Status: filterVal?.status || null,
      subType: filterVal?.subtype || null,
      type: filterVal?.type || null,
    };

    const setDateRange = (startDate, endDate) => {
      filters.startDate = startDate.concat("T00:00:00.000Z");
      filters.endDate = endDate.concat("T23:59:59.353Z");
    };
    if (customFrom && customTo) {
      setDateRange(customFrom, customTo);
      setcustomFrom(null);
      setcustomTo(null);
    } else {
      switch (getitem) {
        case "Yesterday":
          setDateRange(
            moment(yesterday).format("YYYY-MM-DD"),
            moment(yesterday).format("YYYY-MM-DD")
          );
          setitem(" ");
          break;
        case "Today":
          setDateRange(
            moment(new Date()).format("YYYY-MM-DD"),
            moment(new Date()).format("YYYY-MM-DD")
          );
          setitem(" ");
          break;
        case "Tomorrow":
          setDateRange(
            moment(tomorrow).format("YYYY-MM-DD"),
            moment(tomorrow).format("YYYY-MM-DD")
          );
          setitem(" ");
          break;
        case "ThisWeek":
          setDateRange(
            moment(monday).format("YYYY-MM-DD"),
            moment(weekLast).format("YYYY-MM-DD")
          );
          setitem(" ");
          break;
        case "ThisMonth":
          setDateRange(
            moment(currentMonthFirst).format("YYYY-MM-DD"),
            moment(lastDateOfMonth).format("YYYY-MM-DD")
          );
          setitem(" ");
          break;
        default:
          break;
      }
    }
    setPageNo(1);
    setActivitiesData([]);
    if (action === "search") {
      dispatch(fetchActivities(filters));
    } else {
      dispatch(
        fetchActivities({
          PageNumber: 1,
          PageSize: pageSize,
          activityType: selectedFilter2?.Name,
        })
      );
    }
  };

  // code without end and start date filter
  // const searchRefreshData = (action) => {
  //   setPageNo(1);
  //   setActivitiesData([]);
  //   if (action === "search") {
  //     dispatch(
  // fetchActivities({
  //   // startDate: customFrom.concat("T00:00:00.000Z"),
  //   // endDate: customTo.concat("T23:59:59.353Z"),
  //   PageNumber: 1,
  //   PageSize: pageSize,
  //   projectId: filterVal?.projects,
  //   userid: filterVal?.users,
  //   personId: filterVal?.customer,
  //   activityName: filterVal?.title,
  //   leadTitle: filterVal?.lead,
  //   activityType: selectedFilter2?.Name,
  //   Status: filterVal?.status,
  //   subType: filterVal?.subtype,
  //         type: filterVal?.type,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       fetchActivities({
  //         PageNumber: 1,
  //         PageSize: pageSize,
  //         activityType: selectedFilter2?.Name,
  //       })
  //     );
  //   }
  // };

  return (
    <>
      <Box>
        <ActivitiesFilterDrawer
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
          rows={rows}
          setallActivities={setallActivities}
          setTotalCount={setTotalCount}
          handleChangeVal={handleChangeVal}
          setcount={setcount}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          searchRefreshData={searchRefreshData}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Box
              onClick={() => {
                setselectedFilter2({ Id: 10, Name: "All" });
                setselectedFilter(null);
              }}
              className={
                selectedFilter2?.Name === "All"
                  ? customTabSelected2
                  : customTab2
              }
            >
              <Typography
                sx={{
                  color: selectedFilter2?.Name === "All" ? "white" : "#546e7a",
                  fontWeight: "bold",
                }}
              >
                All
              </Typography>
            </Box>
            {LeadsActivityType?.map((type, i) => {
              return (
                <Box
                  key={i}
                  onClick={() => {
                    setselectedFilter2(type);
                  }}
                  className={
                    selectedFilter2?.Name === type.Name &&
                    selectedFilter2?.Id === type.Id
                      ? customTabSelected2
                      : customTab2
                  }
                >
                  <Typography
                    sx={{
                      color:
                        selectedFilter2?.Name === type.Name &&
                        selectedFilter2?.Id === type.Id
                          ? "white"
                          : "#546e7a",
                      fontWeight: "bold",
                    }}
                  >
                    {type?.Name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box className={customTabsParent}>
            {filterArr?.map((item, idx) => {
              return (
                <Box
                  onClick={(e) => {
                    setselectedFilter(item);
                    handleFilterInput(item);
                    if (item.name === "Custom") {
                      handleClick(e);
                    }
                  }}
                  sx={{
                    borderLeft: idx != 0 ? "none !important" : "",
                  }}
                  className={
                    selectedFilter?.name === item.name
                      ? customTabSelected
                      : customTab
                  }
                  key={idx}
                >
                  <Typography
                    sx={{ fontSize: "0.8rem !important" }}
                    variant="small"
                  >
                    {item.name}
                  </Typography>
                </Box>
              );
            })}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Box className={datepickBox}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Date Range</Typography>
                  <CloseIcon
                    onClick={handleClose}
                    fontSize="small"
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
                <Box className={fieldBox}>
                  <TextField
                    type={"date"}
                    size="small"
                    sx={{ width: "100%", maxWidth: "150px" }}
                    onChange={(e) => {
                      setcustomFrom(e.target.value);
                    }}
                    value={customFrom}
                  />
                  <Typography> to</Typography>
                  <TextField
                    type={"date"}
                    size="small"
                    sx={{ width: "100%", maxWidth: "150px" }}
                    onChange={(e) => {
                      setcustomTo(e.target.value);
                    }}
                    value={customTo}
                  />
                </Box>
                <Button
                  sx={{
                    width: "50px",
                    height: "36px",
                    color: "white !important",
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                  onClick={handleCustomFilter}
                >
                  Done
                </Button>
              </Box>
            </Menu>

            {/* {
      value2 === 0 ? "Item One" : value2 === 1 ? "Item2" : value2 === 2 ? "Item3" : null
  } */}
          </Box>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 164px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: "white !important",
          }}
        >
          <DataGrid
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allActivities}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            pagination
            page={pageNo - 1}
            rowCount={totalCount}
            onPageChange={(e) => {
              if (
                activitiesData.length < pageSize * (e + 1) &&
                activitiesData.length < Activities?.TotalRecords
              ) {
                dispatch(
                  fetchActivities({
                    PageNumber: e + 1,
                    PageSize: pageSize,
                    activityType: selectedFilter2?.Name,
                    projectId: filterVal?.projects || null,
                    userid: filterVal?.users || null,
                    personId: filterVal?.customer || null,
                    activityName: filterVal?.title || null,
                    leadTitle: filterVal?.lead || null,
                    Status: filterVal?.status || null,
                    subType: filterVal?.subtype || null,
                    type: filterVal?.type || null,
                  })
                );
              }
              setPageNo(e + 1);
            }}
          />
          {status === "loading" || showLoading || customerDetailsLoading ? (
            <Loader />
          ) : null}
        </Box>
      </Box>

      {/* <Dialog
        open={showModal}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to <b>{activitiesRow?.Title}</b> mark as done{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={handleClosed}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  doneActivities();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}
      {activitiesRow?.Type === "Email" || activitiesRow?.Type === "Message" ? (
        <AppConfirmationDialog
          open={showModal}
          onDeny={() => {
            setactivitiesRow(null);
            handleClosed();
          }}
          onConfirm={() => {
            doneActivities();
          }}
          iconContainerColor="#ebf9f9"
          icon={
            <CheckCircleIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.primary?.main }}
            />
          }
          dialogTitle="Mark Done"
          title={`Are you sure, you want to mark "${activitiesRow?.Title}" as done?`}
          successText="Yes"
          successBtnColor="primary"
          cancelText="No"
        />
      ) : (
        showModal && (
          <ActivityDoneModal
            showModal={showModal}
            setShowModal={setshowModal}
            selectedRow={activitiesRow}
            setselectedRow={setactivitiesRow}
            pageNo={pageNo}
            pageSize={pageSize}
          />
        )
      )}
      {showActivityModal && (
        <Activity
          leadId={leadId}
          setshowModal={setshowActivityModal}
          showModal={showActivityModal}
          customer={customer}
          setCustomer={setCustomer}
          title={title}
          setTitle={setTitle}
          close
          activityModal
          pageNo={pageNo}
          pageSize={pageSize}
        />
      )}

      <CustomerDetailModal
        open={showCustomerDetailsModal}
        onClose={() => {
          setShowCustomerDetailsModal(false);
        }}
        title="Customer Details"
        data={SinglePersons}
      />
      {activityInfoModal && (
        <ActivityInfoModal
          activityInfoModal={activityInfoModal}
          setactivityInfoModal={setactivityInfoModal}
          setactivityRow={setactivityRow}
          activityRow={activityRow}
        />
      )}
      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteActivity(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Activity"
        title={`Are you sure, you want to Delete Activity?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    header: {
      fontWeight: "bold !important",
      fontSize: "1rem",
    },

    customTabsParent: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "10px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    customTab: {
      padding: "5px",
      backgroundColor: "white",
      border: "1px solid #707070",
      cursor: "pointer",
    },
    customTabSelected: {
      padding: "5px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
    },
    datepickBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "10px",
    },
    fieldBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    customTabSelected2: {
      padding: "5px 10px 5px 10px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
      alignItems: "center",
      borderRadius: "19px",
    },
    customTab2: {
      padding: "5px 5px 5px 5px",
      borderRadius: "20px",
      cursor: "pointer",
      alignItems: "center",
    },
  };
});
export default ActivitiesTable;
ActivitiesTable.propTypes = {
  roles: PropTypes.array,
};
