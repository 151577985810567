export default function GetQueryParams(params) {
  const queryParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      queryParams.append(key, params[key]);
    }
  });

  return queryParams?.toString();
}
