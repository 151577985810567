import {
  Box,
  IconButton,
  Link,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImageIcon from "@mui/icons-material/Image";
// import ArticleIcon from "@mui/icons-material/Article";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import pdf from "../../../Assets/MessageMob/pdf.png";
// import video from "../../../Assets/MessageMob/video-icon.png";
import pdfBg from "../../../Assets/MessageMob/whatsapbg.jpg";
import mobile from "../../../Assets/MessageMob/mobile.png";

//   import pdf from '../../assets/icon/pdf.png';
// import video from '../../assets/icon/video-icon.png';
// import pdfBg from '../../assets/icon/whatsapbg.jpg';
// import mobile from '../../assets/icon/mobile.png';
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});
function MessageDetailModal({
  showModal,
  setShowModal,
  setselectedCampaign,
  selectedCampaign,
}) {
  const { Container, ModalContainer, TableHeader } = useStyles();
  console.log(selectedCampaign);
  console.log(selectedCampaign?.FileName?.split(".")[1]);
  console.log(setselectedCampaign);
  const extension = selectedCampaign?.FileName?.split(".")[1];
  console.log(extension);
  console.log(selectedCampaign);
//   const filename = selectedCampaign?.FileName?.split(`.${extension}`)[0];
  //   const fileLink = "a";
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Message Details
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  minWidth: "350px",
                  maxWidth: "350px",
                  minHeight: "600px",
                  borderImageSlice: 20,
                  border: "10px solid transparent",
                  backgroundImage: `url(${mobile})`,
                  backgroundSize: "cover",
                  backgroundClip: "padding-box",
                  // borderImageRepeat: 'round',
                  paddingTop: "70px",
                  paddingLeft: "35px",
                  paddingRight: "0",
                }}
              >
                <Box
                  sx={{
                    minWidth: "245px",
                    maxWidth: "245px",

                    margin: "auto",
                    minHeight: "440px",
                    maxHeight: "440px",
                    overflow: "auto",
                    backgroundImage: `url(${pdfBg})`,
                    backgroundSize: "cover",
                    backgroundClip: "padding-box",
                    // borderImageRepeat: 'round',
                  }}
                >
                  <Box
                    sx={{
                      width:
                        selectedCampaign?.CampaignType === "SMS"
                          ? "200px"
                          : "230px",
                      margin: "auto",
                      overflow: "auto",
                      mt: 3,
                      overflowWrap: "break-word",
                    }}
                  >
                    {selectedCampaign?.CampaignType === "SMS" ? (
                      <Box
                        sx={{
                          pl: 2,
                          pt: 2,
                          p: 2,
                          borderRadius: "15px",
                          backgroundColor: "white !important",
                        }}
                      >
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: selectedCampaign?.Message,
                          }}
                        />

                        <Typography>Please visit this link:</Typography>
                        <Link href={selectedCampaign?.File} target="_blank">
                          <Typography>{selectedCampaign?.File}</Typography>
                        </Link>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          pl: 2,
                          pt: 2,
                          p: 2,
                          borderRadius: "15px",
                          backgroundColor: "white !important",
                        }}
                      >
                        <Box>
                          <Typography color={"primary"}>Subject:</Typography>
                          <Typography>
                            {selectedCampaign?.CampaignTitle}
                          </Typography>
                        </Box>
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: selectedCampaign?.Message,
                          }}
                        />

                        {extension == "png" || extension == "jpg" || extension == "jpeg" ? (
                            <Box sx={{display:"flex",gap:"5px",alignItems:"center"}}>
                          <Link
                            href={selectedCampaign?.File}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Tooltip title="View Image">
                              <Box
                                sx={{
                                  padding: "5px",
                                  border: "1px solid rgb(214, 214, 214)",
                                  width: "fit-content",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <ImageIcon
                                  fontSize="small"
                                  color="primary"
                                  sx={{
                                    fontSize: "45px",
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        <Typography sx={{fontSize:"12px",textOverflow:"ellipsis",whiteSpace:"nowrap",width:"140px",overflow:"hidden"}}>{selectedCampaign?.FileName}</Typography>
                          
                          </Box>
                        )
                        : extension == "docx"? (
                            <Box sx={{display:"flex",gap:"5px",alignItems:"center"}}>

                            <Link
                              href={selectedCampaign?.File}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Tooltip title="View Word">
                                <Box
                                  sx={{
                                    padding: "5px",
                                    border: "1px solid rgb(214, 214, 214)",
                                    width: "fit-content",
                                    borderRadius: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <DocumentScannerIcon
                                    fontSize="small"
                                    color="primary"
                                    sx={{
                                      fontSize: "45px",
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                            </Link>
                        <Typography sx={{fontSize:"12px",textOverflow:"ellipsis",whiteSpace:"nowrap",width:"140px",overflow:"hidden"}}>{selectedCampaign?.FileName}</Typography>

                            </Box>
                          )
                        : null
                        // (
                        //     <Box sx={{display:"flex",gap:"5px",alignItems:"center"}}>

                        //   <Link
                        //     href={selectedCampaign?.File}
                        //     target="_blank"
                        //     rel="noopener noreferrer"
                        //   >
                        //     <Tooltip title="View Document">
                        //       <Box
                        //         sx={{
                        //           padding: "5px",
                        //           border: "1px solid rgb(214, 214, 214)",
                        //           width: "fit-content",
                        //           borderRadius: "5px",
                        //           display: "flex",
                        //           justifyContent: "center",
                        //           alignItems: "center",
                        //         }}
                        //       >
                        //         {/* <ArticleIcon
                        //           fontSize="small"
                        //           color="primary"
                        //           sx={{
                        //             fontSize: "45px",
                        //           }}
                        //         /> */}
                        //       </Box>
                        //     </Tooltip>
                        //   </Link>
                        //   <Typography sx={{fontSize:"12px",textOverflow:"ellipsis",whiteSpace:"nowrap",width:"140px",overflow:"hidden"}}>{selectedCampaign?.FileName}</Typography>

                        //   </Box>
                        // )
                        }
                        <Link
                          href={selectedCampaign?.File}
                          target="_blank"
                        ></Link>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default MessageDetailModal;
MessageDetailModal.propTypes = {
  showModal: PropTypes.any,
  setShowModal: PropTypes.any,
  type: PropTypes.any,
  selectedCampaign: PropTypes.any,
  setselectedCampaign: PropTypes.any,
};
