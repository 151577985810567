import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  CampaignPopulate: null,
  AllCampaign: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchAllCampaigns = createAsyncThunk("/Campaigns", async () => {
  const response = await axiosInstance.get(`/Campaigns`);
  return response;
});
export const fetchCampaignPopulate = createAsyncThunk(
  "/Campaigns/PopulateCompaign",
  async () => {
    const response = await axiosInstance.get(`/Campaigns/PopulateCompaign`);
    return response;
  }
);

const CampaignsSlice = createSlice({
  name: "CampaignsSlice",
  initialState,
  reducers: {
    CampaignsAdded: {
      reducer(state, action) {
        state.CampaignPopulate?.push(
          action.payload?.CampaignPopulate?.data?.Data
        );
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchCampaignPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCampaignPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.CampaignPopulate = action.payload.data?.Data;
      })
      .addCase(fetchCampaignPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAllCampaigns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCampaigns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.AllCampaign = action.payload.data?.Data;
      })
      .addCase(fetchAllCampaigns.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});
export const getCampaignsDetails = (state) => state.CampaignsSlice;

export const { CampaignsAdded } = CampaignsSlice.actions;

export default CampaignsSlice.reducer;
