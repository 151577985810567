import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { Badge, Button, Chip, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useContext } from "react";
import PropTypes from "prop-types";

import Joyride from "react-joyride";
import { getOrganizationDetails } from "../../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import { AppContext } from "../../../Context/TourContext/TourContext";

import DocumentModal from "../../../Components/DocumentModal/DocumentModal";
import DocumentDetailModal from "../../../Components/DocumentModal/DocumentDetailModal";
import Loader from "../../../Components/Loader/Loader";
import {
  fetchBookUnitPopulate,
  fetchUnits,
  getUnitsDetails,
} from "../../../Redux/Slice/UnitSlice/UnitSlice";
import UnitFilterDrawer from "../../../Components/Inventory/Unit/UnitFilterDrawer/UnitFilterDrawer";
import UnitInfoModal from "../../../Components/Inventory/Unit/UnitInfoModal";
import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import { RemoveSCFromInputs } from "../../../Utils/RemoveSCFromInputs";
import BookUnitModal from "../../../Components/Inventory/Unit/BookUnitModal/BookUnitModal";
import TaskIcon from "@mui/icons-material/Task";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

function Unit({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const navigate = useNavigate();
  const [pagesize, setpagesize] = useState(totalRows);
  const [rows, setrows] = React.useState([]);
  const [allUnit, setallUnit] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState({
    area: "",
    section: "",
    project: "",
    unit: "",
    category: "",
    tags: [],
  });
  const [count, setcount] = useState(0);
  // const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  // const [selectedId, setselectedId] = useState(null);
  const area = useRef();
  const unit = useRef();
  const section = useRef();
  const project = useRef();
  const category = useRef();

  const [showModal2, setShowModal2] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [documentDetailModal, setdocumentDetailModal] = useState(false);
  const { setState, state } = useContext(AppContext);
  const [unitInfoModal, setunitInfoModal] = useState(false);
  const [unitRow, setunitRow] = useState(null);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [showBookUnitModal, setShowBookUnitModal] = useState(false);

  const dispatch = useDispatch();
  const { Units, status } = useSelector(getUnitsDetails);
  const { DocumentsData, status2 } = useSelector(getOrganizationDetails);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Units");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value]?.length) {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    // if (Units == null) {
    dispatch(fetchUnits());
    dispatch(fetchBookUnitPopulate());
    // }
  }, [dispatch]);

  useEffect(() => {
    let rowData = [];
    Units?.map((unit, i) => {
      rowData.push({
        id: i + 1,
        project: unit?.Project?.ProjectName,
        section: unit?.Section.SectionName,
        category: unit?.Category?.CategoryName,
        unit: unit?.UnitName,
        area: unit?.Marla + "M" + unit?.Sarsai + "S",
        dimension:
          unit?.DimensionLength !== null
            ? unit.DimensionLength?.toFixed(2) +
              " x " +
              unit.DimensionWidth?.toFixed(2)
            : "0x0",
        ID: unit?.UnitId,
        sRate: unit?.RatePerMarla
          ? Math.round(unit.RatePerMarla)?.toLocaleString()
          : "",
        totalValue: unit?.Price ? Math.round(unit.Price)?.toLocaleString() : "",
        UnitTags: unit?.UnitTags,
        UnitName: unit?.UnitName,
        UnitStatus: unit?.Status,
        TotalCost: unit?.TotalCost ? Math.round(unit.TotalCost) : "",
        RatePerMarlaOrsqft: unit?.RatePerMarlaOrsqft,
        NetSqft: unit?.NetSqft,
        NetMarla: unit?.NetMarla,
        DimensionWidth: unit?.DimensionWidth,
        DimensionLength: unit?.DimensionLength,
        CostPerMarla: unit?.CostPerMarla,
        Price: unit?.Price ? Math.round(unit.Price)?.toLocaleString() : "",
        RatePerMarla: unit?.RatePerMarla,
        Marla: unit?.Marla,
        Sarsai: unit?.Sarsai,
        Section: unit?.Section,
        Project: unit?.Project,
        Category: unit?.Category,
      });
    });
    setrows(rowData);
    setallUnit(rowData);
  }, [Units]);

  const columns = [
    { field: "id", headerName: "Sr", width: 90 },
    {
      field: "project",
      headerName: "Project",
      width: 180,
    },
    {
      field: "section",
      headerName: "Section",
      width: 180,
    },
    {
      field: "category",
      headerName: "Category",
      width: 180,
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 150,
    },
    {
      field: "area",
      headerName: "Area",
      width: 150,
    },
    {
      field: "dimension",
      headerName: "Dimension",
      width: 180,
    },
    {
      field: "sRate",
      headerName: "S.Rate",
      width: 180,
    },
    {
      field: "totalValue",
      headerName: "Total Value",
      width: 180,
    },
    {
      field: "UnitTags",
      headerName: "Tags",
      width: 300,
      renderCell: (celval) => {
        return celval.formattedValue.map((tag, idx) => (
          <div key={idx} style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Chip
              size="small"
              label={tag?.Tag?.Name}
              sx={{
                margin: "1px",
                backgroundColor: tag?.Tag?.Color,
                color: "#fff",
              }}
            />
          </div>
        ));
      },
    },
    {
      field: "UnitStatus",
      headerName: "Status",
      width: 120,
    },
    {
      field: "action",
      headerName: "Action",
      width: 140,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            {roles?.some((role) => role === "Products_Edit") && (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row);
                  }}
                >
                  <EditIcon
                    sx={{ color: (theme) => theme?.palette?.primary?.edit }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            {/* <Tooltip title="Delete">
              <IconButton
                key={i}
                onClick={() => {
                  setshowConfirmationIcons(true);
                  setselectedId(celval.row.ID);
                }}
                disabled={
                  roles?.some((role) => role === "Products_Delete")
                    ? false
                    : true
                }
              >
                <DeleteForeverIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.delete,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>*/}

            <Tooltip title="View">
              <IconButton
                key={i}
                onClick={() => {
                  setunitRow(celval.row);
                  setunitInfoModal(true);
                  // setShowModal(true);
                  // setProduct(celval.row);
                  // dispatch(fetchproduct(celval.row?.ID));
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>

            {celval.row?.UnitStatus === "Available" &&
              roles?.some((role) => role === "Unit_Edit") && (
                <Tooltip title="Book Unit">
                  <IconButton
                    key={i}
                    onClick={() => {
                      setSelectedUnitId(celval.row?.ID);
                      setShowBookUnitModal(true);
                    }}
                  >
                    <TaskIcon
                      sx={{ color: (theme) => theme?.palette?.success?.main }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              )}
          </Box>
        );
      },
    },
  ];

  const handleEdit = (val) => {
    navigate("/inventory/unit/create", { state: val });
  };

  const handleChangeVal = (e) => {
    // let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value || "",
    });
  };

  // const onTextChange = () => {
  //   if (
  //     area.current?.value === "" &&
  //     unit.current?.value === "" &&
  //     section.current?.value === "" &&
  //     project.current?.value === "" &&
  //     filterVal?.tags === ""
  //   ) {
  //     setallUnit(rows);
  //   } else {
  //     let suggestions = [];
  //     let regex1 = new RegExp(RemoveSCFromInputs(project.current.value), "i");
  //     let regex2 = new RegExp(RemoveSCFromInputs(section.current.value), "i");
  //     let regex3 = new RegExp(RemoveSCFromInputs(unit.current.value), "i");
  //     let regex4 = new RegExp(RemoveSCFromInputs(area.current.value), "i");
  //     suggestions = rows?.filter(
  //       (val) =>
  //         (project.current.value === "" ||
  //           regex1.test(val?.Project?.ProjectName)) &&
  //         (section.current.value === "" ||
  //           regex2.test(val?.Section?.SectionName)) &&
  //         (unit.current.value === "" || regex3.test(val?.UnitName)) &&
  //         (area.current.value === "" || regex4.test(val?.area)) &&
  //         (filterVal?.tags === "" ||
  //           val.UnitTags.some((tag) => tag?.Tag?.TagId === filterVal?.tags))
  //     );
  //     setallUnit(suggestions);
  //   }
  // };
  useEffect(() => {
    if (
      // area.current?.value === "" &&
      // unit.current?.value === "" &&
      // section.current?.value === "" &&
      // project.current?.value === "" &&
      filterVal?.area === "" &&
      filterVal?.unit === "" &&
      filterVal?.section === "" &&
      filterVal?.project === "" &&
      filterVal?.category === "" &&
      filterVal?.tags?.length === 0
    ) {
      setallUnit(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(RemoveSCFromInputs(project?.current?.value), "i");
      let regex2 = new RegExp(RemoveSCFromInputs(section?.current?.value), "i");
      let regex3 = new RegExp(RemoveSCFromInputs(unit?.current?.value), "i");
      let regex4 = new RegExp(RemoveSCFromInputs(area?.current?.value), "i");
      let regex5 = new RegExp(
        RemoveSCFromInputs(category?.current?.value),
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          (project?.current?.value === "" ||
            regex1.test(val?.Project?.ProjectName)) &&
          (section?.current?.value === "" ||
            regex2.test(val?.Section?.SectionName)) &&
          (category?.current?.value === "" ||
            regex5.test(val?.Category?.CategoryName)) &&
          (unit?.current?.value === "" || regex3.test(val?.UnitName)) &&
          (area?.current?.value === "" || regex4.test(val?.area)) &&
          (filterVal?.tags?.length === 0 ||
            filterVal?.tags?.every((element) =>
              val.UnitTags?.some((tag) => tag?.Tag?.TagId === element?.TagId)
            ))
      );
      setallUnit(suggestions);
    }
  }, [filterVal, rows]);

  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Units"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="unit">
        <UnitFilterDrawer
          project={project}
          category={category}
          handleChangeVal={handleChangeVal}
          rows={rows}
          section={section}
          unit={unit}
          setcount={setcount}
          setallUnit={setallUnit}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          // onTextChange={onTextChange}
          area={area}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allUnit}
            columns={columns}
          />
          {status === "loading" || status2 === "loading" ? <Loader /> : null}
        </Box>
      </Box>
      {showModal2 && (
        <DocumentModal
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Units"
          setShowModal={setShowModal2}
          showModal={showModal2}
        />
      )}
      {documentDetailModal && (
        <DocumentDetailModal
          DocumentsData={DocumentsData}
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Units"
          setShowModal={setdocumentDetailModal}
          setformModal={setShowModal2}
          showModal={documentDetailModal}
        />
      )}
      {unitInfoModal && (
        <UnitInfoModal
          unitRow={unitRow}
          setunitRow={setunitRow}
          setunitInfoModal={setunitInfoModal}
          unitInfoModal={unitInfoModal}
        />
      )}

      <BookUnitModal
        open={showBookUnitModal}
        onClose={() => {
          setSelectedUnitId(null);
          setShowBookUnitModal(false);
        }}
        selectedUnitId={selectedUnitId}
      />
    </>
  );
}

export default Unit;
Unit.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#unit",
    content: (
      <>
        <p size="large">Units Page</p>
        <p>
          Welcome to Units Management – your control center for managing
          inventory units. Units play a crucial role in defining the quantities
          and measurements of inventory items, ensuring accuracy and consistency
          in inventory management.
          <br />
          <br />
          Use the intuitive interface to define and maintain unit
          specifications. Click &apos;New&apos;.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
