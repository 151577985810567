import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPersonsPopulate,
  getPersonsDetails,
} from "../../../../Redux/Slice/PersonSlice/PersonSlice";
import Loader from "../../../Loader/Loader";
import {
  fetchUserPopulate,
  getUserDetails,
} from "../../../../Redux/Slice/Settings/UserSlice/UserSlice";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});

function QuotesFilterDrawer({
  setcount,
  setfilterVal,
  filterVal,
  handleChangeVal,
  setallQuotes,
  rows,
  setdrawerOpen,
  drawerOpen,
}) {
  const { contentBox, fieldBox } = useStyles();
  const { PersonsPopulate, status } = useSelector(getPersonsDetails);
  const { UserPopulate } = useSelector(getUserDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPersonsPopulate());
    dispatch(fetchUserPopulate());
  }, [dispatch]);
  const handleClose = () => {
    setdrawerOpen(!open);
  };
  console.log(filterVal);
  const handleRemoveAll = () => {
    for (const value in filterVal) {
      filterVal[value] = "";
    }
    setcount(0);
  };
  return (
    <React.Fragment>
      {status == "loading" && <Loader />}
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Filter</Typography>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  setallQuotes(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            <Box>
              <Typography>Subject</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="name"
                  variant="outlined"
                  size="small"
                  value={filterVal?.name}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Subject"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      name: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Sales Person</Typography>
              <Box className={fieldBox}>
                <Select
                  name="salesperson"
                  variant="outlined"
                  size="small"
                  // defaultValue={filterVal?.salesperson}
                  value={filterVal?.salesperson}
                  // type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Sales Person"
                  className="input_border"
                  select
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                >
                  <MenuItem value="">select</MenuItem>
                  {UserPopulate?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item?.FullName}>
                        {item?.FullName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      salesperson: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Customer</Typography>
              <Box className={fieldBox}>
                <Select
                  name="person"
                  variant="outlined"
                  size="small"
                  // defaultValue={filterVal?.salesperson}
                  value={filterVal?.person}
                  onChange={(e, val) => {
                    console.log(e.target);
                    console.log(val);
                    handleChangeVal(e);
                  }}
                  // type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Customer"
                  className="input_border"
                  select
                  // onChange={(e) => { handleChangeVal(e); }}
                >
                  <MenuItem value="">select</MenuItem>
                  {PersonsPopulate?.Data?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item?.Name}>
                        {item?.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      person: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Sub Total</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="subtotal"
                  variant="outlined"
                  size="small"
                  value={filterVal?.subtotal}
                  type="number"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Sub Total"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      subtotal: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Discount</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="discount"
                  variant="outlined"
                  size="small"
                  value={filterVal?.discount}
                  type="number"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Discount"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      discount: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Tax</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="tax"
                  variant="outlined"
                  size="small"
                  value={filterVal?.tax}
                  type="number"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Tax"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      tax: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Adjustment</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="adjustment"
                  variant="outlined"
                  size="small"
                  value={filterVal?.adjustment}
                  type="number"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Adjustment"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      adjustment: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Grand Total</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="drandtotal"
                  variant="outlined"
                  size="small"
                  value={filterVal?.drandtotal}
                  type="number"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Grand Total"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      drandtotal: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Expired At</Typography>
              <Box className={fieldBox}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  value={filterVal?.createdate}
                  size="small"
                  name="createdate"
                  type="date"
                  autoComplete="off"
                  placeholder="Created Date"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  fontSize="small"
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      enddate: "",
                      createdate: "",
                    });
                  }}
                  sx={{ cursor: "pointer" }}
                />
                {/* To
                <TextField
                  variant="outlined"
                  sx={{ width: "150px" }}
                  value={filterVal?.enddate}
                  size='small'
                  name="enddate"
                  type="date"
                  autoComplete="off"
                  placeholder="Created Date"
                  className="input_border"
                  onChange={(e) => { handleChangeVal(e); }}

                />
                <CloseIcon fontSize='small' onClick={() => {
                  setfilterVal({
                    ...filterVal,
                    enddate: '',
                    createdate: ''

                  });


                }} sx={{ cursor: "pointer" }} /> */}
              </Box>
            </Box>
            {/* <Box>
              <Typography>Created At</Typography>
              <Box className={fieldBox}>
                <TextField
                  sx={{ width: "150px" }}
                  variant="outlined"
                  value={filterVal?.createdate}
                  size='small'
                  name="createdate2"
                  type="date"
                  autoComplete="off"
                  placeholder="Created Date"
                  className="input_border"
                  onChange={(e) => { handleChangeVal(e); }}
                />
                To
                <TextField
                  variant="outlined"
                  sx={{ width: "150px" }}
                  value={filterVal?.enddate2}
                  size='small'
                  name="enddate2"
                  type="date"
                  autoComplete="off"
                  placeholder="Created Date"
                  className="input_border"
                  onChange={(e) => { handleChangeVal(e); }}

                />
                <CloseIcon fontSize='small' onClick={() => {
                  setfilterVal({
                    ...filterVal,
                    enddate2: '',
                    createdate2: ''

                  });


                }} sx={{ cursor: "pointer" }} />
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default QuotesFilterDrawer;
QuotesFilterDrawer.propTypes = {
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  setallQuotes: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
};
