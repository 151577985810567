import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
});
function QuoteInformatio({
  handleNext,
  setquoteInfo,
  quoteInfo,
  quotesPopulate,
  state,
  singleLead,
  setLeadId,
}) {
  const {
    // container,
    // subContainer,
    formContainer,
  } = useStyle();
  const navigate = useNavigate();
  const [initialValues] = useState({
    userId: quoteInfo ? quoteInfo?.userId : "",
    subject: quoteInfo ? quoteInfo?.subject : "",
    description: quoteInfo ? quoteInfo?.description : "",
    expiredAt: quoteInfo ? quoteInfo?.expiredAt : "",
    personId: quoteInfo ? quoteInfo?.personId : null,
    leadId: quoteInfo ? quoteInfo?.leadId : null,
  });
  //   console.log(state);
  //   console.log(quotesPopulate);
  useEffect(() => {
    if (state) {
      handleEdit(state);
    }
  }, [state]);

  useEffect(() => {
    if (singleLead) {
      formik?.setFieldValue("personId", {
        Id: singleLead?.PersonId,
        Name: singleLead?.PersonName,
      });
      formik?.setFieldValue("leadId", {
        Id: singleLead?.Id,
        Title: singleLead?.Title,
      });
      formik?.setFieldValue("subject", quoteInfo?.subject);

      formik?.setFieldValue("description", quoteInfo?.description);
      formik?.setFieldValue("userId", singleLead?.UserId);
    }
  }, [singleLead]);

  const handleEdit = (values) => {
    console.log("values", values);
    formik.handleChange({
      target: {
        name: "userId",
        value: values?.UserId || "",
      },
    });
    formik.handleChange({
      target: {
        name: "subject",
        value: values?.subject || "",
      },
    });

    formik.handleChange({
      target: {
        name: "description",
        value: values?.description || "",
      },
    });
    formik.handleChange({
      target: {
        name: "expiredAt",
        value: moment(values?.expiredat).format("YYYY-MM-DD") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "personId",
        value: values?.Personobj ? values?.Personobj : null,
      },
    });
    formik.handleChange({
      target: {
        name: "leadId",
        value: values?.Leadobj === null ? null : values?.Leadobj,
      },
    });
  };
  // console.log(initialValues);
  const handleNavigate = () => {
    navigate(-1);
  };
  const handleSubmit = (values) => {
    // setSubmitting(true);
    // const obj = { ...values, country: values?.label };

    setquoteInfo(values);
    handleNext();
    // setSubmitting(false);
    // resetForm();
  };
  const ValidationSchema = Yup.object().shape({
    userId: Yup.string().required("Please Enter user owner"),
    subject: Yup.string().required("Please Enter Subject"),
    expiredAt: Yup.string().required("Please Enter Expiry Date"),
    personId: Yup.object().nullable().required("Please Enter Person"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  //console.log(quotesPopulate?.Data?.userData);
  useEffect(() => {
    if (formik.values.leadId) {
      setLeadId(formik.values.leadId);
    }
  }, [formik.values.leadId]);

  return (
    <Paper elevation={3} sx={{ padding: "20px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <FormControl fullWidth>
            <Typography>Sales Owner</Typography>
            <TextField
              name="userId"
              value={formik.values?.userId ? formik.values?.userId : ""}
              size={"small"}
              select
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.userId) && Boolean(formik.touched.userId)
              }
              helperText={
                Boolean(formik.errors.userId) &&
                Boolean(formik.touched.userId) &&
                formik.errors.userId
              }
            >
              <MenuItem value="">Select</MenuItem>
              {quotesPopulate?.Data?.userData?.map((user, i) => {
                return (
                  <MenuItem key={i} value={user?.Id}>
                    {user?.FullName}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
          <FormControl fullWidth>
            <Typography>Subject</Typography>
            <TextField
              name="subject"
              value={formik.values?.subject}
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.subject) &&
                Boolean(formik.touched.subject)
              }
              helperText={
                Boolean(formik.errors.subject) &&
                Boolean(formik.touched.subject) &&
                formik.errors.subject
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography>Description</Typography>
            <TextField
              name="description"
              value={formik.values?.description}
              size={"small"}
              multiline
              rows={4}
              fullWidth
              onChange={formik.handleChange}
              //   error={Boolean(formik.errors.description) && Boolean(formik.touched.description)}
              //   helperText={Boolean(formik.errors.description) && Boolean(formik.errors.description)}
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography>Expiry Date</Typography>
            <TextField
              name="expiredAt"
              type={"date"}
              value={formik.values?.expiredAt}
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.expiredAt) &&
                Boolean(formik.touched.expiredAt)
              }
              helperText={
                Boolean(formik.errors.expiredAt) &&
                Boolean(formik.touched.expiredAt) &&
                formik.errors.expiredAt
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography>Person</Typography>
            <Autocomplete
              name="personId"
              //   options={["hjehje"]}
              options={quotesPopulate?.Data?.personData || []}
              getOptionLabel={(option) => option?.Name}
              size={"small"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //   name="personId"
                  error={
                    Boolean(formik.errors.personId) &&
                    Boolean(formik.touched.personId)
                  }
                  helperText={
                    Boolean(formik.errors.personId) &&
                    Boolean(formik.touched.personId) &&
                    formik.errors.personId
                  }
                />
              )}
              value={formik?.values?.personId}
              onChange={(__, value) => {
                formik.setFieldValue("personId", value);
                // formik.values?.date=e.target.value
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography>Lead</Typography>
            <Autocomplete
              name="leadId"
              options={quotesPopulate?.Data?.leadData || []}
              getOptionLabel={(option) => option?.Title}
              size={"small"}
              renderInput={(params) => <TextField {...params} />}
              value={formik.values?.leadId}
              onChange={(__, value) => {
                formik.setFieldValue("leadId", value);
                // formik.values?.date=e.target.value
              }}
            />
          </FormControl>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button onClick={handleNavigate}>
                  <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                </Button>
              </Box>
              <Box>
                {/* {
                  activeStep === steps.length - 1 ?    <Button type='submit' sx={{ backgroundColor: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.white.main }}>
                  less
                </Button> :

                 
                    <Button sx={{ backgroundColor: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.white.main }} onClick={handleNext}>
                    Next
                  </Button>
                } */}

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

export default QuoteInformatio;
QuoteInformatio.propTypes = {
  // activeStep: PropTypes.any.isRequired,
  // steps: PropTypes.string.isRequired,
  handleNext: PropTypes.any,
  setquoteInfo: PropTypes.any,
  quoteInfo: PropTypes.any,
  quotesPopulate: PropTypes.any,
  state: PropTypes.any,
  singleLead: PropTypes.any,
  setLeadId: PropTypes.any,
};
