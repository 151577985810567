import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CreateTagsApi } from "../../../Api/Settings/CreateTagsApi";
import { fetchUnitPopulate } from "../../../Redux/Slice/UnitSlice/UnitSlice";

function AddTagsModal({ showModal, handleClosed, setsnackBarData, dispatch }) {
  const [borderColor, setborderColor] = useState(null);
  const [colorError, setColorError] = useState(null);

  const [selectedColor, setselectedColor] = useState("");
  const [colors] = useState([
    "rgb(50,205,50)",
    "rgb(250,218,94)",
    " rgb(219,112,147)",
    "rgb(39, 182, 187)",
    "rgb(251, 138, 63)",
    "rgb(51, 124, 255)",
  ]);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Tag Name"),
  });
  const initialValues = {
    name: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (selectedColor !== "") {
        handleSubmit(values, resetForm, setSubmitting);
      } else {
        setColorError(true);
        setSubmitting(false);
      }
    },
  });

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const obj = { ...values, color: selectedColor, tagType: "Unit" };
    const response = await CreateTagsApi(obj);
    setsnackBarData(response.snackBarData);

    if (response?.data.IsSuccess) {
      setSubmitting(false);
      resetForm();
      dispatch(fetchUnitPopulate());
    }

    setSubmitting(false);
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            Add Unit Tags
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "600px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <FormControl fullWidth>
              <Typography>Name</Typography>
              <TextField
                name="name"
                value={formik.values?.name}
                size={"small"}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors.name) && Boolean(formik.touched.name)
                }
                helperText={
                  Boolean(formik.errors.name) && Boolean(formik.touched.name)
                    ? formik.errors.name
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Color</Typography>

              <Box sx={{ display: "flex", gap: "10px" }}>
                {colors?.map((clr, idx) => {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: clr,
                        borderRadius: "100%",
                        border: borderColor === idx ? "2px solid white" : clr,
                        boxShadow:
                          borderColor === idx
                            ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                            : null,
                        transform: borderColor === idx ? "scale(0.9.5)" : null,
                        transition: "0.2s",
                      }}
                      onClick={() => {
                        setborderColor(idx);
                        setselectedColor(clr);
                        setColorError(false);
                      }}
                    ></Box>
                  );
                })}
              </Box>
              <FormHelperText sx={{ color: "red", textAlign: "left" }}>
                {colorError && "Please Select Color"}
              </FormHelperText>
            </FormControl>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  <Button onClick={handleClosed}>
                    <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                  </Button>
                </Box>
                <Box>
                  {formik.isSubmitting ? (
                    <Box>
                      {/* <Loader/> */}
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button variant="contained" type="submit">
                      <Typography sx={{ fontWeight: "bold" }}>Save</Typography>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddTagsModal;

AddTagsModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  product: PropTypes.any,
  setsnackBarData: PropTypes.any,
  dispatch: PropTypes.any,
};
