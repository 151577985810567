import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Quotes: [],
  QuotesPopulate: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchQuotes = createAsyncThunk(
  "/Quotes",
  async (selectedFilter) => {
    const response = await axiosInstance.get(
      `/Quotes?status=${selectedFilter}`
    );
    console.log(response);
    return response;
  }
);
export const fetchQuotesPopulate = createAsyncThunk(
  "/Quotes/Populate",
  async () => {
    const response = await axiosInstance.get(`/Quotes/Populate`);
    console.log(response);
    return response;
  }
);

const QuoteSlice = createSlice({
  name: "Quotes",
  initialState,
  reducers: {
    QuotesAdded: {
      reducer(state, action) {
        state.Quotes?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchQuotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Quotes = action.payload.data.Data;
      })
      .addCase(fetchQuotes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchQuotesPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuotesPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.QuotesPopulate = action.payload.data;
      })
      .addCase(fetchQuotesPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getQuotesDetails = (state) => state.Quotes;

export const { QuotesAdded } = QuoteSlice.actions;

export default QuoteSlice.reducer;
