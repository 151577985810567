import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  // DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import {
  Autocomplete,
  Badge,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { useContext } from "react";
import PropTypes from "prop-types";

import Joyride from "react-joyride";
import { AppContext } from "../../../Context/TourContext/TourContext";

import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";

import BookedUnitInfoModal from "../../../Components/Inventory/BookedUnit/BookedUnitInfoModal";
import Loader from "../../../Components/Loader/Loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  fetchUserPerformance,
  getReportsDetails,
} from "../../../Redux/Slice/ReportSlice/ReportSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import { StripedDataGrid } from "../../../Utils/StrippedDataGrid";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import { useNavigate } from "react-router-dom";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";
import MessageIcon from "@mui/icons-material/Message";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import GroupsIcon from "@mui/icons-material/Groups";
import AppTooltip from "../../../Utils/AppTooltip";
import {
  fetchGroups,
  getGroupsDetails,
} from "../../../Redux/Slice/Settings/GroupsSlice/GroupsSlice";

// const useStyles = makeStyles(() => {
//   return {
//     iconWithText: {
//       display: "flex",
//       alignItems: "center",
//       flexWrap: "wrap",
//       flexDirection: "row",
//       justifyContent: "space-between",
//     },
//   };
// });
function UserPerformanceReport({ roles }) {
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const [pagesize, setpagesize] = useState(totalRows);
  // const [rows, setrows] = React.useState([]);
  const [allReport, setallReport] = useState([]);
  // const [drawerOpen, setdrawerOpen] = useState(false);
  // const [filterVal, setfilterVal] = useState(null);
  // const [count, setcount] = useState(0);

  const { setState, state } = useContext(AppContext);
  const [unitInfoModal, setunitInfoModal] = useState(false);
  const [unitRow, setunitRow] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState(null);
  const [hover, setHover] = useState(null);
  const [hover2, setHover2] = useState(null);
  const { Groups } = useSelector(getGroupsDetails);
  const { UserPerformance, status } = useSelector(getReportsDetails);
  const [anchorEl, setAnchorEl] = React.useState(null);
  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "UserPerformanceReport");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  const ValidationSchema = Yup.object().shape({
    from: Yup.string().required("Please Enter From Date"),
    to: Yup.string().required("Please Enter To Date"),
  });
  const initialValues = {
    groupId: null,
    from: "",
    to: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      dispatch(
        fetchUserPerformance({ ...values, groupId: values?.groupId?.Id })
      );
    },
  });
  console.log(formik.values);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "16px", // Add padding to align with right end
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {roles?.some((role) => role === "export_Create") && (
                <GridToolbarExport
                  csvOptions={{ fileName: "Active Employees" }}
                />
              )}
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarColumnsButton />
              {/* <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button> */}
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  paddingTop: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Autocomplete
                  options={Groups || []}
                  getOptionLabel={(option) => option?.Name}
                  renderOption={(props, option) => {
                    return (
                      <ListItem {...props} key={option.Id}>
                        <Typography>{option?.Name}</Typography>
                      </ListItem>
                    );
                  }}
                  listStyle={{ maxHeight: 200, overflow: "auto" }}
                  sx={{ width: "100%", maxWidth: "150px" }}
                  fullWidth
                  name="groupId"
                  value={formik.values.groupId}
                  // onChange={formik.handleChange}
                  placeholder="Groups"
                  onChange={(event, value) => {
                    formik.setFieldValue("groupId", value);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="input_border"
                      placeholder="Groups"
                    />
                  )}
                />

                <TextField
                  id="dashboard-from"
                  label="From"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="from"
                  value={formik.values.from}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  size="small"
                  type={"date"}
                  error={
                    Boolean(formik.errors.from) && Boolean(formik.touched.from)
                  }
                  helperText={
                    Boolean(formik.errors.from) &&
                    Boolean(formik.touched.from) &&
                    formik.errors.from
                  }
                />
                <TextField
                  name="to"
                  id="dashboard-to"
                  label="To"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.to}
                  variant="outlined"
                  size="small"
                  type={"date"}
                  error={
                    Boolean(formik.errors.to) && Boolean(formik.touched.to)
                  }
                  helperText={
                    Boolean(formik.errors.to) &&
                    Boolean(formik.touched.to) &&
                    formik.errors.to
                  }
                />
                <Button
                  id="dashboard-get"
                  variant="contained"
                  className="get"
                  type="submit"
                >
                  Get
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  // useEffect(() => {
  //   let sum = 0;
  //   for (const value in filterVal) {
  //     if (filterVal[value] !== "") {
  //       sum = sum + 1;
  //     }
  //   }
  //   setcount(sum);
  // }, [filterVal]);
  useEffect(() => {
    if (UserPerformance?.length === 0)
      dispatch(fetchUserPerformance(formik?.values));
  }, [dispatch]);

  useEffect(() => {
    let rowData = [];
    UserPerformance?.map((item, i) => {
      rowData.push({
        id: i + 1,
        totalCompleteActivities: item?.totalCompleteActivities,
        totalActivities: item?.totalActivities,
        remainingNumberOfPlot: item?.remainingNumberOfPlot,
        numberOfPlot: item?.numberOfPlot,
        leadWonValue: item?.leadWonValue,
        leadWonCount: item?.leadWonCount,
        contactedLead: item?.contactedLead,
        UserName: item?.User,
        userId: item?.UserId,
        totalActivitiesDetails: item?.totalActivitiesDetails,
        totalCompleteActivitiesDetails: item?.totalCompleteActivitiesDetails,
        TotalLead: item?.TotalLead,
        RottenLead: item?.RottenLead,
      });
    });
    // setrows(rowData);
    setallReport(rowData);
  }, [UserPerformance]);

  const columns = [
    { field: "id", headerName: "Sr", width: 60 },
    {
      field: "UserName",
      headerName: "User",
      width: 250,
    },
    {
      field: "TotalLead",
      headerName: "Total Leads",
      width: 150,
    },
    {
      field: "leadWonCount",
      headerName: "Won Leads",
      width: 150,
    },
    {
      field: "contactedLead",
      headerName: "Active Leads",
      width: 150,
    },
    {
      field: "RottenLead",
      headerName: "Idle Leads",
      width: 150,
    },
    {
      field: "leadWonValue",
      headerName: "Won Lead Value",
      width: 150,
      renderCell: (celval) => {
        return celval.formattedValue?.toLocaleString();
      },
    },
    {
      field: "totalActivities",
      headerName: "Total Activities",
      width: 220,
      renderCell: (celval) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"98%"}
            onMouseEnter={() => setHover(celval?.row?.id)}
            onMouseLeave={() => setHover(null)}
          >
            {hover === celval?.row?.id ? null : celval.formattedValue}
            {hover === celval?.row?.id ? (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <AppTooltip title="Emails">
                  <Badge
                    badgeContent={
                      celval?.row?.totalActivitiesDetails?.totalEmail
                    }
                    color="info"
                  >
                    <EmailIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    />
                  </Badge>
                </AppTooltip>

                <AppTooltip title="Messages">
                  <Badge
                    badgeContent={
                      celval?.row?.totalActivitiesDetails?.totalMessage
                    }
                    color="info"
                  >
                    <MessageIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    />
                  </Badge>
                </AppTooltip>
                <AppTooltip title="Calls">
                  <Badge
                    badgeContent={
                      celval?.row?.totalActivitiesDetails?.totalCall
                    }
                    color="info"
                  >
                    <CallIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    />
                  </Badge>
                </AppTooltip>
                <AppTooltip title="Meetings">
                  <Badge
                    badgeContent={
                      celval?.row?.totalActivitiesDetails?.totalMeeting
                    }
                    color="info"
                  >
                    <GroupsIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    />
                  </Badge>
                </AppTooltip>
              </Box>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: "totalCompleteActivities",
      headerName: "Completed Activities",
      width: 220,
      renderCell: (celval) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"98%"}
            onMouseEnter={() => setHover2(celval?.row?.id)}
            onMouseLeave={() => setHover2(null)}
          >
            {hover2 === celval?.row?.id ? null : celval.formattedValue}
            {hover2 === celval?.row?.id ? (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <AppTooltip title="Emails">
                  <Badge
                    badgeContent={
                      celval?.row?.totalCompleteActivitiesDetails?.completeEmail
                    }
                    color="info"
                  >
                    <EmailIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    />
                  </Badge>
                </AppTooltip>

                <AppTooltip title="Messages">
                  <Badge
                    badgeContent={
                      celval?.row?.totalCompleteActivitiesDetails
                        ?.completeMessage
                    }
                    color="info"
                  >
                    <MessageIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    />
                  </Badge>
                </AppTooltip>
                <AppTooltip title="Calls">
                  <Badge
                    badgeContent={
                      celval?.row?.totalCompleteActivitiesDetails?.completeCall
                    }
                    color="info"
                  >
                    <CallIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    />
                  </Badge>
                </AppTooltip>
                <AppTooltip title="Meetings">
                  <Badge
                    badgeContent={
                      celval?.row?.totalCompleteActivitiesDetails
                        ?.completeMeeting
                    }
                    color="info"
                  >
                    <GroupsIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                    />
                  </Badge>
                </AppTooltip>
              </Box>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: "numberOfPlot",
      headerName: "Assigned Targets",
      width: 150,
    },
    {
      field: "remainingNumberOfPlot",
      headerName: "Remaining Targets",
      width: 150,
    },

    {
      field: "action",
      headerName: "Action",
      width: 110,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <IconButton
              id="basic-button"
              aria-controls={anchorEl ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
              onClick={(event) => handleClick(event, celval.row)}
            >
              <MoreVertIcon
                sx={{
                  color: (theme) => theme?.palette?.primary?.main,
                }}
                fontSize="small"
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              elevation={1}
            >
              <MenuItem
                onClick={() => {
                  navigate("/reports/user-leads", {
                    state: {
                      Id: currentRow?.userId,
                      Name: currentRow?.UserName,
                    },
                  });
                }}
              >
                <ListItemIcon>
                  <LeaderboardIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText> Leads Report</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate("/reports/user-activity", {
                    state: {
                      Id: currentRow?.userId,
                      Name: currentRow?.UserName,
                    },
                  })
                }
              >
                <ListItemIcon>
                  <AvTimerIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText> Activity Report</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate("/reports/user-won-leads", {
                    state: {
                      Id: currentRow?.userId,
                      Name: currentRow?.UserName,
                    },
                  })
                }
              >
                <ListItemIcon>
                  <EmojiEventsIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText> Won Lead Report</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate("/reports/user-targets", {
                    state: {
                      Id: currentRow?.userId,
                      Name: currentRow?.UserName,
                    },
                  })
                }
              >
                <ListItemIcon>
                  <TrackChangesIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText> Target Report</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  // const handleChangeVal = (e) => {
  //   let value = RemoveSCFromInputs(e.target.value);

  //   setfilterVal({
  //     ...filterVal,
  //     [e.target.name]: value || "",
  //   });
  // };

  // const onTextChange = () => {
  //   if (
  //     person.current?.value === "" &&
  //     item.current?.value === "" &&
  //     section.current?.value === "" &&
  //     project.current?.value === "" &&
  //     leadTitle.current?.value === ""
  //   ) {
  //     setallReport(rows);
  //   } else {
  //     let suggestions = [];
  //     let regex1 = new RegExp(RemoveSCFromInputs(project.current.value), "i");
  //     let regex2 = new RegExp(RemoveSCFromInputs(section.current.value), "i");
  //     let regex3 = new RegExp(RemoveSCFromInputs(item.current.value), "i");
  //     let regex4 = new RegExp(RemoveSCFromInputs(person.current.value), "i");
  //     let regex5 = new RegExp(RemoveSCFromInputs(leadTitle.current.value), "i");
  //     suggestions = rows?.filter(
  //       (val) =>
  //         (project.current.value === "" ||
  //           regex1.test(val?.Project?.ProjectName)) &&
  //         (section.current.value === "" ||
  //           regex2.test(val?.Section?.SectionName)) &&
  //         (item.current.value === "" || regex3.test(val?.item)) &&
  //         (person.current.value === "" || regex4.test(val?.person)) &&
  //         (leadTitle.current.value === "" || regex5.test(val?.title))
  //     );
  //     setallReport(suggestions);
  //   }
  // };

  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "UserPerformanceReport"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}
      </Box>
      <Box id="item">
        {/* <BookedUnitFilterDrawer
          handleChangeVal={handleChangeVal}
          rows={rows}
          project={project}
          section={section}
          person={person}
          item={item}
          leadTitle={leadTitle}
          setcount={setcount}
          setallReport={setallReport}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        /> */}
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <StripedDataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allReport}
            columns={columns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
          {status === "loading" ? <Loader /> : null}
        </Box>
      </Box>

      {unitInfoModal && (
        <BookedUnitInfoModal
          unitRow={unitRow}
          setunitRow={setunitRow}
          setunitInfoModal={setunitInfoModal}
          unitInfoModal={unitInfoModal}
        />
      )}
    </>
  );
}

export default UserPerformanceReport;
UserPerformanceReport.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#item",
    content: (
      <>
        <p size="large">User Performance Report</p>
        <p>
          Welcome to the User Performance Report Section! Here, you&apos;ll find
          a detailed overview of your performance, including metrics like total
          leads, won leads, active and idle leads, lead value, activities, and
          targets.
          <br />
          <br />
          Use these insights to track your progress and stay on top of your
          goals.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
