import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from 'moment';
const useStyles = makeStyles(() => {
    return {
        ModalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "10px",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.45) !important",
        },
        Container: {
            width: "100%",
            maxWidth: "800px",
            maxHeight: "90vh",
            padding: "20px",
            border: "none",
            position: "relative",
            background: "white !important",
            borderRadius: "10px",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
            overflowY: "auto",
        },
        TableHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        profile: {
            display: "flex",
            gap: "20px",
            border: "1px solid #edf1f4",
            padding: "5px 10px",
            borderRadius: "5px",
            alignItems: "center",
        },
        iconBox: {
            display: "flex",
            gap: "10px",
            alignItems: "center",
        },
    };
});
function MeetingInfoModal({
    meetingInfoModal,
    setMeetingInfoModal,
    setmeetingRow,
    meetingRow,
    participant
}) {
    const { Container, ModalContainer, TableHeader, } = useStyles();
    return (
        <>
            <Modal
                open={meetingInfoModal}
                onClose={() => {
                    setMeetingInfoModal(false);
                }}
            >
                <Box className={ModalContainer}>
                    <Box className={Container}>
                        <Box className={TableHeader}>
                            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                                Meeting Details
                            </Typography>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setmeetingRow(null);
                                    setMeetingInfoModal(false);
                                }}
                            >
                                <HighlightOffIcon
                                    color="primary"
                                    sx={{
                                        fontSize: "28px",
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                            <Box sx={{
                                padding: "5px 10px",
                                borderRadius: "5px",
                                border: "1px solid #edf1f4",
                            }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" }, 
                                        justifyContent: "space-between",
                                        width: "100%",  
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Meeting Title:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: { xs: "60px", sm: "20px" },
                                                }}
                                            >
                                                {meetingRow?.Title}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>Priority:</Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: { xs: "105px", sm: "60px" },
                                                }}
                                            >
                                                {meetingRow?.Priority}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Start time:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: { xs: "80px", sm: "41px" },
                                                }}
                                            >
                                                {moment(meetingRow?.StartDate).format('DD-MMM-YYYY HH:mm a')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Added By:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: { xs: "80px", sm: "41px" },
                                                }}
                                            >
                                                {meetingRow?.AddedByName}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Type Name:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: { xs: "67px", sm: "30px" },
                                                }}
                                            >
                                                {meetingRow?.TypeName}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>Status:</Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: "105px",
                                                }}
                                            >
                                                {meetingRow?.Status}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Agenda:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: "95px",
                                                }}
                                            >
                                                {meetingRow?.Agenda}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Expected Duration:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: "9px",
                                                }}
                                            >
                                                {`${meetingRow?.Duration} Minutes`}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>AddedOn:</Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: { xs: "80px", sm: "85px" },
                                                }}
                                            >
                                                {moment(meetingRow?.AddedOn).format('DD-MMM-YYYY HH:mm a')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                            marginTop:"10px"
                                            }}>
                                    {meetingRow?.Location && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: { sx: "column", md: "row" },
                                                gap: "3px",
                                                width: "100%",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Location:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: "45px",
                                                    wordBreak: "break-word",
                                                    overflowWrap: "break-word",
                                                    flex: "1",
                                                }}
                                            >
                                                {meetingRow?.Location}
                                            </Typography>
                                        </Box>
                                    )}
                                    {meetingRow?.Notes && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: { sx: "column", md: "row" },
                                                gap: "3px",
                                                width: "100%",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Description:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: { xs: "20px", sm: "27px" },
                                                    wordBreak: "break-word",
                                                    overflowWrap: "break-word",
                                                    flex: "1",
                                                }}
                                            >
                                                {meetingRow?.Notes}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "3px",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "bold" }}>
                                            Participant:
                                        </Typography>
                                        <Typography sx={{ paddingLeft: "27px" }}>
                                            {participant.map((item) => item).join(", ")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                      {(meetingRow?.Status=="Completed" || meetingRow?.Status==="Cancelled") &&
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    border: "1px solid #edf1f4",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                    }}
                                >
                                    {meetingRow?.Reason && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Reason:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: "50px",
                                                }}
                                            >
                                                {meetingRow?.Reason}
                                            </Typography>
                                        </Box>
                                    )}
                                    {meetingRow?.ActualDuration && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Actual Duration:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    paddingLeft: "50px",
                                                }}
                                            >
                                                {`${meetingRow?.ActualDuration} Minutes`}
                                            </Typography>
                                        </Box>
                                    )}
                                    {meetingRow?.Image && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "3px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Image:
                                            </Typography>
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    height: "50%",
                                                    width: "30%",
                                                    borderRadius: "5px",
                                                    border: "1px solid rgb(214, 214, 214)",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer !important",
                                                    justifyContent: "center",
                                                    padding: "5px",
                                                    marginLeft: "100px",
                                                }}
                                            >
                                                <img
                                                    src={meetingRow?.Image}
                                                    alt="Image"
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                                }

                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default MeetingInfoModal;
MeetingInfoModal.propTypes = {
    meetingInfoModal: PropTypes.any,
    setMeetingInfoModal: PropTypes.any,
    meetingRow: PropTypes.any,
    setmeetingRow: PropTypes.any,
    participant: PropTypes.any,
};
