import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    Modal,
    OutlinedInput,
    Typography,
  } from "@mui/material";
  import React, {useContext} from "react";
  import { makeStyles } from "@mui/styles";
  import PropTypes from "prop-types";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import * as Yup from "yup";
  import { useFormik } from "formik";
  import theme from "../../theme";
  import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CheckPasswordApi } from "../../Api/FacebookPagesApi/CheckPasswordApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
  const useStyles = makeStyles(() => {
    return {
      ModalContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "10px",
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.45) !important",
  
        // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      },
      Container: {
        width: "800px",
        padding: "20px",
        border: "none",
        position: "relative",
        background: "white !important",
        borderRadius: "10px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      },
      TableHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      container: {
        // height: "100%",
        width: "100%",
        padding: "0px 20px 20px 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          padding: "5px",
        },
      },
      subContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        // padding: "20px",
        width: "100%",
        // maxWidth: "700px",
        // minHeight: "calc(100vh - 215px)",
        [theme.breakpoints.down("md")]: {
          padding: "5px",
        },
      },
      formContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        textAlign: "left",
      },
    };
  });

function VerifyPasswordModal({
    showModal,
    setShowModal,
    setshowToken
  }) {
    const {
        Container,
        ModalContainer,
        TableHeader,
        container,
        subContainer,
        formContainer,
      } = useStyles();
      const [showPassword, setShowPassword] = React.useState(false);
      const handleClickShowPassword = () => setShowPassword((show) => !show);
      const { setsnackBarData } = useContext(SnackBarContext);
      const initialValues = {
        password: "",

      };
      const validationSchema = Yup.object().shape({
        password: Yup.string().required("Please Enter Password"),

      });

      const handleSave = async (values,resetForm,setSubmitting ) => {
        const response = await CheckPasswordApi(values);
        setsnackBarData(response.snackBarData);
        if (response?.data.IsSuccess) {
          setSubmitting(false);
          resetForm();
          setshowToken(true);
          setShowModal(false);
        }
    
        setSubmitting(false);
      };
      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
          handleSave(values, resetForm, setSubmitting);
        },
      });


  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Verify Your Password
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                  <FormControl fullWidth>
                  <Typography variant="" sx={{ padding: "5px 0px" }}>
                    Password
                  </Typography>
                  <OutlinedInput
                    // sx={{ borderRadius: "100px" }}
                    size="small"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    fullWidth
                    name="password"
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={
                      Boolean(formik.touched.password) &&
                      Boolean(formik.errors.password)
                    }
                    helperText={
                      Boolean(formik.touched.password) &&
                      formik.errors.password
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button type="submit" variant="contained">
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default VerifyPasswordModal;
VerifyPasswordModal.propTypes = {
    showModal: PropTypes.any,
    setShowModal: PropTypes.any,
    setshowToken: PropTypes.any,
  };
  