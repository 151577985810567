import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  // Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import Details from "./Details";
// import ContactPerson from "./ContactPerson";
import Products from "./Products";
import {
  fetchLeadsPopulate,
  getLeadsDetails,
} from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import { fetchUserProfile } from "../../Redux/Slice/Profile/ProfileSlice";
import SelectCustomer from "./SelectCustomer";
// import Cookies from "universal-cookie";

// const cookies = new Cookies();

function CreateLeads() {
  const { container, subContainer } = useStyle();

  const [activeStep, setActiveStep] = useState(0);
  const [detailsData, setdetailsData] = useState(null);
  const [contactPersonData, setcontactPersonData] = useState(null);
  const [leadsPopulate, setleadsPopulate] = useState([]);
  const [leadsViewData, setleadsViewData] = useState(null);
  const [publicLeadData, setPublicLeadData] = useState(null);
  const [leadsPiplineData, setleadsPiplineData] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [title, setTitle] = useState("");
  const [steps, setSteps] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const state = useLocation();
  const dispatch = useDispatch();
  const { LeadsPopulate, status } = useSelector(getLeadsDetails);

  useEffect(() => {
    dispatch(fetchLeadsPopulate());
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setleadsPopulate(LeadsPopulate);
  }, [LeadsPopulate]);

  useEffect(() => {
    if (state?.state?.singleLead) {
      setleadsViewData(state?.state?.singleLead);
    } else if (state?.state?.pipline) {
      setleadsPiplineData(state?.state?.pipline);
    } else if (state?.state?.publicLead) {
      setPublicLeadData(state?.state?.publicLead);
    }
  }, [state?.state]);

  useEffect(() => {
    if (leadsViewData) {
      setSteps([
        "Details",
        sessionStorage.getItem("BusinessType") === "General"
          ? "Products"
          : "Projects/Units",
        // "Contact Person",
      ]);
    } else {
      setSteps([
        "Customer",
        "Details",
        sessionStorage.getItem("BusinessType") === "General"
          ? "Products"
          : "Projects/Units",
        // "Contact Person",
      ]);
    }
  }, [leadsViewData]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <>
      {status == "loading" && <Loader />}
      <Box className={container}>
        <Box className={subContainer}>
          <Box>
            <Box
              role="presentation"
              onClick={(e) => e.preventDefault()}
            >
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ fontSize: "12px" }}
              >
                <Link
                  to="/dashboard"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Dashboard
                </Link>
                <Link
                  to="/Leads"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Leads
                </Link>
                <Typography
                  color="text.primary"
                  sx={{ fontSize: "12px" }}
                >
                  {leadsViewData ? "Update Lead" : "Create Lead"}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography
              variant="h5"
              sx={{ textAlign: "left", fontWeight: 400 }}
            >
              {leadsViewData ? "Update Lead" : "Create Lead"}
            </Typography>
          </Box>
          <Box>
            <Stepper
              sx={{ width: "100%", maxWidth: "400px" }}
              activeStep={activeStep}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step
                    key={label}
                    {...stepProps}
                  >
                    <StepLabel
                      onClick={state.state?.singleLead && handleStep(index)}
                      sx={{ cursor: state.state?.singleLead && "pointer" }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {activeStep === 0 ? (
            leadsViewData ? (
              <Details
                leadsViewData={leadsViewData}
                leadsPopulate={leadsPopulate?.Data}
                detailsData={detailsData}
                setdetailsData={setdetailsData}
                setcontactPersonData={setcontactPersonData}
                handleBack={handleBack}
                handleNext={handleNext}
                activeStep={activeStep}
                steps={steps}
                setCustomer={setCustomer}
                customer={customer}
                setTitle={setTitle}
                publicLeadData={publicLeadData}
                selectedCustomer={selectedCustomer}
              />
            ) : (
              <SelectCustomer
                leadsPopulate={leadsPopulate?.Data}
                handleNext={handleNext}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
              />
            )
          ) : activeStep === 1 ? (
            leadsViewData ? (
              <Products
                leadsViewData={leadsViewData}
                leadsPiplineData={leadsPiplineData}
                state={state}
                leadsPopulate={leadsPopulate}
                detailsData={detailsData}
                contactPersonData={contactPersonData}
                handleBack={handleBack}
                handleNext={handleNext}
                activeStep={activeStep}
                steps={steps}
                customer={customer}
                setCustomer={setCustomer}
                title={title}
                setTitle={setTitle}
                publicLeadData={publicLeadData}
              />
            ) : (
              <Details
                leadsViewData={leadsViewData}
                leadsPopulate={leadsPopulate?.Data}
                detailsData={detailsData}
                setdetailsData={setdetailsData}
                setcontactPersonData={setcontactPersonData}
                handleBack={handleBack}
                handleNext={handleNext}
                activeStep={activeStep}
                steps={steps}
                setCustomer={setCustomer}
                customer={customer}
                setTitle={setTitle}
                publicLeadData={publicLeadData}
                selectedCustomer={selectedCustomer}
              />
            )
          ) : activeStep === 2 ? (
            !leadsViewData ? (
              <Products
                leadsViewData={leadsViewData}
                leadsPiplineData={leadsPiplineData}
                state={state}
                leadsPopulate={leadsPopulate}
                detailsData={detailsData}
                contactPersonData={contactPersonData}
                handleBack={handleBack}
                handleNext={handleNext}
                activeStep={activeStep}
                steps={steps}
                customer={customer}
                setCustomer={setCustomer}
                title={title}
                setTitle={setTitle}
                publicLeadData={publicLeadData}
              />
            ) : null
          ) : null}
          {/* : activeStep === 2 ? 
            <ContactPerson
              leadsViewData={leadsViewData}
              contactPersonData={contactPersonData}
              setcontactPersonData={setcontactPersonData}
              handleBack={handleBack}
              handleNext={handleNext}
              activeStep={activeStep}
              steps={steps}
            /> */}
        </Box>
      </Box>
    </>
  );
}
export default CreateLeads;

const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
      alignItems: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
  };
});
