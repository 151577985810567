import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Sections: [],
  SectionPopulate: [],
  SectionTypes: [],
  SectionCategory: [],
  SectionSubCategory: [],

  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status3: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status4: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
  IsLogin: true,
};
export const fetchSections = createAsyncThunk(
  "/Sections/GetSections",
  async () => {
    const response = await axiosInstance.get(`/Sections/GetSections`);
    return response;
  }
);
export const fetchSectionPopulate = createAsyncThunk(
  "/Sections/GetPopulate",
  async () => {
    const response = await axiosInstance.get(`/Sections/GetPopulate`);
    return response;
  }
);
export const fetchSectionTypes = createAsyncThunk(
  "/Sections/GetSectionsTypes",
  async () => {
    const response = await axiosInstance.get(`/Sections/GetSectionsTypes`);
    return response;
  }
);
export const fetchSectionCategory = createAsyncThunk(
  "Sections/GetCategories",
  async () => {
    const response = await axiosInstance.get(`Sections/GetCategories`);
    return response;
  }
);
export const fetchSectionSubCategory = createAsyncThunk(
  "/Sections/GetSubcategories",
  async () => {
    const response = await axiosInstance.get(`/Sections/GetSubcategories`);
    return response;
  }
);

const SectionSlice = createSlice({
  name: "Sections",
  initialState,
  reducers: {
    SectionsAdded: {
      reducer(state, action) {
        state.Sections?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchSections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSections.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Sections = action.payload.data.Data;
      })
      .addCase(fetchSections.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchSectionPopulate.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchSectionPopulate.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SectionPopulate = action.payload.data.Data;
      })
      .addCase(fetchSectionPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status1 = "failed";
      })
      .addCase(fetchSectionTypes.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchSectionTypes.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SectionTypes = action.payload.data.Data;
      })
      .addCase(fetchSectionTypes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status2 = "failed";
      })
      .addCase(fetchSectionCategory.pending, (state) => {
        state.status3 = "loading";
      })
      .addCase(fetchSectionCategory.fulfilled, (state, action) => {
        state.status3 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SectionCategory = action.payload.data.Data;
      })
      .addCase(fetchSectionCategory.rejected, (state, action) => {
        state.error = action.error.message;
        state.status3 = "failed";
      })
      .addCase(fetchSectionSubCategory.pending, (state) => {
        state.status4 = "loading";
      })
      .addCase(fetchSectionSubCategory.fulfilled, (state, action) => {
        state.status4 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SectionSubCategory = action.payload.data.Data;
      })
      .addCase(fetchSectionSubCategory.rejected, (state, action) => {
        state.error = action.error.message;
        state.status4 = "failed";
      });
  },
});

export const getSectionsDetails = (state) => state.Sections;

export const { SectionsAdded } = SectionSlice.actions;

export default SectionSlice.reducer;
