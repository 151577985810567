import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFacebookPages,
  getFacebookPageDetails,
} from "../../Redux/Slice/Settings/FacebookPageSlice/FacebookPageSlice";
import Loader from "../../Components/Loader/Loader";
import { Badge, Button, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VerifyPasswordModal from "./VerifyPasswordModal";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FbTokenFilterDrawer from "./FbTokenFilterDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { RemoveSCFromInputs } from "../../Utils/RemoveSCFromInputs";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function FbTokenTable({ roles }) {
  const { header } = useStyles();
  const [pagesize, setpagesize] = useState(10);
  const [rows, setrows] = useState([]);
  const [allFbToken, setallFbToken] = useState([]);
  const [showToken, setshowToken] = useState(false);
  const [selectedIdx, setselectedIdx] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { FacebookPages, status } = useSelector(getFacebookPageDetails);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [filterVal, setfilterVal] = useState({
    organization: "",
    pageName: "",
    pageId: "",
  });
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  useEffect(() => {
    dispatch(fetchFacebookPages());
  }, [dispatch]);

  useEffect(() => {
    let arr = [];
    if (FacebookPages) {
      FacebookPages?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
        });
      });
    }
    setrows(arr);
    setallFbToken(arr);
  }, [FacebookPages]);
  const handleEdit = (val) => {
    navigate("/create/fbpagetoken", { state: val });
  };
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge
                badgeContent={count}
                color="primary"
              >
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (
      filterVal?.organization === "" &&
      filterVal?.pageName === "" &&
      filterVal?.pageId === ""
    ) {
      // console.log(salesPersonRef.current?.value);
      setallFbToken(rows);
    } else {
      let suggestions = [];
      let regex3 = new RegExp(filterVal?.organization, "i");
      let regex4 = new RegExp(filterVal?.pageName, "i");
      let regex5 = new RegExp(Number(filterVal?.pageId), "i");

      suggestions = rows?.filter(
        (val) =>
          (filterVal?.organization === "" ||
            regex3.test(val?.Organization?.Name)) &&
          (filterVal?.pageName === "" || regex4.test(val?.PageTitle)) &&
          (filterVal?.pageId === "" || regex5.test(Number(val?.PageId)))
      );
      setallFbToken(suggestions);
    }
  }, [filterVal, rows]);
  const columns = roles?.some((role) => role === "Facebook Page_Edit")
    ? [
        { field: "id", headerName: "ID", width: 90, headerClassName: header },
        {
          field: "Organization",
          headerName: "Organization",
          width: 200,
          headerClassName: header,
          renderCell: (celval, i) => {
            return (
              <>
                <Box
                  sx={{ display: "flex", gap: "5px" }}
                  key={i}
                >
                  <Typography>{celval?.row?.Organization?.Name}</Typography>
                </Box>
              </>
            );
          },
        },
        {
          field: "PageTitle",
          headerName: "Page Name",
          width: 200,
          headerClassName: header,
        },
        {
          field: "PageId",
          headerName: "Page ID",
          width: 250,
          headerClassName: header,
        },
        {
          field: "AccessToken",
          headerName: "Page Access Token",
          width: 450,
          headerClassName: header,
          renderCell: (celval, i) => {
            return (
              <>
                <Box
                  sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  key={i}
                >
                  {showToken && selectedIdx?.Id == celval?.row?.Id ? (
                    <VisibilityOffIcon
                      onClick={() => {
                        // setselectedIdx(celval?.row);
                        setshowToken(false);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => {
                        setselectedIdx(celval?.row);
                        setShowModal(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  )}

                  {showToken && selectedIdx?.Id == celval?.row?.Id ? (
                    <Typography>{celval?.row?.AccessToken}</Typography>
                  ) : (
                    <Typography>****************</Typography>
                  )}
                </Box>
              </>
            );
          },
        },
        {
          field: "actions",
          headerName: "Actions",
          width: 170,
          headerClassName: header,
          renderCell: (celval, i) => {
            return (
              <Box
                id="person-actions"
                key={i}
              >
                {roles?.some((role) => role === "Facebook Page_Edit") && (
                  <Tooltip title="Edit">
                    <IconButton
                      // key={i}
                      onClick={() => {
                        handleEdit(celval.row);
                      }}
                    >
                      <EditIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.edit,
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            );
          },
        },
      ]
    : [
        { field: "id", headerName: "ID", width: 90, headerClassName: header },
        {
          field: "Organization",
          headerName: "Organization",
          width: 200,
          headerClassName: header,
          renderCell: (celval, i) => {
            return (
              <>
                <Box
                  sx={{ display: "flex", gap: "5px" }}
                  key={i}
                >
                  <Typography>{celval?.row?.Organization?.Name}</Typography>
                </Box>
              </>
            );
          },
        },
        {
          field: "PageTitle",
          headerName: "Page Name",
          width: 200,
          headerClassName: header,
        },
        {
          field: "PageId",
          headerName: "Page ID",
          width: 250,
          headerClassName: header,
        },
        {
          field: "AccessToken",
          headerName: "Page Access Token",
          width: 450,
          headerClassName: header,
          renderCell: (celval, i) => {
            return (
              <>
                <Box
                  sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  key={i}
                >
                  {showToken && selectedIdx?.Id == celval?.row?.Id ? (
                    <VisibilityOffIcon
                      onClick={() => {
                        // setselectedIdx(celval?.row);
                        setshowToken(false);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => {
                        setselectedIdx(celval?.row);
                        setShowModal(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  )}

                  {showToken && selectedIdx?.Id == celval?.row?.Id ? (
                    <Typography>{celval?.row?.AccessToken}</Typography>
                  ) : (
                    <Typography>****************</Typography>
                  )}
                </Box>
              </>
            );
          },
        },
      ];

  return (
    <>
      {status === "loading" ? <Loader /> : null}
      <Box>
        <FbTokenFilterDrawer
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
          rows={rows}
          setallFbToken={setallFbToken}
          handleChangeVal={handleChangeVal}
          setcount={setcount}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
        />
      </Box>
      <Box>
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
            }}
            rows={allFbToken}
            columns={columns}
          />
        </Box>
      </Box>
      {showModal && (
        <VerifyPasswordModal
          showModal={showModal}
          setShowModal={setShowModal}
          setshowToken={setshowToken}
        />
      )}
    </>
  );
}

export default FbTokenTable;
FbTokenTable.propTypes = {
  roles: PropTypes.array,
};
