import React, { useState, useContext, useEffect } from "react";

import { useDrop } from "react-dnd";
import {
  Box,
  // Button,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import AppTooltip from "../../../Utils/AppTooltip";
import CardBox from "./CardBox";
// import { useNavigate } from "react-router-dom";
import {
  fetchLeadByPiplineStage,
  fetchLeadsSingle,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { ChangePiplineStageApi } from "../../../Api/LeadsApi/ChangePiplineStageApi";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import LostModal from "../LeadView/LostModal";
import WonModal from "../LeadView/WonModal";
// import Cookies from "universal-cookie";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AppConfirmationDialog from "../../AppConfirmationDialog/AppConfirmationDialog";

export const DropBox = ({
  mainBoxIdx,
  cardsTop,
  paperBox,
  pipline,
  roles,
  total,
  defaultpipline,
}) => {
  // const cookies = new Cookies();
  // let businessType = cookies.get("BusinessType");
  let businessType = sessionStorage.getItem("BusinessType");
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [opens, setOpens] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [lead, setlead] = useState(null);

  const { SingleLeads } = useSelector(getLeadsDetails);
  const [singleLead, setsingleLead] = useState();
  console.log("singleLead", singleLead);
  useEffect(() => {
    if (lead?.Id) {
      dispatch(fetchLeadsSingle(lead?.Id));
    }
  }, [lead]);
  useEffect(() => {
    setsingleLead(SingleLeads);
  }, [SingleLeads]);
  const [loading, setloading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: "BOX",
    drop: async (item) => {
      if (pipline.Name === item?.lead.Name) {
        setsnackBarData({
          type: "info",
          message: `Lead is Already in ${pipline.Name}`,
          openToast: true,
        });
        return;
      } else if (pipline.SortOrder < item.lead.SortOrder) {
        setsnackBarData({
          type: "error",
          message: `Can not move Lead to its previous stage`,
          openToast: true,
        });
        return;
      } else if (item?.lead.Name === "Won" || item?.lead.Name === "Lost") {
        setsnackBarData({
          type: "error",
          message: `Can not change Lead Status as it is ${item?.lead?.Name}`,
          openToast: true,
        });
        return;
      } else if (item && pipline?.Name === "Lost") {
        setlead(item.lead);
        setshowModal(true);
      } else if (
        (!item.lead?.QuoteCount || item.lead?.QuoteCount === 0) &&
        pipline?.Name === "Won" &&
        businessType !== "RealState"
      ) {
        setsnackBarData({
          type: "error",
          message: "Lead has no Quotation",
          openToast: true,
        });
        return;
      } else if (item && pipline?.Name === "Won") {
        
        setlead(item.lead);
        setshowModal2(true);
      } else {
        setlead(item.lead);
        setOpens(true);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const changePipline = async () => {
    setloading(true);
    const response = await ChangePiplineStageApi(lead?.Id, pipline?.Id);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setloading(false);
      setlead(null);
      setOpens(false);
      dispatch(fetchLeadsSingle(Number(lead?.Id)));
      dispatch(fetchLeadByPiplineStage(defaultpipline?.Id));
    }
    setloading(false);
  };

  return (
    <>
      <Paper
        key={mainBoxIdx}
        sx={{
          backgroundColor: isOver && canDrop ? "lightgreen" : "white",
        }}
        ref={dropRef}
        className={paperBox}
        elevation={3}
      >
        <Box className={cardsTop}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <AppTooltip title={pipline?.Name}>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
              >
                {pipline?.Name}
              </Typography>
            </AppTooltip>
            <Typography sx={{ color: "#53c41a", fontWeight: "bold" }}>
              {total.toLocaleString()}
            </Typography>
          </Box>
          {/* {roles?.some((role) => role === "Leads_Create") && (
            <Button
              sx={{
                width: "100%",
                border: (theme) => `2px solid ${theme.palette.primary.main}`,
                fontWeight: "bold",
              }}
              disabled={pipline?.Name == "Won" || pipline?.Name == "Lost"}
              onClick={() => {
                navigate("/leads/create", {
                  state: { pipline: pipline },
                });
              }}
            >
              Create Lead
            </Button>
          )} */}
        </Box>
        <Box
          sx={{
            height: "46rem",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {pipline?.Leads?.map((lead, i) => {
            return (
              <CardBox
                key={i}
                lead={lead}
                index={i}
                mainBoxIdx={mainBoxIdx}
                roles={roles}
              />
            );
            // return (
            //   <Box
            //     key={i}
            //     className="cardBox"
            //     sx={{ backgroundColor: "#ffd0d6", padding: "10px" }}
            //   >
            //     <Box className={iconBoxTypo}>
            //       <Typography sx={{ fontWeight: "bold" }}>
            //         {lead?.Title}
            //       </Typography>
            //       <Box
            //         className="iconhoverEffect"
            //         sx={{
            //           transform: "scale(0)",
            //           display: "flex",
            //           gap: "7px",
            //         }}
            //       >
            //         {roles?.some((role) => role === "Leads_View") && (
            //           <VisibilityIcon
            //             onClick={() => {
            //               navigate("/leads/view", {
            //                 state: { id: lead?.Id },
            //               });
            //             }}
            //             fontSize="small"
            //             sx={{
            //               color: (theme) => theme.palette.primary.main,
            //               cursor: "pointer",
            //             }}
            //           />
            //         )}

            //         <OpenWithIcon
            //           fontSize="small"
            //           sx={{
            //             color: (theme) => theme.palette.primary.main,
            //           }}
            //         />
            //       </Box>
            //     </Box>
            //     <Box className={cardsIconBox}>
            //       <Person2Icon
            //         fontSize="small"
            //         sx={{ color: (theme) => theme.palette.primary.main }}
            //       />
            //       <Typography>{lead?.personName}</Typography>
            //     </Box>
            //     <Box className={cardsIconBox}>
            //       <MonetizationOnIcon
            //         sx={{ color: (theme) => theme.palette.primary.main }}
            //         fontSize="small"
            //       />
            //       <Typography>
            //         {lead?.LeadValue.toLocaleString()}
            //       </Typography>
            //     </Box>
            //   </Box>
            // );
          })}
        </Box>
      </Paper>

      {/* <Dialog
        open={opens}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Change Stage <b>{pipline?.Name} </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                setOpens(false);
                setlead(null);
              }}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  changePipline();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}

      <AppConfirmationDialog
        open={opens}
        onDeny={() => {
          setlead(null);
          setOpens(false);
        }}
        onConfirm={() => {
          changePipline();
        }}
        iconContainerColor="#ebf9f9"
        icon={
          <TrendingUpIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.primary?.main }}
          />
        }
        dialogTitle="Change Stage"
        title={`Are you sure you want to Change Stage to "${pipline?.Name}"?`}
        successText="Yes"
        successBtnColor="primary"
        cancelText="No"
        loading={loading}
      />

      <LostModal
        lostPiplines={pipline}
        singleLead={lead}
        setshowModal={setshowModal}
        showModal={showModal}
        defaultpipline={defaultpipline}
      />
      {showModal2 && (
        <WonModal
          singleLead={singleLead}
          wonPiplines={pipline}
          setshowModal2={setshowModal2}
          showModal2={showModal2}
          defaultpipline={defaultpipline}
        />
      )}
    </>
  );
};
DropBox.propTypes = {
  type: PropTypes.any,
  mainBoxIdx: PropTypes.any,
  cardsTop: PropTypes.any,
  paperBox: PropTypes.any,
  pipline: PropTypes.any,
  roles: PropTypes.any,
  total: PropTypes.any,
  defaultpipline: PropTypes.any,
};
