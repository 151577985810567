import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Projects: [],
  ProjectCategories: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
  IsLogin: true,
};
export const fetchProjects = createAsyncThunk("/Projects", async () => {
  const response = await axiosInstance.get(`/Projects/GetProjects`);
  return response;
});
export const fetchProjectCategories = createAsyncThunk(
  "Projects/GetProjectCategories",
  async () => {
    const response = await axiosInstance.get(`Projects/GetProjectCategories`);
    return response;
  }
);
const ProductSlice = createSlice({
  name: "Projects",
  initialState,
  reducers: {
    ProjectsAdded: {
      reducer(state, action) {
        state.Projects?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Projects = action.payload.data.Data;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchProjectCategories.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchProjectCategories.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ProjectCategories = action.payload.data.Data;
      })
      .addCase(fetchProjectCategories.rejected, (state, action) => {
        state.error = action.error.message;
        state.status1 = "failed";
      });
  },
});

export const getProjectsDetails = (state) => state.Projects;

export const { ProjectsAdded } = ProductSlice.actions;

export default ProductSlice.reducer;
