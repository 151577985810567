import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});
function TempleteFilterDrawer({
  setcount,
  setfilterVal,
  filterVal,
  handleChangeVal,
  // setallCampaign,
  // rows,
  setdrawerOpen,
  drawerOpen,
}) {
  const { contentBox, fieldBox } = useStyles();

  const handleClose = () => {
    setdrawerOpen(!open);
  };
  console.log(filterVal);
  const handleRemoveAll = () => {
    for (const value in filterVal) {
      filterVal[value] = "";
    }
    setcount(0);
  };
  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Filter</Typography>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  // setallCampaign(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            <Box>
              <Typography>Title</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="title"
                  variant="outlined"
                  size="small"
                  value={filterVal?.title}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Title"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      title: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Type</Typography>
              <Box className={fieldBox}>
                <Select
                  name="type"
                  variant="outlined"
                  size="small"
                  // defaultValue={filterVal?.salesperson}
                  value={filterVal?.type}
                  onChange={(e, val) => {
                    console.log(e.target);
                    console.log(val);
                    handleChangeVal(e);
                  }}
                  // type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Type"
                  className="input_border"
                  select
                  // onChange={(e) => { handleChangeVal(e); }}
                >
                  <MenuItem value="">select</MenuItem>
                  <MenuItem value="Image">Image</MenuItem>
                  <MenuItem value="Text">Text</MenuItem>
                  <MenuItem value="Document">Document</MenuItem>
                </Select>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      type: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default TempleteFilterDrawer;
TempleteFilterDrawer.propTypes = {
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  setallCampaign: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
};
