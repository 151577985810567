import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  Piplines: [],
  isSuccess: false,
  message: null,
  statusPipeline: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchPiplines = createAsyncThunk("/LeadPipeline", async () => {
  const response = await axiosInstance.get(`/LeadPipeline`);
  return response;
});

const PiplinesSlice = createSlice({
  name: "Piplines",
  initialState,
  reducers: {
    PiplinesAdded: {
      reducer(state, action) {
        state.Piplines?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchPiplines.pending, (state) => {
        state.statusPipeline = "loading";
      })
      .addCase(fetchPiplines.fulfilled, (state, action) => {
        state.statusPipeline = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Piplines = action.payload.data.Data;
      })
      .addCase(fetchPiplines.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusPipeline = "failed";
      });
  },
});

export const getPiplinesDetails = (state) => state.Piplines;

export const { PiplinesAdded } = PiplinesSlice.actions;

export default PiplinesSlice.reducer;
