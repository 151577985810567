import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Loader from "../../Components/Loader/Loader";
import { Badge, Button, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuotes,
  getQuotesDetails,
} from "../../Redux/Slice/QuoteSlice/QuoteSlice";
import { useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import QuotesFilterDrawer from "../../Components/Quotes/CreateQuotes/QuotesFilterDrawer/QuotesFilterDrawer";
import moment from "moment/moment";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import { DeleteQuoteApi } from "../../Api/CreateQuoteApi/DeleteQuoteApi";
import PropTypes from "prop-types";
import { AppContext } from "../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import theme from "../../theme";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import AppConfirmationDialog from "../../Components/AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    topBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      // justifyContent: "end",
    },

    customTabSelected: {
      padding: "5px 10px 1px 10px",
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      color: "white",
      cursor: "pointer",
      alignItems: "center",
    },
    customTab: {
      padding: "5px 10px 1px 10px",
      backgroundColor: "white",
      border: "1px solid #707070",
      cursor: "pointer",
      alignItems: "center",
      color: theme.palette.primary.main,
    },
  };
});

function Quotes({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  const { totalRows } = useContext(PageNumberContext);

  let guide = sessionStorage.getItem("guide");
  const { header, topBox, customTabSelected, customTab } = useStyles();
  const dispatch = useDispatch();
  const [pagesize, setpagesize] = useState(totalRows);
  const { Quotes, status } = useSelector(getQuotesDetails);
  const [allQuotes, setallQuotes] = useState([]);
  console.log("allQuotes", allQuotes);
  const [loader] = useState(false);
  const [rows, setrows] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [showLoading, setshowLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { setState, state } = useContext(AppContext);
  const [selectedFilter, setselectedFilter] = useState("Pending");
  const [showCards, setshowCards] = useState("Pending");
  console.log(Quotes);
  const [filterArr] = useState([
    { name: "Pending" },
    { name: "Won" },
    { name: "Lost" },
  ]);
  console.log(showCards);
  const navigate = useNavigate();
  const [filterVal, setfilterVal] = useState({
    person: "",
    name: "",
    salesperson: "",
    adjustment: "",
    tax: "",
    discount: "",
    subtotal: "",
    enddate2: "",
    createdate2: "",
    enddate: "",
    createdate: "",
    drandtotal: "",
  });
  const [count, setcount] = useState(0);
  useEffect(() => {
    dispatch(fetchQuotes(selectedFilter));
  }, [dispatch, selectedFilter]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  const deleteQuote = async (id) => {
    setshowLoading(true);
    let res = await DeleteQuoteApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);
    if (res.data.IsSuccess) {
      dispatch(fetchQuotes(selectedFilter));
    }
    setsnackBarData(res.snackBarData);
  };
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  const handleEdit = (val) => {
    navigate("/quotes/create", { state: val });
  };
  useEffect(() => {
    let arr = [];
    if (Quotes) {
      Quotes?.forEach((quote, idx) => {
        return arr.push({
          id: idx + 1,
          subject: quote?.Subject,
          salesperson: quote?.User?.FullName,
          person: quote?.Person?.Name,
          subtotal: quote?.SubTotal.toLocaleString(),
          discount: quote?.DiscountAmount.toLocaleString(),
          tax: quote?.TaxAmount.toLocaleString(),
          adjustment: quote?.AdjustmentAmount.toLocaleString(),
          grandtotal: quote?.GrandTotal.toLocaleString(),
          expiredat: quote?.ExpiredAt
            ? moment(quote?.ExpiredAt).format("DD-MMM-YYYY")
            : null,
          createddate: quote?.CreatedAt
            ? moment(quote?.CreatedAt).format("DD-MMM-YYYY")
            : null,
          ID: quote?.Id,
          QuoteProducts: quote?.QuoteProducts,
          UserId: quote?.UserId,
          description: quote?.Description,
          Personobj: quote?.Person,
          Leadobj: quote?.Lead,
          BillingAddressNavigation: quote?.BillingAddressNavigation,
          ShippingAddressNavigation: quote?.ShippingAddressNavigation,
        });
      });
    }
    setrows(arr);
    setallQuotes(arr);
  }, [Quotes]);
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: header },

    {
      field: "salesperson",
      headerName: "Sales Person",
      width: 150,
      headerClassName: header,
    },
    {
      field: "person",
      headerName: "Customer",
      width: 110,
      headerClassName: header,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 150,
      headerClassName: header,
    },

    {
      field: "subtotal",
      headerName: "Sub Total",
      width: 160,
      headerClassName: header,
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 160,
      headerClassName: header,
    },
    {
      field: "tax",
      headerName: "Tax",
      width: 160,
      headerClassName: header,
    },
    {
      field: "adjustment",
      headerName: "Adjustment",
      width: 160,
      headerClassName: header,
    },
    {
      field: "grandtotal",
      headerName: "Grand Total",
      width: 160,
      headerClassName: header,
    },
    {
      field: "expiredat",
      headerName: "Expired At",
      width: 160,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <Box id="quotes-action" key={i}>
            {selectedFilter == "Pending" ? (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row);
                  }}
                  disabled={
                    (roles?.some((role) => role === "Quotes_Edit") &&
                      celval.row.Leadobj?.Status === "Pending") ||
                    celval.row.Leadobj?.Status == null
                      ? false
                      : true
                  }
                >
                  <EditIcon
                    sx={{
                      color:
                        celval.row.Leadobj?.Status === "Pending" ||
                        celval.row.Leadobj?.Status == null
                          ? (theme) => theme?.palette?.primary?.edit
                          : "gray",
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            ) : null}

            {roles?.some((role) => role === "Quotes_Delete") && (
              <Tooltip title="Delete">
                <IconButton
                  key={i}
                  onClick={() => {
                    setselectedId(celval.row.ID);
                    setshowConfirmationIcons(true);
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.delete,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];
  const handleChangeVal = (e) => {
    console.log(e.target?.value);
    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value || "",
    });
  };
  console.log(rows);
  useEffect(() => {
    if (
      filterVal?.salesperson === "" &&
      filterVal?.name === "" &&
      filterVal?.person === "" &&
      filterVal?.subtotal === "" &&
      filterVal?.discount === "" &&
      filterVal?.tax === "" &&
      filterVal?.adjustment === "" &&
      filterVal?.drandtotal === "" &&
      filterVal?.createdate === "" &&
      filterVal?.enddate === "" &&
      filterVal?.createdate2 === "" &&
      filterVal?.enddate2 === ""
    ) {
      // console.log(salesPersonRef.current?.value);
      setallQuotes(rows);
    } else {
      console.log(moment(filterVal?.createdate).format("DD-MMM-YYYY"));
      const subtotal = parseFloat(filterVal?.subtotal.replace(/,/g, ""));
      let suggestions = [];
      let regex1 = new RegExp(filterVal?.name, "i");
      let regex2 = new RegExp(filterVal?.salesperson, "i");
      let regex3 = new RegExp(filterVal?.person, "i");
      let regex4 = new RegExp(subtotal, "i");
      let regex5 = new RegExp(
        parseFloat(filterVal?.discount.replace(/,/g, "")),
        "i"
      );
      let regex6 = new RegExp(
        parseFloat(filterVal?.tax.replace(/,/g, "")),
        "i"
      );
      let regex7 = new RegExp(
        parseFloat(filterVal?.adjustment.replace(/,/g, "")),
        "i"
      );
      let regex8 = new RegExp(
        parseFloat(filterVal?.drandtotal.replace(/,/g, "")),
        "i"
      );
      let regex9 = new RegExp(
        moment(filterVal?.createdate).format("DD-MMM-YYYY"),
        "i"
      );
      console.log(regex9);
      let regex10 = new RegExp(
        moment(filterVal?.enddate).format("DD-MMM-YYYY"),
        "i"
      );
      let regex11 = new RegExp(
        moment(filterVal?.createdate2).format("DD-MM-YYYY"),
        "i"
      );
      let regex12 = new RegExp(
        moment(filterVal?.enddate2).format("DD-MM-YYYY"),
        "i"
      );
      suggestions = rows?.filter(
        (val) =>
          (filterVal?.name === "" || regex1.test(val?.subject)) &&
          (filterVal?.salesperson === "" || regex2.test(val.salesperson)) &&
          (filterVal?.person === "" || regex3.test(val?.person)) &&
          (filterVal?.subtotal === "" ||
            regex4.test(parseFloat(val?.subtotal.replace(/,/g, "")))) &&
          (filterVal?.discount === "" ||
            regex5.test(parseFloat(val?.discount.replace(/,/g, "")))) &&
          (filterVal?.tax === "" ||
            regex6.test(parseFloat(val?.tax.replace(/,/g, "")))) &&
          (filterVal?.adjustment === "" ||
            regex7.test(parseFloat(val?.adjustment.replace(/,/g, "")))) &&
          (filterVal?.drandtotal === "" ||
            regex8.test(parseFloat(val?.grandtotal.replace(/,/g, "")))) &&
          (filterVal?.createdate === "" || regex9.test(val?.expiredat)) &&
          (filterVal?.enddate === "" || regex10.test(val?.enddate1)) &&
          (filterVal?.createdate2 === "" || regex11.test(val?.createddate)) &&
          (filterVal?.enddate2 === "" || regex12.test(val?.enddate2))
      );
      setallQuotes(suggestions);
    }
  }, [filterVal, rows]);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Quotes");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Quotes"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="quotes">
        <Box className={topBox} sx={{ marginBottom: "10px" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            {filterArr?.map((item, idx) => {
              return (
                <Box
                  onClick={() => {
                    setselectedFilter(item.name);
                    setshowCards(item.name);
                  }}
                  sx={{
                    borderLeft: idx != 0 ? "none !important" : "",
                    textAlign: "center",
                  }}
                  className={
                    selectedFilter === item.name ? customTabSelected : customTab
                  }
                  key={idx}
                >
                  <Typography>{item?.name}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box>
          <QuotesFilterDrawer
            setdrawerOpen={setdrawerOpen}
            drawerOpen={drawerOpen}
            rows={rows}
            setallQuotes={setallQuotes}
            handleChangeVal={handleChangeVal}
            setcount={setcount}
            setfilterVal={setfilterVal}
            filterVal={filterVal}
          />
        </Box>
        {loader ? (
          <Loader />
        ) : (
          <Box
            sx={{
              height: "calc(100vh - 137px)",
              width: "100%",
              backgroundColor: (theme) => theme.palette.primary.contrastText,
            }}
          >
            <DataGrid
              pageSize={pagesize}
              onPageSizeChange={(newPage) => {
                setpagesize(newPage);
              }}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              experimentalFeatures={{ newEditingApi: true }}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: () => CustomNoRowsOverlay(),
              }}
              rows={allQuotes}
              columns={columns}
            />
            {status === "loading" || showLoading ? <Loader /> : null}
          </Box>
        )}
      </Box>

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteQuote(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Quote"
        title={`Are you sure, you want to Delete Quote?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Quotes;
Quotes.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#quotes",
    content: (
      <>
        <p size="large"> Welcome to the Quotes Page</p>
        <p>
          Your central hub for managing and creating quotes. Quotes represent
          detailed estimates or proposals that outline the cost, terms, and
          specifications for your products or services.
          <br />
          <br /> Ready to start a new quote? Click the &apos;New&apos; button to
          initiate the creation process.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#quotes-action",
    content: (
      <>
        <p size="large">Quotes Actions</p>
        <p>
          Edit: Refine and update your quotes easily by clicking
          &apos;Edit&apos; to modify details as needed. <br /> Delete:
          Streamline your workspace by removing quotes that are no longer
          relevant.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
