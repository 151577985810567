import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";

// import theme from '../../theme';
import { Box, MenuItem, TextField } from "@mui/material";
// import LeadView from '../../Components/Leads/LeadView/LeadView';
import LeadsTable from "../../Components/Leads/LeadsTable/LeadsTable";
import LeadsCards from "../../Components/Leads/LeadsCards/LeadsCards";
import { useState } from "react";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import TableViewIcon from "@mui/icons-material/TableView";
import theme from "../../theme";
import {
  fetchPiplines,
  getPiplinesDetails,
} from "../../Redux/Slice/Settings/PiplinesSlice/PiplinesSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsDetails } from "../../Redux/Slice/LeadsSlice/LeadsSlice";
// import { fetchLeadByPiplineStage } from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import PropTypes from "prop-types";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AppContext } from "../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";

const useStyles = makeStyles(() => {
  return {
    LeadViewContainer: {
      width: "100%",
    },
    iconBox: {
      padding: "5px 10px 5px 10px",
      border: "1px solid black",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    topBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "end",
    },

    customTabSelected: {
      padding: "5px 10px 1px 10px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
      alignItems: "center",
    },
    customTab: {
      padding: "5px 10px 1px 10px",
      backgroundColor: "white",
      border: "1px solid #707070",
      cursor: "pointer",
      alignItems: "center",
      color: theme.palette.primary.main,
    },
  };
});

function Leads({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { LeadViewContainer, topBox, customTabSelected, customTab } =
    useStyles();
  const [filterArr] = useState([
    { name: "cardView", icon: <SplitscreenIcon /> },
    { name: "tableView", icon: <TableViewIcon /> },
  ]);
  const [selectedFilter, setselectedFilter] = useState(
    localStorage.getItem("leadView")
  );
  const [showCards, setshowCards] = useState(localStorage.getItem("leadView"));
  const dispatch = useDispatch();
  const { Piplines, statusPipeline } = useSelector(getPiplinesDetails);
  const { LeadsByPiplineStage, status } = useSelector(getLeadsDetails);
  const [defaultpipline, setdefaultpipline] = useState(null);
 
  useEffect(() => {
    Piplines?.map((pipline) => {
      if (pipline?.IsDefualt === true) {
        setdefaultpipline(pipline);
      }
    });
  }, [Piplines, dispatch]);
  useEffect(() => {
    dispatch(fetchPiplines());
  }, [dispatch]);
  // useEffect(()=>{
  //   dispatch(fetchLeadByPiplineStage(defaultpipline?.Id));

  // },[defaultpipline]);
  const { setState, state } = useContext(AppContext);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Leads");
    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);

  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Leads"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="leads" className={`leadsIntro ${LeadViewContainer}`}>
        <Box className={topBox} sx={{}}>
          <TextField
            id="leads-pipelines-select"
            defaultValue={defaultpipline}
            value={defaultpipline}
            onChange={(e) => {
              setdefaultpipline(e.target.value);
            }}
            select
            size={"small"}
          >
            {Piplines?.map((pipline, i) => {
              return (
                <MenuItem key={i} value={pipline}>
                  {pipline?.Name}
                </MenuItem>
              );
            })}
          </TextField>
          <Box
            id="leads-pipelines-view"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {filterArr?.map((item, idx) => {
              return (
                <Box
                  onClick={() => {
                    setselectedFilter(item.name);
                    setshowCards(item.name);
                    localStorage.setItem("leadView", item.name);
                  }}
                  sx={{
                    borderLeft: idx != 0 ? "none !important" : "",
                  }}
                  className={
                    selectedFilter === item.name ? customTabSelected : customTab
                  }
                  key={idx}
                >
                  {item?.icon}
                </Box>
              );
            })}
          </Box>
        </Box>
        {showCards === "cardView" ? (
          <DndProvider backend={HTML5Backend}>
            <LeadsCards
              status={status}
              statusPipeline={statusPipeline}
              LeadsByPiplineStage={LeadsByPiplineStage}
              defaultpipline={defaultpipline}
              setshowCards={setshowCards}
              roles={roles}
            />
          </DndProvider>
        ) : (
          <LeadsTable
            status2={status}
            statusPipeline={statusPipeline}
            defaultpipline={defaultpipline}
            setshowCards={setshowCards}
            roles={roles}
          />
        )}
      </Box>
    </>
  );
}

export default Leads;
const steps = [
  {
    target: "#leads",
    content: (
      <>
        <p size="large">Welcome to the Leads Screen!</p>
        <p>
          This is your central hub for managing potential opportunities and new
          connections. Here, you can track, organize, and nurture your leads
          effectively. Use the features below to streamline your lead management
          process and convert prospects into valuable customers.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#leads-pipelines-select",
    content: (
      <>
        <p size="large">Select Pipeline</p>
        <p>
          Utilize the dropdown menu to effortlessly switch between pipelines and
          tailor your view. Select the specific pipeline you want to explore.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#leads-pipelines-view",
    content: (
      <>
        <p size="large">View Pipeline</p>
        <p>
          Switch between Grid View and Table View with a click. Choose the
          format that suits your workflow.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#leads-pipelines",
    content: (
      <>
        <p size="large">Pipelines</p>
        <p>
          Effortlessly organize and progress your leads by leveraging the
          intuitive drag-and-drop feature. Each box represents a pipeline, a
          strategic stage in your sales or engagement process. Simply grab a
          lead and drop it into the desired pipeline to update its status..
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
Leads.propTypes = {
  roles: PropTypes.array,
};
