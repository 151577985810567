import React, { useEffect, useRef } from 'react';
import { animateScroll } from 'react-scroll';
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
const useStyle = makeStyles((theme) => {
    return {
      chatContainer: {
        width: "100%",
        border: `1px solid ${theme.palette.grey[300]}`,
  
        height: "calc(100vh - 210px )",
        padding: "20px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        background: "#ffffff",
      },

    };
  });
function ScrollableContainer({children}) {
    const {
        chatContainer,
      } = useStyle();
    const containerRef = useRef(null);

    useEffect(() => {
      scrollToBottom();
    }, [children]); // Scroll whenever the content changes
  
    const scrollToBottom = () => {
      animateScroll.scrollToBottom({
        containerId: 'scrollable-container',
        smooth:true,duration:400,spy:true
      });
    };
  
  return (
    <Box
    id="scrollable-container"
    ref={containerRef}
    className={chatContainer}
    // style={{       height: "calc(100vh - 210px )", overflowY: 'scroll',}}
  >
    {children}
  </Box>
  );
}

export default ScrollableContainer;
ScrollableContainer.propTypes = {
    children: PropTypes.array,
  };
