import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
// import globalize from "globalize";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box } from "@mui/material";
import moment from "moment/moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActivityEvents,
  getActivitiesDetails,
} from "../../Redux/Slice/ActivitiesSlice/ActivitiesSlice";
import { useState } from "react";
const localizer = momentLocalizer(moment);

function ActivitiesCalendar() {
  const { Events } = useSelector(getActivitiesDetails);
  const [Event, setEvent] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchActivityEvents());
  }, []);
  useEffect(() => {
    let arr = [];
    Events?.map((evnt) => {
      arr.push({
        // allDay:true,
        start: new Date(evnt?.start),
        ...(evnt?.end
          ? { end: new Date(evnt?.end) }
          : { end: new Date(evnt?.start) }),
        title: evnt?.title,
      });
    });
    setEvent(arr);
  }, [Events]);

  // const Events = [
  //   {
  //     // allDay: true,

  //     end: new Date("2023-03-30 02:15 PM"),
  //     start: new Date("2023-03-30 12:15 AM"),
  //     title: "Birthday",
  //   },
  // ];
  return (
    <Box sx={{ paddingTop: "10px" }}>
      <Calendar
        localizer={localizer}
        //   events={myEventsList}
        defaultView="week"
        views={["month", "week"]}
        events={Event}
        startAccessor="start" // format start date
        endAccessor="end" // format end date
        style={{ height: "calc(100vh - 130px)", backgroundColor: "white" }}
      />
    </Box>
  );
}

export default ActivitiesCalendar;
