import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  User: [],
  UserPopulate: [],
  SingleUser: null,
  UserRoles: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
  IsLogin: true,
};

export const fetchUser = createAsyncThunk("/Users", async () => {
  const response = await axiosInstance.get(`/Users`);
  console.log(response);
  return response;
});
export const fetchUserPopulate = createAsyncThunk(
  "/Users/Populate",
  async () => {
    const response = await axiosInstance.get(`/Users/Populate`);
    console.log(response);
    return response;
  }
);

export const fetchSingleUser = createAsyncThunk("/Users/byId", async (id) => {
  const response = await axiosInstance.get(`/Users/byId/${id}`);
  console.log(response.data);
  return response;
});

export const fetchUserRoles = createAsyncThunk(
  `/Users/UserRoles}`,
  async (id) => {
    const response = await axiosInstance.get(`/Users/UserRoles/${id}`);
    return response;
  }
);

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    UserAdded: {
      reducer(state, action) {
        console.log(action.payload);
        state.User?.push(action.payload);
      },
    },
    resetUserSlice: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        console.log(action.payload.Data);
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.User = action.payload.data.Data;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchSingleUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingleUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SingleUser = action.payload.data.Data;
      })
      .addCase(fetchSingleUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchUserRoles?.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchUserRoles?.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserRoles = action.payload.data.Data;
      })
      .addCase(fetchUserRoles?.rejected, (state, action) => {
        state.error = action.error.message;
        state.status1 = "failed";
      })
      .addCase(fetchUserPopulate?.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserPopulate?.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserPopulate = action.payload.data.Data;
      })
      .addCase(fetchUserPopulate?.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getUserDetails = (state) => state.User;

export const { UserAdded, resetUserSlice } = UserSlice.actions;

export default UserSlice.reducer;
