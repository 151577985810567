import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import DoneIcon from "@mui/icons-material/Done";
import { Badge, Button, Chip, IconButton, Tooltip, } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import moment from "moment/moment";
import PropTypes from "prop-types";
import theme from "../../../theme";
import MeetingsFilterDrawer from "./MeetingsFilterDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AppConfirmationDialog from "../../AppConfirmationDialog/AppConfirmationDialog";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import MeetingDoneModal from "./MeetingDoneModal";
import MeetingInfoModal from "./MeetingInfoModal";
import {
  fetchMeetings,
  fetchMettingTypes,
  fetchPopulateParticipants,
  getMeetingsDetails,
} from "../../../Redux/Slice/MeetingsSlice/MeetingsSlice";
import {
  fetchUser,
  getUserDetails,
} from "../../../Redux/Slice/Settings/UserSlice/UserSlice";
import {
  fetchRoles,
  getRolesDetails,
} from "../../../Redux/Slice/Settings/RolesSlice/RolesSlice";
import ParticipantInfo from "./ParticipantInfo";
import { DeleteMeetingApi } from "../../../Api/MeetingsApi/DeleteMeetingApi";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    customTabSelected: {
      padding: "5px 12px 5px 12px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
      alignItems: "center",
      borderRadius: "19px",
    },
    customTab: {
      padding: "5px 10px 1px 10px",
      borderRadius: "20px",
      cursor: "pointer",
      alignItems: "center",
    },
    datGridBox: {
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 200px) !important",
      },
    },
  };
});

function MeetingsTable({ roles }) {
  const { header, datGridBox } = useStyles();
  // const [showModal, setshowModal] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [allMeetings, setallMeetings] = useState([]);
  const [rows, setrows] = useState([]);
  const [Particpent, setParticipants] = useState(null);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [count, setcount] = useState(0);
  const [selectedId, setselectedId] = useState(null);
  const [meetingsData, setMeetingsData] = useState([]);
  const [showLoading, setshowLoading] = useState(false);
  const [meetingRow, setmeetingRow] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [meetingInfoModal, setMeetingInfoModal] = useState(false);
  const { Roles } = useSelector(getRolesDetails);
  const [filterVal, setfilterVal] = useState({
    Title: "",
    StartDate: "",
    Duration: null,
    Notes: "",
    Agenda: "",
    Location: "",
    Priority: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginId, setLoginId] = useState("");

  useEffect(() => {
    // Retrieve the LoginId from sessionStorage
    const storedLoginId = sessionStorage.getItem("LoginId");
    if (storedLoginId) {
      setLoginId(storedLoginId);
    }
  }, []);
  const { Meetings, MeetingsTypes, status,PopulateParticipant } = useSelector(getMeetingsDetails);
  let { User } = useSelector(getUserDetails);
  console.log("this is roles ", Roles);
  console.log("Metingtype", MeetingsTypes);
  console.log("User", User);
  console.log("Meetings", Meetings);
  console.log("roless", roles);
  console.log("PopulateParticipant", PopulateParticipant);
  const handleClosed = () => {
    setParticipants(null);
    setShowModal2(false);
    setshowModal(false);
  };

  const handleChangeVal = (e) => {
    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value || "",
    });
  };

  useEffect(() => {
    dispatch(fetchMeetings());
    dispatch(fetchMettingTypes());
    dispatch(fetchUser());
    dispatch(fetchRoles());
    dispatch(fetchPopulateParticipants());
  }, [dispatch]);

  useEffect(() => {
    if (Meetings) {
      let arr = [...meetingsData];
      Meetings?.forEach((activity) => {
        const index = arr.findIndex((obj) => obj?.Id === activity?.Id);
        if (index !== -1) {
          arr[index] = activity;
        } else {
          arr.push(activity);
        }
      });
      setMeetingsData([...arr]);
    } else {
      setMeetingsData([]);
    }
  }, [Meetings]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Internal Meetings" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box>
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (typeof filterVal[value] === "number") {
        sum = sum + 1;
      } else {
        if (filterVal[value]?.length) {
          sum = sum + 1;
        }
      }
    }
    setcount(sum);
  }, [filterVal]);

  const userExist=User.some((userObj) => userObj.Id === loginId);
  // const userExist=true;
  // console.log("this is user present or not", userExist);
  
  useEffect(() => {
    let rowData = [];
      Meetings?.forEach((meeting, i) => {
        rowData.push({
          ...meeting,
          id: i + 1,
          Id: meeting?.Id,
          Title: meeting?.Title,
          StartDate: meeting.StartDate
            ? moment(meeting.StartDate).format("DD-MMM-YYYY")
            : null,
          Duration: meeting?.Duration,
          Notes: meeting?.Notes,
          Agenda: meeting?.Agenda,
          TypeName:meeting?.TypeName,
          Status: meeting?.Status,
          Location: meeting?.Location,
          Priority: meeting?.Priority,
          Participant: meeting?.Participant,
        });
      });
      setrows(rowData);
    setallMeetings(rowData);
   
  }, [Meetings]);
  const COMPLETED_STATUS = "Completed";

  // useEffect(() => {
  //   const ParticpentIds =
  //     (filterVal?.Participant && filterVal?.Participant?.map((sp) => sp?.id)) ||
  //     [];
  //   if (
  //     ParticpentIds?.length === 0 &&
  //     filterVal?.id === "" &&
  //     filterVal?.Title === "" &&
  //     filterVal?.StartDate === "" &&
  //     filterVal?.Duration === "" &&
  //     filterVal?.Notes === "" &&
  //     filterVal?.Agenda === "" &&
  //     filterVal?.Location === "" &&
  //     filterVal?.Priority === "" &&
  //     filterVal?.Id === ""
  //   ) {
  //     setallMeetings(rows);
  //   } else {
  //     let suggestions = [];

  //     let regex7 = new RegExp(filterVal?.Title, "i");

  //     let regex9 = new RegExp(
  //       moment(filterVal?.StartDate).format("DD-MMM-YYYY"),
  //       "i"
  //     );

  //     // suggestions = rows?.filter(
  //     //   (val) =>
  //     //     (ParticpentIds?.length === 0 || ParticpentIds?.includes(val?.id)) &&
  //     //     (filterVal?.Duration === null ||
  //     //       val?.Duration === filterVal?.Duration) &&
  //     //     (filterVal?.Notes === "" || val?.Notes.includes(filterVal?.Notes)) &&
  //     //     (filterVal?.Agenda === "" ||
  //     //       val?.Agenda.includes(filterVal?.Agenda)) &&
  //     //     (filterVal?.Location === "" ||
  //     //       val?.Location.includes(filterVal?.Location)) &&
  //     //     (filterVal?.Priority === "" ||
  //     //       val?.Priority === filterVal?.Priority) &&
  //     //     (filterVal?.Id === "" || val?.id === filterVal?.Id) &&
  //     //     (filterVal?.Title === "" || regex7.test(val?.Title)) &&
  //     //     (filterVal?.StartDate === "" || regex9.test(val?.StartDate))
  //     // );

  //     // setallMeetings(suggestions);
  //   }
  // }, [filterVal, rows]);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      width: 90,
      headerClassName: header,
    },

    {
      field: "Title",
      headerName: "Title",
      width: 200,
      headerClassName: header,
    },

    {
      field: "StartDate",
      headerName: " Start Date",
      width: 150,
      headerClassName: header,
    },
    {
      field: "Duration",
      headerName: "Duration",
      width: 130,
      headerClassName: header,
    },

    {
      field: "Location",
      headerName: "Location",
      width: 160,
      headerClassName: header,
    },
    {
      field: "Agenda",
      headerName: "Agenda",
      width: 180,
      headerClassName: header,
    },
    {
      field: "TypeName",
      headerName: "Type",
      width: 180,
      headerClassName: header,
    },
    {
      field: "Notes",
      headerName: "Notes",
      width: 230,
      headerClassName: header,
    },
    {
      field: "Priority",
      headerName: "Priority",
      width: 130,
      headerClassName: header,
    },
    {
      field: "Participant",
      headerName: "Participant",
      width: 200,
      headerClassName: header,
      renderCell: (cellVal) => {
        return (
          <div
            style={{
              display: "flex",
              overflowX: "auto",
              marginRight: "5px",
            }}
            onClick={() => {
              if (cellVal.row?.Participant?.length > 0) {
                setShowModal2(true);
                const fullNames = cellVal.row.Participant.map((participant) =>
                  participant.UserName
               
                );
                setParticipants(fullNames);
              }
            }}
          >
            {cellVal.row?.Participant[0]?.Userid && (
              <Chip
                size="small"
                label={cellVal.row?.Participant[0]?.UserName}
                sx={{
                  margin: "1px",
                }}
              />
            )}
            {cellVal.row?.Participant?.length > 1 && (
              <Chip
                size="small"
                label={"..."}
                sx={{
                  margin: "1px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: "done",
      headerName: "Mark Done",
      width: 130,
      headerClassName: header,
      renderCell: (cellVal) => {
        return (
          <Box>
            {cellVal?.row?.Status === COMPLETED_STATUS ? (
              "Completed"
            ) : (
              <Tooltip title="Mark as done">
                <IconButton
                  disabled={!roles?.some((role) => role === "Activities_Edit")}
                  onClick={() => {
                    handleClickOpen(cellVal?.row.Id);
                  }}
                >
                  <DoneIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.green,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      headerClassName: header,
      renderCell: (celval, i) => {
        const participants = celval.row?.Participant || [];
        const fullNames = participants.map(participant => participant.UserName);
        return (
          <Box key={i}>
            {(roles?.some((role) => role === "Activities_Edit")&& (!userExist) )&& (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row.Id);
                  }}
                  disabled={celval?.row?.Status === COMPLETED_STATUS}
                >
                  <EditIcon
                    sx={{
                      color: (theme) =>
                        celval?.row?.Status === COMPLETED_STATUS
                          ? theme.palette.action.disabled
                          : theme?.palette?.primary?.edit, 
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            {(roles?.some(((role) => role === "Activities_Delete")) && (!userExist)) && (
              <Tooltip title="Delete">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleDelete(celval.row.Id);
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.delete,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="View Details ">
              <IconButton
                key={i}
                onClick={() => {
                  setmeetingRow(celval?.row);
                  setMeetingInfoModal(true);
                  setParticipant(fullNames);
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  const handleClickOpen = (val) => {
    setshowModal(true);
    setselectedId(val);
  };
  const handleEdit = (val) => {
    navigate(`/meetings/create/${val}`);
  };
  const handleDelete = (val) => {
    setselectedId(val);
    setshowConfirmationIcons(true);
  };
    const deleteActivity = async (id) => {
      setshowLoading(true);
      const response = await DeleteMeetingApi(id);
      setshowLoading(false);
      setselectedId(null);
      setshowConfirmationIcons(false);
      if (response?.data.IsSuccess) {
          dispatch(fetchMeetings());
      }
      setsnackBarData(response.snackBarData); 
    };
    const searchRefreshData = (action) => {
      setMeetingsData([]);
      if (action === "search") {
        dispatch(
          fetchMeetings({
            id: filterVal?.Id,
            queryParams: {
              Title: filterVal?.Title,
              StartDate: filterVal?.StartDate,
              Duration: filterVal?.Duration,
              Notes: filterVal?.Notes,
              Agenda: filterVal?.Agenda,
              Location: filterVal?.Location,
              Priority: filterVal?.Priority,
            },
          })
        );
      } else {
        dispatch(fetchMeetings());
      }
    };
    return (
      <>
        <Box>
          <Box>
            <MeetingsFilterDrawer
              setdrawerOpen={setdrawerOpen}
              drawerOpen={drawerOpen}
              rows={rows}
              setallMeetings={setallMeetings}
              handleChangeVal={handleChangeVal}
              setcount={setcount}
              setfilterVal={setfilterVal}
              filterVal={filterVal}
              searchRefreshData={searchRefreshData}
            />
          </Box>

          <Box
            className={datGridBox}
            sx={{
              height: "calc(100vh - 77px)",
              width: "100%",
              overflowX: "visible",
              backgroundColor: "white !important",
            }}
          >
            <DataGrid
              rows={allMeetings}
              columns={columns}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: () => CustomNoRowsOverlay(),
              }}
              pageSize={[50]}
            />
            {status === "loading" || showLoading ? <Loader /> : null}
          </Box>
        </Box>
        {showModal2 && (
          <ParticipantInfo
            showModal={showModal2}
            handleClosed={handleClosed}
            users={Particpent}
          />
        )}
        <AppConfirmationDialog
          open={showConfirmationIcons}
          onDeny={() => {
            setselectedId(null);
            setshowConfirmationIcons(false);
          }}
          onConfirm={() => {
            deleteActivity(selectedId);
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteForeverIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Meeting"
          title={`Are you sure, you want to Delete Meeting?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
        {showModal && (
          <MeetingDoneModal
            showModal={showModal}
            setShowModal={setshowModal}
            userid={selectedId}
          />
        )}
        {meetingInfoModal && (
          <MeetingInfoModal
            meetingInfoModal={meetingInfoModal}
            setMeetingInfoModal={setMeetingInfoModal}
            setmeetingRow={setmeetingRow}
            meetingRow={meetingRow}
            participant={participant}
          />
        )}
      </>
    );
  }

  export default MeetingsTable;
  MeetingsTable.propTypes = {
    roles: PropTypes.array,
  };
