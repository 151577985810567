import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Autocomplete,
  IconButton,
  FormHelperText,
  ListItem,
  Chip,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useEffect } from "react";
import moment from "moment/moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HistoryIcon from "@mui/icons-material/History";
import AppTooltip from "../../Utils/AppTooltip";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { CreatePersonApi } from "../../Api/CreatePersonApi.jsx/CreatePersonApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeadsPopulate } from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import { getUserProfile } from "../../Redux/Slice/Profile/ProfileSlice";
// import Cookies from "universal-cookie";

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required("Please Enter user title"),
  description: Yup.string().required("Please Enter description"),
  leadValue: Yup.string().when("businessType", {
    is: (value) => value === "General",
    then: () => Yup.string().required("Please Enter leadValue"),
    otherwise: () => Yup.string(),
  }),
  leadSourceId: Yup.string().required("Please Enter leadSource"),
  leadTypeId: Yup.string().required("Please Enter Lead Type"),
  // expectedCloseDate: Yup.string().required("Please Enter date"),
  // personId: Yup.string().when("addNewPerson", {
  //   is: (value) => !value,
  //   then: () => Yup.string().required(`Please Select`),
  //   otherwise: () => Yup.string(),
  // }),
  personId: Yup.string().required(`Please Select Customer`),
  userId: Yup.string().required(`Please Select Sales Owner`),

  newPersonName: Yup.string().when("addNewPerson", {
    is: (value) => value,
    then: () => Yup.string().required("Please Enter name"),
    otherwise: () => Yup.string(),
  }),
  newPersonPhone: Yup.string().when("addNewPerson", {
    is: (value) => value,
    then: () =>
      Yup.string()
        .min(9, "Invalid phone number")
        .required("Please Enter phone"),
    otherwise: () => Yup.string(),
  }),
  affiliateId: Yup.string().when("source", {
    is: (value) => value === "Affiliates",
    then: () => Yup.string().required(`Please Select`),
    otherwise: () => Yup.string(),
  }),
  newAffiliateName: Yup.string().when("addNewAffiliate", {
    is: (value) => value,
    then: () => Yup.string().required("Please Enter name"),
    otherwise: () => Yup.string(),
  }),
  newAffiliatePhone: Yup.string().when("addNewAffiliate", {
    is: (value) => value,
    then: () =>
      Yup.string()
        .min(9, "Invalid phone number")
        .required("Please Enter phone"),
    otherwise: () => Yup.string(),
  }),
});

function Details({
  handleNext,
  handleBack,
  leadsPopulate,
  setdetailsData,
  setcontactPersonData,
  leadsViewData,
  detailsData,
  setCustomer,
  setTitle,
  publicLeadData,
  selectedCustomer,

  // customer,
}) {
  const {
    // container,
    // subContainer,
    formContainer,
  } = useStyle();
  // const cookies = new Cookies();

  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { UserProfile } = useSelector(getUserProfile);
  const [coLeadsUsers, setCoLeadUsers] = useState(null);
  const [leadsUsers, setLeadUsers] = useState([]);

  const handleSubmit = async (values) => {
    let obj = {};
    Object.keys(values)
      .filter((val) => {
        if (values.source === "Affiliates") {
          return (
            val !== "addNewPerson" &&
            val !== "newPersonName" &&
            val !== "newPersonDialCode" &&
            val !== "newPersonPhone" &&
            val !== "selectedDescription" &&
            val !== "businessType" &&
            val !== "addNewAffiliate" &&
            val !== "newAffiliateName" &&
            val !== "newAffiliateDialCode" &&
            val !== "newAffiliatePhone" &&
            val !== "source"
          );
        } else {
          return (
            val !== "addNewPerson" &&
            val !== "newPersonName" &&
            val !== "newPersonDialCode" &&
            val !== "newPersonPhone" &&
            val !== "selectedDescription" &&
            val !== "businessType" &&
            val !== "addNewAffiliate" &&
            val !== "newAffiliateName" &&
            val !== "newAffiliateDialCode" &&
            val !== "newAffiliatePhone" &&
            val !== "source" &&
            val !== "affiliateId"
          );
        }
      })
      ?.forEach((item) => {
        obj[item] = values[item];
      });
    setdetailsData(obj);
    handleNext();
  };

  const handleNavigate = () => {
    setdetailsData(null);
    setcontactPersonData(null);
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      businessType: sessionStorage.getItem("BusinessType"),
      title: detailsData?.title || "",
      description: detailsData?.description || "",
      leadValue: detailsData?.leadValue || "",
      leadSourceId: detailsData?.leadSourceId || "",
      leadTypeId: detailsData?.leadTypeId || "",
      userId: detailsData?.userId || "",
      expectedCloseDate: detailsData?.expectedCloseDate || null,
      selectedDescription: detailsData?.selectedDescription || null,
      personId: selectedCustomer
        ? selectedCustomer
        : detailsData?.personId || "",
      addNewPerson: false,
      newPersonName: "",
      newPersonDialCode: "",
      newPersonPhone: "",
      affiliateId: detailsData?.affiliateId || "",
      addNewAffiliate: false,
      newAffiliateName: "",
      newAffiliateDialCode: "",
      newAffiliatePhone: "",
      source: "",
      coLeadUsers: detailsData?.coLeadUsers || [],
    },
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  useEffect(() => {
    if (leadsViewData) {
      formik.setFieldValue("title", leadsViewData?.Title || "");
      formik.setFieldValue("description", leadsViewData?.Description || "");
      formik.setFieldValue("leadValue", leadsViewData?.LeadValue || "");
      formik.setFieldValue("leadSourceId", leadsViewData?.LeadSourceId || "");
      formik.setFieldValue("leadTypeId", leadsViewData?.LeadTypeId || "");
      formik.setFieldValue("userId", leadsViewData?.UserId || "");
      leadsViewData?.ExpectedCloseDate &&
        formik.setFieldValue(
          "expectedCloseDate",
          moment(leadsViewData?.ExpectedCloseDate).format("YYYY-MM-DD") || null
        );
      formik.setFieldValue("personId", leadsViewData?.PersonId || "");
      formik.setFieldValue("affiliateId", leadsViewData?.AffiliateId || "");
      formik.setFieldValue("coLeadUsers", leadsViewData?.LeadUsers || []);
    } else if (detailsData === null && publicLeadData) {
      formik.setFieldValue("title", publicLeadData?.Customer?.Name || "");
      formik.setFieldValue(
        "leadSourceId",
        publicLeadData?.LeadSource?.Id || ""
      );
      formik.setFieldValue("PublicLeadId", publicLeadData?.PublicLeadUnitId);
      formik.setFieldValue("personId", publicLeadData?.Customer?.Id || "");
    }
  }, [leadsViewData, leadsPopulate, publicLeadData]);
  useEffect(() => {
    if (formik.values?.personId) {
      setCustomer(
        leadsPopulate?.personData?.find(
          (person) => person?.Id === formik.values?.personId
        )
      );
    }
  }, [formik.values.personId, leadsPopulate]);

  const handleAddNewPerson = async (type) => {
    if (type === "Person") {
      formik.setFieldTouched("newPersonName");
      formik.setFieldTouched("newPersonPhone");

      if (!formik.errors.newPersonName && !formik.errors.newPersonPhone) {
        formik.setSubmitting(true);
        let formData = new FormData();
        formData.append("name", formik.values.newPersonName);
        formData.append(
          `personContacts[0].dialCode`,
          formik.values.newPersonDialCode
        );
        formData.append(
          `personContacts[0].contactNumber`,
          formik.values.newPersonPhone
        );
        formData.append(`personContacts[0].description`, "Work");
        formData.append(`Type`, type);
        const response = await CreatePersonApi(formData);
        formik.setSubmitting(false);
        if (response?.data?.IsSuccess) {
          formik.setFieldValue("addNewPerson", false);
          formik.setFieldValue("newPersonName", "");
          formik.setFieldValue("newPersonDialCode", "");
          formik.setFieldValue("newPersonPhone", "");
          formik.setFieldValue("personId", response?.data?.Data);

          dispatch(fetchLeadsPopulate());
        }
        setsnackBarData(response.snackBarData);
      }
    } else {
      formik.setFieldTouched("newAffiliateName");
      formik.setFieldTouched("newAffiliatePhone");

      if (!formik.errors.newAffiliateName && !formik.errors.newAffiliatePhone) {
        formik.setSubmitting(true);
        let formData = new FormData();
        formData.append("name", formik.values.newAffiliateName);
        formData.append(
          `personContacts[0].dialCode`,
          formik.values.newAffiliateDialCode
        );
        formData.append(
          `personContacts[0].contactNumber`,
          formik.values.newAffiliatePhone
        );
        formData.append(`personContacts[0].description`, "Work");
        formData.append(`Type`, type);
        const response = await CreatePersonApi(formData);
        formik.setSubmitting(false);
        if (response?.data?.IsSuccess) {
          formik.setFieldValue("addNewAffiliate", false);
          formik.setFieldValue("newAffiliateName", "");
          formik.setFieldValue("newAffiliateDialCode", "");
          formik.setFieldValue("newAffiliatePhone", "");
          formik.setFieldValue("affiliateId", response?.data?.Data);
          dispatch(fetchLeadsPopulate());
        }
        setsnackBarData(response.snackBarData);
      }
    }
  };
  useEffect(() => {
    if (formik?.values?.leadSourceId) {
      let obj = leadsPopulate?.sourceData?.find(
        (item) => item.Id === formik?.values?.leadSourceId
      );

      formik.setFieldValue("source", obj?.Name);
    }
  }, [formik?.values?.leadSourceId]);

  useEffect(() => {
    if (leadsViewData === null && detailsData === null && UserProfile) {
      formik.setFieldValue("userId", UserProfile?.Id);
    }
  }, [UserProfile]);
  useEffect(() => {
    const coLeadsUserIds = formik.values.coLeadUsers?.map((user) => user?.Id);

    const filteredUserData = leadsPopulate?.userData?.filter(
      (user) => user.Id !== formik.values.userId
    );
    const filteredUserData2 =
      coLeadsUserIds.length > 0
        ? leadsPopulate?.userData?.filter(
            (user) => !coLeadsUserIds?.includes(user?.Id)
          )
        : leadsPopulate?.userData;

    setCoLeadUsers(filteredUserData);
    setLeadUsers(filteredUserData2);
  }, [
    formik.values.userId,
    formik.values.coLeadUsers,
    leadsPopulate?.userData,
  ]);

  useEffect(() => {
    console.log("formik values", formik.values);
    let obj = {};
    Object.keys(formik.values)
      .filter((val) => {
        if (formik.values.source === "Affiliates") {
          return (
            val !== "addNewPerson" &&
            val !== "newPersonName" &&
            val !== "newPersonDialCode" &&
            val !== "newPersonPhone" &&
            val !== "selectedDescription" &&
            val !== "businessType" &&
            val !== "addNewAffiliate" &&
            val !== "newAffiliateName" &&
            val !== "newAffiliateDialCode" &&
            val !== "newAffiliatePhone" &&
            val !== "source"
          );
        } else {
          return (
            val !== "addNewPerson" &&
            val !== "newPersonName" &&
            val !== "newPersonDialCode" &&
            val !== "newPersonPhone" &&
            val !== "selectedDescription" &&
            val !== "businessType" &&
            val !== "addNewAffiliate" &&
            val !== "newAffiliateName" &&
            val !== "newAffiliateDialCode" &&
            val !== "newAffiliatePhone" &&
            val !== "source" &&
            val !== "affiliateId"
          );
        }
      })
      ?.forEach((item) => {
        obj[item] = formik.values[item];
      });
    setdetailsData(obj);
    console.log(obj);
  }, [formik.values]);

  return (
    <Paper elevation={3} sx={{ padding: "20px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "10px",
            }}
          >
            <FormControl fullWidth>
              <Typography>Title</Typography>
              <TextField
                name="title"
                value={formik.values?.title}
                size={"small"}
                fullWidth
                onChange={(e) => {
                  formik.handleChange(e);
                  setTitle(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors?.title) &&
                  Boolean(formik.touched?.title)
                }
                helperText={
                  Boolean(formik.errors?.title) &&
                  Boolean(formik.touched?.title) &&
                  formik.errors?.title
                }
              />
            </FormControl>

            {formik.values.businessType === "General" && (
              <FormControl fullWidth>
                <Typography>Lead Value</Typography>
                <TextField
                  name="leadValue"
                  type={"number"}
                  value={formik.values?.leadValue}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors?.leadValue) &&
                    Boolean(formik.touched?.leadValue)
                  }
                  helperText={
                    Boolean(formik.errors?.leadValue) &&
                    Boolean(formik.touched?.leadValue) &&
                    formik.errors?.leadValue
                  }
                />
              </FormControl>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <FormControl fullWidth>
              <Typography>Lead Description</Typography>
              <Autocomplete
                options={leadsPopulate?.Descriptions || []}
                getOptionLabel={(option) => option?.Title}
                sx={{ flex: 1 }}
                size={"small"}
                fullWidth
                value={formik.values.selectedDescription}
                onChange={(event, value) => {
                  formik.setFieldValue("selectedDescription", value);
                  if (value) {
                    formik.setFieldValue("description", value?.Description);
                  } else {
                    formik.setFieldValue("description", "");
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                multiline
                rows={5}
                size={"small"}
                fullWidth
                placeholder="Description..."
                name="description"
                value={formik.values?.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors?.description) &&
                  Boolean(formik.touched?.description)
                }
                helperText={
                  Boolean(formik.errors?.description) &&
                  Boolean(formik.touched?.description) &&
                  formik.errors?.description
                }
              />
            </FormControl>
          </Box>

          {!selectedCustomer && (
            <FormControl fullWidth>
              <Typography>Customer</Typography>

              <Box sx={{ display: "flex", gap: "10px" }}>
                {formik.values.addNewPerson ? (
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <FormControl sx={{ flex: 1 }} fullWidth>
                      <TextField
                        size={"small"}
                        fullWidth
                        placeholder="Name..."
                        name="newPersonName"
                        value={formik.values?.newPersonName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors?.newPersonName &&
                          formik.touched?.newPersonName
                        }
                        helperText={
                          formik.errors?.newPersonName &&
                          formik.touched?.newPersonName &&
                          formik.errors?.newPersonName
                        }
                      />
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} fullWidth>
                      <PhoneInput
                        inputProps={{
                          name: "newPersonPhone",
                        }}
                        inputStyle={{
                          // backgroundColor:"yellow",
                          width: "100%",
                          height: "40px",
                          borderColor:
                            formik.errors?.newPersonPhone &&
                            formik.touched?.newPersonPhone
                              ? "#d32f2f"
                              : "inherit",
                        }}
                        containerStyle={{
                          width: "100%",
                          height: "40px",
                        }}
                        country={"pk"}
                        value={formik.newPersonPhone}
                        onChange={(phone, data) => {
                          formik.setFieldValue(
                            "newPersonDialCode",
                            data.dialCode
                          );
                          formik.setFieldValue("newPersonPhone", phone);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors?.newPersonPhone &&
                        formik.touched?.newPersonPhone && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {formik.errors?.newPersonPhone}
                          </FormHelperText>
                        )}
                    </FormControl>

                    <Button
                      sx={{ alignSelf: "center" }}
                      variant="contained"
                      size="small"
                      onClick={() => handleAddNewPerson("Person")}
                    >
                      Add
                    </Button>
                  </Box>
                ) : (
                  <Autocomplete
                    options={leadsPopulate?.personData || []}
                    getOptionLabel={(option) => option?.Name}
                    renderOption={(props, option) => {
                      return (
                        <ListItem {...props} key={option.Id}>
                          <Typography>
                            {option?.Name} ({option?.ContactNumber})
                          </Typography>
                        </ListItem>
                      );
                    }}
                    sx={{ flex: 1 }}
                    size={"small"}
                    fullWidth
                    value={
                      leadsPopulate?.personData?.find(
                        (person) => person?.Id === formik.values.personId
                      ) || null
                    }
                    onChange={(event, value) => {
                      if (value) {
                        formik.setFieldValue("personId", value?.Id);
                        setCustomer(value);
                      } else {
                        formik.setFieldValue("personId", "");
                        setCustomer(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="personId"
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors?.personId && formik.touched?.personId
                        }
                        helperText={
                          formik.errors?.personId && formik.touched?.personId
                            ? formik.errors?.personId
                            : ""
                        }
                      />
                    )}
                  />
                )}
                <AppTooltip
                  title={
                    formik.values.addNewPerson ? "Add Existing" : "Add New"
                  }
                >
                  <IconButton
                    sx={{ alignSelf: "center" }}
                    size="small"
                    onClick={() => {
                      formik.setFieldValue(
                        "addNewPerson",
                        !formik.values.addNewPerson
                      );
                    }}
                  >
                    {formik.values.addNewPerson ? (
                      <HistoryIcon fontSize="small" />
                    ) : (
                      <AddCircleIcon fontSize="small" />
                    )}
                  </IconButton>
                </AppTooltip>
              </Box>
            </FormControl>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "10px",
            }}
          >
            <FormControl fullWidth>
              <Typography>Type</Typography>
              <TextField
                name="leadTypeId"
                value={formik.values?.leadTypeId}
                size={"small"}
                select
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors?.leadTypeId) &&
                  Boolean(formik.touched?.leadTypeId)
                }
                helperText={
                  Boolean(formik.errors?.leadTypeId) &&
                  Boolean(formik.touched?.leadTypeId) &&
                  formik.errors?.leadTypeId
                }
              >
                <MenuItem value="">Select</MenuItem>
                {leadsPopulate?.typeData?.map((type, i) => {
                  return (
                    <MenuItem key={i} value={type?.Id}>
                      {type?.Name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

            <FormControl fullWidth>
              <Typography>Source</Typography>
              <TextField
                name="leadSourceId"
                value={formik.values?.leadSourceId}
                size={"small"}
                select
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors?.leadSourceId) &&
                  Boolean(formik.touched?.leadSourceId)
                }
                helperText={
                  Boolean(formik.errors?.leadSourceId) &&
                  Boolean(formik.touched?.leadSourceId) &&
                  formik.errors?.leadSourceId
                }
              >
                <MenuItem value="">Select</MenuItem>
                {leadsPopulate?.sourceData?.map((source, i) => {
                  return (
                    <MenuItem key={i} value={source?.Id}>
                      {source?.Name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
          </Box>

          {formik.values.source === "Affiliates" && (
            <FormControl fullWidth>
              <Typography>Affiliate</Typography>

              <Box sx={{ display: "flex", gap: "10px" }}>
                {formik.values.addNewAffiliate ? (
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <FormControl sx={{ flex: 1 }} fullWidth>
                      <TextField
                        size={"small"}
                        fullWidth
                        placeholder="Name..."
                        name="newAffiliateName"
                        value={formik.values?.newAffiliateName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors?.newAffiliateName &&
                          formik.touched?.newAffiliateName
                        }
                        helperText={
                          formik.errors?.newAffiliateName &&
                          formik.touched?.newAffiliateName &&
                          formik.errors?.newAffiliateName
                        }
                      />
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} fullWidth>
                      <PhoneInput
                        inputProps={{
                          name: "newAffiliatePhone",
                        }}
                        inputStyle={{
                          // backgroundColor:"yellow",
                          width: "100%",
                          height: "40px",
                          borderColor:
                            formik.errors?.newAffiliatePhone &&
                            formik.touched?.newAffiliatePhone
                              ? "#d32f2f"
                              : "inherit",
                        }}
                        containerStyle={{
                          width: "100%",
                          height: "40px",
                        }}
                        country={"pk"}
                        value={formik.newAffiliatePhone}
                        onChange={(phone, data) => {
                          formik.setFieldValue(
                            "newAffiliateDialCode",
                            data.dialCode
                          );
                          formik.setFieldValue("newAffiliatePhone", phone);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors?.newAffiliatePhone &&
                        formik.touched?.newAffiliatePhone && (
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {formik.errors?.newAffiliatePhone}
                          </FormHelperText>
                        )}
                    </FormControl>

                    <Button
                      sx={{ alignSelf: "center" }}
                      variant="contained"
                      size="small"
                      onClick={() => handleAddNewPerson("Affiliate")}
                    >
                      Add
                    </Button>
                  </Box>
                ) : (
                  <Autocomplete
                    options={leadsPopulate?.Affiliates || []}
                    getOptionLabel={(option) => option?.Name}
                    sx={{ flex: 1 }}
                    size={"small"}
                    fullWidth
                    value={
                      leadsPopulate?.Affiliates?.find(
                        (person) => person?.Id === formik.values.affiliateId
                      ) || null
                    }
                    onChange={(event, value) => {
                      if (value) {
                        formik.setFieldValue("affiliateId", value?.Id);
                      } else {
                        formik.setFieldValue("affiliateId", "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="affiliateId"
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors?.affiliateId &&
                          formik.touched?.affiliateId
                        }
                        helperText={
                          formik.errors?.affiliateId &&
                          formik.touched?.affiliateId
                            ? formik.errors?.affiliateId
                            : ""
                        }
                      />
                    )}
                  />
                )}
                <AppTooltip
                  title={
                    formik.values.addNewAffiliate ? "Add Existing" : "Add New"
                  }
                >
                  <IconButton
                    sx={{ alignSelf: "center" }}
                    size="small"
                    onClick={() => {
                      formik.setFieldValue(
                        "addNewAffiliate",
                        !formik.values.addNewAffiliate
                      );
                    }}
                  >
                    {formik.values.addNewAffiliate ? (
                      <HistoryIcon fontSize="small" />
                    ) : (
                      <AddCircleIcon fontSize="small" />
                    )}
                  </IconButton>
                </AppTooltip>
              </Box>
            </FormControl>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "10px",
            }}
          >
            <FormControl fullWidth>
              <Typography>Sales Owner</Typography>
              <TextField
                name="userId"
                value={formik.values?.userId}
                size={"small"}
                select
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors?.userId) &&
                  Boolean(formik.touched?.userId)
                }
                helperText={
                  Boolean(formik.errors?.userId) &&
                  Boolean(formik.touched?.userId) &&
                  formik.errors?.userId
                }
              >
                <MenuItem value="">Select</MenuItem>
                {leadsUsers?.map((user, i) => (
                  <MenuItem key={i} value={user?.Id}>
                    {user?.FullName}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            {leadsUsers?.length > 1 ? (
              <FormControl fullWidth>
                <Typography>Sales Co. Owner</Typography>
                <Autocomplete
                  multiple
                  name="coLeadUsers"
                  options={coLeadsUsers || []}
                  getOptionLabel={(option) => option?.FullName}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  value={formik.values?.coLeadUsers}
                  renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option?.FullName}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={(__, value) => {
                    formik.setFieldValue("coLeadUsers", value);
                    // formik.values?.date=e.target.value
                  }}
                />
              </FormControl>
            ) : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "10px",
              width: "50%",
            }}
          >
            <FormControl fullWidth>
              <Typography>Expected Close Date</Typography>
              <TextField
                name="expectedCloseDate"
                type={"date"}
                value={formik.values?.expectedCloseDate}
                size={"small"}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors?.expectedCloseDate) &&
                  Boolean(formik.touched?.expectedCloseDate)
                }
                helperText={
                  Boolean(formik.errors?.expectedCloseDate) &&
                  Boolean(formik.touched?.expectedCloseDate) &&
                  formik.errors?.expectedCloseDate
                }
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button
                  onClick={() => {
                    if (leadsViewData) {
                      handleNavigate();
                    } else {
                      handleBack();
                    }
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                </Button>
              </Box>
              <Box>
                {/* {
                  activeStep === steps.length - 1 ?    <Button type='submit' sx={{ backgroundColor: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.white.main }}>
                  less
                </Button> :

                 
                    <Button sx={{ backgroundColor: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.white.main }} onClick={handleNext}>
                    Next
                  </Button>
                } */}

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

export default Details;
Details.propTypes = {
  handleNext: PropTypes.any,
  handleBack: PropTypes.any,
  leadsPopulate: PropTypes.any,
  setdetailsData: PropTypes.any,
  setcontactPersonData: PropTypes.any,
  leadsViewData: PropTypes.any,
  detailsData: PropTypes.any,
  setCustomer: PropTypes.any,
  setTitle: PropTypes.any,
  customer: PropTypes.any,
  publicLeadData: PropTypes.any,
  selectedCustomer: PropTypes.string,
};

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
});
