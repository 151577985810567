import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Notifications: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchNotifications = createAsyncThunk(
  "/getAllNotifications",
  async () => {
    const response = await axiosInstance.get(`/Notifications/AllNotification`);
    return response;
  }
);
export const fetchNotificationsbyUser = createAsyncThunk(
  "LeadRemarks/Getall",
  async () => {
    const response = await axiosInstance.get(
      `LeadRemarks/Getall`
    );
    return response;
  }
);

const NotificationSlice = createSlice({
  name: "Notifications",
  initialState,
  reducers: {
    NotificationsAdded: {
      reducer(state, action) {
        state.Notifications?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Notifications = action.payload.data.Data;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchNotificationsbyUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotificationsbyUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Notifications = action.payload.data.Data;
      })
      .addCase(fetchNotificationsbyUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getNotifications = (state) => state.Notifications;

export const { NotificationsAdded } = NotificationSlice.actions;

export default NotificationSlice.reducer;
