import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { Badge, Button, Typography, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useContext } from "react";
import PropTypes from "prop-types";

import Joyride from "react-joyride";
import { AppContext } from "../../../Context/TourContext/TourContext";

import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import { RemoveSCFromInputs } from "../../../Utils/RemoveSCFromInputs";
import {
  fetchBookUnit,
  getUnitsDetails,
} from "../../../Redux/Slice/UnitSlice/UnitSlice";
import BookedUnitFilterDrawer from "../../../Components/Inventory/BookedUnit/BookedUnitFilterDrawer/BookedUnitFilterDrawer";
import BookedUnitInfoModal from "../../../Components/Inventory/BookedUnit/BookedUnitInfoModal";
import Loader from "../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

function BookedUnits({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  const { totalRows } = useContext(PageNumberContext);

  let guide = sessionStorage.getItem("guide");
  const [pagesize, setpagesize] = useState(totalRows);
  const [rows, setrows] = React.useState([]);
  const [allUnit, setallUnit] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  // const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  // const [selectedId, setselectedId] = useState(null);
  const person = useRef();
  const unit = useRef();
  const section = useRef();
  const project = useRef();
  const leadTitle = useRef();
  const { setState, state } = useContext(AppContext);
  const [unitInfoModal, setunitInfoModal] = useState(false);
  const [unitRow, setunitRow] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { BookedUnits, status } = useSelector(getUnitsDetails);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "BookedUnits");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);

  useEffect(() => {
    // if (BookedUnits == null) {
    dispatch(fetchBookUnit());
    // }
  }, [dispatch]);

  useEffect(() => {
    let rowData = [];
    BookedUnits?.map((unit, i) => {
      rowData.push({
        id: i + 1,
        amount: unit?.TokenAmount?.toLocaleString(),
        title: unit?.LeadTitle,
        person: unit?.PersonName,
        duration: unit?.NumberOfDays + " (days)",
        project: unit?.Unit?.Project.ProjectName,
        section: unit?.Unit?.Section?.SectionName,
        unit: unit?.Unit?.UnitName,
        area: unit.Unit.Marla + "M" + unit.Unit.Sarsai + "S",
        dimension:
          unit.Unit.DimensionLength !== null
            ? unit.Unit.DimensionLength?.toFixed(2) +
              " x " +
              unit.Unit.DimensionWidth?.toFixed(2)
            : "0x0",
        ID: unit.Unit.UnitId,
        sRate: unit.Unit.RatePerMarla
          ? Math.round(unit.Unit.RatePerMarla)?.toLocaleString()
          : "",
        totalValue: unit.Unit.Price
          ? Math.round(unit.Unit.Price)?.toLocaleString()
          : "",
        UnitTags: unit.Unit.UnitTags,
        UnitStatus: unit?.Unit.Status,
        TotalCost: unit.Unit.TotalCost ? Math.round(unit.Unit.TotalCost) : "",
        RatePerMarlaOrsqft: unit.Unit.RatePerMarlaOrsqft,
        NetSqft: unit.Unit.NetSqft,
        NetMarla: unit.Unit.NetMarla,
        DimensionWidth: unit.Unit.DimensionWidth,
        DimensionLength: unit.Unit.DimensionLength,
        CostPerMarla: unit.Unit.CostPerMarla,
        Price: unit.Unit.Price
          ? Math.round(unit.Unit.Price)?.toLocaleString()
          : "",
        RatePerMarla: unit.Unit.RatePerMarla,
        Marla: unit.Unit.Marla,
        Sarsai: unit.Unit.Sarsai,
        Section: unit.Unit.Section,
        Project: unit.Unit.Project,
        Category: unit?.Unit?.Category,
        LeadId: unit?.LeadId,
      });
    });
    setrows(rowData);
    setallUnit(rowData);
  }, [BookedUnits]);

  const columns = [
    { field: "id", headerName: "Sr", width: 90 },
    {
      field: "project",
      headerName: "Project",
      width: 180,
    },
    {
      field: "section",
      headerName: "Section",
      width: 180,
    },
    {
      field: "unit",
      headerName: "Booked Units",
      width: 150,
    },
    {
      field: "person",
      headerName: "Customers",
      width: 150,
    },
    {
      field: "amount",
      headerName: "Booking Amt",
      width: 180,
    },
    {
      field: "duration",
      headerName: "Booking Duration",
      width: 180,
    },
    {
      field: "title",
      headerName: "Lead Title",
      width: 300,
      renderCell: (celval) => {
        return (
          <Typography
            onClick={() => {
              roles?.some((role) => role === "Leads_View") &&
                navigate("/leads/view", {
                  state: celval?.row?.LeadId,
                });
            }}
            sx={{ color: "#298b8b", cursor: "pointer" }}
          >
            {celval.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 110,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <Tooltip title="Edit">
              <IconButton
                key={i}
                onClick={() => {
                  handleEdit(celval.row);
                }}
                disabled={
                  roles?.some((role) => role === "Products_Edit") ? false : true
                }
              >
                <EditIcon
                  sx={{ color: (theme) => theme?.palette?.primary?.edit }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip> */}
            {/* <Tooltip title="Delete">
              <IconButton
                key={i}
                onClick={() => {
                  setshowConfirmationIcons(true);
                  setselectedId(celval.row.ID);
                }}
                disabled={
                  roles?.some((role) => role === "Products_Delete")
                    ? false
                    : true
                }
              >
                <DeleteForeverIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.delete,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>*/}
            <Tooltip title="View">
              <IconButton
                key={i}
                onClick={() => {
                  setunitRow(celval.row);
                  setunitInfoModal(true);
                  // setShowModal(true);
                  // setProduct(celval.row);
                  // dispatch(fetchproduct(celval.row?.ID));
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };

  const onTextChange = () => {
    if (
      person.current?.value === "" &&
      unit.current?.value === "" &&
      section.current?.value === "" &&
      project.current?.value === "" &&
      leadTitle.current?.value === ""
    ) {
      setallUnit(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(RemoveSCFromInputs(project.current.value), "i");
      let regex2 = new RegExp(RemoveSCFromInputs(section.current.value), "i");
      let regex3 = new RegExp(RemoveSCFromInputs(unit.current.value), "i");
      let regex4 = new RegExp(RemoveSCFromInputs(person.current.value), "i");
      let regex5 = new RegExp(RemoveSCFromInputs(leadTitle.current.value), "i");
      suggestions = rows?.filter(
        (val) =>
          (project.current.value === "" ||
            regex1.test(val?.Project?.ProjectName)) &&
          (section.current.value === "" ||
            regex2.test(val?.Section?.SectionName)) &&
          (unit.current.value === "" || regex3.test(val?.unit)) &&
          (person.current.value === "" || regex4.test(val?.person)) &&
          (leadTitle.current.value === "" || regex5.test(val?.title))
      );
      setallUnit(suggestions);
    }
  };

  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "BookedUnits"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="unit">
        <BookedUnitFilterDrawer
          handleChangeVal={handleChangeVal}
          rows={rows}
          project={project}
          section={section}
          person={person}
          unit={unit}
          leadTitle={leadTitle}
          setcount={setcount}
          setallUnit={setallUnit}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allUnit}
            columns={columns}
          />
          {status === "loading" ? <Loader /> : null}
        </Box>
      </Box>

      {unitInfoModal && (
        <BookedUnitInfoModal
          unitRow={unitRow}
          setunitRow={setunitRow}
          setunitInfoModal={setunitInfoModal}
          unitInfoModal={unitInfoModal}
        />
      )}
    </>
  );
}

export default BookedUnits;
BookedUnits.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#unit",
    content: (
      <>
        <p size="large">Booked Units Page</p>
        <p>
          Welcome to Units Management – your control center for managing
          inventory units. Units play a crucial role in defining the quantities
          and measurements of inventory items, ensuring accuracy and consistency
          in inventory management.
          <br />
          <br />
          Use the intuitive interface to define and maintain unit
          specifications. Click &apos;New&apos;.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
