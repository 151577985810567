import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  Badge,
  Button,
  Chip,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import {
  fetchLeadByPiplineStage,
  fetchLeads,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { DeleteLeadApi } from "../../../Api/LeadsApi/DeleteLeadApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import moment from "moment/moment";
import PropTypes from "prop-types";
import theme from "../../../theme";
import LeadsFilterDrawer from "./LeadsFilterDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AppConfirmationDialog from "../../AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import UsersInfoModal from "./UsersInfoModal";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";
import AppTooltip from "../../../Utils/AppTooltip";
import EmailInfoModal from "../../Contacts/Persons/EmailInfoModal";
import TransferLeadsModal from "./TransferLeadsModal";
import AddRemarksModal from "./AddRemarksModal";
// import { RemoveSCFromInputs } from "../../../Utils/RemoveSCFromInputs";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    customTabSelected: {
      padding: "5px 12px 5px 12px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
      alignItems: "center",
      borderRadius: "19px",
    },
    customTab: {
      padding: "5px 10px 1px 10px",
      borderRadius: "20px",
      cursor: "pointer",
      alignItems: "center",
    },
    datGridBox: {
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 200px) !important",
      },
    },
  };
});

function LeadsTable({ defaultpipline, roles, status2, statusPipeline }) {
  const { totalRows } = useContext(PageNumberContext);
  const [checkedRows, setCheckedRows] = useState([]);
  const { header, customTabSelected, customTab, datGridBox } = useStyles();
  const [allLeads, setallLeads] = useState([]);
  const [rows, setrows] = useState([]);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [showLoading, setshowLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [selectedFilter, setselectedFilter] = useState("All");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(totalRows);
  const [totalCount, setTotalCount] = useState(0);
  console.log("totla", totalCount);
  console.log("rows", rows);
  const [leadsData, setLeadsData] = useState([]);
  const [filterVal, setfilterVal] = useState({
    person: "",
    salesperson: [],
    group: "",
    subject: "",
    stage: "",
    leadValue: "",
    leadSource: "",
    createdate2: "",
    enddate: "",
    closeDate: "",
    tags: [],
    project: "",
    id: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Leads, status } = useSelector(getLeadsDetails);
  const [showModal, setShowModal] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [email, setemail] = useState(null);

  const [users, setUsers] = useState(null);
  const [emailorContact, setemailorContact] = useState("");

  const getSelectedRows = () => {
    return allLeads.filter((row) => checkedRows.includes(row.id));
  };
  const handleClosed = () => {
    setUsers(null);
    setShowModal2(false);
  };
  const handleClosed2 = () => {
    setemailorContact("");
    setemail(null);
    setShowModal3(false);
  };
  useEffect(() => {
    if (defaultpipline) {
      dispatch(
        fetchLeads({
          id: defaultpipline?.Id,
          queryParams: { PageNumber: pageNo, PageSize: pageSize },
        })
      );
      dispatch(fetchLeadByPiplineStage(defaultpipline?.Id));
    }
  }, [defaultpipline]);

  useEffect(() => {
    if (Leads) {
      let arr = [...leadsData];
      Leads?.data?.forEach((activity) => {
        const index = arr.findIndex((obj) => obj?.Id === activity?.Id);
        if (index !== -1) {
          // if item is found
          arr[index] = activity; // replace item at the found index
        } else {
          arr.push(activity);
        }
      });
      setLeadsData([...arr]);
      setTotalCount(Leads?.TotalRecords);
    } else {
      setPageNo(1);
      setLeadsData([]);
    }
  }, [Leads]);

  const handleChangeVal = (e) => {
    // let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value || "",
    });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") &&
          checkedRows.length <= 0 && (
            <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
          )}
        {checkedRows.length <= 0 ? (
          <>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </>
        ) : (
          <Box display="flex" sx={{ m: 1 }} gap={2}>
            {roles?.some((role) => role === "Remarks_Create") && (
              <Button
                variant="contained"
                size="small"
                
                onClick={() => setShowModal4(true)}
                
              >
                Add Remark
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              onClick={() => setShowModal(true)}
            >
              Transfer
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => setCheckedRows([])}
            >
              Cancel
            </Button>
          </Box>
        )}
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (typeof filterVal[value] === "number") {
        sum = sum + 1;
      } else {
        if (filterVal[value]?.length) {
          sum = sum + 1;
        }
      }
    }
    setcount(sum);
  }, [filterVal]);

  useEffect(() => {
    let rowData = [];
    leadsData?.map((lead, i) => {
      rowData.push({
        ...lead,
        id: i + 1,
        ID: lead.Id,
        LeadSource: lead.LeadSourceName,
        LeadValue: lead.LeadValue
          ? Math.round(lead.LeadValue)?.toLocaleString()
          : "",
        PipelineStage: lead.PipelineStageName,
        PersonName: lead?.PersonName,
        PersonId: lead?.PersonId,

        ExpectedCloseDate: lead.ExpectedCloseDate
          ? moment(lead.ExpectedCloseDate).format("DD-MMM-YYYY")
          : null,
        CreatedAt: lead.CreatedAt
          ? moment(lead.CreatedAt).format("DD-MMM-YYYY")
          : null,
        Tags: lead?.LeadTags,
        Projects: lead?.Projects,
      });
    });
    setrows(rowData);
    setallLeads(rowData);
  }, [leadsData]);

  useEffect(() => {
    const salespersonIds =
      (filterVal?.salesperson && filterVal?.salesperson?.map((sp) => sp?.Id)) ||
      [];
    if (
      salespersonIds?.length === 0 &&
      // filterVal?.salesperson == [] &&
      // filterVal?.group === "" &&
      filterVal?.person === "" &&
      filterVal?.leadValue === "" &&
      filterVal?.leadSource === "" &&
      filterVal?.stage === "" &&
      filterVal?.subject === "" &&
      filterVal?.closeDate === "" &&
      filterVal?.tags?.length === 0 &&
      filterVal?.project === "" &&
      filterVal?.id === ""
    ) {
      setTotalCount(Leads?.TotalRecords);
      setallLeads(rows);
    } else {
      const leadValue = parseFloat(filterVal?.leadValue.replace(/,/g, ""));
      let suggestions = [];
      // let regex2 = new RegExp(filterVal?.salesperson, "i");
      // let regex3 = new RegExp(filterVal?.person, "i");
      let regex4 = new RegExp(leadValue, "i");
      // let regex5 = new RegExp(filterVal?.leadSource, "i");
      // let regex6 = new RegExp(filterVal?.stage, "i");
      let regex7 = new RegExp(filterVal?.subject, "i");

      let regex9 = new RegExp(
        moment(filterVal?.closeDate).format("DD-MMM-YYYY"),
        "i"
      );
      let regex10 = new RegExp(filterVal?.id, "i");

      suggestions = rows?.filter(
        (val) =>
          (salespersonIds?.length === 0 ||
            salespersonIds?.includes(val?.UserId)) &&
          // (filterVal?.salesperson == [] ||
          //   val?.UserId === filterVal?.salesperson) &&
          (filterVal?.person === "" || val?.PersonId === filterVal?.person) &&
          (filterVal?.leadValue === "" ||
            regex4.test(parseFloat(val?.LeadValue.replace(/,/g, "")))) &&
          (filterVal?.leadSource === "" ||
            filterVal?.leadSource === val?.LeadSourceId) &&
          // (filterVal?.group === "" ||
          //   filterVal?.group === val?.PipelineStageId) &&
          (filterVal?.stage === "" ||
            filterVal?.stage === val?.PipelineStageId) &&
          (filterVal?.subject === "" || regex7.test(val?.Title)) &&
          (filterVal?.closeDate === "" ||
            regex9.test(val?.ExpectedCloseDate)) &&
          (filterVal?.tags?.length === 0 ||
            filterVal?.tags?.every((element) =>
              val.LeadTags?.some((tag) => tag?.TagId === element?.TagId)
            )) &&
          (filterVal?.project === "" ||
            val.Projects.some(
              (project) => project.ProjectId === filterVal?.project
            )) &&
          (filterVal?.id === "" || regex10.test(val?.ID))
      );
      // setTotalCount(suggestions?.length);
      setallLeads(suggestions);
    }
  }, [filterVal, rows]);

  const deleteLeads = async (id) => {
    setshowLoading(true);
    let res = await DeleteLeadApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);
    if (res.data.IsSuccess) {
      dispatch(
        fetchLeads({
          id: defaultpipline?.Id,
          queryParams: { PageNumber: pageNo, PageSize: pageSize },
        })
      );
    }
    setsnackBarData(res.snackBarData);
  };

  const columns =
    roles?.some((role) => role === "Leads_View") ||
    roles?.some((role) => role === "Leads_Delete")
      ? [
          {
            field: "id",
            headerName: "Sr.",
            width: 90,
            headerClassName: header,
          },
          {
            field: "Projects",
            headerName: "Projects",
            width: 300,
            renderCell: (celval) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {celval.formattedValue?.map((proj, idx) => (
                    <div key={idx} style={{ marginRight: "5px" }}>
                      {idx > 0 && "| "}
                      {proj?.ProjectName}
                    </div>
                  ))}
                </Box>
              );
            },
          },
          {
            field: "PipelineStage",
            headerName: "Stage",
            width: 200,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor:
                        cellVal.row.PipelineStage === "Won"
                          ? "#53c41a"
                          : cellVal.row.PipelineStage === "Lost"
                          ? "red"
                          : "#0e90d9",
                      borderRadius: "100%",
                    }}
                  ></Box>
                  <Typography>{cellVal.row.PipelineStage}</Typography>
                </Box>
              );
            },
          },
          {
            field: "ID",
            headerName: "Lead Id",
            width: 80,

            headerClassName: header,
          },
          {
            field: "Title",
            headerName: "Title",
            width: 180,

            headerClassName: header,
          },
          {
            field: "FullName",
            headerName: "Sales Person",
            width: 200,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <div
                  style={{
                    display: "flex",
                    overflowX: "auto",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    if (cellVal.row?.LeadUsers?.length > 0) {
                      setShowModal2(true);
                      let arr = [`${cellVal.row?.FullName}`];
                      cellVal.row?.LeadUsers.forEach((item) => {
                        arr.push(`${item?.FullName}`);
                      });
                      setUsers(arr);
                    }
                  }}
                >
                  {cellVal.row?.FullName && (
                    <Chip
                      size="small"
                      label={cellVal.row?.FullName}
                      sx={{
                        margin: "1px",
                      }}
                    />
                  )}
                  {cellVal.row?.LeadUsers?.length > 0 && (
                    <Chip
                      size="small"
                      label={"..."}
                      sx={{
                        margin: "1px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              );
            },
          },
          {
            field: "PersonName",
            headerName: "Customer",
            width: 180,

            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Link
                  onClick={() => {
                    navigate("/contacts/persons/createPerson", {
                      state: { PersonId: cellVal.row?.PersonId },
                    });
                  }}
                  underline="none"
                  sx={{ cursor: "pointer" }}
                >
                  <Typography>{cellVal.row.PersonName}</Typography>
                </Link>
              );
            },
          },
          {
            field: "PersonContacts",
            headerName: "Contact No.",
            width: 150,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <>
                  <AppTooltip title={"Click to View All Contacts"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setemailorContact("contact");
                        setemail(celval.row);
                        setShowModal3(true);
                      }}
                    >
                      {celval.row?.PersonContacts?.map((contact, idx) => (
                        <Chip key={idx} label={`+${contact?.ContactNumber}`} />
                      ))}
                    </Box>
                  </AppTooltip>
                </>
              );
            },
          },

          {
            field: "LeadValue",
            headerName: "Lead Value",
            width: 150,

            headerClassName: header,
          },
          {
            field: "LeadSource",
            headerName: "Lead Source",
            width: 180,

            headerClassName: header,
          },

          {
            field: "ExpectedCloseDate",
            headerName: "Expected Close Date",
            width: 170,

            headerClassName: header,
          },
          {
            field: "Tags",
            headerName: "Tags",
            width: 250,
            renderCell: (celval) => {
              return celval.formattedValue?.map((tag, idx) => (
                <div
                  key={idx}
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  <Chip
                    size="small"
                    label={tag?.Name}
                    sx={{
                      margin: "1px",
                      backgroundColor: tag?.Color,
                      color: "#fff",
                    }}
                  />
                </div>
              ));
            },
          },
          {
            field: "action",
            headerName: "Action",
            width: 180,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <Box>
                  {roles?.some((role) => role === "Leads_View") && (
                    <Tooltip title="View">
                      <IconButton
                        onClick={() => {
                          navigate("/leads/view", { state: celval?.row?.ID });
                        }}
                      >
                        <VisibilityIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {roles?.some((role) => role === "Leads_Delete") && (
                    <Tooltip title="Delete">
                      <IconButton
                        key={i}
                        onClick={() => {
                          setselectedId(celval.row.ID);
                          setshowConfirmationIcons(true);
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : [
          {
            field: "id",
            headerName: "Sr.",
            width: 90,
            headerClassName: header,
          },
          {
            field: "Projects",
            headerName: "Projects",
            width: 300,
            renderCell: (celval) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {celval.formattedValue?.map((proj, idx) => (
                    <div key={idx} style={{ marginRight: "5px" }}>
                      {idx > 0 && "| "}
                      {proj?.ProjectName}
                    </div>
                  ))}
                </Box>
              );
            },
          },
          {
            field: "PipelineStage",
            headerName: "Stage",
            width: 200,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor:
                        cellVal.row.PipelineStage === "Won"
                          ? "#53c41a"
                          : cellVal.row.PipelineStage === "Lost"
                          ? "red"
                          : "#0e90d9",
                      borderRadius: "100%",
                    }}
                  ></Box>
                  <Typography>{cellVal.row.PipelineStage}</Typography>
                </Box>
              );
            },
          },
          {
            field: "ID",
            headerName: "Lead Id",
            width: 80,

            headerClassName: header,
          },
          {
            field: "Title",
            headerName: "Title",
            width: 180,

            headerClassName: header,
          },
          {
            field: "FullName",
            headerName: "Sales Person",
            width: 200,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <div
                  style={{
                    display: "flex",
                    overflowX: "auto",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    if (cellVal.row?.LeadUsers?.length > 0) {
                      setShowModal2(true);
                      let arr = [`${cellVal.row?.FullName}`];
                      cellVal.row?.LeadUsers.forEach((item) => {
                        arr.push(`${item?.FullName}`);
                      });
                      setUsers(arr);
                    }
                  }}
                >
                  {cellVal.row?.FullName && (
                    <Chip
                      size="small"
                      label={cellVal.row?.FullName}
                      sx={{
                        margin: "1px",
                      }}
                    />
                  )}
                  {cellVal.row?.LeadUsers?.length > 0 && (
                    <Chip
                      size="small"
                      label={"..."}
                      sx={{
                        margin: "1px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              );
            },
          },
          {
            field: "PersonName",
            headerName: "Customer",
            width: 180,

            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <Link
                  onClick={() => {
                    navigate("/contacts/persons/createPerson", {
                      state: { PersonId: cellVal.row?.PersonId },
                    });
                  }}
                  underline="none"
                  sx={{ cursor: "pointer" }}
                >
                  <Typography>{cellVal.row.PersonName}</Typography>
                </Link>
              );
            },
          },
          {
            field: "PersonContacts",
            headerName: "Contact No.",
            width: 150,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <>
                  <AppTooltip title={"Click to View All Contacts"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setemailorContact("contact");
                        setemail(celval.row);
                        setShowModal3(true);
                      }}
                    >
                      {celval.row?.PersonContacts?.map((contact, idx) => (
                        <Chip key={idx} label={`+${contact?.ContactNumber}`} />
                      ))}
                    </Box>
                  </AppTooltip>
                </>
              );
            },
          },
          {
            field: "LeadValue",
            headerName: "Lead Value",
            width: 150,

            headerClassName: header,
          },
          {
            field: "LeadSource",
            headerName: "Lead Source",
            width: 180,

            headerClassName: header,
          },

          {
            field: "ExpectedCloseDate",
            headerName: "Expected Close Date",
            width: 170,

            headerClassName: header,
          },
          {
            field: "Tags",
            headerName: "Tags",
            width: 250,
            renderCell: (celval) => {
              return celval.formattedValue?.map((tag, idx) => (
                <div
                  key={idx}
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  <Chip
                    size="small"
                    label={tag?.Name}
                    sx={{
                      margin: "1px",
                      backgroundColor: tag?.Color,
                      color: "#fff",
                    }}
                  />
                </div>
              ));
            },
          },
        ];

  useEffect(() => {
    if (selectedFilter === "All") {
      setTotalCount(Leads?.TotalRecords);
      setallLeads(rows);
    } else {
      let valuess = rows?.filter(
        (item) =>
          item?.PipelineStage?.toLowerCase() ===
          selectedFilter?.Name?.toLowerCase()
      );
      setTotalCount(valuess?.length);
      setallLeads(valuess);
    }
  }, [selectedFilter]);

  const handleRowClick = (params) => {
    params?.field === "PersonName" ||
    params?.field === "action" ||
    params?.field === "FullName" ||
    params?.field === "PersonContacts" ||
    params?.field === "__check__" ||
    params?.field === "id"
      ? null
      : navigate("/leads/view", { state: params?.row?.ID });
  };

  const searchRefreshData = (action) => {
    setPageNo(1);
    setLeadsData([]);

    if (action === "search") {
      let salespersonIds = null;
      if (filterVal?.salesperson) {
        salespersonIds = filterVal?.salesperson?.map((sp) => sp?.Id) || [];
      }
      console.log(salespersonIds);
      dispatch(
        fetchLeads({
          id: defaultpipline?.Id,
          queryParams: {
            PageNumber: 1,
            PageSize: pageSize,
            title: filterVal?.subject,
            projectId: filterVal?.project,
            userid: salespersonIds && JSON.stringify(salespersonIds),
            groupId: filterVal?.group ? filterVal?.group : "",
            personId: filterVal?.person,
            leadValue: filterVal?.leadValue,
            leadSourceId: filterVal?.leadSource,
            pipelineStageId: filterVal?.stage,
            leadId: filterVal?.id,
          },
        })
      );
    } else {
      dispatch(
        fetchLeads({
          id: defaultpipline?.Id,
          queryParams: {
            PageNumber: 1,
            PageSize: pageSize,
          },
        })
      );
    }
  };
  return (
    <>
      <Box>
        <Box>
          <LeadsFilterDrawer
            setdrawerOpen={setdrawerOpen}
            drawerOpen={drawerOpen}
            rows={rows}
            setallLeads={setallLeads}
            handleChangeVal={handleChangeVal}
            setcount={setcount}
            setfilterVal={setfilterVal}
            filterVal={filterVal}
            setTotalCount={setTotalCount}
            searchRefreshData={searchRefreshData}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "6px",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <Box
              onClick={() => {
                setselectedFilter("All");
              }}
              className={
                selectedFilter === "All" ? customTabSelected : customTab
              }
            >
              <Typography
                sx={{
                  color: selectedFilter === "All" ? "white" : "#546e7a",
                  fontWeight: "bold",
                }}
              >
                All
              </Typography>
            </Box>
            {defaultpipline?.LeadPipelineStages?.map((pipline, i) => {
              return (
                <Box
                  key={i}
                  onClick={() => {
                    setselectedFilter(pipline);
                  }}
                  className={
                    selectedFilter?.Name === pipline.Name &&
                    selectedFilter?.Id === pipline.Id
                      ? customTabSelected
                      : customTab
                  }
                >
                  <Typography
                    sx={{
                      color:
                        selectedFilter?.Name === pipline.Name &&
                        selectedFilter?.Id === pipline.Id
                          ? "white"
                          : "#546e7a",
                      fontWeight: "bold",
                    }}
                  >
                    {pipline?.Name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          className={datGridBox}
          sx={{
            height: "calc(100vh - 160px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: "white !important",
          }}
        >
          <DataGrid
            checkboxSelection={
              roles?.some((role) => role === "Leads_Transfer") ? true : false
            }
            disableSelectionOnClick
            selectionModel={checkedRows}
            onSelectionModelChange={(newSelection) => {
              console.log("Selected IDs:", newSelection);
              setCheckedRows(newSelection);
            }}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allLeads}
            columns={columns}
            // onRowClick={handleRowClick}
            onCellClick={handleRowClick}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            pagination
            page={pageNo - 1}
            rowCount={totalCount}
            onPageChange={(e) => {
              if (
                leadsData.length < pageSize * (e + 1) &&
                leadsData.length < Leads?.TotalRecords
              ) {
                dispatch(
                  fetchLeads({
                    id: defaultpipline?.Id,
                    queryParams: {
                      PageNumber: e + 1,
                      PageSize: pageSize,
                      title: filterVal?.subject || null,
                      projectId: filterVal?.project || null,
                      userid:
                        filterVal?.salesperson?.length > 0
                          ? JSON.stringify(
                              filterVal?.salesperson?.map((sp) => sp?.Id)
                            )
                          : null,
                      personId: filterVal?.person || null,
                      leadValue: filterVal?.leadValue || null,
                      leadSourceId: filterVal?.leadSource || null,
                      pipelineStageId: filterVal?.stage || null,
                      leadId: filterVal?.id || null,
                    },
                  })
                );
              }
              setPageNo(e + 1);
            }}
          />
          {status === "loading" ||
          statusPipeline === "loading" ||
          status2 === "loading" ||
          showLoading ? (
            <Loader />
          ) : null}
        </Box>
      </Box>

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteLeads(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Lead"
        title={`Are you sure, you want to Delete Lead?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
      {showModal2 && (
        <UsersInfoModal
          showModal={showModal2}
          handleClosed={handleClosed}
          users={users}
        />
      )}
      {showModal3 && (
        <EmailInfoModal
          showModal={showModal3}
          handleClosed={handleClosed2}
          email={email}
          emailorContact={emailorContact}
        />
      )}
      {showModal && (
        <TransferLeadsModal
          selectedLeads={getSelectedRows()}
          handleClosed={() => {
            setShowModal(false);
            setCheckedRows([]);
          }}
          showModal={showModal}
          refreshLeadsPage={() => {
            dispatch(
              fetchLeads({
                id: defaultpipline?.Id,
                queryParams: { PageNumber: 1, PageSize: pageSize },
              })
            );
          }}
        />
      )}
      {showModal4 && (
        <AddRemarksModal
          selectedLeads={getSelectedRows()}
          handleClosed={() => {
            setShowModal4(false);
            //  setCheckedRows([]);
          }}
          RowsUpdate={setCheckedRows}
          showModal={showModal4}
          refreshLeadsPage={() => {
            dispatch(
              fetchLeads({
                id: defaultpipline?.Id,
                queryParams: { PageNumber: 1, PageSize: pageSize },
              })
            );
          }}
        />
      )}
    </>
  );
}

export default LeadsTable;
LeadsTable.propTypes = {
  defaultpipline: PropTypes.any,
  roles: PropTypes.array,
  status2: PropTypes.any,
  statusPipeline: PropTypes.any,
  
};
