import React, { useContext, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import theme from "../../theme";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import Loader from "../../Components/Loader/Loader";
import { CreateFacebookPageApi } from "../../Api/FacebookPagesApi/CreateFacebookPageApi";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPagesPopulate,
  getFacebookPageDetails,
} from "../../Redux/Slice/Settings/FacebookPageSlice/FacebookPageSlice";

const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});
function FbToken() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { PagesPopulate, status } = useSelector(getFacebookPageDetails);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPagesPopulate());
  }, []);
  console.log(state);
  const initialValues = {
    pageTitle: "",
    pageId: "",
    accessToken: "",
    organizationId: null,
  };
  useEffect(() => {
    if (state) {
      formik.setFieldValue("pageTitle", state?.PageTitle);
      formik.setFieldValue("pageId", state?.PageId);
      formik.setFieldValue("accessToken", state?.AccessToken);
      formik.setFieldValue(
        "organizationId",
        state?.Organization?.Name
          ? {
              Name: state?.Organization?.Name,
              Id: state?.Organization?.OrganizationId,
            }
          : null
      );
      //   formik.setFieldValue("organizationId", state?.AccessToken);
    }
  }, [state]);
  const navigate = useNavigate();
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let obj = null;
    if (state) {
      obj = {
        ...values,
        pageId: String(values?.pageId),
        id: state?.Id,
        organizationId: values?.organizationId?.Id
          ? values?.organizationId?.Id
          : null,
        pageType: "Facebook",
      };
    } else {
      obj = {
        ...values,
        pageId: String(values?.pageId),
        organizationId: values?.organizationId?.Id
          ? values?.organizationId?.Id
          : null,
        pageType: "Facebook",
      };
    }
    const response = await CreateFacebookPageApi(obj);
    setsnackBarData(response.snackBarData);
    if (response?.data.IsSuccess) {
      setSubmitting(false);
      resetForm();
      navigate("/settings/fbpagetoken");
    }

    setSubmitting(false);
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    pageTitle: Yup.string().required("Please Enter Page Title"),
    pageId: Yup.string().required("Please Enter Page Id"),
    accessToken: Yup.string().required("Please Enter Access Token"),
    organizationId: Yup.object()
      .nullable()
      .required("Please Enter Organization"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box role="presentation" onClick={(e) => e.preventDefault()}>
          <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
            <Link
              to="/dashboard"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Dashboard
            </Link>
            <Link
              to="/settings"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Settings
            </Link>
            <Link
              to="/settings/fbpagetoken"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Facebook Page Token
            </Link>
            <Typography color="text.primary" sx={{ fontSize: "12px" }}>
              {state
                ? "Update Facebook Page Token"
                : "Create Facebook Page Token"}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
          {state ? "Update" : "Add"} Facebook Page Token
        </Typography>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Organization</Typography>
                <Autocomplete
                  name="organizationId"
                  //   options={["hjehje"]}
                  options={PagesPopulate}
                  getOptionLabel={(option) => option.Name}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        Boolean(formik.errors.organizationId) &&
                        Boolean(formik.touched.organizationId)
                      }
                      helperText={
                        Boolean(formik.errors.organizationId) &&
                        Boolean(formik.touched.organizationId) &&
                        formik.errors.organizationId
                      }
                      //   name="personId"
                    />
                  )}
                  value={formik.values.organizationId}
                  onChange={(__, value) => {
                    formik.setFieldValue("organizationId", value);
                    // formik.values?.date=e.target.value
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Page Title</Typography>
                <TextField
                  type="text"
                  name="pageTitle"
                  value={formik.values?.pageTitle}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.pageTitle) &&
                    Boolean(formik.touched.pageTitle)
                  }
                  helperText={
                    Boolean(formik.errors.pageTitle) &&
                    Boolean(formik.touched.pageTitle)
                      ? formik.errors.pageTitle
                      : ""
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Page ID</Typography>
                <TextField
                  type="number"
                  name="pageId"
                  value={formik.values?.pageId}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.pageId) &&
                    Boolean(formik.touched.pageId)
                  }
                  helperText={
                    Boolean(formik.errors.pageId) &&
                    Boolean(formik.touched.pageId)
                      ? formik.errors.pageId
                      : ""
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Page Access Token</Typography>
                <TextField
                  name="accessToken"
                  value={formik.values?.accessToken}
                  size={"small"}
                  multiline
                  rows={4}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.accessToken) &&
                    Boolean(formik.touched.accessToken)
                  }
                  helperText={
                    Boolean(formik.errors.accessToken) &&
                    Boolean(formik.touched.accessToken)
                      ? formik.errors.accessToken
                      : ""
                  }
                />
              </FormControl>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
      {status === "loading" ? <Loader /> : null}
    </Box>
  );
}

export default FbToken;
