import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyle = makeStyles((theme) => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textAreaContainer: {
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    textContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  };
});
function ServiceInfoModal({ showModal, handleClosed, service }) {
  const { formContainer, textAreaContainer, textContainer } = useStyle();

  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            Service Info
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                  //   pb: 1,
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <Box mt={"10px"} className={formContainer}>
          <Box className={textContainer}>
            <Box className={formContainer}>
              <Box className={textAreaContainer}>
                <Typography variant="body1" fontWeight="bold">
                  Name:
                </Typography>
                <Typography>{service?.name}</Typography>
              </Box>

              <Box className={textAreaContainer}>
                <Typography variant="body1" fontWeight="bold">
                  Price:
                </Typography>
                <Typography>{service?.price}</Typography>
              </Box>
            </Box>
            <Box className={formContainer}>
              <Box
                display={service?.ProductCategory ? "" : "none"}
                className={textAreaContainer}
              >
                <Typography variant="body1" fontWeight="bold">
                  Category:
                </Typography>
                <Typography>{service?.ProductCategory}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={formContainer}>
          <Box marginTop={"10px"} className={textAreaContainer}>
            <Typography variant="body1" fontWeight="bold">
              Description:
            </Typography>
            <Typography>{service?.Description}</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ServiceInfoModal;

ServiceInfoModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  service: PropTypes.any,
};
