import React, { useState, useEffect } from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  fetchTags,
  getTagsDetails,
} from "../../../../Redux/Slice/Settings/TagsSlice/TagsSlice";
import { useDispatch, useSelector } from "react-redux";
import TagsFilterDrawer from "./TagsFilterDrawer";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import Loader from "../../../Loader/Loader";
import { DeleteTagApi } from "../../../../Api/Settings/TagsApi/DeleteTagApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import moment from "moment/moment";
// import { SnackBarContext } from '../../../Context/SnackBarContext/SnackBarContext';
import PropTypes from "prop-types";
import { RemoveSCFromInputs } from "../../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../AppConfirmationDialog/AppConfirmationDialog";
import {
  // fetchNotifications,
  getNotifications,
} from "../../../../Redux/Slice/Notifications/GetNotification";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    circle: {
      width: "15px",
      height: "15px",
      borderRadius: "100%",
    },
  };
});

function Tags({ roles }) {
  const { totalRows } = useContext(PageNumberContext);

  const { Notifications } = useSelector(getNotifications);
  console.log("Notifications", Notifications);
  const { header, circle } = useStyles();
  const [rows, setrows] = useState([]);
  const [pageSize, setpageSize] = useState(totalRows);
  const navigate = useNavigate();
  const { Tags, status } = useSelector(getTagsDetails);
  const [allTags, setallTags] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const [showLoading, setshowLoading] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const name = useRef();
  const userRef = useRef();
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTags());
    // dispatch(fetchNotifications());
  }, [dispatch]);
  useEffect(() => {
    let arr = [];
    if (Tags) {
      Tags?.forEach((tags, idx) => {
        return arr.push({
          id: idx + 1,
          name: tags?.Name,
          backColor: tags?.Color,
          createddate: tags?.CreatedAt
            ? moment(tags?.CreatedAt).format("DD-MM-YYYY")
            : null,
          FullName: tags?.FullName,
          ID: tags?.TagId,
        });
      });
    }
    setrows(arr);
    setallTags(arr);
  }, [Tags]);

  const deleteTypes = async (id) => {
    setshowLoading(true);
    let res = await DeleteTagApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);
    if (res.data.IsSuccess) {
      dispatch(fetchTags());
    }
    setsnackBarData(res.snackBarData);
  };
  const handleEdit = (val) => {
    navigate("/settings/tags/create", { state: val });
  };
  //   const {setsnackBarData}=useContext(SnackBarContext);
  const columns =
    roles?.some((role) => role === "Tags_Edit") ||
    roles?.some((role) => role === "Tags_Delete")
      ? [
          { field: "id", headerName: "Sr", width: 80, headerClassName: header },
          {
            field: "name",
            headerName: "Name",
            width: 200,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Box
                      className={circle}
                      sx={{ backgroundColor: cellVal.row.backColor }}
                    ></Box>
                    {<Typography>{cellVal.row.name}</Typography>}
                  </Box>
                </>
              );
            },
          },
          {
            field: "FullName",
            headerName: "User",
            width: 200,
            headerClassName: header,
          },
          // {
          //   field: "createddate",
          //   headerName: "Created Date",
          //   width: 200,
          //   headerClassName: header,
          // },
          {
            field: "actions",
            headerName: "Actions",
            width: 200,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <>
                  <Box key={i}>
                    {roles?.some((role) => role === "Tags_Edit") && (
                      <Tooltip title="Edit">
                        <IconButton
                          key={i}
                          onClick={() => {
                            handleEdit(celval.row);
                          }}
                        >
                          <EditIcon
                            sx={{
                              color: (theme) => theme?.palette?.primary?.edit,
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    {roles?.some((role) => role === "Tags_Delete") && (
                      <Tooltip title="Delete">
                        <IconButton
                          key={i}
                          onClick={() => {
                            setselectedId(celval.row.ID);
                            setshowConfirmationIcons(true);
                          }}
                        >
                          <DeleteForeverIcon
                            sx={{
                              color: (theme) => theme?.palette?.primary?.delete,
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </>
              );
            },
          },
        ]
      : [
          { field: "id", headerName: "Sr", width: 80, headerClassName: header },
          {
            field: "name",
            headerName: "Name",
            width: 200,
            headerClassName: header,
            renderCell: (cellVal) => {
              return (
                <>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Box
                      className={circle}
                      sx={{ backgroundColor: cellVal.row.backColor }}
                    ></Box>
                    {<Typography>{cellVal.row.name}</Typography>}
                  </Box>
                </>
              );
            },
          },
          {
            field: "FullName",
            headerName: "User",
            width: 200,
            headerClassName: header,
          },
          // {
          //   field: "createddate",
          //   headerName: "Created Date",
          //   width: 200,
          //   headerClassName: header,
          // },
        ];

  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  const onTextChange = () => {
    if (name.current?.value === "" && userRef.current?.value === "") {
      setallTags(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(RemoveSCFromInputs(name.current.value), "i");
      let regex3 = new RegExp(RemoveSCFromInputs(userRef.current.value), "i");

      suggestions = rows?.filter(
        (val) =>
          (name.current.value === "" || regex2.test(val?.name)) &&
          (userRef.current.value === "" || regex3.test(val?.FullName))
      );
      setallTags(suggestions);
    }
  };
  return (
    <>
      <Box>
        <TagsFilterDrawer
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          userRef={userRef}
          setcount={setcount}
          setallTags={setallTags}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            rows={allTags}
            pageSize={pageSize}
            columns={columns}
            onPageSizeChange={(newPage) => {
              setpageSize(newPage);
            }}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            // scrollbarSize={1}
            // scrollbarSize={'1px'}
          />
          {status === "loading" || showLoading ? <Loader /> : null}
        </Box>
      </Box>

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteTypes(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Tag"
        title={`Are you sure, you want to Delete Tag?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Tags;
Tags.propTypes = {
  roles: PropTypes.array,
};
