import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { City } from "country-state-city";
import AddCategoryModal from "./AddCategoryModal";

// import Cookies from "universal-cookie";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { getProjectsDetails } from "../../../../Redux/Slice/ProjectSlice/ProjectCategorySlice";
import {
  fetchOrganizationPopulate,
  getOrganizationDetails,
} from "../../../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import { fetchProjectCategories } from "../../../../Redux/Slice/ProjectSlice/ProjectSlice";
import Loader from "../../../Loader/Loader";
import { CreateProjectApi } from "../../../../Api/ProjectApi/CreateProjectApi";
import theme from "../../../../theme";
import { ReactSelectTheme } from "../../../../Utils/ReactSelectTheme";
// import { fetchProducts } from '../../../Redux/Slice/ProductSlice/ProductSlice';

// import Loader from '../../Loader/Loader';
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function CreateProject() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { ProjectCategories, status1 } = useSelector(getProjectsDetails);
  const { OrganizationPopulate } = useSelector(getOrganizationDetails);
  // const cookies = new Cookies();
  // let businessType = cookies.get("BusinessType");
  let businessType = sessionStorage.getItem("BusinessType");
  const dispatch = useDispatch();
  const [allcities, setallcities] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [catOptions, setCatOptions] = useState([]);
  const [developers, setDevelopers] = useState([]);
  useEffect(() => {
    let value = City.getCitiesOfCountry("PK");
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.name,
      };
      return obj;
    });
    setallcities(value);
  }, []);
  useEffect(() => {
    let val = [{ label: "Add New", value: "new" }];
    ProjectCategories?.map((data) => {
      let obj = {
        label: data?.ProjectCategoryName,
        value: data?.ProjectCategoryId,
      };
      val.push(obj);
    });
    setCatOptions(val);
  }, [ProjectCategories]);
  useEffect(() => {
    let val = [];
    OrganizationPopulate?.map((data) => {
      let obj = {
        label: data?.Name,
        value: data?.Id,
      };
      val.push(obj);
    });
    setDevelopers(val);
  }, [OrganizationPopulate]);
  useEffect(() => {
    dispatch(fetchProjectCategories());
    dispatch(fetchOrganizationPopulate());
  }, [dispatch]);
  const initialValues = {
    name: "",
    location: "",
    owner: "",
    city: "",
    category: "",
  };

  const ownerOptions = [
    { value: "Own Project", label: "Own Project" },
    { value: "Deal From Others", label: "Deal From Others" },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      handleEdit(state);
    }
  }, []);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
    formik.handleChange({
      target: {
        name: "location",
        value: values?.location || "",
      },
    });
    formik.handleChange({
      target: {
        name: "owner",
        value:
          {
            label: values?.ProjectOwner?.Name,
            value: values?.ProjectOwner?.ProjectOwnerId,
          } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "category",
        value:
          {
            label: values?.category?.ProjectCategoryName,
            value: values?.category?.ProjectCategoryId,
          } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "city",
        value:
          {
            label: values?.city,
            value: values?.city,
          } || "",
      },
    });
  };
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let data = {
      projectId: state?.ID || null,
      projectName: values.name,
      projectLocation: values.location,
      projectCity: values.city.value,
      projectOwnerId: values.owner.value,
      projectCategoryId: values.category.value,
    };

    const response = await CreateProjectApi(data);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      setSubmitting(false);
      resetForm();
      navigate(-1);
    }
    setSubmitting(false);
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const handleClosed = () => {
    setOpenModal(false);
  };
  const handleCategoryChange = (value) => {
    if (value.value === "new") {
      setOpenModal(true);
    } else {
      formik.setFieldValue("category", value);
    }
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Project Name"),
    location: Yup.string().required("Please Enter location"),
    category: Yup.object().required("Please Select Category"),
    owner: Yup.object().required("Please Select Owner"),
    city: Yup.object().required("Please Select City"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  return (
    <Box className={container}>
      {status1 === "loading" ? <Loader /> : null}

      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/settings/inventory/project"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Project
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state ? "Update Project" : " Create Project"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state ? "Update Project" : " Create Project"}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  value={formik.values?.name}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                  }
                  helperText={
                    Boolean(formik.errors.name) &&
                    Boolean(formik.touched.name) &&
                    formik.errors.name
                  }
                />
              </FormControl>

              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Location</Typography>
                  <TextField
                    name="location"
                    type="text"
                    value={formik.values?.location}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.location) &&
                      Boolean(formik.touched.location)
                    }
                    helperText={
                      Boolean(formik.errors.location) &&
                      Boolean(formik.touched.location) &&
                      formik.errors.location
                    }
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.owner) &&
                    Boolean(formik.touched.owner)
                  }
                >
                  <Typography>Owner</Typography>
                  <Select
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.owner) &&
                    //       Boolean(formik.touched.owner)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.owner) &&
                        Boolean(formik.touched.owner)
                    )}
                    name="owner"
                    options={
                      businessType === "RealState" ? developers : ownerOptions
                    }
                    value={formik.values.owner}
                    onChange={(value) => {
                      formik.setFieldValue("owner", value);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select Owner"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.owner) &&
                    Boolean(formik.touched.owner)
                      ? formik.errors.owner
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box display="flex" gap={1}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.category) &&
                    Boolean(formik.touched.category)
                  }
                >
                  <Typography>Category</Typography>
                  <Select
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.category) &&
                    //       Boolean(formik.touched.category)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.category) &&
                        Boolean(formik.touched.category)
                    )}
                    name="category"
                    options={catOptions}
                    value={formik.values.category}
                    // onChange={(value) => {
                    //   formik.setFieldValue("category", value);
                    // }}
                    onChange={(value) => handleCategoryChange(value)}
                    onBlur={formik.handleBlur}
                    placeholder="Select Category"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.category) &&
                    Boolean(formik.touched.category)
                      ? formik.errors.category
                      : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.city) && Boolean(formik.touched.city)
                  }
                >
                  <Typography>City</Typography>
                  <Select
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.city) &&
                    //       Boolean(formik.touched.city)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.city) &&
                        Boolean(formik.touched.city)
                    )}
                    name="city"
                    options={allcities}
                    value={formik.values.city}
                    onChange={(value) => {
                      formik.setFieldValue("city", value);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select City"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.city) && Boolean(formik.touched.city)
                      ? formik.errors.city
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
      {openModal && (
        <AddCategoryModal
          showModal={openModal}
          handleClosed={handleClosed}
          setsnackBarData={setsnackBarData}
          dispatch={dispatch}
        />
      )}
    </Box>
  );
}

export default CreateProject;
