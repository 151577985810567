import {
  Autocomplete,
  Box,
  Button,
  Chip,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import Note from "../LeadView/Note/Note";
import Activity from "../LeadView/Activity/Activity";
// import LeadEmail from "../LeadView/LeadEmail/LeadEmail";
import File from "../LeadView/File/File";
import Quote from "../LeadView/Quote/Quote";
import RightBottomTabs from "../LeadView/RightBottomTab/RightBottomTabs";
import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchLeadsSingle,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import Loader from "../../Loader/Loader";
import moment from "moment/moment";
import PiplinesStages from "./PiplinesStages";
import SellIcon from "@mui/icons-material/Sell";
import {
  fetchTags,
  getTagsDetails,
} from "../../../Redux/Slice/Settings/TagsSlice/TagsSlice";
import AddIcon from "@mui/icons-material/Add";
import AddTags from "./AddTags";
import { SearchTagApi } from "../../../Api/LeadsApi/SearchTagsApi";
import ClearIcon from "@mui/icons-material/Clear";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { DeleteLeadTagsApi } from "../../../Api/LeadsApi/DeleteLeadTagsApi";
// import { SearchTagApi } from "../../../Api/LeadsApi/SearchTagsApi";
import PropTypes from "prop-types";
import AppTooltip from "../../../Utils/AppTooltip";
// import Cookies from "universal-cookie";
import AppConfirmationDialog from "../../AppConfirmationDialog/AppConfirmationDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function LeadView({ roles }) {
  const [value, setValue] = useState(0);
  const {
    LeadViewContainer,
    LeftLeadView,
    RightLeadView,
    DetailsTypoBox,
    DetailsPaperBox,
    paperSubBox,
    errorBox,
    multiDataBox,
    addTags,
    plotDetailsMainContainer,
    plotDetailsSingleCard,
    plotCount,
    plotDetailsCard,
    plotDetailsHeading,
    plotDetailsValue,
    // root
  } = useStyles();
  // const cookies = new Cookies();

  const [singleLead, setsingleLead] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addTag, setaddTag] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showModal, setshowModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [tags, settags] = useState(null);
  const [Items, setItems] = useState([]);
  const [Services, setServices] = useState([]);
  // const daysDiff = moment(singleLead?.CreatedAt).diff(today, "days");

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const state = useLocation();
  const dispatch = useDispatch();
  const { Tags } = useSelector(getTagsDetails);
  const { SingleLeads, status1, RottenDays } = useSelector(getLeadsDetails);

  useEffect(() => {
    dispatch(fetchTags());
  }, []);

  useEffect(() => {
    if (state?.state?.id) {
      dispatch(fetchLeadsSingle(state?.state?.id));
    } else if (state?.state) {
      dispatch(fetchLeadsSingle(state?.state));
    }
  }, [state]);

  useEffect(() => {
    setsingleLead(SingleLeads);
  }, [SingleLeads]);
  useEffect(() => {
    if (singleLead) {
      let arr1 = [];
      let arr2 = [];
      singleLead?.LeadProducts?.map((item) => {
        if (item?.Type == "Service") {
          arr1.push(item);
        } else if (item?.Type == "Good") {
          arr2.push(item);
        }
      });
      setItems(arr2);
      setServices(arr1);
    }
    // if (singleLead?.CreatedAt) {
    //   setDaysDiff(moment(today).diff(singleLead?.CreatedAt, "days"));
    // }
  }, [singleLead]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ChangeTags = async (value) => {
    handleClose();

    if (value === null) {
      return;
    }
    const response = await SearchTagApi({
      tagId: value?.TagId,
      leadId: singleLead?.Id,
    });
    setsnackBarData(response.snackBarData);

    if (response?.data.IsSuccess) {
      if (state?.state?.id) {
        dispatch(fetchLeadsSingle(state?.state?.id));
      } else {
        dispatch(fetchLeadsSingle(state?.state));
      }
    }
    // dispatch(
    //   SearchTagApi({
    //     tagId: value?.TagId,
    //     leadId: singleLead?.Id,
    //   })
    // );
  };

  const deleteTags = async () => {
    setloading(true);
    const response = await DeleteLeadTagsApi(tags?.LeadTagId);
    setloading(false);
    settags(null);
    handleClosed();

    if (response?.data.IsSuccess) {
      if (state?.state?.id) {
        dispatch(fetchLeadsSingle(state?.state?.id));
      } else {
        dispatch(fetchLeadsSingle(state?.state));
      }
    }
    setsnackBarData(response.snackBarData);
  };

  const handleClickOpen = () => {
    setshowModal(true);
  };

  const handleClosed = () => {
    setshowModal(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Typography variant="h5">{singleLead?.Title}</Typography>
          <AppTooltip title={"Add Tags"}>
            <SellIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
                cursor: "pointer",
              }}
              onClick={(e) => {
                if (roles?.some((role) => role === "Leads_Edit")) {
                  handleClick(e);
                }
              }}
            />
          </AppTooltip>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              },
            }}
          >
            {addTag === true ? (
              <AddTags
                state={state}
                singleLead={singleLead}
                setaddTag={setaddTag}
              />
            ) : (
              <>
                <MenuItem
                  // className={root}
                  sx={{
                    backgroundColor: "white !important",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleClose();
                  }}
                >
                  <Autocomplete
                    sx={{ width: "200px" }}
                    name="tags"
                    options={Tags || []}
                    getOptionLabel={(option) => option?.Name}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField placeholder="Select Tag" {...params} />
                    )}
                    // value={formik.values?.leadId}
                    onChange={(__, value) => {
                      ChangeTags(value);

                      // formik.setFieldValue("leadId", value);
                      // formik.values?.date=e.target.value
                    }}
                  />
                </MenuItem>
                <Divider sx={{ width: "100%" }} />
                <Box
                  className={addTags}
                  onClick={() => {
                    setaddTag(true);
                  }}
                >
                  <AddIcon fontSize="small" />
                  <Typography>Add Tags</Typography>
                </Box>
              </>
            )}
          </Menu>
          <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
            {singleLead?.LeadTag?.map((tag, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    backgroundColor: tag?.Color,
                    paddingX: "5px",
                    borderRadius: "4px",
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "white !important" }}>
                    {tag?.Name}
                  </Typography>
                  <ClearIcon
                    fontSize="small"
                    sx={{
                      fontSize: "15px",
                      cursor: "pointer",
                      color: "white !important",
                    }}
                    onClick={() => {
                      settags(tag);
                      handleClickOpen();
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        {roles?.some((role) => role === "Leads_Edit") && (
          <Button
            variant="outlined"
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: "white !important",
            }}
            disabled={
              singleLead?.Status === "Won" || singleLead?.Status === "Lost"
            }
            onClick={() => {
              navigate("/leads/create", { state: { singleLead: singleLead } });
            }}
          >
            Edit
          </Button>
        )}
      </Box>

      {status1 === "loading" || loading ? (
        <Loader />
      ) : (
        <Box className={LeadViewContainer}>
          <Box className={LeftLeadView}>
            <Box>
              <Box className={DetailsTypoBox}>
                <Box>
                  <Typography sx={{ fontWeight: "bold", color: "#546e7a" }}>
                    Details{" "}
                  </Typography>
                </Box>
                <Box
                  className={errorBox}
                  sx={{ backgroundColor: RottenDays?.LightColor }}
                >
                  <NewReleasesIcon
                    fontSize="small"
                    sx={{ fontSize: "14px", color: RottenDays?.DarkColor }}
                  />
                  <Typography
                    sx={{ color: RottenDays?.DarkColor, fontSize: "14px" }}
                  >
                    {RottenDays?.Message}
                  </Typography>
                </Box>
              </Box>
              <Paper elevation={3} sx={{ marginTop: "10px" }}>
                <Box className={DetailsPaperBox}>
                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "170px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>Title</Typography>
                    </Box>
                    <Box>
                      <Typography>{singleLead?.Title}</Typography>
                    </Box>
                  </Box>
                  <Divider />

                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "170px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Entry Date
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {singleLead?.CreatedAt
                          ? moment(singleLead?.CreatedAt).format("DD-MMM-YYYY")
                          : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />

                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "170px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Lead Value
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {singleLead?.LeadValue
                          ? Math.round(singleLead?.LeadValue)?.toLocaleString()
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />

                  {singleLead?.PipelineStage?.Name == "Won" &&
                    singleLead.WonValue && (
                      <>
                        <Box className={paperSubBox}>
                          <Box sx={{ width: "100%", maxWidth: "170px" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Won Value
                            </Typography>
                          </Box>
                          <Box>
                            <Typography>
                              {singleLead?.WonValue?.toLocaleString()}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                      </>
                    )}

                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "170px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Source
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{singleLead?.LeadSource?.Name}</Typography>
                    </Box>
                  </Box>
                  <Divider />

                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "170px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>Type</Typography>
                    </Box>
                    <Box>
                      <Typography>{singleLead?.LeadTypeName}</Typography>
                    </Box>
                  </Box>
                  <Divider />

                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "170px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Sales Owner
                      </Typography>
                    </Box>
                    <Box>
                      <div
                        style={{
                          display: "flex",
                          overflowX: "auto",
                          marginRight: "5px",
                        }}
                      >
                        {singleLead?.FullName && (
                          <Chip
                            size="small"
                            label={singleLead?.FullName}
                            sx={{
                              margin: "1px",
                            }}
                          />
                        )}

                        {singleLead?.LeadUsers?.map((proj, idx) => (
                          <Chip
                            key={idx}
                            size="small"
                            label={proj?.FullName}
                            sx={{
                              margin: "1px",
                            }}
                          />
                        ))}
                      </div>
                    </Box>
                  </Box>
                  <Divider />

                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "170px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Expected Close Date
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {singleLead?.ExpectedCloseDate
                          ? moment(singleLead?.ExpectedCloseDate).format(
                              "DD-MMM-YYYY"
                            )
                          : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />

                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "170px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Description
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height:
                          singleLead?.Description?.length > 170
                            ? "140px"
                            : "100%",
                        overflowY: "auto",
                      }}
                    >
                      <Typography>{singleLead?.Description}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Box>
              <Box sx={{ marginTop: "10px" }} className={DetailsTypoBox}>
                <Box>
                  <Typography sx={{ fontWeight: "bold", color: "#546e7a" }}>
                    Contact Person{" "}
                  </Typography>
                </Box>
              </Box>
              <Paper elevation={3} sx={{ marginTop: "10px" }}>
                <Box className={DetailsPaperBox}>
                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "120px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>Name</Typography>
                    </Box>
                    <Box>
                      <Typography
                        onClick={() => {
                          navigate("/contacts/persons/createPerson", {
                            state: { PersonId: singleLead?.PersonId },
                          });
                        }}
                        sx={{ color: "#0e90d9", cursor: "pointer" }}
                      >
                        {singleLead?.PersonName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "120px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>Email</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {singleLead?.PersonEmail?.map((mail, i) => (
                        <Box
                          key={i}
                          className={multiDataBox}
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          <Typography sx={{ fontSize: "15px" }}>
                            {mail?.Email}{" "}
                          </Typography>{" "}
                          <Typography sx={{ fontSize: "15px" }}>
                            ({mail?.Description})
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "120px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Contact Number
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {singleLead?.PersonContacts?.map((contct, i) => (
                        <Box
                          key={i}
                          className={multiDataBox}
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          <Typography sx={{ fontSize: "15px" }}>
                            +{contct?.ContactNumber}{" "}
                          </Typography>{" "}
                          <Typography sx={{ fontSize: "15px" }}>
                            ({contct?.Description})
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box className={paperSubBox}>
                    <Box sx={{ width: "100%", maxWidth: "120px" }}>
                      <Typography sx={{ fontWeight: "bold" }}>City</Typography>
                    </Box>
                    <Box>
                      <Typography>{singleLead?.PersonCity}</Typography>
                    </Box>
                  </Box>
                  {singleLead?.Organization?.Name ? (
                    <Box className={paperSubBox}>
                      <Box sx={{ width: "100%", maxWidth: "120px" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Organization
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          onClick={() => {
                            navigate("/contacts/organizations/create", {
                              state: {
                                OrganizationId: singleLead?.Organization?.Id,
                              },
                            });
                          }}
                          sx={{ color: "#0e90d9", cursor: "pointer" }}
                        >
                          {" "}
                          {singleLead?.Organization?.Name}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            </Box>
            {singleLead?.Affiliate && (
              <Box>
                <Box sx={{ marginTop: "10px" }} className={DetailsTypoBox}>
                  <Box>
                    <Typography sx={{ fontWeight: "bold", color: "#546e7a" }}>
                      Affiliate
                    </Typography>
                  </Box>
                </Box>
                <Paper elevation={3} sx={{ marginTop: "10px" }}>
                  <Box className={DetailsPaperBox}>
                    <Box className={paperSubBox}>
                      <Box sx={{ width: "100%", maxWidth: "120px" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Name
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          onClick={() => {
                            navigate("/contacts/affiliate/createAffiliate", {
                              state: { PersonId: singleLead?.AffiliateId },
                            });
                          }}
                          sx={{ color: "#0e90d9", cursor: "pointer" }}
                        >
                          {singleLead?.Affiliate?.AffiliateName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={paperSubBox}>
                      <Box sx={{ width: "100%", maxWidth: "120px" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Email
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {singleLead?.Affiliate?.AffiliateEmail?.map(
                          (mail, i) => (
                            <Box
                              key={i}
                              className={multiDataBox}
                              sx={{ display: "flex", gap: "10px" }}
                            >
                              <Typography sx={{ fontSize: "15px" }}>
                                {mail?.Email}{" "}
                              </Typography>{" "}
                              <Typography sx={{ fontSize: "15px" }}>
                                ({mail?.Description})
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                    <Box className={paperSubBox}>
                      <Box sx={{ width: "100%", maxWidth: "120px" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Contact Number
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {singleLead?.Affiliate?.AffiliateContacts?.map(
                          (contct, i) => (
                            <Box
                              key={i}
                              className={multiDataBox}
                              sx={{ display: "flex", gap: "10px" }}
                            >
                              <Typography sx={{ fontSize: "15px" }}>
                                {contct?.ContactNumber}{" "}
                              </Typography>{" "}
                              <Typography sx={{ fontSize: "15px" }}>
                                ({contct?.Description})
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                    <Box className={paperSubBox}>
                      <Box sx={{ width: "100%", maxWidth: "120px" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          City
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          {singleLead?.Affiliate?.AffiliateCity}
                        </Typography>
                      </Box>
                    </Box>
                    {singleLead?.Organization?.Name ? (
                      <Box className={paperSubBox}>
                        <Box sx={{ width: "100%", maxWidth: "120px" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Organization
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            onClick={() => {
                              navigate("/contacts/organizations/create", {
                                state: {
                                  OrganizationId: singleLead?.Organization?.Id,
                                },
                              });
                            }}
                            sx={{ color: "#0e90d9", cursor: "pointer" }}
                          >
                            {" "}
                            {singleLead?.Organization?.Name}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                </Paper>
              </Box>
            )}
            <Box>
              {Items?.length > 0 ? (
                <Box sx={{ marginTop: "10px" }} className={DetailsTypoBox}>
                  <Box>
                    <Typography sx={{ fontWeight: "bold", color: "#546e7a" }}>
                      Items {/* Replace from Products to Items */}
                    </Typography>
                  </Box>
                </Box>
              ) : null}
              <Paper elevation={3} sx={{ marginTop: "10px" }}>
                {Items?.map((prod, i) => {
                  return (
                    <Box className={DetailsPaperBox} key={i}>
                      <Typography sx={{ fontWeight: "bold" }}>Item</Typography>
                      <TextField
                        variant="outlined"
                        placeholder="Name"
                        value={prod?.Name}
                        fullWidth
                        size="small"
                        disabled={
                          roles?.some((role) => role === "Leads_Edit")
                            ? false
                            : true
                        }
                      />

                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Box>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Price
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={prod?.Price.toLocaleString()}
                            fullWidth
                            size="small"
                            disabled={
                              roles?.some((role) => role === "Leads_Edit")
                                ? false
                                : true
                            }
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Quantity
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={prod?.Quantity}
                            fullWidth
                            size="small"
                            disabled={
                              roles?.some((role) => role === "Leads_Edit")
                                ? false
                                : true
                            }
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Amount
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={prod?.Amount.toLocaleString()}
                            fullWidth
                            size="small"
                            disabled={
                              roles?.some((role) => role === "Leads_Edit")
                                ? false
                                : true
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Paper>
              {Services?.length > 0 ? (
                <Box sx={{ marginTop: "10px" }} className={DetailsTypoBox}>
                  <Box>
                    <Typography sx={{ fontWeight: "bold", color: "#546e7a" }}>
                      Services {/* Replace from Products to Items */}
                    </Typography>
                  </Box>
                </Box>
              ) : null}
              <Paper elevation={3} sx={{ marginTop: "10px" }}>
                {Services?.map((prod, i) => {
                  return (
                    <Box className={DetailsPaperBox} key={i}>
                      <Typography sx={{ fontWeight: "bold" }}>Item</Typography>
                      <TextField
                        variant="outlined"
                        placeholder="Name"
                        value={prod?.Name}
                        fullWidth
                        size="small"
                        disabled={
                          roles?.some((role) => role === "Leads_Edit")
                            ? false
                            : true
                        }
                      />
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Box>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Price
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={prod?.Price.toLocaleString()}
                            fullWidth
                            size="small"
                            disabled={
                              roles?.some((role) => role === "Leads_Edit")
                                ? false
                                : true
                            }
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Quantity
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={prod?.Quantity}
                            fullWidth
                            size="small"
                            disabled={
                              roles?.some((role) => role === "Leads_Edit")
                                ? false
                                : true
                            }
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Amount
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={prod?.Amount.toLocaleString()}
                            fullWidth
                            size="small"
                            disabled={
                              roles?.some((role) => role === "Leads_Edit")
                                ? false
                                : true
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Paper>
            </Box>

            {sessionStorage.getItem("BusinessType") !== "General" &&
            singleLead?.LeadUnits?.length ? (
              <Box>
                <Box sx={{ marginTop: "10px" }} className={DetailsTypoBox}>
                  <Box>
                    <Typography sx={{ fontWeight: "bold", color: "#546e7a" }}>
                      Plot Details
                    </Typography>
                  </Box>
                </Box>
                <Paper
                  elevation={3}
                  sx={{
                    marginBlock: "10px",
                    maxHeight: "400px",
                    overflowY: "auto",
                  }}
                >
                  <Box className={plotDetailsMainContainer}>
                    {singleLead?.LeadUnits?.map((unit, idx) => (
                      <Box key={idx} className={plotDetailsSingleCard}>
                        <Typography textAlign="left" className={plotCount}>
                          {/* <Divider>({idx + 1})</Divider> */}
                        </Typography>
                        <Box className={plotDetailsCard}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Typography className={plotDetailsHeading}>
                                Project
                              </Typography>
                              <Typography className={plotDetailsValue}>
                                {unit?.Project?.ProjectName || ""}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography className={plotDetailsHeading}>
                                Category
                              </Typography>
                              <Typography className={plotDetailsValue}>
                                {unit?.Category?.CategoryName || ""}
                              </Typography>
                            </Grid>

                            {unit?.Section && (
                              <Grid item xs={12} sm={4}>
                                <Typography className={plotDetailsHeading}>
                                  Section
                                </Typography>
                                <Typography className={plotDetailsValue}>
                                  {unit?.Section?.SectionName || ""}
                                </Typography>
                              </Grid>
                            )}

                            {unit?.Unit && (
                              <Grid item xs={12} sm={4}>
                                <Typography className={plotDetailsHeading}>
                                  Unit
                                </Typography>
                                <Typography className={plotDetailsValue}>
                                  {unit?.Unit?.UnitName || ""}
                                </Typography>
                                <Box display="flex" marginRight={1}>
                                  {unit?.UnitTags?.map((tag, idx) => (
                                    <div
                                      key={idx}
                                      style={{
                                        overflowX: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Chip
                                        size="small"
                                        label={tag?.Tag?.Name}
                                        sx={{
                                          margin: "1px",
                                          backgroundColor: tag?.Tag?.Color,
                                          color: "#fff",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Box>
                              </Grid>
                            )}
                            {unit?.Status && (
                              <Grid item xs={12} sm={4}>
                                <Typography className={plotDetailsHeading}>
                                  Unit Status
                                </Typography>
                                <Typography className={plotDetailsValue}>
                                  {unit?.Status || ""}
                                </Typography>
                              </Grid>
                            )}
                            {unit?.Unit?.Marla && (
                              <Grid item xs={12} sm={4}>
                                <Typography className={plotDetailsHeading}>
                                  Area
                                </Typography>
                                <Typography className={plotDetailsValue}>
                                  {unit?.Unit?.Marla +
                                    "M" +
                                    unit?.Unit?.Sarsai +
                                    "S"}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </Box>
            ) : null}
          </Box>

          <Box className={RightLeadView}>
            <PiplinesStages singleLead={singleLead} roles={roles} />
            <Box
              sx={{
                width: "100%",
                display:
                  singleLead?.Status === "Won" || singleLead?.Status === "Lost"
                    ? "none"
                    : "",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{ fontWeight: "bold" }}
                  label="Note"
                  {...a11yProps(0)}
                  disabled={
                    roles?.some((role) => role === "Leads_Edit") ? false : true
                  }
                />
                <Tab
                  sx={{ fontWeight: "bold" }}
                  label="Activity"
                  {...a11yProps(1)}
                  disabled={
                    roles?.some((role) => role === "Leads_Edit") ? false : true
                  }
                />
                {/* <Tab
                  sx={{ fontWeight: "bold" }}
                  label="Email"
                  {...a11yProps(2)}
                  disabled={
                    roles?.some((role) => role === "Leads_Edit") ? false : true
                  }
                /> */}
                <Tab
                  sx={{ fontWeight: "bold" }}
                  label="File"
                  {...a11yProps(3)}
                  disabled={
                    roles?.some((role) => role === "Leads_Edit") ? false : true
                  }
                />
                <Tab
                  {...a11yProps(4)}
                  label="quote"
                  disabled={
                    roles?.some((role) => role === "Leads_Edit") ? false : true
                  }
                  sx={{
                    fontWeight: "bold",
                    display:
                      sessionStorage.getItem("BusinessType") === "General"
                        ? "inline-flex"
                        : "none",
                  }}
                />
              </Tabs>
              {value === 0 ? (
                <Note state={state?.state} roles={roles} />
              ) : value === 1 ? (
                <Activity leadView state={state?.state} leadData={singleLead} />
              ) : //  value === 2 ? (
              //   <LeadEmail state={state?.state} />
              // ) :
              value === 2 ? (
                <File state={state?.state} />
              ) : value === 3 ? (
                <Quote state={state?.state} singleLead={singleLead} />
              ) : null}
            </Box>

            <RightBottomTabs singleLead={singleLead} roles={roles} />
          </Box>
        </Box>
      )}
      {/* <Dialog
        open={showModal}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete <b>{tags?.Name}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                settags(null);
                handleClosed();
              }}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  deleteTags();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}

      <AppConfirmationDialog
        open={showModal}
        onDeny={() => {
          settags(null);
          handleClosed();
        }}
        onConfirm={() => {
          deleteTags();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Tag"
        title={`Are you sure, you want to Delete "${tags?.Name}"?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default LeadView;
LeadView.propTypes = {
  roles: PropTypes.array,
};

const useStyles = makeStyles(() => {
  return {
    // root: {
    //   '&:hover': {
    //     backgroundColor: 'transparent',
    //   },
    // },
    LeadViewContainer: {
      width: "100%",
      display: "flex",
      gap: "20px",
      [theme.breakpoints.down("lg")]: {
        flexWrap: "wrap",
      },
    },
    LeftLeadView: {
      width: "35%",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    RightLeadView: {
      width: "65%",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    DetailsTypoBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    DetailsPaperBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "20px",
      // marginTop:"20px"
    },
    paperSubBox: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
    },
    errorBox: {
      // backgroundColor: "#ffd0d6",
      padding: "3px",
      display: "flex",
      alignItems: "center",
      gap: "7px",
      paddingX: "10px",
    },
    multiDataBox: {
      backgroundColor: "#eff0f5",
      padding: "5px",
      borderRadius: "15px",
    },
    addTags: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: "7px",
      "&:hover": { backgroundColor: "#0e90d9 !important", cursor: "pointer" },
    },
    plotDetailsMainContainer: {
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    plotDetailsSingleCard: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    plotCount: {
      textAlign: "center",
      color: "#999",
      fontSize: "14px !important",
      fontWeight: "bold !important",
    },
    plotDetailsCard: {
      padding: "10px",
      borderRadius: "8px",
      backgroundColor: "#eff0f5",
    },
    plotDetailsHeading: { fontWeight: "bold  !important" },
    plotDetailsValue: { textTransform: "capitalize" },
  };
});
