import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  Roles: [],
  ParentRoles: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchRoles = createAsyncThunk("/Roles", async () => {
  const response = await axiosInstance.get(`/Roles`);
  console.log(response);
  return response;
});
export const fetchParentRoles = createAsyncThunk(
  "/Screens/ParentPopulate",
  async () => {
    const response = await axiosInstance.get(`/Screens/ParentPopulate`);
    console.log(response);
    return response;
  }
);

const RolesSlice = createSlice({
  name: "Roles",
  initialState,
  reducers: {
    RolesAdded: {
      reducer(state, action) {
        state.Roles?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Roles = action.payload.data.Data;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchParentRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchParentRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ParentRoles = action.payload.data.Data;
      })
      .addCase(fetchParentRoles.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getRolesDetails = (state) => state.Roles;

export const { RolesAdded } = RolesSlice.actions;

export default RolesSlice.reducer;
