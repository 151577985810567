import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  Screens: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchScreens = createAsyncThunk("/Screens", async () => {
  const response = await axiosInstance.get(`/Screens`);
  return response;
});

const ScreensSlice = createSlice({
  name: "Screens",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchScreens.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchScreens.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.data.Message;
        state.isSuccess = action.payload.data.IsSuccess;
        state.Screens = action.payload.data.Data;
      })
      .addCase(fetchScreens.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getScreensDetails = (state) => state.Screens;
export default ScreensSlice.reducer;
