import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  // Tooltip,
  // Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  fetchSingleTemplate,
  getTemplateDetails,
} from "../../../Redux/Slice/TemplateSlice/TemplateSlice";
import {
  fetchCampaignPopulate,
  getCampaignsDetails,
} from "../../../Redux/Slice/CampaignsSlice/CampaignsSlice";
import Loader from "../../Loader/Loader";
import ArticleIcon from "@mui/icons-material/Article";
import { CreateCampaignApi } from "../../../Api/CampaignsApi/CreateCampaignApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import CampaignGroupDetailMod from "./CampaignGroupDetailMod";
// import Cookies from "universal-cookie";
// import VisibilityIcon from "@mui/icons-material/Visibility";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    textAreaContainer: {
      display: "flex",
      //   justifyContent: "start",
      //   alignItems: "start",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
  };
});
const ValidationSchema = Yup.object().shape({
  CampaignTitle: Yup.string().required("Please Enter Title"),
  CampaignType: Yup.string().required("Please Select Type"),
  ReferenceType: Yup.string().required("Please Enter Reference Type"),
  ReferenceId: Yup.object().nullable().required("Please Enter Refrence"),
  CampaignGroupId: Yup.object()
    .nullable()
    .required("Please Enter Campaign Group"),
  TemplateId: Yup.object().nullable().required("Please Enter Template"),
});
function CreateCampaigns() {
  const {
    container,
    subContainer,
    formContainer,
    textContainer,
    textAreaContainer,
  } = useStyle();
  // const cookies = new Cookies();

  const { setsnackBarData } = useContext(SnackBarContext);
  const { SingleTemplate } = useSelector(getTemplateDetails);
  const { CampaignPopulate, status } = useSelector(getCampaignsDetails);
  const [allDocument, setallDocument] = useState([]);
  const [allCampaignGroup, setallCampaignGroup] = useState([]);
  const [allTemplete, setallTemplete] = useState([]);
  const [Placeholders, setPlaceholders] = useState([]);
  const [logo, setlogo] = useState(null);
  const [bodyText, setbodyText] = useState(null);
  const [selectedDocument, setselectedDocument] = useState(null);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [errors, seterrors] = useState(null);
  const [upload, setupload] = useState("Upload");
  const handleClosed2 = () => {
    setShowModal2(false);
  };
  const handleClosed = () => {
    setShowModal(false);
  };
  const dispatch = useDispatch();

  const initialValues = {
    CampaignTitle: "",
    CampaignType: "Email",
    ReferenceType:
      sessionStorage.getItem("BusinessType") === "General"
        ? "Service"
        : "Project",
    ReferenceId: null,
    CampaignGroupId: null,
    TemplateId: null,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  console.log(setallDocument);
  console.log(CampaignPopulate);
  console.log(formik.values.TemplateId);
  useEffect(() => {
    if (formik.values?.TemplateId?.Type === "Text") {
      setlogo(null);
    }
  }, [formik.values?.TemplateId]);
  useEffect(() => {
    dispatch(fetchCampaignPopulate());
  }, [dispatch]);
  useEffect(() => {
    if (CampaignPopulate) {
      if (formik.values?.ReferenceType === "Service") {
        setallDocument(CampaignPopulate?.services);
      } else if (formik.values?.ReferenceType === "Product") {
        setallDocument(CampaignPopulate?.products);
      } else {
        setallDocument(CampaignPopulate?.projects);
      }
      let arr = [];
      let arr2 = [];
      CampaignPopulate?.campaignGroups?.map((item) => {
        if (item?.Type === "Email") {
          arr.push(item);
        } else {
          arr2.push(item);
        }
      });
      if (formik.values?.CampaignType === "Email") {
        setallCampaignGroup(arr);
      } else {
        setallCampaignGroup(arr2);
      }
      setallTemplete(CampaignPopulate?.templates);
    }
  }, [
    CampaignPopulate,
    formik.values?.ReferenceType,
    formik.values?.CampaignType,
  ]);

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.ID) {
      dispatch(fetchSingleTemplate(state?.ID));
    }
  }, [state]);
  useEffect(() => {
    if (state?.ID) {
      formik?.setFieldValue("title", SingleTemplate?.Title);
    }
    // else if (state) {
    //   handleEdit(state);
    // }
  }, [state, SingleTemplate]);
  //   useEffect(() => {
  // if(errors){
  //   Placeholders?.map((item,i)=>{
  //     if (Placeholders[i].values) {
  //       return seterrors({ ...errors, [`values${i}`]: false });
  //     }
  //   });
  // }
  //   }, [errors]);
  useEffect(() => {
    if (bodyText) {
      console.log(bodyText);
      let updatedString = bodyText;
      console.log(Placeholders);
      console.log(updatedString);

      Placeholders?.map((search) => {
        if (search?.values) {
          updatedString = updatedString.replace(
            new RegExp(search?.Key, "g"),
            search?.values
          );
          console.log(updatedString);
        } else {
          updatedString = updatedString.replace(
            new RegExp(search?.Key, "g"),
            `{ ${search?.Title} }`
          );
        }
      });
      console.log(updatedString);

      formik.setFieldValue("TemplateId", {
        ...formik?.values?.TemplateId,
        Body: updatedString,
      });
      console.log(formik?.values?.TemplateId);
    }
  }, [Placeholders]);
  console.log(errors);
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let ValueError = false;

    Placeholders?.map((item, i) => {
      if (!Placeholders[i].values || Placeholders[i].values === "") {
        ValueError = true;
        return seterrors({ ...errors, [`values${i}`]: true });
      }
    });
    console.log(values);
    if (ValueError) {
      setSubmitting(false);
      return;
    } else {
      const formData = new FormData();
      formData.append("CampaignTitle", values?.CampaignTitle);
      formData.append("CampaignType", values?.CampaignType);
      formData.append("ReferenceType", values?.ReferenceType);
      formData.append("ReferenceId", values?.ReferenceId?.Id);
      formData.append(
        "CampaignGroupId",
        values?.CampaignGroupId?.CompaignGroupId
      );
      formData.append("TemplateId", values?.TemplateId?.TemplateId);
      formData.append("Message", values?.TemplateId?.Body);
      if (upload == "Choose from Drive") {
        formData.append(
          "FileName",
          selectedDocument?.FileName ? selectedDocument?.FileName : null
        );
      }
      formData.append("File", logo?.file ? logo?.file : null);
      const response = await CreateCampaignApi(formData);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        resetForm();
        setlogo(null);
        setSubmitting(false);
        navigate(-1);
        // dispatch(fetchDocuments({ type: Type, id: selectedRow?.ID }));
      }
      setSubmitting(false);
    }
  };
  const handleNavigate = () => {
    navigate(-1);
  };

  const handleLogo = (e) => {
    setlogo({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  return (
    <>
      {status === "loading" && <Loader />}
      <Box className={container}>
        <Box className={subContainer}>
          <Box>
            <Box
              role="presentation"
              onClick={(e) => e.preventDefault()}
            >
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ fontSize: "12px" }}
              >
                <Link
                  to="/dashboard"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Dashboard
                </Link>
                <Link
                  to="/campaigns/campaign"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Campaigns
                </Link>
                <Typography
                  color="text.primary"
                  sx={{ fontSize: "12px" }}
                >
                  {state ? "Update Campaigns" : "Create Campaigns"}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography
              variant="h5"
              sx={{ textAlign: "left", fontWeight: 400 }}
            >
              {state ? "Update Campaigns" : "Create Campaigns"}
            </Typography>
          </Box>
          <Paper
            elevation={3}
            sx={{ padding: "20px" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box className={formContainer}>
                <FormControl fullWidth>
                  <Typography>Title</Typography>
                  <TextField
                    name="CampaignTitle"
                    value={formik.values?.CampaignTitle}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.CampaignTitle) &&
                      Boolean(formik.touched.CampaignTitle)
                    }
                    helperText={
                      Boolean(formik.errors.CampaignTitle) &&
                      Boolean(formik.touched.CampaignTitle) &&
                      formik.errors.CampaignTitle
                    }
                  />
                </FormControl>

                {formik.values?.ReferenceType !== "Project" && (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue="Upload"
                      name="ReferenceType"
                      value={formik.values?.ReferenceType}
                      onChange={
                        (e) => {
                          formik.setFieldValue("ReferenceType", e.target.value);
                          formik.setFieldValue("ReferenceId", null);
                        }
                        // formik.handleChange
                      }
                      // onChange={
                      //   formik.handleChange

                      // }
                      // name="radio-buttons-group"
                      row
                    >
                      <FormControlLabel
                        value="Service"
                        control={<Radio />}
                        label="Service"
                      />
                      <FormControlLabel
                        value="Product"
                        control={<Radio />}
                        label="Product"
                      />
                    </RadioGroup>
                  </FormControl>
                )}

                <FormControl fullWidth>
                  <Typography>{formik.values?.ReferenceType}</Typography>
                  <Autocomplete
                    name="ReferenceId"
                    //   options={["hjehje"]}
                    options={allDocument}
                    getOptionLabel={(option) => option.Name}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          Boolean(formik.errors.ReferenceId) &&
                          Boolean(formik.touched.ReferenceId)
                        }
                        helperText={
                          Boolean(formik.errors.ReferenceId) &&
                          Boolean(formik.touched.ReferenceId) &&
                          formik.errors.ReferenceId
                        }
                        //   name="personId"
                      />
                    )}
                    value={formik.values.ReferenceId}
                    onChange={(__, value) => {
                      formik.setFieldValue("ReferenceId", value);
                      // formik.values?.date=e.target.value
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Type</Typography>
                  <TextField
                    name="CampaignType"
                    select
                    value={formik.values?.CampaignType}
                    size={"small"}
                    fullWidth
                    onChange={
                      (e) => {
                        formik.setFieldValue("CampaignType", e.target.value);
                        formik.setFieldValue("CampaignGroupId", null);
                      }
                      // formik.handleChange
                    }
                    error={
                      Boolean(formik.errors.CampaignType) &&
                      Boolean(formik.touched.CampaignType)
                    }
                    helperText={
                      Boolean(formik.errors.CampaignType) &&
                      Boolean(formik.touched.CampaignType) &&
                      formik.errors.CampaignType
                    }
                  >
                    <MenuItem value="Email">Email</MenuItem>
                    <MenuItem value="SMS">SMS</MenuItem>
                  </TextField>
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Campaigns Group</Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Autocomplete
                      name="CampaignGroupId"
                      //   options={["hjehje"]}
                      options={allCampaignGroup}
                      getOptionLabel={(option) => option.Name}
                      size={"small"}
                      sx={{ width: { md: "50%", xs: "100%" } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          //   name="personId"
                          error={
                            Boolean(formik.errors.CampaignGroupId) &&
                            Boolean(formik.touched.CampaignGroupId)
                          }
                          helperText={
                            Boolean(formik.errors.CampaignGroupId) &&
                            Boolean(formik.touched.CampaignGroupId) &&
                            formik.errors.CampaignGroupId
                          }
                        />
                      )}
                      value={formik.values?.CampaignGroupId}
                      onChange={(__, value) => {
                        formik.setFieldValue("CampaignGroupId", value);

                        // setselectedDocument(value);
                        // formik.values?.date=e.target.value
                      }}
                    />
                    <Box fullWidth>
                      <Button
                        disabled={!formik.values?.CampaignGroupId}
                        onClick={() => {
                          setShowModal2(true);
                          setselectedGroup(formik.values?.CampaignGroupId);
                        }}
                      >
                        Audience
                        <IconButton>
                          <VisibilityIcon
                            sx={{
                              color: (theme) => theme?.palette?.primary?.main,
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Button>
                    </Box>
                    {/* <Tooltip title="View Audience">
                      <IconButton
                     
                      >
                        <VisibilityIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.main,
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip> */}
                  </Box>
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Templete</Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Autocomplete
                      name="TemplateId"
                      //   options={["hjehje"]}
                      options={allTemplete}
                      getOptionLabel={(option) => option.Title}
                      size={"small"}
                      sx={{ width: { md: "50%", xs: "100%" } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          //   name="personId"
                          error={
                            Boolean(formik.errors.TemplateId) &&
                            Boolean(formik.touched.TemplateId)
                          }
                          helperText={
                            Boolean(formik.errors.TemplateId) &&
                            Boolean(formik.touched.TemplateId) &&
                            formik.errors.TemplateId
                          }
                        />
                      )}
                      value={formik.values?.TemplateId}
                      onChange={(__, value) => {
                        formik.setFieldValue("TemplateId", value);
                        setPlaceholders(value?.Placeholders);
                        setbodyText(value?.Body);
                        // setselectedDocument(value);
                        // formik.values?.date=e.target.value
                      }}
                    />
                    <Box fullWidth>
                      <Button
                        disabled={!formik.values?.TemplateId}
                        onClick={() => {
                          setShowModal(true);

                          // dispatch(fetchproduct(celval.row?.ID));
                        }}
                      >
                        Message
                        <IconButton>
                          <VisibilityIcon
                            sx={{
                              color: (theme) => theme?.palette?.primary?.main,
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Button>
                    </Box>
                  </Box>
                </FormControl>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  {Placeholders?.map((item, i) => {
                    return (
                      <>
                        <FormControl
                          key={i}
                          fullWidth
                        >
                          <Typography
                            component={"span"}
                            sx={{ display: "flex" }}
                          >
                            {item?.Title}
                            <Typography sx={{ color: "red" }}>*</Typography>
                          </Typography>
                          <TextField
                            variant="standard"
                            sx={{ width: "50%" }}
                            // value={item?.Key}
                            size={"small"}
                            fullWidth
                            onChange={
                              (e) => {
                                let value = JSON.parse(
                                  JSON.stringify(Placeholders)
                                );
                                value[i].values = e.target.value;
                                setPlaceholders(value);
                              }
                              // formik.handleChange
                            }
                          />
                        </FormControl>
                        {errors && errors[`values${i}`] ? (
                          <Typography
                            color={"error"}
                            sx={{ fontSize: "12px" }}
                          >
                            Please Enter Value
                          </Typography>
                        ) : null}
                      </>
                    );
                  })}
                </Box>

                {formik.values?.TemplateId?.Type === "Text" ||
                formik.values?.TemplateId == null ? null : (
                  <>
                    {formik.values?.ReferenceId?.Documents?.length > 0 ? (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          // defaultValue="Upload"
                          value={upload}
                          onChange={(e) => {
                            setupload(e.target.value);
                            setselectedDocument(null);
                            if (e.target.value === "Upload") {
                              console.log("Upload");
                              // setselectedDocument(null);
                            } else if (e.target.value === "Choose from Drive") {
                              setlogo(null);
                            }
                          }}
                          name="radio-buttons-group"
                          row
                        >
                          <FormControlLabel
                            value="Upload"
                            control={<Radio />}
                            label="Upload"
                          />
                          <FormControlLabel
                            value="Choose from Drive"
                            control={<Radio />}
                            label={`${formik.values?.ReferenceType} Documents`}
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}
                    {upload === "Upload" ||
                    formik.values?.ReferenceId?.Documents?.length <= 0 ? (
                      <FormControl fullWidth>
                        <Typography>File</Typography>
                        <TextField
                          sx={{ textAlign: "left", input: { color: "black" } }}
                          fullWidth
                          size="small"
                          name="FileUrl"
                          type="file"
                          InputProps={{
                            inputProps: {
                              accept:
                                formik.values?.TemplateId?.Type === "Image"
                                  ? "image/*"
                                  : ".pdf, .doc, .docx",
                            },
                          }}
                          // accept={formik.values?.Type==="Image"?"image/*":".pdf, .doc, .docx"}
                          onChange={handleLogo}
                          variant="outlined"
                        />
                      </FormControl>
                    ) : (
                      <FormControl fullWidth>
                        <Typography>
                          {`${formik.values?.ReferenceType} Documents`}
                        </Typography>
                        <Autocomplete
                          name="organizationId"
                          //   options={["hjehje"]}
                          options={formik.values?.ReferenceId?.Documents || []}
                          getOptionLabel={(option) => option?.DocumentTitle}
                          size={"small"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              //   name="personId"
                            />
                          )}
                          value={selectedDocument}
                          onChange={(__, value) => {
                            setselectedDocument(value);
                            // formik.values?.date=e.target.value
                          }}
                        />
                      </FormControl>
                    )}
                  </>
                )}
                {logo && formik.values?.TemplateId?.Type == "Image" ? (
                  <Box
                    sx={{
                      height: "70px",
                      width: "70px",
                      borderRadius: "5px",
                      border: "1px solid rgb(214, 214, 214)",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer !important",
                      justifyContent: "center",
                      position: "relative",
                      padding: "10px",
                    }}
                  >
                    <img
                      src={logo?.url}
                      alt="Image"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                ) : formik.values?.TemplateId?.Type == "Document" && logo ? (
                  <Box
                    sx={{
                      height: "70px",
                      width: "70px",
                      borderRadius: "5px",
                      border: "1px solid rgb(214, 214, 214)",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer !important",
                      justifyContent: "center",
                      position: "relative",
                      padding: "10px",
                    }}
                  >
                    <ArticleIcon
                      fontSize="small"
                      color="primary"
                      sx={{
                        fontSize: "36px",
                      }}
                    />
                  </Box>
                ) : null}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <Button onClick={handleNavigate}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Back
                        </Typography>
                      </Button>
                    </Box>
                    <Box>
                      {formik.isSubmitting ? (
                        <Box>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          variant="contained"
                          type="submit"
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            {state ? "Update" : "Save"}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showModal}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              mt={1}
              variant="h6"
              fontWeight="bolder"
            >
              Message Info
            </Typography>
            <Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleClosed();
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Typography>
          </Box>
          <Divider />
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <Box className={textContainer}>
            <Box className={textAreaContainer}>
              <Typography
                variant="body1"
                fontWeight="bold"
              >
                Title:
              </Typography>
              <Typography>{formik.values?.TemplateId?.Title}</Typography>
            </Box>
            <Box className={textAreaContainer}>
              <Typography
                variant="body1"
                fontWeight="bold"
              >
                Type:
              </Typography>
              <Typography>{formik.values?.TemplateId?.Type}</Typography>
            </Box>
          </Box>
          <Typography
            variant="body1"
            fontWeight="bold"
          >
            Body:
          </Typography>
          <Box
            border="1px solid #bdbdbd"
            borderRadius="10px"
            padding="8px"
          >
            <Typography
              dangerouslySetInnerHTML={{
                __html: formik.values?.TemplateId?.Body,
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <CampaignGroupDetailMod
        showModal={showModal2}
        handleClosed={handleClosed2}
        group={selectedGroup}
      />
    </>
  );
}

export default CreateCampaigns;
