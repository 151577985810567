import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  Types: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchTypes = createAsyncThunk("/LeadType", async () => {
  const response = await axiosInstance.get(`/LeadType`);
  console.log(response);
  return response;
});

const TypesSlice = createSlice({
  name: "Types",
  initialState,
  reducers: {
    TypesAdded: {
      reducer(state, action) {
        state.Types?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Types = action.payload.data.Data;
      })
      .addCase(fetchTypes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getTypesDetails = (state) => state.Types;

export const { TypesAdded } = TypesSlice.actions;

export default TypesSlice.reducer;
