import "./App.css";
import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { AdminRoutes } from "./AllRoutes/AllRoutes";
import SnackBarContextProvider from "./Context/SnackBarContext/SnackBarContext";
import SnackBar from "./Components/SnackBar/SnackBar";
import { useDispatch } from "react-redux";
import { fetchUserRoles } from "./Redux/Slice/Settings/UserSlice/UserSlice";
// import Cookies from "universal-cookie";
import AppContextProvider from "./Context/TourContext/TourContext";
import FacebookPageDetailContextProvider from "./Context/FacebookPageDetailContext/FacebookPageDetailContext";
import { SignalRProvider } from "./Context/SignalRContext/SignalRContext";
import NotificationContextProvider from "./Context/NotificationContext/NotificationContext";
import PageNumberContextProvider from "./Context/PageNumberContext/PageNumberContext";
import SideBarContextProvider from "./Context/SideBarContext/SideBarContext";

// const cookies = new Cookies();
function App() {
  // const loginId = cookies?.get("LoginId");
  const loginId = sessionStorage.getItem("LoginId");
  const dispatch = useDispatch();

  useEffect(() => {
    if (loginId) {
      dispatch(fetchUserRoles(loginId));
    }
  }, [loginId]);

  // console.log(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          fontFamily: "Poppins !important",
          backgroundColor: (theme) => theme.palette.background.main,
        }}
        className="App"
      >
        <SnackBarContextProvider>
          <FacebookPageDetailContextProvider>
            <AppContextProvider>
              <SignalRProvider>
                <NotificationContextProvider>
                  <PageNumberContextProvider>
                    <SideBarContextProvider>
                      <Routes>
                        {AdminRoutes?.map((route, idx) => {
                          return (
                            <Route
                              key={idx}
                              exact
                              path={route.path}
                              element={route.component}
                            />
                          );
                        })}
                      </Routes>
                    </SideBarContextProvider>
                  </PageNumberContextProvider>
                </NotificationContextProvider>
              </SignalRProvider>

              <SnackBar />
            </AppContextProvider>
          </FacebookPageDetailContextProvider>
        </SnackBarContextProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
