import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../Utils/AxiosInstance";
import { revertAll } from "../../ResetStateAction";

const initialState = {
  AllSectionsCategoriesData: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchSectionsCategoriesData = createAsyncThunk(
  "/Sections/GetCategories",
  async () => {
    const response = await axiosInstance.get(`/Sections/GetCategories`);
    return response;
  }
);

const CategorySlice = createSlice({
  name: "CategorySlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchSectionsCategoriesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSectionsCategoriesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AllSectionsCategoriesData = action.payload.data.Data;
      })
      .addCase(fetchSectionsCategoriesData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.AllSectionsCategoriesData = [];
      });
  },
});

export const getSectionsCategories = (state) => state.SectionsCategories;
export default CategorySlice.reducer;
