import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { UpdateProductApi } from "../../../Api/ProductsApi/UpdateProductApi";
import { useDispatch } from "react-redux";
import { fetchProducts } from "../../../Redux/Slice/ProductSlice/ProductSlice";

function StockModal({ showModal, handleClosed, state1 }) {
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();

  const ValidationSchema = Yup.object().shape({
    quantity: Yup.string().required("Please Enter quantity"),
    price: Yup.string().required("Please Enter Price"),
    cost: Yup.string().required("Please Enter Cost"),
  });
  const initialValues = {
    name: "",
    description: "",
    sku: "",
    cost: "",
    quantity: "",
    price: "",
    productCat: "",
    productCode: "",
    uomValue: "",
    commissionPercentage: "",
    tax: false,
    active: false,
    image: null,
    imageName: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  console.log(formik.values);
  console.log(state1);
  useEffect(() => {
    if (state1) {
      handleEdit(state1);
    }
  }, [state1]);
  const handleEdit = (values) => {
    console.log("values", values);
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
    formik.handleChange({
      target: {
        name: "sku",
        value: values?.sku || "",
      },
    });
    formik.handleChange({
      target: {
        name: "quantity",
        value: values?.quantity || "",
      },
    });
    formik.handleChange({
      target: {
        name: "price",
        value: values?.price?.replace(/,/g, "") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "cost",
        value: values?.cost?.replace(/,/g, "") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "description",
        value: values?.Description || "",
      },
    });
    formik.handleChange({
      target: {
        name: "uomValue",
        value: { value: values?.Uom, label: values?.Uom } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "tax",
        value: values.Tax,
      },
    });
    formik.handleChange({
      target: {
        name: "productCode",
        value: values?.ProductCode || "",
      },
    });
    formik.handleChange({
      target: {
        name: "productCat",
        value:
          { value: values?.ProductCategory, label: values?.ProductCategory } ||
          "",
      },
    });
    formik.handleChange({
      target: {
        name: "commissionPercentage",
        value: values?.CommissionPercentage || "",
      },
    });
    formik.handleChange({
      target: {
        name: "active",
        value: values?.Active,
      },
    });
    formik.handleChange({
      target: {
        name: "imageName",
        value: values?.ImageName || "",
      },
    });
  };
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    // const data = {
    //   ...values,
    //   uom: values.uomValue?.label,
    //   productCategory: values.productCat?.label,
    // };
    console.log("values555", values);
    let formData = new FormData();
    formData.append("Name", values.name);
    formData.append("Sku", values.sku);
    formData.append("ImageName", values.imageName);
    formData.append("Image", values.image);
    formData.append("Cost", values.cost);
    formData.append("Price", values.price);
    formData.append("CommissionPercentage", values.commissionPercentage);
    formData.append("Uom", values.uomValue?.label);
    formData.append("ProductCode", values.productCode);
    formData.append("ProductCategory", values.productCat?.label);
    formData.append("Tax", values.tax);
    formData.append("Active", values.active);
    formData.append("Quantity", values.quantity);
    formData.append("Description", values.description);

    if (state1) {
      // const response = await UpdateProductApi({ ...values, id: state1.ID });
      const response = await UpdateProductApi(formData, state1.ID);

      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        // dispatch(fetchProducts());
        setSubmitting(false);
        resetForm();
        handleClosed();
        dispatch(fetchProducts());
      }
    }
    setSubmitting(false);
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            Stock Update
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent style={{ width: "600px" }}>
          <FormControl fullWidth>
            <Typography>Cost</Typography>
            <TextField
              name="cost"
              value={formik.values?.cost}
              size={"small"}
              type="number"
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.cost) && Boolean(formik.touched.cost)
              }
              helperText={
                Boolean(formik.errors.cost) &&
                Boolean(formik.touched.cost) &&
                formik.errors.cost
              }
            />
          </FormControl>
          <Box display="flex" gap={1}>
            <FormControl fullWidth>
              <Typography>Quantity</Typography>
              <TextField
                name="quantity"
                value={formik.values?.quantity}
                size={"small"}
                fullWidth
                onChange={formik.handleChange}
                type="number"
                error={
                  Boolean(formik.errors.quantity) &&
                  Boolean(formik.touched.quantity)
                }
                helperText={
                  Boolean(formik.errors.quantity) &&
                  Boolean(formik.touched.quantity) &&
                  formik.errors.quantity
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Price</Typography>
              <TextField
                name="price"
                value={formik.values?.price}
                size={"small"}
                type="number"
                fullWidth
                onChange={formik.handleChange}
                error={
                  Boolean(formik.errors.price) && Boolean(formik.touched.price)
                }
                helperText={
                  Boolean(formik.errors.price) &&
                  Boolean(formik.touched.price) &&
                  formik.errors.price
                }
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            {formik.isSubmitting ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  sx={{
                    background: "#cc6666",
                    color: "white !important",
                    fontSize: "13px",
                    textTransform: "none",
                  }}
                  onClick={handleClosed}
                >
                  Cancel
                </Button>

                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: "white !important",
                    fontSize: "13px",
                    textTransform: "none",
                  }}
                  type="submit"
                  autoFocus
                >
                  Update
                </Button>
              </>
            )}
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default StockModal;

StockModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  state1: PropTypes.any,
};
