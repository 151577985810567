import { Box, Chip, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AppTooltip from "../../Utils/AppTooltip";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import moment from "moment";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "20px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
    },
    iconBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };
});
function ActivityInfoModal({
  activityInfoModal,
  setactivityInfoModal,
  setactivityRow,
  activityRow,
}) {
  const { Container, ModalContainer, TableHeader, profile, iconBox } =
    useStyles();
  console.log("activityRow", activityRow);
  const hours = Math.floor(activityRow?.Minutes / 60);
  const minutes = Math.floor(activityRow?.Minutes % 60);
  const seconds = Math.floor(
    (activityRow?.Minutes - Math.floor(activityRow?.Minutes)) * 60
  );
  const formatDateTime = (input) => {
    let dateTime = moment(input, moment.ISO_8601, true);
    if (dateTime.isValid()) {
      return dateTime.format("DD-MMM-YYYY hh:mm A");
    }
    dateTime = moment(input, "DD-MMM-YYYY hh:mm A", true);
    if (dateTime.isValid()) {
      return dateTime.format("DD-MMM-YYYY hh:mm A");
    }
    throw new Error("Invalid date format");
  };
  return (
    <>
      <Modal
        open={activityInfoModal}
        onClose={() => {
          setactivityInfoModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Activity Details
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setactivityRow(null);
                  setactivityInfoModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box className={profile}>
                <Box sx={{ width: "100%" }}>
                  <Box className={iconBox}>
                    <AppTooltip title={"Lead"}>
                      <LeaderboardIcon
                        sx={{
                          fontSize: "20px",
                          color: "grey",
                          cursor: "pointer",
                        }}
                      />
                    </AppTooltip>
                    <Typography>
                      {activityRow?.LeadTitle || activityRow?.leadTitle}
                    </Typography>
                  </Box>
                  {activityRow.Projects && (
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{ width: "100%", maxWidth: "250px" }}
                        className={iconBox}
                      >
                        <AppTooltip title={"Project"}>
                          <AccountTreeIcon
                            sx={{
                              fontSize: "20px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                          />
                        </AppTooltip>
                        {activityRow?.Projects?.map((proj, idx) => (
                          <Chip
                            key={idx}
                            label={proj?.ProjectName}
                            sx={{
                              margin: "1px",
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}

                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{ width: "100%", maxWidth: "250px" }}
                      className={iconBox}
                    >
                      <AppTooltip title={"Customer"}>
                        <AccessibilityNewIcon
                          sx={{
                            fontSize: "20px",
                            color: "grey",
                            cursor: "pointer",
                          }}
                        />
                      </AppTooltip>
                      {(
                        activityRow?.ActivitiesPersonParticipants ||
                        activityRow?.PersonParticipant
                      )?.map((proj, idx) => (
                        <Chip
                          key={idx}
                          label={proj?.Name || proj?.PersonName}
                          sx={{
                            margin: "1px",
                          }}
                        />
                      ))}
                    </Box>
                    <Box className={iconBox}>
                      <AppTooltip title={"User"}>
                        <AccountCircleIcon
                          sx={{
                            fontSize: "20px",
                            color: "grey",
                            cursor: "pointer",
                          }}
                        />
                      </AppTooltip>

                      {(
                        activityRow?.ActivitiesUserParticipants ||
                        activityRow?.UserParticipant
                      )?.map((proj, idx) => (
                        <Chip
                          key={idx}
                          label={proj?.FullName || proj?.UserName}
                          sx={{
                            margin: "1px",
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Activity Title:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "20px",
                      }}
                    >
                      {activityRow?.Title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Type:</Typography>
                    <Typography
                      sx={{
                        paddingLeft: "80px",
                      }}
                    >
                      {activityRow?.Type}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Schedule On:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "17px",
                      }}
                    >
                      {formatDateTime(activityRow?.ScheduleFrom)}
                    </Typography>
                  </Box>
                  {activityRow?.Description && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "3px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Description:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "27px",
                        }}
                      >
                        {activityRow?.Description}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Status:</Typography>
                    <Typography
                      sx={{
                        paddingLeft: "40px",
                      }}
                    >
                      {activityRow?.StatusDone === true
                        ? "Completed"
                        : "Pending"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Sub Type:
                    </Typography>
                    <Typography
                      sx={{
                        paddingLeft: "20px",
                      }}
                    >
                      {activityRow?.SubType}
                    </Typography>
                  </Box>
                  {activityRow?.Location && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "3px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Location:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "25px",
                        }}
                      >
                        {activityRow?.Location}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              {activityRow?.Minutes && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    border: "1px solid #edf1f4",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "3px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Duration:
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: "50px",
                        }}
                      >
                        {`${hours} Hours ${minutes} Minutes ${seconds} Seconds`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Image:
                      </Typography>
                      <Box
                        sx={{
                          position: "relative",
                          //   height: "20%",
                          width: "50%",
                          borderRadius: "5px",
                          border: "1px solid rgb(214, 214, 214)",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer !important",
                          justifyContent: "center",
                          padding: "10px",
                          marginLeft: "100px",
                        }}
                      >
                        <img
                          src={activityRow?.File}
                          alt="Image"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ActivityInfoModal;
ActivityInfoModal.propTypes = {
  activityInfoModal: PropTypes.any,
  setactivityInfoModal: PropTypes.any,
  activityRow: PropTypes.any,
  setactivityRow: PropTypes.any,
};
