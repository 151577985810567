import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";

import PropTypes from "prop-types";
import AllRemarks from "./AllRemarks";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "40px",
      alignItems: "center",
    },
    noteboxMain: {
      display: "flex",
      flexDirection: "column",

      padding: "20px",
    },
  };
});
function Remarks({ singleLead, roles }) {
  const { noteboxMain, formContainer } = useStyle();
  console.log("Remarks", singleLead?.LeadRemark);
  return (
    <>
      {singleLead?.LeadRemark?.length > 0 ? (
        <Paper elevation={3}>
          <Box className={noteboxMain}>
            <AllRemarks singleLead={singleLead} roles={roles} />
          </Box>
        </Paper>
      ) : (
        <Paper elevation={3}>
          <Box className={formContainer}>
            <Typography>You have no Remarks</Typography>
          </Box>
        </Paper>
      )}
    </>
  );
}

export default Remarks;
Remarks.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
