import React, { useContext } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";
import PropTypes from "prop-types";
import SideBar from "../../Components/SideBar/SideBar";
// import TopBar from "../../Components/TopBar/UserDashBoard/TopBar";
import TitleBar from "../../Components/TopBar/UserDashBoard/TitleBar";
import { useEffect } from "react";
// import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
// import { Divider } from "@mui/material";
// import TopBar from "../../Components/TopBar/UserDashBoard/TopBar";
// import { Divider } from "@mui/material";
// import TitleBar from "../../Components/TopBar/UserDashBoard/TitleBar";
import { useSelector } from "react-redux";
// import { useMount } from "@reactuses/core";
import { AppContext } from "../../Context/TourContext/TourContext";
import { SideBarContext } from "../../Context/SideBarContext/SideBarContext";
// import Joyride from "react-joyride";
// import { TourSteps } from "../../Utils/TourSteps";
// import { TourCallback } from "../../Utils/TourCallbackFunction";

const UseContainer = ({
  activePage,
  headerTitle,
  buttons,
  disableTitleBar,
  transparentBackground,
  breadCrumb,
  breadCrumb2,
  linkbreadCrumb2,
}) => {
  // const cookies = new Cookies();
  const { openSidebar, setOpenSidebar } = useContext(SideBarContext);
  // const [open, setOpen] = useState(false);

  const [OnHover, setOnHover] = useState(false);
  const [selectedTab, setselectedTab] = useState("");
  const [selectedSecondTab, setselectedSecondTab] = useState("");
  // const [openDialog, setopenDialog] = useState(false);
  const [roles, setRoles] = useState([]);
  const { setstate, state } = useContext(AppContext);

  const { UserRoles } = useSelector((state) => state?.User);
  const navigate = useNavigate();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  useEffect(() => {
    setRoles(UserRoles);
  }, [UserRoles]);

  useEffect(() => {
    // let access_token = cookies.get("access_token");
    // let user = cookies.get("user");

    let access_token = sessionStorage.getItem("access_token");
    let user = sessionStorage.getItem("user");
    if (!access_token || !user) {
      navigate("/");
    }
  }, [sessionStorage.getItem("user")]);
  // useEffect(() => {

  //   setState({
  //     ...state,
  //     steps: TourSteps,
  //   });
  // }, []);
  console.log(setstate);
  useEffect(() => {
    if (state?.run == true && !guide) {
      setOpenSidebar(false);
    } else {
      // setOpenSidebar(true);
      // setstate({
      //   ...state,
      //   run: false,
      // });
    }
  }, [state?.run, openSidebar]);

  // const handleCallback = (data) => {
  //   TourCallback(data, state, setState, navigate);
  // };
  // const handleCallback = (data) => {
  //   const { action, index, lifecycle, type } = data;
  //   console.log("index", index);
  //   console.log("action", action);
  //   console.log("type", type);
  //   if (type === "step:after" && index === 0) {
  //     setState({ ...state, run: true, stepIndex: index + 1 });
  //   } else if (type === "step:after" && action === "prev" && index < 2) {
  //     setState({ ...state, run: true, stepIndex: index - 1 });
  //   } else if (
  //     type === "step:after" &&
  //     (index === 1 || (action === "prev" && index === 3))
  //   ) {
  //     setState({ ...state, run: false });

  //     navigate("/leads");
  //   }
  //   // else if (type === "step:after" && index === 1) {
  //   //   if (action === "next") {
  //   //     setState({ ...state, run: false });
  //   //     navigate("/quotes");
  //   //   } else {
  //   //     navigate("/dashboard");
  //   //     setState({ ...state, run: false, stepIndex: 0 });
  //   //   }
  //   // }
  //   else if (action === "reset" || lifecycle === "complete") {
  //     setState({ ...state, run: false, stepIndex: 0, tourActive: false });
  //   }
  // };
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: (theme) => theme.palette.background.main,
      }}
    >
      <CssBaseline />
      {/* Side Bar Component  */}
      <SideBar
        open={guide ? openSidebar : state?.run == true ? false : openSidebar}
        setOpen={setOpenSidebar}
        setOnHover={setOnHover}
        OnHover={OnHover}
        selectedTab={selectedTab}
        setselectedTab={setselectedTab}
        selectedSecondTab={selectedSecondTab}
        setselectedSecondTab={setselectedSecondTab}
        isAdmin={false}
        roles={roles}
      />
      {/* Top Bar Component  */}

      <Box
        sx={{
          flex: 1,
          minWidth: "calc(100% - 390px)",
          width: "100%",
          // paddingTop:"10px"
        }}
      >
        {/* <TopBar
                    open={openSidebar}
                    setOpen={setOpenSidebar}
                    setOnHover={setOnHover}
                    openDialog={openDialog}
                    setopenDialog={setopenDialog}
                    isSettings={false}
                    headerTitle={headerTitle}
                /> */}
        {/* <Divider /> */}
        {!disableTitleBar ? (
          <Box
            position="sticky"
            sx={{
              backgroundColor: (theme) => theme.palette.background.main,
              // padding: "10px",
            }}
          >
            <TitleBar
              headerTitle={headerTitle}
              buttons={buttons}
              roles={roles}
              setOpen={setOpenSidebar}
              openSidebar={openSidebar}
              breadCrumb={breadCrumb}
              breadCrumb2={breadCrumb2}
              linkbreadCrumb2={linkbreadCrumb2}
            />
          </Box>
        ) : null}

        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.main,
            height: disableTitleBar
              ? "calc(100vh - 20px)"
              : "calc(100vh - 70px)",
          }}
        >
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100%",
              backgroundColor: (theme) =>
                transparentBackground
                  ? theme.palette.background.main
                  : theme.palette.background.main,
              borderRadius: "5px",
              padding: "0px 10px 0px 10px",
            }}
            onMouseOver={() => {
              if (OnHover === true) {
                setOpenSidebar(false);
                setOnHover(false);
              }
              setOpenSidebar(false);
            }}
          >
            <Box>
              {React.cloneElement(activePage, {
                roles: roles,
              })}
              {/* <Joyride
                callback={handleCallback}
                continuous
                run={state?.run}
                stepIndex={state?.stepIndex}
                steps={state?.steps}
                showProgress={true}
                showSkipButton={true}
                floaterProps={{
                  styles: {
                    arrow: {
                      margin: -10,
                    },
                  },
                }}
                styles={{
                  options: {
                    arrowColor: "#5caeab",
                    backgroundColor: "#5caeab",
                    // overlayColor: "rgba(92, 174, 171, .3)",
                    primaryColor: "#5caeab",
                    textColor: "#fff",
                  },
                  // spotlight: {
                  //   backgroundColor: "transparent",
                  // },
                }}
              /> */}
              {/* {activePage} */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default UseContainer;

UseContainer.propTypes = {
  activePage: PropTypes.any.isRequired,
  headerTitle: PropTypes.any,
  buttons: PropTypes.any,
  disableTitleBar: PropTypes.any,
  breadCrumb: PropTypes.any,
  breadCrumb2: PropTypes.any,
  linkbreadCrumb2: PropTypes.any,

  transparentBackground: PropTypes.bool,
};
