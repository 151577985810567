import React, { useState, useEffect, useContext } from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Badge, Box, Button, IconButton, Typography } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";

import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import Loader from "../../../Components/Loader/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import TemplatesInfoModal from "../../../Components/Campaign/Templates/TemplatesInfoModal";
import {
  fetchAllCampaigns,
  getCampaignsDetails,
} from "../../../Redux/Slice/CampaignsSlice/CampaignsSlice";
import ChatIcon from "@mui/icons-material/Chat";
import { DeleteCampaignApi } from "../../../Api/CampaignsApi/CampaignDeleteApi";
import CampaignGroupInfoMod from "./CampaignGroupInfoMod";
import MessageDetailModal from "./MessageDetailModal";
import { AppContext } from "../../../Context/TourContext/TourContext";
// import ImageIcon from "@mui/icons-material/Image";
import Joyride from "react-joyride";
import CampaignFilterDrawer from "./CampaignFilterDrawer";
import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import { RemoveSCFromInputs } from "../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../Components/AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

function Campaigns({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");

  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const { AllCampaign, status } = useSelector(getCampaignsDetails);
  const [pageSize, setpageSize] = useState(totalRows);
  const [showLoading, setshowLoading] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [rows, setrows] = useState([]);
  const [allCampaign, setallCampaign] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [messageModal, setmessageModal] = useState(false);
  const [group, setgroup] = useState(null);
  const [selectedCampaign, setselectedCampaign] = useState(null);
  const { setState, state } = useContext(AppContext);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [filterVal, setfilterVal] = useState({
    type: "",
    campaignGroup: "",
    title: "",
    refrenceType: "",
    refrence: "",
    templete: "",
  });
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Campaigns");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  const handleClosed = () => {
    setShowModal(false);
  };
  // const [group, setgroup] = useState(null);
  // const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const handleClosed = () => {
  //   setShowModal(false);
  // };
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    dispatch(fetchAllCampaigns());
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    AllCampaign?.map((item, i) => {
      rowData.push({
        id: i + 1,
        CampaignTitle: item.CampaignTitle,
        CampaignType: item.CampaignType,
        File: item.File,
        ReferenceType: item.ReferenceType,
        Template: item.Template,
        CampaignGroup: item.CampaignGroup,
        Audience: item.CampaignGroup,
        Reference: item.Reference,
        ID: item.CampaignId,
        Message: item.Message,
        FileName: item.FileName,
      });
    });
    setrows(rowData);
    setallCampaign(rowData);
  }, [AllCampaign]);
  useEffect(() => {
    if (
      filterVal?.type === "" &&
      filterVal?.title === "" &&
      filterVal?.refrenceType === "" &&
      filterVal?.campaignGroup === "" &&
      filterVal?.templete === "" &&
      filterVal?.refrence === ""
    ) {
      setallCampaign(rows);
    } else {
      let suggestions = [];
      let regex3 = new RegExp(filterVal?.type, "i");
      let regex4 = new RegExp(filterVal?.title, "i");
      let regex5 = new RegExp(filterVal?.refrenceType, "i");
      let regex6 = new RegExp(filterVal?.campaignGroup, "i");

      let regex9 = new RegExp(filterVal?.templete, "i");
      let regex10 = new RegExp(filterVal?.refrence, "i");

      suggestions = rows?.filter(
        (val) =>
          (filterVal?.type === "" || regex3.test(val?.CampaignType)) &&
          (filterVal?.title === "" || regex4.test(val?.CampaignTitle)) &&
          (filterVal?.refrenceType === "" || regex5.test(val?.ReferenceType)) &&
          (filterVal?.campaignGroup === "" ||
            regex6.test(val?.CampaignGroup?.Name)) &&
          (filterVal?.templete === "" || regex9.test(val?.Template?.Title)) &&
          (filterVal?.refrence === "" ||
            regex10.test(val?.Reference?.ReferenceName))
      );
      setallCampaign(suggestions);
    }
  }, [filterVal, rows]);
  // const handleClick = (val) => {
  //   navigate("/campaigns/templates/create", { state: val });
  // };
  const deleteOrg = async (id) => {
    setshowLoading(true);
    let res = await DeleteCampaignApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);

    if (res.data.IsSuccess) {
      dispatch(fetchAllCampaigns());
      setsnackBarData(res.snackBarData);
    }
  };
  const { setsnackBarData } = useContext(SnackBarContext);

  const getColumns = () => {
    const columns = [
      { field: "id", headerName: "Sr", width: 50 },
      { field: "CampaignTitle", headerName: "Title", width: 200 },
      { field: "CampaignType", headerName: "Type", width: 100 },
      {
        field: "CampaignGroup",
        headerName: "Campaign Group",
        width: 200,
        renderCell: (celval) => {
          return (
            <>
              <Typography>{celval?.row?.CampaignGroup?.Name}</Typography>
            </>
          );
        },
      },
      {
        field: "Template",
        headerName: "Template",
        width: 200,
        renderCell: (celval) => {
          return (
            <>
              <Typography>{celval?.row?.Template?.Title}</Typography>
            </>
          );
        },
      },
      {
        field: "Audience",
        headerName: "Audience",
        width: 100,
        renderCell: (celval) => {
          return (
            <>
              <Tooltip title="View">
                <IconButton
                  id="campaign-audience"
                  // key={i}
                  onClick={() => {
                    setShowModal(true);
                    setgroup(celval.row?.Audience);
                  }}
                >
                  <VisibilityIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.main,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
              {/* {
            celval?.row?.File?
       
            <Link
              href={celval?.row?.File}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip title="View Image">
                <IconButton>
                  <ImageIcon
                    fontSize="small"
                    color="primary"
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Link>
            :null
          } */}
            </>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 180,

        renderCell: (celval, i) => {
          return (
            <>
              <Box id="campaign-actions" key={i}>
                {/* <Tooltip title="Edit">
                    <IconButton
                      key={i}
                      onClick={() => {
                        handleClick(celval.row);
                      }}
                      disabled={
                        roles?.some((role) => role === "Organizations_Edit")
                          ? false
                          : true
                      }
                    >
                      <EditIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.edit,
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip> */}

                {roles?.some((role) => role === "Campaigns_Delete") && (
                  <Tooltip title="Delete">
                    <IconButton
                      key={i}
                      onClick={() => {
                        setselectedId(celval.row.ID);
                        setshowConfirmationIcons(true);
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.delete,
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="View Message">
                  <IconButton
                    key={i}
                    onClick={() => {
                      setselectedCampaign(celval.row);
                      setmessageModal(true);
                    }}
                  >
                    <ChatIcon
                      sx={{
                        color: (theme) => theme?.palette?.primary?.main,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          );
        },
      },
    ];

    if (sessionStorage.getItem("BusinessType") === "General") {
      columns.splice(
        4,
        0,
        {
          field: "ReferenceType",
          headerName: "Reference Type",
          width: 200,
        },
        {
          field: "Reference",
          headerName: "Reference",
          width: 200,
          renderCell: (celval) => {
            return (
              <>
                <Typography>{celval?.row?.Reference?.ReferenceName}</Typography>
              </>
            );
          },
        }
      );
    } else {
      columns.splice(4, 0, {
        field: "Reference",
        headerName: "Project Name",
        width: 200,
        renderCell: (celval) => {
          return (
            <>
              <Typography>{celval?.row?.Reference?.ReferenceName}</Typography>
            </>
          );
        },
      });
    }

    return columns;
  };
  const columns = getColumns();

  return (
    <>
      <Box>
        <CampaignFilterDrawer
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
          rows={rows}
          setallCampaign={setallCampaign}
          handleChangeVal={handleChangeVal}
          setcount={setcount}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
        />
      </Box>
      <Box>
        {!guide ? (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Campaigns"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        ) : (
          <></>
        )}

        {/* {activePage} */}
      </Box>
      <Box id="campaign">
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            rows={allCampaign}
            pageSize={pageSize}
            columns={columns}
            onPageSizeChange={(newPage) => {
              setpageSize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            // scrollbarSize={1}
            // scrollbarSize={'1px'}
          />
          {status === "loading" || showLoading ? <Loader /> : null}
        </Box>
      </Box>
      <CampaignGroupInfoMod
        showModal={showModal}
        handleClosed={handleClosed}
        group={group}
      />
      {messageModal && (
        <MessageDetailModal
          showModal={messageModal}
          setShowModal={setmessageModal}
          selectedCampaign={selectedCampaign}
          setselectedCampaign={setselectedCampaign}
        />
      )}

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteOrg(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Campaign"
        title={`Are you sure, you want to Delete Campaign?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Campaigns;
Campaigns.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#campaign",
    content: (
      <>
        <p size="large">Campaigns Page</p>
        <p>
          Welcome to the Campaigns Page – your command center for orchestrating
          impactful campaigns. Here, you can create, manage, and track the
          details of diverse campaigns to engage with your audience effectively.
          <br />
          <br />
          Ready to launch a new campaign? Click &apos;New&apos; to kickstart the
          creation process. Maintain organized records and watch your campaigns
          flourish.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#campaign-audience",
    content: (
      <>
        <p size="large">Audience</p>
        <p>
          Click on eye icon to view the audience that are targeted by the
          particular campaign.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#campaign-actions",
    content: (
      <>
        <p size="large">Campaign Actions</p>
        <p>
          Delete: Remove campaigns that have completed their lifecycle.
          <br />
          View: View the mobile view of the campaign message.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
