import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";
import GetQueryParams from "../../../Utils/GetQueryParams";

const initialState = {
  Leads: null,
  SingleLeads: [],
  RottenDays: null,
  LeadsPopulate: [],
  LeadsActivityType: [],
  LeadsAffliate: [],
  LeadsUsers: [],
  LeadsPersons: [],
  LeadsByPiplineStage: [],
  DeletedLeads: [],
  LeadWonPopulate: [],
  TransferUsers: [],
  LeadsNotes: null,
  LeadsFiles: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status3: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status4: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status5: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchLeads = createAsyncThunk(
  // "/Lead",
  "/Lead/ByPipeline",
  async (payload) => {
    const queryParams = GetQueryParams(payload?.queryParams);

    const response = await axiosInstance.get(
      `/Lead/ByPipeline/${payload?.id}?${queryParams}`
    );
    return response;
  }
);

export const fetchLeadsSingle = createAsyncThunk("/Lead/{id}", async (id) => {
  const response = await axiosInstance.get(`/Lead/${Number(id)}`);

  return response;
});

export const fetchLeadsPopulate = createAsyncThunk(
  "/Lead/Populate",
  async () => {
    const response = await axiosInstance.get(`/Lead/Populate`);

    return response;
  }
);
////////
export const fetchusers = createAsyncThunk("/Users", async () => {
  const response = await axiosInstance.get(`/Users`);
  return response;
});

export const fetchLeadsNotes = createAsyncThunk("/LeadNotes", async () => {
  const response = await axiosInstance.get(`/LeadNotes`);

  return response;
});

export const fetchLeadsFiles = createAsyncThunk("/LeadFiles", async () => {
  const response = await axiosInstance.get(`/LeadFiles`);

  return response;
});

// export const fetchLeadActivityTypePopulate = createAsyncThunk(
//   "/ActivtyType",
//   async () => {
//     const response = await axiosInstance.get(`/ActivtyType`);

//     return response;
//   }
// );

export const fetchleadActivityTypePopulate = createAsyncThunk(
  "/ActivtyTypePapulate",
  async (LeadId) => {
    if (LeadId) {
      const response = await axiosInstance.get(
        `/LeadActivities/PopulateActivity?LeadId=${LeadId}`
      );
      return response;
    } else {
      const response = await axiosInstance.get(
        `/LeadActivities/PopulateActivity`
      );
      return response;
    }
  }
);
export const fetchLeadByPiplineStage = createAsyncThunk(
  "/Lead/ByPipelineStage",
  async (id) => {
    const response = await axiosInstance.get(`/Lead/ByPipelineStage/${id}`);

    return response;
  }
);

export const fetchDeletedLeadsData = createAsyncThunk(
  "/Lead/GetDeletedLeads",
  async (pipelineId) => {
    const response = await axiosInstance.get(
      `/Lead/GetDeletedLeads?pipelineId=${pipelineId}`
    );

    return response;
  }
);
export const fetchLeadWonPopulate = createAsyncThunk(
  "/Lead/PopulateLeadWon",
  async (id) => {
    const response = await axiosInstance.get(
      `/Lead/PopulateLeadWon?leadId=${id}`
    );
    return response;
  }
);

const Leadslice = createSlice({
  name: "Leads",
  initialState,
  reducers: {
    LeadsAdded: {
      reducer(state, action) {
        state.Leads?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchLeads.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Leads = action.payload.data;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
        state.Leads = null;
      })
      //
      .addCase(fetchusers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchusers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.TransferUsers = action.payload.data;
      })
      .addCase(fetchusers.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
        state.TransferUsers = null;
      })
      //
      .addCase(fetchLeadWonPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeadWonPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.LeadWonPopulate = action.payload.data.Data;
      })
      .addCase(fetchLeadWonPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchLeadsSingle.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchLeadsSingle.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SingleLeads = action.payload.data.Data;
        state.RottenDays = action.payload.data.RottenDays;
      })
      .addCase(fetchLeadsSingle.rejected, (state, action) => {
        state.error = action.error.message;
        state.status1 = "failed";
      })
      .addCase(fetchLeadsNotes.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchLeadsNotes.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.LeadsNotes = action.payload.data.Data;
      })
      .addCase(fetchLeadsNotes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status2 = "failed";
      })
      .addCase(fetchLeadsFiles.pending, (state) => {
        state.status3 = "loading";
      })
      .addCase(fetchLeadsFiles.fulfilled, (state, action) => {
        state.status3 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.LeadsFiles = action.payload.data.Data;
      })
      .addCase(fetchLeadsFiles.rejected, (state, action) => {
        state.error = action.error.message;
        state.status3 = "failed";
      })
      .addCase(fetchleadActivityTypePopulate.pending, (state) => {
        state.status3 = "loading";
      })
      .addCase(fetchleadActivityTypePopulate.fulfilled, (state, action) => {
        state.status3 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.LeadsActivityType = action.payload?.data?.Data?.activityTypesData;
        state.LeadsPersons = action.payload.data?.Data?.peronData;
        state.LeadsUsers = action.payload.data?.Data?.userData;
        state.LeadsAffliate = action.payload?.data?.Data?.affiliateData;
      })
      .addCase(fetchleadActivityTypePopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status3 = "failed";
      })
      .addCase(fetchLeadsPopulate.pending, (state) => {
        state.status4 = "loading";
      })
      .addCase(fetchLeadsPopulate.fulfilled, (state, action) => {
        state.status4 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.LeadsPopulate = action.payload.data;
      })
      .addCase(fetchLeadsPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status4 = "failed";
      })
      .addCase(fetchLeadByPiplineStage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeadByPiplineStage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.LeadsByPiplineStage = action.payload.data.Data;
      })
      .addCase(fetchLeadByPiplineStage.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchDeletedLeadsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeletedLeadsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.DeletedLeads = action.payload.data.Data;
      })
      .addCase(fetchDeletedLeadsData.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
        state.DeletedLeads = [];
      });
  },
});

export const getLeadsDetails = (state) => state.Leads;

export const { LeadsAdded } = Leadslice.actions;

export default Leadslice.reducer;
