import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppConfirmationDialog = ({
  open,
  onDeny,
  onConfirm,
  iconContainerColor,
  icon,
  dialogTitle,
  title,
  successText,
  successBtnColor,
  cancelText,
  loading,
  okButton,
}) => {
  const { mainContainer, container } = useStyles();

  return (
    <Dialog
      open={open}
      onDeny={onDeny}
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: "20px" } }}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <Box className={mainContainer}>
        <Box className={container}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginBottom: "5px",
                backgroundColor: iconContainerColor,
                padding: "15px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {icon}
            </Box>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: 600,
                lineHeight: 1.5,
                textAlign: "center",
              }}
            >
              {dialogTitle}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                color: "GrayText",
                lineHeight: 1.5,
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    flex: 1,
                    backgroundColor: "#d9d9d9 !important",
                    color: "inherit",
                    fontWeight: 600,
                  }}
                  onClick={onDeny}
                >
                  {cancelText}
                </Button>
                {!okButton && (
                  <Button
                    variant="contained"
                    color={successBtnColor}
                    disableElevation
                    sx={{ flex: 1, fontWeight: 600 }}
                    onClick={onConfirm}
                  >
                    {successText}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AppConfirmationDialog;
AppConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onDeny: PropTypes.func,
  onConfirm: PropTypes.func,
  iconContainerColor: PropTypes.string,
  icon: PropTypes.node,
  dialogTitle: PropTypes.string,
  title: PropTypes.string,
  successText: PropTypes.string,
  successBtnColor: PropTypes.string,
  cancelText: PropTypes.string,
  loading: PropTypes.bool,
  okButton: PropTypes.any,
};

const useStyles = makeStyles(() => {
  return {
    mainContainer: {
      minWidth: "350px",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      minWidth: "290px",
      maxWidth: "290px",
      display: "flex",
      flexDirection: "column",
      gap: "30px",
    },
  };
});
