import { axiosInstance } from "../../../Utils/AxiosInstance";

export const AddUpdateCategoryApi = async (obj) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(`/Sections/AddUpdateCategory`, obj);

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const DeleteCategoryApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(
      `/Sections/Category/Delete?Id=${id}`
    );

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
