import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Box } from "@mui/material";
import All from "./All/All";
import Notes from "./Notes/Notes";
import Calls from "./Calls/Calls";
import Meeting from "./Meetings/Meeting";
import Lunches from "./Lunches/Lunches";
import Files from "./Files/Files";
// import Emails from "./Emails/Emails";
import Quote from "./Quote/Quote";
import PropTypes from "prop-types";
import Messages from "./Messages/Messages";
import Won from "./Won/Won";
import Remarks from "./Remarks/Remarks";
// import Cookies from "universal-cookie";

function RightBottomTabs({ singleLead, roles }) {
  // const cookies = new Cookies();

  const [value, setValue] = useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", paddingTop: "15px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ fontWeight: "bold" }}
          label="All Activities"
          {...a11yProps(0)}
        />
        <Tab sx={{ fontWeight: "bold" }} label="Notes" {...a11yProps(1)} />
        <Tab sx={{ fontWeight: "bold" }} label="Calls" {...a11yProps(2)} />
        <Tab sx={{ fontWeight: "bold" }} label="Meetings" {...a11yProps(3)} />
        <Tab sx={{ fontWeight: "bold" }} label="Emails" {...a11yProps(4)} />
        <Tab sx={{ fontWeight: "bold" }} label="Messages" {...a11yProps(8)} />
        <Tab sx={{ fontWeight: "bold" }} label="Files" {...a11yProps(5)} />
        <Tab sx={{ fontWeight: "bold" }} label="Remarks" {...a11yProps(6)} />

        {/* <Tab
          sx={{ fontWeight: "bold" }}
          label="Emails"
          {...a11yProps(6)}
        /> */}
        <Tab
          {...a11yProps(7)}
          label="Quote"
          sx={{
            fontWeight: "bold",
            display:
              sessionStorage.getItem("BusinessType") === "General"
                ? "inline-flex"
                : "none",
          }}
        />
        <Tab
          sx={{
            fontWeight: "bold",
            display: singleLead?.Status === "Won" ? "inline-flex" : "none",
          }}
          label="Won Detail"
          {...a11yProps(9)}
        />
      </Tabs>
      {value === 0 ? (
        <All singleLead={singleLead} roles={roles} />
      ) : value === 1 ? (
        <Notes singleLead={singleLead} roles={roles} />
      ) : value === 2 ? (
        <Calls singleLead={singleLead} roles={roles} />
      ) : value === 3 ? (
        <Meeting singleLead={singleLead} roles={roles} />
      ) : value === 4 ? (
        <Lunches singleLead={singleLead} roles={roles} />
      ) : value === 5 ? (
        <Messages singleLead={singleLead} roles={roles} />
      ) : value === 6 ? (
        <Files singleLead={singleLead} roles={roles} />
      ) : value === 7 ? (
        <Remarks singleLead={singleLead} roles={roles} />
      ) : //  value === 6 ? (
      //   <Emails roles={roles} />
      // )
      //  :
      value === 7 ? (
        <Quote singleLead={singleLead} roles={roles} />
      ) : value === 8 ? (
        <Won singleLead={singleLead} roles={roles} />
      ) : null}
    </Box>
  );
}

export default RightBottomTabs;
RightBottomTabs.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
