import React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import theme from "../../../../../theme";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { AddUpdateCategoryApi } from "../../../../../Api/Settings/CategoryApis/CategoryApis";

const validationSchema = Yup.object().shape({
  categoryName: Yup.string().required("Field Required!"),
});

function CategoryCreateUpdateForm() {
  const { container, subContainer, formContainer } = useStyle();

  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let formData = { ...values };

    if (state) {
      formData.categoryId = state?.categoryId;
    }

    const response = await AddUpdateCategoryApi(formData);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      resetForm();
      navigate(-1);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      categoryName: state?.categoryName || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box
            role="presentation"
            onClick={(e) => e.preventDefault()}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ fontSize: "12px" }}
            >
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "underline" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/settings"
                style={{ color: "#33A5FC", textDecoration: "underline" }}
                className="link"
                underline="hover"
              >
                Settings
              </Link>
              <Link
                to="/settings/inventory/category"
                style={{ color: "#33A5FC", textDecoration: "underline" }}
                className="link"
                underline="hover"
              >
                Category
              </Link>
              <Typography
                color="text.primary"
                sx={{ fontSize: "12px" }}
              >
                {state ? "Update Category" : "Create Category"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography
            variant="h5"
            sx={{ textAlign: "left", fontWeight: 400 }}
          >
            {state ? "Update Category" : "Create Category"}
          </Typography>
        </Box>

        <Paper
          elevation={3}
          sx={{ padding: "20px" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Category Name</Typography>
                <TextField
                  size={"small"}
                  fullWidth
                  name="categoryName"
                  value={formik.values?.categoryName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.categoryName) &&
                    Boolean(formik.touched.categoryName)
                  }
                  helperText={
                    Boolean(formik.errors.categoryName) &&
                    Boolean(formik.touched.categoryName)
                      ? formik.errors.categoryName
                      : ""
                  }
                />
              </FormControl>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                </Button>

                <Box>
                  {formik.isSubmitting ? (
                    <Box>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button
                      variant="contained"
                      type="submit"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {state ? "Update" : "Save"}
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CategoryCreateUpdateForm;
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
});
