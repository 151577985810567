import React, { createContext, useState } from "react";
// import * as signalR from "@microsoft/signalr";
import PropTypes from "prop-types";

const SignalRContext = createContext(null);

export const SignalRProvider = (props) => {
  const [connection] = useState(null);

  // useEffect(() => {
  //   const newConnection = new signalR.HubConnectionBuilder()
  //     .withUrl("https://localhost:7171/notificationHub", {
  //       skipNegotiation: true,
  //       transport: signalR.HttpTransportType.WebSockets,
  //     })
  //     .configureLogging(signalR.LogLevel.Debug)
  //     .build();

  //   setConnection(newConnection);
  // }, []);
  // useEffect(() => {
  //   if (connection) {
  //     connection.start();
  //   }
  // }, [connection]);

  return (
    <SignalRContext.Provider value={connection}>
      {props.children}
    </SignalRContext.Provider>
  );
};

export default SignalRContext;
SignalRProvider.propTypes = {
  children: PropTypes.any,
};
