import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import PropTypes from "prop-types";
import { Divider,  InputAdornment,  TextField,  Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex", flexDirection: "column", gap: "10px",
    },
    fieldBox: {
      display: "flex", alignItems: "center", gap: "10px"
    }
  };
});

function InboxFilterDrawer({drawerOpen,setdrawerOpen,filterVal,setfilterVal,
    setallInbox,setcount,rows,handleChangeVal}) {
    const { contentBox, fieldBox } = useStyles();
    const handleClose = () => {
      setdrawerOpen(!open);
    };
      const handleRemoveAll = () => {
        for (const value in filterVal) {
          filterVal[value] = '';
        }
        setcount(0);
      };
  return (
    <React.Fragment>
    <Drawer
      anchor={"right"}
      open={drawerOpen}
      onClose={handleClose}
      className="abc"
    >
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant='h6'>Filter</Typography>
          <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Typography sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}  onClick={() => { setallInbox(rows); handleRemoveAll(); }} >Remove All</Typography>
            <CloseIcon fontSize='small' sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
        </Box>
        <Divider sx={{ width: "100%", paddingTop: "10px", marginBottom: '10px' }} />
        <Box className={contentBox}>
        <Box>
                <Typography>From</Typography>
                <Box className={fieldBox} >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: (theme) => theme.palette.grey.G2 }}
                          />
                        </InputAdornment>
                      ),
                    }}

                    name="from"
                    variant="outlined"
                    size='small'
                    value={filterVal?.from}
                    type="text"
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    placeholder="From"
                    className="input_border"
                    onChange={(e) => {handleChangeVal(e); }}
                  />
                  <CloseIcon onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      from: ''
                    });

                  }} fontSize='small' sx={{ cursor: "pointer" }} />
                </Box>
              </Box>
              <Box>
                <Typography>Subject</Typography>
                <Box className={fieldBox} >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: (theme) => theme.palette.grey.G2 }}
                          />
                        </InputAdornment>
                      ),
                    }}

                    name="subject"
                    variant="outlined"
                    size='small'
                    value={filterVal?.subject}
                    type="text"
                    sx={{ width: "100%" }}
                    autoComplete="off"
                    placeholder="subject"
                    className="input_border"
                    onChange={(e) => { handleChangeVal(e); }}
                  />
                  <CloseIcon onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      subject: ''
                    });

                  }} fontSize='small' sx={{ cursor: "pointer" }} />
                </Box>
              </Box>

              <Box>
                <Typography>Created Date</Typography>
                <Box className={fieldBox}>
                  <TextField
                    sx={{ width: "150px" }}
                    variant="outlined"
                    value={filterVal?.createdate}
                    size='small'
                    name="createdate"
                    type="date"
                    autoComplete="off"
                    placeholder="Created Date"
                    className="input_border"
                    onChange={(e) => { handleChangeVal(e); }}

                  />
                  To
                  <TextField
                    variant="outlined"
                    sx={{ width: "150px" }}
                    value={filterVal?.enddate}
                    size='small'
                    name="enddate"
                    type="date"
                    autoComplete="off"
                    placeholder="Created Date"
                    className="input_border"
                    onChange={(e) => { handleChangeVal(e); }}

                  />
                  <CloseIcon fontSize='small' onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      enddate: '',
                      createdate: ''

                    });
                

                  }} sx={{ cursor: "pointer" }} />
                </Box>
              </Box>
        </Box>
      </Box>
    </Drawer>
  </React.Fragment>
  );
}

export default InboxFilterDrawer;
InboxFilterDrawer.propTypes = {
    drawerOpen: PropTypes.any,
    setdrawerOpen: PropTypes.any,
    filterVal: PropTypes.any,
    setfilterVal: PropTypes.any,
    setallInbox: PropTypes.any,
    setcount: PropTypes.any,
    rows: PropTypes.any,
    handleChangeVal: PropTypes.any,
  };