import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Divider,
  InputAdornment,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import {
  fetchLeadsPopulate,
  getLeadsDetails,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});
function RecycleLeadsFilterDrawer({
  setcount,
  setfilterVal,
  filterVal,
  handleChangeVal,
  setallLeads,
  rows,
  setdrawerOpen,
  drawerOpen,
}) {
  const { contentBox, fieldBox } = useStyles();

  const dispatch = useDispatch();
  const { LeadsPopulate, status } = useSelector(getLeadsDetails);

  useEffect(() => {
    dispatch(fetchLeadsPopulate());
  }, [dispatch]);

  const handleClose = () => {
    setdrawerOpen(!open);
  };

  const handleRemoveAll = () => {
    for (const value in filterVal) {
      if (value === "tags") {
        filterVal[value] = [];
      } else {
        filterVal[value] = "";
      }
    }
    setcount(0);
  };

  return (
    <React.Fragment>
      {status == "loading" && <Loader />}
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Filter</Typography>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  setallLeads(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            <Box>
              <Typography>Project</Typography>
              <Box className={fieldBox}>
                <Select
                  name="project"
                  variant="outlined"
                  size="small"
                  value={filterVal?.project}
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Project"
                  className="input_border"
                  select
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                >
                  <MenuItem value="">select</MenuItem>
                  {LeadsPopulate?.Data?.projectData?.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={item?.ProjectId}
                      >
                        {item?.ProjectName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      project: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Stage</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="stage"
                  variant="outlined"
                  size="small"
                  value={filterVal?.stage}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Stage"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      stage: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Sales Person</Typography>
              <Box className={fieldBox}>
                <Autocomplete
                  options={LeadsPopulate?.Data?.userData || []}
                  getOptionLabel={(option) => option?.FullName}
                  renderOption={(props, option) => {
                    return (
                      <ListItem
                        {...props}
                        key={option.Id}
                      >
                        <Typography>{option?.FullName}</Typography>
                      </ListItem>
                    );
                  }}
                  listStyle={{ maxHeight: 200, overflow: "auto" }}
                  sx={{ width: "100%", maxWidth: "325px" }}
                  fullWidth
                  name="salesperson"
                  value={
                    LeadsPopulate?.Data?.userData?.find(
                      (item) => item?.Id === filterVal?.salesperson
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setfilterVal({
                        ...filterVal,
                        salesperson: value?.Id,
                      });
                    } else {
                      setfilterVal({
                        ...filterVal,
                        salesperson: "",
                      });
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="input_border"
                    />
                  )}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      salesperson: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Customer</Typography>
              <Box className={fieldBox}>
                <Autocomplete
                  options={LeadsPopulate?.Data?.personData || []}
                  getOptionLabel={(option) => option?.Name}
                  renderOption={(props, option) => {
                    return (
                      <ListItem
                        {...props}
                        key={option.Id}
                      >
                        <Typography>
                          {option?.Name} ({option?.ContactNumber})
                        </Typography>
                      </ListItem>
                    );
                  }}
                  listStyle={{ maxHeight: 200, overflow: "auto" }}
                  sx={{ width: "100%", maxWidth: "325px" }}
                  fullWidth
                  name="person"
                  value={
                    LeadsPopulate?.Data?.personData?.find(
                      (item) => item?.Id === filterVal?.person
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setfilterVal({
                        ...filterVal,
                        person: value?.Id,
                      });
                    } else {
                      setfilterVal({
                        ...filterVal,
                        person: "",
                      });
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="input_border"
                    />
                  )}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      person: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Lead Value</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="leadValue"
                  variant="outlined"
                  size="small"
                  value={filterVal?.leadValue}
                  type="number"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Lead Value"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      leadValue: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Lead Source</Typography>
              <Box className={fieldBox}>
                <Select
                  name="leadSource"
                  variant="outlined"
                  size="small"
                  // defaultValue={filterVal?.leadSource}
                  value={filterVal?.leadSource}
                  // type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Sales Person"
                  className="input_border"
                  select
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                >
                  <MenuItem value="">select</MenuItem>
                  {LeadsPopulate?.Data?.sourceData?.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={item?.Name}
                      >
                        {item?.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      leadSource: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Title</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="subject"
                  variant="outlined"
                  size="small"
                  value={filterVal?.subject}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Title"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      subject: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Expected Close Date</Typography>
              <Box className={fieldBox}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  value={filterVal?.closeDate}
                  size="small"
                  name="closeDate"
                  type="date"
                  autoComplete="off"
                  placeholder="Expected Close Date"
                  className="input_border"
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  fontSize="small"
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      enddate: "",
                      closeDate: "",
                    });
                  }}
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Tags</Typography>
              <Box className={fieldBox}>
                <Autocomplete
                  multiple
                  options={LeadsPopulate?.Data?.Tags || []}
                  getOptionLabel={(option) => option?.Name}
                  listStyle={{ maxHeight: 200, overflow: "auto" }}
                  sx={{ width: "100%" }}
                  fullWidth
                  name="tags"
                  value={filterVal?.tags}
                  onChange={(event, value) => {
                    if (value) {
                      setfilterVal({
                        ...filterVal,
                        tags: value,
                      });
                    } else {
                      setfilterVal({
                        ...filterVal,
                        tags: [],
                      });
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="input_border"
                    />
                  )}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      tags: [],
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default RecycleLeadsFilterDrawer;
RecycleLeadsFilterDrawer.propTypes = {
  screen: PropTypes.string,
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  setallLeads: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
  setTotalCount: PropTypes.func,
  searchAllData: PropTypes.func,
};
