import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  // DialogContent,
  // DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchDocuments,
//   getOrganizationDetails,
// } from "../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import ImageIcon from "@mui/icons-material/Image";
import ArticleIcon from "@mui/icons-material/Article";
import { DeleteDocumentApi } from "../../Api/DocumentApi/DeleteDocumentApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { fetchDocuments } from "../../Redux/Slice/OrganizationSlice/OrganizationSlice";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});
function DocumentDetailModal({
  showModal,
  setShowModal,
  Type,
  setselectedRow,
  selectedRow,
  setformModal,
  DocumentsData,
}) {
  const { Container, ModalContainer, TableHeader } = useStyles();
  // const { DocumentsData } = useSelector(getOrganizationDetails);
  const [allDocuments, setallDocuments] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = useState(false);
  const [row, setrow] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  console.log(row);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setrow(null);
    setOpen(false);
  };
  console.log(setselectedRow);
  console.log(allDocuments);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchDocuments({ type: Type, id: selectedRow?.ID }));
  // }, [dispatch, selectedRow]);
  useEffect(() => {
    if (DocumentsData) {
      setallDocuments(DocumentsData);
    }
  }, [DocumentsData]);
  const handleDelete = async () => {
    setloading(true);
    const response = await DeleteDocumentApi(row?.DocumentRelationId);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      setloading(false);
      setrow(null);
      handleClose();
      dispatch(fetchDocuments({ type: Type, id: selectedRow?.ID }));
    } else {
      setloading(false);
    }
  };
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography
                variant="h1"
                sx={{ fontSize: "18px !important" }}
              >
                Document Details
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setselectedRow(null);
                  setShowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
              <Button
                onClick={() => {
                  setformModal(true);
                }}
                variant="contained"
              >
                Add Document
              </Button>
            </Box>
            <Box>
              <TableContainer
                sx={{
                  height: "100%",
                  maxHeight: "350px",
                  overflow: "scroll",
                  "::-webkit-scrollbar": { width: "5px" },
                  "::-webkit-scrollbar-thumb": {
                    backgroundColor: "darkgrey",
                  },
                }}
              >
                <Table
                  size="small"
                  stickyHeader
                  sx={{
                    width: "100%",
                    flex: 1,
                    "& .MuiTableCell-root": {
                      border: (theme) => `1px solid ${theme.palette.grey[300]}`,
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Document Title
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Access</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Description
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Image / Document
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allDocuments?.map((item, i) => {
                      console.log(item);
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            background:
                              i % 2 === 0
                                ? (theme) => theme?.palette?.background?.tableBg
                                : "",
                          }}
                          key={item?.id}
                        >
                          <TableCell>{item?.Document?.Type}</TableCell>
                          <TableCell>{item?.DocumentTitle}</TableCell>
                          <TableCell>{item?.Document?.Access}</TableCell>
                          <TableCell>{item?.Description}</TableCell>

                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                //   gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {item?.Document?.Type == "Image" ? (
                                <Link
                                  href={item?.Document?.File}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Tooltip title="View Image">
                                    <IconButton key={i}>
                                      <ImageIcon
                                        fontSize="small"
                                        color="primary"
                                        sx={{
                                          fontSize: "16px",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              ) : (
                                <Link
                                  href={item?.Document?.File}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Tooltip title="View Document">
                                    <IconButton key={i}>
                                      <ArticleIcon
                                        fontSize="small"
                                        color="primary"
                                        sx={{
                                          fontSize: "16px",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                //   gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip title="Delete Document">
                                <IconButton
                                  onClick={() => {
                                    setrow(item);
                                    handleClickOpen();
                                  }}
                                >
                                  <DeleteForeverIcon
                                    fontSize="small"
                                    sx={{
                                      color: (theme) =>
                                        theme?.palette?.primary?.delete,
                                      fontSize: "18px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Document
          {/* {` ${row?.DocumentTitle} ?`} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              Are you Sure you want to delete
              <Typography
                sx={{ paddingLeft: "5px", fontWeight: "bold" }}
                component={"span"}
              >
                {row?.DocumentTitle}?
              </Typography>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "#cc6666 !important" }}
            variant="contained"
            onClick={handleClose}
          >
            No
          </Button>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              onClick={handleDelete}
              autoFocus
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DocumentDetailModal;
DocumentDetailModal.propTypes = {
  showModal: PropTypes.any,
  setShowModal: PropTypes.any,
  setselectedRow: PropTypes.any,
  setformModal: PropTypes.any,
  DocumentsData: PropTypes.any,
  selectedRow: PropTypes.any,
  Type: PropTypes.any,
};
