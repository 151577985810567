import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import PropTypes from "prop-types";
import AppTooltip from "../../../../Utils/AppTooltip";
import {
  fetchLeadsDescriptionData,
  getLeadsDescription,
} from "../../../../Redux/Slice/Settings/DescriptionSlice/DescriptionSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppConfirmationDialog from "../../../AppConfirmationDialog/AppConfirmationDialog";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { DeleteLeadDescriptionApi } from "../../../../Api/LeadsApi/LeadsDescriptionApis";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";

function Descriptions({ roles }) {
  const { header } = useStyles();
  const { totalRows } = useContext(PageNumberContext);
  const [pagesize, setpagesize] = useState(totalRows);
  const [rows, setrows] = useState([]);
  const [selectedDescriptionId, setSelectedDescriptionId] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { LeadsDescriptionData, status } = useSelector(getLeadsDescription);

  useEffect(() => {
    dispatch(fetchLeadsDescriptionData());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Leads Description" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns =
    roles?.some((role) => role === "Descriptions_Edit") ||
    roles?.some((role) => role === "Descriptions_Delete")
      ? [
          {
            field: "id",
            headerName: "Sr",
            headerClassName: header,
            width: 50,
          },
          {
            field: "title",
            headerName: "Title",
            headerClassName: header,
            width: 250,
          },
          {
            field: "description",
            headerName: "Description",
            headerClassName: header,
            width: 320,
            renderCell: (params) => (
              <AppTooltip title={params.row?.description}>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    lineHeight: 1.43,
                  }}
                >
                  {params.row?.description}
                </Typography>
              </AppTooltip>
            ),
          },
          {
            field: "action",
            headerName: "Action",
            headerClassName: header,
            width: 120,
            renderCell: (params) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {roles?.some((role) => role === "Descriptions_Edit") && (
                    <AppTooltip title="Edit">
                      <IconButton
                        onClick={() => {
                          navigate("/settings/descriptions/create", {
                            state: params.row?.rowData,
                          });
                        }}
                      >
                        <EditIcon
                          fontSize="small"
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                          }}
                        />
                      </IconButton>
                    </AppTooltip>
                  )}

                  {roles?.some((role) => role === "Descriptions_Delete") && (
                    <AppTooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setSelectedDescriptionId(
                            params.row?.leadDescriptionId
                          );
                          setShowConfirmationDialog(true);
                        }}
                      >
                        <DeleteForeverIcon
                          fontSize="small"
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                          }}
                        />
                      </IconButton>
                    </AppTooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : [
          {
            field: "id",
            headerName: "Sr",
            headerClassName: header,
            width: 50,
          },
          {
            field: "title",
            headerName: "Title",
            headerClassName: header,
            width: 250,
          },
          {
            field: "description",
            headerName: "Description",
            headerClassName: header,
            width: 320,
            renderCell: (params) => (
              <AppTooltip title={params.row?.description}>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    lineHeight: 1.43,
                  }}
                >
                  {params.row?.description}
                </Typography>
              </AppTooltip>
            ),
          },
        ];

  useEffect(() => {
    let arr = [];
    if (LeadsDescriptionData) {
      LeadsDescriptionData?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          leadDescriptionId: item?.LeadDescriptionId,
          title: item?.Title,
          description: item?.Description,
          rowData: item,
        });
      });
    }
    setrows(arr);
  }, [LeadsDescriptionData]);

  const closeConfirmationDialog = () => {
    setSelectedDescriptionId(null);
    setShowConfirmationDialog(false);
  };

  const handleDeleteDescription = async () => {
    setLoading(true);
    const response = await DeleteLeadDescriptionApi(selectedDescriptionId);
    setLoading(false);
    setsnackBarData(response.snackBarData);

    if (response?.data?.IsSuccess) {
      dispatch(fetchLeadsDescriptionData());
      closeConfirmationDialog();
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 77px)",
          overflowX: "visible",
          backgroundColor: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pagesize}
          onPageSizeChange={(newPage) => {
            setpagesize(newPage);
          }}
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => CustomNoRowsOverlay(),
          }}
          disableSelectionOnClick
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
        {status === "loading" ? <Loader /> : null}
      </Box>

      <AppConfirmationDialog
        open={showConfirmationDialog}
        onDeny={closeConfirmationDialog}
        onConfirm={() => {
          handleDeleteDescription();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Description"
        title={`Are you sure, you want to Delete Description?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />
    </>
  );
}

export default Descriptions;
Descriptions.propTypes = {
  roles: PropTypes.array,
};

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
