import * as React from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import {
  fetchPersons,
  getPersonsDetails,
} from "../../../Redux/Slice/PersonSlice/PersonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PersonFilterDrawer from "../../../Components/Contacts/Persons/PersonFilterDrawer/PersonFilterDrawer";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { useContext } from "react";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import { DeletePersonApi } from "../../../Api/CreatePersonApi.jsx/DeletePersonApi";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import DocumentModal from "../../../Components/DocumentModal/DocumentModal";
import DocumentDetailModal from "../../../Components/DocumentModal/DocumentDetailModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArticleIcon from "@mui/icons-material/Article";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  fetchDocuments,
  getOrganizationDetails,
} from "../../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import EmailInfoModal from "../../../Components/Contacts/Persons/EmailInfoModal";
import { AppContext } from "../../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import AppTooltip from "../../../Utils/AppTooltip";
// import Cookies from "universal-cookie";
import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
import { RemoveSCFromInputs } from "../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../Components/AppConfirmationDialog/AppConfirmationDialog";
import CustomerDetailModal from "../../../Components/Activities/CustomerDetailsModal/CustomerDetailsModal";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

const Persons = ({ roles, screen }) => {
  const steps = [
    {
      target: "#person",
      content: (
        <>
          <p size="large">
            {screen === "Customer" ? "Persons" : "Affiliates"} Contacts Page
          </p>
          <p>
            Welcome to the {screen === "Customer" ? "Persons" : "Affiliates"}{" "}
            Screen – your comprehensive directory of individuals. This table
            captures essential details about each{" "}
            {screen === "Customer" ? "person" : "affiliate"} in your network.
            <br />
            Effortlessly manage your network with the intuitive table. Use
            filters and sorting options to streamline your search. Click on any
            {screen === "Customer" ? "person" : "affiliate"}&apos;s name to
            access detailed information.
            <br />
            <br /> Need to add a new{" "}
            {screen === "Customer" ? "person" : "affiliate"}? Click
            &apos;New&apos; to expand your network. Keep your{" "}
            {screen === "Customer" ? "person" : "affiliate"} records up-to-date
            and foster meaningful connections.
          </p>
        </>
      ),
      disableBeacon: true,
    },
    {
      target: "#person-actions",
      content: (
        <>
          <p size="large">Person Actions</p>
          <p>
            Edit: Refine and update{" "}
            {screen === "Customer" ? "person" : "affiliate"} details.
            <br />
            Delete: Remove unnecessary records.
            <br />
            View Document: Access and review associated documents.
          </p>
        </>
      ),
      disableBeacon: true,
    },
  ];

  const { header } = useStyles();
  const { totalRows } = useContext(PageNumberContext);
  const [personsData, setPersonsData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [rows, setrows] = useState([]);
  const [allPersons, setallPersons] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showLoading, setshowLoading] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [documentDetailModal, setdocumentDetailModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [personInfoModal, setpersonInfoModal] = useState(false);
  const [personRow, setpersonRow] = useState(null);
  const [email, setemail] = useState(null);
  const [emailorContact, setemailorContact] = useState("");
  const [msg, setMsg] = useState(null);
  const [count, setcount] = useState(0);
  const [pageSize] = useState(totalRows);

  const [filterVal, setfilterVal] = useState({
    id: "",
    name: "",
    email: "",
    contact: "",
    organization: "",
    // city: "",
    tag: "",
  });

  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");

  const navigate = useNavigate();
  const { setState, state } = useContext(AppContext);

  const dispatch = useDispatch();
  const { Persons, status } = useSelector(getPersonsDetails);
  const { DocumentsData, status2 } = useSelector(getOrganizationDetails);
  useEffect(() => {
    if (screen === "Customer") {
      dispatch(
        fetchPersons({
          type: "Person",
          queryParams: { PageNumber: pageNo, PageSize: pageSize },
        })
      );
    } else {
      dispatch(
        fetchPersons({
          type: "Affiliate",
          queryParams: { PageNumber: pageNo, PageSize: pageSize },
        })
      );
    }
  }, [dispatch, screen]);

  useEffect(() => {
    if (screen === "Customer") {
      setPersonsData([]);

      dispatch(
        fetchPersons({
          type: "Person",
          queryParams: { PageNumber: 1, PageSize: pageSize },
        })
      );
    } else {
      setPersonsData([]);
      dispatch(
        fetchPersons({
          type: "Affiliate",
          queryParams: { PageNumber: 1, PageSize: pageSize },
        })
      );
    }
  }, [dispatch, screen]);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Person");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);

  useEffect(() => {
    let arr = [];
    if (personsData) {
      personsData?.forEach((person, idx) => {
        let Email = person?.PersonEmails.map((email) => {
          return email?.Email;
        });
        let Contact = person?.PersonContacts.map((contct) => {
          return contct?.ContactNumber;
        });
        return arr.push({
          id: idx + 1,
          name: person?.Name,
          contact: Contact?.toString(),
          allContact: person.PersonContacts,
          allEmails: person.PersonEmails,
          organization: person?.OrganizationName,
          organizationID: person?.OrganizationId,
          email: Email?.toString(),
          city: person?.City,
          ID: person.Id,
          Image: person.Image,
          ImageName: person.ImageName,
          IsDocument: person.IsDocument,
          Tag: person?.Tag,
          rowData: person,
        });
      });
    }
    setrows(arr);
    setallPersons(arr);
    setTotalCount(Persons?.TotalRecords);
  }, [personsData]);

  useEffect(() => {
    if (
      filterVal.id === "" &&
      filterVal.name === "" &&
      filterVal.email === "" &&
      filterVal.contact === "" &&
      filterVal.organization === "" &&
      // filterVal.city === "" &&
      filterVal.tag === ""
    ) {
      setallPersons(rows);
      setTotalCount(Persons?.TotalRecords);
    } else {
      const contact = parseFloat(filterVal?.contact.replace(/" "/g, ""));
      let suggestions = [];
      let regex1 = new RegExp(filterVal.id, "i");
      let regex2 = new RegExp(filterVal.name, "i");
      let regex3 = new RegExp(filterVal.email, "i");
      let regex4 = new RegExp(contact, "i");
      let regex5 = new RegExp(filterVal.organization, "i");
      // let regex6 = new RegExp(filterVal?.city, "i");
      let regex7 = new RegExp(filterVal?.tag, "i");

      suggestions = rows?.filter(
        (val) =>
          (filterVal.id === "" || regex1.test(val.id)) &&
          (filterVal.name === "" || regex2.test(val?.name)) &&
          (filterVal.email === "" || regex3.test(val?.email)) &&
          (filterVal.contact === "" ||
            regex4.test(val?.contact.replace(/" "/g, ""))) &&
          (filterVal.organization === "" || regex5.test(val?.organization)) &&
          // (filterVal.city === "" || regex6.test(val?.city)) &&
          (filterVal.tag === "" || regex7.test(val?.Tag?.TagId))
      );
      setallPersons(suggestions);
      // setTotalCount(suggestions?.length);
    }
  }, [filterVal, rows]);

  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: "30%" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "end",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "2px",
                    backgroundColor: "green",
                    cursor: "pointer",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "14px" }}>Available</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "2px",
                    backgroundColor: "#dd284c",
                    cursor: "pointer",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "14px" }}>Not Available</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns =
    sessionStorage.getItem("BusinessType") === "General"
      ? [
          {
            field: "id",
            headerName: "Sr",
            width: 100,
            headerClassName: header,
          },
          {
            field: "name",
            headerName: "Name",
            width: 160,
            headerClassName: header,
          },
          {
            field: "contact",
            headerName: "Contact No",
            width: 250,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <>
                  <AppTooltip title={"Click to View All Contacts"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setemailorContact("contact");
                        setemail(celval.row);
                        setShowModal2(true);
                      }}
                    >
                      {celval.row?.allContact?.map((contact, idx) => (
                        <Chip key={idx} label={`+${contact?.ContactNumber}`} />
                      ))}
                    </Box>
                    {/* <Typography
                key={i}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setemailorContact("contact");
                  setemail(celval.row);
                  setShowModal2(true);
                }}
              >
                {celval.row?.contact}
              </Typography> */}
                  </AppTooltip>
                </>
              );
            },
          },
          {
            field: "email",
            headerName: "Email",
            width: 280,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <>
                  <AppTooltip title={"Click to View All Emails"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setemailorContact("email");
                        setemail(celval.row);
                        setShowModal2(true);
                      }}
                    >
                      {celval.row?.allEmails?.map((email, idx) => (
                        <Chip key={idx} label={email?.Email} />
                      ))}
                    </Box>
                    {/* <Typography
                key={i}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setemailorContact("email");
                  setemail(celval.row);
                  setShowModal2(true);
                }}
              >
                {celval.row?.email}
              </Typography> */}
                  </AppTooltip>
                </>
              );
            },
          },
          {
            field: "organization",
            headerName: "Organization Name",
            width: 200,
            headerClassName: header,
          },
          {
            field: "Tag",
            headerName: "Tag",
            width: 100,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                celval?.formattedValue && (
                  <Chip
                    sx={{
                      backgroundColor: celval.formattedValue?.Color,
                      color: "#fff",
                    }}
                    label={celval?.formattedValue?.Name}
                  />
                )
              );
            },
          },
          {
            field: "IsDocument",
            headerName: "Documents",
            width: 100,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <>
                  <AppTooltip
                    title={
                      celval?.row?.IsDocument == true
                        ? "Docements Available"
                        : "No Docements"
                    }
                  >
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "2px",
                        backgroundColor:
                          celval?.row?.IsDocument == true ? "green" : "#dd284c",
                        cursor: "pointer",
                      }}
                      key={i}
                    ></Box>
                  </AppTooltip>
                </>
              );
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            width: 170,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <Box id="person-actions" key={i}>
                  <Tooltip title="View Details">
                    <IconButton
                      key={i}
                      onClick={() => {
                        setpersonRow(celval.row?.rowData);
                        setpersonInfoModal(true);
                        // dispatch(fetchSingleOrganization(celval.row?.ID));
                      }}
                    >
                      <VisibilityIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary.main,
                          fontSize: "18px",
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>

                  {roles?.some((role) => role === "Persons_Edit") && (
                    <Tooltip title="Edit">
                      <IconButton
                        key={i}
                        onClick={() => {
                          handleEdit(celval.row);
                        }}
                      >
                        <EditIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                            fontSize: "18px",
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {roles?.some((role) => role === "Persons_Delete") && (
                    <Tooltip title="Delete">
                      <IconButton
                        key={i}
                        onClick={() => {
                          setshowConfirmationIcons(true);
                          setselectedId(celval.row.ID);
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                            fontSize: "18px",
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title="View Document">
                    <IconButton
                      key={i}
                      onClick={async () => {
                        // setShowModal(true);
                        let response;
                        if (screen === "Customer") {
                          response = await dispatch(
                            fetchDocuments({
                              type: "Persons",
                              id: celval.row?.ID,
                            })
                          );
                        } else {
                          response = await dispatch(
                            fetchDocuments({
                              type: "Affiliate",
                              id: celval.row?.ID,
                            })
                          );
                        }

                        if (response?.payload?.data?.IsSuccess == true) {
                          setdocumentDetailModal(true);
                        } else {
                          setsnackBarData({
                            type: "error",
                            message: "Something wrong",
                            openToast: true,
                          });
                        }

                        // setdocumentDetailModal(true);
                        setselectedRow(celval.row);
                        // setselectedId(celval.row.ID);
                      }}
                    >
                      <ArticleIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.main,
                          fontSize: "18px",
                        }}
                        fontSize="small"
                      />
                      {/* <VisibilityIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.main,
                        }}
                        fontSize="small"
                      /> */}
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            },
          },
        ]
      : [
          {
            field: "id",
            headerName: "Sr",
            width: 100,
            headerClassName: header,
          },
          {
            field: "name",
            headerName: "Name",
            width: 160,
            headerClassName: header,
          },
          {
            field: "contact",
            headerName: "Contact No",
            width: 250,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <>
                  <AppTooltip title={"Click to View All Contacts"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setemailorContact("contact");
                        setemail(celval.row);
                        setShowModal2(true);
                      }}
                    >
                      {celval.row?.allContact?.map((contact, idx) => (
                        <Chip key={idx} label={`+${contact?.ContactNumber}`} />
                      ))}
                    </Box>
                    {/* <Typography
                key={i}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setemailorContact("contact");
                  setemail(celval.row);
                  setShowModal2(true);
                }}
              >
                {celval.row?.contact}
              </Typography> */}
                  </AppTooltip>
                </>
              );
            },
          },
          {
            field: "email",
            headerName: "Email",
            width: 280,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                <>
                  <AppTooltip title={"Click to View All Emails"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setemailorContact("email");
                        setemail(celval.row);
                        setShowModal2(true);
                      }}
                    >
                      {celval.row?.allEmails?.map((email, idx) => (
                        <Chip key={idx} label={email?.Email} />
                      ))}
                    </Box>
                    {/* <Typography
                key={i}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setemailorContact("email");
                  setemail(celval.row);
                  setShowModal2(true);
                }}
              >
                {celval.row?.email}
              </Typography> */}
                  </AppTooltip>
                </>
              );
            },
          },
          {
            field: "Tag",
            headerName: "Tag",
            width: 100,
            headerClassName: header,
            renderCell: (celval) => {
              return (
                celval?.formattedValue && (
                  <Chip
                    sx={{
                      backgroundColor: celval.formattedValue?.Color,
                      color: "#fff",
                    }}
                    label={celval?.formattedValue?.Name}
                  />
                )
              );
            },
          },
          {
            field: "IsDocument",
            headerName: "Documents",
            width: 100,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <>
                  <AppTooltip
                    title={
                      celval?.row?.IsDocument == true
                        ? "Docements Available"
                        : "No Docements"
                    }
                  >
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "2px",
                        backgroundColor:
                          celval?.row?.IsDocument == true ? "green" : "#dd284c",
                        cursor: "pointer",
                      }}
                      key={i}
                    ></Box>
                  </AppTooltip>
                </>
              );
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            width: 170,
            headerClassName: header,
            renderCell: (celval, i) => {
              return (
                <Box id="person-actions" key={i}>
                  <Tooltip title="View Details">
                    <IconButton
                      key={i}
                      onClick={() => {
                        setpersonRow(celval.row?.rowData);
                        setpersonInfoModal(true);
                        // dispatch(fetchSingleOrganization(celval.row?.ID));
                      }}
                    >
                      <VisibilityIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary.main,
                          fontSize: "18px",
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>

                  {roles?.some((role) => role === "Persons_Edit") && (
                    <Tooltip title="Edit">
                      <IconButton
                        key={i}
                        onClick={() => {
                          handleEdit(celval.row);
                        }}
                      >
                        <EditIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.edit,
                            fontSize: "18px",
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {roles?.some((role) => role === "Persons_Delete") && (
                    <Tooltip title="Delete">
                      <IconButton
                        key={i}
                        onClick={() => {
                          setselectedId(celval.row.ID);
                          setshowConfirmationIcons(true);
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{
                            color: (theme) => theme?.palette?.primary?.delete,
                            fontSize: "18px",
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title="View Document">
                    <IconButton
                      key={i}
                      onClick={async () => {
                        // setShowModal(true);
                        let response;
                        if (screen === "Customer") {
                          response = await dispatch(
                            fetchDocuments({
                              type: "Persons",
                              id: celval.row?.ID,
                            })
                          );
                        } else {
                          response = await dispatch(
                            fetchDocuments({
                              type: "Affiliate",
                              id: celval.row?.ID,
                            })
                          );
                        }
                        if (response?.payload?.data?.IsSuccess == true) {
                          setdocumentDetailModal(true);
                        } else {
                          setsnackBarData({
                            type: "error",
                            message: "Something wrong",
                            openToast: true,
                          });
                        }

                        // setdocumentDetailModal(true);
                        setselectedRow(celval.row);
                        // setselectedId(celval.row.ID);
                      }}
                    >
                      <ArticleIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.main,
                          fontSize: "18px",
                        }}
                        fontSize="small"
                      />
                      {/* <VisibilityIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.main,
                        }}
                        fontSize="small"
                      /> */}
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            },
          },
        ];

  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };

  const handleClosed = () => {
    setemailorContact("");
    setemail(null);
    setShowModal2(false);
  };

  const handleEdit = (val) => {
    navigate(
      `/contacts/${
        screen === "Customer"
          ? "persons/createPerson"
          : "affiliate/createAffiliate"
      }`,
      {
        state: val,
      }
    );
  };

  const deletePerson = async (id) => {
    setshowLoading(true);
    let res;
    if (screen === "Customer") {
      res = await DeletePersonApi(id, "Person");
    } else {
      res = await DeletePersonApi(id, "Affiliate");
    }
    setshowLoading(false);

    if (res.data.IsSuccess) {
      if (screen === "Customer") {
        dispatch(
          fetchPersons({
            type: "Person",
            queryParams: { PageNumber: pageNo, PageSize: pageSize },
          })
        );
      } else {
        dispatch(
          fetchPersons({
            type: "Affiliate",
            queryParams: { PageNumber: pageNo, PageSize: pageSize },
          })
        );
      }

      setselectedId(null);
      setshowConfirmationIcons(false);
    } else if (res.data.Error === "None") {
      setMsg(res.data.Message);
    }
    setsnackBarData(res.snackBarData);
  };
  console.log("Persons", Persons);
  console.log("personsData", personsData);

  useEffect(() => {
    if (Persons) {
      let arr = [...personsData];
      Persons?.data?.forEach((person) => {
        const index = arr.findIndex((obj) => obj?.Id === person?.Id);
        if (index !== -1) {
          // if item is found
          arr[index] = person; // replace item at the found index
        } else {
          arr.push(person);
        }
      });
      setPersonsData([...arr]);
    } else {
      setPageNo(1);
      setPersonsData([]);
    }
  }, [Persons]);
  const searchRefreshData = (action) => {
    setPageNo(1);
    setPersonsData([]);
    if (action === "search") {
      dispatch(
        fetchPersons({
          type: screen === "Customer" ? "Person" : "Affiliate",
          queryParams: {
            PageNumber: 1,
            PageSize: pageSize,
            name: filterVal?.name,
            email: filterVal?.email,
            contactNumber: filterVal?.contact,
            tagId: filterVal?.tag,
          },
        })
      );
    } else {
      dispatch(
        fetchPersons({
          type: screen === "Customer" ? "Person" : "Affiliate",
          queryParams: {
            PageNumber: 1,
            PageSize: pageSize,
            name: filterVal?.name || null,
            email: filterVal?.email || null,
            contactNumber: filterVal?.contact || null,
            tagId: filterVal?.tag || null,
          },
        })
      );
    }
  };
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Person"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>

      <Box id="person">
        <PersonFilterDrawer
          handleChangeVal={handleChangeVal}
          rows={rows}
          setcount={setcount}
          setallPersons={setallPersons}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
          setTotalCount={setTotalCount}
          searchRefreshData={searchRefreshData}
        />

        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            rows={allPersons}
            columns={columns}
            page={pageNo - 1}
            rowCount={totalCount}
            onPageChange={(e) => {
              if (
                personsData.length < pageSize * (e + 1) &&
                personsData.length < Persons?.TotalRecords
              ) {
                dispatch(
                  fetchPersons({
                    // id: defaultpipline?.Id,
                    type: screen === "Customer" ? "Person" : "Affiliate",
                    queryParams: { PageNumber: e + 1, PageSize: pageSize },
                  })
                );
              }
              setPageNo(e + 1);
            }}
          />
          {status === "loading" || status2 === "loading" || showLoading ? (
            <Loader />
          ) : null}
        </Box>
        {/* {personInfoModal && (
          <PersonInfoModal
            personRow={personRow}
            setpersonRow={setpersonRow}
            setpersonInfoModal={setpersonInfoModal}
            personInfoModal={personInfoModal}
          />
        )} */}
        <CustomerDetailModal
          open={personInfoModal}
          onClose={() => {
            setpersonRow(null);
            setpersonInfoModal(false);
          }}
          title={`${screen} Details`}
          data={personRow}
        />
        {showModal && (
          <DocumentModal
            setselectedRow={setselectedRow}
            selectedRow={selectedRow}
            Type={screen === "Customer" ? "Persons" : "Affiliate"}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        )}
        {documentDetailModal && (
          <DocumentDetailModal
            DocumentsData={DocumentsData}
            setselectedRow={setselectedRow}
            selectedRow={selectedRow}
            Type={screen === "Customer" ? "Persons" : "Affiliate"}
            setShowModal={setdocumentDetailModal}
            setformModal={setShowModal}
            showModal={documentDetailModal}
          />
        )}
        <EmailInfoModal
          showModal={showModal2}
          handleClosed={handleClosed}
          email={email}
          emailorContact={emailorContact}
        />
      </Box>

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
          setMsg(null);
        }}
        onConfirm={() => {
          deletePerson(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle={`Delete ${screen}`}
        title={msg ? msg : `Are you sure, you want to Delete ${screen}?`}
        successText="Yes"
        successBtnColor="error"
        cancelText={msg ? "OK" : "No"}
        okButton={msg ? true : false}
      />
    </>
  );
};

export default Persons;

Persons.propTypes = {
  roles: PropTypes.array,
  screen: PropTypes.string,
};

const useStyles = makeStyles(() => {
  return {
    header: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  };
});
