import {
  Box,
  Divider,
  LinearProgress,
  linearProgressClasses,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
// import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import theme from "../../theme";
import AppTooltip from "../../Utils/AppTooltip";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const useStyles = makeStyles(() => {
  return {
    totalBox: {
      display: "flex",
      gap: "5px",
    },
    contentBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "space-between",
      //   padding: "15px",
    },
  };
});
function PiplinesCard({ DashboardData }) {
  const { contentBox, totalBox } = useStyles();

  // const [progress, setProgress] = React.useState(10);
  return (
    <Box
      sx={{
        width: "33%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
      id="dashboard-pipeline"
    >
      <Paper elevation={3} sx={{ height: "350px" }}>
        <Typography sx={{ padding: "15px", fontWeight: "bold" }}>
          Piplines
        </Typography>
        <Divider sx={{ width: "100%" }} />
        {DashboardData?.Pipelines?.length > 0 ? (
          <Box
            sx={{
              padding: "15px",
              height: "289px",
              overflow: "scroll",
            }}
          >
            {DashboardData?.Pipelines?.map((item, i) => {
              return (
                <Box key={i} className={contentBox}>
                  <Box
                    sx={{
                      width: "80px",
                    }}
                  >
                    <AppTooltip title={item?.PipelineName}>
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        // title={"item?.PipelineName"}
                      >
                        {item?.PipelineName}
                      </Typography>
                    </AppTooltip>
                  </Box>
                  <BorderLinearProgress
                    variant="determinate"
                    sx={{ width: "100%" }}
                    value={(item?.Total / DashboardData?.TotalPipeline) * 100}
                  />

                  <Box className={totalBox}>
                    <Typography>{item?.Total}</Typography>
                    <Typography>/</Typography>
                    <Typography>{DashboardData?.TotalPipeline}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "60%",
              display: "flex",
              gap: "10px",
              padding: "10px",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            {CustomNoRowsOverlay("No Data Available")}
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default PiplinesCard;
PiplinesCard.propTypes = {
  DashboardData: PropTypes.any,
};
