import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../Context/TourContext/TourContext";
import BookedUnitInfoModal from "../../../Components/Inventory/BookedUnit/BookedUnitInfoModal";
import Loader from "../../../Components/Loader/Loader";
import {
  fetchsalesreport,
  getReportsDetails,
} from "../../../Redux/Slice/ReportSlice/ReportSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import { StripedDataGrid } from "../../../Utils/StrippedDataGrid";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";
import SaleReportchart from "./SaleReportchart";
import SouthIcon from '@mui/icons-material/South';
function SaleReport() {
  const { totalRows } = useContext(PageNumberContext);
  const [pagesize, setpagesize] = useState(totalRows);
  const [allReport, setallReport] = useState([]);
  const { setState, state } = useContext(AppContext);
  const [unitInfoModal, setunitInfoModal] = useState(false);
  const [unitRow, setunitRow] = useState(null);
  const dispatch = useDispatch();
  const { SalesReport, status } = useSelector(getReportsDetails);
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "SaleReport");
    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  const ValidationSchema = Yup.object().shape({
    from: Yup.string().required("Please Enter From Date"),
    to: Yup.string().required("Please Enter To Date"),
  });
  const initialValues = {
    from: "",
    to: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      dispatch(fetchsalesreport(values));
    },
  });
  function CustomToolbar() {
    return (

      <Box sx={{ display: "flex", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "16px", // Add padding to align with right end
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                paddingTop: "5px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <TextField
                id="dashboard-from"
                label="From"
                InputLabelProps={{
                  shrink: true,
                }}
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                size="small"
                type="date"
                error={Boolean(formik.errors.from) && Boolean(formik.touched.from)}
                helperText={
                  Boolean(formik.errors.from) &&
                  Boolean(formik.touched.from) &&
                  formik.errors.from
                }
              />
              <TextField
                name="to"
                id="dashboard-to"
                label="To"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.to}
                variant="outlined"
                size="small"
                type="date"
                error={Boolean(formik.errors.to) && Boolean(formik.touched.to)}
                helperText={
                  Boolean(formik.errors.to) &&
                  Boolean(formik.touched.to) &&
                  formik.errors.to
                }
              />
              <Button
                id="dashboard-get"
                variant="contained"
                className="get"
                type="submit"
              >
                Get
              </Button>
            </Box>
          </form>
        </Box>
      </Box>

    );
  }
  useEffect(() => {
    if (SalesReport?.length === 0)
      dispatch(fetchsalesreport(formik?.values));
  }, [dispatch]);

  useEffect(() => {
    let rowData = [];
    SalesReport.TableData?.map((item, i) => {
      rowData.push({
        id: i + 1,
        ConversionRate: item?.ConversionRate,
        OnsiteLeads: item?.OnsiteLeads,
        WonLeads: item?.WonLeads,
        LeadSource: item?.LeadSource,
        TotalLead: item?.TotalLeads,
        leadtoOnsitePer: item?.leadtoOnsitePer,
        OnsitetoWonPer: item?.OnsitetoWonPer,
      });
    });
    // setrows(rowData);
    setallReport(rowData);
  }, [SalesReport]);
  const columns = [
    {
      field: "id", headerName: "Sr", width: 90,
    },
    {
      field: "LeadSource",
      headerName: "Lead Source",
      width: 250,
    },
    {
      field: "TotalLead",
      headerName: "Total Leads",
      width: 100,
    },
    {
      field: "leadtoOnsitePer",
      headerName: "",
      width: 140,
      renderCell: (params) => (
        <span style={{ color: 'grey' }}>
          {`${Math.floor(params.value)}%`}
        </span>
      ),
    },
    {
      field: "OnsiteLeads",
      headerName: "Onsite Leads",
      width: 100,
    },
    {
      field: "OnsitetoWonPer",
      headerName: "",
      width: 140,
      renderCell: (params) => (
        <span style={{ color: 'grey' }}>
          {`${Math.floor(params.value)}%`}
        </span>
      ),
    },
    {
      field: "ConversionRate",
      headerName: "Conversion Rate",
      width: 150,
    },
    {
      field: "WonLeads",
      headerName: "Won Leads",
      width: 150,
    },
  ];
  return (
    <>
      <Box id="item" sx={{
        height: "calc(100vh - 77px)",
        width: "100%",
        backgroundColor: (theme) => theme.palette.primary.contrastText,
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3 style={{ paddingLeft: '1rem' }}>Sales Funnel</h3>
          <div style={{  }}>
            <CustomToolbar />
          </div>
        </div>
        <div style={{ display: 'flex', }}>
          <div style={{ width: "80%" }}>
            <SaleReportchart />
          </div>
          <div style={{ display: 'flex', flexDirection: "column", marginLeft: "20px" }}>
            <h4 style={{ marginLeft: "57px", color: "grey", }}>{SalesReport.SumTotalLeads}</h4>
             <SouthIcon style={{ transform: 'scaleY(3) scaleX(1)',marginLeft: "57px", color: "grey",fontSize: "20",}}/>
            <p style={{ fontWeight: "bold" }}>
              <span style={{ marginLeft: "55px" }}>{`${SalesReport.SumConversionRate}%`}</span><br></br>
              Conversion Rate
            </p>
            <SouthIcon style={{ transform: 'scaleY(3) scaleX(1)',marginLeft: "57px", color: "grey",fontSize: "20",}}/>
            <h4 style={{ marginLeft: "65px", color: "grey", }}>{SalesReport.SumWonLeads}</h4>
          </div>
        </div>
        <StripedDataGrid style={{ paddingLeft: "18px", backgroundColor: "white", paddingRight: "18px", }}
          pageSize={pagesize}
          onPageSizeChange={(newPage) => {
            setpagesize(newPage);
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            NoRowsOverlay: () => CustomNoRowsOverlay(),
          }}
          rows={allReport}
          columns={columns}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
        />
        {status === "loading" ? <Loader /> : null}
      </Box>
      {unitInfoModal && (
        <BookedUnitInfoModal
          unitRow={unitRow}
          setunitRow={setunitRow}
          setunitInfoModal={setunitInfoModal}
          unitInfoModal={unitInfoModal}
        />
      )}
    </>
  );
}
export default SaleReport;
SaleReport.propTypes = {
  roles: PropTypes.array,
};
