import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, {useState,useEffect} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyle = makeStyles((theme) => {
  return {
    textContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    textAreaContainer: {
      display: "flex",
      //   justifyContent: "start",
      //   alignItems: "start",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
  };
});
function TemplatesInfoModal({ showModal, handleClosed, template }) {
  const { textAreaContainer, textContainer } = useStyle();
  const [templateRowData,settemplateRowData]=useState(null);
  console.log(templateRowData);
//   useEffect(()=>{
// if(template){
//   settemplateRowData(template);
// }
//   },[template]);
  console.log(template);
  useEffect(() => {
    if(template){

      let updatedString = template?.body;
      // console.log(Placeholders);
      console.log(updatedString);

      template?.Placeholders?.map((search) => {
        // if(search?.Title){
        updatedString = updatedString.replace(new RegExp(search?.Key, 'g'), `{ ${search?.Title} }`);
        console.log(updatedString);
      // }
      });
      console.log(updatedString);
      settemplateRowData({...template,body:updatedString});
    }

  }, [template?.Placeholders]);
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            Templates Info
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <Box className={textContainer}>
          <Box className={textAreaContainer}>
            <Typography variant="body1" fontWeight="bold">
              Title:
            </Typography>
            <Typography>{templateRowData?.title}</Typography>
          </Box>
          <Box className={textAreaContainer}>
            <Typography variant="body1" fontWeight="bold">
              Type:
            </Typography>
            <Typography>{templateRowData?.type}</Typography>
          </Box>
        </Box>
        <Typography variant="body1" fontWeight="bold">
          Body:
        </Typography>
        <Box border="1px solid #bdbdbd" borderRadius="10px" padding="8px">
          <Typography dangerouslySetInnerHTML={{ __html: templateRowData?.body }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default TemplatesInfoModal;

TemplatesInfoModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  template: PropTypes.any,
};
