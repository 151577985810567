import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  // OutlinedInput,
  Paper,
  TextField,
  Typography,
  Breadcrumbs,
  FormHelperText,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theme from "../../theme";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { UpdateProfileApi } from "../../Api/Profile/UpdateProfileApi";
import {
  fetchUserProfile,
  getUserProfile,
} from "../../Redux/Slice/Profile/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../Components/Loader/Loader";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
  };
});
function UpdateProfile() {
  const { container, subContainer, formContainer } = useStyle();
  const initialValues = {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    image: null,
    imageName: null,
    oldPassword: "",
    dob: "",
  };
  const { UserProfile, status } = useSelector(getUserProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (UserProfile) {
      formik.setFieldValue("fullName", UserProfile?.FullName);
      formik.setFieldValue("email", UserProfile?.Email);
      formik.setFieldValue(
        "dob",
        moment(UserProfile?.Dob).format("YYYY-MM-DD")
      );
      formik.setFieldValue("image", UserProfile?.Image);
      formik.setFieldValue("imageName", UserProfile?.ImageName);
      const imagePreview = document.getElementById("image-preview");
      if (UserProfile?.Image) {
        imagePreview.src = UserProfile?.Image;
      }
    }
  }, [UserProfile]);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);
  const { setsnackBarData } = useContext(SnackBarContext);

  const [showPassword, setshowPassword] = useState(false);
  const [showOldPassword, setshowOldPassword] = useState(false);

  const [confirmpass, setconfirmpass] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values, setSubmitting) => {
    // setSubmitting(true);
    console.log("values", values);

    if (values.oldPassword || values.password || values.currentPassword) {
      if (values.oldPassword === "") {
        formik.setFieldError("oldPassword", "Please Enter Old Password");
        return;
      } else if (values.password === "") {
        formik.setFieldError("password", "Please Enter Password");
        return;
      } else if (values?.currentPassword === "") {
        formik.setFieldError(
          "currentPassword",
          "Please Enter Current Password"
        );
        return;
      }
    }
    let formData = new FormData();
    formData.append("FullName", values.fullName);
    formData.append("Email", values.email);
    formData.append("ImageName", values.imageName);
    formData.append("Image", values.image);
    formData.append("DOB", values.dob);
    formData.append("OldPassword", values.oldPassword);
    formData.append("NewPassword", values.password);
    formData.append("ConfirmPassword", values.confirmPassword);

    const response = await UpdateProfileApi(formData);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      // dispatch(fetchProducts());
      setSubmitting(false);
      // resetForm();
      dispatch(fetchUserProfile());
    }
    setSubmitting(false);
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please Enter Full Name"),
    email: Yup.string().required("Please Enter Email"),
    password: Yup.string()
      .min(8, "Password must be 6 character long")
      .matches(/[A-Z]/, "Must have at least one uppercase character"),

    confirmPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both Password must be the same"
      ),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
  });
  const handleClearImage = () => {
    // formik.setValues({ image: null });
    formik.setFieldValue("image", null);
    formik.setFieldValue("imageName", null);
    document.getElementById("imageInput").value = null;
    const imagePreview = document.getElementById("image-preview");
    if (imagePreview) {
      imagePreview.src = null;
    }
  };
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box role="presentation" onClick={(e) => e.preventDefault()}>
          <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
            <Link
              to="/dashboard"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Dashboard
            </Link>

            <Typography color="text.primary" sx={{ fontSize: "12px" }}>
              {"Update Profile"}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
          {"Update Profile"}
        </Typography>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={0}
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="imageInput"
                    type="file"
                    onChange={(event) => {
                      const selectedFile = event.currentTarget.files[0];

                      formik.setFieldValue("image", selectedFile);

                      const imagePreview =
                        document.getElementById("image-preview");
                      if (imagePreview && selectedFile) {
                        imagePreview.src = URL.createObjectURL(selectedFile);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {(formik.values.image || formik.values.imageName) && (
                    <Button
                      onClick={handleClearImage}
                      style={{
                        // position: "absolute",
                        top: "15px",
                        left: "45px",
                        border: "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      color="primary"
                    >
                      <HighlightOffIcon />
                    </Button>
                  )}
                  <Box mt={0} alignItems="center">
                    <img
                      id="image-preview"
                      alt="Not Selected"
                      width="80px"
                      height="80px"
                      style={{
                        display:
                          formik.values.image || formik.values.imageName
                            ? ""
                            : "none",
                        borderRadius: "100%",
                      }}
                    />
                    <Avatar
                      sx={{
                        display:
                          formik.values.image || formik.values.imageName
                            ? "none"
                            : "",
                        width: "80px",
                        height: "80px",
                      }}
                    ></Avatar>
                  </Box>

                  <label htmlFor="imageInput">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        mt: 1,

                        textTransform: "none",
                      }}
                    >
                      {"Update Image"}
                    </Button>
                  </label>
                  {formik.touched.image ? (
                    <Typography color="error">{formik.errors.image}</Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </FormControl>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Name</Typography>
                  <TextField
                    name="fullName"
                    type="text"
                    autocomplete="off"
                    value={formik.values?.fullName}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.fullName) &&
                      (Boolean(formik.touched.fullName) ||
                        formik.submitCount > 0)
                    }
                    helperText={
                      Boolean(formik.errors.fullName) &&
                      (Boolean(formik.touched.fullName) ||
                        formik.submitCount > 0)
                        ? formik.errors.fullName
                        : ""
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Date of Birth</Typography>
                  <TextField
                    name="dob"
                    type={"date"}
                    value={formik.values?.dob}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors?.dob) &&
                      Boolean(formik.touched?.dob)
                    }
                    helperText={
                      Boolean(formik.errors?.dob) &&
                      Boolean(formik.touched?.dob) &&
                      formik.errors?.dob
                    }
                  />
                </FormControl>
              </Box>
              <FormControl fullWidth>
                <Typography>Email</Typography>
                <TextField
                  inputProps={{ readOnly: true }}
                  name="email"
                  type="email"
                  value={formik.values?.email}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.email) &&
                    (Boolean(formik.touched.email) || formik.submitCount > 0)
                  }
                  helperText={
                    Boolean(formik.errors.email) &&
                    (Boolean(formik.touched.email) || formik.submitCount > 0)
                      ? formik.errors.email
                      : ""
                  }
                />
              </FormControl>
              <FormControl
                fullWidth
                error={
                  Boolean(formik.errors.oldPassword) &&
                  Boolean(formik.touched.oldPassword)
                  // || formik.submitCount > 0
                }
              >
                <Typography>Old Password</Typography>
                <OutlinedInput
                  autocomplete="new-password"
                  name="oldPassword"
                  type={showOldPassword ? "text" : "password"}
                  value={formik.values?.oldPassword}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.oldPassword) &&
                    Boolean(formik.touched.oldPassword)
                    // || formik.submitCount > 0
                  }
                  helperText={
                    Boolean(formik.errors.oldPassword) &&
                    Boolean(formik.touched.oldPassword)
                      ? //  || formik.submitCount > 0
                        formik.errors.oldPassword
                      : ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setshowOldPassword(!showOldPassword);
                        }}
                        edge="end"
                      >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {Boolean(formik.errors.oldPassword) &&
                  Boolean(formik.touched.oldPassword)
                    ? //  || formik.submitCount > 0
                      formik.errors.oldPassword
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                error={
                  Boolean(formik.errors.password) &&
                  Boolean(formik.touched.password)
                  // || formik.submitCount > 0
                }
              >
                <Typography>New Password</Typography>
                <OutlinedInput
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values?.password}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.password) &&
                    Boolean(formik.touched.password)
                    // || formik.submitCount > 0
                  }
                  helperText={
                    Boolean(formik.errors.password) &&
                    Boolean(formik.touched.password)
                      ? //  || formik.submitCount > 0
                        formik.errors.password
                      : ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {Boolean(formik.errors.password) &&
                  Boolean(formik.touched.password)
                    ? //  || formik.submitCount > 0
                      formik.errors.password
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                error={
                  Boolean(formik.errors.confirmPassword) &&
                  Boolean(formik.touched.confirmPassword)
                  // ||
                  //   formik.submitCount > 0
                }
              >
                <Typography>Confirm New Password</Typography>
                <OutlinedInput
                  name="confirmPassword"
                  type={confirmpass ? "text" : "password"}
                  value={formik.values?.confirmPassword}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.confirmPassword) &&
                    Boolean(formik.touched.confirmPassword)
                    // ||
                    //   formik.submitCount > 0
                  }
                  helperText={
                    Boolean(formik.errors.confirmPassword) &&
                    Boolean(formik.touched.confirmPassword)
                      ? formik.errors.confirmPassword
                      : ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setconfirmpass(!confirmpass);
                        }}
                        edge="end"
                      >
                        {confirmpass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {Boolean(formik.errors.confirmPassword) &&
                  Boolean(formik.touched.confirmPassword)
                    ? formik.errors.confirmPassword
                    : ""}
                </FormHelperText>
              </FormControl>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          color: (theme) => theme.palette.white.main,
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {"Update"}
                        </Typography>{" "}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
      {status === "loading" ? <Loader /> : null}
    </Box>
  );
}

export default UpdateProfile;
