import { Avatar, Box, Checkbox, FormControlLabel, FormGroup, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import CategoryIcon from '@mui/icons-material/Category';
import AppTooltip from "../../Utils/AppTooltip";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "20px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
    },
    iconBox: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
      },
  };
});
function ProductsInfoModal2({
    productInfoModal,
    setproductInfoModal,
    setproductRow,
    productRow,
  }) {
  const { Container, ModalContainer, TableHeader, profile,iconBox } = useStyles();

  return (
    <>
      <Modal
        open={productInfoModal}
        onClose={() => {
          setproductInfoModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Product Details
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setproductRow(null);
                  setproductInfoModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box className={profile}>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "100px",
                    height: "100px",
                    borderRadius: "50%",
                  }}
                >
                    {
                        productRow?.Image?
                    
                  <img
                    src={productRow?.Image}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    alt="Image"
                  />:
                  
                  (
                    <Avatar sx={{ width: "100%", height: "100%" }} src={"./"} />
                  )}
                </Box>
                <Box sx={{width:"100%"}}>
                  <Typography sx={{fontWeight:"bold"}}>{productRow?.name}</Typography>
                  <Box className={iconBox}>
                  <AppTooltip title={"Product Category"}>

                    <CategoryIcon sx={{ fontSize: "20px", color: "grey" ,cursor:"pointer"}} />
                    </AppTooltip>
                    <Typography>{productRow?.ProductCategory}</Typography>
                  </Box>
                  <Box sx={{display:"flex",}}>
                  <Box sx={{width:"100%",maxWidth:"250px"}} className={iconBox}>
                  <AppTooltip title={"Product Cost"}>

                    <AttachMoneyIcon sx={{ fontSize: "20px", color: "grey" ,cursor:"pointer"}} />
                    </AppTooltip>
                    <Typography>{productRow?.cost}</Typography>
                  </Box>
                  <Box className={iconBox}>
                  <AppTooltip title={"Quantity"}>
                    
                    <TimesOneMobiledataIcon sx={{ fontSize: "20px", color: "grey"  ,cursor:"pointer"}} />
                    </AppTooltip>
                    <Typography>{productRow?.quantity}</Typography>
                  </Box>
                  </Box>
                  <Box sx={{display:"flex",}}>

                  <Box sx={{width:"100%",maxWidth:"250px"}} className={iconBox}>
                  <AppTooltip title={"Price"}>

                    <MonetizationOnIcon sx={{ fontSize: "20px", color: "grey" ,cursor:"pointer" }} />
                    </AppTooltip>
                    <Typography>{productRow?.price}</Typography>
                  </Box>
                  <Box className={iconBox}>
                  <AppTooltip title={"Commission"}>

                    <PercentIcon sx={{ fontSize: "20px", color: "grey"  ,cursor:"pointer"}} />
                    </AppTooltip>
                    <Typography>{productRow?.CommissionPercentage}%</Typography>
                  </Box>
       </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Product Code:</Typography>
                  <Typography
                    sx={{
                      paddingLeft: "100px",
                    }}
                  >
                 {productRow?.ProductCode}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>SKU:</Typography>
                  <Typography
                    sx={{
                      paddingLeft: "100px",
                    }}
                  >
                 {productRow?.sku}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>UOM:</Typography>
                  <Typography
                    sx={{
                      paddingLeft: "100px",
                    }}
                  >
                 {productRow?.Uom}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Description:</Typography>
                  <Typography
                    sx={{
                      paddingLeft: "100px",
                    }}
                  >
                 {productRow?.Description}
                  </Typography>
                </Box>
                <FormGroup style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Checkbox name="active" disabled checked={productRow.Active} />
              }
              label="Active"
            />
            <FormControlLabel
              control={<Checkbox name="tax" disabled checked={productRow.Tax} />}
              label="Tax"
            />
          </FormGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ProductsInfoModal2;
ProductsInfoModal2.propTypes = {
    productInfoModal: PropTypes.any,
    setproductInfoModal: PropTypes.any,
    productRow: PropTypes.any,
    setproductRow: PropTypes.any,
  };
  