import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Targets: [],
  TargetPopulate: [],
  AssignTargetPopulate: [],
  AssignTargetsByTarget: [],
  AssignTargetsData: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
  IsLogin: true,
};
export const fetchTargets = createAsyncThunk(
  "/Targets/getAllGroupTargets",
  async () => {
    const response = await axiosInstance.get(`/Targets/getAllGroupTargets`);
    return response;
  }
);
export const fetchTargetPopulate = createAsyncThunk(
  "Targets/populate",
  async () => {
    const response = await axiosInstance.get(`/Targets/populate`);
    return response;
  }
);
export const fetchAssignTargetPopulate = createAsyncThunk(
  "/Targets/populateTargetAssign",
  async (id) => {
    const response = await axiosInstance.get(
      `/Targets/populateTargetAssign?TargetId=${id}`
    );
    return response;
  }
);
export const fetchAssignTargetsByTarget = createAsyncThunk(
  "/Targets/getTargetAssignByTarget",
  async (id) => {
    const response = await axiosInstance.get(
      `/Targets/getTargetAssignByTarget?TargetId=${id}`
    );
    return response;
  }
);
export const fetchAssignedTargetsData = createAsyncThunk(
  "/Targets/getTargetAssign",
  async () => {
    const response = await axiosInstance.get(`/Targets/getTargetAssign`);
    return response;
  }
);
const TargetSlice = createSlice({
  name: "Targets",
  initialState,
  reducers: {
    TargetAdded: {
      reducer(state, action) {
        state.Targets?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchTargets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTargets.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Targets = action.payload.data.Data;
      })
      .addCase(fetchTargets.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchTargetPopulate.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchTargetPopulate.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.TargetPopulate = action.payload.data.Data;
      })
      .addCase(fetchTargetPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status1 = "failed";
      })

      .addCase(fetchAssignTargetPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAssignTargetPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AssignTargetPopulate = action.payload.data.Data;
      })
      .addCase(fetchAssignTargetPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAssignTargetsByTarget.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAssignTargetsByTarget.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AssignTargetsByTarget = action.payload.data.Data;
      })
      .addCase(fetchAssignTargetsByTarget.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAssignedTargetsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAssignedTargetsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AssignTargetsData = action.payload.data.Data;
      })
      .addCase(fetchAssignedTargetsData.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
        state.AssignTargetsData = [];
      });
  },
});

export const getTargetDetails = (state) => state.Targets;

export const { TargetAdded } = TargetSlice.actions;

export default TargetSlice.reducer;
