import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
// import Person2Icon from "@mui/icons-material/Person2";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import OpenWithIcon from "@mui/icons-material/OpenWith";
import PropTypes from "prop-types";
import { fetchLeadByPiplineStage } from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Loader from "../../Loader/Loader";

import { DropBox } from "./DropBox";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";
// import { useDispatch } from 'react-redux';
// import { fetchLeadByPiplineStage } from '../../../Redux/Slice/LeadsSlice/LeadsSlice';

const useStyles = makeStyles(() => {
  return {
    mainBox: {
      display: "flex",
      gap: "30px",
      height: "calc(100vh - 120px)",
      overflow: "auto",
      width: "100%",
      maxWidth: "fit-content !important",
      paddingTop: "10px",
      //  " ::-webkit-scrollbar":{width:"3px",  height:"5px"}
    },
    paperBox: {
      minWidth: "270px !important",
      height: "47rem",
      overflow: "auto",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },

    cardsTop: {
      padding: "10px",
      display: "flex",
      gap: "10px",
      flexDirection: "column",
    },
    cardsIconBox: {
      display: "flex",
      gap: "5px",
      alignItems: "center",
    },
    iconBoxTypo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});
function LeadsCards({
  LeadsByPiplineStage,
  defaultpipline,
  status,
  roles,
  statusPipeline,
}) {
  const { paperBox, mainBox, cardsTop } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultpipline?.Id) {
      dispatch(fetchLeadByPiplineStage(defaultpipline?.Id));
    }
  }, [defaultpipline]);

  return (
    <>
      <Box sx={{}}>
        {LeadsByPiplineStage?.length > 0 ? (
          <Box id="leads-pipelines" className={mainBox}>
            {LeadsByPiplineStage?.map((pipline, mainBoxIdx) => {
              let total = 0;
              pipline?.Leads?.map((lead) => {
                total = lead?.LeadValue + total;
              });

              return (
                <DropBox
                  key={mainBoxIdx}
                  mainBoxIdx={mainBoxIdx}
                  type={`ITEM_TYPE_${mainBoxIdx}}`}
                  pipline={pipline}
                  roles={roles}
                  total={total}
                  cardsTop={cardsTop}
                  paperBox={paperBox}
                  defaultpipline={defaultpipline}
                />
              );
            })}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "70vh",
              display: "flex",
              gap: "10px",
              padding: "10px",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            {CustomNoRowsOverlay("No Data Available")}
          </Box>
        )}
      </Box>

      {status === "loading" || statusPipeline === "loading" ? <Loader /> : null}
    </>
  );
}

export default LeadsCards;
LeadsCards.propTypes = {
  LeadsByPiplineStage: PropTypes.any,
  defaultpipline: PropTypes.any,
  status: PropTypes.any,
  roles: PropTypes.arrays,
  statusPipeline: PropTypes.any,
};
