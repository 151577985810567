import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import theme from '../../theme';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ReactMultiEmail, } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
// import "react-multi-email/style.css";
// const styles = {
//   fontFamily: "sans-serif",
//   width: "500px",
//   border: "1px solid #eee",
//   background: "#f3f3f3",
//   padding: "25px",
//   margin: "20px"
// };
// interface IProps {}
// interface IState {
//   emails: string[];
// }
// interface Props {}
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    attachmentBox: {
      paddingTop: "10px",
      display: "flex", gap: "10px", cursor: "pointer",
    },
    imgMainBox: {
      paddingTop: "10px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: "60px",
      },
    }

    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});
function Compose() {
  const {
    container,
    subContainer,
    formContainer,
    attachmentBox,
    imgMainBox
  } = useStyle();
  const inputRef = useRef();
  const initialValues = {
    to: "",
    subject: "",
    // image: "",

  };
  console.log(initialValues);
  const [value, setValue] = useState('');
  const [validate, setvalidate] = useState(false);
  const [validate2, setvalidate2] = useState(false);
  console.log(value);
  const [image, setimage] = useState(null);
  const [emails, setEmails] = React.useState([]);

  // const [emails,setemails]=useState([]);
  console.log(value.length);
  function removeImage() {
    setimage(null);
  }
  const navigate = useNavigate();
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    if (value.length < 1) {
      setSubmitting(false);
      setvalidate2(true);
      return;
    }
     else if (emails.length < 1) {
      setSubmitting(false);
      setvalidate(true);
      return;
    }
    setvalidate(false);
    setvalidate2(false);
    // setSubmitting(true);
    // const obj = { ...values, country: value?.label };
    console.log(values);
    setSubmitting(false);
    setValue('');
    setEmails([]);
    resetForm();
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    // to: Yup.string()
    //   .email("Please Enter A Valid Email Address")
    //   .required("Please Enter Email"),
    subject: Yup.string().required("Please Enter subject"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    }
  });

  // const [ setFocused] = React.useState(false);
  return (
    <Box className={container}>

      <Box className={subContainer}>
        <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
          Create Compose
        </Typography>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <Typography sx={{}}>To</Typography>
                    <ReactMultiEmail
                      style={{ border: validate ? "1px solid #d32f2f" : null }}
                      name="to"
                      placeholder='Press Enter to add Emails'
                      value={formik.values?.to}
                      emails={emails}
                      onChange={(_emails) => {
                        setEmails(_emails);
                      }}
                      autoFocus={true}
                      // onFocus={() => setFocused(true)}
                      // onBlur={() => setFocused(false)}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </Box>
                  {

                    validate ? (
                      <Typography sx={{ color: "#d32f2f", fontSize: "0.75rem", paddingLeft: "35px" }}>Please Enter Email</Typography>
                    ) : null
                  }
                </Box>
              </FormControl>

              <FormControl fullWidth>
                <Typography>Subject</Typography>
                <TextField
                  name="subject"
                  value={formik.values?.subject}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.subject) && Boolean(formik.touched.subject)}
                  helperText={Boolean(formik.errors.subject) && formik.errors.subject}
                />
              </FormControl>

              <Box sx={{ minHeight: "245px",border: validate2 ?"1px solid #d32f2f":null}}>
                <ReactQuill  style={{ height: "200px", }} value={value} onChange={setValue} />
   
              </Box>
              {

validate2 ? (
  <Typography sx={{ color: "#d32f2f", fontSize: "0.75rem", paddingLeft: "15px" }}>Please Enter Email</Typography>
) : null
}
              <FormControl fullWidth>
                <Box className={imgMainBox}>
                  <input
                    name="image"
                    id="images"
                    ref={inputRef}
                    type="file"
                    onChange={(e) => {
                      formik.handleChange(e);
                      e.stopPropagation();
                      const obj = {
                        file: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                      };
                      setimage(obj);
                    }}
                    value={formik.values.image}
                    style={{
                      width: "100%",
                      display: "none",
                      position: "absolute",
                    }}
                  />
                  {
                    image !== null ? (
                      <Box sx={{ width: "100%", maxWidth: "200px", display: "flex", alignItems: "center", border: (theme) => `2px solid${theme.palette.primary.main}`, justifyContent: "space-evenly", overflow: "hidden" }}>
                        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>{image?.file.name}</Typography>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            removeImage();
                          }}
                        >
                          <CloseIcon
                          />
                        </IconButton>
                      </Box>
                    ) : null
                  }

                  <Box className={attachmentBox} onClick={() => inputRef.current.click()} >
                    <AttachFileIcon />
                    <Typography>Add Attatchment</Typography>
                  </Box>
                </Box>
              </FormControl>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Back
                      </Typography>
                    </Button>
                  </Box>
                  <Box>
                    {
                      formik.isSubmitting ? <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box> : <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          Save
                        </Typography>
                      </Button>
                    }

                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>

    </Box>
  );
}

export default Compose;