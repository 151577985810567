import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import { Divider, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});

function OrganizationFilterDrawer({
  drawerOpen,
  setdrawerOpen,
  filterVal,
  setfilterVal,
  onTextChange,
  setallOrganization,
  setcount,
  rows,
  handleChangeVal,
  name,
  email,
  city,
  phone,
}) {
  const { contentBox, fieldBox } = useStyles();
  const handleClose = () => {
    setdrawerOpen(!open);
  };
  const handleRemoveAll = () => {
    for (const value in filterVal) {
      filterVal[value] = "";
    }
    setcount(0);
  };
  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        // onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {/* <Typography variant='h6'>Filter</Typography> */}
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  setallOrganization(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            <Box>
              <Typography>Name</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="name"
                  variant="outlined"
                  size="small"
                  value={filterVal?.name}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Name"
                  className="input_border"
                  inputRef={name}
                  onChange={(e) => {
                    onTextChange();
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    name.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      name: "",
                    });
                    onTextChange();
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Email</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="email"
                  variant="outlined"
                  size="small"
                  value={filterVal?.email}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Email"
                  className="input_border"
                  inputRef={email}
                  onChange={(e) => {
                    onTextChange();
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    email.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      email: "",
                    });
                    onTextChange();
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Phone</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="phone"
                  variant="outlined"
                  size="small"
                  value={filterVal?.phone}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Phone"
                  className="input_border"
                  inputRef={phone}
                  onChange={(e) => {
                    onTextChange();
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    phone.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      phone: "",
                    });
                    onTextChange();
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>City</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="city"
                  variant="outlined"
                  size="small"
                  value={filterVal?.city}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="City"
                  className="input_border"
                  inputRef={city}
                  onChange={(e) => {
                    onTextChange();
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    city.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      city: "",
                    });
                    onTextChange();
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default OrganizationFilterDrawer;
OrganizationFilterDrawer.propTypes = {
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  onTextChange: PropTypes.any,
  ID: PropTypes.any,
  setallOrganization: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
  name: PropTypes.any,
  email: PropTypes.any,
  phone: PropTypes.any,
  city: PropTypes.any,
};
