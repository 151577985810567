import React from "react";
import PropTypes from "prop-types";
import PlannedCalls from "../Calls/PlannedCalls";
import PlannedMeetings from "../Meetings/PlannedMeetings";
import PlannedLunches from "../Lunches/PlannedLunches";
import PlannedMessages from "../Messages/PlannedMessages";

//   return {
//     plannedBox: {
//       display: "flex",
//       //   flexDirection:"column",
//       gap: "10px",
//       width: "100%",
//       alignItems: "center",
//     },
//     notesubboxMain: {
//       display: "flex",
//       gap: "40px",
//       width: "100%",
//       padding: "0px 10px 0px 20px",
//     },
//     iconlineMain: {
//       width: "2px",
//       minHeight: "100%",
//       // backgroundColor: theme.palette.primary.main,
//       border: `1px dashed ${theme.palette.primary.main}`,
//       position: "relative",
//     },
//     iconBox: {
//       width: "40px",
//       height: "40px",
//       border: `1px solid ${theme?.palette.primary.main}`,
//       transform: "translate(-50%,0%)",
//       position: "absolute",
//       top: "0%",
//       left: "50%",
//       color: theme.palette.primary.main,
//       borderRadius: "50%",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       backgroundColor: "white !important",
//     },
//     noteBox: {
//       display: "flex",
//       flexDirection: "column",
//       gap: "2px",
//       padding: "10px",
//       width: "100%",
//     },
//     scheduleBox: {
//       display: "flex",
//       gap: "5px",
//     },
//     scheduled: {
//       fontSize: "15px !important",
//       color: theme.palette.primary.main,
//     },
//   };
// });
function PlannedAll({ singleLead, roles }) {
  return (
    <>
      <PlannedCalls singleLead={singleLead} roles={roles} />
      <PlannedMeetings singleLead={singleLead} roles={roles} />
      <PlannedLunches singleLead={singleLead} roles={roles} />
      <PlannedMessages singleLead={singleLead} roles={roles} />
      {/* <PlannedMeetings singleLead={singleLead} /> */}
    </>
  );
}

export default PlannedAll;
PlannedAll.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
