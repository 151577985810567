import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { useContext } from "react";
import PropTypes from "prop-types";

import Joyride from "react-joyride";
import { AppContext } from "../../../../Context/TourContext/TourContext";

import ProductsInfoModal2 from "../../../../Pages/Products/ProductsInfoModal2";
import Loader from "../../../Loader/Loader";
import {
  fetchSections,
  getSectionsDetails,
} from "../../../../Redux/Slice/SectionSlice.jsx/SectionSlice";
// import Cookies from "universal-cookie";
import { TourCheckApi } from "../../../../Api/TourCheckApi/TourCheckApi";
import { RemoveSCFromInputs } from "../../../../Utils/RemoveSCFromInputs";
import SectionFilterDrawer from "./SectionFilterDrawer/SectionFilterDrawer";
import SectionInfoModal from "./SectionInfoModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";

function Section({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  const { totalRows } = useContext(PageNumberContext);
  let guide = sessionStorage.getItem("guide");
  const navigate = useNavigate();
  const [pagesize, setpagesize] = useState(totalRows);
  const dispatch = useDispatch();
  const [rows, setrows] = React.useState([]);
  const [allSection, setallSection] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const { Sections, status } = useSelector(getSectionsDetails);
  // const [showLoading, setshowLoading] = useState(false);
  // const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  // const [selectedId, setselectedId] = useState(null);
  // const { setsnackBarData } = useContext(SnackBarContext);
  const section = useRef();
  const name = useRef();
  const type = useRef();
  const category = useRef();
  const subcategory = useRef();
  const [sectionInfoModal, setsectionInfoModal] = useState(false);
  const [sectionRow, setsectionRow] = useState(null);

  const { setState, state } = useContext(AppContext);
  const [productInfoModal, setproductInfoModal] = useState(false);
  const [productRow, setproductRow] = useState(null);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Sections");

    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    // if (Sections == null) {
    dispatch(fetchSections());
    // }
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    Sections?.map((section, i) => {
      rowData.push({
        id: i + 1,
        project: section?.Project,
        section: section?.SectionName,
        type: section?.SectionType,
        category: section?.Category,
        ID: section?.SectionId,
        subcategory: section?.SubCategory,
        downPay: section?.DownPaymentPer,
        minDownPay: section?.MinDownPaymentPer,
        discounts: section?.SectionDiscounts,
      });
    });
    setrows(rowData);
    setallSection(rowData);
  }, [Sections]);
  // const deleteProduct = async (id) => {
  //   setshowLoading(true);
  //   let res = await DeleteProductApi(id);
  //   setshowLoading(false);
  //   setshowConfirmationIcons(false);
  //   setsnackBarData(res.snackBarData);
  //   if (res.data.IsSuccess) {
  //     dispatch(fetchSections());
  //   }
  // };
  const columns = [
    { field: "id", headerName: "Sr", width: 90 },
    {
      field: "project",
      headerName: "Project Name",
      width: 150,
      renderCell: (celval) => {
        return celval.formattedValue?.ProjectName;
      },
    },
    {
      field: "section",
      headerName: "Section Name",
      width: 160,
    },
    {
      field: "type",
      headerName: "Section Type",
      width: 160,
      renderCell: (celval) => {
        return celval.formattedValue?.Type;
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 180,
      renderCell: (celval) => {
        return celval.formattedValue?.CategoryName;
      },
    },
    {
      field: "subcategory",
      headerName: "Sub Category",
      width: 180,
      renderCell: (celval) => {
        return celval.formattedValue?.SubcategoryName;
      },
    },
    {
      field: "downPay",
      headerName: "Down Payment",
      width: 180,
      renderCell: (celval) => {
        return celval.formattedValue ? `${celval.formattedValue}%` : "";
      },
    },
    {
      field: "minDownPay",
      headerName: "Min Down Payment",
      width: 180,
      renderCell: (celval) => {
        return celval.formattedValue ? `${celval.formattedValue}%` : "";
      },
    },
    {
      field: "subcategory",
      headerName: "Sub Category",
      width: 180,
      renderCell: (celval) => {
        return celval.formattedValue?.SubcategoryName;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            {roles?.some((role) => role === "Sections_Edit") && (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row);
                  }}
                >
                  <EditIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.edit,
                    }}
                    fontSize="small"
                  />{" "}
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="View Discounts">
              <IconButton
                key={i}
                onClick={() => {
                  setsectionInfoModal(true);
                  setsectionRow(celval.row);
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleEdit = (val) => {
    navigate("/settings/inventory/section/create", { state: val });
  };
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  const onTextChange = () => {
    if (
      section.current?.value === "" &&
      name.current?.value === "" &&
      type.current?.value === "" &&
      category.current?.value === "" &&
      subcategory.current?.value === ""
    ) {
      setallSection(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(RemoveSCFromInputs(section.current.value), "i");
      let regex2 = new RegExp(RemoveSCFromInputs(name.current.value), "i");
      let regex3 = new RegExp(RemoveSCFromInputs(type.current.value), "i");
      let regex4 = new RegExp(RemoveSCFromInputs(category.current.value), "i");
      let regex5 = new RegExp(
        RemoveSCFromInputs(subcategory.current.value),
        "i"
      );
      suggestions = rows?.filter(
        (val) =>
          (section.current.value === "" || regex1.test(val.section)) &&
          (name.current.value === "" ||
            regex2.test(val?.project?.ProjectName)) &&
          (type.current.value === "" || regex3.test(val?.type?.Type)) &&
          (category.current.value === "" ||
            regex4.test(val?.category?.CategoryName)) &&
          (subcategory.current.value === "" ||
            regex5.test(val?.subcategory?.SubcategoryName))
      );
      setallSection(suggestions);
    }
  };
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Sections"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="section">
        <SectionFilterDrawer
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          section={section}
          type={type}
          category={category}
          setcount={setcount}
          setallSection={setallSection}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          subcategory={subcategory}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allSection}
            columns={columns}
          />
          {status === "loading" ? <Loader /> : null}
        </Box>
      </Box>
      {sectionInfoModal && (
        <SectionInfoModal
          sectionInfoModal={sectionInfoModal}
          setsectionInfoModal={setsectionInfoModal}
          setsectionRow={setsectionRow}
          sectionRow={sectionRow}
        />
      )}

      {productInfoModal && (
        <ProductsInfoModal2
          productRow={productRow}
          setproductRow={setproductRow}
          setproductInfoModal={setproductInfoModal}
          productInfoModal={productInfoModal}
        />
      )}
    </>
  );
}

export default Section;
Section.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#section",
    content: (
      <>
        <p size="large">Sections Page</p>
        <p>
          Welcome to Section Management – your tool for organizing and
          categorizing inventory sections. Sections provide a structured way to
          arrange and access inventory items, improving efficiency and clarity
          in inventory management.
          <br />
          <br />
          Click &apos;New&apos; to create a new section and enhance inventory
          organization.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
