import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import theme from "../../theme";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";

import AppConfirmationDialog from "../AppConfirmationDialog/AppConfirmationDialog";
import { DoneLeadCallApi } from "../../Api/LeadsApi/DoneLeadCallApi";
import { fetchLeadsSingle } from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { fetchActivities } from "../../Redux/Slice/ActivitiesSlice/ActivitiesSlice";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
const meetingOptions = ["Attempt", "Done"];
const callOptions = ["Attempt", "Connected"];
function ActivityDoneModal({
  showModal,
  setShowModal,
  selectedRow,
  setselectedRow,
  leadId,
  activityForm,
  values,
  formSubmit,
  pageNo,
  pageSize,
}) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const [logo, setlogo] = useState(null);
  const [selectedDocument, setselectedDocument] = useState(null);
  const [loading, setloading] = useState(false);
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [logoError, setlogoError] = useState(false);
  // const extension = selectedDocument?.FileName?.split(".")[1];

  const initialValues = {
    duration: "",
    status: "",
  };
  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Please Select Status"),
    duration: Yup.string().test(
      "required-if-before-current",
      "Please Enter Duration",
      function (value) {
        if (
          (selectedRow?.Type !== "Meeting" ||
            formik?.values?.type !== "Meeting") &&
          formik?.values?.status !== "Attempt"
          // &&
          // moment(formattedDatetime).isBefore(currentDate)
        ) {
          return !!value;
        }
        return true;
      }
    ),
  });
  const handleLogo = (e) => {
    setlogo({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };
  const handleSave = async () => {
    setshowConfirmation(true);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (formik?.values?.status !== "Attempt") {
        if (
          selectedRow?.Type === "Meeting" ||
          formik?.values?.type === "Meeting"
        ) {
          if (logo?.file) {
            if (activityForm) {
              formSubmit(values, resetForm, setSubmitting, logo);
            } else handleSave(values, resetForm, setSubmitting);
          } else {
            setlogoError(true);
            setSubmitting(false);
          }
        } else {
          if (activityForm) {
            formSubmit(values, resetForm, setSubmitting);
          } else handleSave(values, resetForm, setSubmitting);
        }
      } else if (activityForm) {
        formSubmit(values, resetForm, setSubmitting);
      } else handleSave(values, resetForm, setSubmitting);
    },
  });

  const handleClosed = () => {
    setshowConfirmation(false);
  };
  const handleActivity = async () => {
    const formData = new FormData();

    formData.append("File", logo?.file ? logo?.file : null);
    formData.append("Minuts", formik.values?.duration);
    formData.append("Status", formik.values?.status);
    // console.log("formik values==>>>>>",formData);
    setloading(true);
    const response = await DoneLeadCallApi(selectedRow?.ActivtyId, formData);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setselectedRow(null);
      setloading(false);
      handleClosed();
      setShowModal(false);

      leadId
        ? dispatch(fetchLeadsSingle(Number(leadId)))
        : dispatch(
            fetchActivities({
              PageNumber: pageNo,
              PageSize: pageSize,
              activityType: "All",
            })
          );
    } else {
      formik.setSubmitting(false);
    }
    handleClosed();
    setloading(false);
  };
  const handleDelImg = () => {
    document.getElementById("file-input").value = ""; // Clear the file input field
    setlogo(null);
  };
  const setTime = (hh, mm, ss) => {
    let hrs = Number(hh || 0) * 60;
    let sec = Number(ss || 0) / 60;
    let total = hrs + Number(mm || 0) + sec;

    total > 0
      ? formik.setFieldValue("duration", total)
      : formik.setFieldValue("duration", "");
  };
  useEffect(() => {
    if (activityForm && values) {
      formik.setValues(values);
    }
  }, [values]);

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setselectedDocument(null);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography
                variant="h1"
                sx={{ fontSize: "18px !important" }}
              >
                Activity Detail
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(false);
                  setselectedDocument(null);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <FormControl fullWidth>
                      <Typography>Status</Typography>
                      <TextField
                        name="status"
                        value={formik.values?.status}
                        size={"small"}
                        select
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          Boolean(formik.errors.status) &&
                          Boolean(formik.touched.status)
                        }
                        helperText={
                          Boolean(formik.errors.status) &&
                          Boolean(formik.touched.status) &&
                          formik.errors.status
                        }
                      >
                        <MenuItem value="">Select Sub Type</MenuItem>
                        {selectedRow?.Type === "Call" ||
                        formik?.values?.type === "Call"
                          ? callOptions?.map((type, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={type}
                                >
                                  {type}
                                </MenuItem>
                              );
                            })
                          : selectedRow?.Type === "Meeting" ||
                            formik?.values?.type === "Meeting"
                          ? meetingOptions?.map((type, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={type}
                                >
                                  {type}
                                </MenuItem>
                              );
                            })
                          : null}
                      </TextField>
                    </FormControl>
                    {formik.values?.status !== "Attempt" && (
                      <>
                        <FormControl fullWidth>
                          <Typography>Duration</Typography>
                          <Box
                            sx={{ width: "100%", display: "flex", gap: "10px" }}
                          >
                            <TextField
                              sx={{
                                textAlign: "left",
                                input: { color: "black" },
                              }}
                              fullWidth
                              size="small"
                              name="hrs"
                              type="number"
                              placeholder="Hours"
                              onBlur={formik.handleBlur}
                              value={formik.values?.hrs}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setTime(
                                  e.target.value,
                                  formik.values?.mins,
                                  formik.values?.seconds
                                );
                              }}
                              variant="outlined"
                              error={
                                Boolean(formik.touched.duration) &&
                                Boolean(formik.errors.duration)
                              }
                              helperText={
                                Boolean(formik.touched.duration) &&
                                formik.errors.duration
                              }
                            />
                            <Typography variant="h5">:</Typography>
                            <TextField
                              sx={{
                                textAlign: "left",
                                input: { color: "black" },
                              }}
                              fullWidth
                              size="small"
                              name="mins"
                              type="number"
                              placeholder="Minutes"
                              onBlur={formik.handleBlur}
                              value={formik.values?.mins}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setTime(
                                  formik.values?.hrs,
                                  e.target.value,
                                  formik.values?.seconds
                                );
                              }}
                              variant="outlined"
                              error={
                                Boolean(formik.touched.duration) &&
                                Boolean(formik.errors.duration)
                              }
                              helperText={
                                Boolean(formik.touched.duration) &&
                                formik.errors.duration
                              }
                            />
                            <Typography variant="h5">:</Typography>
                            <TextField
                              sx={{
                                textAlign: "left",
                                input: { color: "black" },
                              }}
                              placeholder="Seconds"
                              fullWidth
                              size="small"
                              name="seconds"
                              type="number"
                              onBlur={formik.handleBlur}
                              value={formik.values?.seconds}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setTime(
                                  formik.values?.hrs,
                                  formik.values?.mins,
                                  e.target.value
                                );
                              }}
                              variant="outlined"
                              error={
                                Boolean(formik.touched.duration) &&
                                Boolean(formik.errors.duration)
                              }
                              helperText={
                                Boolean(formik.touched.duration) &&
                                formik.errors.duration
                              }
                            />
                          </Box>
                        </FormControl>

                        <FormControl
                          fullWidth
                          error={logoError}
                        >
                          <Typography>File</Typography>
                          <TextField
                            id="file-input"
                            sx={{
                              textAlign: "left",
                              input: { color: "black" },
                            }}
                            fullWidth
                            size="small"
                            error={logoError}
                            name="FileUrl"
                            type="file"
                            InputProps={{
                              inputProps: {
                                accept: "image/*",
                              },
                            }}
                            // accept={formik.values?.Type==="Image"?"image/*":".pdf, .doc, .docx"}
                            onChange={handleLogo}
                            variant="outlined"
                          />
                          <FormHelperText>
                            {logoError ? "Please Select Image" : ""}
                          </FormHelperText>
                        </FormControl>
                        {logo && (
                          <Box
                            sx={{
                              position: "relative",
                              //   height: "20%",
                              width: "50%",
                              borderRadius: "5px",
                              border: "1px solid rgb(214, 214, 214)",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer !important",
                              justifyContent: "center",
                              padding: "10px",
                            }}
                          >
                            <IconButton
                              color="error"
                              sx={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                              onClick={handleDelImg}
                            >
                              <CancelIcon />
                            </IconButton>
                            <img
                              src={
                                selectedDocument
                                  ? selectedDocument?.File
                                  : logo?.url
                              }
                              alt="Image"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Box>
                        )}
                      </>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                            >
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <AppConfirmationDialog
        open={showConfirmation}
        onDeny={() => {
          handleClosed();
        }}
        onConfirm={() => {
          handleActivity();
        }}
        iconContainerColor="#ebf9f9"
        icon={
          <CheckCircleIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.primary?.main }}
          />
        }
        dialogTitle="Mark Done"
        title={`Are you sure, you want to mark "${selectedRow?.Title}" as done?`}
        successText="Yes"
        successBtnColor="primary"
        cancelText="No"
        loading={loading}
      />
    </>
  );
}

export default ActivityDoneModal;

ActivityDoneModal.propTypes = {
  showModal: PropTypes.any,
  setShowModal: PropTypes.any,
  Type: PropTypes.any,
  selectedRow: PropTypes.any,
  setselectedRow: PropTypes.any,
  leadId: PropTypes.any,
  activityForm: PropTypes.any,
  values: PropTypes.any,
  formSubmit: PropTypes.any,
  pageNo: PropTypes.number,
  pageSize: PropTypes.number,
};
