import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddProjectCategory } from "../../../../Api/ProjectApi/AddCategoryApi";
import { fetchProjectCategories } from "../../../../Redux/Slice/ProjectSlice/ProjectSlice";

function AddCategoryModal({
  showModal,
  handleClosed,
  setsnackBarData,
  dispatch,
}) {
  const ValidationSchema = Yup.object().shape({
    projectCategoryName: Yup.string().required("Please Enter Category Name"),
  });
  const initialValues = {
    projectCategoryName: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const response = await AddProjectCategory(values);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      dispatch(fetchProjectCategories());
      setSubmitting(false);
      resetForm();
      handleClosed();
    }
    setSubmitting(false);
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            Add Project Category
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "600px" }}>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth>
            <Typography>Category Name</Typography>
            <TextField
              name="projectCategoryName"
              value={formik.values?.projectCategoryName}
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.projectCategoryName) &&
                Boolean(formik.touched.projectCategoryName)
              }
              helperText={
                Boolean(formik.errors.projectCategoryName) &&
                Boolean(formik.touched.projectCategoryName) &&
                formik.errors.projectCategoryName
              }
            />
          </FormControl>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button onClick={handleClosed}>
                  <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                </Button>
              </Box>
              <Box>
                {formik.isSubmitting ? (
                  <Box>
                    {/* <Loader/> */}
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button variant="contained" type="submit">
                    <Typography sx={{ fontWeight: "bold" }}>Save</Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddCategoryModal;

AddCategoryModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  product: PropTypes.any,
  setsnackBarData: PropTypes.any,
  dispatch: PropTypes.any,
};
