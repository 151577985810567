import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  CircularProgress,
  // Menu,
  // MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

function NotificationsDrawer({ setdrawerOpen, Id, drawerOpen, Notifications }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [ setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  // const isMenuOpen = Boolean(anchorEl);

  const handleClose = () => {
    setdrawerOpen(!drawerOpen);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function customFormatDate(dateString) {
    const now = moment();
    const date = moment(dateString);

    if (now.diff(date, "days") >= 30) {
      return date.format("MMMM YYYY");
    }
    if (now.diff(date, "days") >= 1) {
      return date.fromNow();
    }
    return date.format("h:mm A");
  }
  const handleSeen = async (Leadid, Notificationid) => {
    setLoading(true);
    console.log(Notificationid, Id);
    try {
      setLoading(false);
      navigate("/leads/view", {
        state: { id: Leadid },
      });
      setdrawerOpen(!drawerOpen);
    } catch (error) {
      console.error(`There was an error in viewing Lead`, error);
    } finally {
      setLoading(false);
    }
  };
  const NotiCount= Notifications?.filter((noti)=> noti.Status==="Pending");
  const Count = NotiCount?.length;
  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ minWidth: "500px" }}>
          <Box
            sx={{
              display: "flex",
              padding: "20px",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Typography
                color="text.primary"
                sx={{ fontSize: "24px", fontWeight: "bold" }}
              >
                Notifications
              </Typography>
              <Typography
                color="text.primary"
                sx={{ fontSize: "16px", fontWeight: "" }}
              >
                {`${Count} unread`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider />

          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Tab label="Unseen" sx={{ flex: 1, textAlign: "center" }} />
            <Tab label="Seen" sx={{ flex: 1, textAlign: "center" }} />
          </Tabs>

          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
              }}
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            <>
              {activeTab === 0 ? (
                <>
                  {Notifications?.length ? (
                    <Box
                      sx={{
                        maxHeight: "80vh",
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                     {Notifications.filter(notification => notification.Status === 'Pending').map((notification) => (
                        <Box
                          key={notification?.Id}
                          onClick={() =>
                            handleSeen(
                              notification?.LeadId,
                              notification?.LeadRemark?.Id
                            )
                          }
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                              backgroundColor: "#F0F0F0",
                            },
                            cursor: "pointer",
                            position: "relative",
                            borderBottom: 1,
                            borderColor: "#C7C1C1",
                          }}
                        >
                          <Box sx={{ flex: 1, padding: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                {notification?.Title}
                              </Typography>
                              {/* <MoreVertIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setAnchorEl(e.currentTarget);
                                }}
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                  cursor: "pointer",
                                }}
                              />
                              <Menu
                                anchorEl={anchorEl}
                                open={isMenuOpen}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setAnchorEl(null);
                                }}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                                PaperProps={{
                                  sx: {
                                    boxShadow:
                                      "rgba(149, 157, 165, 0.) 4px 4px 4px 4px",
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setAnchorEl(null);
                                  }}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#f5f5f5",
                                    },
                                  }}
                                >
                                  Mark Seen
                                </MenuItem>
                              </Menu> */}
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{ paddingTop: 1, fontSize: "14px" }}
                            >
                              {notification?.Remarks}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 13,
                                  paddingTop: 1,
                                  color: "#969494",
                                }}
                              >
                                {customFormatDate(
                                  notification?.CreatedOn
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 10,
                                  paddingTop: 1,
                                  color: "#969494",
                                }}
                              >
                                {`Created by ${notification?.CreatedBy}`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="body2" sx={{ padding: 3 }}>
                      No notifications available
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  {Notifications?.length ? (
                    <Box
                      sx={{
                        maxHeight: "80vh",
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {Notifications.filter(notification => notification.Status === 'Read').map((notification) => (
                        <Box
                          key={notification?.Id}
                          onClick={() =>
                            handleSeen(
                              notification?.LeadId,
                              notification?.LeadRemark?.Id
                            )
                          }
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            backgroundColor: "#F0F0F0",
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                              backgroundColor: "#FFFFFF",
                            },
                            cursor: "pointer",
                            position: "relative",
                            borderBottom: 1,
                            borderColor: "#C7C1C1",
                          }}
                        >
                          <Box sx={{ flex: 1, padding: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                {notification?.Title}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{ paddingTop: 1, fontSize: "14px" }}
                            >
                              {notification?.Remarks}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 13,
                                  paddingTop: 1,
                                  color: "#969494",
                                }}
                              >
                                {customFormatDate(
                                  notification?.CreatedOn
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 10,
                                  paddingTop: 1,
                                  color: "#969494",
                                }}
                              >
                                {`Created by ${notification?.CreatedBy}`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="body2" sx={{ padding: 3 }}>
                      No notifications available
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

NotificationsDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setdrawerOpen: PropTypes.func.isRequired,
  Notifications: PropTypes.array.isRequired,
  Id: PropTypes.string.isRequired,
};

export default NotificationsDrawer;
