import * as React from "react";
import {
  FormControl,
  Button,
  Box,
  OutlinedInput,
  MenuItem,
  Select,
  Chip,
  ListItem,
  TextField,
  Autocomplete,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchusers,
  getLeadsDetails,
} from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { TransferAllLeadsApi } from "../../Api/LeadsApi/TransferLeadsApi";
import CancelIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "@mui/styles";

function TransferLeads() {
  const { setsnackBarData } = useContext(SnackBarContext);
  const { container, subContainer, formstyle, submitbtn, fieldstyle } =
    useStyle();
  const { TransferUsers } = useSelector(getLeadsDetails);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (TransferUsers?.length === 0) dispatch(fetchusers());
  }, [dispatch, TransferUsers]);
  // Yup validation schema
  const validationSchema = Yup.object({
    Firstuser: Yup.string().required("Please select user"),
    personName: Yup.array().min(1, "Please select user").required(""),
  });
  const formik = useFormik({
    initialValues: {
      Firstuser: "",
      personName: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const formData = {
        UserId: values.Firstuser,
        transferUsers: values.personName.map((user) => ({ userId: user.Id })),
      };
      resetForm();
      try {
        const response = await TransferAllLeadsApi(formData);
        setsnackBarData(response.snackBarData);
        if (response?.data?.IsSuccess) {
          console.log("this is form submit data", response.data);
          setSubmitting(true);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });
  const filteredTransferFromOptions = TransferUsers?.Data?.filter(
    (user) => user.Id !== formik.values.Firstuser
  );
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation">
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                Transfer Leads
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            Transfer Leads
          </Typography>
        </Box>
        <FormControl className={formstyle}>
          <Box className={fieldstyle}>
            <Box>
              <Typography>Transfer From</Typography>
              <Select
                name="Firstuser"
                value={formik.values.Firstuser}
                onChange={(event) =>
                  formik.setFieldValue("Firstuser", event.target.value)
                }
                input={<OutlinedInput />}
                sx={{ width: "100%", height: "40px" }}
                error={
                  formik.touched.Firstuser && Boolean(formik.errors.Firstuser)
                }
              >
                {TransferUsers?.Data?.map((user) => (
                  <MenuItem
                    key={user.Id}
                    value={user.Id}
                    disabled={formik.values.personName.some(
                      (selectedUser) => selectedUser.Id === user.Id
                    )}
                  >
                    {user.FullName}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.Firstuser && formik.errors.Firstuser && (
                <Typography
                  color="error"
                  sx={{ fontSize: "12px", paddingLeft: "11px" }}
                >
                  {formik.errors.Firstuser}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography>Transfer To</Typography>
              <Autocomplete
                multiple
                // limitTags={2}
                size="small"
                id="multiple-limit-tags-category"
                options={filteredTransferFromOptions || []}
                getOptionLabel={(option) => option?.FullName}
                isOptionEqualToValue={(option, value) =>
                  option?.Id === value?.Id
                }
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip
                      key={option.Id}
                      label={option?.FullName}
                      {...getTagProps({ index })}
                      style={{ backgroundColor: "#298989", color: "white" }}
                      deleteIcon={<CancelIcon style={{ color: "white" }} />}
                    />
                  ))
                }
                renderOption={(props, option) => (
                  <ListItem
                    {...props}
                    key={option?.Id}
                    disabled={formik.values.Firstuser === option.Id}
                  >
                    <Typography>{option?.FullName}</Typography>
                  </ListItem>
                )}
                listStyle={{ maxHeight: 200, overflow: "auto" }}
                sx={{ width: "100%" }}
                fullWidth
                name="personName"
                value={formik.values.personName}
                onChange={(event, value) =>
                  formik.setFieldValue("personName", value)
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="input_border"
                    error={
                      formik.touched.personName &&
                      Boolean(formik.errors.personName)
                    }
                    helperText={
                      formik.touched.personName && formik.errors.personName
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Box className={submitbtn}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
}
export default TransferLeads;
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      width: "100%",
      maxWidth: "1000px",
    },
    formstyle: {
      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      justifyContent: "center",
      width: "90%",
      PaddingTop: "2rem",
    },
    fieldstyle: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "10px 16px",
    },
    submitbtn: {
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: "20px",
      padding: "10px 16px",
    },
  };
});
