import React from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import PlannedCalls from "./PlannedCalls";
import DoneCalls from "./DoneCalls";
import { useState } from "react";
import { useEffect } from "react";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      //   gap: "20px",
      padding: "20px",
      //   alignItems: "flex-start",
    },
    plannedBox: {
      display: "flex",
      //   flexDirection:"column",
      gap: "10px",
      width: "100%",
      alignItems: "center",
    },

    Border: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "20px",
    },
  };
});
function Calls({ singleLead, roles }) {
  const { formContainer, Border, plannedBox } = useStyle();
  const [plannedCallLeads, setplannedCallLeads] = useState([]);
  const [doneCallLeads, setdoneCallLeads] = useState([]);
  useEffect(() => {
    let arr = [];
    let arr2 = [];
    singleLead?.leadActivity?.map((calls) => {
      if (calls?.Type === "Call" && calls?.StatusDone === false) {
        arr.push(calls);
      }
      if (calls?.Type === "Call" && calls?.StatusDone === true) {
        arr2.push(calls);
      }
    });
    setplannedCallLeads(arr);
    setdoneCallLeads(arr2);
  }, [singleLead]);
  return (
    <>
      <Paper elevation={3}>
        <Box className={formContainer}>
          <Box
            className={Border}
            sx={{}}
          >
            <Box>
              <Box className={plannedBox}>
                <Typography
                  sx={{ fontWeight: "bold", color: "rgb(84, 110, 122)" }}
                >
                  Planned
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <Divider />
                </Box>
              </Box>
              <PlannedCalls
                setplannedCallLeads={setplannedCallLeads}
                singleLead={singleLead}
                roles={roles}
              />
              {plannedCallLeads?.length > 0 ? null : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ color: "rgb(84, 110, 122)" }}>
                    You have no planned calls.
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              <Box className={plannedBox}>
                <Typography
                  sx={{ fontWeight: "bold", color: "rgb(84, 110, 122)" }}
                >
                  Done
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <Divider />
                </Box>
              </Box>
              <DoneCalls
                singleLead={singleLead}
                roles={roles}
              />
              {doneCallLeads?.length > 0 ? null : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ color: "rgb(84, 110, 122)" }}>
                    You have no done calls.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {/* </Box> */}
        </Box>
      </Paper>
    </>
  );
}

export default Calls;
Calls.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
