import React, { useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { useState } from "react";
import PropTypes from "prop-types";
import { AddLeadTagApi } from "../../../Api/LeadsApi/AddLeadTagApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { fetchLeadsSingle } from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function AddTags({ setaddTag,singleLead ,state}) {
  const { formContainer } = useStyle();

  const initialValues = {
    name: "",
  };
  const [colors] = useState([
    "rgb(50,205,50)",
    "rgb(250,218,94)",
    " rgb(219,112,147)",
    "rgb(39, 182, 187)",
    "rgb(251, 138, 63)",
    "rgb(51, 124, 255)",
  ]);
  const [borderColor, setborderColor] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [selectedColor, setselectedColor] = useState("");
  const dispatch=useDispatch();
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const obj = { ...values, color: selectedColor };
  
    const response = await AddLeadTagApi({obj:obj,id:singleLead?.Id});
    setsnackBarData(response.snackBarData);

    if (response?.data.IsSuccess) {
        setSubmitting(false);
    resetForm();
    if (state?.state?.id) {
      dispatch(fetchLeadsSingle(state?.state?.id));
    } else {
      dispatch(fetchLeadsSingle(state?.state));
    }
        // dispatch(TagsAdded());
        // navigate(-1);
    }

    setSubmitting(false);
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter  Name"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Box sx={{ padding: "10px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <FormControl fullWidth>
            <Typography>Name</Typography>
            <TextField
              name="name"
              value={formik.values?.name}
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.name) && Boolean(formik.touched.name)
              }
              helperText={Boolean(formik.errors.name) && formik.errors.name}
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography>Color</Typography>

            <Box sx={{ display: "flex", gap: "10px" }}>
              {colors?.map((clr, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: clr,
                      borderRadius: "100%",
                      border: borderColor === idx ? "2px solid white" : clr,
                      boxShadow:
                        borderColor === idx
                          ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                          : null,
                      transform: borderColor === idx ? "scale(0.9.5)" : null,
                      transition: "0.2s",
                    }}
                    onClick={() => {
                      setborderColor(idx);
                      setselectedColor(clr);
                    }}
                  ></Box>
                );
              })}
            </Box>
          </FormControl>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    border: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                  }}
                  onClick={() => {
                    setaddTag(false);
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "bold", textTransform: "none" }}
                  >
                    Back
                  </Typography>
                </Button>
              </Box>
              <Box>
                {formik.isSubmitting ? (
                  <Box>
                    {/* <Loader/> */}
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button variant="contained" type="submit">
                    <Typography
                      sx={{ fontWeight: "bold", textTransform: "none" }}
                    >
                      Save
                    </Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default AddTags;
AddTags.propTypes = {
  setaddTag: PropTypes.any,
  singleLead: PropTypes.any,
  state: PropTypes.any,
};
