import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { CreateScreensApi } from "../../../../Api/Settings/Roles/CreateScreensApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchParentRoles,
  getRolesDetails,
} from "../../../../Redux/Slice/Settings/RolesSlice/RolesSlice";
import { Autocomplete } from "@mui/material";
import { fetchScreens } from "../../../../Redux/Slice/Settings/ScreensSlice/ScreensSlice";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
});

function FormModal({ showModal, setShowModal }) {
  const { formContainer } = useStyle();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { ParentRoles } = useSelector(getRolesDetails);
  console.log(ParentRoles);
  const dispatch = useDispatch();
  const ModalValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
  });
  const modalFormik = useFormik({
    initialValues: {
      name: "",
      screens: [],
      parentId: null,
    },
    validationSchema: ModalValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleModalSubmit(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    dispatch(fetchParentRoles());
  }, [dispatch]);

  const handleModalSubmit = async (values, resetForm, setSubmitting) => {
    let obj = null;
    let data = [];

    values?.screens.forEach((val) => {
      data.push({
        name: `${val}`,
        roleName: `${values?.name}_${val}`,
      });
    });
    obj = {
      parentId: values?.parentId?.ScreenId ? values?.parentId?.ScreenId : null,
      name: values?.name,
      roleName: values?.parentId?.Name
        ? `${values?.parentId?.Name}_${values?.name}`
        : values?.name,
      subScreen: data,
    };
    const response = await CreateScreensApi(obj);
    setsnackBarData(response.snackBarData);

    if (response?.data.IsSuccess) {
      dispatch(fetchScreens());
      dispatch(fetchParentRoles());

      setSubmitting(false);
      resetForm();
      setShowModal(false);
    }
    setSubmitting(false);
  };
  return (
    <Box>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: {
              xs: "350px",
              sm: "550px",
            },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <form onSubmit={modalFormik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Parent</Typography>
                <Autocomplete
                  name="parentId"
                  //   options={["hjehje"]}
                  options={ParentRoles}
                  getOptionLabel={(option) => option.Name}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // error={
                      //   Boolean(formik.errors.sourceName) &&
                      //   Boolean(formik.touched.sourceName)
                      // }
                      // helperText={
                      //   Boolean(formik.errors.sourceName) &&
                      //   Boolean(formik.touched.sourceName) &&
                      //   formik.errors.sourceName
                      // }
                      //   name="personId"
                    />
                  )}
                  value={modalFormik.values?.parentId}
                  onChange={(__, value) => {
                    console.log(value?.Name);
                    modalFormik.setFieldValue("parentId", value);
                    // formik.values?.date=e.target.value
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  value={modalFormik.values?.name}
                  size={"small"}
                  fullWidth
                  onChange={modalFormik.handleChange}
                  error={
                    modalFormik.touched.name && Boolean(modalFormik.errors.name)
                  }
                  helperText={
                    modalFormik.touched.name && modalFormik.errors.name
                  }
                />
              </FormControl>
              <Box>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      name="screens"
                      value="Create"
                      onChange={modalFormik.handleChange}
                      label="Create"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      name="screens"
                      value="View"
                      onChange={modalFormik.handleChange}
                      label="View"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      name="screens"
                      value="Edit"
                      onChange={modalFormik.handleChange}
                      label="Edit"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      name="screens"
                      value="Delete"
                      onChange={modalFormik.handleChange}
                      label="Delete"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={() => setShowModal(false)}>
                      <Typography sx={{ fontWeight: "bold" }}>Close</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {modalFormik.isSubmitting ? (
                      <Box>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          Save
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}

export default FormModal;

FormModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};
