import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import Loader from "../../Components/Loader/Loader";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
// import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import { DeleteProductApi } from "../../Api/ProductsApi/DeleteProductApi";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Joyride from "react-joyride";
import { AppContext } from "../../Context/TourContext/TourContext";
import ServiceFilterDrawer from "../../Components/Service/ServiceFilterDrawer/ServiceFilterDrawer";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import {
  fetchTargets,
  getTargetDetails,
} from "../../Redux/Slice/TargetSlice/TargetSlice";
import moment from "moment";
import TargetInfoModal from "../../Components/Targets/TargetInfoModal";
import AssignTargetModal from "../../Components/Targets/AssignTarget/AssignTargetModal";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import AddNotesModal from "../../Components/Targets/Notes/AddNotesModal";
import { DeleteTargetApi } from "../../Api/TargetApi/DeleteTargetApi";
import AppConfirmationDialog from "../../Components/AppConfirmationDialog/AppConfirmationDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { PageNumberContext } from "../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

function Target({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const navigate = useNavigate();
  const [pagesize, setpagesize] = useState(totalRows);
  const dispatch = useDispatch();
  const [rows, setrows] = React.useState([]);
  const [allService, setallService] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const { Targets, status } = useSelector(getTargetDetails);
  // const [showLoading, setshowLoading] = useState(false);
  // const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  // const [selectedId, setselectedId] = useState(null);
  // const { setsnackBarData } = useContext(SnackBarContext);
  const Status = useRef();
  const title = useRef();
  const group = useRef();
  const { setState, state } = useContext(AppContext);
  const [targetInfoModal, settargetInfoModal] = useState(false);
  const [targetRow, settargetRow] = useState(null);
  const [assignTargetModal, setAssignTargetModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);

  const [targetId, setTargetId] = useState(null);
  const [showLoading, setshowLoading] = useState(false);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Service");
    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    dispatch(fetchTargets());
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    Targets?.map((target, i) => {
      rowData.push({
        id: i + 1,
        Title: target.Title,
        TargetDetail: target.TragetDetail,
        StartDate: target.StartDate,
        Description: target.Description,
        EndDate: target.EndDate,
        ID: target.TargetId,
        Group: target.Group,
        Status: target.Status,
        TargetNotes: target.TargetNotes,
      });
    });
    setrows(rowData);
    setallService(rowData);
  }, [Targets]);
  const deleteTarget = async (id) => {
    setshowLoading(true);
    let res = await DeleteTargetApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);

    if (res.data.IsSuccess) {
      dispatch(fetchTargets());
      setsnackBarData(res.snackBarData);
    }
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90 },

    {
      field: "Title",
      headerName: "Title",
      width: 200,
    },
    {
      field: "Group",
      headerName: "Group",
      width: 150,
      renderCell: (celval) => {
        return celval?.formattedValue?.Name;
      },
    },

    {
      field: "StartDate",
      headerName: "StartDate",
      width: 180,
      renderCell: (celval) => {
        return moment(celval?.formattedValue).format("DD MMM YYYY");
      },
    },
    {
      field: "EndDate",
      headerName: "EndDate",
      width: 180,
      renderCell: (celval) => {
        return moment(celval?.formattedValue).format("DD MMM YYYY");
      },
    },
    {
      field: "Status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "Description",
      headerName: "Description",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            {roles?.some((role) => role === "Targets_Edit") && (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row);
                  }}
                >
                  <EditIcon
                    sx={{ color: (theme) => theme?.palette?.primary?.edit }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            {roles?.some((role) => role === "Targets_Delete") && (
              <Tooltip title="Delete">
                <IconButton
                  key={i}
                  onClick={() => {
                    setselectedId(celval.row?.ID);
                    setshowConfirmationIcons(true);
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.delete,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="View">
              <IconButton
                key={i}
                onClick={() => {
                  settargetRow(celval.row);
                  settargetInfoModal(true);
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Assign Target">
              <IconButton
                key={i}
                onClick={() => {
                  setTargetId(celval.row?.ID);
                  setAssignTargetModal(true);
                }}
              >
                <AssignmentIcon
                  sx={{
                    color: (theme) => theme?.palette?.warning?.light,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Add Notes">
              <IconButton
                key={i}
                onClick={() => {
                  setTargetId(celval.row?.ID);
                  setAddNoteModal(true);
                }}
              >
                <SpeakerNotesIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.green,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleEdit = (val) => {
    navigate("/target/create", { state: val });
  };
  const handleChangeVal = (e) => {
    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value,
    });
  };
  const onTextChange = () => {
    if (
      Status.current?.value === "" &&
      title.current?.value === "" &&
      group.current?.value === ""
    ) {
      setallService(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(Status.current.value, "i");
      let regex2 = new RegExp(title.current.value, "i");
      let regex3 = new RegExp(group.current.value, "i");

      suggestions = rows?.filter(
        (val) =>
          (Status.current.value === "" || regex1.test(val?.Status)) &&
          (title.current.value === "" || regex2.test(val?.Title)) &&
          (group.current.value === "" || regex3.test(val?.Group?.Name))
      );
      setallService(suggestions);
    }
  };

  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Service"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="service">
        <ServiceFilterDrawer
          title={title}
          handleChangeVal={handleChangeVal}
          rows={rows}
          group={group}
          setcount={setcount}
          setallService={setallService}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          status={Status}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />

        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allService}
            columns={columns}
          />
          {status === "loading" || showLoading ? <Loader /> : null}
        </Box>
      </Box>
      {targetInfoModal && (
        <TargetInfoModal
          targetRow={targetRow}
          settargetRow={settargetRow}
          settargetInfoModal={settargetInfoModal}
          targetInfoModal={targetInfoModal}
          roles={roles}
        />
      )}{" "}
      {assignTargetModal && (
        <AssignTargetModal
          targetId={targetId}
          setTargetId={setTargetId}
          setAssignTargetModal={setAssignTargetModal}
          assignTargetModal={assignTargetModal}
        />
      )}
      {addNoteModal && (
        <AddNotesModal
          targetId={targetId}
          setTargetId={setTargetId}
          setAddNoteModal={setAddNoteModal}
          addNoteModal={addNoteModal}
        />
      )}
      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteTarget(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Target"
        title={`Are you sure, you want to Delete Target?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Target;
Target.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#service",
    content: (
      <>
        <p size="large">Targets Page</p>
        <p>
          Welcome to the Targets Page – your central hub for managing a variety
          of services. Each service plays a crucial role, and you have the tools
          to control and monitor their availability.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
