import { axiosInstance } from "../../Utils/AxiosInstance";

export const DeleteLeadApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/Lead/${Number(id)}`);
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const RecoverLeadApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(`/Lead/LeadRecover/${Number(id)}`);
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const DeleteLeadPermanentlyApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(
      `/Lead/ForceDelete/${Number(id)}`
    );
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
