import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  // Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import QuoteInformatio from "./QuoteInformatio";
import AddressInformation from "./AddressInformation";
import QuoteItems from "./QuoteItems";
import {
  fetchQuotesPopulate,
  getQuotesDetails,
} from "../../../Redux/Slice/QuoteSlice/QuoteSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
      alignItems: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1100px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
  };
});
const steps = ["Quote Information", "Address Information", "Quote Items"];
function CreateQuotes() {
  const { container, subContainer } = useStyle();
  const { QuotesPopulate } = useSelector(getQuotesDetails);
  const [quotesPopulate, setquotesPopulate] = useState([]);
  const [singleLead, setsingleLead] = useState(null);
  const [leadId, setLeadId] = useState(null);

  const { state } = useLocation();
  // const { singleLead } = useLocation();

  // console.log(singleLead);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [quoteInfo, setquoteInfo] = useState(null);
  const [addressInfo, setaddressInfo] = useState(null);
  useEffect(() => {
    dispatch(fetchQuotesPopulate());
  }, [dispatch]);
  useEffect(() => {
    if (QuotesPopulate) {
      setquotesPopulate(QuotesPopulate);
    }
  }, [QuotesPopulate]);
  // console.log(state);
  useEffect(() => {
    if (state?.singleLead && state?.isLead) {
      setsingleLead(state?.singleLead);
    }
  }, [state]);
  // console.log(quoteInfo);
  // console.log(addressInfo);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/quotes"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Quotes
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state && !state?.singleLead && !state?.isLead
                  ? "Update quotes"
                  : "Create Quotes"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state && !state?.singleLead && !state?.isLead
              ? "Update quotes"
              : "Create Quotes"}
          </Typography>
        </Box>

        <Box>
          <Stepper
            sx={{ width: "100%", maxWidth: "400px" }}
            activeStep={activeStep}
          >
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {activeStep === 0 ? (
          <QuoteInformatio
            singleLead={singleLead}
            state={state}
            quotesPopulate={quotesPopulate}
            quoteInfo={quoteInfo}
            setquoteInfo={setquoteInfo}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
            setLeadId={setLeadId}
          />
        ) : activeStep === 1 ? (
          <AddressInformation
            singleLead={singleLead}
            state={state}
            addressInfo={addressInfo}
            setaddressInfo={setaddressInfo}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
          />
        ) : activeStep === 2 ? (
          <QuoteItems
            state={state}
            quotesPopulate={quotesPopulate}
            quoteInfo={quoteInfo}
            addressInfo={addressInfo}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
            leadId={leadId}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default CreateQuotes;
