import React, { useContext } from "react";
import {
  Box,
  // Button,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import theme from "../../../../../theme";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment/moment";
import GroupsIcon from "@mui/icons-material/Groups";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import { fetchLeadsSingle } from "../../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";
import { DeleteLeadActivityApi } from "../../../../../Api/LeadsApi/DeleteActivityApi";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppConfirmationDialog from "../../../../AppConfirmationDialog/AppConfirmationDialog";
import ActivityDoneModal from "../../../../Activities/ActivityDoneModal";
import ActivityInfoModal from "../../../../Activities/ActivityInfoModal";

const useStyle = makeStyles(() => {
  return {
    notesubboxMain: {
      display: "flex",
      gap: "40px",
      width: "100%",
      padding: "0px 10px 0px 20px",
    },
    iconlineMain: {
      width: "2px",
      minHeight: "100%",
      // backgroundColor: theme.palette.primary.main,
      border: `1px dashed ${theme.palette.primary.main}`,
      position: "relative",
    },
    iconBox: {
      width: "40px",
      height: "40px",
      border: `1px solid ${theme?.palette.primary.main}`,
      transform: "translate(-50%,0%)",
      position: "absolute",
      top: "0%",
      left: "50%",
      color: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white !important",
    },
    noteBox: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      padding: "10px",
      width: "100%",
    },
    scheduleBox: {
      display: "flex",
      gap: "5px",
    },
    scheduled: {
      fontSize: "15px !important",
      color: theme.palette.primary.main,
    },
  };
});
function PlannedMeetings({ singleLead, roles }) {
  const {
    notesubboxMain,
    iconlineMain,
    iconBox,
    noteBox,
    scheduleBox,
    scheduled,
  } = useStyle();
  const [meetingsLeads, setmeetingsLeads] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setloading] = useState(false);

  const [meetingLeadsRow, setmeetingLeadsRow] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [opens, setOpens] = useState(false);
  const [plannedMeetingRow, setplannedMeetingRow] = useState(null);
  const [activityRow, setactivityRow] = useState(null);
  const [activityInfoModal, setactivityInfoModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event, meetings) => {
    setAnchorEl(event.currentTarget);
    setplannedMeetingRow(meetings);
  };
  // useEffect(()=>{
  //   dispatch(fetchLeadsSingle(singleLeadId));
  // },[dispatch]);
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let arr = [];
    singleLead?.leadActivity?.map((meeting) => {
      if (meeting?.Type === "Meeting" && meeting?.StatusDone === false) {
        arr.push(meeting);
      }
    });
    setmeetingsLeads(arr);
  }, [singleLead]);
  const handleClickOpen = () => {
    setshowModal(true);
  };

  const handleClosed = () => {
    setshowModal(false);
  };

  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleCloseds = () => {
    setOpens(false);
  };
  const deleteMetting = async () => {
    setloading(true);
    const response = await DeleteLeadActivityApi(plannedMeetingRow?.ActivtyId);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setplannedMeetingRow(null);
      setloading(false);
      handleClosed();
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
    }
    setloading(false);
  };
  console.log("leades data", meetingsLeads);
  return (
    <>
      {meetingsLeads?.map((meetings, i) => {
        let date2 = moment(meetings?.ScheduleFrom.split("T")[0]).format(
          "DD-MM-YYYY"
        );
        let time2 = moment(
          meetings?.ScheduleFrom.split("T")[1].split("Z")[0],
          "hh:mm A"
        ).format("hh:mm A");
        let schFrom = date2.concat(" ").concat(time2);
        return (
          <Box key={i} className={notesubboxMain}>
            <Box className={iconlineMain}>
              <Box className={iconBox}>
                <GroupsIcon sx={{}} />
              </Box>
            </Box>
            <Box sx={{ width: "100%", paddingBottom: "10px" }}>
              <Paper key={i} elevation={3} className={noteBox}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {meetings?.Title}
                  </Typography>
                  <MoreVertIcon
                    onClick={(e) => {
                      if (roles?.some((role) => role === "Leads_Edit")) {
                        handleClick(e, meetings);
                        setmeetingLeadsRow(meetings);
                      }
                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                      display:
                        singleLead?.Status === "Won" ||
                        singleLead?.Status === "Lost"
                          ? "none"
                          : "",
                    }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      sx: {
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleClickOpen();
                      }}
                    >
                      Mark as Done
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                        navigate("/edit/activities", {
                          state: meetingLeadsRow,
                        });
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleClickOpens();
                        // setnotesRow(note);
                      }}
                    >
                      Remove
                    </MenuItem>
                    <MenuItem
                      key={i}
                      onClick={() => {
                        setactivityInfoModal(true);
                        setactivityRow(meetings);
                      }}
                    >
                      View Detail
                    </MenuItem>
                  </Menu>
                </Box>
                <Box className={scheduleBox}>
                  <Typography className={scheduled}>
                    Meeting scheduled at
                  </Typography>
                  <Typography className={scheduled}>{schFrom}</Typography>
                  {/* <Typography className={scheduled}>To</Typography>
                  <Typography className={scheduled}>{schTo}</Typography> */}
                </Box>
                <Box sx={{ backgroundColor: "#fff9c4", padding: "10px" }}>
                  <Typography>{meetings?.Description}</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "12px" }}>
                    Added on:{" "}
                    {moment(meetings?.CreatedAt).format("Do MMMM YYYY, h:mm a")}
                  </Typography>
                  <Typography sx={{ cursor: "pointer", color: "#0e90d9" }}>
                    {meetings?.FullName}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Box>
        );
      })}

      {/* <Dialog
        open={showModal}
        onClose={handleClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to <b>{meetingLeadsRow?.Title}</b> mark as
            done ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={handleClosed}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  doneMeetingLead();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}

      {/* <Dialog
        open={opens}
        onClose={handleCloseds}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete <b>{plannedMeetingRow?.Title}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={handleCloseds}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  deleteMetting();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}
      {activityInfoModal && (
        <ActivityInfoModal
          activityInfoModal={activityInfoModal}
          setactivityInfoModal={setactivityInfoModal}
          setactivityRow={setactivityRow}
          activityRow={activityRow}
        />
      )}
      {showModal && (
        <ActivityDoneModal
          showModal={showModal}
          setShowModal={setshowModal}
          selectedRow={meetingLeadsRow}
          setselectedRow={setmeetingLeadsRow}
          leadId={singleLead?.Id}
        />
      )}

      <AppConfirmationDialog
        open={opens}
        onDeny={() => {
          handleCloseds();
        }}
        onConfirm={() => {
          deleteMetting();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Meeting"
        title={`Are you sure you want to Delete "${plannedMeetingRow?.Title}"?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />
    </>
  );
}

export default PlannedMeetings;
PlannedMeetings.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
