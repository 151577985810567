import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";

import AddSubCatModal from "./AddSubCategoryModal";
import {
  fetchSectionPopulate,
  getSectionsDetails,
} from "../../../../Redux/Slice/SectionSlice.jsx/SectionSlice";
import { CreateSectionApi } from "../../../../Api/SectionApi/CreateSectionApi";
import Loader from "../../../Loader/Loader";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import theme from "../../../../theme";
// import { fetchProducts } from '../../../Redux/Slice/ProductSlice/ProductSlice';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ReactSelectTheme } from "../../../../Utils/ReactSelectTheme";

// import Loader from '../../Loader/Loader';
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function CreateSection() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { SectionPopulate, status1 } = useSelector(getSectionsDetails);
  const dispatch = useDispatch();
  const [projOptions, setProjOptions] = useState([]);
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  // const [renderComp, setrenderComp] = useState(false);

  const [discountDetail, setdiscountDetail] = useState([
    {
      paidPer: 0,
      discountPer: 0,
    },
  ]);
  useEffect(() => {
    dispatch(fetchSectionPopulate());
  }, [dispatch]);
  useEffect(() => {
    let val = [];
    let val1 = [];
    let val2 = [];

    SectionPopulate?.Projects?.map((data) => {
      let obj = {
        label: data?.ProjectName,
        value: data?.ProjectId,
      };
      val1.push(obj);
    });
    SectionPopulate?.Categories?.map((data) => {
      let obj = {
        label: data?.CategoryName,
        value: data?.CategoryId,
      };
      val.push(obj);
    });
    SectionPopulate?.SectionTypes?.map((data) => {
      let obj = {
        label: data?.Type,
        value: data?.SectionTypeId,
      };
      val2.push(obj);
    });

    setCatOptions(val);
    setProjOptions(val1);
    setSectionOptions(val2);
  }, [SectionPopulate]);
  const initialValues = {
    name: "",
    advanceper: "",
    project: "",
    type: "",
    category: "",
    subcategory: "",
    payTime: "",
    downPay: "",
    minDownPay: "",
    discountDetail: discountDetail || [],
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      handleEdit(state);
    }
  }, []);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "name",
        value: values?.section || "",
      },
    });
    formik.handleChange({
      target: {
        name: "downPay",
        value: values?.downPay || "",
      },
    });
    formik.handleChange({
      target: {
        name: "minDownPay",
        value: values?.minDownPay || "",
      },
    });
    formik.handleChange({
      target: {
        name: "category",
        value:
          {
            value: values?.category?.CategoryId,
            label: values?.category?.CategoryName,
          } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "subcategory",
        value:
          {
            value: values?.subcategory?.SubcategoryId,
            label: values?.subcategory?.SubcategoryName,
          } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "type",
        value:
          {
            value: values?.type?.SectionTypeId,
            label: values?.type?.Type,
          } || "",
      },
    });
    formik.handleChange({
      target: {
        name: "project",
        value:
          {
            value: values?.project?.ProjectId,
            label: values?.project?.ProjectName,
          } || "",
      },
    });
    let Subcategories = [{ label: "Add New", value: "new" }];
    values?.category?.Subcategories?.map((data) => {
      let obj = {
        label: data?.SubcategoryName,
        value: data?.SubcategoryId,
      };
      Subcategories.push(obj);
    });
    setSubCatOptions(Subcategories);

    let arr = [];
    if (values?.discounts?.length > 0) {
      values?.discounts?.forEach((discount) => {
        arr.push({
          discountId: discount?.DiscountId,
          discountPer: discount?.DiscountPer,
          paidPer: discount?.PaidPer,
        });
      });
      formik.handleChange({
        target: {
          name: "discountDetail",
          value: arr || [],
        },
      });
      setdiscountDetail(arr);
    }
  };

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let data = {
      sectionId: state?.ID || null,
      sectionName: values.name,
      categoryId: values.category.value,
      subCategoryId: values.subcategory.value,
      projectId: values.project.value,
      sectionTypeId: values.type.value,
      minDownPaymentPer: values.minDownPay,
      downPaymentPer: values.downPay,
      sectionDiscounts: values.discountDetail,
    };
    const response = await CreateSectionApi(data);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      // dispatch(fetchProducts());
      setSubmitting(false);
      resetForm();
      navigate(-1);
    }
    setSubmitting(false);
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const handleSubCatChange = (value) => {
    if (value.value === "new") {
      setOpenModal(true);
    } else {
      formik.setFieldValue("subcategory", value);
    }
  };
  const handleClosed = () => {
    setOpenModal(false);
    formik.setFieldValue("category", {
      value: null,
      label: "",
    });
    formik.setFieldValue("subcategory", {
      value: null,
      label: "",
    });
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Section Name"),
    category: Yup.object().required("Please Select Category"),
    project: Yup.object().required("Please Select Project"),
    subcategory: Yup.object().required("Please Select Sub Category"),
    type: Yup.object().required("Please Select Section Type"),
    downPay: Yup.number()
      .required("Please Enter Down Payment")
      .typeError("Please Enter a valid number")
      .max(100, "Can't be greater than 100%"),
    minDownPay: Yup.number()
      .required("Please Enter Min Down Payment")
      .typeError("Please Enter a valid number")
      .max(100, "Can't be greater than 100%"),
    discountDetail: Yup.array().of(
      Yup.object().shape({
        paidPer: Yup.number().max(100, "Percentage cannot exceed 100"),
        discountPer: Yup.number().max(100, "Percentage cannot exceed 100"),
      })
    ),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const handleCategoryChange = (value) => {
    formik.setFieldValue("category", value);
    let Subcategories = [{ label: "Add New", value: "new" }];
    SectionPopulate.Categories.find(
      (cat) => cat.CategoryId === value.value
    ).Subcategories?.map((data) => {
      let obj = {
        label: data?.SubcategoryName,
        value: data?.SubcategoryId,
      };
      Subcategories.push(obj);
    });
    setSubCatOptions(Subcategories);
  };
  const handleDel = (i) => {
    let value = JSON.parse(JSON.stringify(discountDetail));
    value.splice(i, 1);

    setdiscountDetail(value);
    formik.setFieldValue("discountDetail", value);
  };
  const handleAddField = () => {
    setdiscountDetail([
      ...formik.values.discountDetail,
      {
        discountPer: 0,
        paidPer: 0,
      },
    ]);

    formik.setFieldValue("discountDetail", [
      ...formik.values.discountDetail,
      {
        discountPer: 0,
        paidPer: 0,
      },
    ]);
  };

  const handleChange = (e, i, key) => {
    const { value } = e.target;
    const newValues = [...formik.values.discountDetail];
    newValues[i][key] = value;
    formik.setFieldValue("discountDetail", newValues);

    // Validate individual field
    validateField(i, key, value);
  };

  const validateField = (i, key, value) => {
    ValidationSchema.validateAt(`discountDetail.${i}.${key}`, { [key]: value });
  };

  const handleBlur = (e, i, key) => {
    const { value } = e.target;
    validateField(i, key, value);
  };

  return (
    <Box className={container}>
      {status1 === "loading" ? <Loader /> : null}

      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/settings/inventory/section"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Section
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state ? "Update Section" : " Create Section"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state ? "Update Section" : " Create Section"}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl
                fullWidth
                error={
                  Boolean(formik.errors.project) &&
                  Boolean(formik.touched.project)
                }
              >
                <Typography>Project</Typography>
                <Select
                  // styles={{
                  //   control: (baseStyles) => ({
                  //     ...baseStyles,
                  //     borderColor:
                  //       Boolean(formik.errors.project) &&
                  //       Boolean(formik.touched.project)
                  //         ? "red"
                  //         : "#bdbdbd",
                  //   }),
                  // }}
                  styles={ReactSelectTheme(
                    Boolean(formik.errors.project) &&
                      Boolean(formik.touched.project)
                  )}
                  name="project"
                  options={projOptions}
                  value={formik.values.project}
                  onChange={(value) => {
                    formik.setFieldValue("project", value);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Select Project"
                />
                <FormHelperText>
                  {Boolean(formik.errors.project) &&
                  Boolean(formik.touched.project)
                    ? formik.errors.project
                    : ""}
                </FormHelperText>
              </FormControl>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Section Name</Typography>
                  <TextField
                    name="name"
                    value={formik.values?.name}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name)
                    }
                    helperText={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name) &&
                      formik.errors.name
                    }
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.type) && Boolean(formik.touched.type)
                  }
                >
                  <Typography>Section Type</Typography>
                  <Select
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.type) &&
                    //       Boolean(formik.touched.type)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.type) &&
                        Boolean(formik.touched.type)
                    )}
                    name="type"
                    options={sectionOptions}
                    value={formik.values.type}
                    onChange={(value) => {
                      formik.setFieldValue("type", value);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select Type"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.type) && Boolean(formik.touched.type)
                      ? formik.errors.type
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box display="flex" gap={1}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.category) &&
                    Boolean(formik.touched.category)
                  }
                >
                  <Typography>Category</Typography>
                  <Select
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.category) &&
                    //       Boolean(formik.touched.category)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.category) &&
                        Boolean(formik.touched.category)
                    )}
                    name="category"
                    options={catOptions}
                    value={formik.values.category}
                    // onChange={(value) => {
                    //   formik.setFieldValue("category", value);
                    // }}
                    onChange={handleCategoryChange}
                    onBlur={formik.handleBlur}
                    placeholder="Select Category"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.category) &&
                    Boolean(formik.touched.category)
                      ? formik.errors.category
                      : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.category) &&
                    Boolean(formik.touched.category)
                  }
                >
                  <Typography>Sub Category</Typography>
                  <Select
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.subcategory) &&
                    //       Boolean(formik.touched.subcategory)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.subcategory) &&
                        Boolean(formik.touched.subcategory)
                    )}
                    name="subcategory"
                    options={subCatOptions}
                    value={formik.values.subcategory}
                    // onChange={(value) => {
                    //   formik.setFieldValue("subcategory", value);
                    // }}
                    onChange={handleSubCatChange}
                    onBlur={formik.handleBlur}
                    placeholder="Select Category"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.subcategory) &&
                    Boolean(formik.touched.subcategory)
                      ? formik.errors.subcategory
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box display="flex" gap={1}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.downPay) &&
                    Boolean(formik.touched.downPay)
                  }
                >
                  <Typography>Down Payment</Typography>
                  <OutlinedInput
                    name="downPay"
                    type="number"
                    value={formik.values?.downPay}
                    size={"small"}
                    fullWidth
                    inputProps={{ max: 100 }}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.downPay) &&
                      Boolean(formik.touched.downPay)
                    }
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.downPay) &&
                      Boolean(formik.touched.downPay) &&
                      formik.errors.downPay}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.minDownPay) &&
                    Boolean(formik.touched.minDownPay)
                  }
                >
                  <Typography>Min Down Payment</Typography>
                  <OutlinedInput
                    name="minDownPay"
                    value={formik.values?.minDownPay}
                    size={"small"}
                    fullWidth
                    type="number"
                    inputProps={{ max: 100 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    error={
                      Boolean(formik.errors.minDownPay) &&
                      Boolean(formik.touched.minDownPay)
                    }
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.minDownPay) &&
                      Boolean(formik.touched.minDownPay) &&
                      formik.errors.minDownPay}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <Typography mb={1}>Discount Details</Typography>

                <TableContainer  sx={{}}>
                  <Table
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",

                            // color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Payment
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            // color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Discount
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            size="large"
                            color="info"
                            sx={{ borderRadius: "8px" }}
                            onClick={handleAddField}
                          >
                            + Add More
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values.discountDetail.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell size="small" align="left">
                            <FormControl
                              fullWidth
                              error={Boolean(
                                formik.errors?.discountDetail?.[i]?.paidPer &&
                                  formik.touched?.discountDetail?.[i]?.paidPer
                              )}
                            >
                              <OutlinedInput
                                value={row.paidPer}
                                type="number"
                                name={`discountDetail.${i}.paidPer`}
                                size="small"
                                onChange={(e) => handleChange(e, i, "paidPer")}
                                onBlur={(e) => {
                                  handleBlur(e, i, "paidPer"),
                                    formik.handleBlur(e);
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                }
                              />

                              <FormHelperText>
                                {Boolean(
                                  formik.errors?.discountDetail?.[i]?.paidPer
                                ) &&
                                  Boolean(
                                    formik.touched?.discountDetail?.[i]?.paidPer
                                  ) &&
                                  formik.errors?.discountDetail?.[i]?.paidPer}
                              </FormHelperText>
                            </FormControl>
                          </TableCell>
                          <TableCell size="small" align="left">
                            <FormControl
                              fullWidth
                              error={Boolean(
                                formik.errors?.discountDetail?.[i]
                                  ?.discountPer &&
                                  formik.touched?.discountDetail?.[i]
                                    ?.discountPer
                              )}
                            >
                              <OutlinedInput
                                value={row.discountPer}
                                type="number"
                                name={`discountDetail.${i}.discountPer`}
                                size="small"
                                onChange={(e) =>
                                  handleChange(e, i, "discountPer")
                                }
                                onBlur={(e) => {
                                  handleBlur(e, i, "discountPer"),
                                    formik.handleBlur(e);
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText>
                                {Boolean(
                                  formik.errors?.discountDetail?.[i]
                                    ?.discountPer
                                ) &&
                                  Boolean(
                                    formik.touched?.discountDetail?.[i]
                                      ?.discountPer
                                  ) &&
                                  formik.errors?.discountDetail?.[i]
                                    ?.discountPer}
                              </FormHelperText>
                            </FormControl>
                          </TableCell>
                          <TableCell size="small" align="center">
                            {formik.values.discountDetail.length > 1 && (
                              <DeleteForeverIcon
                                fontSize="small"
                                sx={{
                                  color: (theme) =>
                                    theme?.palette?.primary?.delete,
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDel(i)}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
      {openModal && catOptions.length > 0 && (
        <AddSubCatModal
          showModal={openModal}
          handleClosed={handleClosed}
          setsnackBarData={setsnackBarData}
          dispatch={dispatch}
          catOptions={catOptions}
        />
      )}
    </Box>
  );
}

export default CreateSection;
