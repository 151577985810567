import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  // OutlinedInput,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import theme from "../../../../theme";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
      alignItems: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
  };
});
function General({
  setgeneralData,
  handleNext,
  generalData,
  singleUser,
  setsingleUser,
  state,
}) {
  const {  subContainer, formContainer } = useStyle();
  const initialValues = {
    fullName: "",
    email: "",
    status: false,
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    if (generalData) {
      formik.setFieldValue(
        "fullName",
        generalData ? generalData?.fullName : ""
      );
      formik.setFieldValue("email", generalData ? generalData?.email : "");
      formik.setFieldValue("status", generalData ? generalData?.status : false);
      formik.setFieldValue(
        "password",
        generalData ? generalData?.password : ""
      );
      formik.setFieldValue(
        "confirmPassword",
        generalData ? generalData?.confirmPassword : ""
      );
    }
  }, [generalData]);
  useEffect(() => {
    if (singleUser && state) {
      formik.setFieldValue("fullName", singleUser?.FullName);
      formik.setFieldValue("email", singleUser?.Email);
      formik.setFieldValue(
        "status",
        singleUser?.Status ? singleUser?.Status : false
      );
      formik.setFieldValue("password", "DummyData");
      formik.setFieldValue("confirmPassword", "DummyData");
    }
  }, [singleUser]);

  const [showPassword, setshowPassword] = useState(false);
  const [confirmpass, setconfirmpass] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    // setSubmitting(true);
    // const obj = { ...values, country: value?.label };
    setgeneralData(values);
    handleNext();
    console.log("this is submit of general");
    // setSubmitting(false);
    // resetForm();
  };
  const handleNavigate = () => {
    setsingleUser(null);
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please Enter Full Name"),
    email: Yup.string().required("Please Enter Email"),
    password: Yup.string()
      .required("Please Enter Password")
      .min(8, "Password must be 8 characters long")
      .matches(/[A-Z]/, "Must have at least one uppercase character"),
      confirmPassword: Yup.string()
      .required("Please Confirm Password")
      .oneOf([Yup.ref("password"), null], "Both passwords must match"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
console.log("these are user fporm values", formik.values);
  return (
    <Box className={subContainer}>
      <Paper elevation={3} sx={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box className={formContainer}>
            <FormControl fullWidth>
              <Typography>Name</Typography>
              <TextField
                name="fullName"
                value={formik.values?.fullName}
                size={"small"}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors.fullName) &&
                  (Boolean(formik.touched.fullName) || formik.submitCount > 0)
                }
                helperText={
                  Boolean(formik.errors.fullName) &&
                  (Boolean(formik.touched.fullName) || formik.submitCount > 0)
                    ? formik.errors.fullName
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Email</Typography>
              <TextField
                name="email"
                type="email"
                value={formik.values?.email}
                size={"small"}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={singleUser && state ? true : false}
                error={
                  Boolean(formik.errors.email) &&
                  (Boolean(formik.touched.email) || formik.submitCount > 0)
                }
                helperText={
                  Boolean(formik.errors.email) &&
                  (Boolean(formik.touched.email) || formik.submitCount > 0)
                    ? formik.errors.email
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Status</Typography>
              <Switch
                name="status"
                value={formik.values?.status}
                size={"large"}
                checked={formik.values?.status}
                fullWidth
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Password</Typography>
              <OutlinedInput
                name="password"
                type={
                  singleUser && state
                    ? "password"
                    : showPassword
                    ? "text"
                    : "password"
                }
                value={formik.values?.password}
                size={"small"}
                fullWidth
                disabled={singleUser && state ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors.password) &&
                  Boolean(formik.touched.password)
                  // || formik.submitCount > 0
                }
                helperText={
                  Boolean(formik.errors.password) &&
                  Boolean(formik.touched.password)
                    ? //  || formik.submitCount > 0
                      formik.errors.password
                    : ""
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setshowPassword(!showPassword);
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Confirm Password</Typography>
              <OutlinedInput
                name="confirmPassword"
                type={
                  singleUser && state
                    ? "password"
                    : confirmpass
                    ? "text"
                    : "password"
                }
                value={formik.values?.confirmPassword}
                size={"small"}
                fullWidth
                disabled={singleUser && state ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  Boolean(formik.errors.confirmPassword) &&
                  Boolean(formik.touched.confirmPassword)
                  // ||
                  //   formik.submitCount > 0
                }
                helperText={
                  Boolean(formik.errors.confirmPassword) &&
                  Boolean(formik.touched.confirmPassword)
                    ? // ||
                      //   formik.submitCount > 0
                      formik.errors.confirmPassword
                    : ""
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setconfirmpass(!confirmpass);
                      }}
                      edge="end"
                    >
                      {confirmpass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  <Button onClick={handleNavigate}>
                    <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                  </Button>
                </Box>
                <Box>
                  {/* {
                  activeStep === steps.length - 1 ?    <Button type='submit' sx={{ backgroundColor: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.white.main }}>
                  less
                </Button> :

                 
                    <Button sx={{ backgroundColor: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.white.main }} onClick={handleNext}>
                    Next
                  </Button>
                } */}

                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.white.main,
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  );
}

export default General;
General.propTypes = {
  activeStep: PropTypes.any.isRequired,
  steps: PropTypes.string.isRequired,
  handleNext: PropTypes.any,
  setgeneralData: PropTypes.any,
  generalData: PropTypes.any,
  singleUser: PropTypes.any,
  setsingleUser: PropTypes.any,
  state: PropTypes.any,
};
