import {
  Box,
  Divider,
  LinearProgress,
  linearProgressClasses,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
// import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import theme from "../../theme";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const useStyles = makeStyles(() => {
  return {
    totalBox: {
      display: "flex",
      gap: "5px",
    },
    contentBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "space-between",
      //   padding: "15px",
    },
  };
});
function ActivitiesCard({ DashboardData }) {
  const { contentBox, totalBox } = useStyles();

  return (
    <Box
      sx={{
        width: "33%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
      id="dashboard-activities"
    >
      <Paper elevation={3} sx={{ height: "350px" }}>
        <Typography sx={{ padding: "15px", fontWeight: "bold" }}>
          Total Activities
        </Typography>
        <Divider sx={{ width: "100%" }} />
        <Box
          sx={{
            padding: "15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "40px",
            height: "289px",
            overflow: "scroll",
          }}
        >
          {DashboardData?.ActivityTypes?.map((activity, i) => (
            <Box key={i} className={contentBox}>
              <Box sx={{ width: "80px" }}>
                <Typography>{activity?.ActivityTypeName}</Typography>
              </Box>
              <BorderLinearProgress
                variant="determinate"
                sx={{ width: "100%" }}
                value={
                  (activity?.Count / DashboardData?.TotalActivityData) * 100
                }
              />

              <Box className={totalBox}>
                <Typography>{activity?.Count}</Typography>
                <Typography>/</Typography>
                <Typography>{DashboardData?.TotalActivityData}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  );
}

export default ActivitiesCard;
ActivitiesCard.propTypes = {
  DashboardData: PropTypes.any,
};
