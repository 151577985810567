import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AttachmentIcon from "@mui/icons-material/Attachment";
import InboxFilterDrawer from "../../Components/Email/InboxFilterDrawer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment/moment";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function Inbox({ roles }) {
  const { header } = useStyles();
  const [pagesize, setpagesize] = useState(5);
  const [rows, setrows] = React.useState([]);
  const [allInbox, setallInbox] = useState([]);
  const [count, setcount] = useState(0);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState({
    from: "",
    subject: "",
    createdate: "",
    enddate: "",
  });
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge
                badgeContent={count}
                color="primary"
              >
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        console.log(value);
        sum = sum + 1;
      }
    }
    setcount(sum);
    console.log(sum);
  }, [filterVal]);
  useEffect(() => {
    let rowData = [];
    Array(100)
      .fill("")
      .map((data, i) => {
        rowData.push({
          id: i + 1,
          from: `Aiksol${i + 1}`,
          subject: `math${i + 1}`,
          createddate: `02-04-201${i + 1}`,
          enddate: `02-04-201${i + 1}`,
          icon: `a${i + 1}`,
        });
      });
    setrows(rowData);
    setallInbox(rowData);
  }, []);
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: header },
    {
      field: "icon",
      headerName: <AttachmentIcon fontSize="small" />,
      width: 70,
      headerClassName: header,
      renderCell: (cellval) => {
        return (
          <Tooltip title={cellval.row.icon}>
            <IconButton
            //  onClick={() => { handleEdit(cellVal.row); }}
            >
              <AttachmentIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "from",
      headerName: "From",
      width: 150,
      headerClassName: header,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 150,
      headerClassName: header,
    },
    {
      field: "createddate",
      headerName: "Created Date",
      width: 150,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerClassName: header,
      renderCell: () => {
        return (
          <Box>
            <Tooltip title="View">
              <IconButton
                //  onClick={() => { handleEdit(cellVal.row); }}
                disabled={
                  roles?.some((role) => role === "Mail_View") ? false : true
                }
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                disabled={
                  roles?.some((role) => role === "Mail_Delete") ? false : true
                }
              >
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  const handleChangeVal = (e) => {
    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value || "",
    });
  };
  useEffect(() => {
    console.log(rows);
    console.log(filterVal);
    if (
      filterVal?.from === "" &&
      filterVal?.subject === "" &&
      filterVal?.createdate === "" &&
      filterVal?.enddate === ""
    ) {
      // console.log(salesPersonRef.current?.value);
      setallInbox(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(filterVal?.from, "i");
      let regex2 = new RegExp(filterVal?.subject, "i");
      let regex3 = new RegExp(
        moment(filterVal?.createdate).format("DD-MM-YYYY"),
        "i"
      );
      let regex4 = new RegExp(
        moment(filterVal?.enddate).format("DD-MM-YYYY"),
        "i"
      );
      console.log(regex2);
      console.log(rows);
      suggestions = rows?.filter(
        (val) =>
          (filterVal?.from === "" || regex1.test(val?.from)) &&
          (filterVal?.subject === "" || regex2.test(val.subject)) &&
          (filterVal?.createdate === "" || regex3.test(val?.createdate)) &&
          (filterVal?.enddate === "" || regex4.test(val?.enddate))
      );
      setallInbox(suggestions);
    }
  }, [filterVal, rows]);
  return (
    <Box>
      <InboxFilterDrawer
        setcount={setcount}
        handleChangeVal={handleChangeVal}
        rows={rows}
        setallInbox={setallInbox}
        filterVal={filterVal}
        setfilterVal={setfilterVal}
        setdrawerOpen={setdrawerOpen}
        drawerOpen={drawerOpen}
      />
      <Box
        sx={{
          height: "calc(100vh - 77px)",
          width: "100%",
          overflowX: "visible",
          backgroundColor: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <DataGrid
          pageSize={pagesize}
          onPageSizeChange={(newPage) => {
            setpagesize(newPage);
          }}
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={allInbox}
          columns={columns}
        />
      </Box>
    </Box>
  );
}

export default Inbox;
Inbox.propTypes = {
  roles: PropTypes.array,
};
