import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  Paper,
  Switch,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Breadcrumbs,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import theme from "../../../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { CreatePiplinesApi } from "../../../../Api/Settings/CreatePiplinesApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useEffect } from "react";
import { UpdatePiplineApi } from "../../../../Api/Settings/PiplinesApi/UpdatePiplineApi";

const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});
function CreatePiplines() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [stage, setstage] = useState([
    { id: 0, name: "", probability: 100, sortOrder: 2 },
  ]);

  const initialValues = {
    name: "",
    rottingdays: "",
    default: false,
    nam: "New",
    probability: 100,
    won: "Won",
    lost: "Lost",
    wonprob: 100,
    lostprob: 0,
  };

  useEffect(() => {
    if (state) {
      let arr = [];
      handleEdit(state);
      state?.LeadPipelineStages?.map((stage, i) => {
        if (i !== 0 && i < state?.LeadPipelineStages.length - 2) {
          arr.push({
            id: stage?.Id,
            name: stage?.Name,
            probability: stage?.Probability,
            sortOrder: stage?.SortOrder,
          });
        }
      });

      setstage(arr);
    }
  }, [state]);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
    formik.handleChange({
      target: {
        name: "description",
        value: values?.description || "",
      },
    });
    formik.handleChange({
      target: {
        name: "default",
        value: values?.isdefault,
      },
    });
    formik.handleChange({
      target: {
        name: "rottingdays",
        value: values?.RottenDays || "",
      },
    });
  };

  const handleAddStageField = () => {
    setstage([...stage, { id: 0, name: "", probability: 100, sortOrder: 2 }]);
  };
  const navigate = useNavigate();
  const handleDel = (row, i) => {
    let value = JSON.parse(JSON.stringify(stage));

    value.splice(i, 1);

    // let value = contact.filter((cont)=>cont.id!==contct.id);
    // console.log(value);
    setstage(value);
  };

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const obj = {
      name: values.name,
      isDefualt: values.default,
      rottenDays: Number(values.rottingdays),
      leadPipelineStages: [
        {
          name: values?.nam,
          probability: Number(values?.probability),
          sortOrder: 1,
        },
        ...stage,

        {
          name: values?.won,
          probability: Number(values?.wonprob),
          sortOrder: stage?.length + 2,
        },
        {
          name: values?.lost,
          probability: Number(values?.lostprob),
          sortOrder: stage?.length + 3,
        },
      ],
    };

    if (state) {
      let newid = 0;
      let wonid = 0;
      let lostid = 0;
      state?.LeadPipelineStages?.map((stage, i) => {
        if (i === 0) {
          newid = stage?.Id;
        }
        if (i === state?.LeadPipelineStages.length - 1) {
          lostid = stage?.Id;
        }
        if (i === state?.LeadPipelineStages.length - 2) {
          wonid = stage?.Id;
        }
      });
      const obj2 = {
        name: values.name,
        isDefualt: values.default,
        rottenDays: Number(values.rottingdays),
        leadPipelineStages: [
          {
            id: newid,
            name: values?.nam,
            probability: Number(values?.probability),
            sortOrder: 1,
          },
          ...stage,

          {
            id: wonid,
            name: values?.won,
            probability: Number(values?.wonprob),
            sortOrder: stage?.length + 2,
          },
          {
            id: lostid,
            name: values?.lost,
            probability: Number(values?.lostprob),
            sortOrder: stage?.length + 3,
          },
        ],
      };

      const response = await UpdatePiplineApi({ obj: obj2, id: state?.Id });
      setsnackBarData(response.snackBarData);

      if (response?.data?.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
      setSubmitting(false);
    } else {
      const response = await CreatePiplinesApi(obj);
      setsnackBarData(response.snackBarData);

      if (response?.data?.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
      setSubmitting(false);
    }
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter  Name"),
    rottingdays: Yup.string().required("Please Enter rottingdays"),
    probability: Yup.string().required("Please Enter probability"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/settings"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Settings
              </Link>
              <Link
                to="/settings/piplines"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Piplines
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state ? "Update Piplines" : "Create Piplines"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state ? "Update Piplines" : "Create Piplines"}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  value={formik.values?.name}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                  }
                  helperText={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                      ? formik.errors.name
                      : ""
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Rotting Days</Typography>
                <TextField
                  name="rottingdays"
                  value={formik.values?.rottingdays}
                  type={"number"}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.rottingdays) &&
                    Boolean(formik.touched.rottingdays)
                  }
                  helperText={
                    Boolean(formik.errors.rottingdays) &&
                    Boolean(formik.touched.rottingdays)
                      ? formik.errors.rottingdays
                      : ""
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Mark as Default</Typography>
                <Switch
                  name="default"
                  value={formik.values?.default}
                  size={"large"}
                  checked={formik.values?.default}
                  fullWidth
                  onChange={formik.handleChange}
                />
              </FormControl>
              <Box>
                <TableContainer component={Paper} sx={{}}>
                  <Table
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            // color: (theme) => theme.palette.grey["500"],
                          }}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            // color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            // color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Probability
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell size="small" align="left"></TableCell>
                        <TableCell size="small" align="left">
                          <TextField
                            fullWidth
                            value={formik.values.nam}
                            name="nam"
                            size="small"
                            disabled
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <TextField
                            fullWidth
                            value={formik.values.probability}
                            type={"number"}
                            name="probability"
                            size="small"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              if (e.target.value > 100) {
                                // formik.setFieldError(
                                //   "probability",
                                //   "The Probability (%) field must be 100 or less"
                                // );
                              } else {
                                formik.setFieldValue(
                                  "probability",
                                  e.target.value
                                );
                              }
                            }}
                            error={
                              Boolean(formik.errors.probability) &&
                              Boolean(formik.touched.probability)
                            }
                            helperText={
                              Boolean(formik.errors.probability) &&
                              Boolean(formik.touched.probability)
                                ? formik.errors.probability
                                : ""
                            }
                            // onChange={(e) => {
                            //
                            //     let value = JSON.parse(JSON.stringify(quoteItem));
                            //     value[i].name = e.target.value;
                            //     setquoteItem(value);

                            // }}
                          />
                        </TableCell>
                      </TableRow>
                      {stage &&
                        stage?.map((row, i) => {
                          let order = 2;
                          order = order + i;

                          row.sortOrder = order;
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell size="small" align="left">
                                <FormatListBulletedIcon />
                              </TableCell>
                              <TableCell size="small" align="left">
                                <TextField
                                  fullWidth
                                  value={row?.name}
                                  name="name"
                                  size="small"
                                  onChange={(e) => {
                                    let value = JSON.parse(
                                      JSON.stringify(stage)
                                    );
                                    value[i].name = e.target.value;
                                    setstage(value);
                                  }}
                                />
                              </TableCell>
                              <TableCell size="small" align="left">
                                <TextField
                                  fullWidth
                                  value={row?.probability}
                                  type={"number"}
                                  name="probability"
                                  size="small"
                                  onChange={(e) => {
                                    if (e.target.value <= 100) {
                                      let value = JSON.parse(
                                        JSON.stringify(stage)
                                      );
                                      value[i].probability = e.target.value;
                                      setstage(value);
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell size="small" align="left">
                                {stage?.length > 1 ? (
                                  <DeleteIcon
                                    fontSize="small"
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                    }}
                                    onClick={() => handleDel(row, i)}
                                  />
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell size="small" align="left"></TableCell>
                        <TableCell size="small" align="left">
                          <TextField
                            fullWidth
                            value={formik.values.won}
                            name="won"
                            size="small"
                            disabled
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <TextField
                            fullWidth
                            value={formik.values.wonprob}
                            name="wonprob"
                            size="small"
                            disabled

                            // onChange={(e) => {
                            //
                            //     let value = JSON.parse(JSON.stringify(quoteItem));
                            //     value[i].name = e.target.value;
                            //     setquoteItem(value);

                            // }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell size="small" align="left"></TableCell>
                        <TableCell size="small" align="left">
                          <TextField
                            fullWidth
                            value={formik.values.lost}
                            name="lost"
                            size="small"
                            disabled
                          />
                        </TableCell>
                        <TableCell size="small" align="left">
                          <TextField
                            fullWidth
                            value={formik.values.lostprob}
                            name="lostprob"
                            size="small"
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ width: "fit-content" }}>
                <Button
                  onClick={handleAddStageField}
                  sx={{
                    cursor: "pointer",
                    textTransform: "none",
                    color: "white !important",
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                >
                  Add Stage
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreatePiplines;
