import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { CreateRolesApi } from "../../../../Api/Settings/Roles/CreateRolesApi";
import { fetchRoles } from "../../../../Redux/Slice/Settings/RolesSlice/RolesSlice";
import { useDispatch, useSelector } from "react-redux";
import { UpdateRolesApi } from "../../../../Api/Settings/Roles/UpdateRolesApi";
import FormModal from "./FormModal";
import { getScreensDetails } from "../../../../Redux/Slice/Settings/ScreensSlice/ScreensSlice";
import { fetchScreens } from "../../../../Redux/Slice/Settings/ScreensSlice/ScreensSlice";
import Loader from "../../../Loader/Loader";

// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(() => ({
  // border: `1px solid ${theme.palette.divider}`,
  border: "none !important",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", padding: "0px" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    border: "none",
    padding: "0px",
    height: "10px",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    padding: "0px",
    height: "10px",
    border: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(7),
  padding: "0px",
  border: "none",
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const useStyle = makeStyles((theme) => {
  console.log(theme);
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    accordBox: {
      display: "flex",
      flexDirection: "column",
    },
  };
});
function CreateRole() {
  const { container, subContainer, formContainer, accordBox } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const [renderComp, setrenderComp] = useState(false);
  const { Screens, status } = useSelector(getScreensDetails);
  const [allScreens, setallScreens] = useState([]);
  // const [allRoles] = useState([
  //   {
  //     name: "Dashboard",
  //     rolename: "Dashboard",
  //     status: false,
  //   },
  //   {
  //     name: "Leads",
  //     rolename: "Leads",
  //     status: false,
  //     child: [
  //       {
  //         name: "Create",
  //         rolename: "Create Lead",
  //         status: false,
  //       },
  //       {
  //         name: "View",
  //         rolename: "View Lead",
  //         status: false,
  //       },
  //       {
  //         name: "Edit",
  //         rolename: "Edit Lead",
  //         status: false,
  //       },
  //       {
  //         name: "Delete",
  //         rolename: "Delete Lead",
  //         status: false,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Quote",
  //     status: false,
  //     rolename: "Quote",
  //     child: [
  //       {
  //         name: "Create",
  //         rolename: "Create Quote",
  //         status: false,
  //       },
  //       {
  //         name: "Print",
  //         rolename: "Print Quote",
  //         status: false,
  //       },
  //       {
  //         name: "Edit",
  //         rolename: "Edit Quote",
  //         status: false,
  //       },
  //       {
  //         name: "Delete",
  //         rolename: "Delete Quote",
  //         status: false,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Mail",
  //     status: false,
  //     rolename: "Mail",
  //     child: [
  //       {
  //         name: "Compose",
  //         rolename: "Compose",
  //         status: false,
  //       },
  //       {
  //         name: "Draft",
  //         rolename: "Draft",
  //         status: false,
  //       },
  //       {
  //         name: "Outbox",
  //         rolename: "Outbox",
  //         status: false,
  //       },
  //       {
  //         name: "Sent",
  //         rolename: "Sent",
  //         status: false,
  //       },
  //       {
  //         name: "Trash",
  //         rolename: "Trash",
  //         status: false,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Activities",
  //     rolename: "Activities",
  //     status: false,
  //     child: [
  //       {
  //         name: "Create",
  //         rolename: "Create Activity",
  //         status: false,
  //       },
  //       {
  //         name: "Edit",
  //         rolename: "Edit Activity",
  //         status: false,
  //       },
  //       {
  //         name: "Delete",
  //         rolename: "Delete Activity",
  //         status: false,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Contacts",
  //     rolename: "Contacts",
  //     status: false,
  //     child: [
  //       {
  //         name: "Persons",
  //         rolename: "Persons",
  //         status: false,
  //         child: [
  //           {
  //             name: "Create",
  //             rolename: "Create Person",
  //             status: false,
  //           },
  //           {
  //             name: "Edit",
  //             rolename: "Edit Person",
  //             status: false,
  //           },
  //           {
  //             name: "Delete",
  //             rolename: "Delete Person",
  //             status: false,
  //           },
  //         ],
  //       },
  //       {
  //         name: "Organizations",
  //         rolename: "Organizations",
  //         status: false,
  //         child: [
  //           {
  //             name: "Create",
  //             rolename: "Create Organization",
  //             status: false,
  //           },
  //           {
  //             name: "Edit",
  //             rolename: "Edit Organization",
  //             status: false,
  //           },
  //           {
  //             name: "Delete",
  //             rolename: "Delete Organization",
  //             status: false,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Products",
  //     rolename: "Products",
  //     status: false,
  //     child: [
  //       {
  //         name: "Create",
  //         rolename: "Create Product",
  //         status: false,
  //       },
  //       {
  //         name: "Edit",
  //         rolename: "Edit Product",
  //         status: false,
  //       },
  //       {
  //         name: "Delete",
  //         rolename: "Delete Product",
  //         status: false,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Settings",
  //     rolename: "Settings",
  //     status: false,
  //     child: [
  //       {
  //         name: "User",
  //         rolename: "User",
  //         status: false,
  //         child: [
  //           {
  //             name: "Groups",
  //             rolename: "Groups",
  //             status: false,
  //             child: [
  //               {
  //                 name: "Create",
  //                 rolename: "Create Group",
  //                 status: false,
  //               },
  //               {
  //                 name: "Edit",
  //                 rolename: "Edit Group",
  //                 status: false,
  //               },
  //               {
  //                 name: "Delete",
  //                 rolename: "Delete Group",
  //                 status: false,
  //               },
  //             ],
  //           },
  //           {
  //             name: "Roles",
  //             rolename: "Roles",
  //             status: false,
  //             child: [
  //               {
  //                 name: "Create",
  //                 rolename: "Create Role",
  //                 status: false,
  //               },
  //               {
  //                 name: "Edit",
  //                 rolename: "Edit Role",
  //                 status: false,
  //               },
  //               {
  //                 name: "Delete",
  //                 rolename: "Delete Role",
  //                 status: false,
  //               },
  //             ],
  //           },
  //           {
  //             name: "Users",
  //             rolename: "Users",
  //             status: false,
  //             child: [
  //               {
  //                 name: "Create",
  //                 rolename: "Create User",
  //                 status: false,
  //               },
  //               {
  //                 name: "Edit",
  //                 rolename: "Edit User",
  //                 status: false,
  //               },
  //               {
  //                 name: "Delete",
  //                 rolename: "Delete User",
  //                 status: false,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         name: "Lead",
  //         rolename: "Lead",
  //         status: false,
  //         child: [
  //           {
  //             name: "Pipelines",
  //             rolename: "Pipelines",
  //             status: false,
  //             child: [
  //               {
  //                 name: "Create",
  //                 rolename: "Create Pipeline",
  //                 status: false,
  //               },
  //               {
  //                 name: "Edit",
  //                 rolename: "Edit Pipeline",
  //                 status: false,
  //               },
  //               {
  //                 name: "Delete",
  //                 rolename: "Delete Pipeline",
  //                 status: false,
  //               },
  //             ],
  //           },
  //           {
  //             name: "Sources",
  //             rolename: "Sources",
  //             status: false,
  //             child: [
  //               {
  //                 name: "Create",
  //                 rolename: "Create Sources",
  //                 status: false,
  //               },
  //               {
  //                 name: "Edit",
  //                 rolename: "Edit Sources",
  //                 status: false,
  //               },
  //               {
  //                 name: "Delete",
  //                 rolename: "Delete Sources",
  //                 status: false,
  //               },
  //             ],
  //           },
  //           {
  //             name: "Types",
  //             rolename: "Types",
  //             status: false,
  //             child: [
  //               {
  //                 name: "Create",
  //                 rolename: "Create Types",
  //                 status: false,
  //               },
  //               {
  //                 name: "Edit",
  //                 rolename: "Edit Types",
  //                 status: false,
  //               },
  //               {
  //                 name: "Delete",
  //                 rolename: "Delete Types",
  //                 status: false,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         name: "Other Settings",
  //         rolename: "Other Settings",
  //         status: false,
  //         child: [
  //           {
  //             name: "Web Forms",
  //             rolename: "Web Forms",
  //             status: false,
  //             child: [
  //               {
  //                 name: "Create",
  //                 rolename: "Create Web Forms",
  //                 status: false,
  //               },
  //               {
  //                 name: "Edit",
  //                 rolename: "Edit Web Forms",
  //                 status: false,
  //               },
  //               {
  //                 name: "Delete",
  //                 rolename: "Delete Web Forms",
  //                 status: false,
  //               },
  //             ],
  //           },
  //           {
  //             name: "Tags",
  //             rolename: "Tags",
  //             status: false,
  //             child: [
  //               {
  //                 name: "Create",
  //                 rolename: "Create Tag",
  //                 status: false,
  //               },
  //               {
  //                 name: "Edit",
  //                 rolename: "Edit Tag",
  //                 status: false,
  //               },
  //               {
  //                 name: "Delete",
  //                 rolename: "Delete Tag",
  //                 status: false,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ]);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    dispatch(fetchScreens());
  }, []);
  useEffect(() => {
    if (Screens) {
      let data = JSON.stringify(Screens);
      setallScreens(JSON.parse(data));
    }
  }, [Screens]);
  // const recursiveFunForApi = (data) => {
  //   if (data.status) {
  //     if (data.Child?.length <= 0) {
  //       console.log(data);
  //       return [
  //         {
  //           screenId: data?.ScreenId,
  //         },
  //       ];
  //     } else {
  //       let obj = [{ screenId: data?.ScreenId }];
  //       data?.Child?.forEach((role) => {
  //         if (role?.Child?.length > 0) {
  //           return recursiveFunForApi(role);
  //         } else {
  //           return obj.push({
  //             screenId: role?.ScreenId,
  //           });
  //         }
  //       });

  //       return obj;
  //     }
  //   }
  // };
  // const recursiveFunForPrefill = (data) => {
  //   if (data.Child?.length <= 0) {
  //     return (data.status = true);
  //   } else {
  //     data.status = true;
  //     data?.Child?.forEach((role) => {
  //       if (role?.Child?.length > 0) {
  //         return recursiveFunForPrefill(role);
  //       } else {
  //         return (role.status = true);
  //       }
  //     });

  //     return;
  //   }
  // };

  // else if(data?.Child.length >0) {
  //   console.log(data?.Child);

  //   let obj = [{ screenId: data?.ScreenId }];
  //   data?.Child?.forEach((role) => {
  //   // console.log(role);
  //       if ( role.status) {
  //         console.log(role);
  //         obj.push({
  //           screenId: role?.ScreenId,
  //         });
  //       } else if(role?.Child.length>0)  {
  //         recursiveFunForApi(role);

  //       }

  //   });

  //   return obj;
  // }
  const recursiveFunForApi = (data, arr) => {
    console.log(data);
    if (data.status) {
      if (data.Child?.length <= 0) {
        if (data?.RoleScreenPermissionId) {
          arr.push({
            screenId: data?.ScreenId,
            RoleScreenPermissionId: data?.RoleScreenPermissionId,
          });
        } else {
          arr.push({
            screenId: data?.ScreenId,
          });
        }
      } else {
        if (data?.RoleScreenPermissionId) {
          arr.push({
            screenId: data?.ScreenId,
            RoleScreenPermissionId: data?.RoleScreenPermissionId,
          });
        } else {
          arr.push({
            screenId: data?.ScreenId,
          });
        }
        data?.Child?.forEach((role) => {
          if (role?.Child?.length > 0) {
            recursiveFunForApi(role, arr);
          } else if (role.status) {
            if (role?.RoleScreenPermissionId) {
              arr.push({
                screenId: role?.ScreenId,
                RoleScreenPermissionId: role?.RoleScreenPermissionId,
              });
            } else {
              arr.push({
                screenId: role?.ScreenId,
              });
            }
          }
        });
      }
    }
  };
  // const arr = [];
  // let screenData = allScreens.forEach((item) => {
  //   if(item.status){
  //   recursiveFunForApi(item, arr);
  // }
  // });
  // console.log(arr);
  // console.log(screenData);
  // const recursiveFunForApi = (data) => {
  //   let obj = [];
  //   if (data.Child.length <= 0) {
  //     return obj.push({
  //       screenId: data?.ScreenId,
  //     });
  //   } else {
  //      data?.Child?.forEach((role) => {
  //       if (role.status) {
  //         return obj.push({
  //           screenId: role?.ScreenId,
  //         });
  //       }
  //      else if (role?.Child?.length > 0) {
  //         return recursiveFunForApi(role);
  //       }
  //     });
  //   }
  //   return obj;
  // };
  // const arr = [];
  // let screenData = allScreens.forEach((item) => {
  //   if(item.status){
  //   recursiveFunForApi(item, arr);
  // }

  //   // if (item.status) {
  //   //   let abc = recursiveFunForApi(item);
  //   //   console.log(abc);
  //   //   arr.push(...abc);
  //   // }
  // });
  // console.log(allScreens);
  // console.log(arr);
  // console.log(screenData);
  const recursiveFunForPrefill = (data, abc) => {
    let findObj = abc?.find((it) => it.ScreenId === data.ScreenId);
    console.log(findObj);
    if (findObj) {
      data.status = true;
      data.RoleScreenPermissionId = findObj?.RoleScreenPermissionId;
      // return data;
    }
    if (data.Child?.length > 0) {
      data?.Child?.forEach((role) => {
        let findObj = abc?.find((it) => it.ScreenId === role.ScreenId);
        if (findObj) {
          if (role?.Child?.length > 0) {
            recursiveFunForPrefill(role, abc);
          } else {
            role.status = true;
            role.RoleScreenPermissionId = findObj?.RoleScreenPermissionId;
          }
        }
      });
    }
  };
  // const recursiveFunForPrefill = (data, abc) => {
  //   // console.log(data);
  //   if (data.Child?.length <= 0) {
  //     // return ({...data,status:true,RoleScreenPermissionId:abc.RoleScreenPermissionId});
  //     return (data.status = true);
  //   } else {
  //     data.status = true;
  //     data?.Child?.forEach((role) => {
  //       let findObj = abc?.find((it) => it.ScreenId === role.ScreenId);
  //       if (findObj) {
  //         if (role?.Child?.length > 0) {
  //           return recursiveFunForPrefill(role, abc);
  //         } else {
  //     //       console.log({...role,status:true,RoleScreenPermissionId:findObj.RoleScreenPermissionId});
  //     // return ({...role,status:true,RoleScreenPermissionId:findObj.RoleScreenPermissionId});

  //           return (role.status = true);
  //         }
  //       }
  //     });

  //     return;
  //   }
  // };
  // console.log(allScreens);
  const recursiveFun = (data) => {
    if (!data.Child) {
      return <></>;
    } else {
      return data?.Child.map((role, i) => {
        if (role?.Child.length > 0) {
          return (
            <Accordion key={i}>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <FormControlLabel
                  onChange={(e) => onCheckedChange(role, e)}
                  control={
                    <Checkbox checked={role.status ? role.status : false} />
                  }
                  label={role.Name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>{recursiveFun(role)}</FormGroup>
              </AccordionDetails>
            </Accordion>
          );
        } else {
          return (
            <>
              <FormControlLabel
                key={i}
                onChange={(e) => onCheckedChange(role, e)}
                control={
                  <Checkbox checked={role.status ? role.status : false} />
                }
                label={role.Name}
              />
            </>
          );
        }
      });
    }
  };
  const initialValues = {
    name: "",
    description: "",
    permissionType: "Custom",
  };
  // console.log(JSON.parse(state?.Permissions));
  useEffect(() => {
    if (state) {
      handleEdit(state);
      let abc = state?.RoleScreenPermissions;
      allScreens?.forEach((rol) => {
        let findObj = abc?.find((it) => it.ScreenId === rol.ScreenId);
        if (findObj) {
          recursiveFunForPrefill(rol, abc);
        }
      });
      setrenderComp(!renderComp);
    }
  }, [state, allScreens]);

  // console.log(state);

  // console.log(JSON.parse(state?.Permissions));
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
    formik.handleChange({
      target: {
        name: "description",
        value: values?.description || "",
      },
    });
    formik.handleChange({
      target: {
        name: "permissionType",
        value: values?.PermissionType || "",
      },
    });
  };
  const navigate = useNavigate();
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    // let arr = [];
    // let screenData = allScreens.forEach((item) => {
    //   if (item.status) {
    //     let abc = recursiveFunForApi(item);
    //     arr.push(...abc);
    //   }
    // });
    // console.log(screenData);
    // console.log(arr);
    const arr = [];
    let screenData = allScreens.forEach((item) => {
      if (item.status) {
        recursiveFunForApi(item, arr);
      }
    });
    console.log(screenData);

    let obj = {
      name: values.name,
      description: values.description,
      permissionType: values.permissionType,
      roleScreenPermissions:
        formik?.values?.permissionType === "All" ? null : arr,
    };
    if (obj.permissionType === "All") {
      delete obj.roleScreenPermissions;
    }
    if (state) {
      const response = await UpdateRolesApi({ obj: obj, id: state?.ID });
      setsnackBarData(response.snackBarData);

      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        dispatch(fetchRoles());
        navigate(-1);
      }
      setSubmitting(false);
    } else {
      const response = await CreateRolesApi(obj);
      setsnackBarData(response.snackBarData);

      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        dispatch(fetchRoles());
        navigate(-1);
      }
      setSubmitting(false);
    }
  };
  const onCheckedChange = (role, e) => {
    role.status = e.target.checked;
    if (role.Child.length > 0) {
      role.Child?.forEach((child) => {
        if (child.Child.length > 0) {
          onCheckedChange(child, e);
        } else {
          child.status = e.target.checked;
        }
      });
    }
    setrenderComp(!renderComp);
  };
  // let arr = [];
  // const createRoleArr = (data) => {
  //   data?.forEach((child) => {
  //     if (child.status === true) {
  //       arr.push(child);
  //     }
  //     if (child?.child) {
  //       createRoleArr(child?.child);
  //     }
  //     if (child.child?.child) {
  //       createRoleArr(child?.child?.child);
  //     }
  //   });
  // };
  // useEffect(() => {
  //   arr = [];
  //   createRoleArr([...allRoles]);
  // }, [renderComp]);

  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Product Name"),
    description: Yup.string().required("Please Enter Description"),
    permissionType: Yup.string().required("Please Enter permissionType"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  return (
    <>
      {status == "loading" && <Loader />}
      <Box className={container}>
        <Box className={subContainer}>
          <Box
            role="presentation"
            onClick={(e) => e.preventDefault()}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ fontSize: "12px" }}
            >
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/settings"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Settings
              </Link>
              <Link
                to="/settings/roles"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Roles
              </Link>
              <Typography
                color="text.primary"
                sx={{ fontSize: "12px" }}
              >
                {state ? "Update Roles" : "Create Roles"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography
            variant="h5"
            sx={{ textAlign: "left", fontWeight: 400 }}
          >
            {state ? "Update Roles" : "Create Roles"}
          </Typography>
          <Paper
            elevation={3}
            sx={{ padding: "20px" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box className={formContainer}>
                <FormControl fullWidth>
                  <Typography>Name</Typography>
                  <TextField
                    name="name"
                    value={formik.values?.name}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name)
                    }
                    helperText={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name)
                        ? formik.errors.name
                        : ""
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Description</Typography>
                  <TextField
                    name="description"
                    value={formik.values?.description}
                    size={"small"}
                    multiline
                    rows={4}
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.description) &&
                      Boolean(formik.touched.description)
                    }
                    helperText={
                      Boolean(formik.errors.description) &&
                      Boolean(formik.touched.description)
                        ? formik.errors.description
                        : ""
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Permission Type</Typography>
                  <TextField
                    name="permissionType"
                    // defaultValue="Custom"
                    value={formik.values?.permissionType}
                    size={"small"}
                    select
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.permissionType) &&
                      Boolean(formik.touched.permissionType)
                    }
                    helperText={
                      Boolean(formik.errors.permissionType) &&
                      Boolean(formik.touched.permissionType)
                        ? formik.errors.permissionType
                        : ""
                    }
                  >
                    <MenuItem value="Custom">Custom</MenuItem>
                    <MenuItem value="All">All</MenuItem>
                  </TextField>
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box className={accordBox}>
                    {formik.values.permissionType === "Custom" ? (
                      <>
                        {allScreens?.map((role, idx) => {
                          return (
                            <>
                              <FormControl key={idx}>
                                <Accordion>
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                  >
                                    <FormControlLabel
                                      onChange={(e) => onCheckedChange(role, e)}
                                      control={
                                        <Checkbox
                                          checked={
                                            role.status ? role.status : false
                                          }
                                        />
                                      }
                                      label={role.Name}
                                    />
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <FormGroup>{recursiveFun(role)}</FormGroup>
                                  </AccordionDetails>
                                </Accordion>
                              </FormControl>
                            </>
                          );
                        })}
                      </>
                    ) : null}
                  </Box>
                  {/* <Box>
                    <Button
                      variant="outlined"
                      onClick={() => setShowModal(true)}
                      size="small"
                    >
                      New Screen
                    </Button>
                  </Box> */}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <Button onClick={handleNavigate}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Back
                        </Typography>
                      </Button>
                    </Box>
                    <Box>
                      {formik.isSubmitting ? (
                        <Box>
                          {/* <Loader/> */}
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button
                          variant="contained"
                          type="submit"
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            {state ? "Update" : " Save"}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Box>

      <FormModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}

export default CreateRole;
