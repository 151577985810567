import React, { useState, useEffect, useContext } from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Badge, Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
// import AddIcon from '@mui/icons-material/Add';
// import { useRef } from "react";
// import moment from "moment";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
// import OrganizationFilterDrawer from "../../../Components/Contacts/Organizations/OrganizationFilterDrawer/OrganizationFilterDrawer";
import Loader from "../../../Components/Loader/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  fetchCampaignGroups,
  getCampaignGroupsDetails,
} from "../../../Redux/Slice/CampaignGroupsSlice/CampaignGroupsSlice";
import { DeleteCampaignGroupsApi } from "../../../Api/CampaignGroupsApi/DeleteCampaignGroupsApi";
import CampaignGroupsInfoModal from "../../../Components/Campaign/CampaignGroups/CampaignGroupsInfoModal";
import { AppContext } from "../../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import CampaignGroupFilterDrawer from "./CampaignGroupFilterDrawer";
import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import { RemoveSCFromInputs } from "../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../Components/AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

export default function CampaignGroups({ roles }) {
  // const [rows, setrows] = useState([]);
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const AllCampaignGroups = useSelector(getCampaignGroupsDetails);
  console.log("AllCampaignGroups", AllCampaignGroups);
  const [pageSize, setpageSize] = useState(totalRows);
  const [showLoading, setshowLoading] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  console.log("selectedId", selectedId);
  const [rows, setrows] = useState([]);

  const [AllGroups, setAllGroups] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [filterVal, setfilterVal] = useState({
    type: "",
    title: "",
  });
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [group, setGroup] = useState(null);
  const handleClosed = () => {
    setShowModal(false);
  };
  const { setState, state } = useContext(AppContext);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "CampaignGroup");

    if (!isSkip && AllCampaignGroups?.status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [AllCampaignGroups?.status]);
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (dispatch) {
      dispatch(fetchCampaignGroups());
    }
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    AllCampaignGroups?.CampaignGroups?.map((item, i) => {
      rowData.push({
        id: i + 1,
        name: item.Name,
        type: item.Type,
        file: JSON.parse(item.FileData),
        ID: item.CompaignGroupId,
      });
    });
    setrows(rowData);
    setAllGroups(rowData);
  }, [AllCampaignGroups]);

  const handleClick = (val) => {
    navigate("/campaigns/groups/create", { state: val });
  };
  const deleteOrg = async (id) => {
    setshowLoading(true);
    let res = await DeleteCampaignGroupsApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);

    if (res.data.IsSuccess) {
      dispatch(fetchCampaignGroups());
    }
    setsnackBarData(res.snackBarData);
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  useEffect(() => {
    if (filterVal?.type === "" && filterVal?.title === "") {
      // console.log(salesPersonRef.current?.value);
      setAllGroups(rows);
    } else {
      let suggestions = [];
      let regex3 = new RegExp(filterVal?.type, "i");
      let regex4 = new RegExp(filterVal?.title, "i");

      suggestions = rows?.filter(
        (val) =>
          (filterVal?.type === "" || regex3.test(val?.type)) &&
          (filterVal?.title === "" || regex4.test(val?.name))
      );
      setAllGroups(suggestions);
    }
  }, [filterVal, rows]);
  const columns = [
    { field: "id", headerName: "Sr", width: 50 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "type", headerName: "Type", width: 200 },
    // {
    //   field: "file",
    //   headerName: "File Data",
    //   width: 250,
    //   renderCell: (celval) => {
    //     return (
    //       <div>
    //         {celval?.formattedValue.map((data, index) => (
    //           <>
    //             <Chip
    //               sx={{ mr: 1 }}
    //               key={index}
    //               label={data}
    //               variant="outlined"
    //             />
    //           </>
    //         ))}
    //       </div>
    //     );
    //   },
    // },

    {
      field: "actions",
      headerName: "Actions",
      width: 180,

      renderCell: (celval, i) => {
        console.log("celval", celval);
        return (
          <Box key={i}>
            {roles?.some((role) => role === "Campaign_Groups_Edit") && (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleClick(celval.row);
                    // setsnackBarData({
                    //   type: "success",
                    //   message: "hello",
                    //   openToast: true,
                    // });
                  }}
                >
                  <EditIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.edit,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            {roles?.some((role) => role === "Campaign_Groups_Delete") && (
              <Tooltip title="Delete">
                <IconButton
                  key={i}
                  onClick={() => {
                    setselectedId(celval.row.ID);
                    setshowConfirmationIcons(true);
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.delete,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="View">
              <IconButton
                key={i}
                onClick={() => {
                  console.log("celval.row", celval.row);
                  setShowModal(true);
                  setGroup(celval.row);
                  // dispatch(fetchproduct(celval.row?.ID));
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  // const handleChangeVal = (e) => {
  //   setfilterVal({
  //     ...filterVal,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const onTextChange = () => {
  //   if (
  //     ID.current?.value === "" &&
  //     name.current?.value === "" &&
  //     CreatedDateRef.current?.value === "" &&
  //     endDateRef.current?.value === ""
  //   ) {
  //     setAllGroups(rows);
  //   } else {
  //     let suggestions = [];
  //     let regex1 = new RegExp(ID.current.value, "i");
  //     let regex2 = new RegExp(name.current.value, "i");
  //     let regex3 = new RegExp(
  //       moment(CreatedDateRef.current.value).format("DD-MM-YYYY"),
  //       "i"
  //     );
  //     let regex4 = new RegExp(
  //       moment(endDateRef.current.value).format("DD-MM-YYYY"),
  //       "i"
  //     );

  //     suggestions = rows?.filter(
  //       (val) =>
  //         (ID.current.value === "" || regex1.test(val.id)) &&
  //         (name.current.value === "" || regex2.test(val?.name)) &&
  //         (CreatedDateRef.current.value === "" ||
  //           regex3.test(val?.createddate)) &&
  //         (endDateRef.current.value === "" || regex4.test(val?.createddate))
  //     );
  //     setAllGroups(suggestions);
  //   }
  // };
  return (
    <>
      <Box>
        <CampaignGroupFilterDrawer
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
          rows={rows}
          setAllGroups={setAllGroups}
          handleChangeVal={handleChangeVal}
          setcount={setcount}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
        />
      </Box>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "CampaignGroup"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="campaign-group">
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            rows={AllGroups}
            pageSize={pageSize}
            columns={columns}
            onPageSizeChange={(newPage) => {
              setpageSize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            // scrollbarSize={1}
            // scrollbarSize={'1px'}
          />
          {AllCampaignGroups?.status === "loading" || showLoading ? (
            <Loader />
          ) : null}
        </Box>
      </Box>
      <CampaignGroupsInfoModal
        showModal={showModal}
        handleClosed={handleClosed}
        group={group}
      />

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteOrg(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Campaign Group"
        title={`Are you sure, you want to Delete Campaign Group?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}
const steps = [
  {
    target: "#campaign-group",
    content: (
      <>
        <p size="large">Campaign Group Page</p>
        <p>
          Welcome to Campaign Groups – your strategic tool for organizing and
          managing multiple campaigns efficiently. Create groups to categorize
          and streamline your campaigns based on common themes, objectives, or
          target audiences.
          <br />
          <br />
          Creating a new campaign? Assign it to a specific group for better
          organization. Click &apos;New&apos; to start a fresh group and keep
          your campaigns well-organized.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
CampaignGroups.propTypes = {
  roles: PropTypes.array,
};
