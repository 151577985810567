import React, { useContext, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";

import theme from "../../../theme";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { CreateServiceApi } from "../../../Api/ServicesApi/CreateServiceApi";
import { ReactSelectTheme } from "../../../Utils/ReactSelectTheme";
// import { fetchProducts } from '../../../Redux/Slice/ProductSlice/ProductSlice';

// import Loader from '../../Loader/Loader';
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function CreateService() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const initialValues = {
    name: "",
    description: "",
    cost: "",
    price: "",
    serviceCat: "",
  };

  const catOptions = [
    { value: "Consulting ", label: "Consulting " },
    { value: "Healthcare ", label: "Healthcare " },
    { value: "Education and Training ", label: "Education and Training " },
    { value: "Financial  ", label: "Financial  " },
    { value: "Technology  ", label: "Technology  " },
    { value: "Hospitality  ", label: "Hospitality  " },
    {
      value: "Marketing and Advertising  ",
      label: "Marketing and Advertising  ",
    },
    { value: "Maintenance and Repair  ", label: "Maintenance and Repair  " },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      handleEdit(state);
    }
  }, []);
  const handleEdit = (values) => {
    console.log("values", values);
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });

    formik.handleChange({
      target: {
        name: "price",
        value: values?.price.replace(/,/g, "") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "cost",
        value: values?.cost.replace(/,/g, "") || "",
      },
    });
    formik.handleChange({
      target: {
        name: "description",
        value: values?.Description || "",
      },
    });

    formik.handleChange({
      target: {
        name: "serviceCat",
        value: { value: values?.category, label: values?.category } || "",
      },
    });
  };
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const data = {
      ...values,
      uom: values.uomValue?.label,
      category: values.serviceCat?.label,
    };
    if (state) {
      // const response = await UpdateProductApi({ ...values, id: state.ID });
      const response = await CreateServiceApi({ ...data, id: state.ID });

      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        // dispatch(fetchProducts());
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
    } else {
      const response = await CreateServiceApi(data);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        // dispatch(fetchProducts());
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
      setSubmitting(false);
    }
  };
  const handleNavigate = () => {
    navigate(-1);
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Product Name"),
    description: Yup.string().required("Please Enter Description"),
    price: Yup.string().required("Please Enter Price"),
    cost: Yup.string().required("Please Enter Cost"),
    serviceCat: Yup.object().required("Please Product Category"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/service"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Service
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                {state ? "Update Service" : " Create Service"}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            {state ? "Update Service" : " Create Service"}
          </Typography>
        </Box>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  value={formik.values?.name}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                  }
                  helperText={
                    Boolean(formik.errors.name) &&
                    Boolean(formik.touched.name) &&
                    formik.errors.name
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Description</Typography>
                <TextField
                  name="description"
                  value={formik.values?.description}
                  size={"small"}
                  multiline
                  rows={4}
                  fullWidth
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.description) &&
                    Boolean(formik.touched.description)
                  }
                  helperText={
                    Boolean(formik.errors.description) &&
                    Boolean(formik.touched.description) &&
                    formik.errors.description
                  }
                />
              </FormControl>
              <Box display="flex" gap={1}>
                <FormControl fullWidth>
                  <Typography>Cost</Typography>
                  <TextField
                    name="cost"
                    value={formik.values?.cost}
                    size={"small"}
                    type="number"
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.cost) &&
                      Boolean(formik.touched.cost)
                    }
                    helperText={
                      Boolean(formik.errors.cost) &&
                      Boolean(formik.touched.cost) &&
                      formik.errors.cost
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Price</Typography>
                  <TextField
                    name="price"
                    value={formik.values?.price}
                    size={"small"}
                    type="number"
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.price) &&
                      Boolean(formik.touched.price)
                    }
                    helperText={
                      Boolean(formik.errors.price) &&
                      Boolean(formik.touched.price) &&
                      formik.errors.price
                    }
                  />
                </FormControl>
              </Box>

              <FormControl
                fullWidth
                error={
                  Boolean(formik.errors.serviceCat) &&
                  Boolean(formik.touched.serviceCat)
                }
              >
                <Typography>Service Category</Typography>
                <Select
                  // styles={{
                  //   control: (baseStyles) => ({
                  //     ...baseStyles,
                  //     borderColor:
                  //       Boolean(formik.errors.serviceCat) &&
                  //       Boolean(formik.touched.serviceCat)
                  //         ? "red"
                  //         : "#bdbdbd",
                  //   }),
                  // }}
                  styles={ReactSelectTheme(
                    Boolean(formik.errors.serviceCat) &&
                      Boolean(formik.touched.serviceCat)
                  )}
                  name="serviceCat"
                  options={catOptions}
                  value={formik.values.serviceCat}
                  onChange={(value) => {
                    formik.setFieldValue("serviceCat", value);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Select Service Category"
                />
                <FormHelperText>
                  {Boolean(formik.errors.serviceCat) &&
                  Boolean(formik.touched.serviceCat)
                    ? formik.errors.serviceCat
                    : ""}
                </FormHelperText>
              </FormControl>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreateService;
