import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { Badge, Button, Chip, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGroups,
  getGroupsDetails,
} from "../../../../Redux/Slice/Settings/GroupsSlice/GroupsSlice";
import GroupsFilterDrawer from "./GroupsFilterDrawer";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Loader from "../../../Loader/Loader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { DeleteGroupsApi } from "../../../../Api/Settings/Groups/DeleteGroupsApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import PropTypes from "prop-types";
import { RemoveSCFromInputs } from "../../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../AppConfirmationDialog/AppConfirmationDialog";
import UserInfoModal from "./UserInfoModal";
import { PageNumberContext } from "../../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../../Utils/CustomNoRowsOverLay";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function Groups({ roles }) {
  const { totalRows } = useContext(PageNumberContext);
  const dispatch = useDispatch();
  const { header } = useStyles();
  const { Groups, status } = useSelector(getGroupsDetails);
  const [allGroups, setallGroups] = useState([]);
  const [pagesize, setpagesize] = useState(totalRows);
  const [rows, setrows] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const [showLoading, setshowLoading] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [users, setUsers] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { setsnackBarData } = useContext(SnackBarContext);
  const name = useRef();
  const descriptionRef = useRef();

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
  const handleClosed = () => {
    setUsers(null);
    setShowModal(false);
  };
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);
  useEffect(() => {
    let arr = [];
    if (Groups) {
      Groups?.forEach((groups, idx) => {
        return arr.push({
          id: idx + 1,
          name: groups?.Name,
          description: groups?.Description,
          ID: groups?.Id,
          users: groups?.GroupUsers,
          manager: groups?.GroupUsers.find(
            (user) => user?.UserType === "GroupManager"
          )?.User?.FullName,
        });
      });
    }
    setrows(arr);
    setallGroups(arr);
  }, [Groups]);
  const deleteGroup = async (id) => {
    setshowLoading(true);
    let res = await DeleteGroupsApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);
    if (res.data.IsSuccess) {
      dispatch(fetchGroups());
    }
    setsnackBarData(res.snackBarData);
  };
  const columns = [
    { field: "id", headerName: "Sr", width: 90, headerClassName: header },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerClassName: header,
    },
    {
      field: "manager",
      headerName: "Group Manager",
      width: 180,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          celval?.formattedValue && (
            <Chip key={i} label={celval?.formattedValue} />
          )
        );
      },
    },
    {
      field: "users",
      headerName: "Users",
      width: 350,
      headerClassName: header,
      renderCell: (celval) => {
        const users = celval?.formattedValue?.filter(
          (user) => user.UserType === "User"
        );
        return (
          celval?.formattedValue && (
            <div
              onClick={() => {
                setUsers(celval?.formattedValue);
                setShowModal(true);
              }}
            >
              {users?.slice(0, 2)?.map((user, index) => (
                <Chip
                  key={index}
                  label={user?.User?.FullName}
                  sx={{
                    margin: "2px",
                    cursor: "pointer",
                  }}
                />
              ))}
              {users.length > 2 && (
                <Chip
                  label="..."
                  sx={{
                    margin: "2px",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          )
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            {/* {showConfirmationIcons && celval.row.ID === selectedId ? (
              <>
                <IconButton
                  key={i}
                  onClick={() => setshowConfirmationIcons(false)}
                >
                  <CloseIcon
                    sx={{ color: (theme) => theme?.palette?.primary?.delete }}
                    fontSize="small"
                  />
                </IconButton>
                <IconButton
                  key={i}
                  onClick={() => deleteGroup(celval.row.ID)}
                  color="primary"
                >
                  <DoneIcon
                    sx={{ color: (theme) => theme?.palette?.primary?.green }}
                    fontSize="small"
                  />
                </IconButton>{" "}
              </>
            ) : ( */}
            <>
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row);
                  }}
                  disabled={
                    roles?.some((role) => role === "Groups_Edit") ? false : true
                  }
                >
                  <EditIcon
                    sx={{ color: (theme) => theme?.palette?.primary?.edit }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete">
                <IconButton
                  key={i}
                  onClick={() => {
                    setshowConfirmationIcons(true);
                    setselectedId(celval.row.ID);
                  }}
                  disabled={
                    roles?.some((role) => role === "Groups_Delete")
                      ? false
                      : true
                  }
                >
                  <DeleteForeverIcon
                    sx={{ color: (theme) => theme?.palette?.primary?.delete }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </>
            {/* )} */}
          </Box>
        );
      },
    },
  ];
  const handleEdit = (val) => {
    navigate("/create/group", { state: val });
  };
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  const onTextChange = () => {
    if (name.current?.value === "" && descriptionRef.current?.value === "") {
      setallGroups(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(RemoveSCFromInputs(name.current.value), "i");
      let regex3 = new RegExp(
        RemoveSCFromInputs(descriptionRef.current.value),
        "i"
      );
      suggestions = rows?.filter(
        (val) =>
          (name.current.value === "" || regex2.test(val?.name)) &&
          (descriptionRef.current.value === "" || regex3.test(val?.description))
      );
      setallGroups(suggestions);
    }
  };
  return (
    <>
      <Box>
        <GroupsFilterDrawer
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          descriptionRef={descriptionRef}
          setcount={setcount}
          setallGroups={setallGroups}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allGroups}
            columns={columns}
          />
          {status === "loading" || showLoading ? <Loader /> : null}
        </Box>
      </Box>

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteGroup(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Group"
        title={`Are you sure, you want to Delete Group?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
      {showModal && (
        <UserInfoModal
          users={users}
          handleClosed={handleClosed}
          showModal={showModal}
        />
      )}
    </>
  );
}

export default Groups;

Groups.propTypes = {
  roles: PropTypes.array,
};
