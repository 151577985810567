import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBookUnitPopulate,
  fetchUnits,
  getUnitsDetails,
} from "../../../../Redux/Slice/UnitSlice/UnitSlice";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import AppTooltip from "../../../../Utils/AppTooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HistoryIcon from "@mui/icons-material/History";
import { CreatePersonApi } from "../../../../Api/CreatePersonApi.jsx/CreatePersonApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { BookUnitApi } from "../../../../Api/UnitApi/CreateUnitApi";

const validationSchema = Yup.object().shape({
  personId: Yup.string().when("addNewPerson", {
    is: (value) => !value,
    then: () => Yup.string().required(`Field Required!`),
    otherwise: () => Yup.string(),
  }),
  newPersonName: Yup.string().when("addNewPerson", {
    is: (value) => value,
    then: () => Yup.string().required("Field Required!"),
    otherwise: () => Yup.string(),
  }),
  newPersonPhone: Yup.string().when("addNewPerson", {
    is: (value) => value,
    then: () =>
      Yup.string().min(9, "Invalid phone number").required("Field Required!"),
    otherwise: () => Yup.string(),
  }),
  tokenAmount: Yup.string().required("Field Required!"),
  numberOfDays: Yup.string().required("Field Required!"),
});

function BookUnitModal({ open, onClose, selectedUnitId }) {
  const { Container, ModalContainer, TableHeader } = useStyles();

  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const { BookUnitPopulate } = useSelector(getUnitsDetails);

  const formik = useFormik({
    initialValues: {
      personId: "",
      addNewPerson: false,
      newPersonName: "",
      newPersonDialCode: "",
      newPersonPhone: "",
      tokenAmount: "",
      numberOfDays: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleAddNewPerson = async () => {
    formik.setFieldTouched("newPersonName");
    formik.setFieldTouched("newPersonPhone");

    if (!formik.errors.newPersonName && !formik.errors.newPersonPhone) {
      formik.setSubmitting(true);
      let formData = new FormData();
      formData.append("name", formik.values.newPersonName);
      formData.append(
        `personContacts[0].dialCode`,
        formik.values.newPersonDialCode
      );
      formData.append(
        `personContacts[0].contactNumber`,
        formik.values.newPersonPhone
      );
      formData.append(`personContacts[0].description`, "Work");
      formData.append(`Type`, "Person");
      const response = await CreatePersonApi(formData);
      formik.setSubmitting(false);
      if (response?.data?.IsSuccess) {
        formik.setFieldValue("addNewPerson", false);
        formik.setFieldValue("newPersonName", "");
        formik.setFieldValue("newPersonDialCode", "");
        formik.setFieldValue("newPersonPhone", "");
        formik.setFieldValue("personId", response?.data?.Data);
        dispatch(fetchBookUnitPopulate());
      }
      setsnackBarData(response.snackBarData);
    }
  };

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const formData = {
      unitId: selectedUnitId,
      personId: values.personId,
      tokenAmount: values.tokenAmount,
      numberOfDays: values.numberOfDays,
    };

    const response = await BookUnitApi(formData);
    setSubmitting(false);
    if (response?.data?.IsSuccess) {
      dispatch(fetchUnits());
      resetForm();
      onClose();
    }
    setsnackBarData(response.snackBarData);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        formik.setValues({
          personId: "",
          addNewPerson: false,
          newPersonName: "",
          newPersonDialCode: "",
          newPersonPhone: "",
          tokenAmount: "",
          numberOfDays: "",
        });
      }}
    >
      <Box className={ModalContainer}>
        <Box className={Container}>
          <Box className={TableHeader}>
            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
              Book Unit
            </Typography>
            <IconButton
              onClick={() => {
                onClose();
                formik.resetForm();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <Typography>Person</Typography>

                <Box sx={{ display: "flex", gap: "10px" }}>
                  {formik.values.addNewPerson ? (
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "10px",
                      }}
                    >
                      <FormControl sx={{ flex: 1 }} fullWidth>
                        <TextField
                          size={"small"}
                          fullWidth
                          placeholder="Name..."
                          name="newPersonName"
                          value={formik.values.newPersonName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.errors?.newPersonName &&
                            formik.touched?.newPersonName
                          }
                          helperText={
                            formik.errors?.newPersonName &&
                            formik.touched?.newPersonName &&
                            formik.errors?.newPersonName
                          }
                        />
                      </FormControl>

                      <FormControl sx={{ flex: 1 }} fullWidth>
                        <PhoneInput
                          inputProps={{
                            name: "newPersonPhone",
                          }}
                          inputStyle={{
                            width: "100%",
                            height: "40px",
                            borderColor:
                              formik.errors?.newPersonPhone &&
                              formik.touched?.newPersonPhone
                                ? "#d32f2f"
                                : "inherit",
                          }}
                          containerStyle={{
                            width: "100%",
                            height: "40px",
                          }}
                          country={"pk"}
                          value={formik?.values.newPersonPhone}
                          onChange={(phone, data) => {
                            formik.setFieldValue(
                              "newPersonDialCode",
                              data.dialCode
                            );
                            formik.setFieldValue("newPersonPhone", phone);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors?.newPersonPhone &&
                          formik.touched?.newPersonPhone && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              {formik.errors?.newPersonPhone}
                            </FormHelperText>
                          )}
                      </FormControl>

                      <Button
                        sx={{ alignSelf: "center" }}
                        variant="contained"
                        size="small"
                        onClick={handleAddNewPerson}
                      >
                        Add
                      </Button>
                    </Box>
                  ) : (
                    <Autocomplete
                      options={BookUnitPopulate?.Persons || []}
                      getOptionLabel={(option) => option?.Name}
                      sx={{ flex: 1 }}
                      size={"small"}
                      fullWidth
                      value={
                        BookUnitPopulate?.Persons?.find(
                          (person) => person?.Id === formik.values.personId
                        ) || null
                      }
                      onChange={(event, value) => {
                        if (value) {
                          formik.setFieldValue("personId", value?.Id);
                        } else {
                          formik.setFieldValue("personId", "");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="personId"
                          onBlur={formik.handleBlur}
                          error={
                            formik.errors?.personId && formik.touched?.personId
                          }
                          helperText={
                            formik.errors?.personId && formik.touched?.personId
                              ? formik.errors?.personId
                              : ""
                          }
                        />
                      )}
                    />
                  )}
                  <AppTooltip
                    title={
                      formik?.values.addNewPerson ? "Add Existing" : "Add New"
                    }
                  >
                    <IconButton
                      sx={{ alignSelf: "center" }}
                      size="small"
                      onClick={() => {
                        formik.setFieldValue(
                          "addNewPerson",
                          !formik?.values.addNewPerson
                        );
                      }}
                    >
                      {formik?.values.addNewPerson ? (
                        <HistoryIcon fontSize="small" />
                      ) : (
                        <AddCircleIcon fontSize="small" />
                      )}
                    </IconButton>
                  </AppTooltip>
                </Box>
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "10px",
                }}
              >
                <FormControl fullWidth>
                  <Typography>Token Amount</Typography>
                  <TextField
                    type="number"
                    size={"small"}
                    fullWidth
                    name="tokenAmount"
                    value={formik.values?.tokenAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors?.tokenAmount && formik.touched?.tokenAmount
                    }
                    helperText={
                      formik.errors?.tokenAmount &&
                      formik.touched?.tokenAmount &&
                      formik.errors?.tokenAmount
                    }
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Typography>No. Of Days</Typography>
                  <TextField
                    type="number"
                    size={"small"}
                    fullWidth
                    name="numberOfDays"
                    value={formik.values?.numberOfDays}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors?.numberOfDays &&
                      formik.touched?.numberOfDays
                    }
                    helperText={
                      formik.errors?.numberOfDays &&
                      formik.touched?.numberOfDays &&
                      formik.errors?.numberOfDays
                    }
                  />
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {formik.isSubmitting ? (
                  <Box>
                    {/* <Loader/> */}
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button variant="contained" type="submit">
                    <Typography sx={{ fontWeight: "bold" }}>Book</Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}
export default BookUnitModal;
BookUnitModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedUnitId: PropTypes.any,
};

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "20px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
    },
    iconBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };
});
