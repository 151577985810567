import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";
import GetQueryParams from "../../../Utils/GetQueryParams";

const initialState = {
  Activities: null,
  Events: [],
  // Activities: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchActivities = createAsyncThunk(
  "/LeadActivities",
  async (payload) => {
    const queryParams = GetQueryParams(payload);

    const response = await axiosInstance.get(`/LeadActivities?${queryParams}`);
    return response;
  }
);

export const fetchActivityEvents = createAsyncThunk(
  "/LeadActivities/allActivityEvents",
  async () => {
    const response = await axiosInstance.get(
      `/LeadActivities/allActivityEvents`
    );

    return response;
  }
);
export const fetchFilterInput = createAsyncThunk(
  "/LeadActivities/ByFilters",
  async (data) => {
    const response = await axiosInstance.get(`/LeadActivities/ByFilters`, {
      params: data,
    });

    return response;
  }
);

const ActivitiesSlice = createSlice({
  name: "Activities",
  initialState,
  reducers: {
    ActivitiesAdded: {
      reducer(state, action) {
        state.Activities?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchActivities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Activities = action.payload.data;
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
        state.Activities = null;
      })
      .addCase(fetchFilterInput.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFilterInput.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Activities = action.payload.data.Data;
      })
      .addCase(fetchFilterInput.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchActivityEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActivityEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Events = action.payload.data.Data;
      })
      .addCase(fetchActivityEvents.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getActivitiesDetails = (state) => state.Activities;

export const { ActivitiesAdded } = ActivitiesSlice.actions;

export default ActivitiesSlice.reducer;
