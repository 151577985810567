import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { CreateLeadNoteApi } from "../../../../Api/LeadsApi/CreateLeadNoteApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { fetchLeadsSingle } from "../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      alignItems: "flex-start",
    },
  };
});

function Note({ state, roles }) {
  const { formContainer } = useStyle();
  const initialValues = {
    note: "",
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const obj = { ...values, leadId: state?.id ? state?.id : state };
    const response = await CreateLeadNoteApi(obj);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      dispatch(
        state?.id
          ? fetchLeadsSingle(Number(state?.id))
          : fetchLeadsSingle(Number(state))
      );
      setSubmitting(false);
      resetForm();
    }
    setSubmitting(false);
  };

  const ValidationSchema = Yup.object().shape({
    note: Yup.string().required("Please Enter  note"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Paper elevation={3}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <FormControl fullWidth>
            <Typography>Note</Typography>
            <TextField
              name="note"
              value={formik.values?.note}
              size={"small"}
              multiline
              rows={4}
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.note) && Boolean(formik.touched.note)
              }
              helperText={Boolean(formik.errors.note) && formik.errors.note}
              disabled={
                roles?.some((role) => role === "Leads_Edit") ? false : true
              }
            />
          </FormControl>
          {formik?.isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button
              variant="outlined"
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: "white !important",
              }}
              type="submit"
              disabled={
                roles?.some((role) => role === "Leads_Edit") ? false : true
              }
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </Paper>
  );
}

export default Note;
Note.propTypes = {
  state: PropTypes.any,
  roles: PropTypes.array,
};
