import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { convertToRaw } from "draft-js";
import { useFormik } from "formik";
import { CreateTemplateApi } from "../../../Api/TemplateApi/CreateTemplateApi";
import { fetchTemplate } from "../../../Redux/Slice/TemplateSlice/TemplateSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function TagsModal({
  showModal,
  handleClosed,
  editorState,
  formValue,
  state,
  setsnackBarData,
  placeholder,
  setTagCount,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [array, setArray] = useState();

  const [hasError, setHasError] = useState(false);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (array) {
      const isEmptyTitle = array.some((item) => item.title === "");

      setHasError(isEmptyTitle);
    }
  }, [array]);
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (hasError) {
        setSubmit(true);
        return;
      } else {
        const obj = {
          type: formValue.type.label,
          body: formValue.body,
          title: formValue.title,
          placeholders: array,
        };
        if (state) {
          const response = await CreateTemplateApi({
            ...obj,
            templateId: state.ID,
          });
          setsnackBarData(response.snackBarData);
          if (response?.data?.IsSuccess) {
            setSubmitting(false);
            dispatch(fetchTemplate());
            resetForm();
            handleClosed();

            navigate(-1);
          }
        } else {
          const response = await CreateTemplateApi(obj);
          setsnackBarData(response.snackBarData);
          if (response?.data?.IsSuccess) {
            setSubmitting(false);
            dispatch(fetchTemplate());
            resetForm();
            handleClosed();

            navigate(-1);
          }
        }
      }
    },
  });

  const handleChange = (event, index) => {
    const value = event.target.value;
    setArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = { ...newArray[index], title: value };
      return newArray;
    });
  };
  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const text = rawContent.blocks[0].text;
    const variables = text.match(/_var\d+_/g) || [];
    if (state) {
      setTagCount(variables.length);
    }

    const arr = [];
    if (state) {
      variables.forEach((variable) => {
        const matchingPlaceholder = placeholder.find(
          (item) => item?.Key === variable
        );
        arr.push({
          key: variable,
          title: state ? matchingPlaceholder?.Title : "",
          placeholderId: matchingPlaceholder?.PlaceholderId
            ? matchingPlaceholder?.PlaceholderId
            : null,
        });
      });
      setArray(arr);
    } else {
      variables.forEach((variable) => {
        arr.push({
          key: variable,
          title: "",
        });
      });
      setArray(arr);
    }
  }, [editorState]);
  const renderTextFields = () => {
    return array.map((key, index) => (
      <TextField
        key={index}
        sx={{ m: 1 }}
        label={key.key}
        variant="outlined"
        margin="normal"
        onChange={(event) => handleChange(event, index)}
        onBlur={formik.handleBlur}
        value={key?.title}
        name={key.key}
        error={
          (Boolean(formik.touched[key.key]) || submit) &&
          hasError &&
          key.title === ""
        }
        helperText={
          (Boolean(formik.touched[key.key]) || submit) &&
          hasError &&
          key.title === "" &&
          `Please Enter ${key.key}`
        }
      />
    ));
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        id="alert-dialog-title"
      >
        <Typography mt={1} variant="h6" fontWeight="bolder">
          Add Placeholders
        </Typography>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleClosed();
          }}
        >
          <HighlightOffIcon
            color="primary"
            sx={{
              fontSize: "28px",
              // pb: 1,
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "550px" }}>
        <Box>{showModal ? renderTextFields() : null}</Box>
      </DialogContent>
      <DialogActions>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            {formik.isSubmitting ? (
              <Box>
                <CircularProgress />
              </Box>
            ) : (
              <Button
                type="submit"
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
              >
                Submit
              </Button>
            )}
          </Box>
        </form>
      </DialogActions>
    </Dialog>
  );
}

export default TagsModal;

TagsModal.propTypes = {
  showModal: PropTypes.array,
  handleClosed: PropTypes.array,
  editorState: PropTypes.array,
  formValue: PropTypes.array,
  state: PropTypes.array,
  setsnackBarData: PropTypes.array,
  placeholder: PropTypes.array,
  setTagCount: PropTypes.array,
};
