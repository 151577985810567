import React, { useState, useEffect, useContext } from "react";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Badge, Box, Button, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDocuments,
  fetchOrganization,
  fetchSingleOrganization,
  getOrganizationDetails,
} from "../../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import Loader from "../../../Components/Loader/Loader";
import { DeleteOrganizationApi } from "../../../Api/OrganizationApis/DeleteOrganizationApi";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import OrganizationFilterDrawer from "../../../Components/Contacts/Organizations/OrganizationFilterDrawer/OrganizationFilterDrawer";
// import AddIcon from '@mui/icons-material/Add';
import { useRef } from "react";
import moment from "moment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import DocumentModal from "../../../Components/DocumentModal/DocumentModal";
import DocumentDetailModal from "../../../Components/DocumentModal/DocumentDetailModal";
// import OrganizationInfoModal from "../../../Components/Contacts/Organizations/InfoModal/OrganizationInfoModal";
import ArticleIcon from "@mui/icons-material/Article";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AppContext } from "../../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import OrganizationDetailModal from "../../../Components/Contacts/Organizations/InfoModal/OrganizationDetailModal";
import AppTooltip from "../../../Utils/AppTooltip";
import { TourCheckApi } from "../../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import { RemoveSCFromInputs } from "../../../Utils/RemoveSCFromInputs";
import AppConfirmationDialog from "../../../Components/AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../../Utils/CustomNoRowsOverLay";

export default function Organization({ roles }) {
  const [rows, setrows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const allOrg = useSelector(getOrganizationDetails);
  const [pageSize, setpageSize] = useState(totalRows);
  const [showLoading, setshowLoading] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const [allOrganization, setallOrganization] = useState([]);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [documentDetailModal, setdocumentDetailModal] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [selectedRow, setselectedRow] = useState(null);

  // const { DocumentsData } = useSelector(getOrganizationDetails);

  const [count, setcount] = useState(0);
  const name = useRef();
  const city = useRef();

  const email = useRef();
  const endDateRef = useRef();
  const phone = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { SingleOrganization, DocumentsData, status2 } = useSelector(
    getOrganizationDetails
  );
  const { setState, state } = useContext(AppContext);
  console.log(allOrg);
  let businessType = sessionStorage.getItem("BusinessType");
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Organization");

    if (!isSkip && allOrg?.status === "succeeded") {
      setState({ ...state, run: true });
    }
    // else{
    //   setState({ ...state, run: false });

    // }
  }, [allOrg?.status]);
  // const handleClosed = () => {
  //   setShowInfoModal(false);
  // };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: "30%" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "end",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "2px",
                    backgroundColor: "green",
                    cursor: "pointer",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "14px" }}>Available</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "2px",
                    backgroundColor: "#dd284c",
                    cursor: "pointer",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "14px" }}>Not Available</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    if (allOrg?.Organization == null) {
      dispatch(fetchOrganization());
    }
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    allOrg?.Organization?.map((item, i) => {
      rowData.push({
        id: i + 1,
        name: item.Name,
        email: item.Email,
        address:
          item.Address?.AddressDetail +
          " " +
          item.Address?.City +
          " " +
          item.Address?.State +
          " " +
          item.Address?.Postcode +
          " " +
          item.Address?.Country,
        phone: item.Phone,
        addressObj: item.Address,
        IsDocument: item?.IsDocument,
        createddate: item?.CreatedAt
          ? moment(item?.CreatedAt).format("DD-MM-YYYY")
          : null,
        ID: item.Id,
      });
    });
    setrows(rowData);
    setallOrganization(rowData);
  }, [allOrg]);

  const handleClick = (val) => {
    navigate("/contacts/organizations/create", { state: val });
  };
  const deleteOrg = async (id) => {
    setshowLoading(true);
    let res = await DeleteOrganizationApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);

    if (res.data.IsSuccess) {
      dispatch(fetchOrganization());
    }
    setsnackBarData(res.snackBarData);
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  const columns = [
    { field: "id", headerName: "Sr", width: 50 },
    {
      field: "name",
      headerName: businessType === "RealState" ? "Developers" : "Organization",
      width: 220,
    },
    { field: "email", headerName: "Email", width: 220 },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      renderCell: (celval) => {
        return <div>+{celval?.row?.phone}</div>;
      },
    },
    {
      field: "addressObj",
      headerName: "City",
      width: 200,
      renderCell: (celval) => {
        return celval?.formattedValue?.City;
      },
    },
    {
      field: "IsDocument",
      headerName: "Documents",
      width: 150,
      renderCell: (celval, i) => {
        return (
          <>
            <AppTooltip
              title={
                celval?.row?.IsDocument == true
                  ? "Docements Available"
                  : "No Docements"
              }
            >
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "2px",
                  backgroundColor:
                    celval?.row?.IsDocument == true ? "green" : "#dd284c",
                  cursor: "pointer",
                }}
                key={i}
              ></Box>
            </AppTooltip>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (celval, i) => {
        return (
          <Box id="organization-action" key={i}>
            <Tooltip title="View">
              <IconButton
                key={i}
                onClick={() => {
                  console.log("celval.row", celval.row);
                  dispatch(fetchSingleOrganization(celval.row?.ID));
                  if (!(status2 == "failed")) {
                    setShowInfoModal(true);
                  }
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>

            {roles?.some((role) => role === "Organizations_Edit") && (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleClick(celval.row);
                    // setsnackBarData({
                    //   type: "success",
                    //   message: "hello",
                    //   openToast: true,
                    // });
                  }}
                >
                  <EditIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.edit,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            {roles?.some((role) => role === "Organizations_Delete") && (
              <Tooltip title="Delete">
                <IconButton
                  key={i}
                  onClick={() => {
                    setselectedId(celval.row.ID);
                    setshowConfirmationIcons(true);
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.delete,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="View Document">
              <IconButton
                key={i}
                onClick={async () => {
                  // setShowModal(true);
                  const response = await dispatch(
                    fetchDocuments({
                      type: "Organization",
                      id: celval.row?.ID,
                    })
                  );
                  if (response?.payload?.data?.IsSuccess == true) {
                    setdocumentDetailModal(true);
                  } else {
                    setsnackBarData({
                      type: "error",
                      message: "Something wrong",
                      openToast: true,
                    });
                  }
                  console.log(response);

                  // setdocumentDetailModal(true);
                  setselectedRow(celval.row);
                  // setselectedId(celval.row.ID);
                }}
              >
                <ArticleIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
                {/* <VisibilityIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.main,
                        }}
                        fontSize="small"
                      /> */}
              </IconButton>
              {/* <IconButton
                      key={i}
                      onClick={() => {
                        // setShowModal(true);
                        setdocumentDetailModal(true);
                        setselectedRow(celval.row);
                        // setselectedId(celval.row.ID);
                      }}
                      disabled={
                        roles?.some((role) => role === "Organizations_Delete")
                          ? false
                          : true
                      }
                    >
                      <VisibilityIcon
                        sx={{
                          color: (theme) => theme?.palette?.primary?.main,
                        }}
                        fontSize="small"
                      />
                    </IconButton> */}
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  const onTextChange = () => {
    if (
      name.current?.value === "" &&
      email.current?.value === "" &&
      phone.current?.value === "" &&
      endDateRef.current?.value === "" &&
      city.current?.value === ""
    ) {
      setallOrganization(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(RemoveSCFromInputs(email.current.value), "i");
      let regex2 = new RegExp(RemoveSCFromInputs(name.current.value), "i");
      let regex3 = new RegExp(RemoveSCFromInputs(phone.current.value), "i");
      let regex4 = new RegExp(RemoveSCFromInputs(city.current.value), "i");

      suggestions = rows?.filter(
        (val) =>
          (email.current.value === "" || regex1.test(val?.email)) &&
          (name.current.value === "" || regex2.test(val?.name)) &&
          (phone.current.value === "" || regex3.test(val?.phone)) &&
          (city.current.value === "" || regex4.test(val?.addressObj?.City))
      );
      setallOrganization(suggestions);
    }
  };
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Organization"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="organization">
        <OrganizationFilterDrawer
          phone={phone}
          endDateRef={endDateRef}
          name={name}
          email={email}
          city={city}
          handleChangeVal={handleChangeVal}
          rows={rows}
          setcount={setcount}
          setallOrganization={setallOrganization}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          onTextChange={onTextChange}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />

        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            rows={allOrganization}
            pageSize={pageSize}
            columns={columns}
            onPageSizeChange={(newPage) => {
              setpageSize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            // scrollbarSize={1}
            // scrollbarSize={'1px'}
          />
          {allOrg?.status === "loading" ||
          status2 === "loading" ||
          showLoading ? (
            <Loader />
          ) : null}
        </Box>
      </Box>

      {/* </Box> */}
      {showModal && (
        <DocumentModal
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Organization"
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
      {documentDetailModal && (
        <DocumentDetailModal
          DocumentsData={DocumentsData}
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Organization"
          setShowModal={setdocumentDetailModal}
          setformModal={setShowModal}
          showModal={documentDetailModal}
        />
      )}
      {showInfoModal && (
        <OrganizationDetailModal
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
          SingleOrganization={SingleOrganization}
        />
      )}
      {/* <OrganizationInfoModal
        showInfoModal={false}
        handleClosed={handleClosed}
        SingleOrganization={SingleOrganization}
      /> */}

      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteOrg(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Developer"
        title={`Are you sure, you want to Delete Developer?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}
const steps = [
  {
    target: "#organization",
    content: (
      <>
        <p size="large">Organization Contacts Page</p>
        <p>
          Welcome to the Organizations Screen – your centralized database for
          managing organizational records. The table below captures crucial
          details about each organization in your network.
          <br />
          <br />
          Need to add a new organization? Click &apos;New&apos; to expand your
          database. Maintain up-to-date records to foster strong connections.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#organization-action",
    content: (
      <>
        <p size="large">Organization Actions</p>
        <p>
          Edit: Refine and update person details.
          <br />
          Delete: Remove unnecessary records.
          <br />
          View Document: Access and review associated documents.
          <br />
          View: View detailed information about organizations.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
Organization.propTypes = {
  roles: PropTypes.array,
};
