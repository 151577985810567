import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  // Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import General from "./General";
import { makeStyles } from "@mui/styles";
import Permission from "./Permission";
import { Link, useLocation } from "react-router-dom";
import theme from "../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchSingleUser,
  getUserDetails,
} from "../../../../Redux/Slice/Settings/UserSlice/UserSlice";
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
      alignItems: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
  };
});

const steps = ["General", "Permission"];
function CreateUsers() {
  const { container, subContainer } = useStyle();
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [generalData, setgeneralData] = useState([]);

  const dispatch = useDispatch();
  const { SingleUser } = useSelector(getUserDetails);
  const [singleUser, setsingleUser] = useState(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  useEffect(() => {
    if (state) {
      dispatch(fetchSingleUser(state));
    }
  }, [state]);
  useEffect(() => {
    if (SingleUser) {
      setsingleUser(SingleUser);
    }
  }, [SingleUser]);

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box role="presentation" onClick={(e) => e.preventDefault()}>
          <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
            <Link
              to="/dashboard"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Dashboard
            </Link>
            <Link
              to="/settings"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Settings
            </Link>
            <Link
              to="/settings/users"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Users
            </Link>
            <Typography color="text.primary" sx={{ fontSize: "12px" }}>
              {state ? "Update User" : "Create User"}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
          {state ? "Update User" : "  Create User"}
        </Typography>
        <Box>
          <Stepper
            sx={{ width: "100%", maxWidth: "400px" }}
            activeStep={activeStep}
          >
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {activeStep === 0 ? (
          <General
            setsingleUser={setsingleUser}
            singleUser={singleUser}
            state={state}
            generalData={generalData}
            setgeneralData={setgeneralData}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
          />
        ) : activeStep === 1 ? (
          <Permission
            setsingleUser={setsingleUser}
            singleUser={singleUser}
            state={state}
            generalData={generalData}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            steps={steps}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default CreateUsers;
