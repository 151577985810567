import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WonModal from "./WonModal";
import {
  Box,
  // Button,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { ChangePiplineStageApi } from "../../../Api/LeadsApi/ChangePiplineStageApi";
import { fetchLeadsSingle } from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";
import LostModal from "./LostModal";
import AppTooltip from "../../../Utils/AppTooltip";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AppConfirmationDialog from "../../AppConfirmationDialog/AppConfirmationDialog";

function PiplinesStages({ singleLead, roles }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [piplineStage, setpiplineStage] = useState(null);
  const [loading, setloading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [blueStage, setblueStage] = useState(null);
  const [opens, setOpens] = useState(false);
  const [changeStageIdx, setchangeStageIdx] = useState(null);
  const [wonPiplines, setwonPiplines] = useState(null);
  const [lostPiplines, setlostPiplines] = useState(null);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  console.log(changeStageIdx);
  console.log(singleLead);
  useEffect(() => {
    if (singleLead?.PipelineStage) {
      let idx = singleLead?.leadPipeineStage?.findIndex(
        (item) => item.Id === singleLead?.PipelineStage?.Id
      );
      setchangeStageIdx(idx);
    }
  }, [singleLead?.PipelineStage]);

  // useEffect(()=>{
  //   dispatch(fetchLeadsSingle(singleLeadId));
  // },[dispatch]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changePipline = async () => {
    //  if (singleLead?.Status === "Won" || singleLead?.Status === "Lost") {
    //   setsnackBarData({
    //     type: "error",
    //     message: `Can not change Lead Status as it is ${singleLead?.Status === "Won"}`,
    //     openToast: true,
    //   });
    //   return;
    // }
    setloading(true);
    const response = await ChangePiplineStageApi(
      singleLead?.Id,
      piplineStage?.Id
    );
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setchangeStageIdx(blueStage);
      setpiplineStage(null);
      setloading(false);
      handleClosed();
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
    }
    setloading(false);
  };
  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClosed = () => {
    setOpens(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          overflowX: "auto",
          width: "100%",
          alignItems: "center",
          "::-webkit-scrollbar": { width: "2px", height: "5px" },
        }}
      >
        {singleLead?.leadPipeineStage?.map((pipline, i) => {
          if (i < singleLead?.leadPipeineStage?.length - 1) {
            return (
              <>
                {pipline?.Name === "Won" ? (
                  <Box
                    key={i}
                    sx={{
                      backgroundColor:
                        singleLead?.Status === "Won"
                          ? "#53c41a"
                          : singleLead?.Status === "Lost"
                          ? "#ff4d50"
                          : "#c1c2c3",
                      minWidth: "200px",
                      textAlign: "center",
                      marginLeft: "-20px",
                      clipPath:
                        "polygon(100% 0%, 100% 52%, 100% 100%, 0 98%, 10% 51%, 0 0)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Typography
                      onClick={(e) => {
                        if (roles?.some((role) => role === "Leads_Edit")) {
                          if (
                            singleLead?.Status == "Won" ||
                            singleLead?.Status == "Lost"
                          ) {
                            return;
                          } else {
                            handleClick(e);
                          }
                        }
                      }}
                      sx={{
                        padding: "5px",
                        cursor: "pointer",
                        color:
                          singleLead?.Status === "Won" ||
                          singleLead?.Status === "Lost"
                            ? "white !important"
                            : "black",
                      }}
                    >
                      {pipline?.Name} /
                      {singleLead?.leadPipeineStage[i + 1]?.Name}
                    </Typography>
                    <ArrowDropDownIcon
                      sx={{
                        color:
                          singleLead?.Status === "Won" ||
                          singleLead?.Status === "Lost"
                            ? "white !important"
                            : "black",
                      }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setwonPiplines(pipline);
                          setshowModal2(true);
                          handleClose(e);
                        }}
                      >
                        {pipline?.Name}
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setlostPiplines(singleLead?.leadPipeineStage[i + 1]);
                          setshowModal(true);
                          handleClose();
                        }}
                      >
                        {singleLead?.leadPipeineStage[i + 1]?.Name}
                      </MenuItem>
                    </Menu>
                  </Box>
                ) : (
                  <>
                    {" "}
                    <AppTooltip title={pipline?.Name}>
                      <Box
                        key={i}
                        sx={{
                          cursor: "pointer",
                          backgroundColor:
                            singleLead?.Status === "Won"
                              ? "#53c41a"
                              : singleLead?.Status === "Lost"
                              ? "#ff4d50"
                              : changeStageIdx >= 0
                              ? changeStageIdx >= i
                                ? `rgba(14, 144, 217,${i / 8 + 0.4})`
                                : "#c1c2c3"
                              : `rgba(14, 144, 217,${i / 8 + 0.4})`,
                          minWidth: "200px",
                          textAlign: "center",
                          clipPath:
                            i === 0
                              ? "polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%)"
                              : "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 9% 52%, 0 0)",
                          marginLeft: i === 0 ? null : "-20px",
                        }}
                        onClick={() => {
                          if (roles?.some((role) => role === "Leads_Edit")) {
                            if (
                              singleLead.Status === "Won" ||
                              singleLead.Status === "Lost"
                            ) {
                              setsnackBarData({
                                type: "error",
                                message: `Can not change Lead Status as it is ${singleLead.Status}`,
                                openToast: true,
                              });
                            } else if (changeStageIdx >= i) {
                              setsnackBarData({
                                type: "error",
                                message: `Can not change Lead to move back`,
                                openToast: true,
                              });
                            } else {
                              setblueStage(i);

                              setpiplineStage(pipline);
                              handleClickOpen();
                            }
                          }
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            color: "white !important",
                            padding: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {pipline?.Name}
                        </Typography>
                      </Box>
                    </AppTooltip>
                  </>
                )}
              </>
            );
          }
        })}
      </Box>

      {/* <Dialog
        open={opens}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Change Stage <b>{piplineStage?.Name} </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "#cc6666",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                setpiplineStage(null);
                handleClosed();
              }}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  changePipline();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog> */}

      <AppConfirmationDialog
        open={opens}
        onDeny={() => {
          setpiplineStage(null);
          handleClosed();
        }}
        onConfirm={() => {
          changePipline();
        }}
        iconContainerColor="#ebf9f9"
        icon={
          <TrendingUpIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.primary?.main }}
          />
        }
        dialogTitle="Change Stage"
        title={`Are you sure you want to Change Stage to "${piplineStage?.Name}"?`}
        successText="Yes"
        successBtnColor="primary"
        cancelText="No"
        loading={loading}
      />

      <WonModal
        singleLead={singleLead}
        wonPiplines={wonPiplines}
        setshowModal2={setshowModal2}
        showModal2={showModal2}
      />
      <LostModal
        lostPiplines={lostPiplines}
        singleLead={singleLead}
        setshowModal={setshowModal}
        showModal={showModal}
      />
    </>
  );
}

export default PiplinesStages;
PiplinesStages.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
