import React from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useContext, useState } from "react";
import {
  fetchleadActivityTypePopulate,
  getLeadsDetails,
} from "../../Redux/Slice/LeadsSlice/LeadsSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import theme from "../../theme";
import moment from "moment/moment";
import {
  fetchQuotesPopulate,
  getQuotesDetails,
} from "../../Redux/Slice/QuoteSlice/QuoteSlice";
import { EditLeadActivityApi } from "../../Api/LeadsApi/EditLeadActivityApi";
import "react-datetime/css/react-datetime.css";
// import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      alignItems: "flex-start",
    },
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
  };
});
const callOptions = ["Cold Call", "Follow Up"];
const meetingOptions = [
  "Onsite",
  "Outdoor",
  "Sale Event",
  "Regional",
  "Video Meeting",
];
const emailOptions = ["Email"];
const messageOptions = ["SMS", "WhatsApp"];

function EditActivities() {
  const { formContainer, container, subContainer } = useStyle();
  const [logoError, setlogoError] = useState(false);

  const navigate = useNavigate();
  const initialValues = {
    title: "",
    leadId: null,
    type: "",
    subType: "",
    scheduleFrom: "",
    scheduleTo: "",
    location: "",
    description: "",
    activityPersonParticipants: null,
    activityUserParticipants: [],
    duration: "",
  };
  const { LeadsActivityType, LeadsAffliate, LeadsPersons, LeadsUsers } =
    useSelector(getLeadsDetails);
  const { QuotesPopulate } = useSelector(getQuotesDetails);
  const state = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  // console.log("state", state);
  const dispatch = useDispatch();
  const [logo, setlogo] = useState(null);

  const [formattedDatetime, setformattedDatetime] = useState(null);
  // const currentDate = moment().format().slice(0, -6) + ".000Z";

  // const disablePastDates = (current) => {
  //   const today = new Date();
  //   const yesterday = new Date(today);
  //   yesterday.setDate(yesterday.getDate() - 1);
  //   yesterday.toISOString().split("T")[0];
  //   return current.isAfter(yesterday);
  // };
  useEffect(() => {
    dispatch(fetchleadActivityTypePopulate(state?.state?.ActiviTable?.LeadId));
    dispatch(fetchQuotesPopulate());
  }, [dispatch]);

  console.log("LeadsAffliate", LeadsAffliate);
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const Users = [];
    const Persons = [];
    formik.values?.activityUserParticipants?.map((user) => {
      return Users?.push({
        userId: user?.Id,
        activityUserParticipantId: user?.activityUserParticipantId
          ? user?.activityUserParticipantId
          : 0,
      });
    });
    // formik.values?.activityPersonParticipants?.map((person) => {
    //   return Persons?.push({
    //     personId: person?.Id,
    //     activityPersonParticipantId: person?.activityPersonParticipantId
    //       ? person?.activityPersonParticipantId
    //       : 0,
    //   });
    // });
    Persons?.push({
      personId:
        formik.values?.activityPersonParticipants?.PersonId ||
        formik.values?.activityPersonParticipants?.Id,
      activityPersonParticipantId: formik.values?.activityPersonParticipants
        ?.activityPersonParticipantId
        ? formik.values?.activityPersonParticipants?.activityPersonParticipantId
        : 0,
    });
    // const obj = {
    //   ...values,
    //   scheduleFrom:moment(values?.scheduleFrom).format("YYYY-MM-DD").concat("T").concat(moment(values?.scheduleFrom).format("hh:mm:ss")).concat(".000Z"),
    //   scheduleTo:moment(values?.scheduleFrom).format("YYYY-MM-DD").concat("T").concat(moment(values?.scheduleFrom).format("hh:mm:ss")).concat(".000Z"),
    //   activityPersonParticipants: Persons,
    //   activityUserParticipants: Users,
    //   leadId: state?.id ? state?.id : state,
    // };

    // const obj2 = {
    //   ...values,
    //   // scheduleFrom: moment(values?.scheduleFrom)
    //   //   .format("YYYY-MM-DD")
    //   //   .concat("T")
    //   //   .concat(moment(values?.scheduleFrom).format("HH:mm:ss"))
    //   //   .concat(".000Z"),
    //   scheduleFrom: formattedDatetime,

    //   // scheduleTo: moment(values?.scheduleTo)
    //   //   .format("YYYY-MM-DD")
    //   //   .concat("T")
    //   //   .concat(moment(values?.scheduleTo).format("HH:mm:ss"))
    //   //   .concat(".000Z"),
    //   scheduleTo: null,
    //   activityPersonParticipants: Persons,
    //   activityUserParticipants: Users,
    //   leadId: formik?.values?.leadId?.Id,
    // };

    let formData = new FormData();
    formData.append("Title", values?.title);
    formData.append("Type", values?.type);
    formData.append("SubType", values?.subType);
    formData.append("ScheduleFrom", formattedDatetime);
    formData.append("Location", values?.location);
    formData.append("Description", values?.description);
    formData.append("LeadId", formik?.values?.leadId?.Id);
    logo?.file && formData.append("File", logo?.file ? logo?.file : null);
    logo?.name && formData.append("FileName", logo?.name ? logo?.name : null);

    values?.duration && formData.append("Minuts", values?.duration);

    for (let i = 0; i < Persons?.length; i++) {
      if (Persons[i].personId) {
        formData.append(
          `ActivityPersonParticipants[${i}].personId`,
          Persons[i].personId
        );
      }
    }
    for (let i = 0; i < Users?.length; i++) {
      if (Users[i].userId) {
        formData.append(
          `ActivityUserParticipants[${i}].userId`,
          Users[i].userId
        );
      }
    }

    const response = await EditLeadActivityApi({
      obj: formData,
      id: state?.state?.ActiviTable
        ? state?.state?.ActiviTable?.ActivtyId
        : state?.state?.ActivtyId,
    });
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      setSubmitting(false);
      resetForm();
      navigate(-1);
    }
    setSubmitting(false);
  };
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required("Please Enter  title"),
    // leadId: Yup.string().required("Please Enter  Lead"),
    type: Yup.string().required("Please Select  type"),
    scheduleFrom: Yup.string().required("Date is Required*"),
    scheduleTo: Yup.string().required("Time is Required*"),
    subType: Yup.string().required("Please Select  Sub Type"),
    duration: Yup.string().test(
      "required-if-before-current",
      "Please Enter Duration",
      function (value) {
        if (
          formik.values.type !== "Email" &&
          formik.values.type !== "Message" &&
          formik.values.subType !== "Attempt" &&
          (formik.values.subType === "Connected" ||
            formik.values.subType === "Meeting Done" ||
            (state?.state?.ActiviTable
              ? state?.state?.ActiviTable?.StatusDone
              : state?.state?.StatusDone))
        ) {
          return !!value;
        }
        return true;
      }
    ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (
        formik.values.type === "Meeting" &&
        (formik.values.subType === "Connected" ||
          formik.values.subType === "Meeting Done" ||
          (state?.state?.ActiviTable
            ? state?.state?.ActiviTable?.StatusDone
            : state?.state?.StatusDone))
      ) {
        if (logo?.file || logo?.name)
          handleSubmit(values, resetForm, setSubmitting);
        else {
          setlogoError(true);
          setSubmitting(false);
        }
      } else {
        handleSubmit(values, resetForm, setSubmitting);
      }
    },
  });
  const handleNavigate = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (state?.state?.ActiviTable) {
      let arr = [];
      let arr2 = [];
      // const dateTime = moment(
      //   state?.state?.ActiviTable?.ScheduleFrom,
      //   "DD-MMM-YYYY hh:mm A"
      // );
      const hours = Math.floor(state?.state?.ActiviTable?.Minutes / 60);
      const minutes = Math.floor(state?.state?.ActiviTable?.Minutes % 60);
      const seconds = Math.floor(
        (state?.state?.ActiviTable?.Minutes -
          Math.floor(state?.state?.ActiviTable?.Minutes)) *
          60
      );
      const dateTime = moment(
        state?.state?.ActiviTable?.ScheduleFrom,
        "DD-MMM-YYYY hh:mm A"
      );

      // Extract date and time components
      const date = dateTime.format("YYYY-MM-DD");
      const time = dateTime.format("HH:mm");
      //   let arr2 = [];
      state?.state?.ActiviTable?.ActivitiesUserParticipants?.map((item) => {
        arr.push({
          Id: item?.UserId,
          FullName: item?.FullName,
          activityUserParticipantId: item?.ActivityUserParticipantId,
        });
      });
      state?.state?.ActiviTable?.ActivitiesPersonParticipants?.map((item) => {
        arr2.push({
          Id: item?.PersonId,
          Name: item?.Name,
          activityPersonParticipantId: item?.ActivityPersonParticipantId,
        });
      });
      formik.setFieldValue("title", state?.state?.ActiviTable?.Title);
      formik.setFieldValue("type", state?.state?.ActiviTable?.Type);
      formik.setFieldValue("subType", state?.state?.ActiviTable?.SubType);
      formik.setFieldValue(
        "duration",
        state?.state?.ActiviTable?.Minutes || ""
      );
      state?.state?.ActiviTable?.File &&
        setlogo({
          url: state?.state?.ActiviTable?.File,
          name: state?.state?.ActiviTable?.FileName,
        });
      if (state?.state?.ActiviTable?.Minutes) {
        formik.setFieldValue("mins", minutes);
        formik.setFieldValue("hrs", hours);
        formik.setFieldValue("seconds", seconds);
      }
      // formik.setFieldValue(
      //   "scheduleFrom",
      //   moment(state?.state?.ActiviTable?.ScheduleFrom, [
      //     "DD-MMM-YYYY hh:mm A",
      //   ]).format("MM/DD/YYYY h:mm A")
      // );
      // formik.setFieldValue(
      //   "scheduleTo",
      //   moment(state?.state?.ActiviTable?.ScheduleTo, [
      //     "DD-MMM-YYYY hh:mm A",
      //   ]).format("MM/DD/YYYY h:mm A")
      // );
      formik.setFieldValue("scheduleFrom", date);
      formik.setFieldValue("scheduleTo", time);

      formik.setFieldValue("location", state?.state?.ActiviTable?.Location);
      formik.setFieldValue(
        "description",
        state?.state?.ActiviTable?.Description
      );
      formik.setFieldValue("activityUserParticipants", arr);
      formik.setFieldValue("activityPersonParticipants", arr2?.[0]);
      formik.setFieldValue("leadId", {
        Id: state?.state?.ActiviTable?.LeadId,
        Title: state?.state?.ActiviTable?.LeadTitle,
      });
    } else if (state) {
      // let date = moment(state?.state?.ScheduleTo.split("T")[0]).format(
      //   "DD-MM-YYYY"
      // );
      // let time = moment(
      //   state?.state?.ScheduleTo.split("T")[1].split("Z")[0],
      //   "HH:mm:ss"
      // ).format("hh:mm A");
      // let date2 = moment(state?.state?.ScheduleFrom.split("T")[0]).format(
      //   "DD-MM-YYYY"
      // );
      // let time2 = moment(
      //   state?.state?.ScheduleFrom.split("T")[1].split("Z")[0],
      //   "HH:mm:ss"
      // ).format("hh:mm A");
      // let schFrom = date2.concat(" ").concat(time2);
      // let schTo = date.concat(" ").concat(time);
      const dateTime = moment.utc(state?.state?.ScheduleFrom);

      // Extract date and time components
      const date = dateTime.format("YYYY-MM-DD");
      const time = dateTime.format("HH:mm");

      const hours = Math.floor(state?.state?.Minutes / 60);
      const minutes = Math.floor(state?.state?.Minutes % 60);
      const seconds = Math.floor(
        (state?.state?.Minutes - Math.floor(state?.state?.Minutes)) * 60
      );

      let arr = [];
      let arr2 = [];
      //   let arr2 = [];
      state?.state?.UserParticipant?.map((item) => {
        arr.push({
          Id: item?.UserId,
          FullName: item?.UserName,
          activityUserParticipantId: item?.ActivityUserParticipantId,
        });
      });
      state?.state?.PersonParticipant?.map((item) => {
        arr2.push({
          Id: item?.PersonId,
          Name: item?.PersonName,
          activityPersonParticipantId: item?.ActivityPersonParticipantId,
        });
      });
      formik.setFieldValue("title", state?.state?.Title);
      formik.setFieldValue("type", state?.state?.Type);
      formik.setFieldValue("subType", state?.state?.SubType);
      formik.setFieldValue("duration", state?.state?.Minutes || "");
      state?.state?.File &&
        setlogo({
          url: state?.state?.File,
          name: state?.state?.FileName,
        });
      if (state?.state?.Minutes) {
        formik.setFieldValue("mins", minutes);
        formik.setFieldValue("hrs", hours);
        formik.setFieldValue("seconds", seconds);
      }
      // formik.setFieldValue(
      //   "scheduleFrom",
      //   moment(schFrom, ["DD-MM-YYYY hh:mm A"]).format("MM/DD/YYYY h:mm A")
      // );
      // formik.setFieldValue(
      //   "scheduleTo",
      //   moment(schTo, ["DD-MM-YYYY hh:mm A"]).format("MM/DD/YYYY h:mm A")
      // );
      formik.setFieldValue("scheduleFrom", date);
      formik.setFieldValue("scheduleTo", time);
      formik.setFieldValue("location", state?.state?.Location);
      formik.setFieldValue("description", state?.state?.Description);
      formik.setFieldValue("activityUserParticipants", arr);
      formik.setFieldValue("activityPersonParticipants", arr2?.[0]);
      formik.setFieldValue("leadId", {
        Id: state?.state?.LeadId,
        Title: state?.state?.leadTitle,
      });
    }
  }, [state]);
  useEffect(() => {
    const datetimeString = `${formik.values.scheduleFrom}T${formik.values.scheduleTo}:00.000Z`;
    const datetime = moment(datetimeString).toISOString();
    setformattedDatetime(datetime);
  }, [formik.values.scheduleFrom, formik.values.scheduleTo]);
  const handleLogo = (e) => {
    setlogo({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };
  const handleDelImg = () => {
    document.getElementById("file-input").value = ""; // Clear the file input field
    setlogo(null);
  };
  const setTime = (hh, mm, ss) => {
    let hrs = Number(hh || 0) * 60;
    let sec = Number(ss || 0) / 60;
    let total = hrs + Number(mm || 0) + sec;

    total > 0
      ? formik.setFieldValue("duration", total)
      : formik.setFieldValue("duration", "");
  };

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box role="presentation" onClick={(e) => e.preventDefault()}>
            <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
              <Link
                to="/dashboard"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Dashboard
              </Link>
              <Link
                to="/activities"
                style={{ color: "#33A5FC", textDecoration: "none" }}
                className="link"
                underline="hover"
              >
                Activities
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                Update Activity
              </Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
            Update Activity
          </Typography>
        </Box>

        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Title</Typography>
                <TextField
                  name="title"
                  value={formik.values?.title}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.title) &&
                    Boolean(formik.touched.title)
                  }
                  helperText={
                    Boolean(formik.errors.title) &&
                    Boolean(formik.touched.title) &&
                    formik.errors.title
                  }
                />
              </FormControl>
              <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
                <FormControl fullWidth>
                  <Typography>Type</Typography>
                  <TextField
                    name="type"
                    value={formik.values?.type}
                    size={"small"}
                    select
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue("subType", "");
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik.errors.type) &&
                      Boolean(formik.touched.type)
                    }
                    helperText={
                      Boolean(formik.errors.type) &&
                      Boolean(formik.touched.type) &&
                      formik.errors.type
                    }
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    {LeadsActivityType?.map((type, i) => {
                      return (
                        <MenuItem key={i} value={type?.Name}>
                          {type?.Name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Sub Type</Typography>
                  <TextField
                    name="subType"
                    value={formik.values?.subType}
                    size={"small"}
                    select
                    fullWidth
                    onChange={(e) => {
                      formik.handleChange(e);
                      // if (
                      //   e.target.value === "Connected" ||
                      //   e.target.value === "Meeting Done"
                      // ) {
                      //   formik.setFieldValue("scheduleTo", "");
                      //   formik.setFieldValue("scheduleFrom", "");
                      // }
                    }}
                    onBlur={formik.handleBlur}
                    InputProps={{ value: formik.values.subType }}
                    error={
                      Boolean(formik.errors.subType) &&
                      Boolean(formik.touched.subType)
                    }
                    helperText={
                      Boolean(formik.errors.subType) &&
                      Boolean(formik.touched.subType) &&
                      formik.errors.subType
                    }
                  >
                    <MenuItem value="">Select Sub Type</MenuItem>
                    {formik.values.type === "Call"
                      ? callOptions?.map((type, i) => {
                          return (
                            <MenuItem key={i} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })
                      : formik.values.type === "Meeting"
                      ? meetingOptions?.map((type, i) => {
                          return (
                            <MenuItem key={i} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })
                      : formik.values.type === "Email"
                      ? emailOptions?.map((type, i) => {
                          return (
                            <MenuItem key={i} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })
                      : formik.values.type === "Message"
                      ? messageOptions?.map((type, i) => {
                          return (
                            <MenuItem key={i} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })
                      : null}
                  </TextField>
                </FormControl>
              </Box>
              <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
                <Box sx={{ width: "50%" }}>
                  <FormControl fullWidth>
                    <Typography>Date</Typography>
                    <TextField
                      name="scheduleFrom"
                      type="date"
                      size={"small"}
                      value={formik?.values?.scheduleFrom}
                      onChange={
                        formik.handleChange

                        // formik?.setFieldValue("scheduleFrom", moment(e?._d).format("YYYY-MM-DD").concat("T").concat(moment(e?._d).format("hh:mm:ss")).concat("Z"));
                      }
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      // InputProps={{
                      //   inputProps: {
                      //     min: moment()
                      //       .subtract(1, "days")
                      //       .format("YYYY-MM-DD"), // Set min to yesterday
                      //     max:
                      //       formik.values.subType === "Connected" ||
                      //       formik.values.subType === "Meeting Done"
                      //         ? moment().format("YYYY-MM-DD")
                      //         : undefined,
                      //   },
                      // }}
                      error={
                        Boolean(formik.errors.scheduleFrom) &&
                        Boolean(formik.touched.scheduleFrom)
                      }
                      helperText={
                        Boolean(formik.errors.scheduleFrom) &&
                        Boolean(formik.touched.scheduleFrom) &&
                        formik.errors.scheduleFrom
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <FormControl fullWidth>
                    <Typography>Time</Typography>
                    <TextField
                      value={formik?.values?.scheduleTo}
                      name="scheduleTo"
                      onChange={
                        // formik.values.subType === "Connected" ||
                        // formik.values.subType === "Meeting Done"
                        //   ? (e) => {
                        //       const selectedTime = e.target.value;
                        //       const currentTime = getCurrentTime();
                        //       const selectedDate = formik.values.scheduleFrom;
                        //       const selectedMinutes = convertDateTimeToMinutes(
                        //         `${selectedDate} ${selectedTime}`
                        //       );
                        //       const currentMinutes =
                        //         convertDateTimeToMinutes(currentTime);

                        //       if (selectedMinutes > currentMinutes) {
                        //         // formik.handleChange(e);
                        //         formik.setFieldError(
                        //           "scheduleTo",
                        //           "Selected time can not be greater than current time"
                        //         );
                        //       } else if (selectedMinutes <= currentMinutes) {
                        //         formik.handleChange(e);
                        //       }
                        //     }
                        //   :
                        formik.handleChange
                      }
                      onBlur={formik.handleBlur}
                      type="time"
                      size={"small"}
                      error={
                        Boolean(formik.errors.scheduleTo) &&
                        Boolean(formik.touched.scheduleTo)
                      }
                      helperText={
                        Boolean(formik.errors.scheduleTo) &&
                        Boolean(formik.touched.scheduleTo) &&
                        formik.errors.scheduleTo
                      }
                    />
                  </FormControl>
                  {/* <FormControl fullWidth>
                    <Typography>To</Typography>
                    <Datetime
                      value={formik?.values?.scheduleTo}
                      isValidDate={disablePastDates}
                      name="scheduleTo"
                      onChange={(e) => {
                        formik?.setFieldValue("scheduleTo", e?._d);
                      }}
                      error={
                        Boolean(formik.errors.scheduleTo) &&
                        Boolean(formik.touched.scheduleTo)
                      }
                      helperText={
                        Boolean(formik.errors.scheduleTo) &&
                        Boolean(formik.touched.scheduleTo) &&
                        formik.errors.scheduleTo
                      }
                    />
                    <FormHelperText>
                      {formik.errors.scheduleTo ? formik.errors.scheduleTo : ""}
                    </FormHelperText>
                  </FormControl> */}
                </Box>
              </Box>
              <FormControl fullWidth>
                <Typography>Location</Typography>
                <TextField
                  name="location"
                  value={formik.values?.location}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Description</Typography>
                <TextField
                  name="description"
                  value={formik.values?.description}
                  multiline
                  rows={4}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                />
              </FormControl>
              <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
                <Box sx={{ width: "50%" }}>
                  <FormControl fullWidth>
                    <Typography>Users</Typography>
                    <Autocomplete
                      multiple
                      name="activityUserParticipants"
                      //   options={["hjehje"]}
                      options={LeadsUsers}
                      getOptionLabel={(option) => option.FullName}
                      size={"small"}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                      value={formik.values?.activityUserParticipants}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            variant="outlined"
                            label={option.FullName}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(__, value) => {
                        formik.setFieldValue("activityUserParticipants", value);
                        // formik.values?.date=e.target.value
                      }}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <FormControl fullWidth>
                    {!LeadsAffliate?.AffiliateId && (
                      <Typography>Customer</Typography>
                    )}
                    {LeadsAffliate?.AffiliateId && (
                      <Typography>Affiliate</Typography>
                    )}
                    {/* <Autocomplete
                      name="activityPersonParticipants"
                      //   options={["hjehje"]}
                      multiple
                      options={LeadsPersons}
                      getOptionLabel={(option) => option.Name}
                      size={"small"}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            variant="outlined"
                            label={option.Name}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"

                          //   name="participants"
                        />
                      )}
                      value={formik.values?.activityPersonParticipants}
                      onChange={(__, value) => {
                        formik.setFieldValue(
                          "activityPersonParticipants",
                          value
                        );
                        // formik.values?.date=e.target.value
                      }}
                    /> */}
                    <Autocomplete
                      disabled
                      name="activityPersonParticipants"
                      options={LeadsPersons || []}
                      getOptionLabel={(option) => option.Name}
                      size={"small"}
                      renderInput={(params) => <TextField {...params} />}
                      value={formik.values?.activityPersonParticipants}
                      onChange={(__, value) => {
                        formik.setFieldValue(
                          "activityPersonParticipants",
                          value
                        );
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <FormControl fullWidth>
                <Typography>Lead</Typography>
                <Autocomplete
                  disabled
                  name="leadId"
                  options={QuotesPopulate?.leadData || []}
                  getOptionLabel={(option) => option?.Title}
                  size={"small"}
                  renderInput={(params) => <TextField {...params} />}
                  value={formik.values?.leadId}
                  onChange={(__, value) => {
                    formik.setFieldValue("leadId", value);
                    // formik.values?.date=e.target.value
                  }}
                  //   error={
                  //     Boolean(formik.errors.leadId) &&
                  //     Boolean(formik.touched.leadId)
                  //   }
                  //   helperText={
                  //     Boolean(formik.errors.leadId) &&
                  //     formik.errors.leadId
                  //   }
                />
              </FormControl>
              {formik.values.type !== "Email" &&
                formik.values.type !== "Message" &&
                // (state?.state?.ActiviTable
                //   ? state?.state?.ActiviTable?.Minutes
                //   : state?.state?.Minutes)
                formik.values.type !== "Email" &&
                formik.values.type !== "Message" &&
                (formik.values.subType === "Connected" ||
                  formik.values.subType === "Meeting Done" ||
                  (state?.state?.ActiviTable
                    ? state?.state?.ActiviTable?.StatusDone
                    : state?.state?.StatusDone)) && (
                  <>
                    <FormControl fullWidth>
                      <Typography>Duration (hh:mm:ss)</Typography>
                      <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
                        <TextField
                          sx={{
                            textAlign: "left",
                            input: { color: "black" },
                          }}
                          fullWidth
                          size="small"
                          name="hrs"
                          type="number"
                          placeholder="Hours"
                          onBlur={formik.handleBlur}
                          value={formik.values?.hrs}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setTime(
                              e.target.value,
                              formik.values?.mins,
                              formik.values?.seconds
                            );
                          }}
                          variant="outlined"
                          error={
                            Boolean(formik.touched.duration) &&
                            Boolean(formik.errors.duration)
                          }
                          helperText={
                            Boolean(formik.touched.duration) &&
                            formik.errors.duration
                          }
                        />
                        <Typography variant="h5">:</Typography>
                        <TextField
                          sx={{
                            textAlign: "left",
                            input: { color: "black" },
                          }}
                          fullWidth
                          size="small"
                          name="mins"
                          type="number"
                          placeholder="Minutes"
                          onBlur={formik.handleBlur}
                          value={formik.values?.mins}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setTime(
                              formik.values?.hrs,
                              e.target.value,
                              formik.values?.seconds
                            );
                          }}
                          variant="outlined"
                          error={
                            Boolean(formik.touched.duration) &&
                            Boolean(formik.errors.duration)
                          }
                          helperText={
                            Boolean(formik.touched.duration) &&
                            formik.errors.duration
                          }
                        />
                        <Typography variant="h5">:</Typography>
                        <TextField
                          sx={{
                            textAlign: "left",
                            input: { color: "black" },
                          }}
                          placeholder="Seconds"
                          fullWidth
                          size="small"
                          name="seconds"
                          type="number"
                          onBlur={formik.handleBlur}
                          value={formik.values?.seconds}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setTime(
                              formik.values?.hrs,
                              formik.values?.mins,
                              e.target.value
                            );
                          }}
                          variant="outlined"
                          error={
                            Boolean(formik.touched.duration) &&
                            Boolean(formik.errors.duration)
                          }
                          helperText={
                            Boolean(formik.touched.duration) &&
                            formik.errors.duration
                          }
                        />
                      </Box>
                    </FormControl>

                    <FormControl fullWidth error={logoError}>
                      <Typography>File</Typography>
                      <TextField
                        id="file-input"
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="FileUrl"
                        type="file"
                        error={logoError}
                        InputProps={{
                          inputProps: {
                            accept: "image/*",
                          },
                        }}
                        // accept={formik.values?.Type==="Image"?"image/*":".pdf, .doc, .docx"}
                        onChange={handleLogo}
                        variant="outlined"
                      />
                      <FormHelperText>
                        {logoError ? "Please Select Image" : ""}
                      </FormHelperText>
                    </FormControl>
                    {logo && (
                      <Box
                        sx={{
                          position: "relative",
                          //   height: "20%",
                          width: "50%",
                          borderRadius: "5px",
                          border: "1px solid rgb(214, 214, 214)",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer !important",
                          justifyContent: "center",
                          padding: "10px",
                        }}
                      >
                        <IconButton
                          color="error"
                          sx={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                          }}
                          onClick={handleDelImg}
                        >
                          <CancelIcon />
                        </IconButton>
                        <img
                          src={logo?.url}
                          alt="Image"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Box>
                    )}
                  </>
                )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          Edit
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default EditActivities;
