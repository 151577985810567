import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  UserProfile: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchUserProfile = createAsyncThunk(
  "/Users/profilePopulate",
  async () => {
    const response = await axiosInstance.get(`/Users/profilePopulate`);
    return response;
  }
);

const ProfileSlice = createSlice({
  name: "UserProfile",
  initialState,
  reducers: {
    ProfileAdded: {
      reducer(state, action) {
        state.UserProfile?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserProfile = action.payload.data.Data;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getUserProfile = (state) => state.UserProfile;

export const { ProfileAdded } = ProfileSlice.actions;

export default ProfileSlice.reducer;
