import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const SideBarContext = createContext();

const SideBarContextProvider = (props) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  // console.log("openSidebar", openSidebar);
  return (
    <SideBarContext.Provider value={{ openSidebar, setOpenSidebar }}>
      {props.children}
    </SideBarContext.Provider>
  );
};

export default SideBarContextProvider;

SideBarContextProvider.propTypes = {
  children: PropTypes.any,
};
