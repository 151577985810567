import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitPopulate,
  getUnitsDetails,
} from "../../../../Redux/Slice/UnitSlice/UnitSlice";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});

function UnitFilterDrawer({
  drawerOpen,
  setdrawerOpen,
  filterVal,
  setfilterVal,
  area,
  // tags,
  unit,
  section,
  setallUnit,
  setcount,
  rows,
  handleChangeVal,
  project,
  category,
}) {
  const dispatch = useDispatch();
  const { UnitsPopulate } = useSelector(getUnitsDetails);

  const { contentBox, fieldBox } = useStyles();
  const handleClose = () => {
    setdrawerOpen(!open);
  };
  const handleRemoveAll = () => {
    for (const value in filterVal) {
      if (value === "tags") {
        filterVal[value] = [];
      } else {
        filterVal[value] = "";
      }
    }
    setcount(0);
  };
  useEffect(() => {
    dispatch(fetchUnitPopulate());
  }, [dispatch]);
  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Filter</Typography>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  setallUnit(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            <Box>
              <Typography>Project Name</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="project"
                  variant="outlined"
                  size="small"
                  value={filterVal?.project}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Project"
                  className="input_border"
                  inputRef={project}
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    project.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      project: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Section</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="section"
                  variant="outlined"
                  size="small"
                  value={filterVal?.section}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Section"
                  className="input_border"
                  inputRef={section}
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    section.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      section: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Category</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="category"
                  variant="outlined"
                  size="small"
                  value={filterVal?.category}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Category"
                  className="input_border"
                  inputRef={category}
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    category.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      category: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Unit</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="unit"
                  variant="outlined"
                  size="small"
                  value={filterVal?.unit}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Unit"
                  className="input_border"
                  inputRef={unit}
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    unit.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      unit: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Area</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="area"
                  variant="outlined"
                  size="small"
                  value={filterVal?.area}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Area"
                  className="input_border"
                  inputRef={area}
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    area.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      area: "",
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Tag</Typography>
              <Box className={fieldBox}>
                {/* <Select
                  name="tags"
                  variant="outlined"
                  size="small"
                  // defaultValue={filterVal?.salesperson}
                  value={filterVal?.tags}
                  // type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Tag"
                  className="input_border"
                  select
                  inputRef={tags}
                  onChange={(e) => {
                    handleChangeVal(e);
                  }}
                >
                  <MenuItem value="">select</MenuItem>
                  {UnitsPopulate?.Tags?.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={item?.TagId}
                      >
                        {item?.Name}
                      </MenuItem>
                    );
                  })}
                </Select> */}
                <Autocomplete
                  multiple
                  options={UnitsPopulate?.Tags || []}
                  getOptionLabel={(option) => option?.Name}
                  listStyle={{ maxHeight: 200, overflow: "auto" }}
                  sx={{ width: "100%", maxWidth: "325px" }}
                  fullWidth
                  name="tags"
                  value={filterVal?.tags}
                  onChange={(event, value) => {
                    if (value) {
                      setfilterVal({
                        ...filterVal,
                        tags: value,
                      });
                    } else {
                      setfilterVal({
                        ...filterVal,
                        tags: [],
                      });
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      className="input_border"
                    />
                  )}
                />
                <CloseIcon
                  onClick={() => {
                    setfilterVal({
                      ...filterVal,
                      tags: [],
                    });
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default UnitFilterDrawer;
UnitFilterDrawer.propTypes = {
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  onTextChange: PropTypes.any,
  project: PropTypes.any,
  setallUnit: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
  section: PropTypes.any,
  unit: PropTypes.any,
  area: PropTypes.any,
  tags: PropTypes.any,
  category: PropTypes.any,
};
