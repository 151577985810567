import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  fetchAssignTargetPopulate,
  fetchAssignTargetsByTarget,
  fetchTargets,
  getTargetDetails,
} from "../../../Redux/Slice/TargetSlice/TargetSlice";
import { useDispatch, useSelector } from "react-redux";
import { AssignTargetApi } from "../../../Api/TargetApi/AssignTargetApi";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "100%",
      maxWidth: "1100px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    profile: {
      display: "flex",
      gap: "20px",
      border: "1px solid #edf1f4",
      padding: "5px 10px",
      borderRadius: "5px",
      alignItems: "center",
    },
    iconBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };
});
function AssignTargetModal({
  assignTargetModal,
  setAssignTargetModal,
  setTargetId,
  targetId,
}) {
  const { setsnackBarData } = useContext(SnackBarContext);

  const { Container, ModalContainer, TableHeader } = useStyles();
  const [renderComp, setrenderComp] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const [totalPlots, setTotalPlots] = useState(0);
  const [plotsAssigned, setPlotsAssigned] = useState(0);

  const [projects, setProjects] = useState([]);
  const [members, setMembers] = useState([]);
  const [selected, setSelected] = useState([
    { projectId: null, categoryId: null, userId: null },
  ]);
  const { AssignTargetPopulate, AssignTargetsByTarget, status } =
    useSelector(getTargetDetails);
  console.log("AssignTargetsByTarget", AssignTargetsByTarget, status);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAssignTargetPopulate(targetId));
    dispatch(fetchAssignTargetsByTarget(targetId));
  }, [dispatch]);
  const [targetDetail, settargetDetail] = useState([
    {
      category: null,
      numberOfPlots: 0,
      project: null,
      user: null,
      targetDetailId: null,
      remainingPlots: 0,
      totalPlots: 0,
      categories: [],
    },
  ]);
  console.log("targetDetail", targetDetail);
  console.log("selected", selected);

  const handleDel = async (row, i) => {
    let value = JSON.parse(JSON.stringify(targetDetail));
    let value2 = JSON.parse(JSON.stringify(selected));
    value2.splice(i, 1);
    setSelected(value2);
    console.log("Value at index ", i, " before deletion: ", value[i]);
    let categoryIndex = value[i].categories?.findIndex(
      (category) => category.CategoryId === value[i].category.CategoryId
    );
    console.log("categoryIndex", categoryIndex);
    // If categoryIndex is found, update NumberOfPlots
    if (categoryIndex !== -1) {
      await updateNumberOfPlots(
        value[i].categories,
        value[i].category.CategoryId,
        value[i].project.ProjectId,
        value[i].categories[categoryIndex].RemainingPlots +
          value[i]?.numberOfPlots
      );

      targetDetail.forEach((target, idx) => {
        if (
          target?.project?.ProjectId === value[i].project.ProjectId &&
          target?.category?.CategoryId === value[i].category.CategoryId
        ) {
          value[idx].remainingPlots =
            value[idx].remainingPlots + value[i]?.numberOfPlots;
        }
      });

      // console.log("value", value);
      // settargetDetail(value);
    }

    value.splice(i, 1);

    settargetDetail(value);
  };
  const handleAddField = () => {
    settargetDetail([
      ...targetDetail,
      {
        category: null,
        numberOfPlots: 0,
        project: null,
        user: null,
        targetDetailId: null,
        remainingPlots: 0,
        totalPlots: 0,
        categories: [],
      },
    ]);
    setSelected([
      ...selected,
      {
        categoryId: null,
        projectId: null,
        userId: null,
      },
    ]);
  };
  useEffect(() => {
    if (AssignTargetPopulate) {
      setProjects(AssignTargetPopulate?.Projects);
      setTotalPlots(AssignTargetPopulate?.totalNumberOfPlot);
      setMembers(AssignTargetPopulate?.GroupMembers);
    }
  }, [AssignTargetPopulate]);
  useEffect(() => {
    let targets = [];
    let selected = [];
    if (AssignTargetsByTarget) {
      AssignTargetsByTarget.map((target) => {
        targets.push({
          categories: [],
          category: target?.TargetDetail?.Category,
          user: target?.GroupUser,
          numberOfPlots: target?.NumberOfPlots,
          project: target?.TargetDetail?.Project,
          remainingPlots: 0,
          targetDetailId: target?.TargetDetail?.TargetDetailId,
          targetAssignId: target?.TargetAssignId,
          totalPlots: 0,
        });
        selected.push({ projectId: null, categoryId: null, userId: null });
      });
      let value = JSON.parse(JSON.stringify(targets));
      let select = JSON.parse(JSON.stringify(selected));

      for (let i = 0; i < targets.length; i++) {
        if (value[i]?.project) {
          select[i].projectId = value[i]?.project?.ProjectId;
        }
        select[i].categoryId = value[i]?.category?.CategoryId;
        select[i].userId = value[i]?.user?.UserId;

        let projectMatch = projects?.find(
          (project) => project?.ProjectId === value[i].project.ProjectId
        );
        value[i].categories = projectMatch?.Categories;
        let categoryMatch = projectMatch?.Categories?.find(
          (category) => category?.CategoryId === value[i].category?.CategoryId
        );

        value[i].totalPlots = categoryMatch?.NumberOfPlots;
        if (
          projectMatch?.Categories &&
          value[i]?.category?.CategoryId &&
          value[i]?.project?.ProjectId
        )
          handleRemainingPlots(
            i,
            value,
            projectMatch?.Categories,
            value[i]?.category?.CategoryId,
            value[i]?.project?.ProjectId
          );
        targets = value;
      }
      setSelected(select);
      settargetDetail(targets);
    }
  }, [AssignTargetsByTarget, projects]);
  useEffect(() => {
    let total = 0;
    if (targetDetail.length > 0) {
      targetDetail.forEach((target) => {
        total += target.numberOfPlots;
      });
    }
    console.log("total", total);
    setTotalPlots(AssignTargetPopulate?.totalNumberOfPlot - total);
    setPlotsAssigned((total / AssignTargetPopulate?.totalNumberOfPlot) * 100);
  }, [targetDetail]);

  function updateNumberOfPlots(
    categories,
    categoryId,
    ProjectId,
    newNumberOfPlots
  ) {
    // Find the index of the object with the given
    const categoryIndex = categories?.findIndex(
      (category) => category.CategoryId === categoryId
    );
    // If categoryIndex is found, update NumberOfPlots
    if (categoryIndex !== -1) {
      // Create a copy of the categories array
      let updatedCategories = [...categories];
      // Update NumberOfPlots for the category at categoryIndex
      updatedCategories[categoryIndex] = {
        ...updatedCategories[categoryIndex],
        RemainingPlots: newNumberOfPlots,
      };
      console.log("updatedCategories", updatedCategories);
      // Update state with the modified categories array
      let value = JSON.parse(JSON.stringify(targetDetail));
      targetDetail.forEach((target, idx) => {
        if (target?.project?.ProjectId === ProjectId) {
          value[idx].categories = updatedCategories;
        }
        if (
          target?.project?.ProjectId === ProjectId &&
          target?.category?.CategoryId === categoryId
        ) {
          value[idx].remainingPlots = newNumberOfPlots;
        }
      });
      console.log("value", value);
      settargetDetail(value);
    } else {
      console.log("Category with CategoryId", categoryId, "not found.");
    }
  }

  const handleSubmit = async () => {
    setisSubmitting(true);
    let hasNegativeRemainingPlots = targetDetail.some((targetDetail) => {
      return targetDetail.categories.some(
        (category) => category.RemainingPlots < 0
      );
    });

    if (hasNegativeRemainingPlots) {
      setsnackBarData({
        type: "error",
        message: "Error: At least one row has more plots.",
        openToast: true,
      });
      setisSubmitting(false);
      return;
    }
    let arr = [];
    targetDetail.map((target) => {
      arr.push({
        targetAssignId: target?.targetAssignId || null,
        targetDetailId: target?.targetDetailId,
        groupUserId: target?.user?.UserId,
        numberOfPlots: target?.numberOfPlots,
      });
    });
    let data = {
      targetId: targetId,
      assignDetail: arr,
    };
    const response = await AssignTargetApi(data);

    setsnackBarData(response?.snackBarData);
    if (response?.data?.IsSuccess) {
      setSelected([{ projectId: null, categoryId: null, userId: null }]);
      settargetDetail({
        category: null,
        numberOfPlots: 0,
        project: null,
        user: null,
        targetDetailId: null,
        remainingPlots: 0,
        totalPlots: 0,
        categories: [],
      });
      setAssignTargetModal(false);
      setisSubmitting(false);
      dispatch(fetchTargets());
    } else {
      setisSubmitting(false);
    }
  };
  const handleRemainingPlots = (
    i,
    value,
    categories,
    categoryId,
    projectId
  ) => {
    let sum = 0;
    targetDetail
      .filter(
        (target) => target?.project?.ProjectId === value[i]?.project?.ProjectId
      )
      .filter(
        (target) =>
          target?.category?.CategoryId === value[i]?.category?.CategoryId
      )
      .forEach((target) => {
        sum += target?.numberOfPlots || 0;
      });

    value[i].remainingPlots = Number(value[i]?.totalPlots || 0) - sum;

    console.log("sum", sum);

    setrenderComp(!renderComp);

    updateNumberOfPlots(
      categories,
      categoryId,
      projectId,
      Number(value[i]?.totalPlots || 0) - sum
    );
  };

  return (
    <>
      <Modal
        open={assignTargetModal}
        onClose={() => {
          setAssignTargetModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Assign Target
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setTargetId(null);
                  setAssignTargetModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="meter">
                    <span
                      style={{
                        width: `${plotsAssigned > 100 ? 100 : plotsAssigned}%`,
                        textAlign: "center",
                        paddingBottom: 5,
                        color: "#fff",
                        backgroundColor:
                          plotsAssigned > 100 ? "#d32f2f" : "#298b8b",
                      }}
                    >
                      {plotsAssigned > 100
                        ? `Plots must be less than ${AssignTargetPopulate?.totalNumberOfPlot}`
                        : `Plots Assigned:
                          ${
                            AssignTargetPopulate?.totalNumberOfPlot - totalPlots
                          }`}
                    </span>
                  </div>
                </Grid>
              </Grid>

              <Box
                sx={{
                  border: "1px solid #edf1f4",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  <Box>
                    <Grid container>
                      <Grid item xs={6}>
                        <Chip
                          sx={{
                            float: "left",
                            mt: "5px",
                            color:
                              (totalPlots === 0 || totalPlots < 0) && "#fff",
                            backgroundColor: (theme) =>
                              totalPlots === 0
                                ? theme?.palette?.success.light
                                : totalPlots < 0
                                ? theme?.palette?.error.light
                                : null,
                          }}
                          label={
                            totalPlots >= 0
                              ? `Plots Remaining: ${totalPlots}`
                              : `Plots can't be greater than ${AssignTargetPopulate?.totalNumberOfPlot}`
                          }
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Button
                          size="large"
                          disabled={totalPlots <= 0}
                          color="info"
                          sx={{ borderRadius: "8px", float: "right" }}
                          onClick={handleAddField}
                        >
                          + Add More
                        </Button>
                      </Grid>
                    </Grid>
                    <TableContainer sx={{}}>
                      <Table
                        sx={{
                          minWidth: { md: "auto", xs: "auto" },
                          overflow: "scroll",
                        }}
                        aria-label="simple table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow size="small">
                            <TableCell
                              size="small"
                              align="left"
                              sx={{
                                fontWeight: "bold",
                              }}
                            >
                              Members
                            </TableCell>
                            <TableCell
                              size="small"
                              align="left"
                              sx={{
                                fontWeight: "bold",

                                // color: (theme) => theme.palette.grey["500"],
                              }}
                            >
                              Project
                            </TableCell>
                            <TableCell
                              size="small"
                              align="left"
                              sx={{
                                fontWeight: "bold",
                                // color: (theme) => theme.palette.grey["500"],
                              }}
                            >
                              Category
                            </TableCell>
                            <TableCell
                              size="small"
                              align="left"
                              sx={{
                                fontWeight: "bold",
                                // color: (theme) => theme.palette.grey["500"],
                              }}
                            >
                              No. of Plots
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {targetDetail &&
                            targetDetail?.map((row, i) => {
                              return (
                                <TableRow
                                  key={i}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell size="small" align="left">
                                    <Autocomplete
                                      disabled={
                                        row?.project?.ProjectId ? true : false
                                      }
                                      name="user"
                                      options={members || []}
                                      getOptionLabel={(option) =>
                                        option?.UserName || ""
                                      }
                                      sx={{ width: "240px" }}
                                      size={"small"}
                                      renderInput={(params) => (
                                        <TextField fullWidth {...params} />
                                      )}
                                      value={row?.user}
                                      onChange={(__, values) => {
                                        let value = JSON.parse(
                                          JSON.stringify(targetDetail)
                                        );
                                        value[i].user = {
                                          UserId: values?.UserId,
                                          UserName: values?.UserName,
                                        };
                                        settargetDetail(value);
                                        setrenderComp(!renderComp);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    <Autocomplete
                                      sx={{ width: "180px" }}
                                      name="project"
                                      disabled={
                                        row?.category?.CategoryId ? true : false
                                      }
                                      options={projects || []}
                                      getOptionLabel={(option) =>
                                        option?.ProjectName || ""
                                      }
                                      size={"small"}
                                      renderInput={(params) => (
                                        <TextField fullWidth {...params} />
                                      )}
                                      value={row?.project}
                                      onChange={(__, values) => {
                                        let value = JSON.parse(
                                          JSON.stringify(targetDetail)
                                        );
                                        value[i].project = {
                                          ProjectId: values?.ProjectId,
                                          ProjectName: values?.ProjectName,
                                        };
                                        // value[i].projectId = values?.ProjectId;
                                        let targetMatch = targetDetail.find(
                                          (target) =>
                                            target?.project?.ProjectId ===
                                            values?.ProjectId
                                        );
                                        if (targetMatch) {
                                          value[i].categories =
                                            targetMatch?.categories;
                                        } else {
                                          value[i].categories =
                                            values?.Categories;
                                        }

                                        settargetDetail(value);

                                        setrenderComp(!renderComp);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    <Autocomplete
                                      sx={{ width: "150px" }}
                                      name="category"
                                      disabled={
                                        row?.project?.ProjectId ? false : true
                                      }
                                      options={
                                        row?.categories?.filter(
                                          (category) =>
                                            !selected
                                              .filter(
                                                (item) =>
                                                  item.userId ===
                                                    row?.user?.UserId &&
                                                  item.projectId ===
                                                    row?.project?.ProjectId
                                              )
                                              .some(
                                                (item) =>
                                                  item.categoryId ===
                                                  category.CategoryId
                                              ) &&
                                            category?.RemainingPlots !== 0
                                        ) || []
                                      }
                                      getOptionLabel={(option) =>
                                        option?.CategoryName || ""
                                      }
                                      size={"small"}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      value={row?.category}
                                      onChange={(__, values) => {
                                        let value = JSON.parse(
                                          JSON.stringify(targetDetail)
                                        );
                                        let select = JSON.parse(
                                          JSON.stringify(selected)
                                        );
                                        value[i].category = {
                                          CategoryName: values?.CategoryName,
                                          CategoryId: values?.CategoryId,
                                        };
                                        value[i].numberOfPlots =
                                          //   values?.RemainingPlots ||
                                          //   values?.NumberOfPlots ||
                                          0;
                                        value[i].targetDetailId =
                                          values?.TargetDetailId;
                                        value[i].totalPlots =
                                          values?.NumberOfPlots || 0;

                                        let filteredData = targetDetail
                                          .filter(
                                            (item) =>
                                              item.project.ProjectId ===
                                              value[i].project.ProjectId
                                          )
                                          .find((item) => {
                                            return (
                                              item?.category?.CategoryId ===
                                              values?.CategoryId
                                            );
                                          });
                                        console.log(
                                          "filteredData",
                                          filteredData
                                        );
                                        if (filteredData) {
                                          value[i].remainingPlots =
                                            filteredData.remainingPlots;
                                        } else {
                                          value[i].remainingPlots =
                                            values?.NumberOfPlots || 0;
                                        }
                                        // if (categoryFilter) {
                                        //   const ca tegory =
                                        //     categoryFilter.categories.find(
                                        //       (category) =>
                                        //         category.CategoryId ===
                                        //         categoryId
                                        //     );
                                        //   setRemainingPlots(
                                        //     category.RemainingPlots
                                        //   );
                                        // } else {
                                        //   setRemainingPlots(null);
                                        // }

                                        // value[i].remainingPlots =
                                        //   values?.NumberOfPlots || 0;

                                        settargetDetail(value);
                                        select[i].projectId =
                                          value[i]?.project?.ProjectId;
                                        select[i].categoryId =
                                          values?.CategoryId;
                                        select[i].userId =
                                          value[i]?.user?.UserId;
                                        setSelected(select);
                                        setrenderComp(!renderComp);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    <TextField
                                      disabled={
                                        row?.category?.CategoryId ? false : true
                                      }
                                      value={row?.numberOfPlots}
                                      type="number"
                                      name="numberOfPlots"
                                      size="small"
                                      onBlur={() => {
                                        let value = JSON.parse(
                                          JSON.stringify(targetDetail)
                                        );
                                        handleRemainingPlots(
                                          i,
                                          value,
                                          value[i]?.categories,
                                          value[i]?.category?.CategoryId,
                                          value[i]?.project?.ProjectId
                                        );
                                      }}
                                      onChange={(e) => {
                                        let value = JSON.parse(
                                          JSON.stringify(targetDetail)
                                        );
                                        value[i].numberOfPlots = Number(
                                          e.target.value
                                        );

                                        settargetDetail(value);
                                        setrenderComp(!renderComp);

                                        // Call handleRemainingPlots after updating numberOfPlots in targetDetail
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    {row?.category?.CategoryId && (
                                      <Chip
                                        sx={{
                                          float: "right",
                                          color:
                                            (row?.remainingPlots === 0 ||
                                              row?.remainingPlots < 0) &&
                                            "#fff",
                                          backgroundColor: (theme) =>
                                            row?.remainingPlots === 0
                                              ? theme?.palette?.success.light
                                              : row?.remainingPlots < 0
                                              ? theme?.palette?.error.light
                                              : null,
                                        }}
                                        size="small"
                                        label={
                                          row?.remainingPlots >= 0
                                            ? `Available: ${row?.remainingPlots}`
                                            : `Plots can't be greater than ${row?.totalPlots}`
                                        }
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell size="small" align="left">
                                    {targetDetail?.length > 1 ? (
                                      <DeleteForeverIcon
                                        sx={{
                                          color: (theme) =>
                                            theme?.palette?.primary?.delete,
                                          cursor: "pointer",
                                        }}
                                        fontSize="small"
                                        onClick={() => handleDel(row, i)}
                                      />
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box>
                  {isSubmitting ? (
                    <Box>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button variant="contained" onClick={handleSubmit}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {"Assign"}
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default AssignTargetModal;
AssignTargetModal.propTypes = {
  assignTargetModal: PropTypes.any,
  setAssignTargetModal: PropTypes.any,
  targetId: PropTypes.any,
  setTargetId: PropTypes.any,
};
