import React, { useEffect, useState } from "react";
import {
  Box,
  // Button,
  // CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import theme from "../../../../../theme";

import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment/moment";
import { makeStyles } from "@mui/styles";
import MessageIcon from "@mui/icons-material/Message";
import { SnackBarContext } from "../../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { DeleteLeadActivityApi } from "../../../../../Api/LeadsApi/DeleteActivityApi";
import { fetchLeadsSingle } from "../../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppConfirmationDialog from "../../../../AppConfirmationDialog/AppConfirmationDialog";
import ActivityInfoModal from "../../../../Activities/ActivityInfoModal";

const useStyle = makeStyles(() => {
  return {
    notesubboxMain: {
      display: "flex",
      gap: "40px",
      width: "100%",
      padding: "0px 10px 0px 20px",
    },
    iconlineMain: {
      width: "2px",
      minHeight: "100%",
      // backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      position: "relative",
    },
    iconBox: {
      width: "40px",
      height: "40px",
      border: `1px solid ${theme?.palette.primary.main}`,
      transform: "translate(-50%,0%)",
      position: "absolute",
      top: "0%",
      left: "50%",
      color: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white !important",
    },
    noteBox: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      padding: "10px",
      width: "100%",
    },
    scheduleBox: {
      display: "flex",
      gap: "5px",
    },
    scheduled: {
      fontSize: "15px !important",
      color: theme.palette.primary.main,
    },
  };
});
function DoneMessages({ singleLead, roles }) {
  const {
    notesubboxMain,
    iconlineMain,
    iconBox,
    noteBox,
    scheduleBox,
    scheduled,
  } = useStyle();
  const [doneMsgLeads, setdoneMsgLeads] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activityRow, setactivityRow] = useState(null);
  const [activityInfoModal, setactivityInfoModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [opens, setOpens] = useState(false);
  const [doneMsgrow, setdoneMsgrow] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const handleClick = (event, msg) => {
    setAnchorEl(event.currentTarget);
    setdoneMsgrow(msg);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    let arr = [];
    singleLead?.leadActivity?.map((msg) => {
      if (msg?.Type === "Message" && msg?.StatusDone === true) {
        arr.push(msg);
      }
    });
    setdoneMsgLeads(arr);
  }, [singleLead]);
  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClosed = () => {
    setOpens(false);
  };
  const deleteDoneLunch = async () => {
    setloading(true);
    const response = await DeleteLeadActivityApi(doneMsgrow?.ActivtyId);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setdoneMsgrow(null);
      setloading(false);
      handleClosed();
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
    }
    setloading(false);
  };
  return (
    <>
      <Box>
        {doneMsgLeads?.map((msg, i) => {
          let date2 = moment(msg?.ScheduleFrom.split("T")[0]).format(
            "DD-MM-YYYY"
          );
          let time2 = moment(
            msg?.ScheduleFrom.split("T")[1].split("Z")[0],
            "hh:mm A"
          ).format("hh:mm A");
          let schFrom = date2.concat(" ").concat(time2);
          return (
            <Box key={i} className={notesubboxMain}>
              <Box className={iconlineMain}>
                <Box className={iconBox}>
                  <MessageIcon sx={{}} />
                </Box>
              </Box>
              <Box sx={{ width: "100%", paddingBottom: "10px" }}>
                <Paper elevation={3} className={noteBox}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {msg?.Title}
                    </Typography>
                    <MoreVertIcon
                      onClick={
                        // e.stopPropagation();
                        (e) => {
                          if (roles?.some((role) => role === "Leads_Edit")) {
                            handleClick(e, msg);
                          }
                        }
                      }
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                        display:
                          singleLead?.Status === "Won" ||
                          singleLead?.Status === "Lost"
                            ? "none"
                            : "",
                      }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClose();
                          navigate("/edit/activities", { state: doneMsgrow });
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          handleClickOpen();
                        }}
                      >
                        Remove
                      </MenuItem>
                      <MenuItem
                        key={i}
                        onClick={() => {
                          setactivityInfoModal(true);
                          setactivityRow(msg);
                        }}
                      >
                        View Detail
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box className={scheduleBox}>
                    <Typography className={scheduled}>
                      Message scheduled at
                    </Typography>
                    <Typography className={scheduled}>{schFrom}</Typography>
                    <Typography className={scheduled}>To</Typography>
                    {/* <Typography className={scheduled}>{schTo}</Typography> */}
                  </Box>
                  <Box sx={{ backgroundColor: "#fff9c4", padding: "10px" }}>
                    <Typography>{msg?.Description}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      Added on:{" "}
                      {moment(msg?.CreatedAt).format("Do MMMM YYYY, h:mm a")}
                    </Typography>
                    <Typography sx={{ cursor: "pointer", color: "#0e90d9" }}>
                      {msg?.FullName}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
          );
        })}
      </Box>
      {activityInfoModal && (
        <ActivityInfoModal
          activityInfoModal={activityInfoModal}
          setactivityInfoModal={setactivityInfoModal}
          setactivityRow={setactivityRow}
          activityRow={activityRow}
        />
      )}
      <AppConfirmationDialog
        open={opens}
        onDeny={() => {
          setdoneMsgrow(null);
          handleClosed();
        }}
        onConfirm={() => {
          deleteDoneLunch();
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Message"
        title={`Are you sure you want to Delete "${doneMsgrow?.Title}"?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
        loading={loading}
      />
    </>
  );
}

export default DoneMessages;
DoneMessages.propTypes = {
  singleLead: PropTypes.any,
  roles: PropTypes.array,
};
