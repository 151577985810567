import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  // CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import theme from "../../../../theme";
import { CreateGroupsApi } from "../../../../Api/Settings/CreateGroupsApi";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import {
  GroupsAdded,
  fetchGroupPopulate,
  getGroupsDetails,
} from "../../../../Redux/Slice/Settings/GroupsSlice/GroupsSlice";
import { useDispatch, useSelector } from "react-redux";
import { UpdateGroupsApi } from "../../../../Api/Settings/Groups/UpdateGroupsApi";
import ReactSelect from "react-select";
import { ReactSelectTheme } from "../../../../Utils/ReactSelectTheme";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyle = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    //   LoadingContainer: {
    //     height: "70vh",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
  };
});

function CreateGroup() {
  const { container, subContainer, formContainer } = useStyle();
  const { state } = useLocation();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const { GroupPopulate } = useSelector(getGroupsDetails);
  console.log("GroupPopulate", GroupPopulate);
  const [managerArr, setManagerArr] = useState([]);
  const [updateIds, setUpdateIds] = useState([]);
  const initialValues = {
    name: "",
    description: "",
    groupManager: "",
  };
  useEffect(() => {
    if (state) {
      handleEdit(state);
    }
  }, []);
  useEffect(() => {
    dispatch(fetchGroupPopulate());
  }, [dispatch]);
  useEffect(() => {
    let opt = [];
    if (GroupPopulate) {
      GroupPopulate?.BDMUsers?.forEach((user) => {
        opt?.push({ value: user.Id, label: user.FullName });
      });
      setManagerArr(opt);
    }
  }, [GroupPopulate]);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "name",
        value: values?.name || "",
      },
    });
    formik.handleChange({
      target: {
        name: "description",
        value: values?.description || "",
      },
    });
    let users = [];
    let userupdateIds = [];

    values.users.forEach((user) => {
      userupdateIds.push({ updateid: user.GroupUserId, userId: user.User.Id });
      if (user.UserType === "GroupManager") {
        formik.handleChange({
          target: {
            name: "groupManager",
            value: { value: user.User.Id, label: user.User.FullName } || "",
          },
        });
      } else {
        users.push(user.User.Id);
      }
      setUsers(users);
      setUpdateIds(userupdateIds);
    });
  };
  const navigate = useNavigate();
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    if (state) {
      let groupusers = [
        { userId: values.groupManager.value, userType: "GroupManager" },
      ];
      users.forEach((user) => {
        groupusers.push({
          userId: user,
          userType: "User",
        });
      });
      updateIds.forEach((update) => {
        // Find the corresponding user in groupusers
        const userToUpdate = groupusers.find(
          (user) => user.userId === update.userId
        );
        // If user is found, update its object
        if (userToUpdate) {
          userToUpdate.groupUserId = update.updateid;
        }
      });
      let data = {
        name: values.name,
        description: values.description,
        groupUsers: groupusers,
      };

      const response = await UpdateGroupsApi({ ...data, id: state.ID });
      setsnackBarData(response.snackBarData);
      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
    } else {
      let groupusers = [
        { userId: values.groupManager.value, userType: "GroupManager" },
      ];
      users.forEach((user) => {
        groupusers.push({
          userId: user,
          userType: "User",
        });
      });
      let data = {
        name: values.name,
        description: values.description,
        groupUsers: groupusers,
      };
      const response = await CreateGroupsApi(data);
      setsnackBarData(response.snackBarData);

      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
        dispatch(GroupsAdded());
      }
    }
    setSubmitting(false);
  };
  const handleNavigate = () => {
    navigate(-1);
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter group Name"),
    description: Yup.string().required("Please Enter Description"),
    groupManager: Yup.object().required("Please Select Group Manager"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  console.log("formik.touched", formik.touched);
  console.log("formik.errors", formik.errors);

  const [users, setUsers] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setUsers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box role="presentation" onClick={(e) => e.preventDefault()}>
          <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
            <Link
              to="/dashboard"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Dashboard
            </Link>
            <Link
              to="/settings"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Settings
            </Link>
            <Link
              to="/settings/groups"
              style={{ color: "#33A5FC", textDecoration: "none" }}
              className="link"
              underline="hover"
            >
              Groups
            </Link>
            <Typography color="text.primary" sx={{ fontSize: "12px" }}>
              {state ? "Update Group" : "Create Group"}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Typography variant="h5" sx={{ textAlign: "left", fontWeight: 400 }}>
          {state ? "Update Group" : " Create Group"}
        </Typography>
        <Paper elevation={3} sx={{ padding: "20px" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Name</Typography>
                <TextField
                  name="name"
                  value={formik.values?.name}
                  size={"small"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                  }
                  helperText={
                    Boolean(formik.errors.name) && Boolean(formik.touched.name)
                      ? formik.errors.name
                      : ""
                  }
                />
              </FormControl>

              <FormControl
                fullWidth
                error={
                  Boolean(formik.errors.groupManager) &&
                  Boolean(formik.touched.groupManager)
                }
              >
                <Typography>Group Manager</Typography>
                <ReactSelect
                  styles={ReactSelectTheme(
                    Boolean(formik.errors.groupManager) &&
                      Boolean(formik.touched.groupManager)
                  )}
                  // styles={{
                  //   control: (baseStyles) => ({
                  //     ...baseStyles,
                  //     borderColor:
                  //       Boolean(formik.errors.groupManager) &&
                  //       Boolean(formik.touched.groupManager)
                  //         ? "red"
                  //         : "#bdbdbd",
                  //   }),
                  // }}
                  name="groupManager"
                  options={managerArr}
                  value={formik.values.groupManager}
                  onChange={(value) => {
                    formik.setFieldValue("groupManager", value);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Select Group Manager"
                />
                <FormHelperText>
                  {Boolean(formik.errors.groupManager) &&
                  Boolean(formik.touched.groupManager)
                    ? formik.errors.groupManager
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <Typography>Users</Typography>
                <Select
                  placeholder="Select Other Plot Info"
                  id="demo-multiple-chip"
                  multiple
                  size="small"
                  value={users}
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const selectedUser = GroupPopulate?.BDAUsers?.find(
                          (user) => user.Id === value
                        );
                        return selectedUser ? (
                          <Chip key={value} label={selectedUser.FullName} />
                        ) : null;
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {GroupPopulate?.BDAUsers?.filter(
                    (user) => user.Id !== formik.values.groupManager.value
                  ).map((user, idx) => (
                    <MenuItem
                      key={idx}
                      value={user.Id}
                      sx={
                        users?.includes(user.Id)
                          ? { color: theme.palette.primary.main }
                          : null
                      }
                    >
                      {user?.FullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <Typography>Description</Typography>
                <TextField
                  name="description"
                  value={formik.values?.description}
                  size={"small"}
                  multiline
                  rows={4}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik.errors.description) &&
                    Boolean(formik.touched.description)
                  }
                  helperText={
                    Boolean(formik.errors.description) &&
                    Boolean(formik.touched.description)
                      ? formik.errors.description
                      : ""
                  }
                />
              </FormControl>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <Button onClick={handleNavigate}>
                      <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                    </Button>
                  </Box>
                  <Box>
                    {formik.isSubmitting ? (
                      <Box>
                        {/* <Loader/> */}
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button variant="contained" type="submit">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {state ? "Update" : "Save"}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreateGroup;
