import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import Loader from "../../Components/Loader/Loader";
import { Badge, Button, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import CloseIcon from "@mui/icons-material/Close";
// import DoneIcon from "@mui/icons-material/Done";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DeleteProductApi } from "../../Api/ProductsApi/DeleteProductApi";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Joyride from "react-joyride";
import ArticleIcon from "@mui/icons-material/Article";
import {
  fetchServices,
  getServicesDetails,
} from "../../Redux/Slice/ServiceSlice/ServiceSlice";
import ServiceInfoModal from "../../Components/Service/CreateService/ServiceInfoModal";
import { AppContext } from "../../Context/TourContext/TourContext";
import DocumentModal from "../../Components/DocumentModal/DocumentModal";
import DocumentDetailModal from "../../Components/DocumentModal/DocumentDetailModal";
import {
  fetchDocuments,
  getOrganizationDetails,
} from "../../Redux/Slice/OrganizationSlice/OrganizationSlice";
import AppTooltip from "../../Utils/AppTooltip";
import ServiceFilterDrawer from "../../Components/Service/ServiceFilterDrawer/ServiceFilterDrawer";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import AppConfirmationDialog from "../../Components/AppConfirmationDialog/AppConfirmationDialog";
import { PageNumberContext } from "../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

function Service({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { totalRows } = useContext(PageNumberContext);

  const navigate = useNavigate();
  const [pagesize, setpagesize] = useState(totalRows);
  const dispatch = useDispatch();
  const [rows, setrows] = React.useState([]);
  const [allService, setallService] = useState([]);
  const [service, setService] = useState([]);

  const [drawerOpen, setdrawerOpen] = useState(false);
  const [filterVal, setfilterVal] = useState(null);
  const [count, setcount] = useState(0);
  const { Services, status } = useSelector(getServicesDetails);
  const [showLoading, setshowLoading] = useState(false);
  const [showConfirmationIcons, setshowConfirmationIcons] = useState(false);
  const [selectedId, setselectedId] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const SKU = useRef();
  const name = useRef();
  const priceRef = useRef();
  const costRef = useRef();
  const categoryRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [documentDetailModal, setdocumentDetailModal] = useState(false);
  const { DocumentsData, status2 } = useSelector(getOrganizationDetails);
  const { setState, state } = useContext(AppContext);
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Service");
    if (!isSkip && status === "succeeded") {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, [status]);
  const handleClosed = () => {
    setShowModal(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {roles?.some((role) => role === "export_Create") && (
              <GridToolbarExport
                csvOptions={{ fileName: "Active Employees" }}
              />
            )}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarColumnsButton />
            <Box
            // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
            >
              <Button onClick={() => setdrawerOpen(true)}>
                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                  <Badge badgeContent={count} color="primary">
                    <FilterAltIcon fontSize="small" />
                  </Badge>
                  Multi Filters
                </Box>
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: "30%" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "end",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "2px",
                    backgroundColor: "green",
                    cursor: "pointer",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "14px" }}>Available</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "2px",
                    backgroundColor: "#dd284c",
                    cursor: "pointer",
                  }}
                ></Box>
                <Typography sx={{ fontSize: "14px" }}>Not Available</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  useEffect(() => {
    // if (Products == null) {
    dispatch(fetchServices());
    // }
  }, [dispatch]);
  useEffect(() => {
    let rowData = [];
    Services?.map((service, i) => {
      console.log("service", service);
      rowData.push({
        id: i + 1,
        sku: service.Sku,
        name: service.Name,
        price: service.Price?.toLocaleString(),
        cost: service.Cost?.toLocaleString(),
        quantity: service.Quantity,
        ID: service.Id,
        Description: service.Description,
        Uom: service.Uom,
        Tax: service.Tax,
        ProductCode: service.ProductCode,
        ProductCategory: service.ProductCategory,
        CommissionPercentage: service?.CommissionPercentage,
        Active: service?.Active,
        category: service.ProductCategory,
        IsDocument: service?.IsDocument,
      });
    });
    setrows(rowData);
    setallService(rowData);
  }, [Services]);
  const deleteProduct = async (id) => {
    setshowLoading(true);
    let res = await DeleteProductApi(id);
    setshowLoading(false);
    setselectedId(null);
    setshowConfirmationIcons(false);
    if (res.data.IsSuccess) {
      dispatch(fetchServices());
    }
    setsnackBarData(res.snackBarData);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90 },

    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "price",
      headerName: "Price",
      width: 130,
    },

    {
      field: "category",
      headerName: "Category",
      width: 250,
    },
    {
      field: "cost",
      headerName: "Cost",
      width: 180,
    },
    {
      field: "IsDocument",
      headerName: "Documents",
      width: 150,
      renderCell: (celval, i) => {
        return (
          <>
            <AppTooltip
              title={
                celval?.row?.IsDocument == true
                  ? "Docements Available"
                  : "No Docements"
              }
            >
              <Box
                sx={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "2px",
                  backgroundColor:
                    celval?.row?.IsDocument == true ? "green" : "#dd284c",
                  cursor: "pointer",
                }}
                key={i}
              ></Box>
            </AppTooltip>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (celval, i) => {
        return (
          <Box key={i}>
            {roles?.some((role) => role === "Service_Edit") && (
              <Tooltip title="Edit">
                <IconButton
                  key={i}
                  onClick={() => {
                    handleEdit(celval.row);
                  }}
                >
                  <EditIcon
                    sx={{ color: (theme) => theme?.palette?.primary?.edit }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            {roles?.some((role) => role === "Service_Delete") && (
              <Tooltip title="Delete">
                <IconButton
                  key={i}
                  onClick={() => {
                    setselectedId(celval.row.ID);
                    setshowConfirmationIcons(true);
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme?.palette?.primary?.delete,
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="View">
              <IconButton
                key={i}
                onClick={() => {
                  console.log("celval.row", celval.row);
                  setShowModal(true);
                  setService(celval.row);
                  // dispatch(fetchproduct(celval.row?.ID));
                }}
              >
                <VisibilityIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="View Document">
              <IconButton
                key={i}
                onClick={async () => {
                  // setShowModal(true);
                  const response = await dispatch(
                    fetchDocuments({
                      type: "Service",
                      id: celval.row?.ID,
                    })
                  );
                  if (response?.payload?.data?.IsSuccess == true) {
                    setdocumentDetailModal(true);
                  } else {
                    setsnackBarData({
                      type: "error",
                      message: "Something wrong",
                      openToast: true,
                    });
                  }
                  console.log(response);

                  // setdocumentDetailModal(true);
                  setselectedRow(celval.row);
                  // setselectedId(celval.row.ID);
                }}
              >
                <ArticleIcon
                  sx={{
                    color: (theme) => theme?.palette?.primary?.main,
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleEdit = (val) => {
    navigate("/service/create", { state: val });
  };
  const handleChangeVal = (e) => {
    setfilterVal({
      ...filterVal,
      [e.target.name]: e.target.value,
    });
  };
  const onTextChange = () => {
    if (
      SKU.current?.value === "" &&
      name.current?.value === "" &&
      priceRef.current?.value === "" &&
      costRef.current?.value === "" &&
      categoryRef.current?.value === ""
    ) {
      setallService(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(SKU.current.value, "i");
      let regex2 = new RegExp(name.current.value, "i");
      let regex3 = new RegExp(priceRef.current.value, "i");
      let regex4 = new RegExp(costRef.current.value, "i");
      let regex5 = new RegExp(categoryRef.current.value, "i");
      suggestions = rows?.filter(
        (val) =>
          (SKU.current.value === "" || regex1.test(val.sku)) &&
          (name.current.value === "" || regex2.test(val?.name)) &&
          (priceRef.current.value === "" ||
            regex3.test(val?.price.replace(/,/g, ""))) &&
          (costRef.current.value === "" ||
            regex4.test(val?.cost.replace(/,/g, ""))) &&
          (categoryRef.current.value === "" || regex5.test(val?.category))
      );
      setallService(suggestions);
    }
  };
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Service"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}

        {/* {activePage} */}
      </Box>
      <Box id="service">
        <ServiceFilterDrawer
          name={name}
          handleChangeVal={handleChangeVal}
          rows={rows}
          priceRef={priceRef}
          costRef={costRef}
          setcount={setcount}
          setallService={setallService}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
          categoryRef={categoryRef}
          onTextChange={onTextChange}
          SKU={SKU}
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
        />

        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allService}
            columns={columns}
          />
          {status === "loading" || status2 == "loading" || showLoading ? (
            <Loader />
          ) : null}
        </Box>
      </Box>

      <ServiceInfoModal
        handleClosed={handleClosed}
        showModal={showModal}
        service={service}
      />
      {showModal2 && (
        <DocumentModal
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Service"
          setShowModal={setShowModal2}
          showModal={showModal2}
        />
      )}
      {documentDetailModal && (
        <DocumentDetailModal
          DocumentsData={DocumentsData}
          setselectedRow={setselectedRow}
          selectedRow={selectedRow}
          Type="Service"
          setShowModal={setdocumentDetailModal}
          setformModal={setShowModal2}
          showModal={documentDetailModal}
        />
      )}
      <AppConfirmationDialog
        open={showConfirmationIcons}
        onDeny={() => {
          setselectedId(null);
          setshowConfirmationIcons(false);
        }}
        onConfirm={() => {
          deleteProduct(selectedId);
        }}
        iconContainerColor="#fbeaea"
        icon={
          <DeleteForeverIcon
            fontSize="small"
            sx={{ color: (theme) => theme?.palette?.error?.main }}
          />
        }
        dialogTitle="Delete Service"
        title={`Are you sure, you want to Delete Service?`}
        successText="Yes"
        successBtnColor="error"
        cancelText="No"
      />
    </>
  );
}

export default Service;
Service.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#service",
    content: (
      <>
        <p size="large">Services Page</p>
        <p>
          Welcome to the Services Page – your central hub for managing a variety
          of services. Each service plays a crucial role, and you have the tools
          to control and monitor their availability.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
