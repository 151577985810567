import React, { useState } from "react";
import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchleadActivityTypePopulate,
  fetchLeadsSingle,
  getLeadsDetails,
} from "../../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { CreateLeadActivityApi } from "../../../../Api/LeadsApi/CreateLeadActivityApi";
import PropTypes from "prop-types";
import "react-datetime/css/react-datetime.css";
import moment from "moment/moment";
import { getUserProfile } from "../../../../Redux/Slice/Profile/ProfileSlice";
import ActivityForm from "./ActivityForm";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme from "../../../../theme";
import { makeStyles } from "@mui/styles";
import { fetchActivities } from "../../../../Redux/Slice/ActivitiesSlice/ActivitiesSlice";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
      maxHeight: "800px",
      overflowY: "auto",
      overflowX: "hidden",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
const callOptions = ["Cold Call", "Follow Up"];
const meetingOptions = [
  "Onsite",
  "Outdoor",
  "Sale Event",
  "Regional",
  "Video Meeting",
];
const emailOptions = ["Email"];
const messageOptions = ["SMS", "WhatsApp"];

function Activity({
  state,
  leadData,
  activityModal,
  showModal,
  setshowModal,
  leadId,
  customer,
  title,
  setCustomer,
  setTitle,
  close,
  leadView,
  pageNo,
  pageSize,
}) {
  // const [logo, setlogo] = useState(null);

  const currentDate = moment().format().slice(0, -6) + ".000Z";
  const { Container, ModalContainer, TableHeader, container, subContainer } =
    useStyles();
  const initialValues = {
    title: "",
    isAffiliate: false,
    type: "",
    subType: "",
    scheduleFrom: "",
    scheduleTo: "",
    location: "",
    description: "",
    activityPersonParticipants: null,
    activityUserParticipants: [],
    duration: "",
    status: "",
  };
  const navigate = useNavigate();
  const { LeadsActivityType, LeadsAffliate, LeadsPersons, LeadsUsers } =
    useSelector(getLeadsDetails);
  const { UserProfile } = useSelector(getUserProfile);
  // const [logoError, setlogoError] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [formattedDatetime, setformattedDatetime] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (leadData) {
      dispatch(fetchleadActivityTypePopulate(leadData.Id));
    } else if (leadId) {
      dispatch(fetchleadActivityTypePopulate(leadId));
      console.log("customer", customer);
    }
  }, [dispatch]);

  const handleSubmit = async (values, resetForm, setSubmitting, logo) => {
    if (
      values?.activityPersonParticipants === null ||
      values?.activityPersonParticipants === ""
    ) {
      setSubmitting(false);

      return setsnackBarData({
        type: "error",
        message: "Please Enter Person",
        openToast: true,
      });
    }
    if (values?.activityUserParticipants.length <= 0) {
      setSubmitting(false);

      return setsnackBarData({
        type: "error",
        message: "Please Enter User",
        openToast: true,
      });
    }
    const Users = [];
    const Persons = [];
    formik.values?.activityUserParticipants?.map((user) => {
      return Users?.push({
        userId: user?.Id,
      });
    });

    Persons?.push({
      personId:
        formik.values?.activityPersonParticipants?.PersonId ||
        formik.values?.activityPersonParticipants?.Id,
    });

    let formData = new FormData();
    formData.append("Title", values?.title);
    formData.append("Type", values?.type);
    formData.append("SubType", values?.subType);
    formData.append("ScheduleFrom", formattedDatetime);
    formData.append("Location", values?.location);
    formData.append("Description", values?.description);
    formData.append("LeadId", leadId ? leadId : state?.id ? state?.id : state);
    formData.append("File", logo?.file ? logo?.file : null);
    formData.append("Minuts", values?.duration);
    moment(formattedDatetime).isBefore(currentDate)
      ? formData.append("Status", values?.status || "Sent")
      : null;

    for (let i = 0; i < Persons?.length; i++) {
      if (Persons[i].personId) {
        formData.append(
          `ActivityPersonParticipants[${i}].personId`,
          Persons[i].personId
        );
      }
    }
    for (let i = 0; i < Users?.length; i++) {
      if (Users[i].userId) {
        formData.append(
          `ActivityUserParticipants[${i}].userId`,
          Users[i].userId
        );
      }
    }
    const response = await CreateLeadActivityApi(formData);
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      setSubmitting(false);
      resetForm();
      showDoneModal && setShowDoneModal(false);

      dispatch(
        state?.id
          ? fetchLeadsSingle(Number(state?.id))
          : fetchLeadsSingle(Number(state))
      );
      leadView
        ? null
        : !close
        ? navigate(-1)
        : dispatch(
            fetchActivities({
              PageNumber: pageNo,
              PageSize: pageSize,
              activityType: "All",
            })
          );
      customer && setCustomer(null);
      title && setTitle(null);
      setshowModal(false);
    }
    setSubmitting(false);
  };
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required("Please Enter Title"),
    type: Yup.string().required("Please Enter Type"),
    subType: Yup.string().required("Please Select  Sub Type"),

    scheduleFrom: Yup.string().required(
      "Please Enter Date with correct format"
    ),
    scheduleTo: Yup.string().required("Please Enter Time with correct format"),

    // duration: Yup.string().test(
    //   "required-if-before-current",
    //   "Please Enter Duration",
    //   function (value) {
    //     if (
    //       formik.values.type !== "Email" &&
    //       formik.values.type !== "Message" &&
    //       formik.values.subType !== "Attempt" &&
    //       moment(formattedDatetime).isBefore(currentDate)
    //     ) {
    //       return !!value;
    //     }
    //     return true;
    //   }
    // ),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      // if (
      //   formik.values.type === "Meeting" &&
      //   moment(formattedDatetime).isBefore(currentDate)
      // ) {
      //   if (logo?.file) handleSubmit(values, resetForm, setSubmitting);
      //   else {
      //     setlogoError(true);
      //     setSubmitting(false);
      //   }
      // } else handleSubmit(values, resetForm, setSubmitting);
      if (
        (formik.values.type === "Meeting" || formik.values.type === "Call") &&
        moment(formattedDatetime).isBefore(currentDate)
      ) {
        setShowDoneModal(true);
        setSubmitting(false);
        return;
      } else {
        console.log("first");

        handleSubmit(values, resetForm, setSubmitting);
      }
    },
  });
  console.log("formik.errors", formik.errors);
  useEffect(() => {
    if (UserProfile) {
      formik.setFieldValue("activityUserParticipants", [
        {
          Id: UserProfile?.Id,
          FullName: UserProfile?.FullName,
        },
      ]);
    }
  }, [UserProfile]);
  useEffect(() => {
    if (leadData) {
      formik.setFieldValue("activityPersonParticipants", {
        Id: leadData?.PersonId,
        Name: leadData?.PersonName,
      });
    } else if (customer) {
      formik.setFieldValue("activityPersonParticipants", {
        Id: customer?.PersonId,
        Name: customer?.Name,
      });
    }
  }, [leadData, customer]);
  useEffect(() => {
    const datetimeString = `${formik.values.scheduleFrom}T${formik.values.scheduleTo}:00.000Z`;
    const datetime = moment(datetimeString).toISOString();
    setformattedDatetime(datetime);
  }, [formik.values.scheduleFrom, formik.values.scheduleTo]);
  // const handleLogo = (e) => {
  //   setlogo({
  //     url: URL.createObjectURL(e.target.files[0]),
  //     file: e.target.files[0],
  //   });
  // };
  // const handleDelImg = () => {
  //   document.getElementById("file-input").value = ""; // Clear the file input field
  //   setlogo(null);
  // };
  // const setTime = (hh, mm, ss) => {
  //   let hrs = Number(hh || 0) * 60;
  //   let sec = Number(ss || 0) / 60;
  //   let total = hrs + Number(mm || 0) + sec;

  //   total > 0
  //     ? formik.setFieldValue("duration", total)
  //     : formik.setFieldValue("duration", "");
  // };
  const getCurrentTime = () => {
    const now = new Date();
    let year = now.getFullYear();
    let month = String(now.getMonth() + 1).padStart(2, "0");
    let day = String(now.getDate()).padStart(2, "0");
    let hour = String(now.getHours()).padStart(2, "0");
    let minute = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hour}:${minute}`;
  };
  function convertDateTimeToMinutes(dateTime) {
    const [date, time] = dateTime.split(" "); // Split date and time
    const [year, month, day] = date.split("-").map(Number); // Parse date components
    const [hours, minutes] = time.split(":").map(Number); // Parse time components

    // Calculate total minutes from date and time components
    const totalMinutes =
      (year * 365 + month * 30 + day) * 24 * 60 + hours * 60 + minutes;

    return totalMinutes;
  }
  useEffect(() => {
    if (customer) {
      formik.setFieldValue("activityPersonParticipants", customer);
    }
    if (title) {
      formik.setFieldValue("title", title);
    }
  }, [customer, title]);
  console.log("lead type", LeadsActivityType);
  return (
    <>
      {activityModal ? (
        <Modal
          open={showModal}
          onClose={() => {
            setshowModal(false);
          }}
        >
          <Box className={ModalContainer}>
            <Box className={Container}>
              <Box className={TableHeader}>
                <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                  Add Lead Activity
                </Typography>
                {close && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setshowModal(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                )}
              </Box>
              <Box className={container}>
                <Box className={subContainer}>
                  <ActivityForm
                    formik={formik}
                    LeadsActivityType={LeadsActivityType}
                    leadData={leadData}
                    customer={customer}
                    LeadsPersons={LeadsPersons}
                    callOptions={callOptions}
                    messageOptions={messageOptions}
                    emailOptions={emailOptions}
                    meetingOptions={meetingOptions}
                    convertDateTimeToMinutes={convertDateTimeToMinutes}
                    getCurrentTime={getCurrentTime}
                    LeadsUsers={LeadsUsers}
                    LeadsAffliate={LeadsAffliate}
                    currentDate={currentDate}
                    formattedDatetime={formattedDatetime}
                    // setTime={setTime}
                    // handleLogo={handleLogo}
                    // logoError={logoError}
                    // handleDelImg={handleDelImg}
                    // logo={logo}
                    activityModal={activityModal}
                    handleSubmit={handleSubmit}
                    showDoneModal={showDoneModal}
                    setShowDoneModal={setShowDoneModal}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : (
        <Paper elevation={3}>
          <ActivityForm
            formik={formik}
            LeadsPersons={LeadsPersons}
            LeadsAffliate={LeadsAffliate}
            LeadsActivityType={LeadsActivityType}
            leadData={leadData}
            customer={customer}
            callOptions={callOptions}
            messageOptions={messageOptions}
            emailOptions={emailOptions}
            meetingOptions={meetingOptions}
            convertDateTimeToMinutes={convertDateTimeToMinutes}
            getCurrentTime={getCurrentTime}
            LeadsUsers={LeadsUsers}
            currentDate={currentDate}
            formattedDatetime={formattedDatetime}
            // setTime={setTime}
            // handleLogo={handleLogo}
            // logoError={logoError}
            // handleDelImg={handleDelImg}
            // logo={logo}
            handleSubmit={handleSubmit}
            showDoneModal={showDoneModal}
            setShowDoneModal={setShowDoneModal}
          />
        </Paper>
      )}
    </>
  );
}

export default Activity;
Activity.propTypes = {
  state: PropTypes.any,
  leadData: PropTypes.any,
  activityModal: PropTypes.any,
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
  leadId: PropTypes.any,
  customer: PropTypes.any,
  setCustomer: PropTypes.any,
  setTitle: PropTypes.any,
  close: PropTypes.any,
  title: PropTypes.any,
  leadView: PropTypes.any,
  pageNo: PropTypes.number,
  pageSize: PropTypes.number,
};
