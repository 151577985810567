import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import AttachEmailIcon from "@mui/icons-material/AttachEmail";
// import SendIcon from "@mui/icons-material/Send";
// import DraftsIcon from "@mui/icons-material/Drafts";
// import AttachmentIcon from "@mui/icons-material/Attachment";
import CallIcon from "@mui/icons-material/Call";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ApartmentIcon from "@mui/icons-material/Apartment";
import InventoryIcon from "@mui/icons-material/Inventory";
import SettingsIcon from "@mui/icons-material/Settings";
import NotesIcon from "@mui/icons-material/Notes";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import OutboxIcon from "@mui/icons-material/Outbox";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import CampaignIcon from "@mui/icons-material/Campaign";
import CameraIcon from "@mui/icons-material/Camera";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
// import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import MessageIcon from "@mui/icons-material/Message";
import FacebookIcon from "@mui/icons-material/Facebook";
import HailIcon from "@mui/icons-material/Hail";
import BallotIcon from "@mui/icons-material/Ballot";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AdjustIcon from "@mui/icons-material/Adjust";
import LoopIcon from "@mui/icons-material/Loop";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Groups2Icon from "@mui/icons-material/Groups2";

export const AdminSideBarData = [
  {
    name: "Dashboard",
    RoleName: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [],
  },
  {
    name: "Leads",
    RoleName: "Leads",
    path: "/leads",
    icon: <LeaderboardIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Leads",
        RoleName: "Leads",
        path: "/leads",
        icon: <LeaderboardIcon fontSize="small" />,
      },
      {
        name: "Public Leads",
        RoleName: "Leads_Public Leads",
        path: "/publicleads",
        icon: <HailIcon fontSize="small" />,
      },
      {
        name: "Recycle Leads",
        RoleName: "Leads_Recycle",
        path: "/recycle-leads",
        icon: <LoopIcon fontSize="small" />,
      },
      {
        name: "Transfer Leads",
        RoleName: "Leads_Transfer",
        path: "/TransferLeads",
        icon: <SwapHorizIcon fontSize="small" />,
      },
    ],
  },
  {
    name: "Quotes",
    RoleName: "Quotes",
    path: "/quotes",
    icon: <NotesIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [],
  },
  // {
  //   name: "Email",
  //   RoleName: "Mail",
  //   path: "/email/compose",
  //   icon: <AttachEmailIcon fontSize="small" />,
  //   iconClosed: <ArrowDropDownIcon />,
  //   iconOpened: <ArrowDropUpIcon />,
  //   subitems: [
  //     {
  //       name: "Compose",
  //       RoleName: "Mail",
  //       path: "/email/compose",
  //       icon: <AttachmentIcon fontSize="small" />,
  //     },
  //     {
  //       name: "Inbox",
  //       RoleName: "Mail_Inbox",
  //       path: "/email/inbox",
  //       icon: <ArchiveIcon fontSize="small" />,
  //     },
  //     {
  //       name: "Draft",
  //       RoleName: "Mail_Draft",
  //       path: "/email/draft",
  //       icon: <DraftsIcon fontSize="small" />,
  //     },
  //     {
  //       name: "OutBox",
  //       RoleName: "Mail_OutBox",
  //       path: "/email/outbox",
  //       icon: <OutboxIcon fontSize="small" />,
  //     },
  //     {
  //       name: "Sent",
  //       RoleName: "Mail_Sent",
  //       path: "/email/sent",
  //       icon: <SendIcon fontSize="small" />,
  //     },
  //     {
  //       name: "Trash",
  //       RoleName: "Mail_Trash",
  //       path: "/email/trash",
  //       icon: <RestoreFromTrashIcon fontSize="small" />,
  //     },
  //   ],
  // },
  {
    name: "Activities",
    RoleName: "Activities",
    path: "/activities",
    icon: <AvTimerIcon fontSize="small" />,
    subitems: [],
  },
  {
    name: "Internal Meetings",
    RoleName: "Activities",
    path: "/meetings",
    icon: <Groups2Icon fontSize="small" />,
    subitems: [],
  },

  {
    name: "Contacts",
    RoleName: "Contacts",
    path: "/contacts/persons",
    icon: <CallIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Customers",
        RoleName: "Persons",
        path: "/contacts/persons",
        icon: <PeopleAltIcon fontSize="small" />,
      },
      {
        name: "Developers",
        RoleName: "Organizations",
        path: "/contacts/organizations",
        icon: <ApartmentIcon fontSize="small" />,
      },
      {
        name: "Affiliate",
        RoleName: "Contacts_Affiliates",
        path: "/contacts/affiliate",
        icon: <HandshakeIcon fontSize="small" />,
      },
    ],
  },
  {
    name: "Product",
    RoleName: "Products",
    path: "/products",
    icon: <InventoryIcon fontSize="small" />,
    subitems: [],
  },

  {
    name: "Service",
    RoleName: "Service",
    path: "/service",
    icon: <HomeRepairServiceIcon fontSize="small" />,
    subitems: [],
  },
  {
    name: "Campaign",
    RoleName: "Campaign",
    path: "/campaigns/campaign",
    icon: <CampaignIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Campaigns",
        RoleName: "Campaign_Campaigns",
        path: "/campaigns/campaign",
        icon: <CampaignIcon fontSize="small" />,
      },
      {
        name: "Templates",
        RoleName: "Campaign_Templates",
        path: "/campaigns/templates",
        icon: <CameraIcon fontSize="small" />,
      },
      {
        name: "Campaign Groups",
        RoleName: "Campaign_Campaign_Groups",
        path: "/campaigns/groups",
        icon: <GroupWorkIcon fontSize="small" />,
      },
    ],
  },
  {
    name: "Socials",
    RoleName: "Chats",
    path: "/chats/facebookpage",
    icon: <MessageIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Facebook Page",
        RoleName: "Chats",
        path: "/chats/facebookpage",
        icon: <FacebookIcon fontSize="small" />,
      },
    ],
  },
  {
    name: "Inventory",
    RoleName: "Inventory",
    path: "/inventory/unit",
    icon: <BallotIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      // {
      //   name: "Project",
      //   RoleName: "Inventory_Project",
      //   path: "/inventory/project",
      //   icon: <AccountTreeIcon fontSize="small" />,
      // },
      // {
      //   name: "Section",
      //   RoleName: "Inventory_Section",
      //   path: "/inventory/section",
      //   icon: <CategoryIcon fontSize="small" />,
      // },
      {
        name: "Units",
        RoleName: "Inventory_Unit",
        path: "/inventory/unit",
        icon: <ApartmentIcon fontSize="small" />,
      },
      {
        name: "Booked Units",
        RoleName: "Inventory_Unit",
        path: "/inventory/booked-unit",
        icon: <BookmarkAddedIcon fontSize="small" />,
      },
    ],
  },
  {
    name: "Target",
    RoleName: "Target",
    path: "/target",
    icon: <TrackChangesIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Targets",
        RoleName: "Target_Targets",
        path: "/target",
        icon: <AdjustIcon fontSize="small" />,
      },
      {
        name: "Assigned Targets",
        RoleName: "Target_Assign Targets",
        path: "/assigned-target",
        icon: <AssignmentIcon fontSize="small" />,
      },
    ],
  },
  {
    name: "Reports",
    RoleName: "Contacts",
    path: "/reports/user-performance",
    icon: <AssessmentIcon fontSize="small" />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "User Performance",
        RoleName: "Persons",
        path: "/reports/user-performance",
        icon: <PeopleAltIcon fontSize="small" />,
      },
      {
        name: "User Leads",
        RoleName: "Organizations",
        path: "/reports/user-leads",
        icon: <LeaderboardIcon fontSize="small" />,
      },
      {
        name: "User Activities",
        RoleName: "Organizations",
        path: "/reports/user-activity",
        icon: <AvTimerIcon fontSize="small" />,
      },
      {
        name: "User Won Leads",
        RoleName: "Organizations",
        path: "/reports/user-won-leads",
        icon: <EmojiEventsIcon fontSize="small" />,
      },
      {
        name: "User Targets",
        RoleName: "Organizations",
        path: "/reports/user-targets",
        icon: <TrackChangesIcon fontSize="small" />,
      },
      {
        name: "Group Performance",
        RoleName: "Organizations",
        path: "/reports/group-performance",
        icon: <Diversity3Icon fontSize="small" />,
      },
      {
        name: "Sales Report",
        RoleName: "Organizations",
        path: "/reports/SaleReport",
        icon: <MonetizationOnIcon fontSize="small" />,
      },
      // {
      //   name: "Profit",
      //   RoleName: "Organizations",
      //   path: "/reports/profit",
      //   icon: <AttachMoneyIcon fontSize="small" />,
      // },
    ],
  },

  {
    name: "Settings",
    RoleName: "Settings",
    path: "/settings",
    icon: <SettingsIcon fontSize="small" />,
    subitems: [],
  },
];

// export const RealStateSidebarData = [
//   {
//     name: "Dashboard",
//     RoleName: "Dashboard",
//     path: "/dashboard",
//     icon: <DashboardIcon fontSize="small" />,
//     iconClosed: <ArrowDropDownIcon />,
//     iconOpened: <ArrowDropUpIcon />,
//     subitems: [],
//   },
//   {
//     name: "Leads",
//     RoleName: "Leads",
//     path: "/leads",
//     icon: <LeaderboardIcon fontSize="small" />,
//     iconClosed: <ArrowDropDownIcon />,
//     iconOpened: <ArrowDropUpIcon />,
//     subitems: [
//       {
//         name: "Leads",
//         RoleName: "Leads",
//         path: "/leads",
//         icon: <LeaderboardIcon fontSize="small" />,
//       },
//       {
//         name: "Public Leads",
//         RoleName: "Leads_Public Leads",
//         path: "/publicleads",
//         icon: <HailIcon fontSize="small" />,
//       },
//     ],
//   },
//   // {
//   //   name: "Leads",
//   //   RoleName: "Leads",
//   //   path: "/leads",
//   //   icon: <LeaderboardIcon fontSize="small" />,
//   //   iconClosed: <ArrowDropDownIcon />,
//   //   iconOpened: <ArrowDropUpIcon />,
//   //   subitems: [],
//   // },
//   // {
//   //   name: "Quotes",
//   //   RoleName: "Quotes",
//   //   path: "/quotes",
//   //   icon: <NotesIcon fontSize="small" />,
//   //   iconClosed: <ArrowDropDownIcon />,
//   //   iconOpened: <ArrowDropUpIcon />,
//   //   subitems: [],
//   // },
//   // {
//   //   name: "Email",
//   //   RoleName: "Mail",
//   //   path: "/email/compose",
//   //   icon: <AttachEmailIcon fontSize="small" />,
//   //   iconClosed: <ArrowDropDownIcon />,
//   //   iconOpened: <ArrowDropUpIcon />,
//   //   subitems: [
//   //     {
//   //       name: "Compose",
//   //       RoleName: "Mail",
//   //       path: "/email/compose",
//   //       icon: <AttachmentIcon fontSize="small" />,
//   //     },
//   //     {
//   //       name: "Inbox",
//   //       RoleName: "Mail_Inbox",
//   //       path: "/email/inbox",
//   //       icon: <ArchiveIcon fontSize="small" />,
//   //     },
//   //     {
//   //       name: "Draft",
//   //       RoleName: "Mail_Draft",
//   //       path: "/email/draft",
//   //       icon: <DraftsIcon fontSize="small" />,
//   //     },
//   //     {
//   //       name: "OutBox",
//   //       RoleName: "Mail_OutBox",
//   //       path: "/email/outbox",
//   //       icon: <OutboxIcon fontSize="small" />,
//   //     },
//   //     {
//   //       name: "Sent",
//   //       RoleName: "Mail_Sent",
//   //       path: "/email/sent",
//   //       icon: <SendIcon fontSize="small" />,
//   //     },
//   //     {
//   //       name: "Trash",
//   //       RoleName: "Mail_Trash",
//   //       path: "/email/trash",
//   //       icon: <RestoreFromTrashIcon fontSize="small" />,
//   //     },
//   //   ],
//   // },
//   {
//     name: "Activities",
//     RoleName: "Activities",
//     path: "/activities",
//     icon: <AvTimerIcon fontSize="small" />,
//     subitems: [],
//   },

//   {
//     name: "Contacts",
//     RoleName: "Contacts",
//     path: "/contacts/persons",
//     icon: <CallIcon fontSize="small" />,
//     iconClosed: <ArrowDropDownIcon />,
//     iconOpened: <ArrowDropUpIcon />,
//     subitems: [
//       {
//         name: "Customers",
//         RoleName: "Persons",
//         path: "/contacts/persons",
//         icon: <PeopleAltIcon fontSize="small" />,
//       },
//       {
//         name: "Developers",
//         RoleName: "Organizations",
//         path: "/contacts/organizations",
//         icon: <ApartmentIcon fontSize="small" />,
//       },
//       {
//         name: "Affiliate",
//         RoleName: "Contacts_Affiliates",
//         path: "/contacts/affiliate",
//         icon: <HandshakeIcon fontSize="small" />,
//       },
//     ],
//   },
//   // {
//   //   name: "Product",
//   //   RoleName: "Products",
//   //   path: "/products",
//   //   icon: <InventoryIcon fontSize="small" />,
//   //   subitems: [],
//   // },

//   // {
//   //   name: "Service",
//   //   RoleName: "Service",
//   //   path: "/service",
//   //   icon: <HomeRepairServiceIcon fontSize="small" />,
//   //   subitems: [],
//   // },
//   {
//     name: "Campaign",
//     RoleName: "Campaign",
//     path: "/campaigns/campaign",
//     icon: <CampaignIcon fontSize="small" />,
//     iconClosed: <ArrowDropDownIcon />,
//     iconOpened: <ArrowDropUpIcon />,
//     subitems: [
//       {
//         name: "Campaigns",
//         RoleName: "Campaign_Campaigns",
//         path: "/campaigns/campaign",
//         icon: <CampaignIcon fontSize="small" />,
//       },
//       {
//         name: "Templates",
//         RoleName: "Campaign_Templates",
//         path: "/campaigns/templates",
//         icon: <CameraIcon fontSize="small" />,
//       },
//       {
//         name: "Campaign Groups",
//         RoleName: "Campaign_Campaign_Groups",
//         path: "/campaigns/groups",
//         icon: <GroupWorkIcon fontSize="small" />,
//       },
//     ],
//   },
//   {
//     name: "Socials",
//     RoleName: "Chats",
//     path: "/chats/facebookpage",
//     icon: <MessageIcon fontSize="small" />,
//     iconClosed: <ArrowDropDownIcon />,
//     iconOpened: <ArrowDropUpIcon />,
//     subitems: [
//       {
//         name: "Facebook Page",
//         RoleName: "Chats",
//         path: "/chats/facebookpage",
//         icon: <FacebookIcon fontSize="small" />,
//       },
//     ],
//   },
//   {
//     name: "Inventory",
//     RoleName: "Inventory",
//     path: "/inventory/unit",
//     icon: <BallotIcon fontSize="small" />,
//     iconClosed: <ArrowDropDownIcon />,
//     iconOpened: <ArrowDropUpIcon />,
//     subitems: [
//       // {
//       //   name: "Project",
//       //   RoleName: "Inventory_Project",
//       //   path: "/inventory/project",
//       //   icon: <AccountTreeIcon fontSize="small" />,
//       // },
//       // {
//       //   name: "Section",
//       //   RoleName: "Inventory_Section",
//       //   path: "/inventory/section",
//       //   icon: <CategoryIcon fontSize="small" />,
//       // },
//       {
//         name: "Units",
//         RoleName: "Inventory_Unit",
//         path: "/inventory/unit",
//         icon: <ApartmentIcon fontSize="small" />,
//       },
//       {
//         name: "Booked Units",
//         RoleName: "Inventory_Booked Unit",
//         path: "/inventory/booked-unit",
//         icon: <BookmarkAddedIcon fontSize="small" />,
//       },
//     ],
//   },
//   {
//     name: "Target",
//     RoleName: "Target",
//     path: "/target",
//     icon: <TrackChangesIcon fontSize="small" />,
//     iconClosed: <ArrowDropDownIcon />,
//     iconOpened: <ArrowDropUpIcon />,
//     subitems: [
//       {
//         name: "Targets",
//         RoleName: "Inventory_Unit",
//         path: "/target",
//         icon: <AdjustIcon fontSize="small" />,
//       },
//       {
//         name: "Assigned Targets",
//         RoleName: "Inventory_Unit",
//         path: "/target/assigned-target",
//         icon: <AssignmentIcon fontSize="small" />,
//       },
//     ],
//   },
//   {
//     name: "Settings",
//     RoleName: "Settings",
//     path: "/settings",
//     icon: <SettingsIcon fontSize="small" />,
//     subitems: [],
//   },
// ];
