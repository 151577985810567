import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import GroupIcon from "@mui/icons-material/Group";

import NoStrollerIcon from "@mui/icons-material/NoStroller";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const useStyle = makeStyles(() => {
  return {
    paperBox: {
      width: "100%",
      maxWidth: "1280px",
      padding: "20px",
      marginTop: "20px",
    },
    paperContentBox: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: "10px",
    },
    contentBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    textBox: {
      height: "90px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    setingText: {
      transform: "scale(0)",
      height: "0px",
    },
  };
});

function User({ roles }) {
  const { paperBox, paperContentBox, contentBox, textBox, setingText } =
    useStyle();
  const navigate = useNavigate();
  return (
    <Box>
      <Typography sx={{ fontWeight: "bold" }}>User</Typography>
      <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
        Manage all your users and their permissions in the CRM, what they’re
        allowed to do.
      </Typography>
      <Paper
        className={paperBox}
        elevation={3}
        sx={{}}
      >
        <Box
          className={paperContentBox}
          sx={{}}
        >
          {roles?.some((role) => role === "Groups") && (
            <Box
              onClick={() => {
                navigate("/settings/groups");
              }}
              className="settingBoxA"
              sx={{ padding: "10px", cursor: "pointer" }}
              id="settings-groups"
            >
              <Box
                className={contentBox}
                sx={{}}
              >
                <GroupIcon
                  fontSize="large"
                  sx={{ color: theme.palette.primary.main }}
                />
                <Box
                  className={textBox}
                  sx={{}}
                >
                  <Typography variant="h6">Groups</Typography>
                  <Typography
                    variant="body2"
                    className={`${setingText} settingBoxAhoverEffect`}
                    sx={{}}
                  >
                    Add,edit or delete Groups from{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${setingText} settingBoxAhoverEffect`}
                    sx={{}}
                  >
                    CRM
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {roles?.some((role) => role === "Roles") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/roles");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-roles"
              >
                <Box
                  className={contentBox}
                  sx={{}}
                >
                  <NoStrollerIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box
                    className={textBox}
                    sx={{}}
                  >
                    <Typography variant="h6">Roles</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add,edit or delete Roles from{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      CRM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {roles?.some((role) => role === "Users") && (
            <Box>
              <Box
                onClick={() => {
                  navigate("/settings/users");
                }}
                className="settingBoxA"
                sx={{ padding: "10px", cursor: "pointer" }}
                id="settings-users"
              >
                <Box
                  className={contentBox}
                  sx={{}}
                >
                  <AccountBoxIcon
                    fontSize="large"
                    sx={{ color: theme.palette.primary.main }}
                  />
                  <Box
                    className={textBox}
                    sx={{}}
                  >
                    <Typography variant="h6">Users</Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      Add,edit or delete Users from{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${setingText} settingBoxAhoverEffect`}
                      sx={{}}
                    >
                      CRM
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default User;
User.propTypes = {
  roles: PropTypes.array,
};
