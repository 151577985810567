import React, {  useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Joyride from "react-joyride";
import { AppContext } from "../../Context/TourContext/TourContext";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
import MeetingsTable from "../../Components/Meetings/MeetingsTable/MeetingsTable";

const Meetings = ({ roles }) => {
  let guide = sessionStorage.getItem("guide");
  const { setState, state } = useContext(AppContext);
  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Meetings");
    if (!isSkip) {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, []);
  return (
    <>
      <Box>
        {!guide ? (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Meetings"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <MeetingsTable roles={roles} />
      </Box>
    </>
  );
};
export default Meetings;
const steps = [
  {
    target: "#meetings",
    content: (
      <>
        <p size="large">Welcome to the Meetings Page</p>
        <p>
          Your centralized hub for tracking and managing interactions with
          Meetings. Effortlessly navigate through yourMeetings, gain insights
          into Meetings interactions, and stay organized.
          <br />
          Use the filters and sorting options to tailor your view according to
          your preferences.
          <br />
          <br />
          Pro tip: Enhance Meetings relationships by documenting each
          interaction. Ready to log a new activity? Click &apos;New&apos; to get
          started.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
Meetings.propTypes = {
  roles: PropTypes.array,
};
