import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import theme from "../../../theme";
import {
  fetchLeadByPiplineStage,
  fetchLeadsSingle,
} from "../../../Redux/Slice/LeadsSlice/LeadsSlice";
import { LostPiplineStageApi } from "../../../Api/LeadsApi/LostPiplineStageApi";
import { useDispatch } from "react-redux";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.45) !important",

      // transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white !important",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
function LostModal({
  showModal,
  setshowModal,
  lostPiplines,
  singleLead,
  defaultpipline,
}) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const initialValues = {
    lostReason: "",
    closedDate: "",
  };
  const validationSchema = Yup.object().shape({
    lostReason: Yup.string().required("Please Enter Amount"),
    closedDate: Yup.string().required("Please Enter Amount"),
  });

  const handleSave = async (values, setSubmitting, resetForm) => {
    const obj = { ...values, pipelineStageId: lostPiplines?.Id };
    const response = await LostPiplineStageApi({
      obj: obj,
      id: singleLead?.Id,
    });
    setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      resetForm();
      setSubmitting(false);
      dispatch(fetchLeadsSingle(Number(singleLead?.Id)));
      dispatch(fetchLeadByPiplineStage(defaultpipline?.Id));
      setshowModal(false);
    }
    setSubmitting(false);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Change Stage
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setshowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <FormControl fullWidth>
                      <Typography>Lost Reason</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="lostReason"
                        type="text"
                        multiline
                        rows={5}
                        onBlur={formik.handleBlur}
                        value={formik.values.lostReason}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.lostReason) &&
                          Boolean(formik.errors.lostReason)
                        }
                        helperText={
                          Boolean(formik.touched.lostReason) &&
                          formik.errors.lostReason
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography>Close Date</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="closedDate"
                        type="date"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.closedDate}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.closedDate) &&
                          Boolean(formik.errors.closedDate)
                        }
                        helperText={
                          Boolean(formik.touched.closedDate) &&
                          formik.errors.closedDate
                        }
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button type="submit" variant="contained">
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default LostModal;
LostModal.propTypes = {
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
  lostPiplines: PropTypes.any,
  singleLead: PropTypes.any,
  defaultpipline: PropTypes.any,
};
