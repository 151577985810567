import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const FacebookPageDetailContext = createContext();
const FacebookPageDetailContextProvider = (props) => {
  const [pageData, setpageData] = useState({
name:"",
id:"",
imageUrl:""
  });

  return (
    <FacebookPageDetailContext.Provider value={{ pageData, setpageData }}>
      {props.children}
    </FacebookPageDetailContext.Provider>
  );
};

export default FacebookPageDetailContextProvider;

FacebookPageDetailContextProvider.propTypes = {
  children: PropTypes.any,
};
