import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Papa from "papaparse";
import PublishIcon from "@mui/icons-material/Publish";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import validator from "validator";
import GetAppIcon from "@mui/icons-material/GetApp";
import { CreateCampaignGroupsApi } from "../../../Api/CampaignGroupsApi/CreateCampaignGroupsApi";
import { fetchCampaignGroups } from "../../../Redux/Slice/CampaignGroupsSlice/CampaignGroupsSlice";
import { ReactSelectTheme } from "../../../Utils/ReactSelectTheme";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textAreaContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    AddressContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    LoadingContainer: {
      height: "70vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});
const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please Enter Name"),
  type: Yup.object().required("Please Select Type"),
});

const CreateCampaignGroups = () => {
  const { container, subContainer, formContainer } = useStyle();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [csvData, setCsvData] = useState([]);
  console.log("csvData", csvData);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const typeOptions = [
    { value: "Email", label: "Email" },
    { value: "SMS", label: "SMS" },
  ];

  const initialValues = {
    name: "",
    type: "",
  };
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.ID) {
      console.log("state", state);
      formik?.setFieldValue("name", state?.name);
      formik?.setFieldValue("type", {
        value: state?.type,
        label: state?.type,
      });
      setCsvData(state?.file);
    }
  }, [state]);

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const obj = {
      type: values.type.label,
      name: values.name,
      fileData: JSON.stringify(csvData),
    };

    if (state) {
      const response = await CreateCampaignGroupsApi({
        ...obj,
        campaignGroupId: state.ID,
      });
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        setSubmitting(false);
        dispatch(fetchCampaignGroups());
        resetForm();
        navigate(-1);
      } else {
        setSubmitting(false);
      }
    } else {
      const response = await CreateCampaignGroupsApi(obj);
      setsnackBarData(response.snackBarData);
      if (response?.data?.IsSuccess) {
        setSubmitting(false);
        dispatch(fetchCampaignGroups());
        resetForm();
        navigate(-1);
      } else {
        setSubmitting(false);
      }
    }
    setSubmitting(false);
    resetForm();
  };
  const handleNavigate = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (csvData.length > 0) {
        handleSubmit(values, resetForm, setSubmitting);
      } else {
        setsnackBarData({
          type: "error",
          message: "Please Import CSV",
          openToast: true,
        });
        setSubmitting(false);
      }
    },
  });

  const handleExport = () => {
    if (formik.values.type?.label) {
      const csvContent = Papa.unparse({
        fields:
          formik.values.type?.label === "Email" ? ["Email"] : ["Code", "Phone"],
        data: [], // Add your data here
      });

      // Create a Blob and download the CSV file
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `exported_${formik.values.type?.label}.csv`;
      link.click();
    } else {
      setsnackBarData({
        type: "error",
        message: "Please Select Type",
        openToast: true,
      });
    }
  };

  const handleImport = (e) => {
    const file = e.target.files[0];
    if (!file || !file.name.endsWith(".csv")) {
      setsnackBarData({
        type: "error",
        message: "Please select a valid CSV file.",
        openToast: true,
      });

      return;
    }
    // Parse the CSV file
    Papa.parse(file, {
      complete: (result) => {
        if (
          result.meta.fields.includes("Email") &&
          formik.values.type?.label === "Email"
        ) {
          // Email type - extract emails and remove header
          const emailsArray = result.data
            .filter((row) => row.Email && row.Email.trim() !== "")
            .map((row) => row.Email.trim());
          console.log(emailsArray);
          const newEmailsArray = emailsArray
            .filter((email) => validator.isEmail(email))
            .map((row) => row);
          // Set the emails array in the state or use it as needed
          console.log(newEmailsArray);
          newEmailsArray.push(...csvData);
          const uniqueArray = [...new Set(newEmailsArray)];
          setCsvData(uniqueArray);
          if (emailsArray.length !== newEmailsArray.length) {
            setsnackBarData({
              type: "error",
              message: "Your file contains Invalid Emails",
              openToast: true,
            });
          }
        } else if (
          result.meta.fields.includes("Code") &&
          result.meta.fields.includes("Phone") &&
          formik.values.type?.label === "SMS"
        ) {
          // Code and Phone type - handle differently
          const codeAndPhoneArray = result.data
            .filter((row) => row.Code || row.Phone)
            .map((row) => ({
              code: row.Code,
              phone: row.Phone,
            }));
          console.log("codeAndPhoneArray", codeAndPhoneArray);
          const newCodeAndPhoneArray = codeAndPhoneArray
            .filter((row) => row.code.length === 2 && row.phone.length === 10)
            .map((row) => row.code + row.phone);
          // Set the codeAndPhoneArray in the state or use it as needed
          console.log(newCodeAndPhoneArray);
          newCodeAndPhoneArray.push(...csvData);
          const uniqueArray = [...new Set(newCodeAndPhoneArray)];
          setCsvData(uniqueArray);
          if (newCodeAndPhoneArray.length !== codeAndPhoneArray.length) {
            setsnackBarData({
              type: "error",
              message: "Your file contains Invalid Phone Numbers",
              openToast: true,
            });
          }
        } else if (!formik.values.type.label) {
          // Handle unsupported format or show an error message
          setsnackBarData({
            type: "error",
            message: "Please Select Type",
            openToast: true,
          });
        } else if (formik.values.type.label) {
          // Handle unsupported format or show an error message
          setsnackBarData({
            type: "error",
            message: `Please Select File containing ${
              formik.values.type.label === "Email" ? "Emails" : "Phone Numbers"
            }`,
            openToast: true,
          });
        } else {
          // Handle unsupported format or show an error message
          console.error("Unsupported CSV format");
          setsnackBarData({
            type: "error",
            message: "Unsupported CSV format",
            openToast: true,
          });
        }
      },
      header: true, // Use first row as headers
    });
  };

  return (
    <>
      <Box className={container}>
        <Box className={subContainer}>
          <Box>
            <Box role="presentation" onClick={(e) => e.preventDefault()}>
              <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
                <Link
                  to="/dashboard"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Dashboard
                </Link>
                <Link
                  to="/campaigns/groups"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Campaign Groups
                </Link>
                <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                  {state ? "Update Campaign Groups" : "Create Campaign Groups"}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography
              variant="h5"
              sx={{ textAlign: "left", fontWeight: 400 }}
            >
              {state ? "Update Campaign Groups" : "Create Campaign Groups"}
            </Typography>
          </Box>
          <Paper elevation={3} sx={{ padding: "20px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box className={formContainer}>
                <FormControl fullWidth>
                  <Typography>Name</Typography>
                  <TextField
                    name="name"
                    value={formik.values?.name}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name)
                    }
                    helperText={
                      Boolean(formik.errors.name) &&
                      Boolean(formik.touched.name) &&
                      formik.errors.name
                    }
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.type) && Boolean(formik.touched.type)
                  }
                >
                  <Typography>Type</Typography>

                  <Select
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.type) &&
                        Boolean(formik.touched.type)
                    )}
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.type) &&
                    //       Boolean(formik.touched.type)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    name="type"
                    options={typeOptions}
                    value={formik.values.type}
                    onChange={(value) => {
                      formik.setFieldValue("type", value);
                      setCsvData([]);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select Type"
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.type) && Boolean(formik.touched.type)
                      ? formik.errors.type
                      : ""}
                  </FormHelperText>
                </FormControl>
                <Box>
                  <Button
                    onClick={handleExport}
                    variant="contained"
                    startIcon={<GetAppIcon />}
                    sx={{ mr: 1 }}
                  >
                    Export CSV
                  </Button>
                  <label htmlFor="file-input">
                    <Button
                      component="span"
                      variant="contained"
                      color="info"
                      startIcon={<PublishIcon />}
                    >
                      Import CSV
                    </Button>
                  </label>
                </Box>
                <input
                  id="file-input"
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }} // hide the input
                  ref={fileInputRef}
                  onChange={(e) => {
                    handleImport(e);
                    e.target.value = null;
                  }}
                />

                {/* Render your CSV data as needed */}
                <Box>
                  <Typography>File Content</Typography>
                  {csvData.map((data, index) => (
                    <Chip
                      sx={{ mr: 1 }}
                      key={index}
                      label={data}
                      variant="outlined"
                    />
                  ))}
                </Box>
                {/* <pre>{JSON.stringify(csvData)}</pre> */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <Button onClick={handleNavigate}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Back
                        </Typography>
                      </Button>
                    </Box>
                    <Box>
                      {formik.isSubmitting ? (
                        <Box>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button variant="contained" type="submit">
                          <Typography sx={{ fontWeight: "bold" }}>
                            {state ? "Update" : "Save"}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default CreateCampaignGroups;
