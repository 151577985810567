import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {  useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { CreateLeadFileApi } from "../../../../Api/LeadsApi/CreateLeadFileApi";
import { useDispatch } from "react-redux";
import { fetchLeadsSingle } from "../../../../Redux/Slice/LeadsSlice/LeadsSlice";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      alignItems: "flex-start",
    },
  };
});
function File({ state }) {
  const { formContainer } = useStyle();
  // const [leadFileimage,setleadFileimage]=useState(null);
  console.log(state);
  const initialValues = {
    Name: "",
    File: null,
    Description: "",
  };
  const dispatch=useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const handleSubmit = async (values, setSubmitting, resetForm) => {
console.log(values);

    // const obj = { ...values, leadId: state,File: leadFileimage?.file?leadFileimage?.file:''};
    // Object.keys(values).map((key) => {
      //   formdata.append(key, values[key]);
      // });
      // formdata.append("abc",  leadFileimage?.file);
      const formdata = new FormData();
    console.log(formik.values?.File);
    formdata.append("leadId", state?.id );
    formdata.append("Name", values.Name);
    formdata.append("File",formik.values?.File );
    formdata.append("Description", values.Description );
    const response = await CreateLeadFileApi(formdata);
    console.log(setsnackBarData);
    if (response?.data?.IsSuccess) {
      setSubmitting(false);
      resetForm();
      dispatch(state?.id?fetchLeadsSingle(Number(state?.id)):fetchLeadsSingle(Number(state)));

    }
    setSubmitting(false);
  };
  const ValidationSchema = Yup.object().shape({
    Name: Yup.string().required("Please Enter  Title"),
    // File: Yup.object(),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  console.log(formik.values?.File);
  return (
    <Paper elevation={3}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <FormControl fullWidth>
            <Typography>Title</Typography>
            <TextField
              name="Name"
              value={formik.values?.Name}
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.Name) && Boolean(formik.touched.Name)
              }
              helperText={Boolean(formik.errors.Name) && formik.errors.Name}
            />
          </FormControl>

          <FormControl fullWidth>
            <Typography>Decsrption</Typography>
            <TextField
              name="Description"
              value={formik.values?.Description}
              multiline
              rows={4}
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography>File</Typography>
            <TextField
              name="File"
              // value={formik.values?.File}
              size={"small"}
              type={"file"}
              fullWidth
              onChange={
              
                (e)=>{
                  console.log(e.target.files[0]);
                  //  formik.handleChange({
                  //   target :{
                  //     value:e.target.files[0],
                  //     name :"File"
                  //   }
                  //  });
                  formik.setFieldValue("File", e.target.files[0]);
                }

              }
              // error={
              //   Boolean(formik.errors.File) && Boolean(formik.touched.File)
              // }
              // helperText={Boolean(formik.errors.File) && formik.errors.File}
            />
          </FormControl>
          {
            formik?.isSubmitting?<CircularProgress/>:
         
          <Button
            variant="outlined"
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: "white !important",
            }}
            type="submit"
          >
            Save
          </Button>
           }
        </Box>
      </form>
    </Paper>
  );
}

export default File;
File.propTypes = {
  state: PropTypes.any,
};
