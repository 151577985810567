import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyle = makeStyles((theme) => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textAreaContainer: {
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    textContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  };
});
function ProductsInfoModal({ showModal, handleClosed, product }) {
  const { formContainer, textAreaContainer, textContainer } = useStyle();

  return (
    <Dialog
      open={showModal}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography mt={1} variant="h6" fontWeight="bolder">
            Product Info
          </Typography>
          <Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClosed();
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ width: "600px" }}>
        <Box className={formContainer}>
          <Box mx="auto">
            <img
              id="image-preview"
              alt="Not Selected"
              width="100px"
              height="100px"
              src={product.Image}
              style={{
                display: product.Image ? "" : "none",
                borderRadius: "100%",
              }}
            />
          </Box>
          <Box className={textContainer}>
            <Box className={formContainer}>
              <Box className={textAreaContainer}>
                <Typography variant="body1" fontWeight="bold">
                  Name:
                </Typography>
                <Typography>{product?.name}</Typography>
              </Box>

              <Box
                display={product?.ProductCode ? "" : "none"}
                className={textAreaContainer}
              >
                <Typography variant="body1" fontWeight="bold">
                  Product Code:
                </Typography>
                <Typography>{product?.ProductCode}</Typography>
              </Box>

              <Box className={textAreaContainer}>
                <Typography variant="body1" fontWeight="bold">
                  Quantity:
                </Typography>
                <Typography>{product?.quantity}</Typography>
              </Box>
              <Box className={textAreaContainer}>
                <Typography variant="body1" fontWeight="bold">
                  Price:
                </Typography>
                <Typography>{product?.price}</Typography>
              </Box>

              <Box
                display={product?.CommissionPercentage ? "" : "none"}
                className={textAreaContainer}
              >
                <Typography variant="body1" fontWeight="bold">
                  Commission:
                </Typography>
                <Typography>{product?.CommissionPercentage}%</Typography>
              </Box>
            </Box>
            <Box className={formContainer}>
              <Box
                display={product?.ProductCategory ? "" : "none"}
                className={textAreaContainer}
              >
                <Typography variant="body1" fontWeight="bold">
                  Category:
                </Typography>
                <Typography>{product?.ProductCategory}</Typography>
              </Box>
              <Box className={textAreaContainer}>
                <Typography variant="body1" fontWeight="bold">
                  SKU:
                </Typography>
                <Typography>{product?.sku}</Typography>
              </Box>
              <Box
                display={product?.Uom ? "" : "none"}
                className={textAreaContainer}
              >
                <Typography variant="body1" fontWeight="bold">
                  Uom:
                </Typography>
                <Typography>{product?.Uom}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={formContainer}>
          <Box marginTop={"10px"} className={textAreaContainer}>
            <Typography variant="body1" fontWeight="bold">
              Description:
            </Typography>
            <Typography>{product?.Description}</Typography>
          </Box>
          <FormGroup style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Checkbox name="active" disabled checked={product.Active} />
              }
              label="Active"
            />
            <FormControlLabel
              control={<Checkbox name="tax" disabled checked={product.Tax} />}
              label="Tax"
            />
          </FormGroup>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ProductsInfoModal;

ProductsInfoModal.propTypes = {
  handleClosed: PropTypes.any,
  showModal: PropTypes.any,
  product: PropTypes.any,
};
