import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPublicLeads,
  getFacebookPageDetails,
} from "../../Redux/Slice/Settings/FacebookPageSlice/FacebookPageSlice";
import Loader from "../../Components/Loader/Loader";
import { Badge, Button, Typography, Chip, Tooltip } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PublicLeadsFilterDrawer from "./PublicLeadsFilterDrawer";
// import Cookies from "universal-cookie";
import { RemoveSCFromInputs } from "../../Utils/RemoveSCFromInputs";
import { useNavigate } from "react-router-dom";
import { PageNumberContext } from "../../Context/PageNumberContext/PageNumberContext";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

// import { IconButton, Tooltip } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function PublicLeads({ roles }) {
  const { totalRows } = useContext(PageNumberContext);

  const { header } = useStyles();
  const [pagesize, setpagesize] = useState(totalRows);
  const [rows, setrows] = useState([]);
  const [allPublicLeads, setallPublicLeads] = useState([]);
  const { AllPublicLeads, status } = useSelector(getFacebookPageDetails);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [count, setcount] = useState(0);
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filterVal, setfilterVal] = useState({
    person: "",
    phone: "",
    email: "",
    leadSource: "",
    project: "",
    category: "",
    tag: "",
  });
  const handleChangeVal = (e) => {
    let value = RemoveSCFromInputs(e.target.value);

    setfilterVal({
      ...filterVal,
      [e.target.name]: value || "",
    });
  };
  // const cookies = new Cookies();
  // let businessType = cookies.get("BusinessType");
  let businessType = sessionStorage.getItem("BusinessType");
  useEffect(() => {
    dispatch(fetchAllPublicLeads(businessType));
  }, [dispatch]);
  useEffect(() => {
    let sum = 0;
    for (const value in filterVal) {
      if (filterVal[value] !== "") {
        sum = sum + 1;
      }
    }
    setcount(sum);
  }, [filterVal]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        {roles?.some((role) => role === "export_Create") && (
          <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        <Box
        // sx={{ display: "flex", alignItems: "center", justifyContent: "end", paddingBottom: "10px", }}
        >
          <Button onClick={() => setdrawerOpen(true)}>
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Badge badgeContent={count} color="primary">
                <FilterAltIcon fontSize="small" />
              </Badge>
              Multi Filters
            </Box>
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    let arr = [];
    if (AllPublicLeads) {
      AllPublicLeads?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          Name: item?.Customer?.Name,
          Tag: item?.Tag,
          Email: item?.Customer?.PersonEmails[0]?.Email,
          PhoneNumber:
            (item?.Customer?.PersonContacts[0]?.DialCode
              ? item?.Customer?.PersonContacts[0]?.DialCode
              : "") + item?.Customer?.PersonContacts[0]?.ContactNumber,
        });
      });
    }
    setrows(arr);
    setallPublicLeads(arr);
    // setallPersons(arr);
  }, [AllPublicLeads]);

  useEffect(() => {
    if (
      filterVal?.person === "" &&
      filterVal?.email === "" &&
      filterVal?.leadSource === "" &&
      filterVal?.phone === "" &&
      filterVal?.project === "" &&
      filterVal?.category === "" &&
      filterVal?.tag === ""
    ) {
      setallPublicLeads(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(filterVal?.tag, "i");
      let regex3 = new RegExp(filterVal?.person, "i");
      let regex4 = new RegExp(filterVal?.email, "i");
      let regex5 = new RegExp(filterVal?.leadSource, "i");
      let regex6 = new RegExp(filterVal?.phone, "i");
      let regex7 = new RegExp(filterVal?.project, "i");
      let regex8 = new RegExp(filterVal?.category, "i");

      suggestions = rows?.filter(
        (val) =>
          (filterVal?.person === "" || regex3.test(val?.Name)) &&
          (filterVal?.email === "" || regex4.test(val?.Email)) &&
          (filterVal?.leadSource === "" ||
            regex5.test(val?.LeadSource?.Name)) &&
          (filterVal?.phone === "" || regex6.test(val?.PhoneNumber)) &&
          (filterVal?.project === "" ||
            regex7.test(val?.Project?.ProjectName)) &&
          (filterVal?.category === "" ||
            regex8.test(val?.Category?.CategoryName)) &&
          (filterVal?.tag === "" || regex1.test(val?.Tag?.Name))
      );
      setallPublicLeads(suggestions);
    }
  }, [filterVal, rows]);
  //   const handleEdit = (val) => {
  //     navigate("/create/fbpagetoken", { state: val });
  //   };
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: header },
    {
      field: "Name",
      headerName: "Customer Name",
      width: 200,
      headerClassName: header,
      //   renderCell: (celval, i) => {
      //     return (
      //       <>
      //         <Box sx={{ display: "flex", gap: "5px" }} key={i}>
      //           <Typography>{celval?.row?.Organization?.Name}</Typography>
      //         </Box>
      //       </>
      //     );
      //   },
    },
    {
      field: "Email",
      headerName: "Email",
      width: 200,
      headerClassName: header,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      width: 200,
      headerClassName: header,
      renderCell: (celval) => {
        return <div>+{celval?.row?.PhoneNumber}</div>;
      },
    },
    {
      field: "SourceName",
      headerName: "Source Name",
      width: 200,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <>
            <Box sx={{ display: "flex", gap: "5px" }} key={i}>
              <Typography>{celval?.row?.LeadSource?.Name}</Typography>
            </Box>
          </>
        );
      },
    },
    {
      field: "CatalogType",
      headerName: "Catalog Type",
      width: 200,
      headerClassName: header,
    },
    {
      field: "Catalog",
      headerName: "Catalog Name",
      width: 200,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <>
            <Box sx={{ display: "flex", gap: "5px" }} key={i}>
              <Typography>{celval?.row?.Catalog?.Name}</Typography>
            </Box>
          </>
        );
      },
    },
    {
      field: "Tag",
      headerName: "Tag",
      width: 150,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          celval?.formattedValue && (
            <Chip
              sx={{
                backgroundColor: celval.formattedValue?.Color,
                color: "#fff",
              }}
              label={celval?.formattedValue?.Name}
            />
          )
        );
      },
    },
  ];
  const columns2 = [
    { field: "id", headerName: "ID", width: 90, headerClassName: header },
    {
      field: "Name",
      headerName: "Customer Name",
      width: 200,
      headerClassName: header,
      //   renderCell: (celval, i) => {
      //     return (
      //       <>
      //         <Box sx={{ display: "flex", gap: "5px" }} key={i}>
      //           <Typography>{celval?.row?.Organization?.Name}</Typography>
      //         </Box>
      //       </>
      //     );
      //   },
    },
    {
      field: "Email",
      headerName: "Email",
      width: 200,
      headerClassName: header,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      width: 200,
      headerClassName: header,
      renderCell: (celval) => {
        return <div>+{celval?.row?.PhoneNumber}</div>;
      },
    },
    {
      field: "SourceName",
      headerName: "Source Name",
      width: 200,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <>
            <Box sx={{ display: "flex", gap: "5px" }} key={i}>
              <Typography>{celval?.row?.LeadSource?.Name}</Typography>
            </Box>
          </>
        );
      },
    },
    {
      field: "Project",
      headerName: "Project",
      width: 200,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <>
            <Box sx={{ display: "flex", gap: "5px" }} key={i}>
              <Typography>{celval?.row?.Project?.ProjectName}</Typography>
            </Box>
          </>
        );
      },
    },
    {
      field: "Category",
      headerName: "Category",
      width: 200,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <>
            <Box sx={{ display: "flex", gap: "5px" }} key={i}>
              <Typography>{celval?.row?.Category?.CategoryName}</Typography>
            </Box>
          </>
        );
      },
    },
    {
      field: "Section",
      headerName: "Section",
      width: 200,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <>
            <Box sx={{ display: "flex", gap: "5px" }} key={i}>
              <Typography>{celval?.row?.Section?.SectionName}</Typography>
            </Box>
          </>
        );
      },
    },
    {
      field: "Unit",
      headerName: "Unit",
      width: 200,
      headerClassName: header,
      renderCell: (celval, i) => {
        return (
          <>
            <Box sx={{ display: "flex", gap: "5px" }} key={i}>
              <Typography>{celval?.row?.Unit?.UnitName}</Typography>
            </Box>
          </>
        );
      },
    },
    {
      field: "Tag",
      headerName: "Tag",
      width: 150,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          celval?.formattedValue && (
            <Chip
              sx={{
                backgroundColor: celval.formattedValue?.Color,
                color: "#fff",
              }}
              label={celval?.formattedValue?.Name}
            />
          )
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <Box>
            {roles?.some((role) => role === "Leads_Create") && (
              <Tooltip title="View">
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/leads/create", {
                      state: { publicLead: celval.row },
                    });
                    console.log("celval.row", celval.row);
                  }}
                >
                  Add Lead
                </Button>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {status === "loading" ? <Loader /> : null}
      <Box>
        <PublicLeadsFilterDrawer
          setdrawerOpen={setdrawerOpen}
          drawerOpen={drawerOpen}
          rows={rows}
          setallPublicLeads={setallPublicLeads}
          handleChangeVal={handleChangeVal}
          setcount={setcount}
          setfilterVal={setfilterVal}
          filterVal={filterVal}
        />
      </Box>
      <Box>
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            width: "100%",
            overflowX: "visible",
            backgroundColor: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <DataGrid
            pageSize={pagesize}
            onPageSizeChange={(newPage) => {
              setpagesize(newPage);
            }}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => CustomNoRowsOverlay(),
            }}
            rows={allPublicLeads}
            columns={businessType === "RealState" ? columns2 : columns}
          />
        </Box>
      </Box>
    </>
  );
}

export default PublicLeads;
PublicLeads.propTypes = {
  roles: PropTypes.array,
};
