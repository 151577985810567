import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";
import GetQueryParams from "../../../Utils/GetQueryParams";

const initialState = {
  Persons: [],
  PersonsPopulate: [],
  SinglePersons: [],
  organizationData: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchPersons = createAsyncThunk("/Person", async (payload) => {
  const queryParams = GetQueryParams(payload?.queryParams);
  const response = await axiosInstance.get(
    `/Person?Type=${payload.type}&${queryParams}`
  );
  return response;
});
export const fetchPersonsPopulate = createAsyncThunk(
  "/Person/populate",
  async () => {
    const response = await axiosInstance.get(`/Person/populate`);
    return response;
  }
);
export const fetchSinglePersons = createAsyncThunk(
  "/Person/{id}",
  async (id) => {
    const response = await axiosInstance.get(`/Person/${id}`);
    return response;
  }
);

const PersonSlice = createSlice({
  name: "Persons",
  initialState,
  reducers: {
    PersonsAdded: {
      reducer(state, action) {
        state.Persons?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchPersons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPersons.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.Succeeded;
        state.Persons = action.payload.data;
        // state.organizationData = action.payload.data.Data.organizationData;
      })
      .addCase(fetchPersons.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchSinglePersons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSinglePersons.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SinglePersons = action.payload.data.Data;
      })
      .addCase(fetchSinglePersons.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchPersonsPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPersonsPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.PersonsPopulate = action.payload.data;
      })
      .addCase(fetchPersonsPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const getPersonsDetails = (state) => state.Persons;

export const { PersonsAdded } = PersonSlice.actions;

export default PersonSlice.reducer;
