import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import {
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    contentBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    fieldBox: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});

function RolesFilterDrawer({
  drawerOpen,
  setdrawerOpen,
  filterVal,
  setfilterVal,
  onTextChange,
  permissionRef,
  descriptionRef,
  setallRoles,
  setcount,
  rows,
  handleChangeVal,
  name,
}) {
  const { contentBox, fieldBox } = useStyles();
  const handleClose = () => {
    setdrawerOpen(!open);
  };
  const handleRemoveAll = () => {
    for (const value in filterVal) {
      filterVal[value] = "";
    }
    setcount(0);
  };
  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={handleClose}
        className="abc"
      >
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Filter</Typography>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography
                sx={{ color: "rgb(14, 144, 217)", cursor: "pointer" }}
                onClick={() => {
                  setallRoles(rows);
                  handleRemoveAll();
                }}
              >
                Remove All
              </Typography>
              <CloseIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }}
          />
          <Box className={contentBox}>
            <Box>
              <Typography>Name</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="name"
                  variant="outlined"
                  size="small"
                  value={filterVal?.name}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Name"
                  className="input_border"
                  inputRef={name}
                  onChange={(e) => {
                    onTextChange();
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    name.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      name: "",
                    });
                    onTextChange();
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Description</Typography>
              <Box className={fieldBox}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  name="description"
                  variant="outlined"
                  size="small"
                  value={filterVal?.description}
                  type="text"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Description"
                  className="input_border"
                  inputRef={descriptionRef}
                  onChange={(e) => {
                    onTextChange();
                    handleChangeVal(e);
                  }}
                />
                <CloseIcon
                  onClick={() => {
                    descriptionRef.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      description: "",
                    });
                    onTextChange();
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Permission Type</Typography>
              <Box className={fieldBox}>
                <Select
                  name="permission"
                  variant="outlined"
                  size="small"
                  defaultValue={""}
                  value={filterVal?.permission}
                  // select
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  placeholder="Name"
                  className="input_border"
                  inputRef={permissionRef}
                  onChange={(e) => {
                    onTextChange();
                    handleChangeVal(e);
                  }}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="Custom">Custom</MenuItem>
                  <MenuItem value="All">All</MenuItem>
                </Select>
                <CloseIcon
                  onClick={() => {
                    permissionRef.current.value = "";
                    setfilterVal({
                      ...filterVal,
                      permission: "",
                    });
                    onTextChange();
                  }}
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default RolesFilterDrawer;
RolesFilterDrawer.propTypes = {
  drawerOpen: PropTypes.any,
  setdrawerOpen: PropTypes.any,
  filterVal: PropTypes.any,
  setfilterVal: PropTypes.any,
  onTextChange: PropTypes.any,
  setallRoles: PropTypes.any,
  setcount: PropTypes.any,
  rows: PropTypes.any,
  handleChangeVal: PropTypes.any,
  name: PropTypes.any,
  permissionRef: PropTypes.any,
  descriptionRef: PropTypes.any,
};
