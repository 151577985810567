import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRoles,
  getRolesDetails,
} from "../../../../Redux/Slice/Settings/RolesSlice/RolesSlice";
import { SnackBarContext } from "../../../../Context/SnackBarContext/SnackBarContext";
import { RegisterUserApi } from "../../../../Api/Settings/UsersApi/RegisterUserApi";
import { UpdateUserApi } from "../../../../Api/Settings/UsersApi/UpdateUserApi";

const useStyle = makeStyles(() => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
});

function Permission({ handleBack, generalData, singleUser, state }) {
  const {
    // container,
    // subContainer,
    formContainer,
  } = useStyle();
  const { Roles } = useSelector(getRolesDetails);
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);
  useEffect(() => {
    if (singleUser) {
      formik.setFieldValue("roleId", singleUser?.RoleId);
      formik.setFieldValue("permission", singleUser?.Permission);
    }
  }, [singleUser]);
  const initialValues = {
    // groups: "",
    roleId: "",
    permission: "",
  };
  const navigate = useNavigate();
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const obj = { ...values, ...generalData, userName: generalData?.email };
    const obj2 = {
      ...values,
      email: generalData?.email,
      fullName: generalData?.fullName,
      status: generalData?.status,
    };
    if (state && singleUser) {
      const response = await UpdateUserApi({ obj: obj2, id: singleUser?.Id });
      setsnackBarData(response.snackBarData);
      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
      setSubmitting(false);
    } else {
      const response = await RegisterUserApi(obj);
      setsnackBarData(response.snackBarData);
      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        navigate(-1);
      }
      setSubmitting(false);
    }
  };
  // const handleNavigate = () => {
  //   navigate(-1);
  // };
  const ValidationSchema = Yup.object().shape({
    // groups: Yup.string().required("Please Enter Groups"),
    roleId: Yup.string().required("Please Enter role"),
    permission: Yup.string().required("Please Enter permission"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Paper elevation={3} sx={{ padding: "20px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          {/* <FormControl fullWidth>
              <Typography>Groups</Typography>
              <TextField
                name="groups"
                value={formik.values?.groups}
                size={"small"}
                fullWidth
                onChange={formik.handleChange}
                error={Boolean(formik.errors.groups) && Boolean(formik.touched.groups)}
                helperText={Boolean(formik.errors.groups) && Boolean(formik.errors.groups)}
              />
            </FormControl> */}
          <FormControl fullWidth>
            <Typography>Role</Typography>
            <TextField
              name="roleId"
              value={formik.values?.roleId}
              select
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                Boolean(formik.errors.roleId) && Boolean(formik.touched.roleId)
              }
              helperText={
                Boolean(formik.errors.roleId) && Boolean(formik.touched.roleId)
                  ? formik.errors.roleId
                  : ""
              }
            >
              <MenuItem value="">Select</MenuItem>
              {Roles?.map((role, i) => {
                return (
                  <MenuItem key={i} value={role?.RoleId}>
                    {role?.Name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
          <FormControl fullWidth>
            <Typography>Permission</Typography>
            <TextField
              name="permission"
              value={formik.values?.permission}
              select
              size={"small"}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                Boolean(formik.errors.permission) &&
                Boolean(formik.touched.permission)
              }
              helperText={
                Boolean(formik.errors.permission) &&
                Boolean(formik.touched.permission)
                  ? formik.errors.permission
                  : ""
              }
            >
              <MenuItem value="">Select</MenuItem>

              <MenuItem value="Global">Global</MenuItem>
              <MenuItem value="Individual">Individual</MenuItem>
            </TextField>
          </FormControl>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button onClick={handleBack}>
                  <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                </Button>
              </Box>
              <Box>
                {formik.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.white.main,
                    }}
                  >
                    {singleUser && state ? "Update" : "Save"}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

export default Permission;
Permission.propTypes = {
  activeStep: PropTypes.any.isRequired,
  steps: PropTypes.string.isRequired,
  handleNext: PropTypes.any,
  handleBack: PropTypes.any,
  generalData: PropTypes.any,
  singleUser: PropTypes.any,
  state: PropTypes.any,
};
