import { axiosInstance } from "../../Utils/AxiosInstance";

export const AddRemarksLeadsApi = async (data) => {
  let snackBarData = {};
  try {
    console.log("Posteddata", data);
    let response = await axiosInstance.post("/LeadRemarks/Add/Update", data);

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteRemarksApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/LeadRemarks/Delete?id=${id}`);
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const UpdateStatusApi = async (data) => {
  let snackBarData = {};
  try {
    console.log("Posteddata", data);
    let response = await axiosInstance.post(`/LeadRemarks/statusChange?id=${data}`);

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
