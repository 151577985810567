import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import htmlToDraft from "html-to-draftjs";

import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";

import {
  ContentState,
  EditorState,
  Modifier,
  convertToRaw,
  RichUtils,
  getDefaultKeyBinding,
} from "draft-js";
import { CreateTemplateApi } from "../../../Api/TemplateApi/CreateTemplateApi";
import {
  fetchSingleTemplate,
  fetchTemplate,
  getTemplateDetails,
} from "../../../Redux/Slice/TemplateSlice/TemplateSlice";
import TagsModal from "./TagsModal";
import { ReactSelectTheme } from "../../../Utils/ReactSelectTheme";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      maxWidth: "1000px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textAreaContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    AddressContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    LoadingContainer: {
      height: "70vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required("Please Enter Title"),
  type: Yup.object().required("Please Select Type"),
  body: Yup.string().required("Please Enter Body"),
});
const CreateTemplate = () => {
  const { container, subContainer, formContainer } = useStyle();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { SingleTemplate } = useSelector(getTemplateDetails);
  const [tagCount, setTagCount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const typeOptions = [
    { value: "Image", label: "Image" },
    { value: "Text", label: "Text" },
    { value: "Document", label: "Document" },
  ];

  const initialValues = {
    title: "",
    type: "",
    body: "",
  };
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.ID) {
      dispatch(fetchSingleTemplate(state?.ID));
    }
  }, [state]);
  useEffect(() => {
    if (state?.ID) {
      formik?.setFieldValue("title", SingleTemplate?.Title);
      formik?.setFieldValue("type", {
        value: SingleTemplate?.Type,
        label: SingleTemplate?.Type,
      });
      formik?.setFieldValue("body", SingleTemplate?.Body);
      let body = htmlToDraft(SingleTemplate?.Body ? SingleTemplate?.Body : "");
      console.log(body);
      if (body?.contentBlocks) {
        const contentState = ContentState.createFromBlockArray(
          body?.contentBlocks
        );
        console.log(contentState);
        const editorState = EditorState.createWithContent(contentState);
        console.log(editorState);
        setEditorState(editorState);
      }
    } else if (state) {
      handleEdit(state);
    }
  }, [state, SingleTemplate]);
  console.log(state);
  console.log(SingleTemplate);
  useEffect(() => {
    let html = convertToHTML(editorState && editorState.getCurrentContent());

    console.log(formik.values.body);
    console.log(html);
    if (html !== "<p></p>") {
      formik.values.body = html;
    }
  }, [editorState]);
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const text = rawContent.blocks[0].text;
    const obj = {
      type: values.type.label,
      body: values.body,
      title: values.title,
    };
    if (text.match(/_var\d+_/g)) {
      setShowModal(true);
      setSubmitting(false);
    } else {
      if (state) {
        const response = await CreateTemplateApi({
          ...obj,
          templateId: state.ID,
        });
        setsnackBarData(response.snackBarData);
        if (response?.data?.IsSuccess) {
          setSubmitting(false);
          dispatch(fetchTemplate());
          resetForm();
          navigate(-1);
        }
      } else {
        const response = await CreateTemplateApi(obj);
        setsnackBarData(response.snackBarData);
        if (response?.data?.IsSuccess) {
          setSubmitting(false);
          dispatch(fetchTemplate());
          resetForm();
          navigate(-1);
        }
      }
      setSubmitting(false);
      resetForm();
    }
  };
  const handleNavigate = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "title",
        value: values?.title || "",
      },
    });
    formik.handleChange({
      target: {
        name: "type",
        value: { value: values?.type, label: values?.type } || "",
      },
    });

    formik?.setFieldValue("body", values?.body);
    let body = htmlToDraft(values?.body ? values?.body : "");
    if (body) {
      const contentState = ContentState.createFromBlockArray(
        body?.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      setEditorState(editorState);
    }
  };
  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };

  const insertText = (text, editorValue) => {
    setTagCount(tagCount + 1);
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );

    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  const onChange = async (value) => {
    // let html = convertToHTML(value && value.getCurrentContent());
    // console.log(html);
    // if(html?.includes("_var")){
    //   return;
    // }{

    setEditorState(value);
    // }
  };
  const handleKeyCommand = (command, editorState) => {
    // Intercept Enter key press
    console.log(command);
    if (command === "split-block") {
      // Create a new EditorState with a soft line break instead of a new paragraph
      const newState = RichUtils.insertSoftNewline(editorState);
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    // Handle Enter key press with shift
    if (e.keyCode === 13 /* 'Enter' key */ && e.shiftKey) {
      return "split-block";
    }
    return getDefaultKeyBinding(e);
  };
  const handleClosed = () => {
    setShowModal(false);
  };
  return (
    <>
      <Box className={container}>
        <Box className={subContainer}>
          <Box>
            <Box role="presentation" onClick={(e) => e.preventDefault()}>
              <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "12px" }}>
                <Link
                  to="/dashboard"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Dashboard
                </Link>
                <Link
                  to="/campaigns/templates"
                  style={{ color: "#33A5FC", textDecoration: "none" }}
                  className="link"
                  underline="hover"
                >
                  Templates
                </Link>
                <Typography color="text.primary" sx={{ fontSize: "12px" }}>
                  {state ? "Update Templates" : "Create Templates"}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography
              variant="h5"
              sx={{ textAlign: "left", fontWeight: 400 }}
            >
              {state ? "Update Templates" : "Create Templates"}
            </Typography>
          </Box>
          <Paper elevation={3} sx={{ padding: "20px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box className={formContainer}>
                <FormControl fullWidth>
                  <Typography>Title</Typography>
                  <TextField
                    name="title"
                    value={formik.values?.title}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.title) &&
                      Boolean(formik.touched.title)
                    }
                    helperText={
                      Boolean(formik.errors.title) &&
                      Boolean(formik.touched.title) &&
                      formik.errors.title
                    }
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.type) && Boolean(formik.touched.type)
                  }
                >
                  <Typography>Type</Typography>

                  <Select
                    name="type"
                    options={typeOptions}
                    value={formik.values.type}
                    onChange={(value) => {
                      formik.setFieldValue("type", value);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Select Type"
                    // styles={{
                    //   control: (baseStyles) => ({
                    //     ...baseStyles,
                    //     borderColor:
                    //       Boolean(formik.errors.type) &&
                    //       Boolean(formik.touched.type)
                    //         ? "red"
                    //         : "#bdbdbd",
                    //   }),
                    // }}
                    styles={ReactSelectTheme(
                      Boolean(formik.errors.type) &&
                        Boolean(formik.touched.type)
                    )}
                  />
                  <FormHelperText>
                    {Boolean(formik.errors.type) && Boolean(formik.touched.type)
                      ? formik.errors.type
                      : ""}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.body) && Boolean(formik.touched.body)
                  }
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography>Body</Typography>{" "}
                    <Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          sendTextToEditor(` _var${tagCount + 1}_ `)
                        }
                      >
                        Add Placeholder
                      </Button>
                      {state && (
                        <Button
                          variant="outlined"
                          color="info"
                          onClick={() => setShowModal(true)}
                        >
                          View Placeholder
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      maxHeight: "500px",
                      overflow: "auto",
                      border:
                        Boolean(formik.errors.body) &&
                        Boolean(formik.touched.body)
                          ? "1px solid red"
                          : "1px solid #bdbdbd",
                      padding: "8px",
                    }}
                  >
                    <Editor
                      placeholder="Start Typing your body from here"
                      editorState={editorState}
                      handleKeyCommand={handleKeyCommand}
                      keyBindingFn={mapKeyToEditorCommand}
                      onEditorStateChange={(value) => {
                        onChange(value);
                      }}
                    />
                  </Box>
                  <FormHelperText>
                    {Boolean(formik.errors.body) && Boolean(formik.touched.body)
                      ? formik.errors.body
                      : ""}
                  </FormHelperText>
                </FormControl>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <Button onClick={handleNavigate}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Back
                        </Typography>
                      </Button>
                    </Box>
                    <Box>
                      {formik.isSubmitting ? (
                        <Box>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Button variant="contained" type="submit">
                          <Typography sx={{ fontWeight: "bold" }}>
                            {state ? "Update" : "Save"}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Paper>
        </Box>
      </Box>
      <TagsModal
        showModal={showModal}
        handleClosed={handleClosed}
        editorState={editorState}
        formValue={formik.values}
        state={state}
        setsnackBarData={setsnackBarData}
        placeholder={
          SingleTemplate?.Placeholders ? SingleTemplate?.Placeholders : []
        }
        setTagCount={setTagCount}
      />
    </>
  );
};

export default CreateTemplate;
