import {
  Autocomplete,
  Avatar,
  // Badge,
  Box,
  Button,
  CircularProgress,
  FormControl,
  // CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import theme from "../../theme";
import axios from "axios";
import { FacebookPageDetailContext } from "../../Context/FacebookPageDetailContext/FacebookPageDetailContext";
import ScrollableContainer from "./ScrollableContainer";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFacebookPages,
  fetchPublicLeadPopulate,
  getFacebookPageDetails,
} from "../../Redux/Slice/Settings/FacebookPageSlice/FacebookPageSlice";
import Loader from "../../Components/Loader/Loader";
import {
  fetchSources,
  getSourcesDetails,
} from "../../Redux/Slice/Settings/SourcesSlice/SourcesSlice";
import { AddPublicLeadsApi } from "../../Api/PublicLeadsApi/AddPublicLeadsApi";
import { IsLeadAddedApi } from "../../Api/FacebookPagesApi/IsleadAddedApi";
// import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
// import Cookies from "universal-cookie";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      // padding: "20px",
      height: "100%",
      minHeight: "calc(100vh - 176px )",
    },
    subContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      gap: "30px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "20px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    usersContainer: {
      width: "30%",
      height: "calc(100vh - 210px )",
      background: "#ffffff",
      //   padding: "0px 10px",
      overflowY: "scroll",
      border: `1px solid ${theme.palette.grey[300]}`,
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    chatContainer: {
      width: "100%",
      border: `1px solid ${theme.palette.grey[300]}`,

      height: "calc(100vh - 210px )",
      padding: "20px",
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      background: "#ffffff",
    },
    usersBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
    },
    chatCard: {
      width: "100%",
      padding: "10px 20px",
      borderRadius: "10px",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "space-between",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      // backgroundColor:"#efefef"
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      textAlign: "left",
    },
    loadingContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});
// const cookies = new Cookies();
// let businessType = cookies.get("BusinessType");
let businessType = sessionStorage.getItem("BusinessType");

const ValidationSchema = Yup.object().shape({
  customerName: Yup.string().required("Please Enter Name"),
  email: Yup.string()
    .email("Please Enter A Valid Email Address")
    .required("Please Enter Email"),
  // phoneNumber: Yup.string().required("Please Enter Phone Number"),
  ...(businessType === "RealState"
    ? {
        project: Yup.object().nullable().required("Please Enter Project"),
        category: Yup.object().nullable().required("Please Enter Category"),
        // section: Yup.object().nullable().required("Please Enter Section"),
        // unit: Yup.object().nullable().required("Please Enter Unit"),
      }
    : {
        catalogType: Yup.string().required("Please Enter Catalog Type"),
        catalogId: Yup.object().nullable().required("Please Enter Catalog"),
      }),
});
function FacebookPage() {
  const {
    container,
    subContainer,
    usersContainer,
    // chatContainer,
    usersBox,
    chatCard,
    avatarContainer,
    loadingContainer,
    formContainer,
  } = useStyle();
  const [conversations, setConversations] = useState([]);
  const [allconversations, setallConversations] = useState([]);
  const [selectedConversations, setselectedConversations] = useState(null);
  const [messages, setmessages] = useState([]);
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [loading3, setloading3] = useState(false);
  const [myMessage, setmyMessage] = useState("");
  const { pageData, setpageData } = useContext(FacebookPageDetailContext);
  const { FacebookPages, status, PublicLeadsPopulate } = useSelector(
    getFacebookPageDetails
  );
  console.log("pageData", pageData);
  const [phone, setphone] = useState(null);
  const [dialCode, setDialCode] = useState();
  const { Sources } = useSelector(getSourcesDetails);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showForm, setshowForm] = useState(false);
  const [services, setservices] = useState([]);
  const [projects, setprojects] = useState([]);
  const [categories, setcategories] = useState([]);
  const [tags, setTags] = useState([]);

  const [sections, setSections] = useState([]);
  const [units, setUnits] = useState([]);

  const [modifiedLead, setmodifiedLead] = useState(null);
  const [errors, seterrors] = useState(null);
  const [sourceDisable, setSourceDisable] = useState(false);

  console.log("businessType", businessType);
  console.log(modifiedLead, dialCode);
  const initialValues = {
    customerName: "",
    catalogType: "",
    catalogId: null,
    sourceName: null,
    email: "",
    unit: null,
    section: null,
    category: null,
    project: null,
    tag: null,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });

  const handleMobile = (val, dial) => {
    console.log("val", val);
    setphone(val);
    setDialCode(dial);
  };
  const dispatch = useDispatch();
  console.log(services);
  console.log(selectedConversations);

  useEffect(() => {
    dispatch(fetchFacebookPages());
  }, [dispatch]);
  useEffect(() => {
    if (showForm) {
      dispatch(fetchSources());
      dispatch(fetchPublicLeadPopulate());
    }
  }, [dispatch, showForm]);
  useEffect(() => {
    if (PublicLeadsPopulate && businessType !== "RealState") {
      let arr1 = [];
      let arr2 = [];
      PublicLeadsPopulate?.map((item) => {
        if (item?.Type == "Service") {
          arr1.push(item);
        } else if (item?.Type == "Good") {
          arr2.push(item);
        }
      });
      console.log(arr1, arr2);
      if (formik.values.catalogType == "Service") {
        setservices(arr1);
      } else if (formik.values.catalogType == "Product") {
        setservices(arr2);
      } else {
        setservices([]);
      }
    }
  }, [PublicLeadsPopulate, formik.values.catalogType]);
  useEffect(() => {
    if (PublicLeadsPopulate && businessType === "RealState") {
      setprojects(PublicLeadsPopulate?.Projects);
      setcategories(PublicLeadsPopulate?.Categories);
    }
    setTags(PublicLeadsPopulate?.tags);
  }, [PublicLeadsPopulate]);
  const isLeadAdded = async (id) => {
    const response = await IsLeadAddedApi(id);
    // setsnackBarData(response.snackBarData);
    if (response?.data?.IsSuccess) {
      setmodifiedLead(response?.data?.Data);
    }
  };
  useEffect(() => {
    // if(modifiedLead){
    formik.setFieldValue(
      "email",
      modifiedLead?.Customer?.PersonEmails[0]?.Email || ""
    );
    setphone(
      modifiedLead?.Customer?.PersonContacts[0]?.ContactNumber
        ? modifiedLead?.Customer?.PersonContacts[0]?.DialCode +
            modifiedLead?.Customer?.PersonContacts[0]?.ContactNumber
        : ""
    );
    // formik.setFieldValue("phoneNumber",modifiedLead?.PhoneNumber?modifiedLead?.PhoneNumber:"");
    // formik.setFieldValue("catalogType",modifiedLead?.CatalogType?modifiedLead?.CatalogType:"");
    // formik.setFieldValue("sourceName",modifiedLead?.LeadSource?{Id:modifiedLead?.LeadSource?.Id,Name:modifiedLead?.LeadSource?.Name,}:null);
    // formik.setFieldValue("catalogId",modifiedLead?.Catalog?{Id:modifiedLead?.Catalog?.CatalogId,Name:modifiedLead?.Catalog?.Name,}:null);
  }, [modifiedLead]);
  useEffect(() => {
    if (selectedConversations && showForm) {
      formik.setFieldValue(
        "customerName",
        pageData?.name == selectedConversations?.participants?.data[0]?.name
          ? selectedConversations?.participants?.data[1]?.name
          : selectedConversations?.participants?.data[0]?.name
      );
    }
  }, [selectedConversations, showForm]);
  console.log("formik.values", formik.values);
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    let phoneValidation = false;
    if (phone == null || phone?.length < 4) {
      phoneValidation = true;
      setSubmitting(false);
      seterrors({ ...errors, phone: true });
      return;
    }
    if (phoneValidation) {
      return;
    } else {
      let obj = null;
      obj = {
        ...values,
        catalogId: values?.catalogId?.Id,
        phoneNumber: String(phone.replace(dialCode, "")),
        leadSourceId: values?.sourceName?.Id,
        sourceId: FacebookPages[FacebookPages?.length - 1]?.Id,
        userId:
          pageData?.name == selectedConversations?.participants?.data[0]?.name
            ? selectedConversations?.participants?.data[1]?.id
            : selectedConversations?.participants?.data[0]?.id,
        dialCode: dialCode,
        projectId: values?.project?.ProjectId,
        categoryId: values?.category?.CategoryId,
        unitId: values?.unit?.UnitId,
        sectionId: values?.section?.SectionId,
        tagId: values?.tag?.TagId,
      };
      console.log(showForm);
      const response = await AddPublicLeadsApi(obj, businessType);
      setsnackBarData(response.snackBarData);
      if (response?.data.IsSuccess) {
        setSubmitting(false);
        resetForm();
        isLeadAdded(
          pageData?.name == selectedConversations?.participants?.data[0]?.name
            ? selectedConversations?.participants?.data[1]?.id
            : selectedConversations?.participants?.data[0]?.id
        );
        setshowForm(false);
        // navigate("/settings/fbpagetoken");
      }
    }
    setSubmitting(false);
  };

  // const [imageData, setImageData] = useState('');
  // console.log(imageData);
  // const handleFileInputChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     const base64Data = reader.result.split(',')[1];
  //     setImageData(base64Data);
  //   };

  //   reader.readAsDataURL(file);
  // };

  const fetchConversations2 = async () => {
    try {
      // Fetching the conversations using Page Access Token
      const response = await fetch(
        `https://graph.facebook.com/v12.0/${
          FacebookPages[FacebookPages?.length - 1]?.PageId
        }/conversations?fields=id,picture,recipient{id,picture},updated_time,participants{name,id,email,picture},snippet,unread_count&access_token=${
          FacebookPages[FacebookPages?.length - 1]?.AccessToken
        }`
      );
      const data = await response.json();
      if (data.data) {
        console.log(data);

        setConversations(data.data);
        setallConversations(data.data);
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
      }
    } catch (error) {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      console.error("Error fetching conversations:", error);
    }
  };
  useEffect(() => {
    if (FacebookPages?.length > 0) {
      const fetchImg = async () => {
        setloading(true);
        try {
          // Fetching the conversations using Page Access Token
          const response = await fetch(
            `https://graph.facebook.com/v12.0/me?fields=id,name,picture&access_token=${
              FacebookPages[FacebookPages?.length - 1]?.AccessToken
            }`
          );
          const data = await response.json();
          if (response) {
            setloading(false);
            setpageData({
              name: data?.name,
              id: data?.id,
              imageUrl: data?.picture?.data?.url,
            });
            console.log(data);
            // setConversations(data.data);
          } else {
            setsnackBarData({
              type: "error",
              message: "Something went wrong",
              openToast: true,
            });
            setloading(false);
          }
        } catch (error) {
          setsnackBarData({
            type: "error",
            message: "Network Error",
            openToast: true,
          });
          setloading(false);

          console.error("Error fetching conversations:", error);
        }
      };
      const fetchConversations = async () => {
        setloading(true);
        try {
          // Fetching the conversations using Page Access Token
          const response = await fetch(
            `https://graph.facebook.com/v12.0/709847126138605/conversations?fields=id,picture,recipient{id,picture},updated_time,participants{name,id,email,picture},snippet,unread_count&access_token=${
              FacebookPages[FacebookPages?.length - 1]?.AccessToken
            }`
          );
          const data = await response.json();
          if (data.data) {
            console.log(data);
            setloading(false);
            setConversations(data.data);
            setallConversations(data.data);
          } else {
            setloading(false);
            setsnackBarData({
              type: "error",
              message: "Something went wrong",
              openToast: true,
            });
          }
        } catch (error) {
          setloading(false);
          setsnackBarData({
            type: "error",
            message: "Network Error",
            openToast: true,
          });
          console.error("Error fetching conversations:", error);
        }
      };

      // Call fetchConversations when the component mounts
      fetchConversations();
      fetchImg();
      const intervalId = setInterval(() => {
        fetchConversations2().catch((error) =>
          console.error("Error fetching data:", error)
        );
      }, 60000);

      // Clean up the interval to prevent memory leaks
      return () => clearInterval(intervalId);
    }
  }, [FacebookPages]);
  const fetchMessages2 = async () => {
    try {
      // Fetching the messages for a specific conversation using Page Access Token
      const response = await fetch(
        `https://graph.facebook.com/v12.0/${
          selectedConversations?.id
        }/messages?fields=id,message,unread_count,attachments,created_time,from,to&access_token=${
          FacebookPages[FacebookPages?.length - 1]?.AccessToken
        }`
      );
      const data = await response.json();
      if (data.data) {
        setmessages(data?.data.reverse());
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
      }
      console.log(data); // Log the messages data to the console or handle it as needed
    } catch (error) {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });

      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (selectedConversations?.id) {
      isLeadAdded(
        pageData?.name == selectedConversations?.participants?.data[0]?.name
          ? selectedConversations?.participants?.data[1]?.id
          : selectedConversations?.participants?.data[0]?.id
      );
      const intervalId2 = setInterval(() => {
        fetchMessages2().catch((error) =>
          console.error("Error fetching data:", error)
        );
      }, 60000);
      // Clean up the interval to prevent memory leaks
      return () => clearInterval(intervalId2);
    }
  }, [selectedConversations]);
  const fetchMessages = async (conversationId) => {
    setloading2(true);
    try {
      // Fetching the messages for a specific conversation using Page Access Token
      const response = await fetch(
        `https://graph.facebook.com/v12.0/${conversationId}/messages?fields=id,message,unread_count,attachments,created_time,from,to&access_token=${
          FacebookPages[FacebookPages?.length - 1]?.AccessToken
        }`
      );
      const data = await response.json();
      if (data.data) {
        setloading2(false);

        setmessages(data?.data.reverse());
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
        setloading2(false);
      }
      console.log(data); // Log the messages data to the console or handle it as needed
    } catch (error) {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      setloading2(false);

      console.error("Error fetching messages:", error);
    }
  };
  const sendMessage = async () => {
    setloading3(true);
    try {
      const response = await axios.post(
        `https://graph.facebook.com/v12.0/me/messages`,
        {
          messaging_type: "RESPONSE", // or 'MESSAGE_TAG', depending on your use case
          recipient: {
            id: selectedConversations?.participants?.data[0]?.id,
          },
          // message: {
          //   attachment: {
          //     type: 'image',
          //     payload: {
          //       url: `data:image/jpeg;base64,${imageData}`
          //     }
          //   }
          // },
          message: {
            text: myMessage,
          },
        },
        {
          params: {
            access_token: FacebookPages[FacebookPages?.length - 1]?.AccessToken,
          },
        }
      );
      if (response.data) {
        setloading3(false);
        fetchMessages(selectedConversations?.id);
        setmyMessage("");
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
      }
      console.log("Message sent:", response.data);
      // Optionally, update state or perform other actions upon successful message sending
    } catch (err) {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      setloading3(false);

      // setError(err.response.data.error.message);
    }
  };
  const groupedChats2 = messages?.reduce((acc, chat) => {
    const dateKey = new Date(chat.created_time).toDateString();
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(chat);
    return acc;
  }, {});
  const handleSearch = (e) => {
    console.log(conversations);
    const query = e.target.value;
    console.log(query);
    if (query == "") {
      setConversations(allconversations);
    } else {
      let suggestions = [];
      const regex = new RegExp(query, "i");
      suggestions = conversations?.filter((item) =>
        regex.test(item?.participants?.data[0]?.name)
      );
      console.log(suggestions);
      setConversations(suggestions);
    }
    // let val = conversations?.filter((f) => {
    //   console.log(f);
    //   console.log(f.participants?.data[0]?.name);
    //   if (
    //     f.participants?.data[0]?.name
    //       .toLowerCase()
    //       .includes(e.target.value?.toLowerCase())
    //   ) {
    //     return f;
    //   }
    // });
    // console.log(val);
    // setConversations(val);
  };
  // const ChatList = ({ chats }) => {
  //     // Group chats by date
  //     const groupedChats = chats.reduce((acc, chat) => {
  //       const dateKey = new Date(chat.timestamp).toDateString();
  //       if (!acc[dateKey]) {
  //         acc[dateKey] = [];
  //       }
  //       acc[dateKey].push(chat);
  //       return acc;
  //     }, {});

  //     return (
  //       <div>
  //         {Object.keys(groupedChats).map(dateKey => (
  //           <div key={dateKey}>
  //             <h2>{dateKey}</h2>
  //             <ul>
  //               {groupedChats[dateKey].map(chat => (
  //                 <li key={chat.id}>
  //                   <strong>{chat.sender}:</strong> {chat.message}
  //                 </li>
  //               ))}
  //             </ul>
  //           </div>
  //         ))}
  //       </div>
  //     );
  //   };

  //   const dummyChats = [
  //     { id: 1, sender: 'Alice', message: 'Hi there!', timestamp: '2024-03-01T10:00:00' },
  //     { id: 2, sender: 'Bob', message: 'Hello!', timestamp: '2024-03-01T12:30:00' },
  //     { id: 3, sender: 'Alice', message: 'How are you?', timestamp: '2024-03-02T09:15:00' },
  //     { id: 4, sender: 'Bob', message: 'I\'m good, thanks!', timestamp: '2024-03-02T10:00:00' },
  //     { id: 5, sender: 'Alice', message: 'Great!', timestamp: '2024-03-02T11:20:00' },
  //     { id: 6, sender: 'Alice', message: 'What about our meeting tomorrow?', timestamp: '2024-03-03T14:00:00' },
  //     { id: 7, sender: 'Bob', message: 'I almost forgot! It\'s at 2 PM, right?', timestamp: '2024-03-03T15:00:00' },
  //   ];
  function enterKeySearch(e) {
    console.log(e.key);
    if (myMessage) {
      if (e.key === "Enter") {
        sendMessage();
        setmyMessage("");
      }
    } else {
      console.log("err");
    }
  }
  useEffect(() => {
    console.log("sources", Sources);
    Sources.forEach((source) => {
      if (source.Name === "Social Media") {
        formik.setFieldValue("sourceName", source);
        setSourceDisable(true);
      }
    });
  }, [Sources]);
  return (
    <>
      {status === "loading" ? <Loader /> : null}
      {/* <button onClick={()=>{
    scroll.scrollToBottom();

    }}>Scroll Down</button> */}
      {/* <input type="file" onChange={handleFileInputChange} /> */}
      {/* <div>
      <h1>Chat Application</h1>
      <ChatList chats={dummyChats} />
    </div> */}
      <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
        <Box sx={{ width: "100%", maxWidth: "50px", borderRadius: "50%" }}>
          <img
            src={pageData?.imageUrl}
            alt="Image"
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </Box>
        <Typography variant="h5" noWrap href="/">
          {pageData?.name}
        </Typography>
      </Box>
      <Box className={container}>
        <Box className={subContainer}>
          <Box className={usersContainer}>
            <Box
              sx={{
                width: "100%",
                position: "sticky",
                top: "0",
                padding: "5px 5px 0px 5px",
                zIndex: "10",
                //   backgroundColor:"red"
              }}
            >
              <OutlinedInput
                fullWidth
                placeholder="Conversations"
                size="small"
                onChange={handleSearch}
                sx={{ background: (theme) => theme.palette.white.main }}
                inputProps={{ style: { color: "black !important" } }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            {loading ? (
              <Box className={loadingContainer}>
                <CircularProgress />
              </Box>
            ) : (
              <Box className={usersBox}>
                {conversations?.map((data, i) => {
                  return (
                    // <Badge
                    //   key={i}
                    //   badgeContent={data?.unread_count}
                    //   color="primary"
                    // >
                    <Box
                      key={i}
                      className={chatCard}
                      sx={{
                        backgroundColor:
                          data?.id == selectedConversations?.id
                            ? (theme) => theme.palette.primary.main
                            : "",
                        boxShadow:
                          data?.id == selectedConversations?.id ? "none" : "",
                      }}
                      onClick={() => {
                        setselectedConversations(data);
                        fetchMessages(data?.id);
                        //   handleChangeConversations(data?.Conversation);
                      }}
                    >
                      <Box className={avatarContainer}>
                        <Box>
                          <Avatar src={"./"} />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color:
                                data?.id == selectedConversations?.id
                                  ? "#fff"
                                  : "",
                            }}
                          >
                            {data?.participants?.data[0]?.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              color:
                                data?.id == selectedConversations?.id
                                  ? "#fff"
                                  : "",
                            }}
                          >
                            {data?.updated_time
                              ? moment(data?.updated_time).format(
                                  "DD-MM-YYYY hh:mm A"
                                )
                              : ""}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    // {/* </Badge> */}
                  );
                })}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              // height: "calc(100vh - 210px )",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "calc(100vh - 210px )",

                  width: "100%",
                  maxWidth: showForm ? "80%" : "100%",
                  [theme.breakpoints.down("md")]: {
                    maxWidth: showForm ? "65%" : "100%",
                  },
                }}
              >
                {messages?.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "#ffffff",
                      border: `1px solid ${theme.palette.grey[300]}`,
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Avatar src={"./"} />
                      </Box>
                      <Box
                        sx={{
                          position: "sticky",
                          top: "0",

                          fontSize: "20px",
                        }}
                      >
                        {pageData?.name == messages[0]?.from?.name
                          ? messages[0]?.to?.data[0]?.name
                          : messages[0]?.from?.name}
                      </Box>
                    </Box>
                    <Button
                      onClick={() => {
                        setshowForm(true);
                      }}
                      sx={{ textTransform: "none" }}
                      variant="contained"
                    >
                      {modifiedLead ? "Add Another Lead" : "Add Lead"}
                    </Button>
                  </Box>
                ) : null}
                <ScrollableContainer>
                  {/* <Box 
             className={chatContainer}
             > */}
                  <>
                    {loading2 ? (
                      <Box className={loadingContainer}>
                        <CircularProgress />
                      </Box>
                    ) : messages?.length > 0 ? (
                      <Box>
                        {Object.keys(groupedChats2)?.map((dateKey) => (
                          <Box key={dateKey}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                paddingY: "15px",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "11px", color: "#999999" }}
                              >
                                {moment(dateKey).format("DD-MMM-YYYY")}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                flexDirection: "column",
                              }}
                            >
                              {groupedChats2[dateKey]?.map((data, i) => {
                                return (
                                  <Box
                                    key={i}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "10px",
                                    }}
                                  >
                                    {data?.from?.name ==
                                    pageData?.name ? null : data?.attachments?.data[0]?.mime_type?.includes(
                                        "image/"
                                      ) ? (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          maxWidth: "100px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                          src={
                                            data?.attachments?.data[0]
                                              ?.image_data?.url
                                          }
                                        />
                                      </Box>
                                    ) : data?.attachments?.data[0]?.mime_type?.includes(
                                        "audio/"
                                      ) ? (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Box
                                          className={chatCard}
                                          sx={{
                                            maxWidth: { md: "50%", xs: "80%" },
                                            borderRadius: "40px 40px 40px 0px",
                                            backgroundColor:
                                              "#EFEFEF !important",
                                            boxShadow: "none !important",

                                            // borderRadius: "100px 100px 100px 0px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <Box>
                                            <Link
                                              href={
                                                data?.attachments?.data[0]
                                                  ?.file_url
                                              }
                                            >
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <KeyboardVoiceIcon
                                                  sx={{ fontSize: "30px" }}
                                                />
                                                <Typography
                                                  sx={{
                                                    width: "100%",
                                                    maxWidth: "300px",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {
                                                    data?.attachments?.data[0]
                                                      ?.file_url
                                                  }
                                                </Typography>
                                              </Box>
                                            </Link>
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                paddingTop: "3px",
                                              }}
                                            >
                                              {data?.created_time
                                                ? moment(
                                                    data?.created_time
                                                  ).format("DD-MM-YYYY hh:mm A")
                                                : ""}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    ) : data?.message == "" ? null : (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <Box
                                          className={chatCard}
                                          sx={{
                                            maxWidth: { md: "50%", xs: "80%" },
                                            borderRadius: "40px 40px 40px 0px",
                                            backgroundColor:
                                              "#EFEFEF !important",
                                            boxShadow: "none !important",
                                            // borderRadius: "100px 100px 100px 0px",
                                            textAlign: "left",
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: "500",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {data?.message}
                                            </Typography>
                                            <Typography
                                              sx={{ fontSize: "10px" }}
                                            >
                                              {data?.created_time
                                                ? moment(
                                                    data?.created_time
                                                  ).format("DD-MM-YYYY hh:mm A")
                                                : ""}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    )}

                                    {data?.from?.name == pageData?.name ? (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <Box
                                          className={chatCard}
                                          sx={{
                                            maxWidth: { md: "50%", xs: "80%" },
                                            borderRadius: "40px 40px 0px 40px",
                                            textAlign: "left",
                                            // backgroundColor: "#0A7CFF",
                                            backgroundColor: (theme) =>
                                              theme.palette.primary.main,
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                color: "#ffffff",
                                              }}
                                            >
                                              {data?.message}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontSize: "10px",
                                                color: "#ffffff",
                                              }}
                                            >
                                              {data?.created_time
                                                ? moment(
                                                    data?.created_time
                                                  ).format("DD-MM-YYYY hh:mm A")
                                                : ""}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    ) : null}
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      // messages?.map((data, i) => {
                      //   console.log(data);
                      //   return (
                      //     <Box
                      //       key={i}
                      //       sx={{
                      //         display: "flex",
                      //         flexDirection: "column",
                      //         gap: "10px",
                      //       }}
                      //     >
                      //       {data?.from?.name ==
                      //       pageData?.name ? null : data?.attachments?.data[0]?.mime_type?.includes(
                      //           "image/"
                      //         ) ? (
                      //         <Box sx={{ width: "100%", maxWidth: "100px" }}>
                      //           <img
                      //             style={{ width: "100%", height: "100%" }}
                      //             src={data?.attachments?.data[0]?.image_data?.url}
                      //           />
                      //         </Box>
                      //       ) : (
                      //         <Box
                      //           sx={{
                      //             width: "100%",
                      //             display: "flex",
                      //             justifyContent: "start",
                      //           }}
                      //         >
                      //           <Box
                      //             className={chatCard}
                      //             sx={{
                      //               maxWidth: { md: "50%", xs: "80%" },
                      //               borderRadius: "100px 100px 100px 0px",
                      //               textAlign: "left",
                      //             }}
                      //           >
                      //             <Box>
                      //               <Typography
                      //                 sx={{ fontWeight: "700", fontSize: "14px" }}
                      //               >
                      //                 {data?.message}
                      //               </Typography>
                      //               <Typography sx={{ fontSize: "10px" }}>
                      //                 {data?.created_time
                      //                   ? moment(data?.created_time).format(
                      //                       "DD-MM-YYYY hh:mm A"
                      //                     )
                      //                   : ""}
                      //               </Typography>
                      //             </Box>
                      //           </Box>
                      //         </Box>
                      //       )}
                      //       {data?.from?.name == pageData?.name ? (
                      //         <Box
                      //           sx={{
                      //             width: "100%",
                      //             display: "flex",
                      //             justifyContent: "end",
                      //           }}
                      //         >
                      //           <Box
                      //             className={chatCard}
                      //             sx={{
                      //               maxWidth: { md: "50%", xs: "80%" },
                      //               borderRadius: "100px 100px 0px 100px",
                      //               textAlign: "left",
                      //               backgroundColor: "#0A7CFF",
                      //             }}
                      //           >
                      //             <Box>
                      //               <Typography
                      //                 sx={{
                      //                   fontWeight: "700",
                      //                   fontSize: "14px",
                      //                   color: "#ffffff",
                      //                 }}
                      //               >
                      //                 {data?.message}
                      //               </Typography>
                      //               <Typography
                      //                 sx={{ fontSize: "10px", color: "#ffffff" }}
                      //               >
                      //                 {data?.created_time
                      //                   ? moment(data?.created_time).format(
                      //                       "DD-MM-YYYY hh:mm A"
                      //                     )
                      //                   : ""}
                      //               </Typography>
                      //             </Box>
                      //           </Box>
                      //         </Box>
                      //       ) : null}
                      //     </Box>
                      //   );
                      // })
                      <Typography>No Messages</Typography>
                    )}
                  </>
                  {/* </Box> */}
                </ScrollableContainer>
                {messages?.length > 0 ? (
                  <Box
                    sx={{
                      backgroundColor: "#ffffff",
                      //   border: `1px solid ${theme.palette.grey[300]}`,
                      position: "sticky",
                      bottom: "0",
                      //   paddingX: "10px",
                      //   paddingY: "5px",
                      fontSize: "20px",
                    }}
                  >
                    <OutlinedInput
                      fullWidth
                      placeholder="Type Your Message Here..."
                      size="small"
                      multiline
                      rows={2}
                      value={myMessage}
                      onChange={(e) => {
                        setmyMessage(e.target.value);
                      }}
                      onKeyPress={(e) => enterKeySearch(e)}
                      //   onChange={handleSearch}
                      sx={{
                        background: (theme) => theme.palette.white.main,
                        borderRadius: "0px",
                        border: "none !important",
                      }}
                      inputProps={{ style: { color: "black !important" } }}
                      endAdornment={
                        loading3 ? (
                          <CircularProgress />
                        ) : (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={sendMessage}
                              disabled={myMessage?.length <= 0}
                            >
                              <SendIcon
                                sx={{
                                  color:
                                    myMessage?.length <= 0
                                      ? ""
                                      : (theme) => theme.palette.primary.main,
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />

                    {/* {messages[0]?.from?.name} */}
                  </Box>
                ) : null}
              </Box>
              {showForm ? (
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "20%",
                    backgroundColor: "#ffffff",
                    border: `1px solid ${theme.palette.grey[300]}`,
                    padding: "10px",
                    [theme.breakpoints.down("md")]: {
                      maxWidth: showForm ? "35%" : "20%",
                    },
                  }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: (theme) => theme.palette.primary.main,
                      }}
                    >
                      {modifiedLead
                        ? "Modify Public Lead:"
                        : "Add To Public Lead:"}
                    </Typography>

                    <Box className={formContainer}>
                      <FormControl fullWidth>
                        <Typography>Name</Typography>
                        <TextField
                          disabled
                          name="customerName"
                          value={formik.values?.customerName}
                          size={"small"}
                          fullWidth
                          onChange={formik.handleChange}
                          error={
                            Boolean(formik.errors.customerName) &&
                            Boolean(formik.touched.customerName)
                          }
                          helperText={
                            Boolean(formik.errors.customerName) &&
                            Boolean(formik.touched.customerName) &&
                            formik.errors.customerName
                          }
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography>Email</Typography>
                        <TextField
                          disabled={modifiedLead ? true : false}
                          name="email"
                          value={formik.values?.email}
                          size={"small"}
                          fullWidth
                          onChange={formik.handleChange}
                          error={
                            Boolean(formik.errors.email) &&
                            Boolean(formik.touched.email)
                          }
                          helperText={
                            Boolean(formik.errors.email) &&
                            Boolean(formik.touched.email) &&
                            formik.errors.email
                          }
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography>Phone Number</Typography>
                        <PhoneInput
                          disabled={modifiedLead ? true : false}
                          inputStyle={{
                            // backgroundColor:"yellow",
                            width: "100%",
                            height: "40px",
                          }}
                          country={"pk"}
                          value={phone}
                          // onChange={(e, data) => handleMobile(e, data.dialCode)}
                          onChange={(e, data) => handleMobile(e, data.dialCode)}
                          containerStyle={{ width: "100%", height: "40px" }}
                        />
                        {errors?.phone ? (
                          <Typography color={"error"} sx={{ fontSize: "12px" }}>
                            Please Enter Phone Number
                          </Typography>
                        ) : null}
                      </FormControl>
                      {/* <FormControl fullWidth>
                        <Typography>Phone Number</Typography>
                        <TextField
                          name="phoneNumber"
                       disabled={modifiedLead?true:false}

                          type="number"
                          value={formik.values?.phoneNumber}
                          size={"small"}
                          fullWidth
                          onChange={formik.handleChange}
                          error={
                            Boolean(formik.errors.phoneNumber) &&
                            Boolean(formik.touched.phoneNumber)
                          }
                          helperText={
                            Boolean(formik.errors.phoneNumber) &&
                            Boolean(formik.touched.phoneNumber) &&
                            formik.errors.phoneNumber
                          }
                        />
                      </FormControl> */}
                      <FormControl fullWidth>
                        <Typography>Source</Typography>
                        <Autocomplete
                          disabled={sourceDisable}
                          name="sourceName"
                          //   options={["hjehje"]}
                          options={Sources}
                          getOptionLabel={(option) => option.Name}
                          size={"small"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // error={
                              //   Boolean(formik.errors.sourceName) &&
                              //   Boolean(formik.touched.sourceName)
                              // }
                              // helperText={
                              //   Boolean(formik.errors.sourceName) &&
                              //   Boolean(formik.touched.sourceName) &&
                              //   formik.errors.sourceName
                              // }
                              //   name="personId"
                            />
                          )}
                          value={formik.values?.sourceName}
                          onChange={(__, value) => {
                            console.log(value?.Name);
                            formik.setFieldValue("sourceName", value);
                            // formik.values?.date=e.target.value
                          }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography>Tags</Typography>
                        <Autocomplete
                          name="tag"
                          options={tags}
                          getOptionLabel={(option) => option.Name}
                          size={"small"}
                          renderInput={(params) => <TextField {...params} />}
                          value={formik.values?.tag}
                          onChange={(__, value) => {
                            console.log("value", value);
                            formik.setFieldValue("tag", value);
                            // formik.values?.date=e.target.value
                          }}
                        />
                      </FormControl>
                      {businessType === "RealState" ? (
                        <>
                          <FormControl fullWidth>
                            <Typography>Project</Typography>
                            <Autocomplete
                              name="project"
                              //   options={["hjehje"]}
                              options={projects}
                              getOptionLabel={(option) => option.ProjectName}
                              size={"small"}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    Boolean(formik.errors.project) &&
                                    Boolean(formik.touched.project)
                                  }
                                  helperText={
                                    Boolean(formik.errors.project) &&
                                    Boolean(formik.touched.project) &&
                                    formik.errors.project
                                  }
                                  //   name="personId"
                                />
                              )}
                              value={formik.values.project}
                              onChange={(__, value) => {
                                formik.setFieldValue("project", value);
                                // formik.values?.date=e.target.value
                              }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <Typography>Category</Typography>
                            <Autocomplete
                              name="category"
                              //   options={["hjehje"]}
                              options={categories}
                              getOptionLabel={(option) => option.CategoryName}
                              size={"small"}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    Boolean(formik.errors.category) &&
                                    Boolean(formik.touched.category)
                                  }
                                  helperText={
                                    Boolean(formik.errors.category) &&
                                    Boolean(formik.touched.category) &&
                                    formik.errors.category
                                  }
                                  //   name="personId"
                                />
                              )}
                              value={formik.values.category}
                              onChange={(__, value) => {
                                formik.setFieldValue("category", value);
                                setSections(value.Sections);
                                // formik.values?.date=e.target.value
                              }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <Typography>Section</Typography>
                            <Autocomplete
                              name="section"
                              //   options={["hjehje"]}
                              options={sections}
                              getOptionLabel={(option) => option.SectionName}
                              size={"small"}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    Boolean(formik.errors.section) &&
                                    Boolean(formik.touched.section)
                                  }
                                  helperText={
                                    Boolean(formik.errors.section) &&
                                    Boolean(formik.touched.section) &&
                                    formik.errors.section
                                  }
                                  //   name="personId"
                                />
                              )}
                              value={formik.values.section}
                              onChange={(__, value) => {
                                formik.setFieldValue("section", value);
                                setUnits(value.Units);
                                // formik.values?.date=e.target.value
                              }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <Typography>Unit</Typography>
                            <Autocomplete
                              name="unit"
                              //   options={["hjehje"]}
                              options={units}
                              getOptionLabel={(option) => option.UnitName}
                              size={"small"}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    Boolean(formik.errors.unit) &&
                                    Boolean(formik.touched.unit)
                                  }
                                  helperText={
                                    Boolean(formik.errors.unit) &&
                                    Boolean(formik.touched.unit) &&
                                    formik.errors.unit
                                  }
                                  //   name="personId"
                                />
                              )}
                              value={formik.values.unit}
                              onChange={(__, value) => {
                                formik.setFieldValue("unit", value);
                                // formik.values?.date=e.target.value
                              }}
                            />
                          </FormControl>
                        </>
                      ) : (
                        <>
                          <FormControl fullWidth>
                            <Typography>Catalog Type</Typography>
                            <TextField
                              name="catalogType"
                              select
                              value={formik.values?.catalogType}
                              size={"small"}
                              fullWidth
                              onChange={formik.handleChange}
                              error={
                                Boolean(formik.errors.catalogType) &&
                                Boolean(formik.touched.catalogType)
                              }
                              helperText={
                                Boolean(formik.errors.catalogType) &&
                                Boolean(formik.touched.catalogType) &&
                                formik.errors.catalogType
                              }
                            >
                              <MenuItem value="Product">Product</MenuItem>
                              <MenuItem value="Service">Service</MenuItem>
                            </TextField>
                          </FormControl>
                          <FormControl fullWidth>
                            <Typography>
                              {formik.values.catalogType == "Service"
                                ? "Services"
                                : "Products"}
                            </Typography>
                            <Autocomplete
                              name="catalogId"
                              //   options={["hjehje"]}
                              options={services}
                              getOptionLabel={(option) => option.Name}
                              size={"small"}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    Boolean(formik.errors.catalogId) &&
                                    Boolean(formik.touched.catalogId)
                                  }
                                  helperText={
                                    Boolean(formik.errors.catalogId) &&
                                    Boolean(formik.touched.catalogId) &&
                                    formik.errors.catalogId
                                  }
                                  //   name="personId"
                                />
                              )}
                              value={formik.values.catalogId}
                              onChange={(__, value) => {
                                formik.setFieldValue("catalogId", value);
                                // formik.values?.date=e.target.value
                              }}
                            />
                          </FormControl>
                        </>
                      )}
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Box>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#e06b6b !important",
                              }}
                              onClick={() => {
                                // setmodifiedLead(null);
                                setshowForm(false);
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  textTransform: "none",
                                }}
                              >
                                Cancel
                              </Typography>
                            </Button>
                          </Box>
                          {formik.isSubmitting ? (
                            <Box>
                              {/* <Loader/> */}
                              <CircularProgress />
                            </Box>
                          ) : (
                            <Box>
                              <Button variant="contained" type="submit">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    textTransform: "none",
                                  }}
                                >
                                  {/* {modifiedLead ? "Modify" : "Save"} */}
                                  {"Save"}
                                </Typography>
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </form>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FacebookPage;
// FacebookPage.propTypes = {
//     chats: PropTypes.array,
//   };
