import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ActivitiesTable from "../../Components/Activities/ActivitiesTable";
import ActivitiesCalendar from "../../Components/Activities/ActivitiesCalendar";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import PropTypes from "prop-types";
import Joyride from "react-joyride";
import { AppContext } from "../../Context/TourContext/TourContext";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => {
  return {
    topBox: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "end",
    },

    customTabSelected: {
      padding: "5px 10px 1px 10px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
      alignItems: "center",
    },
    customTab: {
      padding: "5px 10px 1px 10px",
      backgroundColor: "white",
      border: "1px solid #707070",
      cursor: "pointer",
      alignItems: "center",
      color: theme.palette.primary.main,
    },
  };
});

const Activities = ({ roles }) => {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { topBox, customTabSelected, customTab } = useStyles();
  const [filterArr] = useState([
    { name: "tableView", icon: <BackupTableIcon /> },
    { name: "calanderView", icon: <CalendarMonthIcon sx={{}} /> },
  ]);
  const [selectedFilter, setselectedFilter] = useState("tableView");
  const [showCards, setshowCards] = useState("tableView");
  const { setState, state } = useContext(AppContext);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Activities");
    if (!isSkip) {
      setState({ ...state, run: true });
    } else {
      setState({ ...state, run: false });
    }
  }, []);
  return (
    <>
      <Box>
        {!guide ? (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Activities"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        ) : (
          <></>
        )}

        {/* {activePage} */}
      </Box>
      <Box id="activities">
        <Box
          className={topBox}
          sx={{}}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {filterArr?.map((item, idx) => {
              return (
                <Box
                  onClick={() => {
                    setselectedFilter(item.name);
                    setshowCards(item.name);
                  }}
                  sx={{
                    borderLeft: idx != 0 ? "none !important" : "",
                  }}
                  className={
                    selectedFilter === item.name ? customTabSelected : customTab
                  }
                  key={idx}
                >
                  {item?.icon}
                </Box>
              );
            })}
          </Box>
        </Box>
        {showCards === "tableView" ? (
          <ActivitiesTable
            setshowCards={setshowCards}
            roles={roles}
          />
        ) : (
          <ActivitiesCalendar setshowCards={setshowCards} />
        )}
      </Box>
    </>
  );
};
export default Activities;
const steps = [
  {
    target: "#activities",
    content: (
      <>
        <p size="large">Welcome to the Activities Page</p>
        <p>
          Your centralized hub for tracking and managing interactions with
          leads. Effortlessly navigate through your activities, gain insights
          into lead interactions, and stay organized.
          <br />
          Use the filters and sorting options to tailor your view according to
          your preferences.
          <br />
          <br />
          Pro tip: Enhance lead relationships by documenting each interaction.
          Ready to log a new activity? Click &apos;New&apos; to get started.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
Activities.propTypes = {
  roles: PropTypes.array,
};
