import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Lead from "../../Components/Settings/Lead/Lead";
import OtherSettings from "../../Components/Settings/OtherSettings/OtherSettings";
import User from "../../Components/Settings/User/User";
import PropTypes from "prop-types";
import { AppContext } from "../../Context/TourContext/TourContext";
import Joyride from "react-joyride";
import { TourCheckApi } from "../../Api/TourCheckApi/TourCheckApi";
// import Cookies from "universal-cookie";
import Inventory from "../../Components/Settings/Inventory/Inventory";

function Settings({ roles }) {
  // const cookies = new Cookies();
  // let guide = cookies.get("guide");
  let guide = sessionStorage.getItem("guide");
  const { setState, state } = useContext(AppContext);
  console.log(roles);

  useEffect(() => {
    const isSkip = state?.skip?.some((item) => item == "Settings");
    if (!isSkip) {
      setState({ ...state, run: true });
    }
  }, []);
  return (
    <>
      <Box>
        {!guide && (
          <Joyride
            run={guide ? false : state?.run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            spotlightPadding={10}
            disableOverlayClose={true}
            disableScrolling={true}
            disableScrollParentFix={true}
            floaterProps={{
              styles: {
                arrow: {
                  margin: -10,
                },
              },
            }}
            styles={{
              options: {
                arrowColor: "#5caeab",
                backgroundColor: "#5caeab",
                // overlayColor: "rgba(92, 174, 171, .3)",
                primaryColor: "#5caeab",
                textColor: "#fff",
              },
              // spotlight: {
              //   backgroundColor: "transparent",
              // },
            }}
            callback={(data) => {
              const { status, action } = data;
              console.log(status);
              if (
                action == "close" ||
                action == "skip" ||
                status == "finished"
              ) {
                setState({
                  ...state,
                  run: false,
                  skip: [...state.skip, "Settings"],
                });
                action === "skip" && TourCheckApi(true);
              } else if (
                [Joyride.STATES?.FINISHED, Joyride.STATES?.SKIPPED].includes(
                  status
                )
              ) {
                setState({ ...state, run: false });
              }
            }}
            steps={steps}
          />
        )}
        {/* {activePage} */}
      </Box>
      <Box
        id="settings"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        {roles?.some((role) => role === "Groups") ||
        roles?.some((role) => role === "Roles") ||
        roles?.some((role) => role === "Users") ? (
          <User roles={roles} />
        ) : null}
        {roles?.some((role) => role === "Pipelines") ||
        roles?.some((role) => role === "Sources") ||
        roles?.some((role) => role === "Types") ||
        roles?.some((role) => role === "Descriptions") ? (
          <Lead roles={roles} />
        ) : null}{" "}
        {roles?.some(
          (role) => role === "Inventory Settings_Inventory Settings_Sections"
        ) || roles?.some((role) => role === "Inventory Settings_Projects") ? (
          <Inventory roles={roles} />
        ) : null}
        {roles?.some((role) => role === "Tags") ||
        roles?.some((role) => role === "Other Settings_Business") ||
        roles?.some((role) => role === "Other Settings_Facebook Page") ||
        roles?.some((role) => role === "Other_Settings") ? (
          <OtherSettings roles={roles} />
        ) : null}
      </Box>
    </>
  );
}

export default Settings;
Settings.propTypes = {
  roles: PropTypes.array,
};
const steps = [
  {
    target: "#settings-groups",
    content: (
      <>
        <p size="large">Groups Management</p>
        <p>
          Your control center for organizing and categorizing users. Groups help
          streamline permissions, making it easier to manage access and
          visibility.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#settings-roles",
    content: (
      <>
        <p size="large">Roles Management</p>
        <p>
          Where you define user permissions and access levels. Roles are crucial
          for maintaining security and ensuring that each user has the right
          level of access.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#settings-users",
    content: (
      <>
        <p size="large">Users Management</p>
        <p>
          Your hub for creating, editing, and managing user accounts. Ensure
          that each team member has the right access and permissions for
          seamless collaboration.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#settings-pipelines",
    content: (
      <>
        <p size="large">Pipelines Management</p>
        <p>
          Your control center for organizing and tracking lead progress.
          Pipelines help you define and visualize the stages your leads go
          through in the conversion process.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#settings-sources",
    content: (
      <>
        <p size="large">Sources Management</p>
        <p>
          You define the origins of your leads. Sources help you understand
          where your leads are coming from and optimize your outreach
          strategies.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#settings-types",
    content: (
      <>
        <p size="large">Types Management </p>
        <p>
          You define the categories of your leads. Types help you classify and
          segment your leads based on specific characteristics or attributes.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#settings-tags",
    content: (
      <>
        <p size="large">Tags Management </p>
        <p>
          Your tool for organizing and categorizing items. Tags provide a
          flexible way to label and filter information, improving the
          organization and retrieval of data.
        </p>
      </>
    ),
    disableBeacon: true,
  },
  {
    target: "#settings-business",
    content: (
      <>
        <p size="large">Business Settings</p>
        <p>
          The hub for configuring and managing business-specific details. Here,
          you can fine-tune settings related to your organization, ensuring
          accuracy and relevance.
        </p>
      </>
    ),
    disableBeacon: true,
  },
];
