import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import theme from "../../theme";
import CustomNoRowsOverlay from "../../Utils/CustomNoRowsOverLay";

function TopCustomers({ DashboardData }) {
  return (
    <Box
      sx={{
        width: "33%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
      id="dashboard-top-customer"
    >
      <Paper elevation={3} sx={{ height: "350px" }}>
        <Typography sx={{ padding: "15px", fontWeight: "bold" }}>
          Top Customers
        </Typography>
        <Divider sx={{ width: "100%" }} />
        {DashboardData?.TopCustomer?.length > 0 ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              padding: "10px",
              height: "289px",
              overflow: "scroll",
            }}
          >
            {DashboardData?.TopCustomer?.map((item, i) => {
              return (
                <>
                  <Box sx={{ width: "49%" }} key={i}>
                    <Typography
                      sx={{ color: (theme) => theme.palette.primary.main }}
                      variant="h4"
                    >
                      {item?.total}
                    </Typography>
                    <Typography
                      sx={{ color: (theme) => theme.palette.primary.main }}
                    >
                      {item?.CustomerName}
                    </Typography>
                    <Divider sx={{ width: "100%" }} />
                  </Box>
                  {/* <Box sx={{width:"49%"}} key={i}>
<Typography>{item?.total}</Typography>
<Typography>{item?.CustomerName}</Typography>
<Divider sx={{width:"100%"}} />
            </Box>
        <Box sx={{width:"49%"}} key={i}>
<Typography>{item?.total}</Typography>
<Typography>{item?.CustomerName}</Typography>
<Divider sx={{width:"100%"}} />
            </Box> */}
                </>
              );
            })}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "60%",
              display: "flex",
              gap: "10px",
              padding: "10px",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            {CustomNoRowsOverlay("No Data Available")}
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default TopCustomers;
TopCustomers.propTypes = {
  DashboardData: PropTypes.any,
};
