import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
// import countryList from "react-select-country-list";
import { Country, State, City } from "country-state-city";

import * as Yup from "yup";
import Select from "react-select";
import PropTypes from "prop-types";
import { ReactSelectTheme } from "../../../Utils/ReactSelectTheme";

const useStyle = makeStyles((theme) => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textAreaContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    AddressContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    LoadingContainer: {
      height: "70vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});
const ValidationSchema = Yup.object().shape({
  billingaddress: Yup.string().required("Please Enter billing address"),
  // state: Yup.string().required("Please Enter State"),
  // city: Yup.string().required("Please Enter City"),
  postCode: Yup.string().required("Please Enter Postal Code"),
});

function AddressInformation({
  handleNext,
  handleBack,
  setaddressInfo,
  addressInfo,
  state,
  singleLead,
}) {
  const { formContainer, textAreaContainer, AddressContainer } = useStyle();
  const [allcountries, setallcountries] = useState([]);
  const [allstates, setallstates] = useState([]);
  const [allstates2, setallstates2] = useState([]);
  const [country, setcountry] = useState(null);
  const [states, setstates] = useState(null);
  const [city, setcity] = useState(null);
  const [country2, setcountry2] = useState(null);
  const [states2, setstates2] = useState(null);
  const [city2, setcity2] = useState(null);
  const [allcities, setallcities] = useState([]);
  const [allcities2, setallcities2] = useState([]);
  const [errors, seterrors] = useState(null);

  // const options = useMemo(() => countryList().getData(), []);
  console.log(state);
  console.log(addressInfo);
  useEffect(() => {
    let value = Country.getAllCountries()?.map((data) => {
      let obj = {
        label: data?.name,
        value: data,
      };
      return obj;
    });
    setallcountries(value);
  }, [Country]);
  console.log(country);
  useEffect(() => {
    let value = State.getStatesOfCountry(country?.isoCode);
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data,
      };
      return obj;
    });
    setallstates(value);
  }, [country]);
  useEffect(() => {
    let value = State.getStatesOfCountry(country2?.isoCode);
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data,
      };
      return obj;
    });
    setallstates2(value);
  }, [country2]);
  useEffect(() => {
    let value = City.getCitiesOfState(states?.countryCode, states?.isoCode);
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.name,
      };
      return obj;
    });
    setallcities(value);
  }, [states]);
  useEffect(() => {
    let value = City.getCitiesOfState(states2?.countryCode, states2?.isoCode);
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.name,
      };
      return obj;
    });
    setallcities2(value);
  }, [states2]);

  useEffect(() => {
    if (addressInfo) {
      allcountries?.map((country) => {
        if (addressInfo.country == country?.label) {
          setcountry({
            value: country.label,
            label: country.label,
            isoCode: country?.value?.isoCode,
          });
        }
      });
      allcountries?.map((country) => {
        if (country?.label == addressInfo.country2) {
          setcountry2({
            value: country.label,
            label: country.label,
            isoCode: country?.value?.isoCode,
          });
        }
      });
    }
    // if (state) {
    //   setcountry({ label: state?.BillingAddressNavigation?.Country, });
    //   setcountry2({ label: state?.ShippingAddressNavigation?.Country });
  }, [addressInfo, allcountries]);
  useEffect(() => {
    if (addressInfo) {
      allstates?.map((stat) => {
        if (stat?.label == addressInfo.state) {
          setstates({
            label: stat?.label,
            value: stat?.label,
            isoCode: stat?.value?.isoCode,
            countryCode: stat?.value?.countryCode,
          });
        }
      });
      allstates2?.map((stat) => {
        if (stat?.label == addressInfo.state2) {
          setstates2({
            label: stat?.label,
            value: stat?.label,
            isoCode: stat?.value?.isoCode,
            countryCode: stat?.value?.countryCode,
          });
        }
      });
    }
  }, [addressInfo, allstates, allstates2]);
  useEffect(() => {
    if (addressInfo) {
      allcities?.map((cit) => {
        if (cit?.label == addressInfo.city) {
          setcity({
            label: cit?.label,
            value: cit?.label,
            isoCode: cit?.value?.isoCode,
            countryCode: cit?.value?.countryCode,
          });
        }
      });
      allcities2?.map((cit) => {
        if (cit?.label == addressInfo.city2) {
          setcity2({
            label: cit?.label,
            value: cit?.label,
            isoCode: cit?.value?.isoCode,
            countryCode: cit?.value?.countryCode,
          });
        }
      });
    }
  }, [addressInfo, allcities, allcities2]);
  const [initialValues] = useState({
    billingaddress: addressInfo?.billingaddress || "",
    country: addressInfo ? addressInfo?.country : "",
    // state: addressInfo ? addressInfo?.state : "",
    // city: addressInfo ? addressInfo?.city : "",
    postCode: addressInfo ? addressInfo?.postCode : "",
    shippingaddress: addressInfo ? addressInfo?.shippingaddress : "",
    // country2: addressInfo ? addressInfo?.country2 : "",
    // state2: addressInfo ? addressInfo?.state2 : "",
    // city2: addressInfo ? addressInfo?.city2 : "",
    postCode2: addressInfo ? addressInfo?.postCode2 : "",
  });

  useEffect(() => {
    console.log(allcountries);
    console.log(state?.BillingAddressNavigation?.Country);
    allcountries?.map((country) => {
      if (state?.BillingAddressNavigation?.Country == country?.label) {
        console.log(state?.BillingAddressNavigation?.Country);
        setcountry({
          value: country.label,
          label: country.label,
          isoCode: country?.value?.isoCode,
        });
      }
    });
    allcountries?.map((country) => {
      if (country?.label == state?.ShippingAddressNavigation?.Country) {
        setcountry2({
          value: country.label,
          label: country.label,
          isoCode: country?.value?.isoCode,
        });
      }
    });
  }, [state, allcountries]);
  useEffect(() => {
    allstates?.map((stat) => {
      if (stat?.label == state?.BillingAddressNavigation?.State) {
        setstates({
          label: stat?.label,
          value: stat?.label,
          isoCode: stat?.value?.isoCode,
          countryCode: stat?.value?.countryCode,
        });
      }
    });
    allstates2?.map((stat) => {
      if (stat?.label == state?.ShippingAddressNavigation?.State) {
        setstates2({
          label: stat?.label,
          value: stat?.label,
          isoCode: stat?.value?.isoCode,
          countryCode: stat?.value?.countryCode,
        });
      }
    });
  }, [state, allstates, allstates2]);
  useEffect(() => {
    allcities?.map((cit) => {
      if (cit?.label == state?.BillingAddressNavigation?.City) {
        setcity({
          label: cit?.label,
          value: cit?.label,
          isoCode: cit?.value?.isoCode,
          countryCode: cit?.value?.countryCode,
        });
      }
    });
    allcities2?.map((cit) => {
      if (cit?.label == state?.ShippingAddressNavigation?.City) {
        setcity2({
          label: cit?.label,
          value: cit?.label,
          isoCode: cit?.value?.isoCode,
          countryCode: cit?.value?.countryCode,
        });
      }
    });
  }, [state, allcities, allcities2]);
  useEffect(() => {
    handleEdit(state);
  }, [state]);
  useEffect(() => {
    if (addressInfo) {
      formik.setFieldValue("billingaddress", addressInfo?.billingaddress);
      formik.setFieldValue("country", addressInfo?.country);
      formik.setFieldValue("state", addressInfo?.state);
      formik.setFieldValue("city", addressInfo?.city);
      formik.setFieldValue("postCode", addressInfo?.postCode);
      formik.setFieldValue("shippingaddress", addressInfo?.shippingaddress);
      formik.setFieldValue("country2", addressInfo?.country2);
      formik.setFieldValue("state2", addressInfo?.state2);
      formik.setFieldValue("city2", addressInfo?.city2);
      formik.setFieldValue("postCode2", addressInfo?.postCode2);
      setcountry(addressInfo?.country);
      setcountry2(addressInfo?.country2);
    }
  }, [addressInfo]);
  const handleEdit = (values) => {
    formik.handleChange({
      target: {
        name: "billingaddress",
        value: values?.BillingAddressNavigation?.AddressDetail || "",
      },
    });
    formik.handleChange({
      target: {
        name: "postCode",
        value: Number(values?.BillingAddressNavigation?.Postcode) || "",
      },
    });
    formik.handleChange({
      target: {
        name: "shippingaddress",
        value: values?.ShippingAddressNavigation?.AddressDetail || "",
      },
    });
    formik.handleChange({
      target: {
        name: "postCode2",
        value: values?.ShippingAddressNavigation?.Postcode || "",
      },
    });
  };
  const changeHandler = (value) => {
    setcountry({
      value: value.label,
      label: value.label,
      isoCode: value?.value?.isoCode,
    });
    setstates(null);
    setcity(null);
  };
  const changeHandler2 = (country2) => {
    setcountry2({
      value: country2.value,
      label: country2.label,
      isoCode: country2?.value?.isoCode,
    });
    setstates2(null);
    setcity2(null);
  };
  useEffect(() => {
    if (country != null) {
      seterrors({ ...errors, country: false });
      return;
    } else if (states != null) {
      seterrors({ ...errors, state: false });
      return;
    } else if (city != null) {
      seterrors({ ...errors, city: false });
      return;
    }
  }, [country, states, city]);
  const handleSubmit = (values, setSubmitting) => {
    let countryValidation = false;
    let stateValidation = false;
    let cityValidation = false;
    if (country == null || country == "") {
      countryValidation = true;
      setSubmitting(false);
      seterrors({ ...errors, country: true });
      return;
    } else if (states == null || states == "") {
      stateValidation = true;
      setSubmitting(false);
      seterrors({ ...errors, state: true });
      return;
    } else if (city == null || city == "") {
      cityValidation = true;
      setSubmitting(false);
      seterrors({ ...errors, city: true });
      return;
    }
    if (countryValidation || stateValidation || cityValidation) {
      setSubmitting(false);
      return;
    } else {
      const obj = {
        ...values,
        country: country?.label,
        country2: country2?.label,
        state: states?.label,
        city: city?.label,
        state2: states2?.label,
        city2: city2?.label,
      };
      setaddressInfo(obj);
      handleNext();
      console.log(obj);
    }
    setSubmitting(false);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const handleNavigate = () => {
    handleBack();
  };
  useEffect(() => {
    if (singleLead?.Address) {
      formik?.setFieldValue(
        "billingaddress",
        singleLead?.Address?.AddressDetail
      );

      formik?.setFieldValue("country", singleLead?.Address?.Country);
      setcountry({ label: singleLead?.Address?.Country });

      formik?.setFieldValue("state", singleLead?.Address?.State);
      formik?.setFieldValue("city", singleLead?.Address?.City);
      formik?.setFieldValue("postCode", singleLead?.Address?.Postcode);
    }
  }, [singleLead]);
  return (
    <Paper elevation={3} sx={{ padding: "20px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={formContainer}>
          <Box>
            <Box>
              <Typography> Billing Address</Typography>
            </Box>
            <Box className={textAreaContainer}>
              <Box className={AddressContainer}>
                <FormControl fullWidth>
                  <TextField
                    name="billingaddress"
                    value={formik.values?.billingaddress}
                    multiline
                    minRows={8}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.billingaddress) &&
                      Boolean(formik.touched.billingaddress)
                    }
                    helperText={
                      Boolean(formik.errors.billingaddress) &&
                      Boolean(formik.touched.billingaddress) &&
                      formik.errors.billingaddress
                    }
                  />
                </FormControl>
              </Box>
              <Box className={AddressContainer}>
                <FormControl fullWidth>
                  <Select
                    // options={options}
                    options={allcountries}
                    value={country}
                    onChange={(e) => changeHandler(e)}
                    placeholder="Select Country"
                    styles={ReactSelectTheme(false)}
                  />
                  {errors?.country ? (
                    <Typography color={"error"} sx={{ fontSize: "12px" }}>
                      Please Enter Country
                    </Typography>
                  ) : null}
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    isDisabled={!country ? true : false}
                    options={allstates}
                    value={states}
                    placeholder="Select State"
                    styles={ReactSelectTheme(false)}
                    onChange={(e) => {
                      console.log(e);
                      setstates({
                        label: e?.label,
                        value: e?.label,
                        isoCode: e?.value?.isoCode,
                        countryCode: e?.value?.countryCode,
                      });
                      setcity(null);
                    }}
                  />
                  {errors?.state ? (
                    <Typography color={"error"} sx={{ fontSize: "12px" }}>
                      Please Enter State
                    </Typography>
                  ) : null}
                  {/* <TextField
                    name="state"
                    value={formik.values?.state}
                    placeholder="State"
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.state) &&
                      Boolean(formik.touched.state)
                    }
                    helperText={
                      Boolean(formik.errors.state) &&
                      Boolean(formik.touched.state) &&
                      formik.errors.state
                    }
                  /> */}
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    isDisabled={!states ? true : false}
                    options={allcities}
                    value={city}
                    placeholder="Select City"
                    styles={ReactSelectTheme(false)}
                    onChange={(e) => {
                      console.log(e);
                      setcity({
                        label: e?.label,
                        value: e?.label,
                        isoCode: e?.value?.isoCode,
                        countryCode: e?.value?.countryCode,
                      });
                    }}
                  />
                  {errors?.city ? (
                    <Typography color={"error"} sx={{ fontSize: "12px" }}>
                      Please Enter City
                    </Typography>
                  ) : null}
                  {/* <TextField
                    name="city"
                    value={formik.values?.city}
                    placeholder="City"
                    size={"small"}
                    onChange={formik.handleChange}
                    fullWidth
                    error={
                      Boolean(formik.errors.city) &&
                      Boolean(formik.touched.city)
                    }
                    helperText={
                      Boolean(formik.errors.city) &&
                      Boolean(formik.touched.city) &&
                      formik.errors.city
                    }
                  /> */}
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    name="postCode"
                    value={formik.values?.postCode}
                    onChange={formik.handleChange}
                    type="number"
                    placeholder={"Postal Code"}
                    size={"small"}
                    fullWidth
                    error={
                      Boolean(formik.errors.postCode) &&
                      Boolean(formik.touched.postCode)
                    }
                    helperText={
                      Boolean(formik.errors.postCode) &&
                      Boolean(formik.touched.postCode) &&
                      formik.errors.postCode
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography> Shipping Address</Typography>
            </Box>
            <Box className={textAreaContainer}>
              <Box className={AddressContainer}>
                <FormControl fullWidth>
                  <TextField
                    name="shippingaddress"
                    value={formik.values?.shippingaddress}
                    multiline
                    minRows={8}
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box>
              <Box className={AddressContainer}>
                <FormControl fullWidth>
                  <Select
                    // options={options}
                    options={allcountries}
                    value={country2}
                    onChange={(e) => changeHandler2(e)}
                    placeholder="Select Country"
                    styles={ReactSelectTheme(false)}
                  />
                  {/* <Select
                    // options={options}
                    options={allcountries}
                    value={country2}
                    onChange={(e) => changeHandler2(e)}
                    placeholder="Select Country"
                  /> */}
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    isDisabled={!country2 ? true : false}
                    options={allstates2}
                    value={states2}
                    placeholder="Select State"
                    styles={ReactSelectTheme(false)}
                    onChange={(e) => {
                      console.log(e);
                      setstates2({
                        label: e?.label,
                        value: e?.label,
                        isoCode: e?.value?.isoCode,
                        countryCode: e?.value?.countryCode,
                      });
                      setcity2(null);
                    }}
                  />
                  {/* <TextField
                    name="state2"
                    value={formik.values?.state2}
                    placeholder="State"
                    size={"small"}
                    fullWidth
                    onChange={formik.handleChange}
                  /> */}
                </FormControl>
                <FormControl fullWidth>
                  <Select
                    styles={ReactSelectTheme(false)}
                    isDisabled={!states2 ? true : false}
                    options={allcities2}
                    value={city2}
                    placeholder="Select City"
                    onChange={(e) => {
                      console.log(e);
                      setcity2({
                        label: e?.label,
                        value: e?.label,
                        isoCode: e?.value?.isoCode,
                        countryCode: e?.value?.countryCode,
                      });
                    }}
                  />
                  {/* <TextField
                    name="city2"
                    value={formik.values?.city2}
                    placeholder="City"
                    size={"small"}
                    onChange={formik.handleChange}
                    fullWidth
                  /> */}
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    name="postCode2"
                    value={formik.values?.postCode2}
                    onChange={formik.handleChange}
                    type="number"
                    placeholder={"Postal Code"}
                    size={"small"}
                    fullWidth
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button
                  onClick={() => {
                    handleNavigate();
                    const obj = {
                      ...formik.values,
                      country: country?.label,
                      country2: country2?.label,
                      state: states?.label,
                      city: city?.label,
                      state2: states2?.label,
                      city2: city2?.label,
                    };
                    setaddressInfo(obj);
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Back</Typography>
                </Button>
              </Box>
              <Box>
                {formik.isSubmitting ? (
                  <Box>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button variant="contained" type="submit">
                    <Typography sx={{ fontWeight: "bold" }}>Next</Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

export default AddressInformation;
AddressInformation.propTypes = {
  // activeStep: PropTypes.any.isRequired,
  // steps: PropTypes.string.isRequired,
  handleNext: PropTypes.any,
  handleBack: PropTypes.any,
  setaddressInfo: PropTypes.any,
  addressInfo: PropTypes.any,
  state: PropTypes.any,
  singleLead: PropTypes.any,
};
