import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../Utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  Organization: null,
  SingleOrganization: null,
  DocumentsData: null,
  populateDocumentsData: null,
  OrganizationPopulate: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchOrganizationPopulate = createAsyncThunk(
  "/Organization/populate",
  async () => {
    const response = await axiosInstance.get(`/Organization/populate`);
    return response;
  }
);
export const fetchDocumentPopulate = createAsyncThunk(
  "/Documents/populateDocuments",
  async () => {
    const response = await axiosInstance.get(`/Documents/populateDocuments`);
    return response;
  }
);
export const fetchDocuments = createAsyncThunk(
  "/Documents/{id}",
  async ({ type, id }) => {
    const response = await axiosInstance.get(
      `/Documents?type=${type}&Id=${id}`
    );
    return response;
  }
);
export const fetchOrganization = createAsyncThunk("/Organization", async () => {
  const response = await axiosInstance.get(`/Organization`);
  return response;
});
export const fetchSingleOrganization = createAsyncThunk(
  "/Organization/{id}",
  async (id) => {
    const response = await axiosInstance.get(`/Organization/${id}`);
    return response;
  }
);

const OrganizationSlice = createSlice({
  name: "Organization",
  initialState,
  reducers: {
    OrganizationAdded: {
      reducer(state, action) {
        state.Organization?.push(action.payload?.Organization?.data?.Data);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchOrganization.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.Organization = action.payload.data?.Data;
      })
      .addCase(fetchOrganization.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchSingleOrganization.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingleOrganization.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.SingleOrganization = action.payload.data?.Data;
      })
      .addCase(fetchSingleOrganization.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchDocuments.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.DocumentsData = action.payload.data?.Data;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.error = action.error.message;
        state.status2 = "failed";
      })
      .addCase(fetchDocumentPopulate.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchDocumentPopulate.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.populateDocumentsData = action.payload.data?.Data;
      })
      .addCase(fetchDocumentPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status2 = "failed";
      })
      .addCase(fetchOrganizationPopulate.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchOrganizationPopulate.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.data?.Message;
        state.isSuccess = action.payload.data?.IsSuccess;
        state.OrganizationPopulate = action.payload.data?.Data;
      })
      .addCase(fetchOrganizationPopulate.rejected, (state, action) => {
        state.error = action.error.message;
        state.status2 = "failed";
      });
  },
});

export const getOrganizationDetails = (state) => state.Organization;

export const { OrganizationAdded } = OrganizationSlice.actions;

export default OrganizationSlice.reducer;
